import React, { useEffect, useState } from 'react';
import Sidebar from '../../includes/Sidebar'
import CheckboxTree from 'react-checkbox-tree';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Toast, ToastContainer, Form } from 'react-bootstrap';

import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import { RoleApiService } from '../../../services/RoleApiService';


function CreateUpdateRole() {
  const roleService = new RoleApiService();
  const { addOrUpdateRole, getSingleRoleDetails, getPermissions } = roleService

  const { role_id } = useParams();
  const [data, setData] = useState('');
  const [checked, setChecked] = useState([]);
  const [expanded, setExpanded] = useState([]);
  const [nodes, setNodes] = useState([]);
  const [toaster, setToaster] = useState({ statusCode: 0, status: "", open: false })
  const [isSubmit, setIsSubmit] = useState(false)
  const navigate = useNavigate();
  useEffect(() => {
    if (checked?.length > 0) {
      setIsSubmit(true)
    }
    else {
      setIsSubmit(false)

    }
  }, [checked]);
  const [state, setState] = useState({
    showToast: false,
    error: false,
    error_message: "",
  })
  const addToaster = (open, message, status_code) => {
    setToaster(
      {
        open: open,
        statusCode: status_code,
        status: message,
      }
    )
  }
  const __handleEditRole = (values) => {

    addOrUpdateRole(data.id, values.roleName, checked).then((response) => {
      // console.log(values.roleName)
      if (response && response.status) {

        addToaster(true, 'Success', 200)


        const timer = setTimeout(() => {
          addToaster(false, '', '')

          navigate('/list-roles')
        }, 2000);
        return () => clearTimeout(timer);
      }
      else {
        const message = response?.response?.data?.errors?.name[0] ?? "Failed"
        addToaster(true, message, 201)
        const timer = setTimeout(() => {
          addToaster(false, '', '')

          let message = response.message
        }, 2000);
        return () => clearTimeout(timer);
      }
    });
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      roleName: "",
    },
    validationSchema: Yup.object().shape({
      roleName: Yup.string()
        .min(1, ' at least 1 character')
        .required('Required'),
    }),
    onSubmit: (values) => {
      __handleEditRole(values)
    },
  });


  useEffect(() => {
    getPermissions().then((response) => {
      if (response && response.data && response.data.permissions) {
        setNodes(response.data.permissions)
      }

    });
    if (role_id) {

      getSingleRoleDetails(role_id).then((response) => {
        if (response?.status && response?.data) {
          setData(response?.data?.role);
          formik.setValues({
            ...formik.values,
            role: role_id,
            roleName: response?.data?.role?.name,

          })
          setChecked(response?.data?.role?.permissions)
        }
      });

    }
  }, [role_id]);



  console.log(toaster)
  return (
    <React.Fragment>
      <Sidebar />
      <main id="main" className="main">
        <ToastContainer className="p-3" position={"top-end"}>
          <Toast bg="success" show={toaster.statusCode == 200 ? true : false} animation={true} delay={2000} autohide>

            <Toast.Body ><span className='text-white'>{toaster.status}</span></Toast.Body>
          </Toast>
          <Toast bg="danger" show={toaster.statusCode == 201 ? true : false} animation={true} delay={2000} autohide>

            <Toast.Body ><span className='text-white'>{toaster.status}</span></Toast.Body>
          </Toast>
        </ToastContainer>

        <div className="pagetitle tab-fixed-header">
          <h1>{role_id ? "Edit" : "Create"} Role</h1>

        </div>

        <section className="section mt-5">

          <div className="row align-items-top py-3 gap-2 gap-md-5">
            <div className="col-lg-12">
              <div className="card mb-5 mb-xl-8">

                <div className="card-body py-3 ">

                  <Form
                    onSubmit={formik.handleSubmit}
                  >
                    <div className="mb-3 row" >
                      <div className='col-lg-6'>
                        <Form.Group className="mb-3" >

                          <Form.Label className='fw-bold'>Role Name</Form.Label>
                          <Form.Control
                            name='roleName'
                            type="text"
                            onChange={formik.handleChange}
                            autoComplete="false"
                            // isValid={formik.touched.roleName && !!formik.errors.roleName}
                            isInvalid={!!formik.errors.roleName}
                            value={formik.values.roleName}
                            className='b-r-10 form-control-solid'
                            maxLength="255"
                            required
                            placeholder="Enter Role Name"
                          />
                          <Form.Control.Feedback type="invalid">{formik.errors.roleName}</Form.Control.Feedback>

                        </Form.Group>
                      </div>
                      <div className='col-lg-6'>
                        <label htmlhtmlFor="inputName5" className="required form-label">Permissions</label>
                        <CheckboxTree
                          nodes={nodes}
                          checked={checked}
                          expanded={expanded}
                          onCheck={checked => setChecked(checked)}
                          onExpand={expanded => setExpanded(expanded)}
                          icons={{
                            check: <i className="bi bi-check2-square" />,
                            uncheck: <span className="bi bi-square" />,
                            halfCheck: <span className="bi bi-dash-square" />,
                            expandClose: <span className="bi bi-chevron-right" />,
                            expandOpen: <span className="bi bi-chevron-down" />,
                            expandAll: <span className="rct-icon rct-icon-expand-all" />,
                            collapseAll: <span className="rct-icon rct-icon-collapse-all" />,
                            parentClose: <span className="bi bi-folder" />,
                            parentOpen: <span className="bi-folder2-open" />,
                            leaf: <span className="bi bi-file-earmark" />,
                          }}
                        />
                        {!isSubmit &&
                          <span className='text-danger '>
                            Required
                          </span>}

                      </div>
                      <div>
                        {state.error &&
                          <span className='text-danger'>{state.error_message}</span>}
                      </div>
                    </div>

                    <div className='d-flex flex-row justify-content-end'>
                      <div className=''>

                      </div>
                      <div className=''>
                        <button
                          disabled={!isSubmit} type='submit'
                          className='btn btn-primary btnHover'
                        >
                          {role_id ? "Update" : "Create"}
                        </button>
                      </div>

                    </div>
                  </Form>

                </div>
              </div>
            </div>
          </div>
        </section>


      </main>
    </React.Fragment>
  )
}

export default CreateUpdateRole