import request from "../api/agent";

const API_ROUTE = "reports";

export class PayerReportService {

  async getPayerReportSummery(page, limit, station, job_status, date_range, hour_count, payer_list, clients, facilities) {
    const params = `?page=${page}&limit=${limit}&date_range=${date_range}&job_status=${job_status}&station=${station}&hour_count=${hour_count}&payer_list=${payer_list}&clients=${clients}&facilities=${facilities}`

    const response = await request.get(`${API_ROUTE}/get-payer-report-summery` + params);
    return response.data ?? response?.response?.data;
  }

  async getPayerReportDetails(page, limit, station, job_status, date_range, hour_count, user, day, year, week, monthList, clients, facilities, client_id, facility_id) {
    const params = `?page=${page}&limit=${limit}&date_range=${date_range}&job_status=${job_status}&station=${station}&hour_count=${hour_count}&user=${user}&day=${day}&year=${year}&week=${week}&month=${monthList}&clients=${clients}&facilities=${facilities}&client_id=${client_id}&facility_id=${facility_id}`

    const response = await request.get(`${API_ROUTE}/get-payer-report-details` + params);
    return response.data;
  }
  async downloadPayerReportSummery(page, limit, station, job_status, date_range, hour_count, payer_list, clients, facilities) {
    const params = `?page=${page}&limit=${limit}&date_range=${date_range}&job_status=${job_status}&station=${station}&hour_count=${hour_count}&payer_list=${payer_list}&clients=${clients}&facilities=${facilities}`

    const response = await request.get(`${API_ROUTE}/payer-report-download` + params);
    return response.data;
  }

  async downloadPayerReportDetails(page, limit, station, job_status, date_range, hour_count, user, day, year, week, monthList, clients, facilities, client_id, facility_id) {
    const params = `?page=${page}&limit=${limit}&date_range=${date_range}&job_status=${job_status}&station=${station}&hour_count=${hour_count}&user=${user}&day=${day}&year=${year}&week=${week}&month=${monthList}&clients=${clients}&facilities=${facilities}&client_id=${client_id}&facility_id=${facility_id}`

    const response = await request.get(`${API_ROUTE}/payer-report-details-download` + params);
    return response.data;
  }
  async getPayerDropdown(page, search, client) {
    const params = "?page=" + page + "&search=" + search

    const response = await request.get(`${API_ROUTE}/search-payer-dropdown` + params);
    return response.data;
  }
}