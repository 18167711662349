import React from 'react'
import Sidebar from '../../includes/Sidebar'

import * as Yup from 'yup';
import { Form } from 'react-bootstrap';
import { useFormik } from 'formik';
import { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { TbGridDots } from "react-icons/tb";
import { HiChevronDown } from "react-icons/hi";
import Select, { components } from 'react-select';
import { Toast, ToastContainer } from 'react-bootstrap';
import { GroupApiService } from '../../../services/GroupApiService';


function CreateUpdateGroup() {
  const groupService = new GroupApiService();
  const { addOrUpdateGroup, getSingleGroupDetails, getRolesDropdown } = groupService

  const navigate = useNavigate()
  const { group_id } = useParams();
  const [state, setState] = useState({
    error: false,
    error_message: ""
  })
  const [data, setData] = useState([]);
  const [toaster, setToaster] = useState(false)
  const [roles, setRoles] = useState({})
  const [options, setoOptions] = useState([])

  useEffect(() => {

    getRolesDropdown().then((response) => {
      if (response?.status && response?.data) {
        console.log(response?.data?.roles);

        const data = [];
        // response?.data?.roles?.forEach((item) => {
        //   data.push({ value: item.id, label: item.name })
        // })
        setoOptions(response?.data?.roles)
      }
    });

    if (group_id) {

      getSingleGroupDetails(group_id).then((response) => {

        console.log(response);
        if (response?.status && response?.data?.group) {
          setData(response?.data?.group);
          setRoles(response?.data?.group?.roles.map((val) => val.value));
          // setRoles(response?.data?.group?.roles?.map((val) => val.value))


          // const role_ids = response?.data?.group?.roles.map((val) => val.value);

          // console.log(role_ids)
          formik.setValues({
            ...formik.values,
            groupName: response?.data?.group?.name,
            roles: response?.data?.group?.roles,
          })
        }
      });
    }

  }, [group_id]);

  console.log(roles);

  const __showDataEntry = (e) => {
    e.preventDefault();
    addOrUpdateGroup(data.id, formik.values.groupName, roles).then((response) => {
      if (response?.status) {

        setToaster('success')
        const timer = setTimeout(() => {
          setToaster(false)
          navigate('/list-groups')
        }, 2000);
        return () => clearTimeout(timer);
      }
      else {
        formik.setErrors({

          groupName: response?.response?.data?.errors?.name,
          roles: response?.data?.errors?.roles,


        })
        console.log(response)


      }
    });
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      groupName: "", roles: []
    },
    validationSchema: Yup.object().shape({
      groupName: Yup.string()
        .required('Required'),
      roles: Yup.array().min(1, 'At least one value is required')
        .of(
          Yup.object().shape({
            value: Yup.number().required(),
            label: Yup.string().required()
          })
        ),
    }),

    onSubmit: (values) => {
      // __handleEditUser(values)
    },
  });
  const DropdownIndicator = ({ state, ...props }) => {
    return (
      <components.DropdownIndicator {...props}>

        {roles?.length > 1 ? (
          <TbGridDots style={{ fontSize: "1.5em" }} />
        ) : (
          <HiChevronDown style={{ fontSize: "1.5em" }} />
        )}

      </components.DropdownIndicator>
    );
  };
  const handleOnSelectChange = ({ name, value }) => {

    formik.setValues({ ...formik.values, roles: value })
    setRoles(value.map((val) => val.value))
  }


  const [isFocusedClient, setIsFocusedClient] = useState(false);
  console.log(options);
  return (
    <React.Fragment>
      <Sidebar />
      <main id="main" className="main">
        <ToastContainer className="p-3" position={"top-end"}>
          <Toast bg="success" show={toaster == 'success' ? true : false} animation={true} delay={2000} autohide>

            <Toast.Body ><span className='text-white'>Success</span></Toast.Body>
          </Toast>
          <Toast bg="danger" show={toaster == 'error' ? true : false} animation={true} delay={2000} autohide>

            <Toast.Body ><span className='text-white'>Failed</span></Toast.Body>
          </Toast>
        </ToastContainer>
        <div className="pagetitle tab-fixed-header">
          <h1>{group_id ? "Update" : "Create"} Group</h1>

        </div>

        <section className="section mt-5">
          <div className="row align-items-top py-3 gap-2 gap-md-5">
            <div className="col-lg-12">
              <div className="card mb-5 mb-xl-8">
                <div className="card-body py-3 ">
                  <Form
                    onSubmit={(e) => __showDataEntry(e)}
                  >
                    <div className="mb-3 row">
                      <div className='col-lg-6'>
                        <Form.Group className="mb-3">
                          <Form.Label className='fw-bold'>Group Name</Form.Label>

                          <Form.Control
                            type="text"
                            name='groupName'
                            value={formik.values.groupName}
                            onChange={formik.handleChange}
                            className="form-control form-control-solid b-r-10"
                            placeholder="Enter Group Name"
                            isValid={formik.touched?.groupName && !!formik.errors?.groupName}
                            isInvalid={!!formik.errors?.groupName}
                          />
                          <Form.Control.Feedback type="invalid">{formik.errors?.groupName}</Form.Control.Feedback>

                        </Form.Group>
                      </div>

                      <div className='col-lg-6'>
                        <Form.Group className="mb-3">
                          <Form.Label className='fw-bold'>Roles</Form.Label>
                          <Select
                            className="basic-single"
                            classNamePrefix="select"
                            isMulti
                            value={formik.values.roles}
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            name="roles"
                            options={options}
                            onFocus={() => setIsFocusedClient(true)}
                            onBlur={() => setIsFocusedClient(false)}
                            components={{ DropdownIndicator: (props) => <DropdownIndicator {...props} state="roles" /> }}
                            onChange={(e) => { handleOnSelectChange({ name: 'roles', value: e }) }}
                            placeholder="Select Roles"
                            styles={{
                              container: (styles, state) => ({
                                ...styles,
                                borderRadius: "9px",
                              }),
                              control: (styles, state) => ({
                                ...styles,
                                backgroundColor: "hsl(204deg 33.33% 97.06%)",
                                borderStyle: "none", boxShadow: "none",
                                borderRadius: "10px",
                                maxHeight: "30px",
                              }),
                              indicatorSeparator: styles => ({
                                ...styles, width: "0px", minHeight: "20px",
                                maxHeight: "30px",
                              }),

                              valueContainer: (styles, state) => ({
                                ...styles,
                                maxHeight: "30px",
                                overflow: 'scroll',
                              }),
                              option: (styles, state) => ({
                                ...styles,
                                backgroundColor: state.isSelected ? '#d9f4f5' : 'inherit',
                                color: state.isSelected ? '#000000' : 'inherit',
                                '&:hover': { backgroundColor: state.isSelected ? '#d9f4f5' : 'rgb(222, 235, 255)' }

                              })
                            }}
                          />
                        </Form.Group>
                        {formik.errors?.roles &&

                          <span className='text-danger'>{formik.errors.roles}</span>
                        }
                      </div>
                    </div>
                    {state.error &&
                      <div className='text-center mt-3'>

                        <span className='text-danger fw-bold '>{state.error_message}</span>
                      </div>
                    }

                    <div className='row mt-2'>
                      <div className='col-lg-12 text-end'>

                        <button
                          disabled={!formik.isValid}
                          type='submit'
                          className='btn btn-light btn-active-primary'
                        >
                          {group_id ? "Update" : "Create"}
                        </button>
                      </div>
                    </div>

                  </Form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </React.Fragment>
  )
}

export default CreateUpdateGroup