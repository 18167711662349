import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { FireBaseService } from '../services/firebase/FireBaseService';


// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//   authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
//   databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
//   projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
//   appId: process.env.REACT_APP_FIREBASE_APP_ID,
// };
const firebaseConfig = {
  apiKey: "AIzaSyCNXEXg76ad2aFikGOLe-_KaEgOfaOfIQg",
  authDomain: "xtract-uat-analytics.firebaseapp.com",
  projectId: "xtract-uat-analytics",
  storageBucket: "xtract-uat-analytics.appspot.com",
  messagingSenderId: "99339273135",
  appId: "1:99339273135:web:4e12e3ca9641344725e55a",
  measurementId: "G-PZ88Z0EF9F"
};
const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);
const firebaseService = new FireBaseService();

export const requestForToken = async () => {
  console.log("requestForTokenrequestForTokenrequestForToken")
  try {
    const currentToken = await getToken(messaging, { vapidKey: 'BFDhmm8B91GqO-heSas_FaI64dgVTMAbkXECcjwhpCxYkMt9QhiYGyTZ7HWQZV3dMqJGv0EeAfvhVadyD3Ot5IA' });
    if (currentToken) {
      localStorage.setItem('currentToken', currentToken);
      firebaseService.registerDevice(currentToken);
    } else {
      console.log('No registration token available. Request permission to generate one.');
    }
  } catch (err) {
    console.log('An error occurred while retrieving token.', err);
  }
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log('payload', payload);
      resolve(payload);
    });
  });
