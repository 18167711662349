import request from "../api/agent";

const API_ROUTE = "users/groups";

export class GroupApiService {
  groups = [];

  async getAllGroups(page, limit, search = '') {
    const params = `?page=${page}&limit=${limit}&search=${search}`

    const response = await request.get(`${API_ROUTE}/get-groups` + params);
    return response?.data;
  }
  async getRolesDropdown() {

    const response = await request.get(`${API_ROUTE}/get-roles-for-group-creation`);
    return response?.data;
  }
  async getSingleGroupDetails(group) {
    const params = `?page=${1}&group=${group}`

    const response = await request.get(`${API_ROUTE}/get-group-details` + params);
    return response?.data;
  }

  async addOrUpdateGroup(group, name, roles) {

    let action = `${API_ROUTE}/create-or-update-group`;
    const response = await request.post(action, { group, name, roles });
    return response;
  }

  async deleteGroup(id) {
    const response = await request.delete(`${API_ROUTE}/destroy-group`, {
      data: { group: id }
    });
    return response;
  }
}