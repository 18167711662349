import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import InputMask from "react-input-mask";
import { handleWheel } from "../gridFunctions";
import { DbkField } from "../Elements/DbkField";
import { useEffect } from "react";
import moment from "moment";
import { EDIT_WORK, QA_REVIEW, isActiveDbkField } from "../../../../../functions";

const Claims = (props) => {
  const {
    index,
    formik,
    claim835Index,
    setClaim835Index,
    currentPage,
    // DBK
    eobFocus,
    isDbkPermission,
    jobData,
    qdxTransaction,
    handleClaimCalculation,
    handleRemainingBalanceCalculation,
    jumpToPage,
    jumpRef,
    job_id,
    log_id,
    pdfLoaded,
    disableCorresField,
    isSubJob,
    isFirstJob
  } = props;

  useEffect(() => {
    setClaim835Index(index);
  }, [index]);

  const __removePatient = async (index) => {

    await formik.setValues((prevState) => ({

      ...prevState,
      claims: prevState.claims.map((claim, ind) => {
        if (claim835Index == ind) {
          return {
            ...claim,
            patient: false,
            patient_id: "",
            patient_first_name: "",
            patient_middle_name: "",
            patient_last_name: "",
            patient_identifier_code: "",
            dbk_patient_first_name: "",
            dbk_patient_middle_name: "",
            dbk_patient_last_name: "",
            dbk_patient_identifier_code: "",
          }
        }
        else {
          return claim;
        }
      })
    }));

  };
  const __removeSubscriber = async (index) => {

    await formik.setValues((prevState) => ({

      ...prevState,
      claims: prevState.claims.map((claim, ind) => {
        if (claim835Index == ind) {
          return {
            ...claim,
            subscriber: false,
            subscriber_id: "",
            subscriber_first_name: "",
            subscriber_middle_name: "",
            subscriber_last_name: "",
            subscriber_identifier_code: "",
            dbk_subscriber_first_name: "",
            dbk_subscriber_middle_name: "",
            dbk_subscriber_last_name: "",
            dbk_subscriber_identifier_code: "",
          }
        }
        else {
          return claim;
        }
      })
    }));
  };
  const __removeRenderingprovider = async (index) => {

    await formik.setValues((prevState) => ({

      ...prevState,
      claims: prevState.claims.map((claim, ind) => {
        if (claim835Index == ind) {
          return {
            ...claim,
            rendering_provider: false,
            rendering_provider_id: "",
            rendering_provider_first_name: "",
            rendering_provider_last_name: "",
            rendering_provider_identifier_code: "",
            dbk_rendering_provider_first_name: "",
            dbk_rendering_provider_last_name: "",
            dbk_rendering_provider_identifier_code: "",

          }
        }
        else {
          return claim;
        }
      })
    }));
  };

  const handleChangeFrom = (e) => {
    // var data = formik.values.claims;

    // (data[index].page_from = currentPage + 1),
    //   formik.setValues({
    //     ...formik.values,
    //     claims: data,
    //   });
    if (!e.target.disabled) {

      formik.setValues((prevState) => ({
        ...prevState,
        claims: prevState.claims.map((claim, i) => {
          if (i === index) {
            return {
              ...claim,
              page_from: currentPage + 1
            };
          }
          return claim;
        })
      }));
    }
  };

  const handleChangeTo = (e) => {

    formik.setValues((prevState) => ({
      ...prevState,
      claims: prevState.claims.map((claim, i) => {
        if (i === index) {
          return {
            ...claim,
            page_to: currentPage + 1
          };
        }
        return claim;
      })
    }));
    // }
  };
  const handleFloatValue = async (e) => {
    e.target.value = parseFloat(e.target.value).toFixed(2);
    e.target.type = "text"
    formik.handleChange(e)
    e.target.type = "number"
  }


  const changeClaimValue = async (event, type, adjIndex) => {
    const value = event.target.value;
    let claimTotal = 0;
    if (value.includes('.') && value.split('.')[1].length > 2) {
      return;
    }
    const oldDatas = formik.values.claims;

    await formik.setValues((prevState) => ({
      ...prevState,
      claims: prevState.claims.map((claim, ind) => {
        console.log(typeof index, typeof ind, claim);
        if (ind == index) {
          return {
            ...claim,
            // Update the specific fields within the claim here
            [type]: value,
            // Add other fields to update if needed
          };
        }
        return claim; // Return the unchanged claim for other indices
      }),
    }));

    if (type == 'claim_paid') {
      const remaining = await handleRemainingBalanceCalculation()
    }
    const claim = await handleClaimCalculation()

  }

  const handleRowFromClicked = (index) => {

    const claims = formik.values?.claims

    if (claims.length > 0) {


      jumpToPage(claims[index]?.page_from - 1)
    }
  }
  const handleRowToClicked = (index) => {
    const claims = formik.values?.claims

    if (claims.length > 0) {

      jumpToPage(claims[index]?.page_to - 1)
    }
  }

  const pathname = window.location.pathname;
  const gridData = jobData?.grid_data;
  const qaCorrectedData = gridData?.qa_corrected_data;

  const renderClaimClass = (name, type, defaultClass) => {
    if (formik.errors?.claims &&
      formik.errors?.claims[index] &&
      formik.errors?.claims[index]?.[name]) {
      return defaultClass + ' error-field'
    }
    if (pathname == QA_REVIEW) {

      if (qaCorrectedData &&
        qaCorrectedData?.claims &&
        qaCorrectedData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index] &&
        qaCorrectedData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.[name]) {
        return defaultClass + ' qa-field'
      }
      else if (type == 'DATE' &&
        gridData?.claims &&
        gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index] &&
        moment(formik.values?.claims[index]?.[name]).format("MM/DD/YYYY") != moment(gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.[name]).format("MM/DD/YYYY")) {
        return defaultClass + ' qa-field'
      }
      else if (type == 'TEXT' &&
        gridData?.claims &&
        gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index] &&
        formik.values?.claims[index]?.[name]?.toUpperCase() !=
        gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.[name]?.toUpperCase()) {
        return defaultClass + ' qa-field'
      }
      else if (type == 'NUMBER' &&
        gridData?.claims &&
        gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index] &&
        parseFloat(formik.values?.claims[index]?.[name] ? formik.values?.claims[index]?.[name] : 0).toFixed(2) !=
        parseFloat(gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.[name] ?
          gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.[name] : 0).toFixed(2)) {
        return defaultClass + ' qa-field'
      }
      else if (formik.values?.claims[index]?.corrected_claim || !gridData?.claims || gridData?.transaction_type != "PAYMENT835") {
        return defaultClass + ' qa-field'
      }
      else if (type == 'DATE' && formik.values?.claims[index]?.color_data?.[name] && moment(formik.values?.claims[index]?.color_data?.[name]).format("MM/DD/YYYY") ==
        moment(formik.values?.claims[index]?.[name]).format("MM/DD/YYYY")) {
        return defaultClass + ' data-837'
      }
      else if (type == 'TEXT' && formik.values?.claims[index]?.color_data?.[name] && formik.values?.claims[index]?.color_data?.[name]?.toUpperCase() == formik.values?.claims[index]?.[name]?.toUpperCase()) {
        return defaultClass + ' data-837'
      }
      else if (type == 'NUMBER' && formik.values?.claims[index]?.color_data?.[name] && formik.values?.claims[index]?.color_data?.[name] == formik.values?.claims[index]?.[name]) {
        return defaultClass + ' data-837'
      }


      else {
        return defaultClass;
      }


    }
    else if ((pathname == EDIT_WORK + job_id || pathname == EDIT_WORK + job_id + "?log_id=" + log_id) && qaCorrectedData &&
      qaCorrectedData?.claims &&
      qaCorrectedData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index] &&
      qaCorrectedData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.[name]) {
      return defaultClass + ' qa-field'
    }
    else if (type == 'DATE' && formik.values?.claims[index]?.color_data?.[name] && moment(formik.values?.claims[index]?.color_data?.[name]).format("MM/DD/YYYY") ==
      moment(formik.values?.claims[index]?.[name]).format("MM/DD/YYYY")) {
      return defaultClass + ' data-837'
    }
    else if (type == 'TEXT' && formik.values?.claims[index]?.color_data?.[name] && formik.values?.claims[index]?.color_data?.[name]?.toUpperCase() == formik.values?.claims[index]?.[name]?.toUpperCase()) {
      return defaultClass + ' data-837'
    }
    else if (type == 'NUMBER' && formik.values?.claims[index]?.color_data?.[name] && formik.values?.claims[index]?.color_data?.[name] == formik.values?.claims[index]?.[name]) {
      return defaultClass + ' data-837'
    }
    else {
      return defaultClass;
    }
  }


  return (
    <React.Fragment>
      <div
        className="bottom-wrapper claim_details accordion-collapse collapse show"
        id="collapseTwo"
        aria-labelledby="headingTwo"
        data-bs-parent="#claim"
      >
        <div className="sub-section">
          <div className="">
            <label className="btn btn-claim">Claim Details </label>
          </div>
          <div className="wrapper-patient">

            <InputGroup>
              <InputGroup.Text>Patient Account #</InputGroup.Text>
              <div className="dbk-main-container">

                <Form.Control
                  type="text"
                  name={`claims[${index}].patient_account_number`}
                  value={formik.values?.claims[index]?.patient_account_number}
                  className={renderClaimClass('patient_account_number', 'TEXT', 'w-patient')}

                />

              </div>
            </InputGroup>
          </div>
          <div className="wrapper-patient-claim">
            <InputGroup>
              <InputGroup.Text>Payer Claim #</InputGroup.Text>
              <div className="dbk-main-container">
                <Form.Control
                  type="text"
                  name={`claims[${index}].payer_claim_number`}
                  value={formik.values?.claims[index]?.payer_claim_number}
                  className={renderClaimClass('payer_claim_number', 'TEXT', 'w-patient')}
                  data-corress-disable={disableCorresField('payer_claim_number')}
                />

              </div>
            </InputGroup>
          </div>
          <div className="wrapper">
            <InputGroup>
              <InputGroup.Text>Period Start</InputGroup.Text>
              <InputMask
                mask="99/99/9999"
                className={renderClaimClass('period_start', 'DATE', 'patient-date')}

                value={formik.values?.claims[index]?.period_start}

                name={`claims[${index}].period_start`}
                placeholder={"mm/dd/yyyy"}
              />
            </InputGroup>
          </div>
          <div className="wrapper">
            <InputGroup>
              <InputGroup.Text>Period End</InputGroup.Text>
              <InputMask
                mask="99/99/9999"
                className={renderClaimClass('period_end', 'DATE', 'patient-date')}


                value={formik.values?.claims[index]?.period_end}
                placeholder={"mm/dd/yyyy"}
              />
            </InputGroup>
          </div>
          <div className="wrapper">
            <InputGroup>
              <InputGroup.Text>Status Code</InputGroup.Text>
              <Form.Control
                as="select"
                name={`claims[${index}].status_code`}

                value={
                  formik.values?.claims &&
                  formik.values?.claims[index]?.status_code
                }

                className={renderClaimClass('status_code', 'NUMBER', 'selc-bg-2')}

                aria-label="Default select example"
              >
                <option value="">Select an Option</option>
                <option value="1">1 Primary</option>
                <option value="2">2 Secondary</option>
                <option value="3">3 Tertiary</option>
                <option value="4">4 Denial</option>
                <option value="22">22 Reverse</option>
              </Form.Control>
            </InputGroup>
          </div>
          {qdxTransaction != "Correspondence" &&
            <React.Fragment>
              <div className="wrapper">
                <InputGroup>
                  <InputGroup.Text>MRN #</InputGroup.Text>
                  <div className="dbk-main-container">

                    <Form.Control
                      type="text"
                      name={`claims[${index}].mrn`}

                      value={formik.values?.claims[index]?.mrn}
                      className={renderClaimClass('mrn', 'TEXT', 'w-mrn')}
                      data-corress-disable={disableCorresField('mrn')}
                    />


                  </div>
                </InputGroup>
              </div>
              <div className="wrapper">
                <InputGroup>
                  <InputGroup.Text>Statement #</InputGroup.Text>
                  <div className="dbk-main-container">

                    <Form.Control
                      type="text"
                      name={`claims[${index}].statement_number`}
                      value={formik.values?.claims[index]?.statement_number}
                      className={renderClaimClass('statement_number', 'TEXT', 'w-statement-number')}
                      data-corress-disable={disableCorresField('statement_number')}
                    />

                  </div>
                </InputGroup>
              </div>
            </React.Fragment>
          }
          <div className="">
            <InputGroup>
              <InputGroup.Text>From</InputGroup.Text>
              <Form.Control
                type="number"
                min={1}
                name={`claims[${index}].page_from`}
                value={formik.values?.claims[index]?.page_from}
                className={renderClaimClass('page_from', 'NUMBER', 'pagefrom')}

              />
            </InputGroup>
          </div>
          <div className="">
            <InputGroup>
              <InputGroup.Text>To</InputGroup.Text>
              <Form.Control
                type="number"
                name={`claims[${index}].page_to`}
                min={1}

                value={formik.values?.claims[index]?.page_to}
                className={renderClaimClass('page_to', 'NUMBER', 'pageto')}

              />
            </InputGroup>
          </div>
        </div>

        {formik.values?.claims[index]?.patient ? (
          <div className="sub-section">
            <div className="">
              <div className="d-flex align-items-center label-claim">
                <button
                  type="button"
                  tabIndex={"-1"}
                  className="btn btn-outline-danger"
                  onClick={__removePatient}
                >
                  ×
                </button>
                <span>

                  Patient{" "}
                </span>
              </div>
            </div>
            <div className="wrapper-patient">
              <InputGroup>
                <InputGroup.Text>First Name</InputGroup.Text>
                <div className="dbk-main-container">
                  <Form.Control
                    id="pan-focus"
                    type="text"
                    name={`claims[${index}].patient_first_name`}



                    value={
                      formik.values?.claims &&
                      formik.values?.claims[index]?.patient_first_name
                    }
                    className={renderClaimClass('patient_first_name', 'TEXT', 'w-fsname')}

                  />

                </div>
              </InputGroup>
            </div>
            <div className="wrapper-miname">
              <InputGroup>
                <InputGroup.Text>Middle Name</InputGroup.Text>
                <div className="dbk-main-container">
                  <Form.Control
                    type="text"
                    name={`claims[${index}].patient_middle_name`}
                    className={renderClaimClass('patient_middle_name', 'TEXT', 'w-miname')}

                    value={
                      formik.values?.claims &&
                      formik.values?.claims[index]?.patient_middle_name
                    }
                    data-corress-disable={disableCorresField('patient_middle_name')}
                  />

                </div>
              </InputGroup>
            </div>
            <div className="wrapper-patient">
              <InputGroup>
                <InputGroup.Text>Last Name</InputGroup.Text>
                <div className="dbk-main-container">
                  <Form.Control
                    name={`claims[${index}].patient_last_name`}

                    className={renderClaimClass('patient_last_name', 'TEXT', 'w-fsname')}

                    value={
                      formik.values?.claims &&
                      formik.values?.claims[index]?.patient_last_name
                    }

                  />

                </div>
              </InputGroup>
            </div>
            <div className="wrapper-patient">
              <InputGroup>
                <InputGroup.Text>Patient Identifier</InputGroup.Text>
                <div className="dbk-main-container">
                  <Form.Control
                    name={`claims[${index}].patient_identifier_code`}
                    className={renderClaimClass('patient_identifier_code', 'TEXT', 'w-fsname')}

                    value={
                      formik.values?.claims &&
                      formik.values?.claims[index]?.patient_identifier_code
                    }
                  />


                </div>
              </InputGroup>
            </div>
          </div>
        ) : (
          ""
        )}
        {formik.values?.claims[index]?.subscriber ? (
          <div className="sub-section">
            <div className="">
              <div className="d-flex align-items-center label-claim">
                <button
                  type="button"
                  tabIndex={"-1"}
                  className="btn btn-outline-danger"
                  onClick={__removeSubscriber}
                >
                  ×
                </button>{" "}
                <span>

                  Subscriber{" "}
                </span>
              </div>
            </div>
            <div className="wrapper-patient">
              <InputGroup>
                <InputGroup.Text>First Name</InputGroup.Text>
                <div className="dbk-main-container">
                  <Form.Control
                    name={`claims[${index}].subscriber_first_name`}
                    className={renderClaimClass('subscriber_first_name', 'TEXT', 'w-fsname')}
                    value={
                      formik.values?.claims &&
                      formik.values?.claims[index]?.subscriber_first_name
                    }
                    data-corress-disable={disableCorresField('subscriber_first_name')}
                  />
                </div>
              </InputGroup>
            </div>
            <div className="wrapper-miname">
              <InputGroup>
                <InputGroup.Text>Middle Name</InputGroup.Text>
                <div className="dbk-main-container">
                  <Form.Control
                    name={`claims[${index}].subscriber_middle_name`}

                    value={
                      formik.values?.claims &&
                      formik.values?.claims[index]?.subscriber_middle_name
                    }
                    className={renderClaimClass('subscriber_middle_name', 'TEXT', 'w-miname')}
                    data-corress-disable={disableCorresField('subscriber_middle_name')}

                  />

                </div>
              </InputGroup>
            </div>
            <div className="wrapper-patient">
              <InputGroup>
                <InputGroup.Text>Last Name</InputGroup.Text>
                <div className="dbk-main-container">
                  <Form.Control
                    name={`claims[${index}].subscriber_last_name`}
                    value={
                      formik.values?.claims &&
                      formik.values?.claims[index]?.subscriber_last_name
                    }
                    className={renderClaimClass('subscriber_last_name', 'TEXT', 'w-fsname')}
                    data-corress-disable={disableCorresField('subscriber_last_name')}
                  />

                </div>
              </InputGroup>
            </div>
            <div className="wrapper-patient">
              <InputGroup>
                <InputGroup.Text>Subscriber Identifier</InputGroup.Text>
                <div className="dbk-main-container">
                  <Form.Control
                    name={`claims[${index}].subscriber_identifier_code`}

                    value={
                      formik.values?.claims &&
                      formik.values?.claims[index]?.subscriber_identifier_code
                    }
                    className={renderClaimClass('subscriber_identifier_code', 'TEXT', 'w-fsname')}
                    data-corress-disable={disableCorresField('subscriber_identifier_code')}
                  />

                </div>
              </InputGroup>
            </div>
          </div>
        ) : (
          ""
        )}
        {formik.values?.claims[index]?.rendering_provider ? (
          <div className="sub-section">
            <div className="">
              <div className="d-flex align-items-center label-claim">
                <button
                  tabIndex={"-1"}
                  type="button"
                  className="btn btn-outline-danger"
                  onClick={__removeRenderingprovider}
                >
                  ×
                </button>{" "}
                <span>

                  Rendering Provider{" "}
                </span>
              </div>
            </div>
            <div className="wrapper-patient">
              <InputGroup>
                <InputGroup.Text>First Name</InputGroup.Text>
                <div className="dbk-main-container">
                  <Form.Control
                    name={`claims[${index}].rendering_provider_first_name`}
                    value={
                      formik.values?.claims &&
                      formik.values?.claims[index]
                        ?.rendering_provider_first_name
                    }
                    className={renderClaimClass('rendering_provider_first_name', 'TEXT', 'w-fsname')}

                    disabled={isActiveDbkField(eobFocus, formik, isDbkPermission?.payment?.payment_rendering_provider_first_name) ? !eobFocus.claims[index]?.rendering_provider_first_name : false}
                    data-corress-disable={disableCorresField('rendering_provider_first_name')}
                  />

                </div>
              </InputGroup>
            </div>
            <div className="wrapper-patient">
              <InputGroup>
                <InputGroup.Text>Last Name</InputGroup.Text>
                <div className="dbk-main-container">
                  <Form.Control
                    name={`claims[${index}].rendering_provider_last_name`}

                    value={
                      formik.values?.claims &&
                      formik.values?.claims[index]?.rendering_provider_last_name
                    }
                    className={renderClaimClass('rendering_provider_last_name', 'TEXT', 'w-fsname')}
                    data-corress-disable={disableCorresField('rendering_provider_last_name')}

                  />

                </div>
              </InputGroup>
            </div>
            <div className="wrapper-rendering">
              <InputGroup>
                <InputGroup.Text>Rendering Provider Identifier</InputGroup.Text>
                <div className="dbk-main-container">
                  <Form.Control
                    type="text"
                    name={`claims[${index}].rendering_provider_identifier_code`}

                    value={
                      formik.values?.claims &&
                      formik.values?.claims[index]
                        ?.rendering_provider_identifier_code
                    }
                    className={renderClaimClass('rendering_provider_identifier_code', 'TEXT', 'w-patient')}
                    data-corress-disable={disableCorresField('rendering_provider_identifier_code')}


                  />


                </div>
              </InputGroup>
            </div>
          </div>
        ) : (
          ""
        )}
        {qdxTransaction == "PATIENT_PAY" &&
          <div className="sub-section">
            <div className="">
              <div className="label-claim">

                Payment Details{" "}
              </div>
            </div>
            <div className="wrapper-patient">
              <InputGroup>
                <InputGroup.Text>Charge Amount</InputGroup.Text>
                <div className="dbk-main-container1">
                  <Form.Control
                    name={`claims[${index}].claim_billed`}
                    type="number"


                    value={formik.values?.claims[index]?.claim_billed}

                    className={renderClaimClass('claim_billed', 'NUMBER', 'serviceline-btn text-end')}

                  />

                </div>
              </InputGroup>
            </div>
            <div className="wrapper-patient">
              <InputGroup>
                <InputGroup.Text>Paid Amount</InputGroup.Text>
                <div className="dbk-main-container1">
                  <Form.Control
                    type="number"

                    name={`claims[${index}].claim_paid`}

                    value={formik.values?.claims[index]?.claim_paid}
                    className={renderClaimClass('claim_paid', 'NUMBER', 'serviceline-btn text-end')}

                  />

                </div>
              </InputGroup>
            </div>
            <div className="wrapper-statement-date">
              <InputGroup>
                <InputGroup.Text>Statement Date</InputGroup.Text>
                <div className="dbk-main-container">

                  <InputMask
                    mask="99/99/9999"

                    style={{ fontSize: "0.7em" }}
                    className={renderClaimClass('statement_date', 'DATE', 'patient-date')}


                    value={formik.values?.claims[index]?.statement_date}

                    name={`claims[${index}].statement_date`}
                    placeholder={"mm/dd/yyyy"}
                  />

                </div>
              </InputGroup>
            </div>

          </div>
        }
      </div>
    </React.Fragment>
  );
};

export default Claims;
