import React from 'react'
import Chart from 'react-apexcharts';
import BarSkelitonLoader from '../../loader/BarSkelitonLoader';

const AllReport = ({ isVisible, data, handleClickData }) => {

    const options = {
        dataLabels: {
            enabled: true,
            style: {
                colors: ['black']
            },
            dropShadow: {
                enabled: false,
                enabledOnSeries: undefined,
                top: 0,
                left: 0,
                blur: 3,
                opacity: 0.35
            }
        },

        fill: {
            type: 'gradient',
        },
        chart: {
            id: 'basic-bar',
            type: 'bar',
            stacked: true,
            toolbar: {
                show: false
            },
            fontFamily: 'Open Sans',
            events: {
                dataPointSelection: handleClickData
            }
        },
        grid: {
            borderColor: '#E4E4E4',
            xaxis: {
                lines: {
                    show: false
                }
            },
            yaxis: {
                lines: {
                    show: true
                }
            },
        },
        plotOptions: {
            bar: {
                columnWidth: '40px'
            },
        },
        legend: {
            show: true
        },
        noData: {
            text: 'No data available',
        },
        colors: ['#b2d3a3', '#9cadda', '#ffd993', '#c7d07b', '#f6b89d', '#abc7e7', '#969696', '#24B2DD', '#FFB11F', '#D98383'],
        xaxis: {
            categories: data?.my_work ? Object.values(data?.my_work)?.map(item => item?.[0]?.client_name) : [],
            axisBorder: {
                show: true,
                color: '#000000',
                height: 1,
                width: '100%',
                offsetX: 0,
                offsetY: 0
            },
            labels: {
                show: true,
                trim: true,
                hideOverlappingLabels: false,
                rotate: 0
            }
        },
        yaxis: {
            axisBorder: {
                show: true,
                color: '#000000',
                height: '100%',
                width: 1,
                offsetX: 0,
                offsetY: 0
            },
        },
    };
    const labels = Object.keys(data)
    const series = labels?.map(item => { return { name: item == 'my_work' ? 'My Work' : 'QA', data: Object.values(data[item]).map(val => item == 'my_work' ? val?.[0]?.total_time : val[0]?.qa_total_time) } })
    return (
        isVisible ?
            <BarSkelitonLoader report="time-report" /> :
            <Chart options={options} series={series} type="bar" height={'90%'} width={(series?.[0]?.data?.length ?? 1) * 50 + 500 > 1000 ? ((series?.[0]?.data?.length ?? 1) * 50 + 500) : '100%'} />
    )
}

export default AllReport