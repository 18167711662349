import request from "../api/agent";
const API_ROUTE = "auth";

export class AuthService {
    async userLogin(data) {
        const action = `${API_ROUTE}/login`;
        const response = await request.post(action, data);
        return response.data ?? response;
    }
    async userLogout(data) {
        const action = `${API_ROUTE}/logout`;
        const response = await request.post(action, data);
        return response.data;
    }
    async changePasswordUser(arg) {
        const action = `users/reset-password-by-user`;
        const response = await request.post(action, arg);
        return response.data ?? response;
    }
    async userPermissions(group) {
        console.log(group);
        const action = `${API_ROUTE}/permission-list`;
        const response = await request.post(action, group);
        return response.data;
    }
    async syncXtractAnalytics(data) {
        const action = `reports/sync-report-details`;
        const response = await request.post(action);
        console.log(response)
        return response.response;
    }
    async syncXtractAnalyticsRefresh(data) {
        const action = `reports/sync-times`;
        const response = await request.get(action);
        return response.data;
    }
    async keepAliveApi() {
        const action = `users/keep-alive`;
        const response = await request.get(action);
        return response.data;
    }
}