import logo from './logo.svg';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import "./assets/css/custom.css";
import "./assets/css/main.css";
import "./assets/css/sidebar.css";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "/node_modules/bootstrap/dist/js/bootstrap.min.js";
// import "react-datepicker/dist/react-datepicker.css";
import "../src/assets/vendor/bootstrap-icons/bootstrap-icons.css";
import '@wojtekmaj/react-datetimerange-picker/dist/DateTimeRangePicker.css';
import "./assets/css/dashboard.css";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "/node_modules/bootstrap/dist/js/bootstrap.min.js";
import "../src/assets/vendor/bootstrap-icons/bootstrap-icons.css";
import 'rsuite/dist/rsuite.min.css';


import Login from './views/authentication/Login';
import AdminPane from './views/components/AdminPanel';

function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/*' element={<AdminPane />} />
        <Route exact path='/login' element={<Login />} />

        {/* News */}


      </Routes>
    </BrowserRouter>
  );
}

export default App;
