import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import styled from 'styled-components/macro';
import { UserApiService } from '../../../services/UserApiService';

function ChangePasswordForm({ __passwordToast, closeModal, UserId }) {


    const userApiService = new UserApiService()
    const { changePasswordAdmin } = userApiService

    const [isSubmit, setIsSubmit] = useState(false)
    const [loading, setLoading] = useState(false)
    const [showPassword, setShowPassword] = useState(false)


    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            new_password: "",
        },
        validationSchema: Yup.object().shape({
            new_password: Yup.string().required('Please Enter your password')
                .matches(
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                    "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Character"
                ),
            // .min(3, 'Password is too short - should be 3 chars minimum.'),
        }),
        onSubmit: (values) => {
            setLoading(true)
            setIsSubmit(true)

            // console.log(values,"###");
            let formData = new FormData();    //formdata object

            formData.append('new_password', values.new_password);
            formData.append('user', UserId);

            changePasswordAdmin(formData).then((response) => {
                if (!response?.data?.status && response?.status && response?.status_code === 201) {

                    __passwordToast("success", response?.message, true)
                    setIsSubmit(true)
                    setLoading(false)


                } else {
                    // alert('error')

                    // const errors = response?.response?.data?.errors;
                    formik.setErrors({
                        ...formik,
                        new_password: response?.response?.data?.errors?.new_password,

                    })
                    setLoading(false)

                    setIsSubmit(false)

                }
            });
        },
    });


    // const __toggleToast = () => {
    //     setState({ showToast: !state.showToast })

    // }



    return (
        <React.Fragment>

            <Form
                onSubmit={formik.handleSubmit}
            >
                <TopHeadPassword>
                    <span onClick={() => { closeModal() }} className='close-icon'><i className="bi bi-x-square"></i></span>
                    <Form.Label className='fw-bold clr-heading' >Change Password</Form.Label>
                </TopHeadPassword>

                <div className="mb-3 row border-bottom-dashed" >


                    <div className='col-lg-12'>

                        <Form.Group className="mb-3" >

                            <Form.Label className='fw-bold'>New Password</Form.Label>
                            <Form.Control
                                name='new_password'
                                type={showPassword ? "text" : "password"}
                                onChange={formik.handleChange}
                                // onBlur={formik.handleBlur}
                                autoComplete="false"
                                isValid={formik.touched?.new_password && !formik.errors?.new_password}
                                isInvalid={!!formik.errors?.new_password}
                                value={formik.values?.new_password}
                                className='b-r-10 form-control-solid'
                                // 
                                placeholder="New Password"
                            />
                            <div className='' style={formik.errors?.new_password ? { position: 'absolute', top: '96px', right: '60px', cursor: 'pointer' } : { position: 'absolute', top: '96px', right: '35px', cursor: 'pointer' }} onClick={() => setShowPassword(!showPassword)}  >
                                <i className={showPassword ? 'bi bi-eye' : "bi bi-eye-slash"}></i>
                            </div>
                            <Form.Control.Feedback type="invalid"> {formik.errors?.new_password}</Form.Control.Feedback>


                        </Form.Group>

                    </div>
                </div>

                <div className='d-flex flex-row justify-content-end'>

                    <div className=''>
                        <button
                            disabled={!formik.isValid}
                            type='submit'
                            className='btn btn-light btn-active-primary'
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </Form>
        </React.Fragment>
    )
}

export default ChangePasswordForm

const TopHeadPassword = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    margin-bottom:10px;
    span.close-icon{
        cursor: pointer;
    }
`;