import React from 'react'
import { Table } from 'react-bootstrap'

const ClaimSummary = (props) => {

    const { formik, jumpToPage, setClaim835, setClaim835Index, setState } = props;


    const handleClaimBilledTotal = (array) => {
        const sum = array.reduce((acc, item) => acc + parseFloat(item.claim_billed ? item.claim_billed : 0), 0)
        return parseFloat(sum).toFixed(2);
    }

    const handleClaimPaidTotal = (array) => {
        const sum = array.reduce((acc, item) => acc + parseFloat(item.claim_paid ? item.claim_paid : 0), 0)
        return parseFloat(sum).toFixed(2);
    }
    const handleSlClaimTotal = (claim) => {
        console.log(claim);
        const diff = (parseFloat(claim.claim_billed ? claim.claim_billed : 0) - parseFloat(claim.claim_paid ? claim.claim_paid : 0))

        return parseFloat(diff).toFixed(2);
    }
    const handleSlAdj = (array) => {


        let sum = array.reduce((total, item) => total + (parseFloat(item?.discount ? item?.discount : 0)
            + parseFloat(item?.cob ? item?.cob : 0)
            + item?.adjustments?.reduce((adjTotal, adjustment) => adjTotal + (adjustment && adjustment?.group_code != "PR" ?
                parseFloat(adjustment?.amount ? adjustment?.amount : 0) : 0), 0)), 0)

        if (sum) {

            return parseFloat(sum).toFixed(2);
        }
        else {
            return parseFloat(0).toFixed(2);
        }

    }
    const handleDiffFromClaimBalance = (claims) => {

        var claim_balance = [];
        claims.forEach((claim, index) => {
            claim_balance.push(claim?.claim_balance)
        })


        let totalbalance = claim_balance.reduce((acc, item) => acc - parseFloat(item ? item : 0), 0)
        console.log(totalbalance);
        let diff = parseFloat(formik.values.check_amount ? formik.values.check_amount : 0).toFixed(2) - parseFloat(totalbalance ? totalbalance : 0).toFixed(2)

        if (diff) {
            return parseFloat(diff).toFixed(2);
        }
        else {
            return parseFloat(0).toFixed(2);

        }

    }

    const handleRowClicked = (row) => {
        jumpToPage(row?.page_to - 1)
        // setClaim835Index(index)

    }

    const handleRowDoubleClicked = (row, index) => {
        setState((prevState) => {
            return {
                ...prevState,
                updateDisable: !prevState.updateDisable,
            }
        })
        setClaim835(false)
        jumpToPage(row?.page_to - 1)
        setClaim835Index(index)

    }


    const calculateClaimPRCost = (claim) => {

        // const deductible = claim?.claim_deduct ?? 0;
        // const coins = claim?.claim_coins ?? 0;
        // const copay = claim?.claim_copay ?? 0;
        // const adjustments = claim?.claim_adjustments.map((adjustment) => {
        //     return adjustment.group_code === "PR" ? adjustment.amount : 0;
        // });

        // return parseFloat(deductible + coins + copay + adjustments).toFixed(2);

        console.log(claim);

        const sum = (claim?.claim_adjustments || [])
            .filter(adjustment => adjustment.group_code === 'PR')
            .reduce((accumulator, adjustment) => accumulator + parseFloat(adjustment.amount ? adjustment.amount : 0), 0);


        const result = parseFloat(parseFloat(claim?.claim_deduct ? claim?.claim_deduct : 0) + parseFloat(claim?.claim_coins ? claim?.claim_coins : 0) + parseFloat(claim?.claim_copay ? claim?.claim_copay : 0) + parseFloat(sum));

        return parseFloat(result).toFixed(2)
    };
    const calculateClaimCost = (claim) => {

        // const claimDiscounts = claim.claim_discount ? claim.claim_discount : 0;
        // const claimCOBs = claim.claim_cob ? claim.claim_cob : 0;
        // const claimAdjustments = claim.claim_adjustments.map((adjustment) => {
        //     return adjustment.amount > 0 && adjustment.group_code != "PR" ? adjustment.amount : 0;
        // });
        // const totalAdjustments = claimDiscounts + claimCOBs + claimAdjustments;
        // return parseFloat(totalAdjustments).toFixed(2);

        const sum = (claim?.claim_adjustments || [])
            .filter(adjustment => adjustment.group_code !== 'PR')
            .reduce((accumulator, adjustment) => accumulator + parseFloat(adjustment.amount ? adjustment.amount : 0), 0);


        const result = parseFloat(parseFloat(claim?.claim_discount ? claim?.claim_discount : 0) + parseFloat(claim?.claim_cob ? claim?.claim_cob : 0) + parseFloat(sum));

        return parseFloat(result).toFixed(2)
    };

    return (
        <React.Fragment>
            <div className="topblock-wrapper eob_block accordion-collapse collapse show" id="claimssummary" aria-labelledby="claimssummary" data-bs-parent="#claimSummary">
                <div className="">
                    <div className='table-responsive'>
                        <Table striped bordered hover >
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th style={{ width: '17rem' }}>Patient Account Number</th>
                                    <th style={{ width: '17rem' }}>Payer Claim Number</th>
                                    <th style={{ width: '10rem' }}>Status Code</th>
                                    <th style={{ width: '5rem' }} className='text-end'>Billed </th>
                                    <th style={{ width: '8rem' }} className='text-end'>Paid Amount</th>
                                    <th className='text-end'>CL Adj(PR)</th>
                                    <th className='text-end'>SL Adj(PR)</th>
                                    <th className='text-end'>CL Adj</th>
                                    <th className='text-end'>SL Adj</th>
                                    <th className='text-end'>Diff</th>
                                </tr>
                            </thead>
                            <tbody>
                                {formik.values?.claims && formik.values?.claims.length > 0 && formik.values?.claims?.map((claim, index) => (


                                    <tr onClick={() => handleRowClicked(claim)} onDoubleClick={() => handleRowDoubleClicked(claim, index)} key={index}>

                                        <td>{index + 1}</td>
                                        <td className='text-uppercase'>{claim?.patient_account_number ? claim?.patient_account_number : "---------"}</td>
                                        <td className='text-uppercase'>{claim?.payer_claim_number ? claim?.payer_claim_number : "---------"}</td>
                                        <td>{claim?.status_code ? claim?.status_code : "---------"}</td>
                                        <td className='text-end'>{claim?.claim_billed ? parseFloat(claim?.claim_billed).toFixed(2) : parseFloat(0).toFixed(2)}</td>
                                        <td className='text-end'>{claim?.claim_paid ? parseFloat(claim?.claim_paid).toFixed(2) : parseFloat(0).toFixed(2)}</td>
                                        <td className='text-end'>
                                            {calculateClaimPRCost(claim)}

                                            {/* {claim?.claim_deduct > 0 && claim?.claim_coins > 0 && claim?.claim_copay > 0 ? parseFloat(parseFloat(claim?.claim_deduct ? claim?.claim_deduct : 0) + parseFloat(claim?.claim_coins ? claim?.claim_coins : 0) + parseFloat(claim?.claim_copay ? claim?.claim_copay : 0) + parseFloat(claim?.claim_adjustments.map((adjustment, index) => (
                                                adjustment.group_code == "PR" ? parseFloat(adjustment.amount ? adjustment.amount : 0) : 0
                                            )))).toFixed(2) : parseFloat(0).toFixed(2)}xvxc */}
                                        </td>
                                        <td className='text-end'> {claim?.sl_patres > 0 ? parseFloat(claim?.sl_patres).toFixed(2) : parseFloat(0).toFixed(2)}</td>
                                        <td className='text-end'>

                                            {/* {claim?.claim_discount > 0 && claim?.claim_cob > 0 ? parseFloat(parseFloat(claim?.claim_discount ? claim?.claim_discount : 0) + parseFloat(claim?.claim_cob ? claim?.claim_cob : 0) + parseFloat(claim?.claim_adjustments.map((adjustment, index) => (
                                                adjustment.amount > 0 && adjustment.group_code != "PR" ? parseFloat(adjustment.amount ? adjustment.amount : 0) : parseFloat(0)
                                            )))).toFixed(2) : parseFloat(0).toFixed(2)} */}

                                            {calculateClaimCost(claim)}
                                        </td>
                                        <td className='text-end'>

                                            {handleSlAdj(claim?.service_lines)}
                                            {/* {claim?.service_lines && claim?.service_lines.length > 0 && claim?.service_lines?.map((servicefield, index) => (
                                                <span>
                                                    {servicefield?.discount > 0 && servicefield?.cob > 0 ? parseFloat(parseFloat(servicefield?.discount) + parseFloat(servicefield?.cob) + parseFloat(servicefield?.adjustments?.map((adjustment, index) => (
                                                        adjustment?.group_code != "PR" ? parseFloat(adjustment.amount) : 0)
                                                    ))).toFixed(2) : 0}
                                                </span>

                                            ))} */}
                                        </td>
                                        {/* <td> {claim?.sl_discount > 0 && claim?.sl_cob > 0 ? parseFloat(claim?.sl_discount) + parseFloat(claim?.sl_cob) + parseFloat(claim?.claim_adjustments.map((adjustment, index) => (
                                            adjustment.amount > 0 && adjustment.group_code != "PR" ? parseFloat(adjustment.amount) : 0
                                        ))) : 0}</td> */}
                                        {/* <td className='text-end'>{handleSlClaimTotal(claim)}</td> */}
                                        <td className='text-end'>{parseFloat(claim?.claim_balance ? claim?.claim_balance : 0).toFixed(2)}</td>

                                    </tr>

                                ))}
                                <tr className='border-top-bottom-dark-2'>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr className=''>

                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td className='fw-bolder text-end table-padding'>Claim Total:</td>
                                    <td className='fw-bolder table-padding text-end'>{handleClaimBilledTotal(formik.values?.claims)}</td>
                                    <td className='fw-bolder table-padding text-end'>{handleClaimPaidTotal(formik.values?.claims)}</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr className=''>

                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td className='fw-bolder text-end table-padding'>Check Total:</td>
                                    <td ></td>
                                    <td className='fw-bolder table-padding text-end'>{formik.values?.check_amount > 0 ? parseFloat(formik.values?.check_amount ? formik.values?.check_amount : 0).toFixed(2) : parseFloat(0).toFixed(2)}</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr className=''>

                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td className='fw-bolder text-end table-padding'>Diff:</td>
                                    <td ></td>
                                    <td className='fw-bolder table-padding text-end'>
                                        {/* {handleDiffFromClaimBalance(formik.values?.claims)} */}
                                        {formik.values?.remaining_balance}
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                {formik.values?.plb_adjustments?.map((plb, index) => (
                                    <tr className='' key={index}>

                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td className='fw-bolder text-end table-padding'>PLB Adj diff:</td>
                                        <td ></td>
                                        <td className='fw-bolder table-padding text-end'>{isNaN(plb?.plb_amt) ? 0 : plb?.plb_amt ? parseFloat(plb.plb_amt ? plb.plb_amt : 0).toFixed(2) : parseFloat(0).toFixed(2)}</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                ))}
                                {/* <tr className=''>

                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td className='fw-bolder text-end table-padding'>Diff:</td>
                                    <td ></td>
                                    <td className='fw-bolder table-padding'>{formik.values?.remaining_balance > 0 ? parseFloat(formik.values?.remaining_balance).toFixed(2) : parseFloat(0).toFixed(2)}</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr> */}

                            </tbody>
                        </Table>
                        <Table striped bordered hover >
                            <thead>
                                <th></th>
                                <th> </th>
                                <th> </th>
                                <th> </th>
                                <th> </th>
                                <th> </th>
                                <th> </th>
                                <th> </th>
                                <th> </th>
                                <th> </th>
                                <th></th>
                            </thead>
                            <tbody>

                            </tbody>
                        </Table>
                    </div>
                    {/* <div className='d-flex flex-column align-items-center justify-content-center'>
                        <div className='py-2  border-top-bottom-dark-2'>
                            <div className=''>
                                <div className='d-flex flex-row justify-content-center'>
                                    <span className=' fw-bolder' >Claim Total</span>
                                    <span className=' fw-bolder ms-3' >-321.79</span>
                                    <span className=' fw-bolder ms-5' >-68.74</span>
                                </div>
                            </div>
                        </div>
                        <div className='py-2  border-top-bottom-dark-2'>
                            <div className=''>
                                <div className='d-flex flex-row justify-content-center'>
                                    <span className=' fw-bolder' >Claim Total</span>
                                    <span className=' fw-bolder ms-3' >-321.79</span>
                                    <span className=' fw-bolder ms-5' >-68.74</span>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>

        </React.Fragment>
    )
}

export default ClaimSummary