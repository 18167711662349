import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  message: "",
  is_show: false
};

const errorSlice = createSlice({
  name: "error",
  initialState,
  reducers: {
    seErrorMessage: (state, action) => {
      state.message = action.payload.message;
      state.is_show = action.payload.is_show;
    },

    setErrorMessageClear: (state) => {
      state.message = null;
      state.is_show = false;
    },
  },
});

export const { seErrorMessage, setErrorMessageClear } = errorSlice.actions;

export default errorSlice.reducer;
