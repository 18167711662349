import React from 'react'
import Chart from 'react-apexcharts';
import styled from "styled-components/macro";
import BarSkelitonLoader from '../loader/BarSkelitonLoader';

const FileReportChart = ({ loaderState, data }) => {
    const options = {
        dataLabels: {
            enabled: true,
            style: {
                colors: ['black']
            },
            dropShadow: {
                enabled: false,
                enabledOnSeries: undefined,
                top: 0,
                left: 0,
                blur: 3,
                opacity: 0.35
            }
        },


        fill: {
            type: 'gradient',
        },
        chart: {
            id: 'basic-bar',
            type: 'bar',
            fontFamily: 'Open Sans',
            toolbar: {
                show: false
            },
        },
        grid: {
            borderColor: '#E4E4E4',
            xaxis: {
                lines: {
                    show: false
                }
            },
            yaxis: {
                lines: {
                    show: true
                }
            },
        },
        plotOptions: {
            bar: {
                columnWidth: '40px'
            },
        },
        legend: {
            show: false
        },
        noData: {
            text: 'No data available',
        },
        colors: ['#b2d3a3', '#9cadda', '#ffd993', '#c7d07b', '#f6b89d', '#abc7e7', '#969696', '#24B2DD', '#FFB11F', '#D98383'],

        // colors: ['#E5A4C3', '#70D6F5', '#FFB87D', '#A86AB2', '#6497AD', '#C9D072', '#D98383', '#24B2DD', '#FFB11F', '#D98383'],
        xaxis: {
            categories: data?.client_name ?? [],
            axisBorder: {
                show: true,
                color: '#000000',
                height: 1,
                width: '100%',
                offsetX: 0,
                offsetY: 0
            },
            labels: {
                show: true,
                trim: true,
                hideOverlappingLabels: false,
                rotate: 0
            }
        },
        yaxis: {
            axisBorder: {
                show: true,
                color: '#000000',
                height: '100%',
                width: 1,
                offsetX: 0,
                offsetY: 0
            },
        },
    };

    const series = [
        {
            name: 'Lockbox Count',
            data: data?.lockbox_count ?? []
        },
        {
            name: 'Lockbox Files Count',
            data: data?.lockboxfiles_count ?? []
        },
        {
            name: 'Job Count',
            data: data?.job_count ?? []
        },
    ];

    return (
        <div className='card  p-2'>

            <div className='mt-2' style={{ fontWeight: '500', fontSize: '20px', marginLeft: '30px', color: '#012970' }}>File Report</div>
            <div style={{ height: '45vh', overflow: 'auto' }}>
                {loaderState.fileReportLoader ?
                    <BarSkelitonLoader /> :
                    <Chart options={options} series={series} type="bar" height={'90%'} width={(series?.[0]?.data?.length ?? 3) * 100 + 500 > 1000 ? ((series?.[0]?.data?.length ?? 3) * 100 + 500) : '100%'} />
                }
            </div>
        </div>
    )
}

export default FileReportChart

