import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import search from "../../../../../assets/img/searchicon.png";
import InputMask from "react-input-mask";
import { handleKeyPress, handleWheel } from "../gridFunctions";
import { DbkField } from "../Elements/DbkField";
import { DATA_ENTRY, EDIT_WORK, QA_REVIEW, isActiveDbkField } from "../../../../../functions";
import moment from "moment";

const Lite = (props) => {
  const {
    formik,

    setPayeeLiteFocus,
    payeeLiteFocus,
    // DBK
    totalPages,

    jobData,
    isPayerPage,
    isPayeePage,
    pdfLoaded,
    isViewOnllyGrid,
    job_id,
    log_id
  } = props;



  const pathname = window.location.pathname;
  const gridData = jobData?.grid_data;
  const qaCorrectedData = gridData?.qa_corrected_data;
  const renderClass = (name, type, defaultClass) => {
    if (formik.errors?.[name]) {
      return defaultClass + ' error-field'
    }
    if (pathname == QA_REVIEW) {

      if (qaCorrectedData &&
        qaCorrectedData?.[name]) {
        return defaultClass + ' qa-field'
      }
      else if (type == 'DATE' && moment(formik.values?.[name]).format("MM/DD/YYYY") != moment(gridData?.[name]).format("MM/DD/YYYY")) {
        return defaultClass + ' qa-field'
      }
      else if (type == 'TEXT' && formik.values?.[name]?.toUpperCase() !=
        gridData?.[name]?.toUpperCase()) {
        return defaultClass + ' qa-field'
      }
      else if (type == 'NUMBER' && parseFloat(formik.values?.[name] ? formik.values?.[name] : 0).toFixed(2) !=
        parseFloat(gridData?.[name] ?
          gridData?.[name] : 0).toFixed(2)) {
        return defaultClass + ' qa-field'
      }

      else if (gridData?.transaction_type != "PAYMENT835_LITE") {
        return defaultClass + ' qa-field'
      }
      else {
        return defaultClass;
      }


    }
    else if ((pathname == EDIT_WORK + job_id || pathname == EDIT_WORK + job_id + "?log_id=" + log_id) &&
      qaCorrectedData &&
      qaCorrectedData?.[name]) {
      return defaultClass + ' qa-field'
    }
    else {
      return defaultClass;
    }
  }
  const renderPlbClass = (name, type, defaultClass, index) => {
    if (formik.errors?.plb_adjustments &&
      formik.errors?.plb_adjustments[index] &&
      formik.errors?.plb_adjustments[index]?.[name]) {
      return defaultClass + ' error-field'
    }
    if (pathname == QA_REVIEW) {

      if (qaCorrectedData &&
        qaCorrectedData?.plb_adjustments &&
        qaCorrectedData?.plb_adjustments[index] &&
        qaCorrectedData?.plb_adjustments[index]?.[name]) {
        return defaultClass + ' qa-field'
      }
      else if (type == 'DATE' &&
        gridData?.plb_adjustments &&
        gridData?.plb_adjustments[index] &&
        moment(formik.values?.plb_adjustments[index]?.[name]).format("MM/DD/YYYY") != moment(gridData?.plb_adjustments[index]?.[name]).format("MM/DD/YYYY")) {
        return defaultClass + ' qa-field'
      }
      else if (type == 'TEXT' &&
        gridData?.plb_adjustments &&
        gridData?.plb_adjustments[index] &&
        formik.values?.plb_adjustments[index]?.[name]?.toUpperCase() !=
        gridData?.plb_adjustments[index]?.[name]?.toUpperCase()) {
        return defaultClass + ' qa-field'
      }
      else if (type == 'STRING' &&
        gridData?.plb_adjustments &&
        gridData?.plb_adjustments[index] &&
        formik.values?.plb_adjustments[index]?.[name] !=
        gridData?.plb_adjustments[index]?.[name]) {
        return defaultClass + ' qa-field'
      }
      else if (type == 'NUMBER' &&
        gridData?.plb_adjustments &&
        gridData?.plb_adjustments[index] &&
        parseFloat(formik.values?.plb_adjustments[index]?.[name] ? formik.values?.plb_adjustments[index]?.[name] : 0).toFixed(2) !=
        parseFloat(gridData?.plb_adjustments[index]?.[name] ?
          gridData?.plb_adjustments[index]?.[name] : 0).toFixed(2)) {
        return defaultClass + ' qa-field'
      }
      else if (gridData?.plb_adjustments &&
        !gridData?.plb_adjustments[index]) {
        return defaultClass + ' qa-field'
      }

      else if (gridData?.transaction_type != "PAYMENT835_LITE") {
        return defaultClass + ' qa-field'
      }
      else {
        return defaultClass;
      }


    }
    else if ((pathname == EDIT_WORK + job_id || pathname == EDIT_WORK + job_id + "?log_id=" + log_id) &&
      qaCorrectedData &&
      qaCorrectedData?.plb_adjustments &&
      qaCorrectedData?.plb_adjustments[index] &&
      qaCorrectedData?.plb_adjustments[index]?.[name]) {
      return defaultClass + ' qa-field'
    }
    else {
      return defaultClass;
    }
  }

  return (
    <React.Fragment>
      <div
        className="topblock-wrapper eoblite_block accordion-collapse collapse show"
        id="collapseOne"
        aria-labelledby="headingOne"
        data-bs-parent="#payment835"
      >
        <div className="sub-section">
          <div className="">
            <label className="btn btn-payment835">Payment835 Lite</label>
          </div>
          <div className="paymentmethod-wi">
            <InputGroup>
              <InputGroup.Text>Payment Method</InputGroup.Text>
              <Form.Control
                as="select"
                name="payment_method"
                onChange={formik.handleChange}
                value={formik.values?.payment_method}
                className={renderClass('payment_method', 'TEXT', 'selc-bg-1')}


              >
                <option value="0">Select an Option</option>
                <option value="ACH">ACH</option>
                <option
                  value="CHK"
                  selected={formik.values?.check_amount > parseFloat(0)}
                >
                  CHK
                </option>
                <option
                  value="NON"
                  selected={formik.values?.check_amount == parseFloat(0)}
                >
                  NON
                </option>
                <option
                  value="VCP"
                >
                  VCP
                </option>
              </Form.Control>
            </InputGroup>
          </div>
          <div className="checknum-wi">
            <InputGroup>
              <InputGroup.Text>Check Number</InputGroup.Text>
              <div className="dbk-main-container">
                <Form.Control
                  type="text"
                  className={renderClass('check_number', 'TEXT', 'w-78')}

                  name="check_number"
                  value={formik.values?.check_number}


                />


              </div>
            </InputGroup>
          </div>
          <div className="checkdate-wi">
            <InputGroup>
              <InputGroup.Text>Check Date</InputGroup.Text>
              <div className="dbk-main-container">
                <InputMask
                  mask="99/99/9999"
                  className={renderClass('check_date', 'DATE', 'w-48')}

                  style={{ fontSize: "0.7em" }}

                  value={formik.values?.check_date}
                  name="check_date"
                  placeholder={"mm/dd/yyyy"}

                />


              </div>
            </InputGroup>
          </div>
          <div className="checkamt-wi">
            <InputGroup>
              <InputGroup.Text>Check Amount</InputGroup.Text>
              <div className="dbk-main-container">
                <Form.Control
                  type="number"
                  value={formik.values?.check_amount}
                  className={renderClass('check_amount', 'NUMBER', 'w-78')}

                  name="check_amount"
                  pattern={/[0-9]+([\.][0-9]{2})?/}

                />



              </div>
            </InputGroup>
          </div>
          <div className="remainingbln-wi">
            <InputGroup>
              <InputGroup.Text>Remaining Balance</InputGroup.Text>
              <Form.Control
                type="number"


                value={formik.values?.remaining_balance}

                className={
                  "w-78 text-end"
                }
                name="check_amount"
                pattern={/[0-9]+([\.][0-9]{2})?/}
              />

            </InputGroup>
          </div>
        </div>

        <div className="sub-section">
          <div className="">
            <label className="btn btn-payer">Payer </label>
          </div>
          <div className="wrapper-payer">
            <InputGroup>
              <InputGroup.Text>
                Name{" "}
                <a
                  type="button"
                  className="payersearch"
                >
                  <img src={search} alt="" />
                </a>
                {/* Check View Pop-Up */}
              </InputGroup.Text>
              <div className="dbk-main-container">
                <Form.Control
                  type="text"

                  value={formik.values?.payer_name}

                  className={renderClass('payer_name', 'TEXT', 'w-payer text-uppercase')}

                  name="payer_name"

                />


              </div>
            </InputGroup>
          </div>
          {console.log(formik.errors)}
          {isPayerPage &&
            <div className='pagefrom-corress'>
              <div className="input-group">
                <label className="input-group-text">
                  Page From
                </label>
                <Form.Control
                  className={renderClass('payer_page_from', 'NUMBER', 'pagefrom')}

                  min={1}
                  type="number"
                  value={formik.values?.payer_page_from}
                  name="payer_page_from"
                />
              </div>
            </div>
          }
          {isPayerPage &&

            <div className='pageto-corress'>
              <div className="input-group">
                <label className="input-group-text">
                  Page To
                </label>
                <Form.Control
                  data-disabled={!pdfLoaded}
                  className={renderClass('payer_page_to', 'NUMBER', 'pageto')}

                  min={1}
                  type="number"
                  value={formik.values?.payer_page_to}
                  name={"payer_page_to"}
                />
              </div>
            </div>
          }

        </div>
        <div className="sub-section">
          <div className="">
            <label className="btn btn-payer">Payee </label>
          </div>
          <div className="wrapper-payer">
            <InputGroup>
              <InputGroup.Text>
                Name{" "}
                <a className="payersearch">
                  <img
                    src={search}
                    alt=""
                  />
                </a>
              </InputGroup.Text>
              <div className="dbk-main-container">

                <Form.Control
                  type="text"
                  className={renderClass('payee_name', 'TEXT', 'w-payer')}

                  onBlur={() => setPayeeLiteFocus(false)}
                  autoFocus={payeeLiteFocus}
                  value={formik.values?.payee_name}

                  name="payee_name"

                />



              </div>
            </InputGroup>
          </div>
          {console.log(isViewOnllyGrid, 'isViewOnllyGridisViewOnllyGrid')}
          {isPayeePage &&

            <div className='pagefrom-corress'>
              <div className="input-group">
                <label className="input-group-text">
                  Page From
                </label>
                <Form.Control
                  className={renderClass('payee_page_from', 'NUMBER', 'pagefrom')}

                  min={1}
                  type="number"
                  value={formik.values?.payee_page_from}
                  name={`payee_page_from`}

                />
              </div>
            </div>
          }
          {isPayeePage &&

            <div className='pageto-corress'>
              <div className="input-group">
                <label className="input-group-text">
                  Page To
                </label>
                <Form.Control
                  className={renderClass('payee_page_to', 'NUMBER', 'pageto')}

                  min={1}
                  type="number"
                  value={formik.values?.payee_page_to}
                  name={`payee_page_to`}

                />
              </div>
            </div>
          }
        </div>
        <React.Fragment>
          <div className="row">
            {formik.values?.plb_adjustments?.map((plb, index) => (
              <React.Fragment key={index}>
                <div className="col-lg-6">
                  <div className="sub-section">
                    <div className="">
                      <div className="d-flex align-items-center label-claim">
                        <button
                          disabled={true}
                          type="button"
                          tabIndex="-1"
                          // onClick={() => {
                          //   handlePlbDeleteAmount(index);
                          // }}
                          className="btn btn-outline-danger"
                        >
                          ×
                        </button>
                        <span>PLB Adjustment{" "}</span>
                      </div>
                    </div>
                    <div className="wrapper-plb">
                      <InputGroup>
                        <InputGroup.Text>NPI </InputGroup.Text>
                        <Form.Control
                          disabled={true}

                          type="text"
                          className={renderPlbClass('npi', 'TEXT', 'w-npi', index)}

                          value={plb?.npi}
                          maxLength={10}
                          name={`plb_adjustments[${index}].npi`}
                        />
                      </InputGroup>
                    </div>

                    <div className="wrapper-add">
                      <InputGroup>
                        <InputGroup.Text>PLB Page</InputGroup.Text>
                        <Form.Control
                          disabled={true}

                          as="select"
                          type="text"
                          className={renderPlbClass('plb_page', 'STRING', 'select-pan', index)}

                          value={plb?.plb_page}
                          name={`plb_adjustments[${index}].plb_page`}
                        >
                          {/* <option value="0">---select---</option> */}
                          {Array.from(Array(totalPages).keys()).map(
                            (i, index) => (
                              <option key={index} value={i + 1}>
                                {i + 1}
                              </option>
                            )
                          )}
                        </Form.Control>
                      </InputGroup>
                    </div>
                    <div className="wrapper-add">
                      <InputGroup>
                        <InputGroup.Text>Reason</InputGroup.Text>
                        <Form.Control
                          as="select"
                          disabled={true}

                          type="text"

                          value={formik.values?.plb_adjustments[index]?.reason}

                          className={renderPlbClass('reason', 'STRING', 'select-pan', index)}

                          name={`plb_adjustments[${index}].reason`}
                        >
                          <option value="">---Select---</option>
                          <option value="L6">L6 - Interest</option>
                          <option value="WO">WO - Overpayment Recovery</option>
                          <option value="FB">FB - Forward Balance</option>
                          <option value="BD">BD - Bad Debt</option>
                          <option value="72">72 - Authorized Return</option>
                          <option value="BN">BN - Bonus</option>
                          <option value="CS">CS - Adjustment</option>
                          <option value="AH">AH - Origination</option>
                          <option value="AM">AM - Borrower's Account</option>
                          <option value="AP">
                            AP - Acceleration of Benefits
                          </option>
                          <option value="B2">B2 - Rebate</option>
                          <option value="B3">B3 - Recovery</option>
                          <option value="C5">C5 - Temporary Allowance</option>
                          <option value="CR">CR - Capitation Interest</option>
                          <option value="CT">CT - Capitation Payment</option>
                          <option value="CV">CV - Capital Passthru</option>
                          <option value="CW">
                            CW - Registered Nurse Passthru
                          </option>
                          <option value="DM">
                            DM - Direct Medical Passthru
                          </option>
                          <option value="E3">E3 - Withholding</option>
                          <option value="FC">FC - Fund Allocation</option>
                          <option value="GO">GO - Graduate Passthru</option>
                          <option value="HM">HM - Hemophilia Clotting</option>
                          <option value="IP">IP - Incentive Payment</option>
                          <option value="IR">IR - IRS withholding</option>
                          <option value="IS">IS - Interim Settlement</option>
                          <option value="J1">J1 - Nonreimbursable</option>
                          <option value="L3">L3 - Penalty</option>
                          <option value="LE">LE - Levy</option>
                          <option value="LS">LS - Lump Sum</option>
                          <option value="OA">OA - Organ Passthru</option>
                          <option value="OB">OB - Provider Offset</option>
                          <option value="PI">PI - Periodic Payment</option>
                          <option value="PL">PL - Final Payment</option>
                          <option value="RA">
                            RA - Retroactive Adjustment
                          </option>
                          <option value="RE">RE - Return on Equity</option>
                          <option value="SL">
                            SL - Student Loan Repayment
                          </option>
                          <option value="TL">TL - Third Party Liability</option>
                          <option value="WU">WU - Unspecified Recovery</option>
                          <option value="50">50 - Late charge</option>
                          <option value="51">51 - Interest</option>
                          <option value="90">90 - Early Payment</option>
                        </Form.Control>
                      </InputGroup>
                    </div>
                    <div className="wrap-pan">
                      <InputGroup>
                        <InputGroup.Text>
                          Patient Account Number
                        </InputGroup.Text>
                        <Form.Control
                          type="text"
                          disabled={true}

                          value={
                            formik.values?.plb_adjustments[index]
                              ?.patient_account_number
                          }
                          className={renderPlbClass('patient_account_number', 'TEXT', 'w-pat-a-n', index)}
                          name={`plb_adjustments[${index}].patient_account_number`}
                        />
                      </InputGroup>
                    </div>
                    <div className="wrap-amt">
                      <InputGroup>
                        <InputGroup.Text>Amt</InputGroup.Text>
                        <Form.Control
                          type="number"
                          disabled={true}

                          value={formik.values?.plb_adjustments[index]?.plb_amt}
                          className={renderPlbClass('plb_amt', 'NUMBER', 'w-82', index)}
                          name={`plb_adjustments[${index}].plb_amt`}
                        />
                      </InputGroup>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ))}
          </div>
        </React.Fragment>
      </div>
    </React.Fragment>
  );
};

export default Lite;
