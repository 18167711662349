import React from 'react'
import { Form, InputGroup } from 'react-bootstrap'
import { DATA_ENTRY, EDIT_WORK, QA_REVIEW, isActiveDbkField } from '../../../../../functions';
import { DbkField } from '../Elements/DbkField';
import moment from 'moment';

const Claim = (props) => {
    const { formik,
        index,
        currentPage,
        jobData,
        log_id,
        job_id,
        pdfLoaded
    } = props;

    const handleChangeFrom = () => {
    }

    const handleChangeTo = () => {

    }
    const pathname = window.location.pathname;
    const gridData = jobData?.grid_data;
    const qaCorrectedData = gridData?.qa_corrected_data;

    const renderClass = (name, type, defaultClass) => {
        if (formik.errors &&
            formik.errors?.claims &&
            formik.errors?.claims[index] &&
            formik.errors?.claims[index]?.[name]) {
            return defaultClass + ' error-field'
        }
        if (pathname == QA_REVIEW) {

            if (qaCorrectedData &&
                qaCorrectedData?.claims &&
                qaCorrectedData?.claims[index] &&
                qaCorrectedData?.claims[index]?.[name]) {
                return defaultClass + ' qa-field'
            }
            else if (type == 'DATE' &&
                gridData?.claims &&
                gridData?.claims[formik.values?.claims[index]?.claim_corrected_index ? formik.values?.claims[index]?.claim_corrected_index : index] &&
                moment(formik.values?.claims[index]?.[name]).format("MM/DD/YYYY") != moment(gridData?.claims[formik.values?.claims[index]?.claim_corrected_index ? formik.values?.claims[index]?.claim_corrected_index : index]?.[name]).format("MM/DD/YYYY")) {
                return defaultClass + ' qa-field'
            }
            else if (type == 'TEXT' &&
                gridData?.claims &&
                gridData?.claims[formik.values?.claims[index]?.claim_corrected_index ? formik.values?.claims[index]?.claim_corrected_index : index] &&
                formik.values?.claims[index]?.[name]?.toUpperCase() !=
                gridData?.claims[formik.values?.claims[index]?.claim_corrected_index ? formik.values?.claims[index]?.claim_corrected_index : index]?.[name]?.toUpperCase()) {
                return defaultClass + ' qa-field'
            }
            else if (type == 'NUMBER' &&
                gridData?.claims &&
                gridData?.claims[formik.values?.claims[index]?.claim_corrected_index ? formik.values?.claims[index]?.claim_corrected_index : index] &&
                parseFloat(formik.values?.claims[index]?.[name] ? formik.values?.claims[index]?.[name] : 0).toFixed(2) !=
                parseFloat(gridData?.claims[formik.values?.claims[index]?.claim_corrected_index ? formik.values?.claims[index]?.claim_corrected_index : index]?.[name] ?
                    gridData?.claims[formik.values?.claims[index]?.claim_corrected_index ? formik.values?.claims[index]?.claim_corrected_index : index]?.[name] : 0).toFixed(2)) {
                return defaultClass + ' qa-field'
            }

            else if (gridData?.transaction_type != "PAYMENT835_LITE" || formik.values?.claims[index]?.corrected_claim) {
                return defaultClass + ' qa-field'
            }
            else {
                return defaultClass;
            }


        }
        else if ((pathname == EDIT_WORK + job_id || pathname == EDIT_WORK + job_id + "?log_id=" + log_id) &&
            qaCorrectedData &&
            qaCorrectedData?.claims &&
            qaCorrectedData?.claims[index] &&
            qaCorrectedData?.claims[index]?.[name]) {
            return defaultClass + ' qa-field'
        }
        else {
            return defaultClass;
        }
    }
    return (
        <div className="bottom-wrapper claimlite_details accordion-collapse collapse show" id="collapseTwo" aria-labelledby="headingTwo" data-bs-parent="#claim">
            <div className="sub-section">
                <div className="">

                    <label className="btn btn-claim">Claim Details </label>
                </div>
                <div className="wrapper-patient">
                    <InputGroup>
                        <InputGroup.Text>Patient Account #</InputGroup.Text>
                        <div className="dbk-main-container">

                            <Form.Control
                                type="text"
                                name={`claims[${index}].patient_account_number`}
                                value={formik.values?.claims[index]?.patient_account_number}
                                // className={formik.errors?.claims && formik.errors?.claims[index]?.patient_account_number ? 'w-claim-patient error-field' : 'w-claim-patient'}
                                className={renderClass('patient_account_number', 'TEXT', 'w-claim-patient')}

                            />


                        </div>
                    </InputGroup>
                </div>
                <div className="sub-section ml-40" >
                    <div className="">

                        <label className="btn btn-claim" >Patient </label>
                    </div>
                    <div className="wrapper-patient">
                        <InputGroup>
                            <InputGroup.Text>First Name</InputGroup.Text>
                            <div className="dbk-main-container">

                                <Form.Control
                                    type="text"
                                    name={`claims[${index}].patient_first_name`}
                                    value={formik.values?.claims && formik.values?.claims[index]?.patient_first_name}
                                    className={renderClass('patient_first_name', 'TEXT', 'w-fsname')}

                                />

                            </div>
                        </InputGroup>
                    </div>
                    <div className="wrapper-miname">
                        <InputGroup>
                            <InputGroup.Text>Middle Name</InputGroup.Text>
                            <div className="dbk-main-container">

                                <Form.Control
                                    type="text"
                                    name={`claims[${index}].patient_middle_name`}
                                    value={formik.values?.claims && formik.values?.claims[index]?.patient_middle_name}
                                    className={renderClass('patient_middle_name', 'TEXT', 'w-miname')}
                                />

                            </div>
                        </InputGroup>
                    </div>

                    <div className="wrapper-patient">
                        <InputGroup>
                            <InputGroup.Text>Last Name</InputGroup.Text>
                            <div className="dbk-main-container">

                                <Form.Control
                                    name={`claims[${index}].patient_last_name`}
                                    value={formik.values?.claims && formik.values?.claims[index]?.patient_last_name}
                                    // className={formik.errors?.claims && formik.errors?.claims[index]?.patient_last_name ? 'w-fsname ps-1 error-field' : 'w-fsname ps-1'}
                                    className={renderClass('patient_last_name', 'TEXT', 'w-fsname')}
                                />

                            </div>
                        </InputGroup>
                    </div>
                    <div className="w-claim-from">
                        <InputGroup>
                            <InputGroup.Text>From</InputGroup.Text>

                            <Form.Control

                                type="text"

                                name={`claims[${index}].page_from`}

                                value={formik.values?.claims[index]?.page_from}
                                className={renderClass('page_from', 'NUMBER', 'page-claim-from')}
                            />

                        </InputGroup>
                    </div>
                    <div className="">
                        <InputGroup>
                            <InputGroup.Text>To</InputGroup.Text>

                            <Form.Control
                                type="text"
                                name={`claims[${index}].page_to`}
                                value={formik.values?.claims[index]?.page_to}
                                className={renderClass('page_to', 'NUMBER', 'page-claim-to')}
                            />
                        </InputGroup>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Claim