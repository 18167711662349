import React, { useState } from 'react';
import { useEffect } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import Logo from '../../assets/img/Xtract.png';
import Logo_X from '../../assets/img/xtract_x.png';
import { BsBoxArrowRight } from 'react-icons/bs';
import { AiOutlineSync } from 'react-icons/ai';
import { AuthService } from '../../services/AuthService';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsPasswordReset, selectIsSuperUser, selectPermissions, selectSyncTime, selectUserName, setSignOutState, setUserDetails } from '../../slice/user/userSlice';
import { ReloadCircle } from '../components/RoundCircle';
import Modal from "react-modal";
import ResetPasswordForm from '../pages/Users/ResetPasswordForm';
import { Toast, ToastContainer } from 'react-bootstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import { seErrorMessage, setErrorMessageClear } from '../../slice/error/errorSlice';
import { onMessageListener } from '../../app/firebase';
import { Toaster } from 'react-hot-toast';



const Sidebar = (props) => {
    const authService = new AuthService();
    const { userLogout, syncXtractAnalytics, syncXtractAnalyticsRefresh } = authService
    const dispatch = useDispatch()
    const params = useParams()
    const { group_id, role_id, user_id } = params

    const userDetails = useSelector(state => state.user);
    const reportError = useSelector(state => state.error);
    const syncTime = useSelector(selectSyncTime);
    const is_password_reset = useSelector(selectIsPasswordReset);
    const permissions = useSelector(selectPermissions);
    const isSuperUser = useSelector(selectIsSuperUser);
    const userName = useSelector(selectUserName);


    const navigate = useNavigate()

    const [rotate, setRotate] = useState(false);
    const rotateTransform = rotate ? "rotate(180deg)" : "rotate(0)"
    const [loader, setLoader] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [showToastSync, setShowToastSync] = useState(false);

    const [showToastPassword, setShowToastPassword] = useState(false);
    const [showErrorPassword, setShowErrorPassword] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState('');
    const [showSyncModal, setShowSyncModal] = useState(false);

    const closeModal = () => {
        setIsOpen(false);
    }
    useEffect(() => {
        if (showToastPassword == true) {
            const timer = setTimeout(() => {
                setShowToastPassword(false)
            }, [2000]);
            return () => clearTimeout(timer);
        }
    }, [showToastPassword]);
    const handleLogout = () => {
        let formData = new FormData();    //formdata object
        const refresh = localStorage.getItem('refresh')
        formData.append('refresh', refresh);   //append the values with key, value pair


        userLogout(formData).then((response) => {
            if (response?.status) {
                dispatch(setSignOutState())
                localStorage.removeItem('token')
                localStorage.removeItem('refresh')
                navigate('/login');
            }
            else {
                dispatch(setSignOutState())
                localStorage.clear()
                window.location.assign('/login'); // Replace '/login' with your actual login page URL

            }
        })


    };
    const syncXtract = () => {
        setLoader(true)
        setShowSyncModal(false)
        let formData = new FormData();    //formdata object
        const refresh = localStorage.getItem('refresh')
        formData.append('refresh', refresh);   //append the values with key, value pair

        syncXtractAnalytics(formData).then((response) => {
            console.log(response)
            if (response?.data?.status) {
                // const dict = {
                //     ...userDetails,
                //     sync_time: response?.data?.sync_time
                // }

                // dispatch(
                //     setUserDetails(dict)
                // );
                // setShowToastPassword(true)
                setLoader(false)
            }
            else if (response?.data?.status_code === 400) {
                setLoader(false)
                dispatch(seErrorMessage({ message: response?.data?.message, is_show: true }))
            }
            else if (response?.response?.status_code === 401) {
                setLoader(false)
            }
            else {
                setLoader(false)
            }
        })


    };

    const handleClick = () => {
        setRotate(!rotate);

        setIsActive(current => !current);
        if (isActive) {
            const toggleRemovee = document.body.classList.remove('toggle-sidebar')
            const toggleRemove = document.getElementById('navbar').classList.remove('expander')
        }
        else {
            const toggleOpenn = document.body.classList.add('toggle-sidebar')
            const toggleOpen = document.getElementById('navbar').classList.add('expander')
            const toggleActiveRemove = document.getElementsByClassName('nav-link');
            Array.from(toggleActiveRemove).forEach(element => {
                element.classList.add('collapsed');

            });
            const collapse = document.getElementsByClassName('nav-content');
            Array.from(collapse).forEach(element => {

                element.classList.remove('show');

            });
        }
    }
    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '18%'
        },
    };
    const __passwordToast = (status, message) => {
        if (status == 'success') {
            setShowToastPassword(true)
            closeModal()
        }
        else {
            setShowErrorPassword(true)
            setShowErrorMessage(message)
        }
    }
    const isHavePermission = (name) => {
        return permissions?.includes(name) || isSuperUser
    }

    function handleSidebarHover() {
        const logoXElement = document.querySelector('.sidebar .logo_x');
        if (logoXElement) {
            logoXElement.classList.add('hidden');
        }
    }

    function handleSidebarLeave() {
        const logoXElement = document.querySelector('.sidebar .logo_x');
        if (logoXElement) {
            logoXElement.classList.remove('hidden');
        }
    }
    useEffect(() => {
        if (reportError.is_show) {
            setShowErrorPassword(true)
            setShowErrorMessage(reportError.message)
            const timer = setTimeout(() => {
                dispatch(setErrorMessageClear())
            }, [2000]);
            return () => clearTimeout(timer);
        }
    }, [reportError])

    onMessageListener().then(payload => {
        console.log(payload);

        if (payload.notification.title === "data_syncing_analytics") {
            const dict = {
                ...userDetails,
                sync_time: payload.notification.body
            }

            dispatch(
                setUserDetails(dict)
            );
            setShowToastSync(true)

        }
    }).catch(err => console.log('failed: ', err));

    return (
        <React.Fragment>
            <Toaster
                position="top-right"
                reverseOrder={false}
            />
            <ToastContainer className="p-3" position={"top-end"}>
                <Toast bg="success" onClose={() => setShowToastPassword(!showToastPassword)} show={showToastPassword} animation={true} delay={2000} autohide>

                    <Toast.Body ><span className='text-white'>Success</span></Toast.Body>
                </Toast>
                <Toast bg="danger" onClose={() => setShowErrorPassword(!showErrorPassword)} show={showErrorPassword} animation={true} delay={2000} autohide>
                    <Toast.Body ><span className='text-white'>{showErrorMessage}</span></Toast.Body>
                </Toast>
                <Toast bg="sync-color" show={showToastSync} animation={true} autohide={true}>
                    <Toast.Body >
                        <div className='text-black'>Data synced, refresh now?</div>
                        <div className='d-flex mt-3'>
                            <button style={{ fontSize: '12px' }} onClick={() => window.location.reload()} className='me-2 btn btn-lg btn-primary custom-confirm-button-class'>Refresh</button>
                            <button style={{ fontSize: '12px' }} onClick={() => setShowToastSync(!showToastSync)} className='btn btn-lg btn-link custom-cancel-button-class'>Cancel</button>
                        </div>
                    </Toast.Body>
                </Toast>
            </ToastContainer>
            {showSyncModal &&
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Ok"
                    cancelBtnText="Cancel"
                    //confirmBtnBsStyle="danger"
                    confirmBtnCssClass="custom-confirm-button-class"
                    cancelBtnCssClass="custom-cancel-button-class"
                    title="Sync the data?"
                    onConfirm={() => { syncXtract() }}
                    onCancel={() => { setShowSyncModal(false) }}
                    focusCancelBtn
                >
                </SweetAlert>
            }
            <div className='aside-main'>
                <div className='aside-footer'>

                    <li style={{ cursor: 'pointer' }} className='synced_data d-flex align-items-center flex-stack'>
                        <span class="badge badge-light-primary fs-7 fw-bold ">{syncTime}
                            {isHavePermission("sync_datas") &&
                                <ReloadCircle loader={loader} className='icon_logout' onClick={() => { setShowSyncModal(true) }} />
                            }
                        </span>
                    </li>
                    <li className="nav-item dropdown pe-3">

                        <Link className="nav-link nav-profile d-flex align-items-center pe-0" data-bs-toggle="dropdown">
                            <span className="d-none d-md-block dropdown-toggle ps-2">{userName}</span>
                        </Link>

                        <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">

                            {/* <li>
                                <Link className="dropdown-item d-flex align-items-center" onClick={() => { syncXtract() }}>
                                    <ReloadCircle loader={loader} className='icon_logout' />
                                    <span>Sync</span>
                                </Link>
                            </li>
                            <li>
                                <hr className="dropdown-divider" />
                            </li> */}

                            <li>
                                <Link onClick={() => { handleLogout() }} className="dropdown-item d-flex align-items-center"
                                >
                                    <BsBoxArrowRight className='icon_logout' />
                                    <span>Log Out</span>
                                </Link>
                            </li>

                        </ul>
                    </li>
                    {/* <li className="nav-item">
                        <a className="nav-link collapsed" data-bs-target="#components-nav" data-bs-toggle="collapse" href="#">
                            <span className='me-2'></span>User Name<i className="bi bi-chevron-down ms-auto"></i>
                        </a>

                        <ul id="components-nav" className={

                            window.location.pathname === '/list-roles' ||
                                window.location.pathname === '/create-role' ||
                                window.location.pathname === '/edit-role/' + role_id ||
                                window.location.pathname === '/list-groups' ||
                                window.location.pathname === '/create-group' ||
                                window.location.pathname === '/edit-group/' + group_id ||
                                window.location.pathname === '/list-users' ||
                                window.location.pathname === '/create-user' ||
                                window.location.pathname === '/edit-user/' + user_id


                                ? "nav-content" : "nav-content collapse"} data-bs-parent="#sidebar-nav">



                            {isHavePermission("sync_datas") &&
                                <li className=''>
                                    <Link onClick={() => { syncXtract() }} className="nav-link"><ReloadCircle loader={loader} className='icon_logout' /> Sync</Link>
                                </li>
                            }
                            <li className='mb-3'>
                                <Link onClick={() => { handleLogout() }} className='nav-link' ><BsBoxArrowRight className='icon_logout' /> Log Out</Link>
                            </li>

                        </ul>
                    </li> */}


                </div>
            </div>


            <aside id="navbarToggleExternalContent" className="sidebar" onMouseEnter={handleSidebarHover} onMouseLeave={handleSidebarLeave}>

                <div className='aside-logo flex-column-auto mb-9 mb-lg-17'>
                    <Link to="/" className="logo_main">
                        <img src={Logo} alt="Xtract" />

                    </Link>
                    <Link to="/" className="logo_x">
                        <img src={Logo_X} alt="Xtract" />

                    </Link>
                    {/* <div className="dashboard_togglebtn" onClick={handleClick} style={{ transform: rotateTransform, transition: "all 0.2s linear" }} >
                        <span className="svg-icon svg-icon-2 rotate-180">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.5" d="M14.2657 11.4343L18.45 7.25C18.8642 6.83579 18.8642 6.16421 18.45 5.75C18.0358 5.33579 17.3642 5.33579 16.95 5.75L11.4071 11.2929C11.0166 11.6834 11.0166 12.3166 11.4071 12.7071L16.95 18.25C17.3642 18.6642 18.0358 18.6642 18.45 18.25C18.8642 17.8358 18.8642 17.1642 18.45 16.75L14.2657 12.5657C13.9533 12.2533 13.9533 11.7467 14.2657 11.4343Z" fill="currentColor"></path>
                                <path d="M8.2657 11.4343L12.45 7.25C12.8642 6.83579 12.8642 6.16421 12.45 5.75C12.0358 5.33579 11.3642 5.33579 10.95 5.75L5.40712 11.2929C5.01659 11.6834 5.01659 12.3166 5.40712 12.7071L10.95 18.25C11.3642 18.6642 12.0358 18.6642 12.45 18.25C12.8642 17.8358 12.8642 17.1642 12.45 16.75L8.2657 12.5657C7.95328 12.2533 7.95328 11.7467 8.2657 11.4343Z" fill="currentColor"></path>
                            </svg>
                        </span>
                    </div> */}
                </div>

                <div className={isActive ? "l-navbar aside-menu" : "l-navbar aside-menu expander"} id="navbar">
                    <nav className="nav nav-padding">
                        <ul className="sidebar-nav" id="sidebar-nav">

                            <div className='side_menus'>
                                <div className='ul_menus-top'>
                                    <li className="nav-item">
                                        <Link to='/' className={window.location.pathname === '/' ? "nav-link active" : "nav-link"}>
                                            <i className="bi bi-grid"></i>
                                            <span>Dashboard</span>
                                        </Link>
                                    </li>
                                    {isHavePermission("data_listing_management") &&
                                        <li className="nav-item">
                                            <Link to='/data-list' className={window.location.pathname === '/data-list' ? "nav-link active" : "nav-link"}>
                                                <i className="bi bi-card-list"></i>
                                                <span>Data Listing</span>
                                            </Link>
                                        </li>
                                    }

                                    {isHavePermission("user_report_management") &&
                                        <li className="nav-item">
                                            <Link to='/user-report' className={window.location.pathname === '/user-report' ? "nav-link active" : "nav-link"} >
                                                <i className="bi bi-people-fill"></i><span>User Report </span>
                                            </Link>
                                        </li>
                                    }
                                    {isHavePermission("client_report_management") &&
                                        <li className="nav-item">
                                            <Link to='/client-report' className={window.location.pathname === '/client-report' ? "nav-link active" : "nav-link"} >
                                                <i className="bi bi-person-bounding-box"></i><span>Client Report </span>
                                            </Link>
                                        </li>
                                    }
                                    {isHavePermission("job_report_management") &&
                                        <li className="nav-item">
                                            <Link to='/job-report' className={window.location.pathname === '/job-report' ? "nav-link active" : "nav-link"} >
                                                <i className="bi bi-person-video3"></i><span>Job Status Report </span>
                                            </Link>
                                        </li>
                                    }
                                    {isHavePermission("payer_report_management") &&
                                        <li className="nav-item">
                                            <Link to='/payer-report' className={window.location.pathname === '/payer-report' ? "nav-link active" : "nav-link"} >
                                                <i className="bi bi-person-lines-fill"></i><span>Payer Report </span>
                                            </Link>
                                        </li>
                                    }
                                    {isHavePermission("file_report_management") &&
                                        <li className="nav-item">
                                            <Link to='/file-report' className={window.location.pathname === '/file-report' ? "nav-link active" : "nav-link"} >
                                                <i className="bi bi-journals"></i><span>File Report </span>
                                            </Link>
                                        </li>
                                    }
                                    {isHavePermission("file_report_management_payer_reasoncode") &&
                                        <li className="nav-item">
                                            <Link to='/file-management' className={window.location.pathname === '/file-management' ? "nav-link active" : "nav-link"} >
                                                <i className="bi bi-files"></i><span>Payer Crosswalk </span>
                                            </Link>
                                        </li>
                                    }
                                    {isHavePermission("invoice_report_management") &&
                                    <li className="nav-item">
                                        <Link to='/invoice-report' className={window.location.pathname === '/invoice-report' ? "nav-link active" : "nav-link"} >
                                            <i className="bi bi-journal-text"></i><span>Invoice Report </span>
                                        </Link>
                                    </li>
                                    }
                                    {isHavePermission("qa_error_report_management") &&
                                        <li className="nav-item">
                                            <Link to='/qa-error-report' className={window.location.pathname === '/qa-error-report' ? "nav-link active" : "nav-link"} >
                                                <i className="bi bi-bug-fill"></i><span>QA Report </span>
                                            </Link>
                                        </li>
                                    }
                                    {isHavePermission("view_users") && isHavePermission("view_group") && isHavePermission("view_role") &&
                                        <li className="nav-item">
                                            <a className={`nav-link collapsed ${window.location.pathname == '/list-roles' || window.location.pathname == '/list-groups' || window.location.pathname == '/list-users' ? 'active' : ''}`} data-bs-target="#components-nav" data-bs-toggle="collapse" href="#">
                                                <i className="bi bi-person"></i><span className='me-2'>User Management</span><i className="bi bi-chevron-down ms-auto"></i>
                                            </a>
                                            <ul id="components-nav" className={

                                                window.location.pathname === '/list-roles' ||
                                                    window.location.pathname === '/create-role' ||
                                                    window.location.pathname === '/edit-role/' + role_id ||
                                                    window.location.pathname === '/list-groups' ||
                                                    window.location.pathname === '/create-group' ||
                                                    window.location.pathname === '/edit-group/' + group_id ||
                                                    window.location.pathname === '/list-users' ||
                                                    window.location.pathname === '/create-user' ||
                                                    window.location.pathname === '/edit-user/' + user_id


                                                    ? "nav-content" : "nav-content collapse"} data-bs-parent="#sidebar-nav">
                                                {isHavePermission("view_role") &&
                                                    <l>
                                                        <Link to='/list-roles' className={
                                                            window.location.pathname === '/list-roles' ||
                                                                window.location.pathname === '/create-role' ||
                                                                window.location.pathname === '/edit-role/' + role_id
                                                                ? "nav-link active" : "nav-link"}>
                                                            <i className="bi bi-dot"></i><span>Roles</span>
                                                        </Link>
                                                    </l>
                                                }
                                                {isHavePermission("view_group") &&
                                                    <l>
                                                        <Link to='/list-groups' className={

                                                            window.location.pathname === '/list-groups' ||
                                                                window.location.pathname === '/create-group' ||
                                                                window.location.pathname === '/edit-group/' + group_id

                                                                ? "nav-link active" : "nav-link"}>
                                                            <i className="bi bi-dot"></i><span>Groups</span>
                                                        </Link>
                                                    </l>
                                                }
                                                {isHavePermission("view_users") &&

                                                    <l>
                                                        <Link to='/list-users' className={
                                                            window.location.pathname === '/list-users' ||
                                                                window.location.pathname === '/create-user' ||
                                                                window.location.pathname === '/edit-user/' + user_id

                                                                ? "nav-link active" : "nav-link"}>
                                                            <i className="bi bi-dot"></i><span>Users</span>
                                                        </Link>
                                                    </l>
                                                }


                                            </ul>
                                        </li>
                                    }
                                </div>

                            </div>
                        </ul>

                    </nav>

                </div>

                <Modal
                    isOpen={modalIsOpen || is_password_reset}
                    ariaHideApp={false}
                    onRequestClose={() => { closeModal() }}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <ResetPasswordForm __passwordToast={__passwordToast} closeModal={closeModal} />
                </Modal>
            </aside>

        </React.Fragment>
    )
}

export default Sidebar;