import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import styled from "styled-components/macro";

const BarSkelitonLoader = ({ isVisible, report }) => {
  return (
    <BlurBackground isVisible={isVisible}>
      <SkeletonContainer report={report} >
        <div className="main-bar-graph-box">
          <div className="y-line">
            <Skeleton height={200} width={2} />
          </div>
          <div className="bars-container">
            <div className="bar">
              <Skeleton height={100} width={30} />
            </div>
            <div className="bar">
              <Skeleton height={150} width={30} />
            </div>
            <div className="bar">
              <Skeleton height={120} width={30} />
            </div>
            <div className="bar">
              <Skeleton height={80} width={30} />
            </div>
            <div className="bar">
              <Skeleton height={200} width={30} />
            </div>
          </div>
          <div className="x-line">
            {/* <Skeleton height={2} width={400} /> */}
          </div>
        </div>
      </SkeletonContainer>
    </BlurBackground>
  );
};

export default BarSkelitonLoader;

const BlurBackground = styled.div`
  /* display: ${(props) => (props.isVisible)}; */
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

const SkeletonContainer = styled.div`
  width: 100%;
  position: relative;

  .main-bar-graph-box {
    display: flex;
    align-items: flex-end;
    margin: 0 auto;
    width: 70%;
    height: 86%;
  }

  .y-line {
    position: absolute;
    left: 50px;
    top: 60px;
    bottom: 0px;
    width: 2px;
    background-color: #ccc;
    height: ${(prop) => prop.report === 'time-report' ? '292px' : '338px'} ;
  }

  .bars-container {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    margin: 0 10px;
  }

  .bar {
    width: 30px;
    position: relative;
    background-color: #ccc;
  }

  .x-line {
    position: absolute;
    left: 50px;
    bottom: 60;
    width: calc(100% - 50px);
    height: 2px;
    background-color: #ccc;
  }
`;
