import React, { useState } from 'react'
import AllReport from './allReport';
import SingleReport from './singleReport';
import { VscGraph } from "react-icons/vsc";
import { MdDonutLarge } from "react-icons/md";
import AllReportGraph from './allReportGraph';
import SingleReportGraph from './singleReportGraph';
import Select from 'react-select';
import styled from "styled-components/macro";
import CircleSkelitonLoader from '../../loader/CircleSkelitonLoader';

const VolumeReport = ({ graphType, setGraphType, showSingle, setShowSingle, setLoaderState, loaderState, data, searchFormik, update }) => {
    // const [graphType, setGraphType] = useState('DONUT')
    const [singleData, setSingleData] = useState([])
    const [filterData, setFilterData] = useState([])
    const [singleColor, setSingleColor] = useState('')
    const [filter, setFilter] = useState({
        year: false,
        month: false,
        week: false,
        days: false
    })
    const [selectedFilter, setSelectedFilter] = useState({ value: '', label: 'Filter..' })
    let inner = false

    const filterOptions = [
        { value: '', label: 'Filter..' },
        { value: 'year', label: 'Year' },
        { value: 'month', label: 'Month' },
        { value: 'week', label: 'Week' },
        { value: 'days', label: 'Days' }
    ]


    const handleClickData = (event, chartContext, { dataPointIndex, seriesIndex, w }) => {
        console.log(w.config.colors[dataPointIndex])
        if (data && data[dataPointIndex]) {
            // let items = [data[dataPointIndex].payment835_claims, data[dataPointIndex].payment835lite_claims, data[dataPointIndex].patientpay_claims, data[dataPointIndex].correspondence_claims]
            setSingleData(data[dataPointIndex])
            setSingleColor(w.config.colors[dataPointIndex])
            setTimeout(() => {
                setShowSingle(true)
            }, 500);
        }
    }
    const handleClickFilterData = (event, chartContext, { dataPointIndex, seriesIndex, w }) => {
        console.log(filterData, dataPointIndex, seriesIndex)
        if (filterData && filterData[seriesIndex] && filterData[seriesIndex]?.data && filterData[seriesIndex]?.data[dataPointIndex]) {
            // let items = [filterData[seriesIndex]?.data[dataPointIndex].payment835_claims, filterData[seriesIndex]?.data[dataPointIndex].payment835lite_claims, filterData[seriesIndex]?.data[dataPointIndex].patientpay_claims, filterData[seriesIndex]?.data[dataPointIndex].correspondence_claims]
            setSingleData(filterData[seriesIndex]?.data[dataPointIndex])
            setSingleColor(w.config.colors[seriesIndex])
            setTimeout(() => {
                setShowSingle(true)
            }, 500);
        }
    }
    const handleClickInnerGraph = () => {
        if (inner) {
            inner = false
        }
        else {
            setTimeout(() => {
                setShowSingle(false)
            }, 500);
        }
    }
    const handleClickInnerData = (e) => {
        inner = true
    }

    const handleGraphChange = (type) => {
        setGraphType(type)
    }


    const handleSelectChange = (val) => {
        setSelectedFilter(val)
        setFilter({
            year: val?.value == 'year' ? true : false,
            month: val?.value == 'month' ? true : false,
            week: val?.value == 'week' ? true : false,
            days: val?.value == 'days' ? true : false
        })
    }
    console.log(data)
    return (
        <div className='card  p-2'>

            <div className='d-flex align-items-center justify-content-between'>
                <div className='mt-2' style={{ fontWeight: '500', fontSize: '20px', marginLeft: '30px', color: '#012970' }}>Volume Report</div>
                <div className='d-flex align-items-center me-2'>
                    {graphType == 'GRAPH' && !showSingle ?
                        <div className='me-3'>
                            <div>
                                <Select
                                    options={filterOptions}
                                    className=""
                                    classNamePrefix="select"
                                    placeholder="Filter"
                                    menuPlacement="auto"
                                    maxMenuHeight={300}
                                    onChange={(val) => {
                                        handleSelectChange(val)
                                    }}
                                    value={selectedFilter}
                                    name="partners"
                                    tabSelectsValue={false}
                                    styles={{
                                        container: (styles, state) => ({
                                            ...styles,
                                            borderRadius: "9px",
                                        }),
                                        control: (styles, state) => ({
                                            ...styles,
                                            backgroundColor: "hsl(204deg 33.33% 97.06%)",
                                            borderStyle: "none", boxShadow: "none",
                                            borderRadius: "10px",
                                            maxHeight: "30px",
                                        }),
                                        indicatorSeparator: styles => ({
                                            ...styles, width: "0px",
                                            minHeight: "20px",
                                            maxHeight: "30px",
                                        }),

                                        valueContainer: (styles, state) => ({
                                            ...styles,
                                            maxHeight: "30px",
                                            overflow: 'scroll',
                                        }),
                                        option: (styles, state) => ({
                                            ...styles,
                                            backgroundColor: state.isSelected ? '#d9f4f5' : 'inherit',
                                            color: state.isSelected ? '#000000' : 'inherit',
                                            '&:hover': { backgroundColor: state.isSelected ? '#d9f4f5' : 'rgb(222, 235, 255)' }

                                        })
                                    }}
                                    menuPortalTarget={document.body}

                                />
                            </div>
                        </div>
                        :
                        <></>
                    }
                    {graphType == 'DONUT' ?
                        <div onClick={() => handleGraphChange('GRAPH')}><VscGraph size={25} /></div>
                        :
                        <div onClick={() => handleGraphChange('DONUT')}><MdDonutLarge size={25} /></div>
                    }
                </div>
            </div>
            <div style={{ height: '45vh', overflow: graphType == 'DONUT' ? 'visible' : 'auto' }}>
                {showSingle ?
                    graphType == 'DONUT' ?
                        <SingleReport isVisible={loaderState.volumeReportLoader} handleClickInnerGraph={handleClickInnerGraph} handleClickInnerData={handleClickInnerData} singleData={singleData} singleColor={singleColor} />
                        :
                        <SingleReportGraph isVisible={loaderState.volumeReportLoader} handleClickInnerGraph={handleClickInnerGraph} handleClickInnerData={handleClickInnerData} singleData={singleData} singleColor={singleColor} />
                    :
                    graphType == 'DONUT' ?
                        <AllReport isVisible={loaderState.volumeReportLoader} isNodataShow={!loaderState.volumeReportLoader} data={data} handleClickData={handleClickData} />
                        :
                        <AllReportGraph isVisible={loaderState.volumeReportLoader} setLoaderState={setLoaderState} searchFormik={searchFormik} handleClickData={handleClickData} filter={filter} update={update} setFilterData={setFilterData} handleClickFilterData={handleClickFilterData} />

                }
            </div>

        </div>
    )
}

export default VolumeReport


