import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import styled from "styled-components/macro";

const ModalSkelitonLoader = ({ isVisible }) => {
  return (
    <BlurBackground isVisible={isVisible}>
      <SkeletonContainer  >
        <div className="single-container">
          <Skeleton count={5} height={20} width={10} />
          <Skeleton count={5} height={20} width={400} />

        </div>
      </SkeletonContainer>
    </BlurBackground>
  );
};

export default ModalSkelitonLoader;

const BlurBackground = styled.div`
 
`;

const SkeletonContainer = styled.div`
 .single-container{
  display: flex;
  gap: 10px;
 }
`;
