import React, { useEffect, useState } from 'react'
import Chart from 'react-apexcharts';
import SingleReport from './singleReport';
import AllReport from './allReport';
import styled from "styled-components/macro";

const TimeReport = ({ loaderState, data, searchFormik, update }) => {
    const [singleData, setSingleData] = useState([])
    const [showSingle, setShowSingle] = useState(false)
    const [graphType, setGraphType] = useState('DONUT')
    const [singleColor, setSingleColor] = useState('')
    const [filter, setFilter] = useState({
        year: true,
        month: false,
        week: false,
        days: false
    })
    const [selectedFilter, setSelectedFilter] = useState({ value: 'year', label: 'Year' })
    let inner = false

    const handleClickData = (event, chartContext, { dataPointIndex, seriesIndex, w }) => {
        let items = []
        if (seriesIndex == 1) {
            items = Object.values(data.qa)
        }
        else {
            items = Object.values(data.my_work)
        }
        if (items && items?.length) {
            let values = []
            if (seriesIndex == 1) {
                values = [items[dataPointIndex]?.[0]?.payment835_qa_total_time, items[dataPointIndex]?.[0]?.payment835_lite_qa_total_time, items[dataPointIndex]?.[0]?.patientpay_qa_total_time, items[dataPointIndex]?.[0]?.correspondance_qa_total_time]
            }
            else {
                values = [items[dataPointIndex]?.[0]?.payment835_total_time, items[dataPointIndex]?.[0]?.payment835_lite_total_time, items[dataPointIndex]?.[0]?.patientpay_total_time, items[dataPointIndex]?.[0]?.correspondance_total_time]
            }
            console.log(w.config.colors[seriesIndex])
            setSingleColor(w.config.colors[seriesIndex])
            setSingleData(values)
            setTimeout(() => {
                setShowSingle(true)
            }, 500);
        }
    }
    const handleClickInnerGraph = () => {
        if (inner) {
            inner = false
        }
        else {
            setTimeout(() => {
                setShowSingle(false)
            }, 500);
        }
    }
    const handleClickInnerData = (e) => {
        inner = true
    }

    useEffect(() => {
        setShowSingle(false)
    }, [update])



    return (
        <>
            <div className='card  p-2' >
                {/* <BlurBackground isVisible={loaderState.timeReportLoader ? "flex" : "none"}>
                    <div class="spinner-border" role="status">
                        <span class="sr-only"></span>
                    </div>
                </BlurBackground> */}
                <div className='mt-2' style={{ fontWeight: '500', fontSize: '20px', marginLeft: '30px', color: '#012970' }}>Time Report</div>
                <div style={showSingle ? { height: '45vh', position: 'relative', overflow: 'visible' } : { height: '45vh', position: 'relative', overflow: 'auto' }}>
                    {showSingle ?
                        <SingleReport isVisible={loaderState.timeReportLoader} handleClickInnerGraph={handleClickInnerGraph} handleClickInnerData={handleClickInnerData} singleData={singleData} singleColor={singleColor} />
                        :
                        <AllReport isVisible={loaderState.timeReportLoader} data={data} handleClickData={handleClickData} />
                    }
                </div>
            </div>
        </>
    )
}

export default TimeReport

const BlurBackground = styled.div`
  /* filter: blur(5px);  */
  /* pointer-events: none; */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgb(215 215 215 / 80%), rgb(215 215 215 / 50%));
  z-index: 1;
  /* display: ${(props) => props.isVisible}; */
  display: ${(props) => props.isVisible};
  align-items: center;
  justify-content: center;
  color: black;
`;