import request from "../api/agent";

const API_ROUTE = "users/roles";

export class RoleApiService {
  roles = [];

  async getAllRoles(page, limit, search) {
    const params = `?page=${page}&limit=${limit}&search=${search ?? ""}`

    const response = await request.get(`${API_ROUTE}/get-roles`+params);
    return response.data;
  }
  async getPermissions() {
    const response = await request.get(`users/get-permissions`);
    return response.data;
  }
  async getSingleRoleDetails(role) {
    const params = `?page=${1}&role=${role}`

    const response = await request.get(`${API_ROUTE}/get-role-details` + params);
    return response.data;
  }

  async addOrUpdateRole(role, name, permissions) {
    let action = `${API_ROUTE}/create-or-update-role`;
    const response = await request.post(action, { role, name, permissions });
    return response;
  }

  async deleteRole(id) {
    const response = await request.delete(`${API_ROUTE}/destroy-role`, {
      data: { role: id }
    });
    return response;
  }
}