import request from "../api/agent";

const API_ROUTE = "reports";

export class InvoiceReportService {

  async getAllClientDropdown() {

    const response = await request.get(`job_monitor/client-list`);
    return response.data ?? response?.response?.data;

  }
  async getClientPartnerDropdown(partner = null) {

    const response = await request.post(`job_monitor/get-client-partner`, { partner });
    return response.data ?? response?.response?.data;

  }
  async getFacilityDropdown(client=null) {
    const response = await request.post(`job_monitor/get-faicility-partner`, { client });
    return response.data ?? response?.response?.data;

  }
  async getPartnerDropdown() {

    const response = await request.get(`job_monitor/partner-list`);
    return response.data ?? response?.response?.data;

  }

  async getInvoiceReportSummery(page, limit, station, job_status, date_range, hour_count, clients, facilities, day, year, week, monthList, order_by = 'date', order_type = 'DESC', partners, depo_date_range) {
    const params = `?page=${page}&limit=${limit}&date_range=${date_range}&clients=${clients}&facilities=${facilities}&day=${day}&year=${year}&week=${week}&month=${monthList}&sort_key=${order_by}&order_type=${order_type}&partners=${partners}&depo_date_range=${depo_date_range}`

    const response = await request.get(`${API_ROUTE}/get-invoice-summery` + params);
    return response.data ?? response?.response?.data;

  }
  async getInvoiceReportTotal(page, limit, station, job_status, date_range, hour_count, clients, facilities, day, year, week, monthList, order_by = 'date', order_type = 'DESC', partners, depo_date_range) {
    const params = `?page=${page}&limit=${limit}&date_range=${date_range}&clients=${clients}&facilities=${facilities}&day=${day}&year=${year}&week=${week}&month=${monthList}&sort_key=${order_by}&order_type=${order_type}&partners=${partners}&depo_date_range=${depo_date_range}`

    const response = await request.get(`${API_ROUTE}/invoice-summery-total` + params);
    return response.data ?? response?.response?.data;

  }

  async getInvoiceReportDetails(page, limit, station, job_status, date_range, hour_count, user, commom_filter, day, year, week, monthList, facility_id, depo_date_range) {
    const params = `?page=${page}&limit=${limit}&date_range=${date_range}&user=${user}&commom_filter=${commom_filter}&day=${day}&year=${year}&week=${week}&month=${monthList}&facility_id=${facility_id}&depo_date_range=${depo_date_range}`

    const response = await request.get(`${API_ROUTE}/get-invoice-details` + params);
    return response.data;
  }
  async downloadInvoiceReportSummery(page, limit, station, job_status, date_range, hour_count, clients, facilities, day, year, week, monthList, order_by = 'date', order_type = 'DESC', partners, depo_date_range) {
    const params = `?page=${page}&limit=${limit}&date_range=${date_range}&job_status=${job_status}&clients=${clients}&facilities=${facilities}&day=${day}&year=${year}&week=${week}&month=${monthList}&partners=${partners}&depo_date_range=${depo_date_range}&sort_key=${order_by}&order_type=${order_type}`

    const response = await request.get(`${API_ROUTE}/invoice-report-download` + params);
    return response.data;
  }

  async downloadInvoiceReportDetails(page, limit, station, job_status, date_range, hour_count, user, commom_filter, day, year, week, monthList, facility_id, depo_date_range) {
    const params = `?page=${page}&limit=${limit}&date_range=${date_range}&user=${user}&commom_filter=${commom_filter}&day=${day}&year=${year}&week=${week}&month=${monthList}&facility_id=${facility_id}&depo_date_range=${depo_date_range}`

    const response = await request.get(`${API_ROUTE}/invoice-report-details-download` + params);
    return response.data;
  }
}