import React, { useEffect, useState } from 'react'
import Chart from 'react-apexcharts';
import { DashboardService } from '../../../../services/DashboardService';
import moment from 'moment';
import BarSkelitonLoader from '../../loader/BarSkelitonLoader';

const AllReportGraph = ({ update, isVisible, setLoaderState, searchFormik, handleClickData }) => {
    const [data, setData] = useState([])
    const [labels, setLabels] = useState([])
    const dashboardService = new DashboardService();

    const { getProductivityReport } = dashboardService
    useEffect(() => {
        setLoaderState((prevState) => {
            return {
                ...prevState,
                productivityReportLoader: true
            }
        })
        let date = moment(searchFormik.values.date_range[0]).format('MM/DD/YYYY HH:mm:ss') + ' - ' + moment(searchFormik.values.date_range[1]).format('MM/DD/YYYY HH:mm:ss')
        getProductivityReport({ date_range: date, partners: searchFormik?.values?.partners, clients: searchFormik?.values?.clients, facilities: searchFormik?.values.facilities }).then((response) => {
            if (response && response.status) {
                setLabels(response?.data?.map(item => item?.client_name))
                setData(response?.data?.map(item => item?.calc_total))
                setLoaderState((prevState) => {
                    return {
                        ...prevState,
                        productivityReportLoader: false
                    }
                })
            }
        });
    }, [update])

    const options = {
        dataLabels: {
            enabled: true,
            style: {
                colors: ['black']
            },
            dropShadow: {
                enabled: false,
                enabledOnSeries: undefined,
                top: 0,
                left: 0,
                blur: 3,
                opacity: 0.35
            }
        },


        fill: {
            type: 'gradient',
        },
        chart: {
            id: 'basic-bar',
            type: 'bar',
            toolbar: {
                show: false
            },
            fontFamily: 'Open Sans',
            events: {
                dataPointSelection: handleClickData
            }
        },
        plotOptions: {
            bar: {
                distributed: true,
                columnWidth: '40px'
            },
        },
        grid: {
            borderColor: '#E4E4E4',
            xaxis: {
                lines: {
                    show: false
                }
            },
            yaxis: {
                lines: {
                    show: true
                }
            },
        },

        legend: {
            show: false
        },
        noData: {
            text: 'No data available',
        },
        // colors: ['#ad6baf', '#75ba7a', '#61d6f3', '#c7d07b', '#ffb981', '#00a1a8', '#166783', '#c7d07b', '#df8483', '#ffb237'],
        colors: ['#b2d3a3', '#9cadda', '#ffd993', '#c7d07b', '#f6b89d', '#abc7e7', '#969696', '#24B2DD', '#FFB11F', '#D98383'],

        xaxis: {
            categories: labels ?? [],
            axisBorder: {
                show: true,
                color: '#000000',
                height: 1,
                width: '100%',
                offsetX: 0,
                offsetY: 0
            },
            labels: {
                show: true,
                trim: true,
                hideOverlappingLabels: false,
                rotate: 0
            }
        },
        yaxis: {
            axisBorder: {
                show: true,
                color: '#000000',
                height: '100%',
                width: 1,
                offsetX: 0,
                offsetY: 0
            },
        },
    };

    console.log(labels, data)
    const series = [{
        name: 'CPH',
        data: data ? data : []
    }]
    return (
        isVisible ?
            <BarSkelitonLoader /> :
            <Chart options={options} series={series} type="bar" height={'90%'} width={(series?.[0]?.data?.length ?? 3) * 50 + 500 > 1000 ? ((series?.[0]?.data?.length ?? 3) * 50 + 500) : '100%'} />
    )
}

export default AllReportGraph