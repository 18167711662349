import React, { useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";

import { useMemo, useCallback } from "react";
import { Button, Form } from "react-bootstrap";
import moment from "moment";
import { useFormik } from "formik";
import { DateRangePicker } from "rsuite";
import { BiSearch } from "react-icons/bi";
import { Stack } from 'rsuite';
import subDays from 'date-fns/subDays';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import addDays from 'date-fns/addDays';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import addMonths from 'date-fns/addMonths';
import Sidebar from "../../includes/Sidebar";
import { InvoiceReportService } from "../../../services/InvoiceReportService";
import InvoiceReportDetails from "./InvoiceReportDetails";
import { BsCloudDownloadFill, BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import { changeDateFormat, downloadXl } from "../../../functions";
import { useDispatch, useSelector } from "react-redux";
import { selectIsSuperUser, selectPermissions } from "../../../slice/user/userSlice";
import { Tooltip } from "@mui/material";
import { seErrorMessage } from "../../../slice/error/errorSlice";
import Select, { components } from 'react-select';
import { endOfDay, startOfDay } from "date-fns";



const datatableCustomStyles = {
  rows: {
    style: {
      minHeight: '20px', // override the row height
      fontSize: '0.7em',
      fontColor: '#212529 !important',
      width: '100%'
    },
  },
  headCells: {
    style: {
      paddingLeft: '5px', // override the cell padding for head cells
      paddingRight: '5px',
      textTransform: "uppercase",
      fontSize: '0.7em',
      fontWeight: '800',
      fontColor: '#6c757d !important',
      opacity: 1,
      minHeight: "10px",
    },
  },
  cells: {
    style: {
      paddingLeft: '8px', // override the cell padding for data cells
      paddingRight: '8px',
      width: "100%",
    },
  },
  pagination: {
    style: {
      color: '#000',
      fontSize: '13px',
      minHeight: '56px',
      backgroundColor: '#fff',
      borderTopStyle: 'solid',
      borderTopWidth: '1px',
      borderTopColor: '#f2f2f2',
    },
    pageButtonsStyle: {
      borderRadius: '50%',
      height: '40px',
      width: '40px',
      padding: '8px',
      margin: '0px',
      cursor: 'pointer',
      transition: '0.4s',
      color: '#000',
      fill: '#000',
      backgroundColor: 'transparent',
      '&:disabled': {
        cursor: 'unset',
        color: '#e2e2e2',
        fill: '#e2e2e2',
      },
      '&:hover:not(:disabled)': {
        backgroundColor: '#f2f2f2',
      },
      '&:focus': {
        outline: 'none',
        backgroundColor: '#f5f5f5',
      },
    },
  },
};

function InvoiceReport() {
  const dispatch = useDispatch()
  const [grandTotal, setGrandTotal] = useState({
    isActive: false,
  });
  const [grandTotalData, setGrandTotalData] = useState({
    data: {}
  });

  const facilityRef = useRef(null)
  const clientRef = useRef(null)

  const [day, setDay] = useState(false)
  const [year, setYear] = useState(false)
  const [week, setWeek] = useState(false)
  const [month, setMonth] = useState(true)
  const [reRenderDataTable, setRerenderDataTable] = useState(false)
  const [pageno, setPageNo] = useState(1);
  const [loading, setLoading] = useState(false);
  const [isTimeCount, setisTimeCount] = useState(false);
  const [totalRows, setTotalRows] = useState(100);
  const [filterText, setFilterText] = useState('');
  const [colorRow, setColorRow] = React.useState({});
  const [data, setData] = useState({})
  const [limit, setLimit] = useState(100)
  const [showTotal, setShowTotal] = useState(false)
  const [loader, setLoader] = useState(false);
  const [clients, setClients] = useState([]);
  const [isFocusedStatus, setIsFocusedStatus] = useState(false);
  const [isFocusedFacility, setIsFocusedFacility] = useState(false);
  const [isFocusedPartner, setIsFocusedPartner] = useState(false);
  const [selectValue, setSelectValue] = useState([])
  const [facilities, setFacilities] = useState([])
  const [selectedPartners, setSelectedPartners] = useState([])

  const [selectIds, setSelectIds] = useState("")
  const [selectedFacility, setSelectedFacility] = useState([])
  const [selectedFacilityIds, setSelectedFacilityIds] = useState("")
  const [showDatePicker, setShowDatePicker] = useState(false);

  const [searchedData, setSearchedData] = useState({
    station: "",
    // date_range: `${moment(startOfMonth(new Date())).format('MM/DD/YYYY HH:mm:ss')} - ${moment(new Date()).format('MM/DD/YYYY HH:mm:ss')}`,
    date_range: "",
    hour_count: "",
    clients: [],
    facilities: [],
    partners: [],
    depo_date_range: [startOfMonth(new Date()), new Date()]

  })
  const [sort, setSort] = useState({
    field: 'date',
    type: 'DESC',
    date: false,
    client_name: false,
    facility_name: false,
    partner_name: false
  })

  const invoiceReportService = new InvoiceReportService();
  const { getInvoiceReportSummery, downloadInvoiceReportSummery, getClientPartnerDropdown, getPartnerDropdown, getFacilityDropdown, getInvoiceReportTotal } = invoiceReportService
  const permissions = useSelector(selectPermissions);
  const isSuperUser = useSelector(selectIsSuperUser);
  const isHavePermission = (name) => {
    return permissions?.includes(name) || isSuperUser
  }

  const predefinedBottomRanges = [
    {
      label: 'Today',
      value: [startOfDay(new Date()), new Date()],
      placement: 'left'
    },
    {
      label: 'Yesterday',
      value: [startOfDay(addDays(new Date(), -1)), endOfDay(addDays(new Date(), -1))],
      placement: 'left'
    },
    {
      label: 'This week',
      value: [startOfWeek(new Date()), endOfWeek(new Date())],
      placement: 'left'
    },
    {
      label: 'Last 7 days',
      value: [startOfDay(subDays(new Date(), 6)), new Date()],
      placement: 'left'
    },
    {
      label: 'Last 30 days',
      value: [startOfDay(subDays(new Date(), 29)), new Date()],
      placement: 'left'
    },
    {
      label: 'This month',
      value: [startOfMonth(new Date()), new Date()],
      placement: 'left'
    },
    {
      label: 'Last month',
      value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
      placement: 'left'
    },
    {
      label: 'This year',
      value: [new Date(new Date().getFullYear(), 0, 1), new Date()],
      placement: 'left'
    },
    {
      label: 'Last year',
      value: [new Date(new Date().getFullYear() - 1, 0, 1), endOfDay(new Date(new Date().getFullYear(), 0, 0))],
      placement: 'left'
    },

    {
      label: 'Last week',
      closeOverlay: false,
      value: value => {
        const [start = new Date()] = value || [];
        return [
          addDays(startOfWeek(start, { weekStartsOn: 0 }), -7),
          addDays(endOfWeek(start, { weekStartsOn: 0 }), -7)
        ];
      },
      appearance: 'default'
    },
    {
      label: 'Next week',
      closeOverlay: false,
      value: value => {
        const [start = new Date()] = value || [];
        return [
          addDays(startOfWeek(start, { weekStartsOn: 0 }), 7),
          addDays(endOfWeek(start, { weekStartsOn: 0 }), 7)
        ];
      },
      appearance: 'default'
    }
  ];


  const handlePerRowsChange = async (newPerPage, page) => {


    setLimit(newPerPage);
    setPageNo(1)
    fetchData(1, newPerPage, searchedData?.station, searchedData?.station, searchedData?.date_range ?? "", searchedData?.hour_count, searchedData?.clients, searchedData?.facilities, day, year, week, month, sort?.field, sort?.type, searchedData?.partners, searchedData?.depo_date_range);

    handleResetDataTable()

  };

  const handlePageChange = page => {
    setPageNo(page)
    fetchData(page, limit, searchedData?.station, searchedData?.station, searchedData?.date_range ?? "", searchedData?.hour_count, searchedData?.clients, searchedData?.facilities, day, year, week, month, sort?.field, sort?.type, searchedData?.partners, searchedData?.depo_date_range);
  };


  const fetchData = (page, limit, station, job_status, date_range, hour_count, clients, facilities, day, year, week, month, order_by, order_type, partners, depo_date_range) => {
    const depo_range = convertDateToStr(depo_date_range)
    setLoading(true)
    setisTimeCount(hour_count == 'true' || hour_count == '' ? false : true)
    getInvoiceReportSummery(page, limit, station, job_status, date_range, hour_count, clients, facilities, day, year, week, month, order_by, order_type, partners, depo_range).then((response) => {
      setData(response?.data);
      setLimit(limit);
      setPageNo(page)
      setTotalRows(response?.data?.count);
      setLoading(false);
      if (response?.errors?.date_range) {
        dispatch(seErrorMessage({ message: response?.errors?.date_range[0], is_show: true }))
      }
      return response?.data?.results
    });


  }

  const handleRowClickCheck = (row) => {
    data?.results.map((item, index) => {
      if (row?.id == item.id) {
        setColorRow(row)
      }
    })
  }
  const [expandedRows, setExpandedRows] = useState([]);
  const ExpandedComponent = useCallback(
    ({ data }) => (
      <pre>
        {expandedRows?.length > 0 && (
          <InvoiceReportDetails
            report_page={1}
            report_limit={limit}
            report_station={""}
            report_job_status={""}
            report_date_range={searchedData?.date_range}
            report_hour_count={searchedData?.hour_count}
            user={data.client_id}
            facility_id={data.facility_id}
            commom_filter={data.filter_data}
            day={day}
            year={year}
            week={week}
            month={month}
            depo_date_range={convertDateToStr(searchedData?.depo_date_range)}

          />
        )}
      </pre>
    ),
    [expandedRows, limit, searchedData]
  );
  // const ExpandedComponent = (row) => <pre>{expandedRows?.length > 0 && <InvoiceReportDetails report_page={1} report_limit={limit} report_station={""} report_job_status={""} report_date_range={searchedData?.date_range} report_hour_count={searchedData?.hour_count} user={row.data.status} />}</pre>;


  const handleRowExpand = useCallback((row) => {
    setColorRow(row);
    const isRowExpanded = expandedRows.includes(row);
    setExpandedRows(isRowExpanded ? expandedRows.filter((r) => r !== row) : [row]);
  });

  const searchFormik = useFormik({
    initialValues: {
      partners: "",
      search: "",
      job_status: "",
      station: "",
      date_range: [],
      created_date: ``,
      hour_count: "true",
      clients: [],
      facilities: "",
      depo_date_range: [startOfMonth(new Date()), new Date()]

    },
    validate: value => {
      console.log(value)
      const errors = {}
      if (!value?.date_range?.length > 0 && !value?.depo_date_range.length > 0) {
        errors.date_range = "Required"
        errors.depo_date_range = "Required"
      }
      return errors

    },
    onSubmit: (values) => {
      setGrandTotal({ ...grandTotal, isActive: false })

      values.day = day
      values.year = year
      values.week = week
      values.month = month
      const client_ids = selectValue?.map(i => i.value)
      const facility_ids = selectedFacility?.map(i => i.value)
      values.clients = client_ids
      values.facilities = facility_ids
      searchData(values)

      setSearchedData({
        search: values?.search ? values?.search : "",
        job_status: values?.job_status ? values?.job_status : "",
        station: values?.station ? values?.station : "",
        date_range: values?.created_date ? values?.created_date : "",
        clients: values?.clients,
        facilities: values?.facilities,
        hour_count: values?.hour_count ? values?.hour_count : "",
        partners: values?.partners ? values?.partners : [],
        depo_date_range: values?.depo_date_range,
      })

      handleResetDataTable()
    }
  })

  const columns = useMemo(
    () => [
      {
        id: 'S.NO',
        name: 'S.NO',
        selector: (row, index) => pageno == 1 ? index + 1 : (pageno - 1) * limit + index + 1,
        wrap: true,
        width: "40px",


      },
      {
        id: 'DATE',
        name: year ? 'YEAR' : week ? "WEEK" : month ? "MONTH" : "DATE",
        selector: row => row?.filter_data,
        cell: (row) =>

          <div>
            {row?.filter_data &&

              <div className='d-flex flex-row justify-content-between align-items-center gap-2' style={{ width: '120px' }}>
                <div className='overflow-hidden text-truncate' style={{ width: "120px" }} onClick={() => handleRowClick(row)}>
                  <Tooltip disableInteractive title={day ? changeDateFormat(row?.filter_data) : row?.filter_data} placement="top" arrow>
                    <span className="text-truncate"> {day ? changeDateFormat(row?.filter_data) : row?.filter_data}</span>
                  </Tooltip>
                </div>
              </div>

            }
          </div>
        ,

        sortable: true,
        left: true,
        wrap: true,
        reorder: false,

        expandOnRowClicked: true,
        // grow: 0,
        minWidth: '100px'
      },
      {
        id: 'PARTNER_NAME',
        name: 'PARTNER NAME',
        selector: row => row?.partner_name,
        cell: (row) =>
          <div>
            {row?.partner_name &&

              <div onClick={() => handleRowExpand(row)} className='d-flex flex-row justify-content-between align-items-center gap-2' style={{ width: '120px' }}>
                <div className='overflow-hidden text-truncate' style={{ width: "120px" }} >
                  <Tooltip disableInteractive title={row?.partner_name} placement="top" arrow>
                    <span className="text-truncate" > {row?.partner_name}</span>
                  </Tooltip>
                </div>
              </div>

            }
          </div>
        ,

        sortable: true,
        left: true,
        wrap: true,
        reorder: false,

        // grow: 0,
        minWidth: '130px',
        expandOnRowClicked: true,

      },
      {
        id: 'CLIENT_NAME',
        name: 'CLIENT NAME',
        selector: row => row?.name,
        cell: (row) =>
          <div>
            {row?.name &&

              <div onClick={() => handleRowExpand(row)} className='d-flex flex-row justify-content-between align-items-center gap-2' style={{ maxWidth: '120px' }}>
                <div className='overflow-hidden text-truncate' style={{ maxWidth: "120px" }} >
                  <Tooltip disableInteractive title={row?.name} placement="top" arrow>
                    <span className="text-truncate" > {row?.name}</span>
                  </Tooltip>
                </div>
              </div>

            }
          </div>
        ,

        sortable: true,
        left: true,
        wrap: true,
        reorder: false,

        expandOnRowClicked: true,
        // grow: 0,
        minWidth: '130px'


      },
      {
        id: 'FACILITY_NAME',
        name: 'FACILITY NAME',
        selector: row => row?.facility_name,
        cell: (row) =>
          <div>
            {row?.facility_name &&

              <div onClick={() => handleRowExpand(row)} className='d-flex flex-row justify-content-between align-items-center gap-2' style={{ width: '120px' }}>
                <div className='overflow-hidden text-truncate' style={{ width: "120px" }} >
                  <Tooltip disableInteractive title={row?.facility_name} placement="top" arrow>
                    <span className="text-truncate" > {row?.facility_name}</span>
                  </Tooltip>
                </div>
              </div>

            }
          </div>
        ,

        sortable: true,
        left: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        minWidth: '130px',

        expandOnRowClicked: true,

      },

      {
        id: 'TAT',
        name: 'TAT',
        selector: row => row?.tat,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.tat}</span>,

        right: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        minWidth: '50px',

      },
      {
        id: 'JOB_COUNT',
        name: 'JOB COUNT',
        selector: row => row?.job_count,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.job_count}</span>,

        right: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        minWidth: '100px',


      },
      {
        id: 'PAYMENT835',
        name: 'PAYMENT835',
        selector: row => row?.payment_835,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.payment_835}</span>,

        right: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        minWidth: '100px',


      },
      {
        id: 'REVISED_SL',
        name: 'REVISED SL',
        selector: row => row?.calc_payment_835,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.calc_payment_835}</span>,

        right: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        minWidth: '70px',

      },
      {
        id: 'PAYMENT835_TIME',
        name: 'TIME',
        selector: row => row?.payment_835_time,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.payment_835_time}</span>,

        right: true,
        wrap: true,
        reorder: false,

        // grow: 0,
        minWidth: '80px'

      },
      {
        id: 'SVC_LINES',
        name: '#SVC LINES',
        selector: row => row?.payment_835_servicelines,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.payment_835_servicelines}</span>,

        right: true,
        wrap: true,
        reorder: false,

        // grow: 0,
        minWidth: '80px'

      },
      {
        id: 'PA_837_USED',
        name: '837 USED',
        selector: row => row?.payment_837_used,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.payment_837_used}</span>,

        right: true,
        wrap: true,
        reorder: false,

        // grow: 0,
        minWidth: '80px'

      },
      {
        id: 'PA_837_MATCH',
        name: '837 MATCH %',
        selector: row => row?.payment_837_match,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.payment_837_match}</span>,

        right: true,
        wrap: true,
        reorder: false,

        // grow: 0,
        minWidth: '80px'

      },
      {
        id: 'CORRES 837 USED',
        name: 'CORRES 837 USED',
        selector: row => row?.corres_837_used,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.corres_837_used}</span>,

        right: true,
        wrap: true,
        reorder: false,

        // grow: 0,
        minWidth: '100px'

      },
      {
        id: 'CORRES_837_MATCH',
        name: 'CORRES 837 MATCH %',
        selector: row => row?.corres_837_match,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.corres_837_match}</span>,

        right: true,
        wrap: true,
        reorder: false,

        // grow: 0,
        minWidth: '120px'

      },
      {
        id: 'PAYMENT_LITE',
        name: 'PAYMENT LITE',
        selector: row => row?.payment_835_lite,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.payment_835_lite}</span>,

        right: true,
        wrap: true,
        reorder: false,

        // grow: 0,
        minWidth: '80px'

      },
      {
        id: 'PAYMENT_LITE_TIME',
        name: 'TIME',
        selector: row => row?.payment_835_lite_time,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.payment_835_lite_time}</span>,

        right: true,
        wrap: true,
        reorder: false,

        // grow: 0,
        minWidth: '70px'

      },
      {
        id: 'CORRES',
        name: 'CORRES',
        selector: row => row?.correspondence,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.correspondence}</span>,

        right: true,
        wrap: true,
        reorder: false,

        // grow: 0,
        minWidth: '70px'

      },


      {
        id: 'CORRES_TIME',
        name: 'TIME',
        selector: row => row?.correspondence_time,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.correspondence_time}</span>,

        right: true,
        wrap: true,
        reorder: false,

        // grow: 0,
        minWidth: '70px'

      },

      {
        id: 'PAT_PAY',
        name: 'PAT PAY',
        selector: row => row?.patientpay,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.patientpay}</span>,

        right: true,
        wrap: true,
        reorder: false,

        // grow: 0,
        minWidth: '60px'


      },
      {
        id: 'PAT_PAY_TIME',
        name: 'TIME',
        selector: row => row?.patientpay_time,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.patientpay_time}</span>,

        right: true,
        wrap: true,
        reorder: false,

        // grow: 0,
        minWidth: '60px'


      },
      {
        id: 'PP_837_USED',
        name: '837 USED',
        selector: row => row?.patientpay_837_used,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.patientpay_837_used}</span>,

        right: true,
        wrap: true,
        reorder: false,
        // grow: 0,


      },
      {
        id: 'PP_837_MATCH',
        name: '837 MATCH %',
        selector: row => row?.patientpay_837_match,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.patientpay_837_match}</span>,

        right: true,
        wrap: true,
        reorder: false,
        // grow: 0,


      },
      {
        id: 'SNIPPING_TIME',
        name: 'SNIPPING TIME',
        selector: row => row?.snipping_time,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.snipping_time}</span>,

        right: true,
        wrap: true,
        reorder: false,

        // grow: 0,
        minWidth: '80px'

      },

    ],

    [handleRowExpand, limit, pageno, isTimeCount],
  );

  var i = 0;
  const conditionalRowStyles = [

    {
      when: row => colorRow.id == row.id ? true : false,
      style: (row, index) => ({
        backgroundColor: colorRow?.id == row.id ? '#dbf4f5' : '#f2f2f2',
        color: 'black',
        // fontWeight: '550'
      }),
    },


  ];

  const handleSort = (field, type) => {
    console.log(field, type);
    let sort_key = "date"
    if (field.id == "CLIENT_NAME") {
      sort_key = "client_name"
    } else if (field.id == "PARTNER_NAME") {
      sort_key = "partner_name"
    } else if (field.id == "FACILITY_NAME") {
      sort_key = "facility_name"

    }

    let sort_type = sort.type === 'DESC' ? 'ASC' : 'DESC'
    setSort({
      field: sort_key,
      // type: type == 'desc' ? 'DESC' : 'ASC',
      type: sort_type
    })
    fetchData(pageno, limit, searchedData?.station, searchedData?.station, searchedData?.date_range ?? "", searchedData?.hour_count, searchedData?.clients, searchedData?.facilities, day, year, week, month, sort_key, sort_type, searchedData?.partners, searchedData?.depo_date_range);
  }
  const handleRowClick = (row) => {
    // setExpandedRows([])
    // setColorRow(row);
    setColorRow(row);
    const isRowExpanded = expandedRows.includes(row);
    setExpandedRows(isRowExpanded ? expandedRows.filter((r) => r !== row) : [row]);
  };



  const [datetime, setDateTime] = useState([]);

  const handleOnDateTimeChange = ({ name, value }) => {
    console.log(value);
    setExpandedRows([])
    let created_date = ""
    if (value !== null) {
      const startDate = value[0];
      const endDate = value[1];
      // Check if the start date is after the end date, and swap the dates if needed
      let isGreater = false
      if (startDate > endDate) {
        isGreater = true
      }

      const datefrom = moment(isGreater ? endDate : startDate).format('MM/DD/YYYY HH:mm:ss')
      const dateto = moment(isGreater ? startDate : endDate).format('MM/DD/YYYY HH:mm:ss')

      created_date = datefrom + " - " + dateto
    } else {
      // fetchData(1, limit, searchedData?.search, selectValue, "", searchedData?.hour_count, searchedData?.clients, searchedData?.facilities, day, year, week, month, sort?.field, sort?.type, searchedData?.partners, searchedData?.depo_date_range);

    }

    setDateTime(created_date)
    searchFormik.setValues({ ...searchFormik.values, created_date, date_range: value?.length > 0 ? value : "" })


  }

  const searchData = ({ station, job_status, date_range, hour_count, clients, facilities, day, year, week, month, order_by, order_type, partners, depo_date_range }) => {
    fetchData(1, limit, station, selectValue, datetime, hour_count, clients, facilities, day, year, week, month, order_by, order_type, partners, depo_date_range)
  }


  const showTooltip = (text, props) => (
    <Tooltip id="button-tooltip" {...props}>
      {text}
    </Tooltip>
  );


  const handleResetDataTable = () => {
    setRerenderDataTable(true)
    setTimeout(() => {
      setRerenderDataTable(false)
    }, 200);
  }

  const convertDateToStr = (value) => {
    let date = ""
    if (value?.length > 0) {

      const startDate = value[0];
      const endDate = value[1];
      // Check if the start date is after the end date, and swap the dates if needed
      let isGreater = false
      if (startDate > endDate) {
        isGreater = true
      }

      const datefrom = moment(isGreater ? endDate : startDate).format('MM/DD/YYYY')
      const dateto = moment(isGreater ? startDate : endDate).format('MM/DD/YYYY')

      date = datefrom + " - " + dateto
    }

    return date

  }

  const handleDownload = () => {
    setLoader(true)
    const depo_date_range = convertDateToStr(searchedData?.depo_date_range)
    downloadInvoiceReportSummery(1, limit, searchedData?.search, selectValue, datetime, searchedData?.hour_count, searchedData?.clients, searchedData?.facilities, day, year, week, month, sort?.field, sort?.type, searchedData?.partners, depo_date_range).then((response) => {
      setLoader(false)
      downloadXl(response?.data?.export_file)
    });
  }
  useEffect(() => {
    fetchData(1, limit, searchedData?.search, selectValue, datetime, searchedData?.hour_count, searchedData?.clients, searchedData?.facilities, day, year, week, month, "date", "DESC", searchedData?.partners, searchedData?.depo_date_range);

    getPartnerDropdown().then((response) => {
      if (response?.status) {
        setPartners(response?.data)
        getClientPartnerDropdown(response?.data?.map(i => i.value)).then((response) => {
          if (response?.status) {
            const data = response?.data?.partners
            const clientIdArr = []
            const FullClients = []
            setClients(data?.map(result => {
              result?.clients?.map(item => clientIdArr.push(item.value));
              FullClients.push({ options: result?.clients, label: result?.partner, id: result?.pk })
              return { options: result?.clients, label: result?.partner, id: result?.pk }

            }))
            setFullClients(FullClients)

            getFacilityDropdown(clientIdArr).then((response) => {
              if (response && response.status) {
                const feciltyIdArr = []
                const FullFacilities = []
                setFacilities(response.data.facilities?.map(result => {
                  result?.facilities?.map(item => feciltyIdArr.push(item.value));
                  FullFacilities.push({ options: result?.facilities, label: result?.client, id: result?.pk })
                  return { options: result?.facilities, label: result?.client, id: result?.pk }

                }))

                setFullFacilities(FullFacilities)
                const newArray = selectedFacility.filter(item => feciltyIdArr.includes(item.value));
                setSelectedFacility(newArray)
              }
            });
          }
        })

      }
    })
  }, [])
  // useEffect(() => {
  //   if (selectValue && selectValue.length > 0) {

  //     getFacilityDropdown(selectValue?.map((value) => value?.value)).then((response) => {
  //       if (response && response.status) {
  //         const feciltyIdArr = []
  //         setFacilities(response.data.facilities?.map(result => {
  //           console.log(result?.facilities, 'EEEEE')
  //           result?.facilities?.map(item => feciltyIdArr.push(item.value));
  //           return { options: result?.facilities, label: result?.client }

  //         }))
  //         const newArray = selectedFacility.filter(item => feciltyIdArr.includes(item.value));
  //         setSelectedFacility(newArray)
  //         console.log(selectedFacility, "UVAIS", feciltyIdArr, newArray)
  //       }
  //     });

  //   }

  // }, [selectValue])
  const setFacilityOptions = (selectedIds, dataList) => {
    const selectedData = selectedIds.length > 0 ? dataList.filter((item) => selectedIds.includes(item.id)) : dataList;
    console.log(fullClients, selectedData)
    setFacilities(selectedData)

  }
  const setCLientOptions = (selectedIds, dataList) => {
    const selectedData = selectedIds.length > 0 ? dataList.filter((item) => selectedIds.includes(item.id)) : dataList;
    console.log(fullClients, selectedData)
    setClients(selectedData);
    // getClientPartnerDropdown(clientId).then((response) => {
    //   if (response?.status) {
    //     const clientIdArr = []
    //     setClients(response.data.partners?.map(result => {
    //       result?.clients?.map(item => clientIdArr.push(item.value));
    //       return { options: result?.clients, label: result?.partner, id: result?.pk }

    //     }))
    //     // getFacilityDropdown(clientIdArr).then((response) => {
    //     //   if (response && response.status) {
    //     //     const feciltyIdArr = []
    //     //     const FullFacilities = []
    //     //     setFacilities(response.data.facilities?.map(result => {
    //     //       result?.facilities?.map(item => feciltyIdArr.push(item.value));
    //     //       FullFacilities.push({ options: result?.facilities, label: result?.client, id: result?.pk })
    //     //       return { options: result?.facilities, label: result?.client, id: result?.pk }

    //     //     }))

    //     //     setFullFacilities(FullFacilities)
    //     //     const newArray = selectedFacility.filter(item => feciltyIdArr.includes(item.value));
    //     //     setSelectedFacility(newArray)
    //     //   }
    //     // });

    //   }
    // })
  }

  const [fullFacilities, setFullFacilities] = useState([])
  const [fullClients, setFullClients] = useState([])

  const [partners, setPartners] = useState([])

  const checkSelectedFecilityInitialClient = (selectedOptions, selectedList, allList) => {
    // Initialize an empty result array and a set to track unique labels
    var result = [...selectedList];
    var uniqueLabels = new Set();
    const clientId = selectedList?.map((value) => String(value?.value))

    // Loop through selected options
    for (var i = 0; i < selectedOptions.length; i++) {
      var selectedOption = selectedOptions[i];

      // Loop through the list of dictionaries to find matching options
      for (var j = 0; j < allList.length; j++) {
        var options = allList[j].options;
        for (var k = 0; k < options.length; k++) {
          if (options[k].value === selectedOption.value && options[k].label === selectedOption.label) {
            // Check if the label is unique before adding it to the result
            console.log(clientId, allList[j].id)
            if (!uniqueLabels.has(allList[j].label) && !clientId.includes(String(allList[j].id))) {
              result.push({
                "value": Number(allList[j].id),
                "label": allList[j].label,
              });
              uniqueLabels.add(allList[j].label);
            }
            break;
          }
        }
      }
    }
    console.log("Result:", result);
    return result
  }
  function getParentInfo(ids, dataList) {
    const parentInfoMap = {};
    const parentInfoIds = [];

    ids.forEach((id) => {
      const item = dataList.find((data) => data.options.some((option) => option.value === id));

      if (item) {
        parentInfoMap[item.id] = item.label;
        parentInfoIds.push(Number(item.id));
      }
    });

    return parentInfoIds;
  }
  function getClientInfo(ids, dataList) {
    const parentInfoMap = {};

    ids.forEach((id) => {
      const item = dataList.find((data) => data.options.some((option) => option.value === id));

      if (item) {
        parentInfoMap[item.id] = item.label;
      }
    });

    return [parentInfoMap];
  }
  // const handleSelectChange = (val, name = null) => {

  //   console.log(val)
  //   let selectedOptions = [];
  //   let selectedIds = [];

  //   if (val && val?.length > 0) {

  //     val?.map((option) => {
  //       selectedIds.push(option.value)
  //       selectedOptions.push(option)
  //     })

  //   } else {

  //     setSelectedFacility([])
  //     setSelectedFacilityIds([])
  //   }

  //   if (name == "facility") {
  //     const initiaClientsOptions = checkSelectedFecilityInitialClient(selectedOptions, selectValue, fullFacilities)
  //     const clientIds = initiaClientsOptions?.map((value) => value?.value)
  //     setSelectedFacility(selectedOptions)
  //     setSelectedFacilityIds(selectedIds)
  //     setSelectValue(initiaClientsOptions)
  //     // setFacilityOptions(clientIds, fullFacilities)
  //     const partnersIds = getParentInfo(clientIds, fullClients)
  //     setCLientOptions(partnersIds, fullClients)

  //     const currentPartners = searchFormik.values.partners;
  //     const uniquePartners = Array.from(new Set([...currentPartners, ...partnersIds]));

  //     searchFormik.setValues({
  //       ...searchFormik.values,
  //       partners: uniquePartners

  //     })
  //   } else {
  //     // setClients(val.includes('all') ? ['all'] : selectedId)
  //     if (!(val && val?.length > 0)) {
  //       if (clients && clients.length > 0) {
  //         // const clientId = clients?.map((value) => value?.id)
  //         console.log(fullFacilities)
  //         // setFacilityOptions([], fullFacilities)

  //         getFacilityDropdown().then((response) => {
  //           if (response && response.status) {
  //             const feciltyIdArr = []
  //             const FullFacilities = []
  //             setFacilities(response.data.facilities?.map(result => {
  //               result?.facilities?.map(item => feciltyIdArr.push(item.value));
  //               FullFacilities.push({ options: result?.facilities, label: result?.client, id: result?.pk })
  //               return { options: result?.facilities, label: result?.client, id: result?.pk }

  //             }))

  //           }
  //         });

  //         setClients(fullClients)

  //       }

  //     } else {

  //       // if (name == 'partner') {
  //       //   getClientPartnerDropdown(selectedIds).then((response) => {
  //       //     if (response?.status) {
  //       //       const data = response?.data?.partners
  //       //       const clientIdArr = []
  //       //       const FullClients = []
  //       //       setClients(data?.map(result => {
  //       //         result?.clients?.map(item => clientIdArr.push(item.value));
  //       //         FullClients.push({ options: result?.clients, label: result?.partner, id: result?.pk })
  //       //         return { options: result?.clients, label: result?.partner, id: result?.pk }

  //       //       }))
  //       //     }
  //       //   })
  //       // }






  //       getFacilityDropdown(selectedIds).then((response) => {
  //         if (response && response.status) {
  //           const feciltyIdArr = []
  //           setFacilities(response.data.facilities?.map(result => {
  //             console.log(result?.facilities, 'EEEEE')
  //             result?.facilities?.map(item => feciltyIdArr.push(item.value));
  //             return { options: result?.facilities, label: result?.client }

  //           }))

  //           const newArray = selectedFacility.filter(item => feciltyIdArr.includes(item.value));
  //           setSelectedFacility(newArray)
  //         }
  //       });
  //       const partnersIds = getParentInfo(selectedIds, fullClients)
  //       const currentPartners = searchFormik.values.partners;
  //       const uniquePartners = Array.from(new Set([...currentPartners, ...partnersIds]));
  //       searchFormik.setValues({
  //         ...searchFormik.values,
  //         partners: uniquePartners

  //       })
  //     }
  //     setSelectValue(selectedOptions)
  //     setSelectIds(selectedIds)
  //     setSelectedFacility([])

  //   }
  // };

  const handleSelectChange = (val, name = null) => {
    let selectedOptions = [];
    let selectedIds = [];
    if (val && val?.length > 0) {

      val?.map((option) => {
        selectedIds.push(option.value)
        selectedOptions.push(option)
      })
    }
    if (name == 'facility') {
      setSelectedFacility(selectedOptions)
      setSelectedFacilityIds(selectedIds)
    }
    else if (name == 'client') {
      setSelectedFacility([])
      setFacilities([])
      setSelectValue(selectedOptions)
      setSelectIds(selectedIds)
      const feciltyIdArr = []
      console.log(clients)
      let clientoptions = []
      clients?.map(client => {
        client?.options?.map(item => {
          clientoptions.push(item?.value)
        })
      })
      getFacilityDropdown(selectedIds?.length ? selectedIds : clientoptions).then((response) => {
        if (response && response.status) {
          setFacilities(response.data.facilities?.map(result => {
            result?.facilities?.map(item => feciltyIdArr.push(item.value));
            return { options: result?.facilities, label: result?.client }

          }))
          const newArray = selectedFacility.filter(item => feciltyIdArr.includes(item.value));
          setSelectedFacility(selectedIds?.length ? newArray : [])
        }
      });
    }
    else if (name == 'partner') {
      setSelectedPartners(selectedOptions)
      setSelectedFacility([])
      setFacilities([])
      setClients([])
      setSelectValue([])
      searchFormik.setFieldValue("partners", selectedIds)
      getClientPartnerDropdown(selectedIds?.length ? selectedIds : null).then((response) => {
        if (response?.status) {

          const data = response?.data?.partners
          const clientIdArr = []
          setClients(data?.map(result => {
            result?.clients?.map(item => clientIdArr.push(item.value));
            return { options: result?.clients, label: result?.partner, id: result?.pk }
          }))
          const newArray = selectValue.filter(item => clientIdArr.includes(item.value));
          setSelectValue(selectedIds?.length ? newArray : [])
          let clientIds = []
          newArray?.map((item) => {
            clientIds.push(item.value)
          })
          console.log(selectedIds?.length, clientIds)
          getFacilityDropdown(selectedIds?.length && clientIds?.length ? clientIds : clientIdArr).then((response) => {
            if (response && response.status) {
              const feciltyIdArr = []
              setFacilities(response.data.facilities?.map(result => {
                result?.facilities?.map(item => feciltyIdArr.push(item.value));
                return { options: result?.facilities, label: result?.client, id: result?.pk }

              }))
              const newArray = selectedFacility.filter(item => feciltyIdArr.includes(item.value));
              setSelectedFacility(selectedIds?.length ? newArray : [])

            }
          });
        }
      })
    }
  }
  const handleSelectPartnerChange = (val) => {
    console.log(val)
    let selectedOptions = [];
    let selectedIds = [];

    if (val && val?.length > 0) {

      val?.map((option) => {
        selectedIds.push(option.value)
        selectedOptions.push(option)
      })
      getClientPartnerDropdown(selectedIds).then((response) => {
        if (response?.status) {

          const data = response?.data?.partners
          const clientIdArr = []
          const FullClients = []
          setClients(data?.map(result => {
            result?.clients?.map(item => clientIdArr.push(item.value));
            FullClients.push({ options: result?.clients, label: result?.partner, id: result?.pk })


            return { options: result?.clients, label: result?.partner, id: result?.pk }

          }))
          const newArray = selectValue.filter(item => clientIdArr.includes(item.value));
          setSelectValue(newArray)
          // const clientIdArr = []

          // setClients(response.data.partners?.map(result => {
          //   result?.clients?.map(item => clientIdArr.push(item.value));
          //   return { options: result?.clients, label: result?.partner, id: result?.pk }

          // }))
          getFacilityDropdown(clientIdArr).then((response) => {
            if (response && response.status) {
              const feciltyIdArr = []
              const FullFacilities = []
              setFacilities(response.data.facilities?.map(result => {
                result?.facilities?.map(item => feciltyIdArr.push(item.value));
                FullFacilities.push({ options: result?.facilities, label: result?.client, id: result?.pk })
                return { options: result?.facilities, label: result?.client, id: result?.pk }

              }))

              setFullFacilities(FullFacilities)
              const newArray = selectedFacility.filter(item => feciltyIdArr.includes(item.value));
              setSelectedFacility(newArray)
            }
          });

        }
      })
      setSelectedPartners(selectedOptions)
      searchFormik.setFieldValue("partners", selectedIds)
    } else {

      setSelectedFacility([])
      setSelectValue([])
      setClients(fullClients)
      setFacilities(fullFacilities)
      searchFormik.setFieldValue("partners", [])

    }



  };
  const handleDetailFilterChange = (name) => {
    const hasMissingValues = Object.values(searchFormik.errors);
    if (!hasMissingValues.length > 0) {
      debugger

      if (name === 'year') {
        setYear(true)
        setWeek(false)
        setMonth(false)
        setDay(false)
        fetchData(1, limit, searchedData?.station, searchedData?.station, searchedData?.date_range ?? "", searchedData?.hour_count, searchedData?.clients, searchedData?.facilities, false, true, false, false, sort?.field, sort?.type, searchedData?.partners, searchedData?.depo_date_range);

      }
      else if (name === 'day') {
        setYear(false)
        setWeek(false)
        setMonth(false)
        setDay(true)
        fetchData(1, limit, searchedData?.station, searchedData?.station, searchedData?.date_range ?? "", searchedData?.hour_count, searchedData?.clients, searchedData?.facilities, true, false, false, false, sort?.field, sort?.type, searchedData?.partners, searchedData?.depo_date_range);


      }
      else if (name === 'month') {
        setYear(false)
        setWeek(false)
        setMonth(true)
        setDay(false)
        fetchData(1, limit, searchedData?.station, searchedData?.station, searchedData?.date_range ?? "", searchedData?.hour_count, searchedData?.clients, searchedData?.facilities, false, false, false, true, sort?.field, sort?.type, searchedData?.partners, searchedData?.depo_date_range);

      }
      else if (name === 'week') {
        setYear(false)
        setWeek(true)
        setMonth(false)
        setDay(false)
        fetchData(1, limit, searchedData?.station, searchedData?.station, searchedData?.date_range ?? "", searchedData?.hour_count, searchedData?.clients, searchedData?.facilities, false, false, true, false, sort?.field, sort?.type, searchedData?.partners, searchedData?.depo_date_range);

      }
    }

  }

  const secondDiv = document.querySelector(".custom-datatable");

  useEffect(() => {
    if (!secondDiv) return;

    const observer = new MutationObserver(mutations => {
      const duplicateRdtTable = secondDiv.querySelector(".custome-rdt_TableHead");
      if (duplicateRdtTable) duplicateRdtTable.remove();

      const rdtTable = secondDiv.querySelector(".rdt_TableHead");
      if (!rdtTable) return;

      const clonedTable = rdtTable.cloneNode(true);
      clonedTable.classList.add("custome-rdt_TableHead");
      if (grandTotal.isActive) {
        clonedTable.classList.remove("none-div-class");
        clonedTable.classList.add("display-div-class");
      }
      else {

        clonedTable.classList.add("none-div-class");
        clonedTable.classList.remove("display-div-class");
        // setGrandTotal({ ...grandTotal, isActive: false })


      }

      clonedTable.querySelectorAll(".rdt_TableCol").forEach(cell => {
        const inputElement = cell.querySelector("input");
        const spanElement = cell.querySelector("span");
        if (spanElement) spanElement.remove();
        if (inputElement) inputElement.remove();

        const divElement = cell.querySelector("div");
        const contentDiv = divElement?.querySelector("div");
        if (contentDiv) {
          switch (contentDiv.getAttribute('data-column-id')) {
            case "SNIPPING_TIME":
              divElement.setAttribute("id", "id_total_snipping_time")
              divElement.innerHTML = grandTotalData?.data?.total_snipping_time ?? "";
              break;
            case "JOB_COUNT":
              divElement.setAttribute("id", "id_job_count")
              divElement.innerHTML = grandTotalData?.data?.job_count ?? "";
              break;
            case "PAYMENT835":
              divElement.setAttribute("id", "id_total_payment_835")
              divElement.innerHTML = grandTotalData?.data?.total_payment_835 ?? "";
              break;
            case "REVISED_SL":
              divElement.setAttribute("id", "id_total_payment_835_calc")
              divElement.innerHTML = grandTotalData?.data?.total_payment_835_calc ?? "";
              break;
            case "PAYMENT835_TIME":
              divElement.setAttribute("id", "id_total_payment_835_time")
              divElement.innerHTML = grandTotalData?.data?.total_payment_835_time ?? "";
              break;
            case "PAYMENT_LITE":
              divElement.setAttribute("id", "id_total_payment_835_lite")
              divElement.innerHTML = grandTotalData?.data?.total_payment_835_lite ?? "";
              break;
            case "PAYMENT_LITE_TIME":
              divElement.setAttribute("id", "id_total_payment_835_lite_time")
              divElement.innerHTML = grandTotalData?.data?.total_payment_835_lite_time ?? "";
              break;
            case "CORRES":
              divElement.setAttribute("id", "id_total_correspondence")
              divElement.innerHTML = grandTotalData?.data?.total_correspondence ?? "";
              break;
            case "CORRES_TIME":
              divElement.setAttribute("id", "id_total_correspondence_time")
              divElement.innerHTML = grandTotalData?.data?.total_correspondence_time ?? "";
              break;
            case "PAT_PAY":
              divElement.setAttribute("id", "id_total_patient_pay")
              divElement.innerHTML = grandTotalData?.data?.patientpay ?? "";
              break;
            case "PAT_PAY_TIME":
              divElement.setAttribute("id", "id_total_patient_pay_time")
              divElement.innerHTML = grandTotalData?.data?.total_patientpay_time ?? "";
              break;
            case "PP_837_USED":
              divElement.setAttribute("id", "id_total_patientpay_837_used")
              divElement.innerHTML = grandTotalData?.data?.total_patientpay_837_used ?? "";
              break;
            case "PP_837_MATCH":
              divElement.setAttribute("id", "id_total_patientpay_837_percentage")
              divElement.innerHTML = grandTotalData?.data?.total_patientpay_837_percentage ?? "";
              break;
            case "PA_837_USED":
              divElement.setAttribute("id", "id_total_payment_837_used")
              divElement.innerHTML = grandTotalData?.data?.total_payment_837_used ?? "";
              break;
            case "PA_837_MATCH":
              divElement.setAttribute("id", "id_total_payment_837_percentage")
              divElement.innerHTML = grandTotalData?.data?.total_payment_837_percentage ?? "";
              break;
            case "SVC_LINES":
              divElement.setAttribute("id", "id_total_payment_835_servicelines")
              divElement.innerHTML = grandTotalData?.data?.total_payment_835_servicelines ?? "";
              break;

            default:
              divElement.innerHTML = "";
          }
        }
      });

      const customeRdtTable = secondDiv.querySelector(".rdt_Table");
      customeRdtTable.appendChild(clonedTable);

      observer.disconnect();
    });

    observer.observe(secondDiv, { childList: true, subtree: true });
  }, [secondDiv, loading, grandTotal, grandTotalData.data]);
  // adding total row in the bottom of the data table END
  const fetchTotalQueueListing = async (page, limit, station, job_status, date_range, hour_count, clients, facilities, day, year, week, monthList, order_by, order_type, partners, depo_date_range) => {
    const depo_date_range1 = convertDateToStr(searchedData?.depo_date_range)

    setLoading(true);
    getInvoiceReportTotal(page, limit, station, job_status, date_range, hour_count, clients, facilities, day, year, week, monthList, order_by, order_type, partners, depo_date_range1).then((response) => {

      if (response?.status) {

        setGrandTotalData({ ...grandTotalData, data: response.data })

        setTimeout(() => {
          // setKunjani(true)
          setLoading(false);
        }, 200);


      }
    });

  };
  const handleTotalJobMonitor = async () => {
    const duplicateRdtTable = secondDiv?.querySelector(".custome-rdt_TableHead");
    if (duplicateRdtTable) {
      if (duplicateRdtTable.classList.contains('none-div-class')) {
        // if the element has the "none-div-class" class, remove it and add the "display-div-class" class
        duplicateRdtTable.classList.remove('none-div-class');
        duplicateRdtTable.classList.add('display-div-class');
        setGrandTotal({ ...grandTotal, isActive: true })

        // fetch the data and update the table
        fetchTotalQueueListing(pageno, limit, searchedData?.station, searchedData?.station, searchedData?.date_range ?? "", searchedData?.hour_count, searchedData?.clients, searchedData?.facilities, day, year, week, month, sort?.field, sort?.type, searchedData?.partners, searchedData?.depo_date_range, duplicateRdtTable);

      } else {
        // if the element does not have the "none-div-class" class, remove the "display-div-class" class and add the "none-div-class" class
        setGrandTotal({ ...grandTotal, isActive: false })
        duplicateRdtTable.classList.remove('display-div-class');
        duplicateRdtTable.classList.add('none-div-class');

      }
    } else {
      if (grandTotal.isActive) {
        setGrandTotal({ ...grandTotal, isActive: false })
      } else {
        setGrandTotal({ ...grandTotal, isActive: true })

      }
      fetchTotalQueueListing(pageno, limit, searchedData?.station, searchedData?.station, searchedData?.date_range ?? "", searchedData?.hour_count, searchedData?.clients, searchedData?.facilities, day, year, week, month, sort?.field, sort?.type, searchedData?.partners, searchedData?.depo_date_range, null);

    }
    // check if the element has the "none-div-class" class

  }
  useEffect(() => {
    const inputs = document.getElementsByTagName("input");
    for (var i = 0; i < inputs.length; i++) {
      console.log(inputs[i])
      inputs[i].setAttribute('autocomplete', 'one-time-code')
    }
  }, [])

  return (
    <React.Fragment>
      {/* <Header /> */}
      <Sidebar />
      <main id="main" className="main">

        <div className="pagetitle tab-fixed-header">
          <h1 id='page-title'>Invoice Report</h1>
        </div>

        <section className="section mt-5 ">
          <div className="row align-items-top py-2 gap-2 gap-md-5">
            <div className="col-lg-12">
              <div className="card mb-5 mb-xl-8 " style={{ height: "92vh" }}>

                <Form onSubmit={searchFormik.handleSubmit} >
                  <div className=" ">
                    <div className="px-1 py-1 d-flex flex-row justify-content-start gap-2 align-items-center flex-wrap">
                      <div>
                        <Select
                          options={partners}
                          className=""
                          classNamePrefix="select"
                          placeholder="Partner"
                          menuPlacement="auto"
                          maxMenuHeight={300}
                          isClearable
                          isMulti
                          name="partners"
                          tabSelectsValue={false}
                          onChange={(val) => {
                            handleSelectChange(val, 'partner')
                          }} onFocus={() => setIsFocusedPartner(true)}
                          onBlur={() => setIsFocusedPartner(false)}
                          value={partners.filter((item) => searchFormik.values.partners?.includes(item.value))}
                          hideSelectedOptions={false}
                          styles={{
                            container: (styles, state) => ({
                              ...styles,
                              borderRadius: "9px",
                            }),
                            control: (styles, state) => ({
                              ...styles,
                              backgroundColor: "hsl(204deg 33.33% 97.06%)",
                              borderStyle: "none", boxShadow: "none",
                              borderRadius: "10px",
                              maxHeight: "30px",
                            }),
                            indicatorSeparator: styles => ({
                              ...styles, width: "0px",
                              minHeight: "20px",
                              maxHeight: "30px",
                            }),

                            valueContainer: (styles, state) => ({
                              ...styles,
                              maxHeight: "30px",
                              overflow: 'scroll',
                            }),
                            option: (styles, state) => ({
                              ...styles,
                              backgroundColor: state.isSelected ? '#d9f4f5' : 'inherit',
                              color: state.isSelected ? '#000000' : 'inherit',
                              '&:hover': { backgroundColor: state.isSelected ? '#d9f4f5' : 'rgb(222, 235, 255)' }

                            })
                          }}
                          menuPortalTarget={document.body}
                          closeMenuOnSelect={false} // Keep the menu open after selecting an option

                        />
                      </div>
                      <div>
                        <Select
                          ref={clientRef}
                          tabSelectsValue={false}
                          isMulti
                          options={clients}
                          value={selectValue}
                          className=""
                          classNamePrefix="select"
                          placeholder="Client"
                          menuPlacement="auto"
                          maxMenuHeight={300}
                          isClearable
                          name="client"
                          onChange={(val) => handleSelectChange(val, 'client')}
                          hideSelectedOptions={false}
                          onFocus={() => setIsFocusedStatus(true)}
                          onBlur={() => setIsFocusedStatus(false)}
                          styles={{
                            container: (styles, state) => ({
                              ...styles,
                              borderRadius: "9px",
                            }),
                            control: (styles, state) => ({
                              ...styles,
                              backgroundColor: "hsl(204deg 33.33% 97.06%)",
                              borderStyle: "none", boxShadow: "none",
                              borderRadius: "10px",
                              maxHeight: "30px",
                            }),
                            indicatorSeparator: styles => ({
                              ...styles, width: "0px",
                              minHeight: "20px",
                              maxHeight: "30px",
                            }),

                            valueContainer: (styles, state) => ({
                              ...styles,
                              maxHeight: "30px",
                              overflow: 'scroll',
                            }),
                            option: (styles, state) => ({
                              ...styles,
                              backgroundColor: state.isSelected ? '#d9f4f5' : 'inherit',
                              color: state.isSelected ? '#000000' : 'inherit',
                              '&:hover': { backgroundColor: state.isSelected ? '#d9f4f5' : 'rgb(222, 235, 255)' }

                            })
                          }}
                          menuPortalTarget={document.body}
                          closeMenuOnSelect={false} // Keep the menu open after selecting an option

                        />
                      </div>
                      <div>
                        <Select
                          ref={facilityRef}
                          options={facilities}
                          className=""
                          classNamePrefix="select"
                          placeholder="Facility"
                          menuPlacement="auto"
                          maxMenuHeight={300}
                          isClearable
                          isMulti
                          name="facility"
                          value={selectedFacility}
                          tabSelectsValue={false}
                          hideSelectedOptions={false}
                          onChange={(val) => handleSelectChange(val, 'facility')}
                          onFocus={() => setIsFocusedFacility(true)}
                          onBlur={() => setIsFocusedFacility(false)}

                          styles={{
                            container: (styles, state) => ({
                              ...styles,
                              borderRadius: "9px",
                            }),
                            control: (styles, state) => ({
                              ...styles,
                              backgroundColor: "hsl(204deg 33.33% 97.06%)",
                              borderStyle: "none", boxShadow: "none",
                              borderRadius: "10px",
                              maxHeight: "30px",
                            }),
                            indicatorSeparator: styles => ({
                              ...styles, width: "0px",
                              minHeight: "20px",
                              maxHeight: "30px",
                            }),

                            valueContainer: (styles, state) => ({
                              ...styles,
                              maxHeight: "30px",
                              overflow: 'scroll',
                            }),
                            option: (styles, state) => ({
                              ...styles,
                              backgroundColor: state.isSelected ? '#d9f4f5' : 'inherit',
                              color: state.isSelected ? '#000000' : 'inherit',
                              '&:hover': { backgroundColor: state.isSelected ? '#d9f4f5' : 'rgb(222, 235, 255)' }

                            })
                          }}
                          menuPortalTarget={document.body}
                          closeMenuOnSelect={false} // Keep the menu open after selecting an option

                        />
                      </div>

                      <Tooltip disableInteractive title={"Deposit Date"} placement="top" arrow>

                        <div className={searchFormik.errors.depo_date_range ? "error invoice-report-date correspondenceDatePicker" : "invoice-report-date correspondenceDatePicker"}>
                          <Stack direction="column" spacing={2} alignItems="flex-start">

                            <DateRangePicker
                              id={'date-time-picker-id111'}
                              isClearable={true}
                              cleanable={true}
                              autocomplete={false}
                              ranges={predefinedBottomRanges}
                              placement="auto"
                              appearance="default"
                              format="MM/dd/yyyy"
                              // format={"MM/dd/yyyy"}
                              className="form-date-control form-control-solid b-r-1 text-dark custom-daterange"
                              placeholder={"MM/DD/YYYY - MM/DD/YYYY"}
                              name="depo_date_range"
                              onChange={(value) => {
                                if (value !== null) {
                                  searchFormik.setFieldValue("depo_date_range", value)
                                } else {
                                  searchFormik.setFieldValue("depo_date_range", [])
                                }
                              }}
                              value={searchFormik?.values?.depo_date_range} />
                          </Stack>
                        </div>
                      </Tooltip>
                      <Tooltip disableInteractive title={"Processed Date"} placement="top" arrow>

                        <div className={searchFormik.errors.date_range ? "error invoice-report-date correspondenceDatePicker" : "invoice-report-date correspondenceDatePicker"}>
                          <Stack direction="column" spacing={8} alignItems="flex-start">

                            <DateRangePicker
                              id={'date-time-picker-id'}
                              cleanable={true}
                              isClearable={true}
                              ranges={predefinedBottomRanges}
                              autocomplete="one-time-code"
                              placement="auto"
                              appearance="default"
                              format="MM/dd/yyyy HH:mm:ss"
                              // format={"MM/dd/yyyy"}
                              className="form-date-control form-control-solid b-r-1 text-dark custom-daterange"
                              placeholder={"MM/DD/YYYY - MM/DD/YYYY"}
                              name="date_range"
                              onChange={(e) => { handleOnDateTimeChange({ name: 'date_range', value: e }) }}
                              value={searchFormik?.values?.date_range}
                            />
                          </Stack>
                        </div>
                      </Tooltip>
                      <div>
                        <Tooltip disableInteractive title={"Search"} placement="top" arrow>
                          <Button className="btn btn-light btn-active-primary" type="submit">
                            <span className="svg-icon svg-icon-3">
                              <BiSearch />
                            </span>

                          </Button>
                        </Tooltip>
                      </div>
                      {/* {searchedData.date_range && */}
                      <div className="px-3 pt-1 d-flex flex-row justify-content-start gap-2 align-items-center flex-wrap">

                        <div>
                          <Tooltip disableInteractive title={"Year"} placement="top" arrow>
                            <div class="form-check">
                              <input class="form-check-input me-1" type="radio" onChange={() => handleDetailFilterChange('year')} checked={year} name="year" id="year1" />
                              <label class="form-check-label" for="year1" style={{ fontSize: "14px", fontWeight: "600" }}>
                                Year
                              </label>
                            </div>
                          </Tooltip>

                        </div>
                        <div>
                          <Tooltip disableInteractive title={"Month"} placement="top" arrow>

                            <div class="form-check">
                              <input class="form-check-input me-1" type="radio" onChange={() => handleDetailFilterChange('month')} checked={month} name="month" id="month2" />
                              <label class="form-check-label" for="month2" style={{ fontSize: "14px", fontWeight: "600" }}>
                                Month
                              </label>
                            </div>
                          </Tooltip>

                        </div>


                        <div>
                          <Tooltip disableInteractive title={"Week"} placement="top" arrow>

                            <div class="form-check">
                              <input class="form-check-input me-1" onChange={() => handleDetailFilterChange('week')} checked={week} type="radio" name="week" id="week3" />
                              <label class="form-check-label" for="week3" style={{ fontSize: "14px", fontWeight: "600" }}>
                                Week
                              </label>
                            </div>
                          </Tooltip>

                        </div>
                        <div>
                          <Tooltip disableInteractive title={"Day"} placement="top" arrow>

                            <div class="form-check">
                              <input class="form-check-input me-1" onChange={() => handleDetailFilterChange('day')} checked={day} type="radio" name="flexRadioDefault" id="flexRadioDefault4" />
                              <label class="form-check-label" for="flexRadioDefault4" style={{ fontSize: "14px", fontWeight: "600" }}>
                                Day
                              </label>
                            </div>
                          </Tooltip>

                        </div>
                      </div>
                      {/* } */}
                      {isHavePermission("export_invoice_report_management") &&
                        <div>
                          <Tooltip disableInteractive title={"Download"} placement="top" arrow>
                            {!loader ?
                              <Button onClick={() => { handleDownload() }} className="btn btn-light btn-active-primary" >
                                <span className="svg-icon svg-icon-3">
                                  <BsCloudDownloadFill />
                                </span>

                              </Button> :
                              <Button className="btn btn-light btn-active-primary" >
                                <span className="svg-icon svg-icon-3">
                                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                </span>

                              </Button>}
                          </Tooltip>

                        </div>
                      }
                      <Tooltip disableInteractive title={"View Total"} placement="top" arrow>
                        {grandTotal.isActive ?
                          <Button onClick={() => { handleTotalJobMonitor(false) }} className="btn btn-light btn-active-primary" >
                            <span className="svg-icon svg-icon-3">
                              <BsFillEyeFill />
                            </span>

                          </Button>
                          :
                          <Button onClick={() => { handleTotalJobMonitor(false) }} className="btn btn-light btn-active-primary" >
                            <span className="svg-icon svg-icon-3">
                              <BsFillEyeSlashFill />
                            </span>

                          </Button>
                        }

                      </Tooltip>

                    </div>
                  </div>

                </Form>


                <div className="card-body p-common">
                  <div className="table-responsive">
                    <div className="dashboardtable">
                      {!reRenderDataTable &&
                        <DataTable
                          className="custom-datatable"
                          fixedHeader={true}
                          progressPending={loading}
                          data={data?.results}
                          columns={columns}
                          pointerOnHover={true}
                          pagination
                          paginationDefaultPage={pageno}

                          paginationServer
                          onRowClicked={handleRowClick}
                          sortServer={true}
                          onSort={handleSort}
                          // highlightOnHover
                          paginationTotalRows={totalRows}
                          paginationPerPage={limit}
                          paginationRowsPerPageOptions={[25, 50, 100, 150, 200]}
                          onChangeRowsPerPage={handlePerRowsChange}
                          onChangePage={handlePageChange}
                          conditionalRowStyles={conditionalRowStyles}
                          theme="solarized"
                          customStyles={datatableCustomStyles}
                          striped={true}
                          expandableRows
                          expandOnRowClicked={false}
                          onRowExpandToggled={handleRowExpand}
                          expandableRowsComponent={ExpandedComponent}
                          expandableRowsHideExpander
                          expandableRowExpanded={(row) => expandedRows.includes(row)}

                        />
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>



      </main >
    </React.Fragment >
  )
}
export default InvoiceReport




