import { createSlice } from "@reduxjs/toolkit";

const initialState = localStorage.getItem("user")
  ? JSON.parse(localStorage.getItem("user"))
  : {
    user_id: "",
    username: "",
    email: "",
    permissions: "",
    is_password_reset: "",
    is_superuser: "",
    sync_time: "",
    token: "",
    refresh: "",
    is_login: false
  };

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserDetails: (state, action) => {
      state.user_id = action.payload.user_id;
      state.username = action.payload.username;
      state.email = action.payload.email;
      state.permissions = action.payload.permissions;
      state.is_password_reset = action.payload.is_password_reset;
      state.is_superuser = action.payload.is_superuser;
      state.sync_time = action.payload.sync_time;
      state.token = action.payload.token;
      state.refresh = action.payload.refresh;
      state.is_login = action.payload.is_login;
      localStorage.setItem("user", JSON.stringify(action.payload));
    },

    setSignOutState: (state) => {
      state.user_id = null;
      state.username = null;
      state.email = null;
      state.permissions = null;
      state.is_password_reset = null;
      state.is_superuser = null;
      state.sync_time = null;
      state.token = null;
      state.refresh = null;
      state.is_login = false;
      localStorage.removeItem("user");

    },
  },
});

export const { setUserDetails, setSignOutState } = userSlice.actions;

export const selectIsSuperUser = (state) => state.user.is_superuser;
export const selectUserName = (state) => state.user.username;
export const selectSyncTime = (state) => state.user.sync_time;
export const selectIsPasswordReset = (state) => state.user.is_password_reset;
export const selectIsLogin = (state) => state.user.is_login;
export const selectPermissions = (state) => state.user.permissions;

export default userSlice.reducer;
