import styled from 'styled-components';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { OverlayTrigger, Toast, ToastContainer } from 'react-bootstrap';
import "../../assets/css/dashboard.css"
import Button from 'react-bootstrap/Button';
import DataTable from 'react-data-table-component';
import Form from 'react-bootstrap/Form';
import moment from 'moment';
import { BiHide, BiShow, BiExport, iFile, BiReset, BiSearch, BiDownload } from 'react-icons/bi';
import { TbBoxMultiple } from 'react-icons/tb';
import { BsCloudDownloadFill, BsEye } from 'react-icons/bs';
import { HiChevronDown } from 'react-icons/hi';
import { IoIosSwitch, IoMdCheckbox } from 'react-icons/io';
import { TbGridDots } from 'react-icons/tb';
import Select, { components } from 'react-select';
import DateRangePicker from 'rsuite/DateRangePicker';
import * as Yup from 'yup';


import { Stack } from 'rsuite';
import subDays from 'date-fns/subDays';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import addDays from 'date-fns/addDays';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import addMonths from 'date-fns/addMonths';
import { Tooltip } from '@mui/material';
import { DataListService } from '../../services/DataListService';
import queueDatatableCustomStyles from './queueDatatableCustomStyles';
import { downloadFile, downloadXl } from '../../functions';
import { endOfDay, startOfDay } from "date-fns";


const TextField = styled.input`
    height: 32px;
    width: 200px;
    &:hover {
        cursor: pointer;
    }
    `;
const TH = styled.th`
    :first-child{
        padding-left:10px !important;
    }
    padding-top:10px!important;
    padding-bottom:10px!important;
   
    `;
const TR = styled.tr`
   
    `;
var input = {
    client: '',
    file: ''
};



const DataList = () => {
    const dataListService = new DataListService();
    const { filterQueueDetails, getUserlist, getClientGrouplist, getClientlist, exportDataList, filterTotalQueueDetails, downloadMultipleReport, getSnippetImage } = dataListService

    const [grandTotal, setGrandTotal] = useState({
        isActive: false,
    });
    const [grandTotalData, setGrandTotalData] = useState({
        data: {}
    });

    const [staffList, setStaffList] = useState();
    const [clientGroupList, setClientGroupList] = useState();
    const [pageno, setPageNo] = useState(1);
    const [settings, setSettings] = useState();
    const [toaster, setToaster] = useState(null);
    const [value, onChange] = useState(new Date());
    const [tatDate, setTatDate] = useState('')
    const [tatTime, setTatTime] = useState('')
    const [sortColumn, setSortColumn] = useState('');
    const [sortOrder, setSortOrder] = useState('desc');
    // const dispatch = useDispatch()
    const [show, setShow] = useState(false);
    const [exportLoader, setExportLoader] = useState(false);
    //const [is_multiple, setIs_multiple] = useState(true);

    let { lockbox_file } = useParams();


    useEffect(() => {
        getUserlist().then((response) => {
            setStaffList(response?.data?.results)
        });
        getClientGrouplist().then((response) => {
            setClientGroupList(response?.data)
        });
        getClientlist().then((response) => {

            setClientList(response?.data)
        })
    }, []);


    const navigate = useNavigate();
    const handleSort = async (column, order) => {

        setSortColumn(column?.sort_key);
        setSortOrder(order);

        fetchQueueListing(pageno, limit, searchedData?.staff, searchedData.client, searchedData.client_group, searchedData.status, searchedData?.filename, searchedData?.job_unique, searchedData?.check_number, searchedData?.batch_id, searchedData?.start_date, searchedData?.end_date, order, column.sort_key, searchedData?.tat_status, searchedData?.station);

    };



    const predefinedBottomRanges = [
        {
            label: 'Today',
            value: [startOfDay(new Date()), new Date()],
            placement: 'left'
        },
        {
            label: 'Yesterday',
            value: [startOfDay(addDays(new Date(), -1)), endOfDay(addDays(new Date(), -1))],
            placement: 'left'
        },
        {
            label: 'This week',
            value: [startOfWeek(new Date()), endOfWeek(new Date())],
            placement: 'left'
        },
        {
            label: 'Last 7 days',
            value: [startOfDay(subDays(new Date(), 6)), new Date()],
            placement: 'left'
        },
        {
            label: 'Last 30 days',
            value: [startOfDay(subDays(new Date(), 29)), new Date()],
            placement: 'left'
        },
        {
            label: 'This month',
            value: [startOfMonth(new Date()), new Date()],
            placement: 'left'
        },
        {
            label: 'Last month',
            value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
            placement: 'left'
        },
        {
            label: 'This year',
            value: [new Date(new Date().getFullYear(), 0, 1), new Date()],
            placement: 'left'
        },
        {
            label: 'Last year',
            value: [new Date(new Date().getFullYear() - 1, 0, 1), endOfDay(new Date(new Date().getFullYear(), 0, 0))],
            placement: 'left'
        },

        {
            label: 'Last week',
            closeOverlay: false,
            value: value => {
                const [start = new Date()] = value || [];
                return [
                    addDays(startOfWeek(start, { weekStartsOn: 0 }), -7),
                    addDays(endOfWeek(start, { weekStartsOn: 0 }), -7)
                ];
            },
            appearance: 'default'
        },
        {
            label: 'Next week',
            closeOverlay: false,
            value: value => {
                const [start = new Date()] = value || [];
                return [
                    addDays(startOfWeek(start, { weekStartsOn: 0 }), 7),
                    addDays(endOfWeek(start, { weekStartsOn: 0 }), 7)
                ];
            },
            appearance: 'default'
        }
    ];
    /////////////////////////////////////////////////////////////////



    const handleResetCallFetch = async () => {

        const currentUrl = window.location.pathname; // Get the current URL
        const decodedUrl = decodeURI(currentUrl); // Decode the URL
        const newUrl = encodeURI(decodedUrl.replace(/\%20/g, '')); // Remove all occurrences of '%20' and encode the new URL
        // const currentUrl2 = SINGLE_LOCKBOX_LOCKBOX_FILES + file_id; // Get the current URL
        // const decodedUrl2 = decodeURI(currentUrl); // Decode the URL
        const newUrl2 = encodeURI(decodedUrl.replace(/\%20/g, '')); // Remove all occurrences of '%20' and encode the new URL

        if (newUrl == newUrl2) {
            // navigate(QMS)
            window.location.reload()

        } else {
            window.location.reload()

        }

    }

    const [disableTotal, setDisableTotal] = useState(false)
    const handleFileDownload = (file) => {
        getSnippetImage(file).then((response) => {
            // setStaffList(response?.data?.results)
            const fileURL = response?.data;
            let alink = document.createElement('a');
            alink.href = fileURL;
            alink.download = 'SamplePDF.pdf';
            alink.target = '_blank';
            alink.click();
        });


    }
    const handleExportJobMonitor = async (page, limit, staff, client, client_group, status, filename, job_unique, check_number, batch_id, start_date, end_date, sort_order, sort_key, tat_status, station) => {
        if (!exportLoader) {
            setGrandTotal({ ...grandTotal, isActive: false })

            setExportLoader(true)
            setDisableTotal(true)
            let header_names1 = [];

            const headerMap = {
                deposit_date: 'DEPOSIT DATE',
                processed_date: 'PROCESSED DATE',
                client: 'CLIENT',
                facility: 'FACILITY',
                job_file_name: "JOB FILE NAME",
                job_id: "JOB ID",
                job_status: "JOB STATUS",
                lock_box_file: "LOCKBOX FILE NAME",
                batch_id: "BATCH ID",
                document_type: "DOCUMENT TYPE",
                // qdx_type: "QDX TYPE",
                internal_tat: "INTERNAL TAT",
                external_tat: "EXTERNAL TAT",
                check_number: "CHECK NUMBER",
                index_amount: "INDEX AMOUNT",
                check_amount: "CHECK AMOUNT",
                eft_amount: "EFT AMOUNT",
                payer: "PAYER",
                pages: "NO OF PAGES",
                claims: "NO OF CLAIMS",
                servicelines: "NO OF SERVICE LINES",
                fields: "NO OF FIELDS",
                corrected_fields: "NO OF CORRECTED FIELDS",
                autofilled_fields: "NO OF AUTOFILLED FIELDS",
                no_of_key_fields: "NO OF KEYED FIELDS",
                user_name: "USER NAME",
                user_start: "USER START TIME",
                user_end: "USER END TIME",
                user_total_time: "USER TOTAL TIME",
                qa_name: "QA NAME",
                qa_start_time: "QA START TIME",
                qa_end_time: "QA END TIME",
                qa_total_time: "QA TOTAL TIME",
                snipping_name: "SNIPPING NAME",
                snipping_start_time: "SNIPPING START TIME",
                snipping_end_time: "SNIPPING END TIME",
                snipping_total_time: "SNIPPING TOTAL TIME",
                additionalinfo: "ADDITIONAL INFO STATUS",
                plb_amt: "PLB AMOUNT",
                calc_claims: "REVISED SL",
                used837: "837 USED CLAIMS",
                cph: "CPH",
                comment: "COMMENT",
            };

            console.log(state)
            for (let key in state) {
                console.log(key)
                if (state && state[key]) {
                    const headerName = headerMap[key];
                    if (headerName) {
                        header_names1.push(headerName);
                    }
                }
            }


            // Select all elements with class "sc-jJTsDX dFomoq" within the specified div
            const headings = document.querySelectorAll('.rdt_TableCol .rdt_TableCol_Sortable div');

            // Extract the text content of each heading
            const header_names = Array.from(headings)
                .map((heading) => heading.textContent)
                .filter((text) => text !== 'S.NO' && text !== 'Action');


            exportDataList({ page, limit, staff, client, client_group, status, filename, job_unique, check_number, batch_id, start_date, end_date, sort_order, sort_key, tat_status, header_names, station }).then((response) => {

                if (!response?.data?.status && response?.status) {
                    setExportLoader(false)
                    handleResetCheckBox()
                    setDisableTotal(false)

                    downloadXl(response?.data?.export_file)

                } else {
                    setExportLoader(false)
                    handleResetCheckBox()
                    setDisableTotal(false)

                    console.log("ERROR");
                }
            });
        }
    }


    /////////////////////////////////////////////////////////////

    const [state, setState] = useState({
        deposit_date: true,
        processed_date: true,
        client: true,
        facility: true,
        job_file_name: true,
        job_id: true,
        job_status: true,
        lock_box_file: true,
        batch_id: true,
        document_type: true,
        // qdx_type: true,
        internal_tat: true,
        external_tat: true,
        check_number: true,
        index_amount: true,
        check_amount: true,
        eft_amount: true,
        payer: true,
        pages: true,
        claims: true,
        servicelines: true,
        fields: true,
        corrected_fields: true,
        autofilled_fields: true,
        no_of_key_fields: true,
        user_name: true,
        user_start: true,
        user_end: true,
        user_total_time: true,
        qa_name: true,
        qa_start_time: true,
        qa_end_time: true,
        qa_total_time: true,
        snipping_name: true,
        snipping_start_time: true,
        snipping_end_time: true,
        snipping_total_time: true,
        additionalinfo: true,
        plb_amt: true,
        calc_claims: true,
        used837: true,
        cph: true,
        comment: true,


    });
    const [loading, setLoading] = useState(false);


    const [totalRows, setTotalRows] = useState(100);
    const [limit, setLimit] = useState(100);
    const [selectedRows, setSelectedRows] = useState([]);
    const [colorRow, setColorRow] = React.useState({});
    const [data, setData] = useState({})


    const [isFocusedStaff, setIsFocusedStaff] = useState(false);
    const [isFocusedClient, setIsFocusedClient] = useState(false);
    const [isFocusedGroup, setIsFocusedGroup] = useState(false);
    const [isFocusedTat, setIsFocusedTat] = useState(false);
    const [isFocusedStatus, setIsFocusedStatus] = useState(false);

    useEffect(() => {
        if (toaster) {
            const timer = setTimeout(() => {
                setToaster(false)
            }, 2000);
            return () => clearTimeout(timer);
        }
    }, [toaster])


    const handleSingleReportDetails = async (log_id) => {
        navigate('/view-work/' + log_id)
    }


    const handleSerialNumber = (index) => {
        return pageno == 1 ? index + 1 : (pageno - 1) * limit + index + 1
    }
    const columns = useMemo(
        () => [
            // {
            //     id: 'asdf',
            //     name: '',
            //     cell: (row) => <span style={{ position: 'absolute', right: '40px' }} > {row?.is_large_batch_job && !row?.is_sub_job && <TbBoxMultiple />}</span>
            //     ,
            //     width: "5px",
            //     compact: true,
            //     wrap: true,
            //     reorder: true,
            //     // omit: !state.client,
            //     ignoreRowClick: false,

            // },
            {
                id: 'id',
                name: 'S.NO',
                selector: (row, index) => handleSerialNumber(index),
                // cell: (row, index) => <span style={{ paddingRight: '10px' }} onClick={() => handleRowClick(row)}> {pageno == 1 ? index + 1 : ((pageno - 1) * limit) + index + 1}</span>,
                width: "30px",
                compact: true,
                wrap: true,
                reorder: true,
                // omit: !state.client,
                ignoreRowClick: false,

            },
            {
                id: 'action',
                name: 'Action',
                width: '50px',
                reorder: true,
                // omit: !state.status,
                cell: (row) =>
                    <React.Fragment>
                        {/* {row?.job_status == 'Snipping Completed' && row?.snippet_image && row?.snippet_image != '' && */}
                        <div style={row?.job_status == 'Snipping Completed' && row?.snippet_image && row?.snippet_image != '' ? { visibility: 'visible' } : { visibility: 'hidden' }} className="me-2 d-flex justify-content-center align-items-center gap-2 job-monitor-table-wrapper">

                            <React.Fragment>
                                <Tooltip disableInteractive title="Snippet Image" placement='top' arrow>
                                    <div className={''} style={{ fontSize: '1.5em' }}

                                        onClick={() => { handleFileDownload(row?.snippet_image) }}

                                    >
                                        <BiDownload color={"#000000"} />
                                    </div>
                                </Tooltip>
                            </React.Fragment>




                        </div>
                        {/* } */}
                        {/* {row?.job_status != 'Rejected' && row?.job_status != 'Re Assigned' && !row?.is_freeze && */}
                        <div style={row?.job_status != 'Rejected' && row?.job_status != 'Re Assigned' && !row?.is_freeze && row.is_last ? { visibility: 'visible' } : { visibility: 'hidden' }} className="d-flex justify-content-center align-items-center gap-2 job-monitor-table-wrapper">


                            <React.Fragment>
                                <Tooltip disableInteractive title='View' placement='top' arrow>
                                    <div className={''} style={{ fontSize: '1.5em' }}

                                        onClick={() => { handleSingleReportDetails(row.job_instance) }}

                                    >
                                        <BsEye color={"#000000"} />
                                    </div>
                                </Tooltip>
                            </React.Fragment>




                        </div>
                        {/* } */}
                    </React.Fragment>
                ,
                // ignoreRowClick: true,
                // allowOverflow: false,
                // button: true,
                center: true,
                omit: !state.action
            },
            {
                id: 'DEPOSIT DATE',
                name: 'DEPOSIT DATE',
                sort_key: "deposit_date",
                selector: row => moment(row?.deposit_date).format('MM/DD/YY'),
                cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.deposit_date}</span>,
                // cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.deposit_date ? moment(row?.deposit_date).format('MM/DD/YY') : ""}</span>,
                sortable: true,
                reorder: true,
                left: true,
                wrap: true,
                grow: 0.2,
                omit: !state.deposit_date,
            },
            {
                id: 'PROCESSED DATE',
                name: 'PROCESSED DATE',
                sort_key: "processed_date",
                selector: row => moment(row?.processed_date).format('MM/DD/YY'),
                cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.processed_date}</span>,
                // cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.processed_date ? moment(row?.processed_date).format('MM/DD/YY') : ""}</span>,
                sortable: true,
                reorder: true,
                left: true,
                wrap: true,
                // grow: 0.3,
                omit: !state.processed_date,
            },

            {
                id: 'CLIENT',
                name: 'CLIENT',
                sort_key: "client",
                selector: row => row?.client,
                cell: (row) => <Tooltip disableInteractive title={row?.client} placement='top' arrow>
                    <span className='text-truncate' onClick={() => handleRowClick(row)} onDoubleClick={(e) => { handleDblClickCopy(row?.client); }}>{row?.client}</span>
                </Tooltip>,
                sortable: true,
                wrap: true,
                grow: 0.1,
                reorder: true,
                omit: !state.client,
                ignoreRowClick: false,

            },
            {
                id: 'FACILITY',
                name: 'FACILITY',
                sort_key: "facility",
                selector: row => row?.facility,
                cell: (row, index) => <div>
                    {row?.facility &&
                        <div onClick={() => handleRowClick(row)} className='overflow-hidden d-flex flex-row  flex-wrap  text-truncate ' style={{ width: "120px" }}>
                            <Tooltip disableInteractive title={row?.facility} placement='top' arrow>
                                {row?.facility &&
                                    <span key={index} className='text-truncate ' onDoubleClick={() => handleDblClickCopy(row?.facility)}>{row?.facility}</span>
                                }
                            </Tooltip>
                        </div>
                    }
                </div>
                ,
                sortable: true,
                reorder: true,
                left: true,
                wrap: true,
                width: "140px",
                grow: 0.1,
                omit: !state.facility,
            },
            {
                // row?.job?.eob_sftpfile?.file_name
                id: 'JOB FILE NAME',
                name: 'JOB FILE NAME',
                sort_key: "job_file_name",
                sortable: true,
                selector: row => row?.job_file_name,
                cell: (row) => <div className='d-flex flex-row align-items-center gap-2' onClick={() => handleRowClick(row)}>
                    {row?.job_file_name &&
                        <React.Fragment>
                            <div style={{ width: '120px' }}
                                onDoubleClick={(e) => { handleDblClickCopy(row?.job_file_name); }}
                                className='overflow-hidden d-flex flex-row  flex-wrap text-truncate' >
                                <Tooltip disableInteractive title={row?.job_file_name} placement='top' arrow>
                                    <span className='text-truncate' > {row?.job_file_name}</span>
                                </Tooltip>
                            </div>
                            {/* <div
                                // style={{ maxWidth: "10%" }} 
                                className='' >
                                <div role="button" onClick={() => onButtonClick(row?.splited_file)} className=''>
                                    <i className={'bi bi-download text-black '} style={{ fontSize: '1.3em' }} />
                                </div>
                            </div> */}
                        </React.Fragment>
                    }
                </div>,
                // sortable: true,
                width: '160px',
                left: true,
                wrap: true,
                reorder: true,
                grow: 1.3,
                omit: !state.job_file_name,
            },
            {
                id: 'JOB ID',
                name: 'JOB ID',
                sort_key: "job_id",
                selector: row => row?.job_id,
                cell: (row) =>
                    <span onClick={() => handleRowClick(row)} onDoubleClick={() => handleDblClickCopy(row?.job_id)}>
                        {row?.job_id}
                    </span>,
                sortable: true,
                wrap: true,
                // grow: 0.1,
                width: "120px",
                reorder: true,
                omit: !state.job_id,


            },
            {
                id: 'JOB STATUS',
                name: 'JOB STATUS',
                sort_key: 'job_status',
                selector: row => row?.job_status,
                cell: (row) => <Tooltip disableInteractive title={row?.job_status} placement='top' arrow>
                    <span className='text-truncate' onClick={() => handleRowClick(row)}> {row?.job_status}</span>
                </Tooltip>,
                //sortable: true,
                reorder: true,
                left: true,
                wrap: true,
                width: "130px",
                omit: !state.job_status,
            },

            {
                // row?.job?.eob_sftpfile?.file_name
                id: 'LOCKBOX FILE NAME',
                name: 'LOCKBOX FILE NAME',
                sort_key: "lock_box_file",
                sortable: true,
                selector: row => row?.lock_box_file,
                cell: (row) => <div className='d-flex flex-row gap-2' onClick={() => handleRowClick(row)}>
                    {row?.lock_box_file &&
                        <React.Fragment>

                            <div style={{ width: '120px' }} className='overflow-hidden d-flex flex-row  flex-wrap text-truncate'
                                onDoubleClick={() => handleDblClickCopy(row?.lock_box_file)}
                            >
                                <Tooltip disableInteractive title={row?.lock_box_file} placement='top' arrow>
                                    <span className='text-truncate'> {row?.lock_box_file}</span>
                                </Tooltip>
                            </div>
                            <div
                                // style={{ maxWidth: "10%" }} 
                                className='' >
                                {/* <div role="button" onClick={() => onButtonClick(row?.converted_file)} className=''>
                                    <i className={'bi bi-download text-black '} style={{ fontSize: '1.3em' }} />
                                </div> */}
                            </div>
                        </React.Fragment>
                    }
                </div>,
                width: '140px',
                left: true,
                wrap: true,
                reorder: true,
                grow: 1.5,
                omit: !state.lock_box_file,
            },
            {
                id: 'BATCH ID',
                name: 'BATCH ID',
                sort_key: "batch_id",
                selector: row => row?.batch_id,
                cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.batch_id}</span>,
                sortable: true,
                reorder: true,
                left: true,
                wrap: true,
                grow: 0.3,
                omit: !state.batch_id,
            },
            {
                id: 'DOCUMENT TYPE',
                name: 'DOCUMENT TYPE',
                sort_key: "document_type",
                selector: row => row?.document_type,
                //cell: (row) => <span className='text-truncate' onClick={() => handleRowClick(row)}> {row?.document_type}</span>,
                cell: (row, index) => <div>
                    {row?.document_type &&
                        <div className='text-truncate d-flex flex-row  flex-wrap' onClick={() => handleRowClick(row)} style={{ width: '120px' }}>
                            <Tooltip
                                disableInteractive
                                title={row?.document_type} placement='top' arrow>
                                {row?.document_type &&
                                    <span className='text-truncate' key={index} onDoubleClick={() => handleDblClickCopy(row?.document_type)}>{row?.document_type}</span>
                                }
                            </Tooltip>
                        </div>
                    }
                </div>,
                // sortable: true,
                reorder: true,
                left: true,
                wrap: true,
                grow: 0.1,
                width: "140px",
                omit: !state.document_type,
            },
            // {
            //     id: 'QDX TYPE',
            //     name: 'QDX TYPE',
            //     sort_key: "qdx_type",
            //     selector: row => row?.qdx_type,
            //     //cell: (row) => <span className='text-truncate' onClick={() => handleRowClick(row)}> {row?.qdx_type}</span>,
            //     cell: (row, index) => <div>
            //         {row?.qdx_type &&
            //             <div className='text-truncate d-flex flex-row  flex-wrap' onClick={() => handleRowClick(row)} style={{ width: '120px' }}>
            //                 <Tooltip
            //                     disableInteractive
            //                     title={row?.qdx_type} placement='top' arrow>
            //                     {row?.qdx_type &&
            //                         <span className='text-truncate' key={index} onDoubleClick={() => handleDblClickCopy(row?.qdx_type)}>{row?.qdx_type}</span>
            //                     }
            //                 </Tooltip>
            //             </div>
            //         }
            //     </div>,
            //     // sortable: true,
            //     reorder: true,
            //     left: true,
            //     wrap: true,
            //     grow: 0.1,
            //     width: "140px",
            //     omit: !state.qdx_type,
            // },
            {
                id: 'INTERNAL TAT',
                name: 'INTERNAL TAT',
                sort_key: "internal_tat",
                selector: row => row?.internal_tat,
                cell: (row) => <span className='text-truncate' onClick={() => handleRowClick(row)}>{row.internal_tat}</span>,
                // cell: (row) => <span className='text-truncate' onClick={() => handleRowClick(row)}>{row.internal_tat ? moment(row.internal_tat).format('M/DD/YY, H:mm') : "---"}</span>,
                sortable: true,
                reorder: true,
                left: true,
                wrap: true,
                grow: 0.5,
                omit: !state.internal_tat,
            },

            {      // sachu told to remove this on 26-04-2023
                id: 'EXTERNAL TAT',
                name: 'EXTERNAL TAT',
                selector: row => row?.external_tat,
                cell: (row) => <span className='text-truncate' onClick={() => handleRowClick(row)}>{row.external_tat}</span>,
                // selector: row => row.external_tat ? moment(row.external_tat).format('M/D/Y, h:mm a') : "---",
                sortable: true,
                reorder: true,
                left: true,
                wrap: true,
                grow: 0.5,
                omit: !state.external_tat,
            },

            {
                id: 'CHECK NUMBER',
                name: 'CHECK NUMBER',
                sort_key: "check_number",
                //sortable: true,
                selector: row => parseInt(row?.check_number ? row?.check_number : 0),
                cell: (row) => <div className=' '

                    onDoubleClick={() => handleDblClickCopy(row?.check_number)}
                    onClick={() => handleRowClick(row)}>
                    <span>

                        {row?.check_number}
                    </span>
                </div>,
                // sortable: true,
                width: "110px",
                reorder: true,
                left: true,
                grow: 1.1,
                omit: !state.check_number,
            },

            {
                id: 'INDEX AMOUNT',
                name: 'INDEX AMOUNT',
                sort_key: "index_amount",
                sortable: true,
                selector: row => parseInt(row?.index_amount ? row?.index_amount : 0),
                cell: (row) => <div className='d-flex flex-row justify-content-center flex-wrap' onClick={() => handleRowClick(row)}>
                    {parseFloat(row?.index_amount ? row?.index_amount : 0).toFixed(2)}
                </div>,
                // sortable: true,
                reorder: true,
                left: false,
                right: true,
                wrap: true,
                grow: 0.1,
                omit: !state.index_amount,
            },

            {
                id: 'CHECK AMOUNT',
                name: 'CHECK AMOUNT',
                sort_key: "check_amount",
                //sortable: true,
                selector: row => parseInt(row?.check_amount ? row?.check_amount : 0),
                cell: (row) => <div className='d-flex flex-row justify-content-center flex-wrap' onClick={() => handleRowClick(row)}>
                    {parseFloat(row?.check_amount ? row?.check_amount : 0).toFixed(2)}
                </div>,
                // sortable: true,
                reorder: true,
                left: false,
                right: true,
                wrap: true,
                grow: 0.1,
                omit: !state.check_amount,
            },

            {
                id: 'EFT AMOUNT',
                name: 'EFT AMOUNT',
                sort_key: "eft_amount",
                //sortable: true,
                selector: row => parseInt(row?.eft_amount ? row?.eft_amount : 0),
                cell: (row) => <div className='d-flex flex-row justify-content-center flex-wrap' onClick={() => handleRowClick(row)}>
                    {parseFloat(row?.eft_amount ? row?.eft_amount : 0).toFixed(2)}
                </div>,
                // sortable: true,
                reorder: true,
                left: false,
                right: true,
                wrap: true,
                grow: 0.2,
                omit: !state.eft_amount,
            },

            {
                id: 'PAYER',
                name: 'PAYER',
                sort_key: "payer",
                //sortable: true,
                selector: row => row?.payer,
                cell: (row) =>
                    <div className=' d-flex flex-row flex-wrap text-truncate justify-content-start ' style={{ width: '120px' }} onClick={() => handleRowClick(row)}>
                        <Tooltip disableInteractive title={row?.payer} placement='top' arrow>
                            <span className='text-truncate'>{row?.payer}</span>

                        </Tooltip>
                    </div>,
                // sortable: true,
                reorder: true,
                left: true,
                wrap: true,
                width: "140px",
                grow: 0.2,
                omit: !state.payer,
            },

            {
                id: 'NO OF PAGES',
                name: 'NO OF PAGES',
                sort_key: "pages",
                selector: row => row?.pages,
                cell: (row) => <div className='text-end'> <span onClick={() => handleRowClick(row)}> {row?.pages}</span></div>,
                sortable: true,
                reorder: true,
                left: true,
                wrap: true,
                grow: 0.1,
                omit: !state.pages,
            },

            {
                id: 'NO OF CLAIMS',
                name: 'NO OF CLAIMS',
                sort_key: "claims",
                selector: row => row?.claims,
                cell: (row) => <div className='text-end'> <span onClick={() => handleRowClick(row)}> {row?.claims}</span></div>,
                //sortable: true,
                reorder: true,
                left: true,
                wrap: true,
                grow: 0.1,
                omit: !state.claims,
            },

            {
                id: 'NO OF SERVICE LINES',
                name: 'NO OF SERVICE LINES',
                sort_key: "servicelines",
                selector: row => row?.servicelines,
                cell: (row) => <div className='text-end'> <span onClick={() => handleRowClick(row)}> {row?.servicelines}</span></div>,
                sortable: true,
                width: '120px',
                reorder: true,
                left: true,
                wrap: true,
                grow: 0.1,
                omit: !state.servicelines,
            },
            {
                id: 'NO OF FIELDS',
                name: 'NO OF FIELDS',
                sort_key: "fields",
                selector: row => row?.fields,
                cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.fields}</span>,
                sortable: true,
                reorder: true,
                left: true,
                wrap: true,
                grow: 0.1,
                omit: !state.fields,
            },

            {
                id: 'NO OF CORRECTED FIELDS',
                name: 'NO OF CORRECTED FIELDS',
                sort_key: "corrected_fields",
                selector: row => row?.corrected_fields,
                cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.corrected_fields}</span>,
                sortable: true,
                reorder: true,
                width: '120px',
                left: true,
                wrap: true,
                grow: 0.2,
                omit: !state.corrected_fields,
            },
            {
                id: 'NO OF AUTOFILLED FIELDS',
                name: 'NO OF AUTOFILLED FIELDS',
                sort_key: "autofilled_fields",
                selector: row => row?.autofilled_fields,
                cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.autofilled_fields}</span>,
                sortable: true,
                width: '120px',
                reorder: true,
                left: true,
                wrap: true,
                grow: 0.2,
                omit: !state.autofilled_fields,
            },

            {
                id: 'NO OF KEYED FIELDS',
                name: 'NO OF KEYED FIELDS',
                sort_key: "no_of_key_fields",
                selector: row => row?.no_of_key_fields,
                cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.no_of_key_fields}</span>,
                sortable: true,
                reorder: true,
                left: true,
                wrap: true,
                grow: 0.2,
                omit: !state.no_of_key_fields,
            },

            {
                id: 'USER NAME',
                name: 'USER NAME',
                sort_key: "user_name",
                reorder: true,
                sortable: true,
                omit: !state.user_name,
                width: "140px",
                selector: row => row?.user_name,
                cell: (row) =>
                    <div className="d-flex justify-content-end text-truncate" onClick={() => handleRowClick(row)}>

                        {row?.status == "partial_save" ? (
                            <React.Fragment>
                                <Tooltip disableInteractive title={row?.user_name} placement='top' arrow>
                                    <span onClick={() => handleRowClick(row)} style={!row?.user_name ? { visibility: 'hidden' } : { visibility: 'show' }} className=' text-truncate '>

                                        {row?.user_name ? row?.user_name : "nodata"}
                                    </span>
                                </Tooltip>

                            </React.Fragment>
                        ) :
                            <React.Fragment>
                                <Tooltip disableInteractive title={row?.user_name} placement='top' arrow>
                                    <span className='text-truncate' onClick={() => handleRowClick(row)} style={!row?.user_name ? { visibility: 'hidden' } : { visibility: 'show' }}>

                                        {row?.user_name ? row?.user_name : "nodata"}

                                    </span>
                                </Tooltip>
                            </React.Fragment>
                        }

                        {row?.is_user_multiple &&
                            <div style={{ paddingLeft: "10px" }}>

                                <TbBoxMultiple />
                            </div>
                        }
                    </div>,

            },

            {
                id: 'USER START TIME',
                name: 'USER START TIME',
                sort_key: "user_start_time",
                selector: row => moment(row?.user_start_time).format('M/DD/YY, H:mm'),
                cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.user_start_time}</span>,
                sortable: true,
                reorder: true,
                left: true,
                wrap: true,
                grow: 0.2,
                omit: !state.user_start,
            },
            {
                id: 'USER END TIME',
                name: 'USER END TIME',
                sort_key: "user_end_time",
                selector: row => moment(row?.user_end_time).format('M/DD/YY, H:mm'),
                cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.user_end_time}</span>,
                sortable: true,
                reorder: true,
                left: true,
                wrap: true,
                grow: 0.2,
                omit: !state.user_end,
            },

            {
                id: 'USER TOTAL TIME',
                name: 'USER TOTAL TIME',
                // selector: row => moment(row?.user_total_time).format('M/DD/YY, H:mm'),
                sort_key: "user_total_time",
                selector: row => row?.user_total_time,
                cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.user_total_time}</span>,
                // sortable: true,
                reorder: true,
                left: true,
                wrap: true,
                grow: 0.2,
                omit: !state.user_total_time,
            },
            // {
            //     id: 'QA NAME',
            //     name: 'QA NAME',
            //     sort_key: "qa_name",
            //     selector: row => row?.qa_name,
            //     cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.qa_name}</span>,
            //     // sortable: true,
            //     reorder: true,
            //     left: true,
            //     wrap: true,
            //     grow: 0.2,
            //     omit: !state.qa_name,
            // },
            {
                id: 'QA NAME',
                name: 'QA NAME',
                sort_key: "qa_name",
                //sortable: true,
                selector: row => row?.qa_name,
                cell: (row) =>
                    <div className="d-flex justify-content-between text-truncate" onClick={() => handleRowClick(row)}>

                        {/* <span> {row?.qa_name}
                        </span> */}
                        <Tooltip disableInteractive title={row?.qa_name} placement='top' arrow>
                            <span className='text-truncate' onClick={() => handleRowClick(row)} onDoubleClick={(e) => { handleDblClickCopy(row?.qa_name); }}>{row?.qa_name}</span>
                        </Tooltip>

                        {row?.is_multiple_qa &&
                            <div style={{ paddingLeft: "10px" }}>

                                <TbBoxMultiple />
                            </div>
                        }
                    </div>

                ,

                grow: 0.1,
                reorder: true,
                omit: !state.qa_name,
                allowOverflow: false,
                width: "140px",
                // button: true,
            },
            {
                id: 'QA START TIME',
                name: 'QA START TIME',
                sort_key: "qa_start_time",
                selector: row => moment(row?.qa_start_time).format('M/DD/YY, H:mm'),
                cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.qa_start_time}</span>,
                sortable: true,
                reorder: true,
                left: true,
                wrap: true,
                grow: 0.2,
                omit: !state.qa_start_time,
            },
            {
                id: 'QA END TIME',
                name: 'QA END TIME',
                sort_key: "qa_end_time",
                selector: row => moment(row?.qa_end_time).format('M/DD/YY, H:mm'),
                cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.qa_end_time}</span>,
                sortable: true,
                reorder: true,
                left: true,
                wrap: true,
                grow: 0.1,
                omit: !state.qa_end_time,
            },
            {
                id: 'QA TOTAL TIME',
                name: 'QA TOTAL TIME',
                sort_key: "qa_total_time",
                selector: row => row?.qa_total_time,
                cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.qa_total_time}</span>,
                // sortable: true,
                reorder: true,
                left: true,
                wrap: true,
                grow: 0.1,
                omit: !state.qa_total_time,
            },
            {
                id: 'SNIPPING NAME',
                name: 'SNIPPING NAME',
                sort_key: "snipping_name",
                //sortable: true,
                selector: row => row?.snipping_name,
                cell: (row) =>
                    <div className="d-flex justify-content-between text-truncate" onClick={() => handleRowClick(row)}>

                        {/* <span> {row?.snipping_name}
                        </span> */}
                        <Tooltip disableInteractive title={row?.snipping_name} placement='top' arrow>
                            <span className='text-truncate' onClick={() => handleRowClick(row)} onDoubleClick={(e) => { handleDblClickCopy(row?.snipping_name); }}>{row?.snipping_name}</span>
                        </Tooltip>


                    </div>

                ,

                grow: 0.1,
                reorder: true,
                omit: !state.snipping_name,
                allowOverflow: false,
                width: "140px",
                // button: true,
            },
            {
                id: 'SNIPPING START TIME',
                name: 'SNIPPING START TIME',
                sort_key: "snipping_start_time",
                selector: row => moment(row?.snipping_start_time).format('M/DD/YY, H:mm'),
                cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.snipping_start_time}</span>,
                sortable: true,
                reorder: true,
                left: true,
                wrap: true,
                grow: 0.2,
                omit: !state.snipping_start_time,
            },
            {
                id: 'SNIPPING END TIME',
                name: 'SNIPPING END TIME',
                sort_key: "snipping_end_time",
                selector: row => moment(row?.snipping_end_time).format('M/DD/YY, H:mm'),
                cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.snipping_end_time}</span>,
                sortable: true,
                reorder: true,
                left: true,
                wrap: true,
                grow: 0.1,
                omit: !state.snipping_end_time,
            },
            {
                id: 'SNIPPING TOTAL TIME',
                name: 'SNIPPING TOTAL TIME',
                sort_key: "snipping_total_time",
                selector: row => row?.snipping_total_time,
                cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.snipping_total_time}</span>,
                // sortable: true,
                reorder: true,
                left: true,
                wrap: true,
                grow: 0.1,
                omit: !state.snipping_total_time,
            },

            {
                id: 'ADDITIONAL INFO STATUS',
                name: 'ADDITIONAL INFO STATUS',
                sort_key: "additionalinfo",
                selector: row => row?.additionalinfo,
                cell: (row) => <span className='text-truncate' onClick={() => handleRowClick(row)}>{row.additionalinfo}</span>,
                //sortable: true,
                reorder: true,
                left: true,
                width: '150px',
                wrap: true,
                omit: !state.additionalinfo,
            },

            {
                id: 'COMMENT',
                name: 'COMMENT',
                sort_key: "comment",
                selector: row => row?.comment,
                cell: (row, index) => <div style={{ cursor: 'pointer' }}>
                    {row?.comment &&
                        <div onClick={() => handleRowClick(row)} className='overflow-hidden d-flex flex-row  flex-wrap text-truncate' style={{ width: '120px' }}>
                            <Tooltip disableInteractive title={row?.comment.toUpperCase()} placement='top' arrow>
                                {row?.comment &&
                                    <span style={{ cursor: 'pointer' }} key={index} className='text-truncate  text-uppercase' onDoubleClick={() => handleDblClickCopy(row?.comment)}>{row?.comment}</span>
                                }
                            </Tooltip>
                        </div>
                    }
                </div>,
                style: {
                    cursor: "pointer",
                },
                //sortable: true,
                reorder: true,
                width: '140px',
                left: true,
                wrap: true,
                omit: !state.comment,
            },
            {
                id: 'PLB AMOUNT',
                name: 'PLB AMOUNT',
                sort_key: "plb_amt",
                selector: row => row?.plb_amt,
                cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.plb_amt}</span>,
                // sortable: true,
                reorder: true,
                left: true,
                wrap: true,
                grow: 0.1,
                omit: !state.plb_amt,
            },
            {
                id: 'REVISED SL',
                name: 'REVISED SL',
                sort_key: "calc_claims",
                selector: row => row?.calc_claims,
                cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.calc_claims}</span>,
                // sortable: true,
                reorder: true,
                left: true,
                wrap: true,
                grow: 0.1,
                omit: !state.calc_claims,
            },
            {
                id: '837 USED CLAIMS',
                name: '837 USED CLAIMS',
                sort_key: "used837",
                selector: row => row?.used837,
                cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.used837}</span>,
                // sortable: true,
                reorder: true,
                left: true,
                wrap: true,
                grow: 0.1,
                omit: !state.used837,
            },
            {
                id: 'CPH',
                name: 'CPH',
                sort_key: "cph",
                selector: row => row?.cph,
                cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.cph ? row?.cph : "0.0"}</span>,
                // sortable: true,
                reorder: true,
                left: true,
                wrap: true,
                grow: 0.1,
                omit: !state.cph,
            },


        ],

        [state, pageno, selectedRows],
    );



    const rowDisabledCriteria = (row) => {

        if (row.status == "rejected" && row.is_freeze) {
            return true
        }
        else if (row.is_large_batch_job && !row.is_sub_job && row.status == 'imported') {
            return true
        }
        else if (row.is_large_batch_job && row.is_sub_job && row.status == 'qa_review') {
            return true
        }
        else if (row.is_large_batch_job && row.is_sub_job && row.status == 'qa_complete') {
            return true
        }
        else if (row.is_large_batch_job && row.is_sub_job && row.status == 'output_generated') {
            return true
        }
        else if (row.is_large_batch_job && row.is_sub_job && row.status == 'output_completed') {
            return true
        }

        else if (row.status == 'imported'
            || row.status == 'assigned'
            || row.status == 'inprocess'
            || row.status == 'datacaptured'
            || row.status == "partial_save"
            || row.status == 'qa_review'
            || row.status == 'qa_inprocess'
            || row.status == "qa_complete"
            || row.status == "ready_to_output_generate"
            || row.status == "output_generated"
            || row.status == "hold"
            || row.status == "rejected") {

            return false;
        }

    }




    const fetchQueueListing = async (page, limit, staff, client, client_group, status, filename, job_unique, check_number, batch_id, start_date, end_date, sort_order, sort_key, tat_status, station) => {
        setLoading(true);

        filterQueueDetails({ page, limit, staff, client, client_group, status, filename, job_unique, check_number, batch_id, start_date, end_date, sort_order, sort_key, tat_status, station }).then((response) => {
            setLoading(true)
            if (!response?.data?.status && response?.status) {

                setData(response);
                setPageNo(page)
                setDefaultValues(response?.data?.results?.default_values);
                setTotalRows(response?.data?.count);
                setLoading(false);
                const bottomTotalContainer = document.querySelector('.display-div-class')
                if (bottomTotalContainer) {
                    bottomTotalContainer.style.setProperty('display', 'block', 'important');
                }

            } else {
                queueformik.setErrors({
                    ...queueformik.values,
                    filename: response?.response?.data?.errors?.username,
                    client: response?.response?.data?.errors?.client_id,
                    status: response?.response?.data?.errors?.name,

                })
            }
        });

    };

    const fetchTotalQueueListing = async (page, limit, staff, client, client_group, status, filename, job_unique, check_number, batch_id, start_date, end_date, sort_order, sort_key, tat_status, station, isAdd = false) => {

        setLoading(true);
        filterTotalQueueDetails({ page, limit, staff, client, client_group, status, filename, job_unique, check_number, batch_id, start_date, end_date, sort_order, sort_key, tat_status, station }).then((response) => {

            if (response?.status) {

                // debugger
                setGrandTotalData({ ...grandTotalData, data: response.data })
                if (isAdd) {
                    const duplicateRdtTable = secondDiv?.querySelector(".custome-rdt_TableHead");
                    duplicateRdtTable.classList.remove('none-div-class');
                    duplicateRdtTable.classList.add('display-div-class');
                }
                setTimeout(() => {
                    // setKunjani(true)
                    setLoading(false);
                }, 200);



            }
        });

    };

    const visibilityBottomTotal = (status) => {
        console.log(document.querySelector('.display-div-class'))
        const bottomTotalContainer = document.querySelector('.display-div-class')
        if (bottomTotalContainer) {
            bottomTotalContainer.style.setProperty('display', status, 'important');
        }
    }

    const handlePageChange = page => {
        //setLoading(true);
        visibilityBottomTotal('none')
        setPageNo(page)

        fetchQueueListing(page, limit, searchedData?.staff, searchedData.client, searchedData.client_group, searchedData.status, searchedData?.filename, searchedData?.job_unique, searchedData?.check_number, searchedData?.batch_id, searchedData?.start_date, searchedData?.end_date, sortOrder, sortColumn, searchedData?.tat_status, searchedData?.station);

        //setLoading(false);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setGrandTotal({ ...grandTotal, isActive: false })

        //setLoading(true);

        fetchQueueListing(1, newPerPage, searchedData?.staff, searchedData.client, searchedData.client_group, searchedData.status, searchedData?.filename, searchedData?.job_unique, searchedData?.check_number, searchedData?.batch_id, searchedData?.start_date, searchedData?.end_date, sortOrder, sortColumn, searchedData?.tat_status, searchedData?.station);

        setPageNo(1)

        setLimit(newPerPage);

        //setLoading(false);

        handleResetCheckBox()


    };


    const [clientList, setClientList] = useState();
    const [checking, setChecking] = useState(true)
    const [defaultValues, setDefaultValues] = useState({})

    useEffect(() => {
        fetchQueueListing(1, limit, null, null, null, null, null, null, null, null, searchedData?.start_date, searchedData?.end_date, null, null, null, searchedData?.station);

    }, []);

    useEffect(() => {

        if (!!checking && defaultValues?.client) {

            setState({
                deposit_date: defaultValues?.deposit_date,
                processed_date: defaultValues?.processed_date,

                client: defaultValues?.client,
                facility: defaultValues?.facility,
                job_file_name: defaultValues?.job_file_name,
                job_id: defaultValues?.job_id,
                job_status: defaultValues?.job_status,
                lock_box_file: defaultValues?.lock_box_file,
                batch_id: defaultValues?.batch_id,
                document_type: defaultValues?.document_type,
                // qdx_type: defaultValues?.qdx_type,
                internal_tat: defaultValues?.internal_tat,
                external_tat: defaultValues?.external_tat,
                check_number: defaultValues?.check_number,
                index_amount: defaultValues?.index_amount,
                check_amount: defaultValues?.check_amount,
                eft_amount: defaultValues?.eft_amount,
                payer: defaultValues?.payer,
                pages: defaultValues?.pages,
                claims: defaultValues?.claims,
                servicelines: defaultValues?.servicelines,
                fields: defaultValues?.fields,
                corrected_fields: defaultValues?.corrected_fields,
                autofilled_fields: defaultValues?.autofilled_fields,
                no_of_key_fields: defaultValues?.no_of_key_fields,
                user_name: defaultValues?.user_name,
                user_start: defaultValues?.user_start_time,
                user_end: defaultValues?.user_end_time,
                user_total_time: defaultValues?.user_total_time,
                qa_name: defaultValues?.qa_name,
                qa_start_time: defaultValues?.qa_start_time,
                qa_end_time: defaultValues?.qa_end_time,
                qa_total_time: defaultValues?.qa_total_time,
                snipping_name: defaultValues?.snipping_name,
                snipping_start_time: defaultValues?.snipping_start_time,
                snipping_end_time: defaultValues?.snipping_end_time,
                snipping_total_time: defaultValues?.snipping_total_time,
                additionalinfo: defaultValues?.additionalinfo,
                plb_amt: defaultValues?.plb_amt,
                calc_claims: defaultValues?.calc_claims,
                used837: defaultValues?.used837,
                cph: defaultValues?.cph,
                comment: defaultValues?.comment,
                action: true,

            })
            setChecking(false)
        }

    }, [defaultValues]);

    const handleDblClickCopy = async (filename) => {
        navigator.clipboard.writeText(filename)

    };

    const handleResetCheckBox = () => {
        setUnselectCheck(true)
        setTimeout(() => {
            setUnselectCheck(false)
        }, 200);
    }

    const onButtonClick = (file) => {
        // using Java Script method to get PDF file
        const fileURL = file;
        // Setting various property values
        let alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = 'SamplePDF.pdf';
        alink.target = '_blank';
        alink.click();
    }

    const [searchedData, setSearchedData] = useState({

        filename: "",
        job_unique: "",
        client: "",
        client_group: "",
        staff: "",
        status: "",
        tat_status: "",
        station: "",
        check_number: "",
        batch_id: "",
        deposit_date_range: [startOfMonth(new Date()), new Date()],

        start_date: moment(startOfMonth(new Date())).format('MM/DD/YYYY HH:mm:ss'),
        end_date: moment(new Date()).format('MM/DD/YYYY HH:mm:ss')
    });


    const queueformik = useFormik({
        enableReinitialize: false,
        initialValues: {
            filename: "",
            job_unique: "",
            client: "",
            client_group: "",
            staff: "",
            status: "",
            tat_status: "",
            station: "",
            check_number: "",
            batch_id: "",
            deposit_date_range: [startOfMonth(new Date()), new Date()],

            start_date: moment(startOfMonth(new Date())).format('MM/DD/YYYY HH:mm:ss'),
            end_date: moment(new Date()).format('MM/DD/YYYY HH:mm:ss')

        },
        onSubmit: (values) => {

            setGrandTotal({ ...grandTotal, isActive: false })

            fetchQueueListing(1, limit, values?.staff, values.client, values.client_group, values.status, values?.filename, values?.job_unique, values?.check_number, values?.batch_id, values?.start_date, values?.end_date, sortOrder, sortColumn, values?.tat_status, values?.station)

            setSearchedData({
                filename: values?.filename ? values?.filename : "",
                job_unique: values?.job_unique ? values?.job_unique : "",
                client: values?.client ? values?.client : "",
                client_group: values?.client_group ? values?.client_group : "",
                staff: values?.staff ? values?.staff : "",
                status: values?.status ? values?.status : "",
                tat_status: values?.tat_status ? values?.tat_status : "",
                station: values?.station ? values?.station : "",
                check_number: values?.check_number ? values?.check_number : "",
                batch_id: values?.batch_id ? values?.batch_id : "",
                deposit_date_range: [],
                start_date: values?.start_date ? values?.start_date : "",
                end_date: values?.end_date ? values?.end_date : "",
            })
            handleResetCheckBox()
            setSelectedRows([])
            // setTimeout(() => {

            //     handleResetDataTable()
            // }, 200);


            // if (!response?.data?.status && response?.status) {
            //     setData(response);
            //     setTotalRows(response?.data?.count);


            // } else {
            //     queueformik.setErrors({s
            //         ...queueformik.values,
            //         filename: response?.response?.data?.errors?.username,
            //         client: response?.response?.data?.errors?.client_id,
            //         status: response?.response?.data?.errors?.name,

            //     })
            // }
            // });
        },
    });

    const [filterState, setFilterState] = useState([{
        staff: [],
        client: [],
        client_group: [],
    }])

    const handleOnDateTimeChange = ({ name, value }) => {
        let start_date = ''
        let end_date = ''
        if (value && value != "") {

            start_date = moment(value['0']).format('MM/DD/YYYY HH:mm:ss')
            end_date = moment(value['1']).format('MM/DD/YYYY HH:mm:ss')

        }
        console.log(start_date, 'start_date')
        console.log(end_date, 'end_date')
        queueformik.setValues({
            ...queueformik.values,
            deposit_date_range: value ?? "",
            start_date,
            end_date,
        })
    }
    const handleOnMultySelectChange = async (selected, name) => {
        let selectedIds = [];

        selected.map((option) => {
            selectedIds.push(option.value)
        })

        queueformik.setValues({ ...queueformik.values, [name]: selectedIds });


        setFilterState({
            ...filterState,
            [name]: selected
        })

    }

    const handleTatStatusChange = async (selected, name) => {
        queueformik.setValues({ ...queueformik.values, [name]: selected?.value ?? "" });
    }

    const handleStationChange = (selected, name) => {
        queueformik.setValues({ ...queueformik.values, [name]: selected?.value ?? "" })
    }

    const tat_Statuses = [
        { value: "1", label: 'TAT Nearby' },
        { value: "2", label: 'TAT Missed' },
    ];

    const station_option = [
        { value: "1", label: "My Work" },
        { value: "2", label: "QA Review" },
        { value: "3", label: "Snipping" },
    ]

    const [status_options, setStatusOptions] = useState([
        // { value: "imported", label: 'Imported' },
        // { value: "assigned", label: 'Assigned' },
        // { value: "inprocess", label: 'In Process' },
        { value: "datacaptured", label: 'Data Capture Completed' },
        { value: "partial_save", label: 'Partial Save' },
        { value: "re_assigned", label: 'Re Assigned' },
        { value: "released", label: 'Released' },
        // { value: "qa_review", label: 'QA Review' },
        // { value: "qa_inprocess", label: 'QA In Process' },
        // { value: "qa_rejected", label: 'QA Rejected' },
        { value: "qa_complete", label: 'QA Completed' },
        // { value: "ready_to_output_generate", label: 'Ready To Generate' },
        // { value: "output_generated", label: 'Output Generated' },
        // { value: "output_completed", label: 'Output Completed' },
        // { value: "archived", label: 'Archived' },
        { value: "rejected", label: 'Rejected' },
        { value: "hold", label: 'On Hold' },
        { value: "exit", label: 'Exit' },
        { value: "snipping_complete", label: 'Snipping Completed' },

    ]);

    const formatOptionLabel = ({ value, label, is_logged_in }) => (
        <div className='d-flex flex-row justify-content-between align-items-center'>
            <div>{label}</div>
            {is_logged_in ?
                <div className='ms-2 dot' />

                :
                <div></div>
            }
        </div>
    );


    const [selectedData, setSelectedData] = useState([])
    const [unselectCheck, setUnselectCheck] = useState(false)
    const handleRowSelected = React.useCallback(state => {
        console.log(state);
        var ids = [];
        var datas = [];
        state.selectedRows.map((row, index) => {
            ids.push(row.id);
            datas.push(row);
        })
        setSelectedRows(ids);
        setSelectedData(datas)
    }, []);


    const handleRowClick = (row) => {
        setColorRow(row);
        // // console.log(row);
    };

    const handleRowClickCheck = (row) => {

        data?.data?.results?.data?.map((item, index) => {
            if (row?.id == item.id) {
                setColorRow(row)
            }
        })
    }

    var i = 0;

    const conditionalRowStyles = [

        {
            when: row => { i = i + 1; return i % 2 == 0 || colorRow.id == row.id ? true : false },
            style: (row, index) => ({
                backgroundColor: colorRow?.id == row.id ? '#dbf4f5' : '#f2f2f2',
                color: 'black',
                fontWeight: '550',
                position: 'relative'
            }),
        },

        {
            when: row => row?.internal_tat_diff < 0 && row?.status !== "output_generated" && row?.status !== "output_completed",
            style: {
                backgroundColor: '#e89d9d',
                color: 'black',
                fontWeight: '550',
                position: 'relative'
            },
        },

        {
            when: row => colorRow.id == row.id && row?.internal_tat_diff < 0 && row?.status !== "output_generated" && row?.status !== "output_completed",
            style: (row, index) => ({
                backgroundColor: colorRow?.id == row.id && row?.internal_tat_diff < 0 && row?.status !== "output_generated" && row?.status !== "output_completed" ? '#dbf4f5' : '#f2f2f2',
                color: 'black',
                fontWeight: '550',
                position: 'relative'
            }),
        },

        {
            when: row => row?.internal_tat_diff >= 0 && row?.internal_tat_diff <= 2 && row?.status !== "output_generated" && row?.status !== "output_completed",
            style: {
                backgroundColor: '#f4f475',
                color: 'black',
                fontWeight: '550',
                position: 'relative'
            },
        },

        {
            when: row => colorRow.id == row.id && row?.internal_tat_diff > 0 && row?.internal_tat_diff <= 2 && row?.status !== "output_generated" && row?.status !== "output_completed",
            style: (row, index) => ({
                backgroundColor: colorRow.id == row.id && row?.internal_tat_diff > 0 && row?.internal_tat_diff <= 2 ? '#dbf4f5' : '#f2f2f2',
                color: 'black',
                fontWeight: '550',
                position: 'relative'
            }),
        },
    ];

    const handleTotalJobMonitor = async () => {
        const duplicateRdtTable = secondDiv?.querySelector(".custome-rdt_TableHead");
        if (duplicateRdtTable) {
            if (duplicateRdtTable.classList.contains('none-div-class')) {
                // if the element has the "none-div-class" class, remove it and add the "display-div-class" class
                // duplicateRdtTable.classList.remove('none-div-class');
                // duplicateRdtTable.classList.add('display-div-class');
                setGrandTotal({ ...grandTotal, isActive: true })

                // fetch the data and update the table
                fetchTotalQueueListing(pageno, limit, searchedData?.staff, searchedData.client, searchedData.client_group, searchedData.status, searchedData?.filename, searchedData?.job_unique, searchedData?.check_number, searchedData?.batch_id, searchedData?.start_date, searchedData?.end_date, sortOrder, sortColumn, searchedData?.tat_status, searchedData?.station, null, true);

            } else {
                // if the element does not have the "none-div-class" class, remove the "display-div-class" class and add the "none-div-class" class
                setGrandTotal({ ...grandTotal, isActive: false })
                duplicateRdtTable.classList.remove('display-div-class');
                duplicateRdtTable.classList.add('none-div-class');

            }
        } else {
            if (grandTotal.isActive) {
                setGrandTotal({ ...grandTotal, isActive: false })
            } else {
                setGrandTotal({ ...grandTotal, isActive: true })

            }
            fetchTotalQueueListing(pageno, limit, searchedData?.staff, searchedData?.client, searchedData?.client_group, searchedData?.status, searchedData?.filename, searchedData?.job_unique, searchedData?.check_number, searchedData?.batch_id, searchedData?.start_date, searchedData?.end_date, sortOrder, sortColumn, searchedData?.tat_status, searchedData?.station, null);

        }
        // check if the element has the "none-div-class" class

    }

    // adding total row in the bottom of the data table START
    const secondDiv = document.querySelector(".custom-datatable");

    useEffect(() => {
        // if (!secondDiv || !loading) return;
        if (!secondDiv) return;

        const observer = new MutationObserver(mutations => {
            const duplicateRdtTable = secondDiv.querySelector(".custome-rdt_TableHead");
            if (duplicateRdtTable) duplicateRdtTable.remove();

            const rdtTable = secondDiv.querySelector(".rdt_TableHead");
            if (!rdtTable) return;

            const clonedTable = rdtTable.cloneNode(true);
            clonedTable.classList.add("custome-rdt_TableHead");
            if (grandTotal.isActive) {
                clonedTable.classList.remove("none-div-class");
                clonedTable.classList.add("display-div-class");
            }
            else {

                clonedTable.classList.add("none-div-class");
                clonedTable.classList.remove("display-div-class");
                // setGrandTotal({ ...grandTotal, isActive: false })


            }

            clonedTable.querySelectorAll(".rdt_TableCol").forEach(cell => {
                const inputElement = cell.querySelector("input");
                const spanElement = cell.querySelector("span");
                if (spanElement) spanElement.remove();
                if (inputElement) inputElement.remove();

                const divElement = cell.querySelector("div");
                const contentDiv = divElement?.querySelector("div");
                if (contentDiv) {
                    switch (contentDiv.textContent) {
                        case "USER TOTAL TIME":
                            divElement.setAttribute("id", "id_user_total_time")
                            divElement.innerHTML = grandTotalData.data.user_total_time ?? "";
                            break;
                        case "QA TOTAL TIME":
                            divElement.classList.add("qa_total_time")

                            divElement.innerHTML = grandTotalData.data.qa_total_time ?? "";
                            break;
                        case "SNIPPING TOTAL TIME":
                            divElement.classList.add("snipping_total_time")

                            divElement.innerHTML = grandTotalData.data.snipping_total_time ?? "";
                            break;
                        case "INDEX AMOUNT":
                            divElement.classList.add("index_amount")

                            divElement.innerHTML = grandTotalData.data.index_amount ? grandTotalData.data.index_amount.toFixed(2) : "";
                            break;
                        case "CHECK AMOUNT":
                            divElement.classList.add("check_amount")

                            divElement.innerHTML = grandTotalData.data.check_amount ? grandTotalData.data.check_amount.toFixed(2) : "";
                            break;
                        case "EFT AMOUNT":
                            divElement.classList.add("eft_amount")

                            divElement.innerHTML = grandTotalData.data.eft_amount ? grandTotalData.data.eft_amount.toFixed(2) : "";

                            break;
                        case "NO OF PAGES":
                            divElement.classList.add("pages")

                            divElement.innerHTML = grandTotalData.data.pages ?? "";
                            break;
                        case "NO OF CLAIMS":
                            divElement.classList.add("claims")

                            divElement.innerHTML = grandTotalData.data.claims ?? "";
                            break;
                        case "NO OF SERVICE LINES":
                            divElement.classList.add("servicelines")

                            divElement.innerHTML = grandTotalData.data.servicelines ?? "";
                            break;
                        case "NO OF FIELDS":
                            divElement.classList.add("fields")

                            divElement.innerHTML = grandTotalData.data.fields ?? "";
                            break;
                        case "NO OF CORRECTED FIELDS":
                            divElement.classList.add("corrected_fields")

                            divElement.innerHTML = grandTotalData.data.corrected_fields ?? "";
                            break;
                        case "NO OF KEYED FIELDS":
                            divElement.innerHTML = grandTotalData.data.no_of_key_fields ?? "";
                            break;
                        // case "QDX TYPE":
                        //     divElement.innerHTML = grandTotalData.data.qdx_type ?? "";
                        //     break;
                        case "PLB AMOUNT":
                            divElement.innerHTML = grandTotalData.data.plb_amt ? grandTotalData.data.plb_amt.toFixed(2) : "";
                            break;
                        case "REVISED SL":
                            divElement.innerHTML = grandTotalData.data.calc_claims ? grandTotalData.data.calc_claims.toFixed(2) : "";

                            break;
                        case "837 USED CLAIMS":
                            divElement.innerHTML = grandTotalData.data.used837 ?? "";
                            break;
                        case "CPH":
                            divElement.innerHTML = grandTotalData.data.cph ?? "";
                            break;
                        case "NO OF AUTOFILLED FIELDS":
                            divElement.innerHTML = grandTotalData.data.autofilled_fields ?? "";
                            break;
                        default:
                            divElement.innerHTML = "";

                    }
                }
            });

            const customeRdtTable = secondDiv.querySelector(".rdt_Table");
            customeRdtTable.appendChild(clonedTable);

            observer.disconnect();
        });

        observer.observe(secondDiv, { childList: true, subtree: true });
    }, [secondDiv, loading, state, grandTotal, grandTotalData.data]);
    // adding total row in the bottom of the data table END

    const DropdownIndicator = ({ state, ...props }) => {
        return (
            <components.DropdownIndicator {...props}>

                {filterState && filterState[state] && filterState[state].length > 1 ? (
                    <TbGridDots style={{ fontSize: "1.5em" }} />
                ) : (
                    <HiChevronDown style={{ fontSize: "1.5em" }} />
                )}



            </components.DropdownIndicator>
        );
    };
    function DownloadDropDown() {
        const [loader, setLoader] = useState(false)
        const handleDownload = (filter_type) => {
            const page = pageno
            const staff = searchedData?.staff
            const client = searchedData.client
            const client_group = searchedData.client_group
            const status = searchedData.status
            const filename = searchedData?.filename
            const job_unique = searchedData?.job_unique
            const check_number = searchedData?.check_number
            const batch_id = searchedData?.batch_id
            const start_date = searchedData?.start_date
            const end_date = searchedData?.end_date
            const sort_order = sortOrder
            const sort_key = sortColumn
            const tat_status = searchedData?.tat_status
            const station = searchedData?.station
            downloadMultipleReport({ page, limit, staff, client, client_group, status, filename, job_unique, check_number, batch_id, start_date, end_date, sort_order, sort_key, tat_status, station, filter_type }).then((response) => {
                setLoader(true)
                if (!response?.data?.status && response?.status) {
                    setLoader(false)
                    downloadXl(response?.data?.export_file)

                }
            })
        }
        return (
            <div className="dropdown">
                {!loader ?
                    <div>
                        <button className="btn btn-light btn-active-primary pb-2 dropdown-toggle  jobMonitor-Buttons" type="button" data-bs-toggle="dropdown" aria-expanded="false">

                            <Tooltip disableInteractive title={"Download"} placement="top" arrow>
                                <div>
                                    <BsCloudDownloadFill className='jobMonitor-svg' />
                                </div>
                            </Tooltip>


                        </button>

                        <ul className="dropdown-menu hide-scroll-bar" style={{ height: '140px', overflowX: 'scroll', cursor: 'pointer' }}>
                            <li>
                                <div className='dropdown-item' onClick={() => handleDownload('report_invoice')}>

                                    <div className="">
                                        Invoice Report
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className='dropdown-item' onClick={() => handleDownload('report_tat')}>

                                    <div className="">
                                        TAT Report
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className='dropdown-item' onClick={() => handleDownload('report_qa')}>

                                    <div className="">
                                        Quality Report
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className='dropdown-item' onClick={() => handleDownload('report_match')}>

                                    <div className="">
                                        837 Match Report
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    : <Button className="btn btn-light btn-active-primary jobMonitor-Buttons" >
                        <span className="svg-icon svg-icon-3">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        </span>

                    </Button>
                }
            </div>
        )
    }


    const [ToastMessage, setToastMessage] = useState({
        status: false,
        message: "",
        type: "",
    })


    return (
        <React.Fragment>

            <ToastContainer className="p-3" position={"top-end"}>
                <Toast bg={ToastMessage?.type} onClose={() => setToastMessage(
                    {
                        status: false,
                        message: "",
                        type: "",
                    }

                )}
                    show={ToastMessage?.status} animation={true} delay={2000} autohide>
                    <Toast.Body ><span className='text-white'>{ToastMessage?.message}</span></Toast.Body>
                </Toast>

            </ToastContainer>


            <main id="main" className="main">


                <div className="pagetitle tab-fixed-header ">
                    <h1 id='page-title'>Data Listing</h1>

                </div>
                <section className="dashboard-data-list section mt-5">



                    <div className="row align-items-top py-2 gap-2 gap-md-5">
                        <div className="col-lg-12">
                            <div className="card  mb-xl-8 py-2">
                                <Form onSubmit={queueformik.handleSubmit}
                                    onReset={() => {
                                        handleResetCallFetch()
                                    }}
                                >
                                    <div className=" ">
                                        <div className=" px-2  grid-job-monitor-select">

                                            <div className='' >

                                                <Select
                                                    isMulti
                                                    options={staffList}
                                                    isClearable
                                                    formatOptionLabel={formatOptionLabel}
                                                    components={{ DropdownIndicator: (props) => <DropdownIndicator {...props} state="staff" /> }}
                                                    className="select-search-job-monitor"
                                                    classNamePrefix="select"
                                                    closeMenuOnSelect={false}
                                                    hideSelectedOptions={false}
                                                    placeholder=" Users"
                                                    // menuIsOpen={true}
                                                    name="staff"
                                                    onFocus={() => setIsFocusedStaff(true)}
                                                    onBlur={() => setIsFocusedStaff(false)}
                                                    onChange={(e) => {
                                                        queueformik.setFieldValue("staff", e.map(i => i.value))
                                                    }}
                                                    defaultValue={queueformik?.values?.staff}
                                                    styles={{
                                                        container: (styles, state) => ({
                                                            ...styles,
                                                            borderRadius: "9px",
                                                        }),
                                                        control: (styles, state) => ({
                                                            ...styles,
                                                            backgroundColor: "hsl(204deg 33.33% 97.06%)",
                                                            borderStyle: "none", boxShadow: "none",
                                                            borderRadius: "10px",
                                                            maxHeight: "30px",
                                                        }),
                                                        indicatorSeparator: styles => ({
                                                            ...styles, width: "0px",
                                                            minHeight: "20px",
                                                            maxHeight: "30px",
                                                        }),

                                                        valueContainer: (styles, state) => ({
                                                            ...styles,
                                                            maxHeight: "30px",
                                                            overflow: 'scroll',
                                                        }),
                                                        option: (styles, state) => ({
                                                            ...styles,
                                                            backgroundColor: state.isSelected ? '#d9f4f5' : 'inherit',
                                                            color: state.isSelected ? '#000000' : 'inherit',
                                                            '&:hover': { backgroundColor: state.isSelected ? '#d9f4f5' : 'rgb(222, 235, 255)' },

                                                        })
                                                    }}
                                                />

                                            </div>
                                            <div className=''>
                                                <Select
                                                    isMulti
                                                    options={clientList}
                                                    className="select-search hide-scroll-bar"
                                                    components={{ DropdownIndicator: (props) => <DropdownIndicator {...props} state="client" /> }}
                                                    classNamePrefix="select"
                                                    placeholder="Clients"
                                                    isClearable
                                                    closeMenuOnSelect={false}
                                                    hideSelectedOptions={false}
                                                    name="client"
                                                    onFocus={() => setIsFocusedClient(true)}
                                                    onBlur={() => setIsFocusedClient(false)}
                                                    onChange={(e) => handleOnMultySelectChange(e, 'client')}
                                                    defaultValue={queueformik?.values?.client}
                                                    styles={{
                                                        container: (styles, state) => ({
                                                            ...styles,
                                                            borderRadius: "9px",
                                                        }),
                                                        control: (styles, state) => ({
                                                            ...styles,
                                                            backgroundColor: "hsl(204deg 33.33% 97.06%)",
                                                            borderStyle: "none", boxShadow: "none",
                                                            borderRadius: "10px",
                                                            maxHeight: "30px",

                                                        }),
                                                        indicatorSeparator: styles => ({
                                                            ...styles, width: "0px",
                                                            minHeight: "20px",
                                                            maxHeight: "30px",

                                                        }),

                                                        valueContainer: (styles, state) => ({
                                                            ...styles,
                                                            maxHeight: "30px",
                                                            overflow: 'scroll',


                                                        }),
                                                        option: (styles, state) => ({
                                                            ...styles,
                                                            backgroundColor: state.isSelected ? '#d9f4f5' : 'inherit',
                                                            color: state.isSelected ? '#000000' : 'inherit',
                                                            '&:hover': { backgroundColor: state.isSelected ? '#d9f4f5' : 'rgb(222, 235, 255)' }

                                                        })
                                                    }} />
                                            </div>
                                            <div className=''>
                                                <Select
                                                    isMulti
                                                    options={clientGroupList}
                                                    className="select-search"
                                                    components={{ DropdownIndicator: (props) => <DropdownIndicator {...props} state="client_group" /> }}
                                                    classNamePrefix="select"
                                                    placeholder=" Client Group"
                                                    isClearable
                                                    closeMenuOnSelect={false}
                                                    hideSelectedOptions={false}
                                                    name="client_group"
                                                    onFocus={() => setIsFocusedGroup(true)}
                                                    onBlur={() => setIsFocusedGroup(false)}
                                                    onChange={(e) => handleOnMultySelectChange(e, 'client_group')}
                                                    defaultValue={queueformik?.values?.client_group}
                                                    styles={{
                                                        container: (styles, state) => ({
                                                            ...styles,
                                                            borderRadius: "9px",
                                                        }),
                                                        control: (styles, state) => ({
                                                            ...styles,
                                                            backgroundColor: "hsl(204deg 33.33% 97.06%)",
                                                            borderStyle: "none", boxShadow: "none",
                                                            borderRadius: "10px",
                                                            maxHeight: "30px",
                                                        }),
                                                        indicatorSeparator: styles => ({
                                                            ...styles, width: "0px",
                                                            minHeight: "20px",
                                                            maxHeight: "30px",
                                                        }),

                                                        valueContainer: (styles, state) => ({
                                                            ...styles,
                                                            maxHeight: "30px",
                                                            overflow: 'scroll',
                                                        }),
                                                        option: (styles, state) => ({
                                                            ...styles,
                                                            backgroundColor: state.isSelected ? '#d9f4f5' : 'inherit',
                                                            color: state.isSelected ? '#000000' : 'inherit',
                                                            '&:hover': { backgroundColor: state.isSelected ? '#d9f4f5' : 'rgb(222, 235, 255)' }

                                                        })
                                                    }} />
                                            </div>
                                            <div className='-tat'>
                                                <Select
                                                    options={tat_Statuses}
                                                    className="select-search"
                                                    classNamePrefix="select"
                                                    placeholder="TAT Status"
                                                    isClearable
                                                    name="tat_status"
                                                    onFocus={() => setIsFocusedTat(true)}
                                                    onBlur={() => setIsFocusedTat(false)}
                                                    onChange={(e) => handleTatStatusChange(e, 'tat_status')}
                                                    defaultValue={queueformik?.values?.tat_status}
                                                    styles={{
                                                        container: (styles, state) => ({
                                                            ...styles,
                                                            borderRadius: "9px",
                                                        }),
                                                        control: (styles, state) => ({
                                                            ...styles,
                                                            backgroundColor: "hsl(204deg 33.33% 97.06%)",
                                                            borderStyle: "none", boxShadow: "none",
                                                            borderRadius: "10px",
                                                            maxHeight: state.isFocused ? "auto" : "30px",
                                                        }),
                                                        indicatorSeparator: styles => ({
                                                            ...styles, width: "0px",
                                                            //  minHeight: "20px"
                                                        }),

                                                        valueContainer: (styles, state) => ({
                                                            ...styles,
                                                            maxHeight: isFocusedTat ? "auto" : "30px",
                                                        }),
                                                    }}
                                                />
                                            </div>
                                            <div className=''>

                                                <Select
                                                    options={station_option}
                                                    className="select-search"
                                                    classNamePrefix="select"
                                                    //components={{ DropdownIndicator: (props) => <DropdownIndicator {...props} state="status" /> }}
                                                    placeholder="Station"
                                                    isClearable
                                                    name="station"
                                                    //onFocus={() => setIsFocusedStatus(true)}
                                                    //onBlur={() => setIsFocusedStatus(false)}
                                                    onChange={(e) => handleStationChange(e, 'station')}
                                                    defaultValue={queueformik?.values?.station}
                                                    styles={{
                                                        container: (styles, state) => ({
                                                            ...styles,
                                                            borderRadius: "9px",
                                                        }),
                                                        control: (styles, state) => ({
                                                            ...styles,
                                                            backgroundColor: "hsl(204deg 33.33% 97.06%)",
                                                            borderStyle: "none", boxShadow: "none",
                                                            borderRadius: "10px",
                                                            maxHeight: state.isFocused ? "auto" : "30px",
                                                        }),
                                                        indicatorSeparator: styles => ({
                                                            ...styles, width: "0px", minHeight: "20px"
                                                        }),

                                                        // valueContainer: (styles, state) => ({
                                                        //     ...styles,
                                                        //     maxHeight: isFocusedStatus ? "auto" : "30px",
                                                        // }),
                                                    }}
                                                />
                                            </div>
                                            <div className=''>

                                                <Select
                                                    isMulti
                                                    options={status_options}
                                                    className="select-search"
                                                    classNamePrefix="select"
                                                    components={{ DropdownIndicator: (props) => <DropdownIndicator {...props} state="status" /> }}
                                                    placeholder="Status"
                                                    isClearable
                                                    closeMenuOnSelect={false}
                                                    hideSelectedOptions={false}

                                                    name="status"
                                                    onFocus={() => setIsFocusedStatus(true)}
                                                    onBlur={() => setIsFocusedStatus(false)}
                                                    onChange={(e) => handleOnMultySelectChange(e, 'status')}
                                                    defaultValue={queueformik?.values?.status}
                                                    styles={{
                                                        container: (styles, state) => ({
                                                            ...styles,
                                                            borderRadius: "9px",
                                                        }),
                                                        control: (styles, state) => ({
                                                            ...styles,
                                                            backgroundColor: "hsl(204deg 33.33% 97.06%)",
                                                            borderStyle: "none", boxShadow: "none",
                                                            borderRadius: "10px",
                                                            maxHeight: "30px",
                                                        }),
                                                        indicatorSeparator: styles => ({
                                                            ...styles, width: "0px", minHeight: "20px",
                                                            maxHeight: "30px",
                                                        }),

                                                        valueContainer: (styles, state) => ({
                                                            ...styles,
                                                            maxHeight: "30px",
                                                            overflow: 'scroll',
                                                        }),
                                                        option: (styles, state) => ({
                                                            ...styles,
                                                            backgroundColor: state.isSelected ? '#d9f4f5' : 'inherit',
                                                            color: state.isSelected ? '#000000' : 'inherit',
                                                            '&:hover': { backgroundColor: state.isSelected ? '#d9f4f5' : 'rgb(222, 235, 255)' }

                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className=" px-2  grid-job-monitor-input">

                                            <div className=''>
                                                <TextField
                                                    id="search"
                                                    className="form-control form-control-solid  b-r-10"
                                                    name='filename'
                                                    type="search"
                                                    style={{ width: "100%" }}
                                                    placeholder="Filename"
                                                    aria-label="Search Input"
                                                    value={queueformik.values?.filename}
                                                    // value={input.file}
                                                    onChange={queueformik.handleChange}
                                                />
                                            </div>
                                            <div className=''>
                                                <TextField
                                                    id="search"
                                                    className="form-control form-control-solid  b-r-10"
                                                    name='job_unique'
                                                    type="search"
                                                    style={{ width: "100%" }}
                                                    placeholder="Job Id"
                                                    aria-label="Search Input"
                                                    value={queueformik.values?.job_unique}
                                                    // value={input.file}
                                                    onChange={queueformik.handleChange}
                                                />
                                            </div>
                                            <div className=''>
                                                <TextField
                                                    id="search"
                                                    className="form-control form-control-solid  b-r-10"
                                                    name='check_number'
                                                    type="search"
                                                    style={{ width: "100%" }}
                                                    placeholder="Check Number"
                                                    aria-label="Search Input"
                                                    value={queueformik.values?.check_number}
                                                    // value={input.file}
                                                    onChange={queueformik.handleChange}
                                                />
                                            </div>
                                            <div className=''>
                                                <TextField
                                                    id="search"
                                                    className="form-control form-control-solid  b-r-10"
                                                    name='batch_id'
                                                    type="search"
                                                    style={{ width: "100%" }}
                                                    placeholder="Batch ID"
                                                    aria-label="Search Input"
                                                    value={queueformik.values?.batch_id}
                                                    // value={input.file}
                                                    onChange={queueformik.handleChange}
                                                />
                                            </div>
                                            <Tooltip disableInteractive title={"Processed Date"} placement="top" arrow>

                                                <div className=''>
                                                    <Stack direction="column" spacing={8} alignItems="flex-start">
                                                        <DateRangePicker
                                                            ranges={predefinedBottomRanges}
                                                            cleanable={false}
                                                            appearance="default"
                                                            format={"MM/dd/yyyy HH:mm:ss"}
                                                            placement='auto'
                                                            placeholder={"MM/DD/YYYY - MM/DD/YYYY"}
                                                            className="form-date-control form-control-solid b-r-1 text-dark jobmonitor-datepicker"
                                                            name="deposit_date_range"
                                                            onChange={(e) => { handleOnDateTimeChange({ name: 'deposit_date_range', value: e }) }}
                                                            value={queueformik?.values?.deposit_date_range} />
                                                    </Stack>

                                                </div>
                                            </Tooltip>

                                            <div>
                                                <Tooltip disableInteractive title="Search" placement='top' arrow>
                                                    <Button className="btn btn-light btn-active-primary jobMonitor-Buttons " type="submit">
                                                        <span className="svg-icon svg-icon-3 ">
                                                            <BiSearch className='jobMonitor-svg' />
                                                        </span>

                                                    </Button>
                                                </Tooltip>
                                            </div>
                                            <div>
                                                <Tooltip disableInteractive title="Reset" placement='top' arrow>
                                                    <Button className="btn btn-light btn-active-primary  jobMonitor-Buttons"
                                                        //onClick={handleResetCallFetch}
                                                        type="reset">
                                                        <span className="svg-icon svg-icon-3">
                                                            <BiReset className='jobMonitor-svg' />
                                                        </span>

                                                    </Button>
                                                </Tooltip>
                                            </div>
                                            <div>
                                                <Tooltip disableInteractive title="Table Headings" placement='top' arrow>
                                                    <div className="dropdown">
                                                        <button className="btn btn-light btn-active-primary pb-2 dropdown-toggle  jobMonitor-Buttons" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <IoIosSwitch className='jobMonitor-svg' />
                                                        </button>
                                                        <ul className="dropdown-menu hide-scroll-bar" style={{ height: '500px', overflowX: 'scroll' }} onClick={(e) => e.stopPropagation()}>
                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.deposit_date} onChange={() => setState({ ...state, deposit_date: !state.deposit_date })} type="checkbox" id="deposit_date" />
                                                                        <label className="form-check-label" htmlFor="deposit_date">
                                                                            Deposit Date
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.processed_date} onChange={() => setState({ ...state, processed_date: !state.processed_date })} type="checkbox" id="processed_date" />
                                                                        <label className="form-check-label" htmlFor="processed_date">
                                                                            Processed Date
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.client} onChange={() => setState({ ...state, client: !state.client })} type="checkbox" id="client" />
                                                                        <label className="form-check-label" htmlFor="client">
                                                                            Client
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.facility} onChange={() => setState({ ...state, facility: !state.facility })} type="checkbox" id="facility" />
                                                                        <label className="form-check-label" htmlFor="facility">
                                                                            Facility
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.job_file_name} onChange={() => setState({ ...state, job_file_name: !state.job_file_name })} type="checkbox" id="job_file_name" />
                                                                        <label className="form-check-label" htmlFor="job_file_name">
                                                                            Job File Name
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.job_id} onChange={() => setState({ ...state, job_id: !state.job_id })} type="checkbox" id="job" />
                                                                        <label className="form-check-label" htmlFor="job">
                                                                            Job id
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.job_status} onChange={() => setState({ ...state, job_status: !state.job_status })} type="checkbox" id="job_status" />
                                                                        <label className="form-check-label" htmlFor="job_status">
                                                                            Job Status
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.lock_box_file} onChange={() => setState({ ...state, lock_box_file: !state.lock_box_file })} type="checkbox" id="lock_box_file" />
                                                                        <label className="form-check-label" htmlFor="lock_box_file">
                                                                            Lockbox File Name
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.batch_id} onChange={() => setState({ ...state, batch_id: !state.batch_id })} type="checkbox" id="batch_id" />
                                                                        <label className="form-check-label" htmlFor="batch_id">
                                                                            Batch Id
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.document_type} onChange={() => setState({ ...state, document_type: !state.document_type })} type="checkbox" id="document_type" />
                                                                        <label className="form-check-label" htmlFor="document_type">
                                                                            Document Type
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.internal_tat} onChange={() => setState({ ...state, internal_tat: !state.internal_tat })} type="checkbox" id="internal_tat" />
                                                                        <label className="form-check-label" htmlFor="internal_tat">
                                                                            Internal Tat
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            {/*    // sachu told to remove this  26-04-2023 */}
                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.external_tat} onChange={() => setState({ ...state, external_tat: !state.external_tat })} type="checkbox" id="external_tat" />
                                                                        <label className="form-check-label" htmlFor="external_tat">
                                                                            External Tat
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.check_number} onChange={() => setState({ ...state, check_number: !state.check_number })} type="checkbox" id="check_number" />
                                                                        <label className="form-check-label" htmlFor="check_number">
                                                                            Check Number
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.index_amount} onChange={() => setState({ ...state, index_amount: !state.index_amount })} type="checkbox" id="index_amount" />
                                                                        <label className="form-check-label" htmlFor="index_amount">
                                                                            index amount
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.check_amount} onChange={() => setState({ ...state, check_amount: !state.check_amount })} type="checkbox" id="check_amount" />
                                                                        <label className="form-check-label" htmlFor="check_amount">
                                                                            check amount
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.eft_amount} onChange={() => setState({ ...state, eft_amount: !state.eft_amount })} type="checkbox" id="eft_amount" />
                                                                        <label className="form-check-label" htmlFor="eft_amount">
                                                                            EFT amount
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.payer} onChange={() => setState({ ...state, payer: !state.payer })} type="checkbox" id="payer" />
                                                                        <label className="form-check-label" htmlFor="payer">
                                                                            Payer
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>

                                                            {/* <li onClick={() => setState({ ...state, lockbox_payee_name: !state.lockbox_payee_name })}>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.lockbox_payee_name} onChange={() => setState({ ...state, lockbox_payee_name: !state.lockbox_payee_name })} type="checkbox" id="lockbox_payee_name" />
                                                                        <label className="form-check-label" htmlFor="lockbox_payee_name">
                                                                            Lockbox Payee Name
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li> */}

                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.pages} onChange={() => setState({ ...state, pages: !state.pages })} type="checkbox" id="pages" />
                                                                        <label className="form-check-label" htmlFor="pages">
                                                                            # Pages
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.claims} onChange={() => setState({ ...state, claims: !state.claims })} type="checkbox" id="claims" />
                                                                        <label className="form-check-label" htmlFor="claims">
                                                                            # claims
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.servicelines} onChange={() => setState({ ...state, servicelines: !state.servicelines })} type="checkbox" id="servicelines" />
                                                                        <label className="form-check-label" htmlFor="servicelines">
                                                                            # service lines
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.fields} onChange={() => setState({ ...state, fields: !state.fields })} type="checkbox" id="fields" />
                                                                        <label className="form-check-label" htmlFor="fields">
                                                                            # Fields
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.corrected_fields} onChange={() => setState({ ...state, corrected_fields: !state.corrected_fields })} type="checkbox" id="corrected_fields" />
                                                                        <label className="form-check-label" htmlFor="corrected_fields">
                                                                            # Corrected Fields
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.autofilled_fields} onChange={() => setState({ ...state, autofilled_fields: !state.autofilled_fields })} type="checkbox" id="autofilled_fields" />
                                                                        <label className="form-check-label" htmlFor="autofilled_fields">
                                                                            # Autofilled Fields
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.no_of_key_fields} onChange={() => setState({ ...state, no_of_key_fields: !state.no_of_key_fields })} type="checkbox" id="no_of_key_fields" />
                                                                        <label className="form-check-label" htmlFor="no_of_key_fields">
                                                                            # Keyed Fields
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.user_name} onChange={() => setState({ ...state, user_name: !state.user_name })} type="checkbox" id="user_name" />
                                                                        <label className="form-check-label" htmlFor="user_name">
                                                                            User Name
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.user_start} onChange={() => setState({ ...state, user_start: !state.user_start })} type="checkbox" id="user_start" />
                                                                        <label className="form-check-label" htmlFor="user_start">
                                                                            User Start Time
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.user_end} onChange={() => setState({ ...state, user_end: !state.user_end })} type="checkbox" id="user_end" />
                                                                        <label className="form-check-label" htmlFor="user_end">
                                                                            User End Time
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.user_total_time} onChange={() => setState({ ...state, user_total_time: !state.user_total_time })} type="checkbox" id="user_total_time" />
                                                                        <label className="form-check-label" htmlFor="user_total_time">
                                                                            User Total Time
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.qa_name} onChange={() => setState({ ...state, qa_name: !state.qa_name })} type="checkbox" id="qa_name" />
                                                                        <label className="form-check-label" htmlFor="qa_name">
                                                                            QA Name
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.qa_start_time} onChange={() => setState({ ...state, qa_start_time: !state.qa_start_time })} type="checkbox" id="qa_start_time" />
                                                                        <label className="form-check-label" htmlFor="qa_start_time">
                                                                            QA Start Time
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.qa_end_time} onChange={() => setState({ ...state, qa_end_time: !state.qa_end_time })} type="checkbox" id="qa_end_time" />
                                                                        <label className="form-check-label" htmlFor="qa_end_time">
                                                                            QA End Time
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.qa_total_time} onChange={() => setState({ ...state, qa_total_time: !state.qa_total_time })} type="checkbox" id="qa_total_time" />
                                                                        <label className="form-check-label" htmlFor="qa_total_time">
                                                                            QA Total Time
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.snipping_name} onChange={() => setState({ ...state, snipping_name: !state.snipping_name })} type="checkbox" id="snipping_name" />
                                                                        <label className="form-check-label" htmlFor="snipping_name">
                                                                            Snipping Name
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.snipping_start_time} onChange={() => setState({ ...state, snipping_start_time: !state.snipping_start_time })} type="checkbox" id="snipping_start_time" />
                                                                        <label className="form-check-label" htmlFor="snipping_start_time">
                                                                            Snipping Start Time
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.snipping_end_time} onChange={() => setState({ ...state, snipping_end_time: !state.snipping_end_time })} type="checkbox" id="snipping_end_time" />
                                                                        <label className="form-check-label" htmlFor="snipping_end_time">
                                                                            Snipping End Time
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.snipping_total_time} onChange={() => setState({ ...state, snipping_total_time: !state.snipping_total_time })} type="checkbox" id="snipping_total_time" />
                                                                        <label className="form-check-label" htmlFor="snipping_total_time">
                                                                            Snipping Total Time
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>


                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.additionalinfo} onChange={() => setState({ ...state, additionalinfo: !state.additionalinfo })} type="checkbox" id="additionalinfo" />
                                                                        <label className="form-check-label" htmlFor="additionalinfo">
                                                                            Additional info Status
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>


                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.comment} onChange={() => setState({ ...state, comment: !state.comment })} type="checkbox" id="comment" />
                                                                        <label className="form-check-label" htmlFor="comment">
                                                                            Comment
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            {/* <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.qdx_type} onChange={() => setState({ ...state, qdx_type: !state.qdx_type })} type="checkbox" id="qdx_type" />
                                                                        <label className="form-check-label" htmlFor="qdx_type">
                                                                            QDX Type
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li> */}
                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.plb_amt} onChange={() => setState({ ...state, plb_amt: !state.plb_amt })} type="checkbox" id="plb_amt" />
                                                                        <label className="form-check-label" htmlFor="plb_amt">
                                                                            PLB Amount
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.calc_claims} onChange={() => setState({ ...state, calc_claims: !state.calc_claims })} type="checkbox" id="calc_claims" />
                                                                        <label className="form-check-label" htmlFor="calc_claims">
                                                                            REVISED SL
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.used837} onChange={() => setState({ ...state, used837: !state.used837 })} type="checkbox" id="used837" />
                                                                        <label className="form-check-label" htmlFor="used837">
                                                                            837 Used Claims
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.cph} onChange={() => setState({ ...state, cph: !state.cph })} type="checkbox" id="cph" />
                                                                        <label className="form-check-label" htmlFor="cph">
                                                                            CPH
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.action} onChange={() => setState({ ...state, action: !state.action })} type="checkbox" id="action" />
                                                                        <label className="form-check-label" htmlFor="comment">
                                                                            Actions
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>


                                                        </ul>
                                                    </div>
                                                </Tooltip>
                                            </div>
                                            <div>
                                                <Tooltip disableInteractive title="Export" placement='top' arrow>
                                                    <Button className="btn btn-light btn-active-primary  jobMonitor-Buttons"
                                                        onClick={() => handleExportJobMonitor(pageno, limit, searchedData?.staff, searchedData.client, searchedData.client_group, searchedData.status, searchedData?.filename, searchedData?.job_unique, searchedData?.check_number, searchedData?.batch_id, searchedData?.start_date, searchedData?.end_date, sortOrder, sortColumn, searchedData?.tat_status, searchedData?.station)}
                                                        type="button">
                                                        <span className="svg-icon svg-icon-3">
                                                            {!exportLoader ?
                                                                <BiExport className='jobMonitor-svg' /> :
                                                                <div className="spinner-border spinner-border-sm text-light" role="status">
                                                                </div>
                                                            }
                                                        </span>


                                                    </Button>
                                                </Tooltip>
                                            </div>
                                            <div>
                                                <Tooltip disableInteractive title="Total" placement='top' arrow>
                                                    <Button className="btn btn-light btn-active-primary  jobMonitor-Buttons"
                                                        onClick={handleTotalJobMonitor}
                                                        disabled={disableTotal}
                                                        type="button">
                                                        <span className="svg-icon svg-icon-3 jobMonitor-Release-span">
                                                            {grandTotal.isActive ?
                                                                <BiShow /> :
                                                                <BiHide />
                                                            }
                                                        </span>


                                                    </Button>
                                                </Tooltip>
                                            </div>
                                            <DownloadDropDown />

                                        </div>
                                    </div>
                                </Form>

                                <div className="p-2">
                                    <div className="table-responsive">
                                        <div className="dashboardtable">
                                            {!unselectCheck &&
                                                <DataTable
                                                    // paginationDefaultPage={pageno}
                                                    data={data?.data?.results?.data}
                                                    className="custom-datatable"
                                                    columns={columns}
                                                    progressPending={loading}
                                                    pagination

                                                    paginationServer
                                                    pointerOnHover={true}
                                                    fixedHeader={true}
                                                    // selectableRows
                                                    // onSelectedRowsChange={handleRowSelected}


                                                    paginationTotalRows={totalRows}
                                                    paginationPerPage={limit}
                                                    paginationRowsPerPageOptions={[25, 50, 100]}
                                                    onChangeRowsPerPage={handlePerRowsChange}
                                                    onChangePage={handlePageChange}
                                                    selectableRowDisabled={rowDisabledCriteria}
                                                    theme="solarized"
                                                    customStyles={queueDatatableCustomStyles}
                                                    conditionalRowStyles={conditionalRowStyles}
                                                    onRowClicked={handleRowClickCheck}
                                                    sortServer
                                                    onSort={handleSort}
                                                    responsive={true}
                                                    customFooter

                                                />
                                            }
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>

        </React.Fragment>
    )
}

export default DataList;
