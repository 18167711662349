import React from 'react'
import newicon from "../../../../../assets/img/new-icon.svg";
import addicon from "../../../../../assets/img/add-icon.svg";
import deleteicon from "../../../../../assets/img/delete-icon.svg";
import copyicon from "../../../../../assets/img/copy-icon.svg";
import { BiChevronLeft, BiChevronRight, BiFirstPage, BiLastPage } from 'react-icons/bi';
import { useState } from 'react';
import { useRef } from 'react';
import { QA_REVIEW, EDIT_WORK } from '../../../../../functions';
const ClaimHeaderEob = (props) => {

    const {
        formik,
        currentPage,

        createNewClaim,
        copyClaim,
        claim835Index,
        setClaim835Index,
        jobData,
        cleareEobFocus,
        addingNewEobClaimDbk,
        removeEobClaimDbk,
        setState,
        state,
        handleRemainingBalanceCalculation,
        isViewOnllyGrid,
        job_id,
        log_id
    } = props;


    // delete claim index data when formik has more than one claim
    const deleteClaim = async () => {

        // var claims = formik.values.claims;

        // claims.splice(claim835Index, 1);

        // await formik.setValues({
        //     ...formik.values,
        //     claims: claims,
        // })


        await formik.setValues(prevValues => {
            const claims = [...prevValues.claims];

            // Remove the third claim (index 2)
            claims.splice(claim835Index, 1);

            return {
                ...prevValues,
                claims: [...claims],
            };
        });

        await handleRemainingBalanceCalculation()


    }
    // reset claim only on if the formik has only one claim.
    const resetClaimData = () => {
        const _ = require('lodash');

        const data = _.cloneDeep(formik.values?.claims);
        // var datas = formik.values?.claims;
        data[claim835Index] = {
            patient: true,
            subscriber: true,
            rendering_provider: true,
            // corrected_claim: true,

            order: "",
            color_index: "",
            claim_id: "",
            patient_account_number: "",
            payer_claim_number: "",
            period_start: "",
            period_end: "",
            status_code: "",
            page_from: currentPage + 1,
            page_to: currentPage + 1,
            mrn: "",
            statement_number: "",
            claim_billed: "",
            claim_discount: "",
            claim_allowed: "",
            claim_deduct: "",
            claim_coins: "",
            claim_copay: "",
            claim_cob: "",
            claim_patres: "",
            claim_paid: "",
            claim_balance: "",

            sl_billed: "",
            sl_discount: "",
            sl_allowed: "",
            sl_deduct: "",
            sl_coins: "",
            sl_copay: "",
            sl_cob: "",
            sl_patres: "",
            sl_paid: "",
            sl_balance: "",
            sl_total: "",

            color_data: [],

            patient_id: "",
            patient_first_name: "",
            patient_middle_name: "",
            patient_last_name: "",
            patient_identifier_code: "",

            subscriber_id: "",
            subscriber_first_name: "",
            subscriber_middle_name: "",
            subscriber_last_name: "",
            subscriber_identifier_code: "",

            rendering_provider_id: "",
            rendering_provider_first_name: "",
            rendering_provider_last_name: "",
            rendering_provider_identifier_code: "",

            dbk_patient_account_number: "",
            dbk_payer_claim_number: "",

            dbk_patient_first_name: "",
            dbk_patient_middle_name: "",
            dbk_patient_last_name: "",
            dbk_patient_identifier_code: "",

            dbk_subscriber_first_name: "",
            dbk_subscriber_middle_name: "",
            dbk_subscriber_last_name: "",
            dbk_subscriber_identifier_code: "",

            dbk_rendering_provider_first_name: "",
            dbk_rendering_provider_last_name: "",
            dbk_rendering_provider_identifier_code: "",

            claim_adjustments: [{
                pc: "",
                group_code: "",
                reason_code: "",
                rmk: "",
                amount: "",
                claim_adjustment_id: ""
            }],


            service_lines: [{
                color_index: "",
                // corrected_serviceline: true,
                order: "",
                service_line_id: "",
                from_date: "",
                to_date: "",
                code: "",
                procedure_type: "HC",
                modifier1: "",
                modifier2: "",
                modifier3: "",
                modifier4: "",
                rev_code: "",
                units: "",
                billed: "",
                patres: "",
                discount: "",
                allowed: "",
                deduct: "",
                coins: "",
                copay: "",
                cob: "",
                paid: "",
                color_data: [],

                balance: "",
                adjustments: [{
                    adjustment_id: "",
                    pc: "",
                    group_code: "",
                    reason_code: "",
                    rmk: "",
                    amount: "",
                }],
            }]

        }
        formik.setValues({
            ...formik.values,
            claims: data,
        })

    }



    //patient - subscriber - rendering add and remove buttons and its functions
    const [patient, setPatient] = useState(true);

    const [subscriber, setSubscriber] = useState(true);

    const [inputRenderingprovider, setRenderingprovider] = useState(true);

    const __addPatient = () => {
        var data = formik?.values?.claims;
        data[claim835Index].patient = true;
        formik.setValues({ ...formik.values, 'claims': data })

    }
    const __addSubscriber = () => {
        var data = formik?.values?.claims;
        data[claim835Index].subscriber = true;
        formik.setValues({ ...formik.values, 'claims': data })
    }
    const __addRenderingprovider = () => {
        var data = formik?.values?.claims;
        data[claim835Index].rendering_provider = true;
        formik.setValues({ ...formik.values, 'claims': data })

    }


    const handleBackspace = (e) => {
        if (e.keyCode == 8) {
            e.preventDefault();

            e.target.select();
            // pageRef.current.select()
        }
    }
    const handleClaimPagination = (value, e) => {
        // console.log(e, '@@@@@@@@@@@##################');


        if (value < 1 || value == 0) {
            setClaim835Index(0)
        }
        else if (value <= formik.values?.claims?.length) {
            setClaim835Index(value - 1);
        }
        else if (value > formik.values?.claims?.length) {
            // setClaim835Index(formik.values?.totalclaims?.length)
        }
        // else {
        //     setClaim835Index(e.target.value)

        // }


    }
    const pathname = window.location.pathname;

    const renderClass = () => {
        // return '#ecf38f' 



        // else if ((pathname == QA_REVIEW || pathname == EDIT_WORK + job_id || pathname == EDIT_WORK + job_id + "?log_id=" + log_id) &&
        //     jobData?.grid_data?.qdx_status != formik.values?.status) {
        //     'select-search input-focus-shadow qa-field'
        // }
        // else {
        //     'select-search input-focus-shadow'
        // }


        if (pathname == QA_REVIEW || pathname == EDIT_WORK + job_id || pathname == EDIT_WORK + job_id + "?log_id=" + log_id) {
            if (jobData?.grid_data?.qa_corrected_data?.qdx_reasons) {
                return '#ecf38f'
            }
            else {
                const array1 = jobData?.grid_data?.qdx_reasons ? jobData?.grid_data?.qdx_reasons : [];
                const array2 = formik.values?.reason ? formik.values?.reason : [];
                const array1sort = array1.sort()
                const array2sort = array2.sort()
                if (JSON.stringify(array1sort) != JSON.stringify(array2sort)) {
                    return '#ecf38f'
                }
            }
        }


    }
    return (
        <React.Fragment>
            <div className="healthcare-sub">
                <div className="healthcare-top">
                    <div className="leftside-block">
                        <div className="accordion" id="claim">
                            <button type="button" tabIndex="-1" className="btn btn-healthcare accordion-icon" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">Claim835</button>
                        </div>
                        <div className="">
                            <button type="button" tabIndex="-1" className="btn btn-healthcare" id='claim_add_btn-aaa' onClick={createNewClaim}> <img src={newicon} alt="" />  New
                            </button>
                        </div>
                        <div className="">
                            {formik.values?.claims?.length == 1 ? (

                                <button type="button" disabled={isViewOnllyGrid} tabIndex="-1" className="btn btn-healthcare" onClick={resetClaimData}> <img src={deleteicon} alt="" /> Delete</button>

                            ) : (


                                <button type="button" tabIndex="-1" className="btn btn-healthcare" disabled={formik.values?.claims?.length == 1 || isViewOnllyGrid} onClick={() => {
                                    setClaim835Index(claim835Index + 1 == formik.values.claims.length ? claim835Index - 1 : claim835Index);
                                    deleteClaim()
                                    removeEobClaimDbk(claim835Index)
                                }}> <img src={deleteicon} alt="" /> Delete</button>
                            )
                            }
                            {/* <button type="button" tabIndex="-1" className="btn btn-healthcare" disabled={formik.values?.claims?.length == 1 || claim835Index + 1 == 1} onClick={() => { claimHelpers.remove(claim835Index); setClaim835Index(claim835Index - 1) }}> <img src={deleteicon} alt="" /> Delete</button> */}
                        </div>
                        <div className="">
                            <button onClick={copyClaim} type="button" tabIndex="-1" className="btn btn-healthcare"> <img src={copyicon} alt="" /> Copy</button>
                        </div>
                        <div className="dropdown">
                            <button type="button" tabIndex="-1" className="btn btn-healthcare" data-bs-toggle="dropdown"> <img src={addicon} alt="" /> Add</button>
                            <ul className="dropdown-menu">
                                <li><button tabIndex="-1" type="button" className="dropdown-item" onClick={__addPatient}>Patient</button></li>
                                <li><button tabIndex="-1" type="button" className="dropdown-item" onClick={__addSubscriber}>Subscriber</button></li>
                                <li><button tabIndex="-1" type="button" className="dropdown-item" onClick={__addRenderingprovider}>Renderingprovider</button></li>
                            </ul>
                        </div>
                    </div>
                    <div className="rightside-block exclude-disabled-new">
                        <button type="button" tabIndex="-1" className='py-0 m-0 btn pagination-btn btn-sm ' disabled={claim835Index + 1 === 1 ? true : false} onClick={(e) => {
                            setClaim835Index(0);
                            setState({
                                ...state,
                                updateDisable: !state.updateDisable,
                            })

                        }} >

                            <span><BiFirstPage size="1.3em" style={claim835Index + 1 === 1 ? { color: '#ffefef' } : { color: 'black' }} /></span>
                        </button>
                        <button type="button" tabIndex="-1" className='py-0 m-0 btn pagination-btn btn-sm'
                            disabled={claim835Index + 1 === 1 ? true : false}
                            onClick={(e) => {
                                setClaim835Index(claim835Index - 1);
                                setState({
                                    ...state,
                                    updateDisable: !state.updateDisable,
                                })

                            }} >
                            <BiChevronLeft size="1.3em" style={claim835Index + 1 === 1 ? { color: '#ffefef' } : { color: 'black' }} />
                        </button>
                        <div className="pagenum-sub">
                            <input tabIndex="-1" className="viewer-toolbar-current-page-input text-center w-82" type="text" value={claim835Index + 1}
                                // ref={pageRef}
                                onKeyDown={(e) => handleBackspace(e)}

                                onChange={(e) => { handleClaimPagination(e.target.value, e); }}
                            />

                            / {formik.values?.claims?.length}</div>
                        <button type="button" tabIndex="-1" className='py-0 m-0 pagination-btn btn btn-sm' disabled={formik.values?.claims?.length == claim835Index + 1 ? true : false} onClick={(e) => {
                            setClaim835Index(claim835Index + 1);
                            setState({
                                ...state,
                                updateDisable: !state.updateDisable,
                            })

                        }}>
                            <BiChevronRight size="1.3em" style={formik.values?.claims?.length - 1 === claim835Index ? { color: '#ffefef' } : { color: 'black' }} />
                        </button>
                        <button type="button" tabIndex="-1" className='py-0  btn pagination-btn btn-sm ' disabled={!formik.values?.claims?.length < claim835Index && formik.values?.claims?.length - 1 === claim835Index ? true : false} onClick={(e) => {
                            setClaim835Index(formik.values?.claims.length - 1);
                            setState({
                                ...state,
                                updateDisable: !state.updateDisable,
                            })

                        }}>
                            <span><BiLastPage size="1.3em" style={formik.values?.claims?.length - 1 === claim835Index ? { color: '#ffefef' } : { color: 'black' }} /></span>
                        </button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default ClaimHeaderEob