import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";

import addDays from 'date-fns/addDays';
import addMonths from 'date-fns/addMonths';
import endOfMonth from 'date-fns/endOfMonth';
import endOfWeek from 'date-fns/endOfWeek';
import startOfMonth from 'date-fns/startOfMonth';
import startOfWeek from 'date-fns/startOfWeek';
import subDays from 'date-fns/subDays';
import { useFormik } from "formik";
import moment from "moment";
import { useCallback, useMemo } from "react";
import { Button, Form, OverlayTrigger } from "react-bootstrap";
import { BiExport, BiSearch } from "react-icons/bi";
import { BsCloudDownloadFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { DateRangePicker, Stack } from "rsuite";
import { downloadXl } from "../../../functions";
import { ClientReportService } from "../../../services/ClientReportService";
import { selectIsSuperUser, selectPermissions } from "../../../slice/user/userSlice";
import Sidebar from "../../includes/Sidebar";
import ClientReportDetails from "./ClientReportDetails";
import { Tooltip } from "@mui/material";
import SingleSelect from "../../components/SingleSelect";
import { seErrorMessage } from "../../../slice/error/errorSlice";
import { InvoiceReportService } from "../../../services/InvoiceReportService";
import { useRef } from "react";
import Select, { components } from 'react-select';
import { TbGridDots } from "react-icons/tb";
import { HiChevronDown } from "react-icons/hi";
import DownloadDropDown from "../../components/DownloadDropDown";


const datatableCustomStyles = {
  rows: {
    style: {
      minHeight: '20px', // override the row height
      fontSize: '0.7em',
      fontColor: '#212529 !important',
      width: '100%'
    },
  },
  headCells: {
    style: {
      paddingLeft: '5px', // override the cell padding for head cells
      paddingRight: '5px',
      textTransform: "uppercase",
      fontSize: '0.7em',
      fontWeight: '800',
      fontColor: '#6c757d !important',
      opacity: 1,
      minHeight: "10px",
    },
  },
  cells: {
    style: {
      paddingLeft: '8px', // override the cell padding for data cells
      paddingRight: '8px',
      width: "100%",
    },
  },
  pagination: {
    style: {
      color: '#000',
      fontSize: '13px',
      minHeight: '56px',
      backgroundColor: '#fff',
      borderTopStyle: 'solid',
      borderTopWidth: '1px',
      borderTopColor: '#f2f2f2',
    },
    pageButtonsStyle: {
      borderRadius: '50%',
      height: '40px',
      width: '40px',
      padding: '8px',
      margin: '0px',
      cursor: 'pointer',
      transition: '0.4s',
      color: '#000',
      fill: '#000',
      backgroundColor: 'transparent',
      '&:disabled': {
        cursor: 'unset',
        color: '#e2e2e2',
        fill: '#e2e2e2',
      },
      '&:hover:not(:disabled)': {
        backgroundColor: '#f2f2f2',
      },
      '&:focus': {
        outline: 'none',
        backgroundColor: '#f5f5f5',
      },
    },
  },
};

function ClientReport() {
  const dispatch = useDispatch()

  const facilityRef = useRef(null)
  const clientRef = useRef(null)

  const [exportLoader, setExportLoader] = useState(false);
  const [reRenderDataTable, setRerenderDataTable] = useState(false)
  const [pageno, setPageNo] = useState(1);
  const [loading, setLoading] = useState(false);
  const [isTimeCount, setisTimeCount] = useState(false);
  const [totalRows, setTotalRows] = useState(100);
  const [filterText, setFilterText] = useState('');
  const [colorRow, setColorRow] = React.useState({});
  const [data, setData] = useState({})
  const [limit, setLimit] = useState(100)
  const [searchedData, setSearchedData] = useState({
    job_status: "",
    hour_count: "",
    date_range: `${moment(startOfMonth(new Date())).format('MM/DD/YYYY')} - ${moment(new Date()).format('MM/DD/YYYY')}`,
    clients: [],
    facilities: [],
  })

  const [isFocusedClient, setIsFocusedClient] = useState(false);

  const [clients, setClients] = useState([]);

  const [isFocusedFacility, setIsFocusedFacility] = useState(false);

  const [selectValue, setSelectValue] = useState([])

  const [facilities, setFacilities] = useState([])

  const [selectIds, setSelectIds] = useState("")

  const [selectedFacility, setSelectedFacility] = useState([])
  const [selectedStation, setSelectedStation] = useState('')

  const [selectedFacilityIds, setSelectedFacilityIds] = useState("")

  const clientReportService = new ClientReportService();
  const invoiceReportService = new InvoiceReportService
    ();

  const { getClientReportSummery, downloadClientReportSummery, downloadClientReportSingle } = clientReportService
  const { getFacilityDropdown, getAllClientDropdown } = invoiceReportService

  const permissions = useSelector(selectPermissions);
  const isSuperUser = useSelector(selectIsSuperUser);
  const isHavePermission = (name) => {
    return permissions?.includes(name) || isSuperUser
  }
  const station_option = [
    { value: "1", label: "My Work" },
    { value: "2", label: "QA Review" },
    { value: "3", label: "Snipping" },
  ]
  const predefinedBottomRanges = [
    {
      label: 'Today',
      value: [new Date(), new Date()],
      placement: 'left'
    },
    {
      label: 'Yesterday',
      value: [addDays(new Date(), -1), addDays(new Date(), -1)],
      placement: 'left'
    },
    {
      label: 'This week',
      value: [startOfWeek(new Date()), endOfWeek(new Date())],
      placement: 'left'
    },
    {
      label: 'Last 7 days',
      value: [subDays(new Date(), 6), new Date()],
      placement: 'left'
    },
    {
      label: 'Last 30 days',
      value: [subDays(new Date(), 29), new Date()],
      placement: 'left'
    },
    {
      label: 'This month',
      value: [startOfMonth(new Date()), new Date()],
      placement: 'left'
    },
    {
      label: 'Last month',
      value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
      placement: 'left'
    },
    {
      label: 'This year',
      value: [new Date(new Date().getFullYear(), 0, 1), new Date()],
      placement: 'left'
    },
    {
      label: 'Last year',
      value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date(new Date().getFullYear(), 0, 0)],
      placement: 'left'
    },

    {
      label: 'Last week',
      closeOverlay: false,
      value: value => {
        const [start = new Date()] = value || [];
        return [
          addDays(startOfWeek(start, { weekStartsOn: 0 }), -7),
          addDays(endOfWeek(start, { weekStartsOn: 0 }), -7)
        ];
      },
      appearance: 'default'
    },
    {
      label: 'Next week',
      closeOverlay: false,
      value: value => {
        const [start = new Date()] = value || [];
        return [
          addDays(startOfWeek(start, { weekStartsOn: 0 }), 7),
          addDays(endOfWeek(start, { weekStartsOn: 0 }), 7)
        ];
      },
      appearance: 'default'
    }
  ];
  const handleClear = () => {
    if (filterText) {
      //setResetPaginationToggle(!resetPaginationToggle);
      setFilterText('');
      // fetchData(1, limit)
    }
  };
  const handleSearchSplits = async (e) => {
    setFilterText(e.target.value)
    let data = e.target.value ? e.target.value : '';
    fetchData(1, limit, searchFormik?.values?.station, selectValue, datetime, searchFormik?.values?.hour_count, searchFormik?.values?.clients ?? "", searchFormik?.values?.facilities ?? "");

  }

  const handlePerRowsChange = async (newPerPage, page) => {

    setLimit(newPerPage);
    setPageNo(1)
    fetchData(1, newPerPage, searchedData?.station ?? "", searchedData?.job_status ?? "", searchedData?.date_range ?? "", searchFormik?.values?.hour_count ?? "", searchFormik?.values?.clients ?? "", searchFormik?.values?.facilities ?? "");
    handleResetDataTable()

  };

  const handlePageChange = page => {
    setPageNo(page)
    fetchData(page, limit, searchedData?.station ?? "", searchedData?.job_status ?? "", searchedData?.date_range ?? "", searchFormik?.values?.hour_count ?? "", searchFormik?.values?.clients ?? "", searchFormik?.values?.facilities ?? "");
  };



  const fetchData = (page, limit, station = '', job_status, date_range, hour_count, clients, facilities) => {
    setLoading(true)

    setisTimeCount(hour_count == 'true' || hour_count == '' ? false : true)
    getClientReportSummery(page, limit, station, job_status, date_range, hour_count, clients, facilities).then((response) => {
      setData(response?.data);
      setLimit(limit);
      setPageNo(page)
      setTotalRows(response?.data?.count);
      setLoading(false);
      if (response?.errors?.date_range) {
        dispatch(seErrorMessage({ message: response?.errors?.date_range[0], is_show: true }))
      }
    });

  }

  const [expandedRows, setExpandedRows] = useState([]);
  const ExpandedComponent = useCallback(
    ({ data }) => (
      <pre>
        {expandedRows?.length > 0 && (
          <ClientReportDetails
            report_page={1}
            report_limit={limit}
            report_station={searchedData?.station}
            report_job_status={""}
            report_date_range={searchedData?.date_range}
            report_hour_count={searchedData?.hour_count}
            clients={searchedData?.clients}
            facilities={searchedData?.facilities}
            facility_id={data?.facility_id}
            user={data.client_id}
            isTimeCount={isTimeCount}
          />
        )}
      </pre>
    ),
    [expandedRows, limit, searchedData, isTimeCount]
  );
  const handleRowExpand = useCallback((row) => {
    setColorRow(row);
    const isRowExpanded = expandedRows.includes(row);
    setExpandedRows(isRowExpanded ? expandedRows.filter((r) => r !== row) : [row]);
  });

  const searchFormik = useFormik({
    initialValues: {
      search: "",
      job_status: "",
      station: 1,
      date_range: [startOfMonth(new Date()), new Date()],
      created_date: `${moment(startOfMonth(new Date())).format('MM/DD/YYYY')} - ${moment(new Date()).format('MM/DD/YYYY')}`,
      hour_count: "",
      clients: [],
      facilities: ""

    },
    onSubmit: (values) => {

      searchData(values)
      handleResetDataTable()
    }
  })
  const handleRowClick = useCallback((row) => {
    // setColorRow(row);
    // setExpandedRows([])
    setColorRow(row);
    const isRowExpanded = expandedRows.includes(row);
    setExpandedRows(isRowExpanded ? expandedRows.filter((r) => r !== row) : [row]);
  });
  const columns = useMemo(
    () => [
      {
        id: 'S.NO',
        name: 'S.NO',
        selector: (row, index) => pageno == 1 ? index + 1 : (pageno - 1) * limit + index + 1,
        wrap: true,
        width: "40px",
        grow: 0,

      },
      {
        id: 'CLIENT NAME',
        name: 'CLIENT NAME',
        selector: row => row?.name,
        cell: (row) =>

          <div>
            {row?.name &&

              <div onClick={() => handleRowExpand(row)} className='d-flex flex-row justify-content-between align-items-center gap-2' style={{ maxWidth: '100px' }}>
                <div className='overflow-hidden text-truncate' style={{ maxWidth: "100px" }} >
                  <Tooltip disableInteractive title={row?.name} placement="top" arrow>
                    <span className="text-truncate" > {row?.name}</span>
                  </Tooltip>
                </div>
              </div>

            }
          </div>
        ,


        left: true,
        wrap: true,
        reorder: false,
        grow: 0,
        expandOnRowClicked: true,
        minWidth: '110px'


      },
      {
        id: 'FACILITY NAME',
        name: 'FACILITY NAME',
        selector: row => row?.facility,
        cell: (row) =>

          <div>
            {row?.facility &&

              <div onClick={() => handleRowExpand(row)} className='d-flex flex-row justify-content-between align-items-center gap-2' style={{ maxWidth: '100px' }}>
                <div className='overflow-hidden text-truncate' style={{ maxWidth: "100px" }} >
                  <Tooltip disableInteractive title={row?.facility} placement="top" arrow>
                    <span className="text-truncate" > {row?.facility}</span>
                  </Tooltip>
                </div>
              </div>

            }
          </div>
        ,


        left: true,
        wrap: true,
        reorder: false,
        grow: 0,
        expandOnRowClicked: true,
        minWidth: '110px'


      },
      {
        id: 'PAYMENT835',
        name: 'PAYMENT835',
        selector: row => row?.no_of_835,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.no_of_835}</span>,

        right: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        minWidth: '72px'

      },
      {
        id: 'TIME',
        name: 'TIME',
        selector: row => row?.time_835,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.time_835}</span>,

        left: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        minWidth: '65px',
        omit: searchedData.hour_count == "" ? false : true,


      },
      {
        id: 'PAYMENT LITE',
        name: 'PAYMENT LITE',
        selector: row => row?.no_835_lite,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.no_835_lite}</span>,

        right: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        minWidth: '80px'

      },
      {
        id: 'TIME',
        name: 'TIME',
        selector: row => row?.time_835_lite,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.time_835_lite}</span>,

        left: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        minWidth: '55px',
        omit: searchedData.hour_count == "" ? false : true,


      },
      {
        id: 'CORRES',
        name: 'CORRES',
        selector: row => row?.correspondence,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.correspondence}</span>,

        right: true,
        wrap: true,
        reorder: false,
        grow: 0,
        minWidth: '55px'

      },
      {
        id: 'TIME',
        name: 'TIME',
        selector: row => row?.time_correspondence,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.time_correspondence}</span>,

        left: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        minWidth: '55px',
        omit: searchedData.hour_count == "" ? false : true,


      },
      {
        id: 'PAT PAY',
        name: 'PAT PAY',
        selector: row => row?.patient_pay,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.patient_pay}</span>,

        right: true,
        wrap: true,
        reorder: false,
        grow: 0,
        minWidth: '55px'

      },
      {
        id: 'TIME',
        name: 'TIME',
        selector: row => row?.time_patient_pay,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.time_patient_pay}</span>,

        left: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        minWidth: '55px',
        omit: searchedData.hour_count == "" ? false : true,


      },
      {
        id: 'PAT PAY 835',
        name: 'PAT PAY 835',
        selector: row => row?.patient_pay_835,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.patient_pay_835}</span>,

        right: true,
        wrap: true,
        reorder: false,
        grow: 0,
        minWidth: '70px'
      },

      {
        id: 'TIME',
        name: 'TIME',
        selector: row => row?.time_patient_pay_835,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.time_patient_pay_835}</span>,

        left: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        minWidth: '55px',
        omit: searchedData.hour_count == "" ? false : true,


      },
      {
        id: 'CORRES 835',
        name: 'CORRES 835',
        selector: row => row?.correspondence_835,
        cell: (row) => <span onClick={() => handleRowClick(row)} > {row?.correspondence_835}</span>,

        right: true,
        wrap: true,
        reorder: false,
        grow: 0,
        minWidth: '70px'
      },
      {
        id: 'TIME',
        name: 'TIME',
        selector: row => row?.time_correspondence_835,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.time_correspondence_835}</span>,

        left: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        minWidth: '65px',
        omit: searchedData.hour_count == "" ? false : true,


      },
      {
        id: 'JOB TOTAL',
        name: 'JOB TOTAL',
        selector: row => row?.total,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.total}</span>,

        right: true,
        wrap: true,
        reorder: false,
        grow: 0,
        omit: isTimeCount,
        minWidth: '65px'



      },
      {
        id: 'REJECTED',
        name: 'REJECTED',
        selector: row => row?.rejected,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.rejected}</span>,

        right: true,
        wrap: true,
        reorder: false,
        grow: 0,
        omit: isTimeCount,
        minWidth: '65px'



      },
      {
        id: 'HOLD',
        name: 'HOLD',
        selector: row => row?.hold,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.hold}</span>,

        right: true,
        wrap: true,
        reorder: false,
        grow: 0,
        omit: isTimeCount,
        minWidth: '50px'



      },
      {
        id: 'MANUAL SPLIT',
        name: 'MANUAL SPLIT',
        selector: row => row?.file_split,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.file_split}</span>,

        right: true,
        wrap: true,
        reorder: false,
        grow: 0,
        minWidth: '85px'
      },
      {
        id: 'TIME',
        name: 'TIME',
        selector: row => row?.time_file_split,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.time_file_split}</span>,

        left: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        minWidth: '55px',
        omit: searchedData.hour_count == "" ? false : true,


      },
      {
        id: 'TOTAL TIME',
        name: 'TOTAL TIME',
        selector: row => row?.work_time_total_time,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.work_time_total_time}</span>,

        right: true,
        wrap: true,
        reorder: false,
        grow: 0,
        minWidth: '70px',
        omit: searchedData.hour_count == "" ? false : true,


      },
      {
        id: 'QUALITY',
        name: 'QUALITY %',
        selector: row => row?.quality,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.quality}</span>,

        right: true,
        wrap: true,
        reorder: false,
        grow: 0,
        minWidth: '65px'

      },
      {
        id: '# CLAIMS',
        name: '# CLAIMS',
        selector: row => row?.claims,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.claims}</span>,

        right: true,
        wrap: true,
        reorder: false,
        grow: 0,
        omit: isTimeCount,
        minWidth: '65px'

      },
      {
        id: '# SVC LINES',
        name: '# SVC LINES',
        selector: row => row?.servicelines,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.servicelines}</span>,

        right: true,
        wrap: true,
        reorder: false,
        grow: 0,
        omit: isTimeCount,
        minWidth: '70px'


      },
      {
        id: 'LARGE BATCHES',
        name: 'LARGE BATCHES',
        selector: row => row?.large_batches,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.large_batches}</span>,

        right: true,
        wrap: true,
        reorder: false,
        grow: 0.,
        omit: isTimeCount,
        minWidth: '90px'


      },
      {
        id: 'COMPLETE',
        name: 'COMPLETE',
        selector: row => row?.complete,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.complete}</span>,

        right: true,
        wrap: true,
        reorder: false,
        grow: 0,
        omit: isTimeCount,
        minWidth: '70px'


      },
      {
        id: 'INCOMPLETE',
        name: 'INCOMPLETE',
        selector: row => row?.incomplete,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.incomplete}</span>,

        right: true,
        wrap: true,
        reorder: false,
        grow: 0,
        omit: isTimeCount,
        minWidth: '80px'


      },

      {
        id: 'JOB TOTAL',
        name: 'JOB TOTAL',
        selector: row => row?.total,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.total}</span>,

        right: true,
        wrap: true,
        reorder: false,
        grow: 0,
        omit: !isTimeCount,
        minWidth: '70px'


      },
      {
        id: 'CPH',
        name: 'CPH',
        selector: row => row?.cph,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.cph}</span>,

        right: true,
        wrap: true,
        reorder: false,
        grow: 0,
        omit: isTimeCount,
        minWidth: '55px',


      },

    ],

    [isTimeCount, pageno, limit, handleRowExpand, handleRowClick],
  );
  const countColumns = useMemo(
    () => [
      {
        id: 'S.NO',
        name: 'S.NO',
        selector: (row, index) => pageno == 1 ? index + 1 : (pageno - 1) * limit + index + 1,
        wrap: true,
        width: "40px",
        grow: 0,

      },
      {
        id: 'CLIENT NAME',
        name: 'CLIENT NAME',
        selector: row => row?.name,
        cell: (row) =>

          <div>
            {row?.name &&

              <div onClick={() => handleRowExpand(row)} className='d-flex flex-row justify-content-between align-items-center gap-2' style={{ maxWidth: '110px' }}>
                <div className='overflow-hidden text-truncate' style={{ maxWidth: "110px" }} >
                  <Tooltip disableInteractive title={row?.name} placement="top" arrow>
                    <span className="text-truncate" > {row?.name}</span>
                  </Tooltip>
                </div>
              </div>

            }
          </div>
        ,


        left: true,
        wrap: true,
        reorder: false,
        grow: 0,
        expandOnRowClicked: true,
        minWidth: '120px',



      },
      {
        id: 'FACILITY NAME',
        name: 'FACILITY NAME',
        selector: row => row?.facility,
        cell: (row) =>

          <div>
            {row?.facility &&

              <div onClick={() => handleRowExpand(row)} className='d-flex flex-row justify-content-between align-items-center gap-2' style={{ maxWidth: '100px' }}>
                <div className='overflow-hidden text-truncate' style={{ maxWidth: "100px" }} >
                  <Tooltip disableInteractive title={row?.facility} placement="top" arrow>
                    <span className="text-truncate" > {row?.facility}</span>
                  </Tooltip>
                </div>
              </div>

            }
          </div>
        ,


        left: true,
        wrap: true,
        reorder: false,
        grow: 0,
        expandOnRowClicked: true,
        minWidth: '110px',



      },
      {
        id: 'PAYMENT835',
        name: 'PAYMENT835',
        selector: row => row?.no_of_835,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.no_of_835}</span>,

        right: true,
        wrap: true,
        reorder: false,
        grow: 0,

      },

      {
        id: 'PAYMENT LITE',
        name: 'PAYMENT LITE',
        selector: row => row?.no_835_lite,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.no_835_lite}</span>,

        right: true,
        wrap: true,
        reorder: false,
        grow: 0,

      },

      {
        id: 'CORRES',
        name: 'CORRES',
        selector: row => row?.correspondence,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.correspondence}</span>,

        right: true,
        wrap: true,
        reorder: false,
        grow: 0,
        minWidth: '90px'

      },

      {
        id: 'PAT PAY',
        name: 'PAT PAY',
        selector: row => row?.patient_pay,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.patient_pay}</span>,

        right: true,
        wrap: true,
        reorder: false,
        grow: 0,
        minWidth: '90px'

      },

      {
        id: 'PAT PAY 835',
        name: 'PAT PAY 835',
        selector: row => row?.patient_pay_835,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.patient_pay_835}</span>,

        right: true,
        wrap: true,
        reorder: false,
        grow: 0,
        minWidth: '90px'
      },


      {
        id: 'CORRES 835',
        name: 'CORRES 835',
        selector: row => row?.correspondence_835,
        cell: (row) => <span onClick={() => handleRowClick(row)} > {row?.correspondence_835}</span>,

        right: true,
        wrap: true,
        reorder: false,
        grow: 0,
        minWidth: '90px'
      },

      {
        id: 'JOB TOTAL',
        name: 'JOB TOTAL',
        selector: row => row?.total,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.total}</span>,

        right: true,
        wrap: true,
        reorder: false,
        grow: 0,
        minWidth: '90px'



      },
      {
        id: 'REJECTED',
        name: 'REJECTED',
        selector: row => row?.rejected,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.rejected}</span>,

        right: true,
        wrap: true,
        reorder: false,
        grow: 0,
        minWidth: '80px'



      },
      {
        id: 'HOLD',
        name: 'HOLD',
        selector: row => row?.hold,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.hold}</span>,

        right: true,
        wrap: true,
        reorder: false,
        grow: 0,
        minWidth: '70px'



      },
      {
        id: 'MANUAL SPLIT',
        name: 'MANUAL SPLIT',
        selector: row => row?.file_split,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.file_split}</span>,

        right: true,
        wrap: true,
        reorder: false,
        grow: 0,
        minWidth: '90px'
      },


      {
        id: 'QUALITY',
        name: 'QUALITY %',
        selector: row => row?.quality,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.quality}</span>,

        right: true,
        wrap: true,
        reorder: false,
        grow: 0,
        minWidth: '80px'

      },
      {
        id: '# CLAIMS',
        name: '# CLAIMS',
        selector: row => row?.claims,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.claims}</span>,

        right: true,
        wrap: true,
        reorder: false,
        grow: 0,
        minWidth: '80px'

      },
      {
        id: '# SVC LINES',
        name: '# SVC LINES',
        selector: row => row?.servicelines,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.servicelines}</span>,

        right: true,
        wrap: true,
        reorder: false,
        grow: 0,
        minWidth: '85px'


      },
      {
        id: 'LARGE BATCHES',
        name: 'LARGE BATCHES',
        selector: row => row?.large_batches,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.large_batches}</span>,

        right: true,
        wrap: true,
        reorder: false,
        grow: 0.,
        minWidth: '95px'


      },
      {
        id: 'COMPLETE',
        name: 'COMPLETE',
        selector: row => row?.complete,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.complete}</span>,

        right: true,
        wrap: true,
        reorder: false,
        grow: 0,
        minWidth: '85px'


      },
      {
        id: 'INCOMPLETE',
        name: 'INCOMPLETE',
        selector: row => row?.incomplete,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.incomplete}</span>,

        right: true,
        wrap: true,
        reorder: false,
        grow: 0,
        minWidth: '90px'


      },


      {
        id: 'CPH',
        name: 'CPH',
        selector: row => row?.cph,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.cph}</span>,

        right: true,
        wrap: true,
        reorder: false,
        grow: 0,
        minWidth: '95px',


      },

    ],

    [pageno, limit, handleRowExpand, handleRowClick],
  );
  const timeColumns = useMemo(
    () => [
      {
        id: 'S.NO',
        name: 'S.NO',
        selector: (row, index) => pageno == 1 ? index + 1 : (pageno - 1) * limit + index + 1,
        wrap: true,
        width: "40px",
        grow: 0,

      },
      {
        id: 'CLIENT NAME',
        name: 'CLIENT NAME',
        selector: row => row?.name,
        cell: (row) =>

          <div>
            {row?.name &&

              <div onClick={() => handleRowExpand(row)} className='d-flex flex-row justify-content-between align-items-center gap-2' style={{ maxWidth: '120px' }}>
                <div className='overflow-hidden text-truncate' style={{ maxWidth: "120px" }} >
                  <Tooltip disableInteractive title={row?.name} placement="top" arrow>
                    <span className="text-truncate" > {row?.name}</span>
                  </Tooltip>
                </div>
              </div>

            }
          </div>
        ,


        left: true,
        wrap: true,
        reorder: false,
        grow: 0,
        expandOnRowClicked: true,
        minWidth: '150px',


      },
      {
        id: 'FACILITY NAME',
        name: 'FACILITY NAME',
        selector: row => row?.facility,
        cell: (row) =>

          <div>
            {row?.facility &&

              <div onClick={() => handleRowExpand(row)} className='d-flex flex-row justify-content-between align-items-center gap-2' style={{ maxWidth: '120px' }}>
                <div className='overflow-hidden text-truncate' style={{ maxWidth: "120px" }} >
                  <Tooltip disableInteractive title={row?.facility} placement="top" arrow>
                    <span className="text-truncate" > {row?.facility}</span>
                  </Tooltip>
                </div>
              </div>

            }
          </div>
        ,


        left: true,
        wrap: true,
        reorder: false,
        grow: 0,
        expandOnRowClicked: true,
        minWidth: '150px',



      },
      {
        id: 'PAYMENT835',
        name: 'PAYMENT835',
        selector: row => row?.no_of_835,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.no_of_835}</span>,

        right: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        // minWidth: '150px',


      },

      {
        id: 'PAYMENT LITE',
        name: 'PAYMENT LITE',
        selector: row => row?.no_835_lite,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.no_835_lite}</span>,

        right: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        // minWidth: '150px',


      },

      {
        id: 'CORRES',
        name: 'CORRES',
        selector: row => row?.correspondence,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.correspondence}</span>,

        right: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        // minWidth: '150px',

      },

      {
        id: 'PAT PAY',
        name: 'PAT PAY',
        selector: row => row?.patient_pay,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.patient_pay}</span>,

        right: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        // minWidth: '150px',

      },

      {
        id: 'PAT PAY 835',
        name: 'PAT PAY 835',
        selector: row => row?.patient_pay_835,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.patient_pay_835}</span>,

        right: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        // minWidth: '150px',
      },


      {
        id: 'CORRES 835',
        name: 'CORRES 835',
        selector: row => row?.correspondence_835,
        cell: (row) => <span onClick={() => handleRowClick(row)} > {row?.correspondence_835}</span>,

        right: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        // minWidth: '150px',
      },



      {
        id: 'MANUAL SPLIT',
        name: 'MANUAL SPLIT',
        selector: row => row?.file_split,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.file_split}</span>,

        right: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        // minWidth: '150px',
      },

      {
        id: 'QUALITY',
        name: 'QUALITY %',
        selector: row => row?.quality,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.quality}</span>,

        right: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        // minWidth: '150px',

      },


      {
        id: 'JOB TOTAL',
        name: 'JOB TOTAL',
        selector: row => row?.total,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.total}</span>,

        right: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        // minWidth: '150px',


      },

    ],

    [pageno, limit, handleRowExpand, handleRowClick],
  );

  const conditionalRowStyles = [

    {
      when: row => colorRow.id == row.id ? true : false,
      style: (row, index) => ({
        backgroundColor: colorRow?.id == row.id ? '#dbf4f5' : '#f2f2f2',
        color: 'black',
        // fontWeight: '550'
      }),
    },


  ];


  const [datetime, setDateTime] = useState([]);

  const handleOnDateTimeChange = ({ name, value }) => {
    setExpandedRows([])
    let created_date = ""
    if (value !== null) {
      const startDate = value[0];
      const endDate = value[1];
      // Check if the start date is after the end date, and swap the dates if needed
      let isGreater = false
      if (startDate > endDate) {
        isGreater = true
      }
      const datefrom = moment(isGreater ? endDate : startDate).format('MM/DD/YYYY')
      const dateto = moment(isGreater ? startDate : endDate).format('MM/DD/YYYY')

      created_date = datefrom + " - " + dateto
    } else {
      setSearchedData({
        ...searchedData,
        created_date: "",
        date_range: "",
      })
      fetchData(1, limit, searchedData?.search, selectValue, "", searchedData?.hour_count, searchedData?.clients ?? "", searchedData?.facilities ?? "");

    }

    setDateTime(created_date)

    searchFormik.setValues({ ...searchFormik.values, created_date, date_range: value ?? "" })


  }

  const searchData = ({ station, job_status, created_date, hour_count, clients, facilities }) => {
    const client_ids = selectValue?.map(i => i.value)
    const facility_ids = selectedFacility?.map(i => i.value)
    setSearchedData({
      job_status: job_status ?? "",
      date_range: created_date ?? "",
      hour_count: hour_count ?? "",
      clients: client_ids,
      facilities: facility_ids,
      station: selectedStation?.value
    })
    fetchData(1, limit, selectedStation?.value, '', created_date, hour_count, client_ids, facility_ids)
  }

  const handleResetDataTable = () => {
    setRerenderDataTable(true)
    // setTimeout(() => {
    //   setRerenderDataTable(false)
    // }, 200);
  }
  const [loader, setLoader] = useState(false);

  const handleDownload = (type = 'summury') => {
    setLoader(true)
    if (type === 'summury') {
      downloadClientReportSummery(1, limit, searchedData?.station, selectValue, searchedData.date_range, searchedData?.hour_count, searchedData?.clients, searchedData?.facilities).then((response) => {
        setLoader(false)
        downloadXl(response?.data?.export_file)
      });

    } else {
      downloadClientReportSingle(1, limit, searchedData?.station, selectValue, searchedData.date_range, searchedData?.hour_count, searchedData?.clients, searchedData?.facilities).then((response) => {
        setLoader(false)
        downloadXl(response?.data?.export_file)
      });
    }
  }

  useEffect(() => {
    fetchData(1, limit, searchFormik?.values?.search, selectValue, searchedData.date_range, searchFormik?.values?.hour_count, "", "");
    getAllClientDropdown().then((response) => {
      if (response?.status) {
        const data = response?.data
        getFacilityDropdown(data.map((i) => i.value)).then((response) => {
          if (response && response.status) {
            const feciltyIdArr = []
            const FullFacilities = []
            setFacilities(response.data.facilities?.map(result => {
              result?.facilities?.map(item => feciltyIdArr.push(item.value));
              FullFacilities.push({ options: result?.facilities, label: result?.client, id: result?.pk })
              return { options: result?.facilities, label: result?.client, id: result?.pk }

            }))

            setFullFacilities(FullFacilities)
            const newArray = selectedFacility.filter(item => feciltyIdArr.includes(item.value));
            setSelectedFacility(newArray)
          }
        });

        setClients(data)
      }
    })
  }, [])

  // useEffect(() => {
  //   if (selectValue && selectValue.length > 0) {

  //     getFacilityDropdown(selectValue?.map((value) => value?.value)).then((response) => {
  //       if (response && response.status) {
  //         const feciltyIdArr = []
  //         setFacilities(response.data.facilities?.map(result => {
  //           console.log(result?.facilities, 'EEEEE')
  //           result?.facilities?.map(item => feciltyIdArr.push(item.value));
  //           return { options: result?.facilities, label: result?.client }

  //         }))
  //         const newArray = selectedFacility.filter(item => feciltyIdArr.includes(item.value));
  //         setSelectedFacility(newArray)
  //         searchFormik.setValues({
  //           ...searchFormik.values, facilities: newArray.length > 0 ? newArray.map(i => i.value) : []
  //         })
  //       }
  //     });

  //   }

  // }, [selectValue])
  const setFacilityOptions = (clientId) => {
    getFacilityDropdown(clientId).then((response) => {
      if (response && response.status) {
        setFacilities(response.data.facilities?.map(result => {
          return { options: result?.facilities, label: result?.client }

        }))

      }
    });
  }
  const [fullFacilities, setFullFacilities] = useState([])

  const checkSelectedFecilityInitialClient = (selectedOptions) => {
    // Initialize an empty result array and a set to track unique labels
    var result = [...selectValue];
    var uniqueLabels = new Set();
    const clientId = selectValue?.map((value) => String(value?.value))

    // Loop through selected options
    for (var i = 0; i < selectedOptions.length; i++) {
      var selectedOption = selectedOptions[i];

      // Loop through the list of dictionaries to find matching options
      for (var j = 0; j < fullFacilities.length; j++) {
        var options = fullFacilities[j].options;
        for (var k = 0; k < options.length; k++) {
          if (options[k].value === selectedOption.value && options[k].label === selectedOption.label) {
            // Check if the label is unique before adding it to the result
            console.log(clientId, fullFacilities[j].id)
            if (!uniqueLabels.has(fullFacilities[j].label) && !clientId.includes(String(fullFacilities[j].id))) {
              result.push({
                "value": Number(fullFacilities[j].id),
                "label": fullFacilities[j].label,
              });
              uniqueLabels.add(fullFacilities[j].label);
            }
            break;
          }
        }
      }
    }
    console.log("Result:", result);
    return result
  }
  // const handleSelectChange = (val, name = null) => {
  //   console.log(val)
  //   let selectedOptions = [];
  //   let selectedIds = [];

  //   if (val && val?.length > 0) {

  //     val?.map((option) => {
  //       selectedIds.push(option.value)
  //       selectedOptions.push(option)
  //     })

  //   } else {

  //     setSelectedFacility([])
  //     setSelectedFacilityIds([])
  //   }

  //   if (name == "facility") {
  //     const initiaClientsOptions = checkSelectedFecilityInitialClient(selectedOptions)
  //     // const clientIds = initiaClientsOptions?.map((value) => value?.value)
  //     setSelectedFacility(selectedOptions)
  //     setSelectedFacilityIds(selectedIds)
  //     setSelectValue(initiaClientsOptions)
  //     // setFacilityOptions(clientIds)
  //   } else {
  //     // setClients(val.includes('all') ? ['all'] : selectedId)
  //     if (!(val && val?.length > 0)) {
  //       if (clients && clients.length > 0) {
  //         const clientId = clients?.map((value) => value?.value)
  //         setFacilityOptions(clientId)
  //       }

  //     } else {
  //       getFacilityDropdown(selectedIds).then((response) => {
  //         if (response && response.status) {
  //           const feciltyIdArr = []
  //           setFacilities(response.data.facilities?.map(result => {
  //             console.log(result?.facilities, 'EEEEE')
  //             result?.facilities?.map(item => feciltyIdArr.push(item.value));
  //             return { options: result?.facilities, label: result?.client }

  //           }))

  //           const newArray = selectedFacility.filter(item => feciltyIdArr.includes(item.value));
  //           setSelectedFacility(newArray)
  //         }
  //       });

  //     }

  //     setSelectValue(selectedOptions)
  //     setSelectIds(selectedIds)
  //     // setSelectedFacility([])
  //   }
  // };

  const handleSelectChange = (val, name = null) => {
    let selectedOptions = [];
    let selectedIds = [];
    if (val && val?.length > 0) {

      val?.map((option) => {
        selectedIds.push(option.value)
        selectedOptions.push(option)
      })
    }
    if (name == 'facility') {
      setSelectedFacility(selectedOptions)
      setSelectedFacilityIds(selectedIds)
    }
    else if (name == 'client') {
      setSelectedFacility([])
      setFacilities([])
      setSelectValue(selectedOptions)
      setSelectIds(selectedIds)
      const feciltyIdArr = []
      console.log(clients)
      let clientoptions = []
      clients?.map(client => {
        clientoptions.push(client?.value)
      })
      console.log('selectedIds', selectedIds)
      getFacilityDropdown(selectedIds?.length ? selectedIds : clientoptions).then((response) => {
        if (response && response.status) {
          setFacilities(response.data.facilities?.map(result => {
            result?.facilities?.map(item => feciltyIdArr.push(item.value));
            return { options: result?.facilities, label: result?.client }

          }))
          const newArray = selectedFacility.filter(item => feciltyIdArr.includes(item.value));
          setSelectedFacility(selectedIds?.length ? newArray : [])
        }
      });
    }
  }
  const DropdownIndicator = ({ state, ...props }) => {
    console.log(state?.length, state)
    return (
      <components.DropdownIndicator {...props}>

        {state?.length > 1 ? (
          <TbGridDots style={{ fontSize: "1.5em" }} />
        ) : (
          <HiChevronDown style={{ fontSize: "1.5em" }} />
        )}



      </components.DropdownIndicator>
    );
  };
  const hour_count_option = [
    { value: "true", label: "Count" },
    { value: "false", label: "Time" },
  ]

  const handleStationChange = (selected, name) => {
    setSelectedStation(selected)
  }
  return (
    <React.Fragment>
      <Sidebar />
      <main id="main" className="main">

        <div className="pagetitle tab-fixed-header">
          <h1 id='page-title'>Client Report</h1>
        </div>

        <section className="section mt-5 ">
          <div className="row align-items-top py-2 gap-2 gap-md-5">
            <div className="col-lg-12">
              <div className="card mb-xl-8 " style={{ height: "92vh" }}>

                <Form onSubmit={searchFormik.handleSubmit} >
                  <div className=" ">
                    <div className="px-1 py-1 d-flex flex-row justify-content-start gap-2 align-items-center flex-wrap">
                      <div>
                        <Select
                          ref={clientRef}

                          isMulti
                          options={clients}
                          value={selectValue}
                          className=""
                          classNamePrefix="select"
                          placeholder="Client"
                          menuPlacement="auto"
                          maxMenuHeight={300}
                          components={{ DropdownIndicator: (props) => <DropdownIndicator {...props} state={selectValue} /> }}
                          hideSelectedOptions={false}
                          isClearable
                          name="client"
                          onChange={(val) => handleSelectChange(val, 'client')}
                          tabSelectsValue={false}

                          onFocus={() => setIsFocusedClient(true)}
                          onBlur={() => setIsFocusedClient(false)}
                          styles={{
                            container: (styles, state) => ({
                              ...styles,
                              borderRadius: "9px",
                            }),
                            control: (styles, state) => ({
                              ...styles,
                              backgroundColor: "hsl(204deg 33.33% 97.06%)",
                              borderStyle: "none", boxShadow: "none",
                              borderRadius: "10px",
                              maxHeight: "30px",
                            }),
                            indicatorSeparator: styles => ({
                              ...styles, width: "0px",
                              minHeight: "20px",
                              maxHeight: "30px",
                            }),

                            valueContainer: (styles, state) => ({
                              ...styles,
                              maxHeight: "30px",
                              overflow: 'scroll',
                            }),
                            option: (styles, state) => ({
                              ...styles,
                              backgroundColor: state.isSelected ? '#d9f4f5' : 'inherit',
                              color: state.isSelected ? '#000000' : 'inherit',
                              '&:hover': { backgroundColor: state.isSelected ? '#d9f4f5' : 'rgb(222, 235, 255)' }

                            })
                          }}
                          menuPortalTarget={document.body}
                          closeMenuOnSelect={false} // Keep the menu open after selecting an option

                        />
                      </div>
                      <div>
                        <Select
                          ref={facilityRef}
                          options={facilities}
                          className=""
                          classNamePrefix="select"
                          placeholder="Facility"
                          menuPlacement="auto"
                          maxMenuHeight={300}
                          isClearable
                          isMulti
                          name="facility"
                          value={selectedFacility}
                          components={{ DropdownIndicator: (props) => <DropdownIndicator {...props} state={selectedFacility} /> }}
                          hideSelectedOptions={false}
                          tabSelectsValue={false}

                          onChange={(val) => handleSelectChange(val, 'facility')}
                          onFocus={() => setIsFocusedFacility(true)}
                          onBlur={() => setIsFocusedFacility(false)}
                          // styles={{
                          //     container: (styles, state) => ({
                          //         ...styles,
                          //         borderRadius: "15px",
                          //     }),
                          //     control: (styles, state) => ({
                          //         ...styles,
                          //         backgroundColor: "#fff",
                          //         borderStyle: "none", boxShadow: "none",
                          //         height: "26px",
                          //         minHeight: "0px",
                          //         borderRadius: "7px",
                          //         maxHeight: state.isFocused ? "auto" : "30px",
                          //     }),
                          //     indicatorsContainer: styles => ({
                          //         ...styles,
                          //         height: "28px",
                          //     }),
                          //     indicatorSeparator: styles => ({
                          //         ...styles, width: "0px", minHeight: "20px"
                          //     }),
                          //     valueContainer: (styles, state) => ({
                          //         ...styles,
                          //         marginBottom: "89px",
                          //         maxHeight: "30px",
                          //     }),
                          //     option: (styles, { isFocused }) => ({
                          //         ...styles,
                          //         padding: "6px 12px", // Adjust the padding as per your preference
                          //         fontSize: '13px'
                          //     }),
                          // }}
                          styles={{
                            container: (styles, state) => ({
                              ...styles,
                              borderRadius: "9px",
                            }),
                            control: (styles, state) => ({
                              ...styles,
                              backgroundColor: "hsl(204deg 33.33% 97.06%)",
                              borderStyle: "none", boxShadow: "none",
                              borderRadius: "10px",
                              maxHeight: "30px",
                            }),
                            indicatorSeparator: styles => ({
                              ...styles, width: "0px",
                              minHeight: "20px",
                              maxHeight: "30px",
                            }),

                            valueContainer: (styles, state) => ({
                              ...styles,
                              maxHeight: "30px",
                              overflow: 'scroll',
                            }),
                            option: (styles, state) => ({
                              ...styles,
                              backgroundColor: state.isSelected ? '#d9f4f5' : 'inherit',
                              color: state.isSelected ? '#000000' : 'inherit',
                              '&:hover': { backgroundColor: state.isSelected ? '#d9f4f5' : 'rgb(222, 235, 255)' }

                            })
                          }}
                          menuPortalTarget={document.body}
                          closeMenuOnSelect={false} // Keep the menu open after selecting an option

                        />
                      </div>
                      <div className='small-select-container'>
                        <SingleSelect formik={searchFormik} placeholder="Time/Count" name="hour_count" list={hour_count_option} />

                      </div>
                      <div className=''>

                        <Select
                          options={station_option}
                          className="select-search"
                          classNamePrefix="select"
                          //components={{ DropdownIndicator: (props) => <DropdownIndicator {...props} state="status" /> }}
                          placeholder="Station"
                          isClearable
                          name="station"
                          //onFocus={() => setIsFocusedStatus(true)}
                          //onBlur={() => setIsFocusedStatus(false)}
                          onChange={(e) => handleStationChange(e, 'station')}
                          defaultValue={selectedStation}
                          styles={{
                            container: (styles, state) => ({
                              ...styles,
                              borderRadius: "9px",
                            }),
                            control: (styles, state) => ({
                              ...styles,
                              backgroundColor: "hsl(204deg 33.33% 97.06%)",
                              borderStyle: "none", boxShadow: "none",
                              borderRadius: "10px",
                              maxHeight: state.isFocused ? "auto" : "30px",
                            }),
                            indicatorSeparator: styles => ({
                              ...styles, width: "0px", minHeight: "20px"
                            }),

                            // valueContainer: (styles, state) => ({
                            //     ...styles,
                            //     maxHeight: isFocusedStatus ? "auto" : "30px",
                            // }),
                          }}
                        />
                      </div>
                      <Tooltip disableInteractive title={"Processed Date"} placement="top" arrow>
                        <div className="correspondenceDatePicker">
                          <Stack direction="column" spacing={8} alignItems="flex-start">

                            <DateRangePicker
                              ranges={predefinedBottomRanges}
                              cleanable={false}
                              placement="auto"
                              appearance="default" format={"MM/dd/yyyy"}
                              className="form-date-control form-control-solid b-r-1 text-dark"
                              placeholder={"MM/DD/YYYY - MM/DD/YYYY"}
                              name="date_range"
                              onChange={(e) => { handleOnDateTimeChange({ name: 'date_range', value: e }) }} value={searchFormik?.values?.date_range} />
                          </Stack>
                        </div>
                      </Tooltip>
                      <div>
                        <Tooltip disableInteractive title={"Search"} placement="top" arrow>
                          <Button className="btn btn-light btn-active-primary" type="submit">
                            <span className="svg-icon svg-icon-3">
                              <BiSearch />
                            </span>

                          </Button>
                        </Tooltip>
                      </div>
                      {/* {isHavePermission("export_client_report_management") &&
                        <div>
                          <Tooltip disableInteractive title={"Download"} placement="top" arrow>
                            {!loader ?
                              <Button onClick={() => { handleDownload() }} className="btn btn-light btn-active-primary" >
                                <span className="svg-icon svg-icon-3">
                                  <BsCloudDownloadFill />
                                </span>

                              </Button> :
                              <Button className="btn btn-light btn-active-primary" >
                                <span className="svg-icon svg-icon-3">
                                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                </span>

                              </Button>}
                          </Tooltip>

                        </div>

                      } */}
                      {isHavePermission("export_client_report_management") &&
                        <DownloadDropDown loader={loader} handleDownload={handleDownload} />

                      }
                    </div>
                  </div>

                </Form>


                <div className="card-body p-common">
                  <div className="table-responsive">
                    <div className="dashboardtable">
                      {/* {!reRenderDataTable && */}
                      <DataTable
                        className="custom-datatable"
                        paginationDefaultPage={pageno}

                        fixedHeader={true}
                        progressPending={loading}
                        data={data?.results}
                        columns={searchedData.hour_count === "false" ? timeColumns : searchedData.hour_count === "true" ? countColumns : columns}
                        pointerOnHover={true}
                        pagination
                        paginationServer
                        onRowClicked={handleRowClick}
                        // highlightOnHover
                        paginationTotalRows={totalRows}
                        paginationPerPage={limit}
                        paginationRowsPerPageOptions={[25, 50, 100, 150, 200]}
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                        conditionalRowStyles={conditionalRowStyles}
                        theme="solarized"
                        customStyles={datatableCustomStyles}
                        striped={true}

                        expandableRows
                        expandOnRowClicked={false}
                        onRowExpandToggled={handleRowExpand}
                        expandableRowsComponent={ExpandedComponent}
                        expandableRowsHideExpander
                        expandableRowExpanded={(row) => expandedRows.includes(row)}

                      />
                      {/* } */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>



      </main>
    </React.Fragment>
  )
}
export default ClientReport




