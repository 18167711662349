import React, { useRef, useState, useEffect } from "react";
import { ScrollMode, SpecialZoomLevel, Viewer, Worker } from "@react-pdf-viewer/core";
import Modal from "react-modal";
import { Overlay, Popover, Form } from "react-bootstrap";
import { FormikProvider } from "formik";

import Select, { components, DropdownIndicatorProps } from 'react-select';
import { TbGridDots, TbHandGrab } from 'react-icons/tb';
import { HiChevronDown } from 'react-icons/hi';
import { EDIT_WORK, QA_REVIEW } from "../../../../../functions";
import { DataListService } from "../../../../../services/DataListService";
import { Tooltip } from '@mui/material';

const AdditionalInfo = ({ formik, isAdditionalInfoOptions, setIsIncomplete, log_id, aditionalInfoRef, job_id, additionalModal, setAdditionalModal, setIsDrag, formEob, eobLiteFormik, formPP, formCorres, handleAdditionalCloseButton, jobData, handleAdditionalInfoChange, isViewOnllyGrid, isFirstJob }) => {
    const dataListService = new DataListService();
    const { getReasonsDropdown, getDocumentsDropdown } = dataListService
    const DropdownIndicator = ({ state, ...props }) => {
        return (
            <components.DropdownIndicator {...props}>

                {filterState && filterState[state] && filterState[state].length > 1 ? (
                    <TbGridDots style={{ fontSize: "1.5em" }} />
                ) : (
                    <HiChevronDown style={{ fontSize: "1.5em" }} />
                )}



            </components.DropdownIndicator>
        );
    };
    const documnetRef = useRef(null)
    const statusRef = useRef(null)
    const reasonRef = useRef(null)

    const [isFocusedReason, setIsFocusedReason] = useState(false);
    const [filterState, setFilterState] = useState([{
        reasons: [],
    }])
    const pathname = window.location.pathname;
    const additionalInfoCheckActive = true;
    const [reasonData, setReasonData] = useState([])
    const [documentData, setDocumentData] = useState([])
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        getReasonsDropdown(jobData?.client_id).then((response) => {
            if (response?.status && response?.status_code == 200) {
                setReasonData(response?.data?.status_data)
                setLoading(false)
            }
        })

        getDocumentsDropdown(jobData?.client_id).then((response) => {
            if (response?.status && response?.status_code == 200) {
                setDocumentData(response?.data?.results)
                setLoading(false)
            }
        })
    }, [])
    const handleSwitchChange = (e) => {
        formik.setValues({
            ...formik.values,
            [e.target.name]: e.target.checked
        })
    }
    const handleIsIncomplete = (e) => {
        if (e.target.value == "INCOMPLETE") {
            setIsIncomplete(true)
        }
        else {
            setIsIncomplete(false)

            if (jobData?.grid_data?.transaction_type == "PAYMENT835") {
                formEob.validateForm()
            }
            else if (jobData?.grid_data?.transaction_type == "PAYMENT835_LITE") {
                eobLiteFormik.validateForm()
            }
            else if (jobData?.grid_data?.transaction_type == "PATIENT_PAY") {
                formPP.validateForm()
            }
            else if (jobData?.grid_data?.transaction_type == "CORRESPONDENCE") {
                formCorres.validateForm()
            }

        }
    }
    const handleKeyDown = (e, name) => {
        console.log(e)
        if (e.shiftKey === true && e.key === 'Tab') {
            e.preventDefault(); // Prevent the tab key from changing the selection
            if (name === 'reason') {
                statusRef.current.focus()
            } else if (name === 'document') {
                reasonRef.current.focus()
            }
        }
        else if (e.key === 'Tab') {
            e.preventDefault(); // Prevent the tab key from changing the selection
            if (name === 'reason') {
                documnetRef.current.focus();
            } else if (name == 'status') {
                reasonRef.current.focus();

            }
        }
    }
    const reasonsLabels = formik?.values?.reason?.length > 0 ? formik?.values?.reason?.map((i) => i.label) : ''
    const concatenatedString = reasonsLabels.length > 0 ? reasonsLabels.join(', ') : '';
    const renderClass = () => {
        if (pathname == QA_REVIEW || pathname == EDIT_WORK + job_id || pathname == EDIT_WORK + job_id + "?log_id=" + log_id) {
            if (jobData?.grid_data?.qa_corrected_data?.qdx_reasons) {
                return '#ecf38f'
            }
            else {
                const array1 = jobData?.grid_data?.qdx_reasons ? jobData?.grid_data?.qdx_reasons : [];
                const array2 = formik.values?.reason ? formik.values?.reason : [];
                const array1sort = array1.sort()
                const array2sort = array2.sort()
                if (JSON.stringify(array1sort) != JSON.stringify(array2sort)) {
                    return '#ecf38f'
                }
            }
        }


    }
    return (
        <React.Fragment>

            <Overlay
                // rootClose={true}
                onHide={() => { setAdditionalModal(false); setIsDrag(true); }}
                show={additionalModal}
                target={aditionalInfoRef.current}
                containerPadding={80} placement="top"
            >
                {
                    (props) => (
                        <Popover className='pc-popover-popup' id="pc-popover-popup"  {...props}>
                            <Popover.Header className='p-1 ' style={{ fontSize: '1em', backgroundColor: "#6ba2b6" }} >
                                <div className='d-flex flex-row justify-content-between align-items-center'>
                                    <span className='text-white fw-bold'> Additional Information </span>
                                    <button className='btn text-white fw-bold' onClick={() => { setAdditionalModal(false); setIsDrag(true); handleAdditionalCloseButton() }}>&times;</button>
                                </div>
                            </Popover.Header>

                            <Popover.Body className="">
                                <FormikProvider value={formik}>
                                    <Form
                                        onSubmit={formik.handleSubmit}
                                    >
                                        <div className='row  align-items-center'>

                                            <React.Fragment>
                                                {isAdditionalInfoOptions?.status &&
                                                    <div className="mb-10 col-lg-4">

                                                        <Form.Select
                                                            style={{ fontSize: '15px' }}
                                                            ref={statusRef}
                                                            onKeyDown={(e) => { handleKeyDown(e, 'status') }}
                                                            disabled
                                                            name="status"
                                                            className={
                                                                (pathname == QA_REVIEW || pathname == EDIT_WORK + job_id || pathname == EDIT_WORK + job_id + "?log_id=" + log_id) &&
                                                                    jobData?.grid_data?.qa_corrected_data?.qdx_status ?
                                                                    'form-control form-control-solid b-r-10 qa-field' :
                                                                    (pathname == QA_REVIEW || pathname == EDIT_WORK + job_id || pathname == EDIT_WORK + job_id + "?log_id=" + log_id) &&
                                                                        jobData?.grid_data?.qdx_status != formik.values?.status ?
                                                                        'form-control form-control-solid b-r-10 qa-field' :
                                                                        'form-control form-control-solid b-r-10'}
                                                            value={formik.values?.status}
                                                            // isValid={formik.touched?.status && !formik?.errors?.status}
                                                            isInvalid={!!formik.errors?.status}
                                                            placeholder="select an option"
                                                            onChange={(e) => { formik.handleChange(e); handleIsIncomplete(e) }}
                                                        >
                                                            <option value="">Status</option>
                                                            <option value="COMPLETE">Complete</option>
                                                            <option value="INCOMPLETE">Incomplete</option>
                                                        </Form.Select>
                                                        <span className="text-danger">
                                                            {formik?.errors?.status}
                                                        </span>

                                                    </div>
                                                }
                                                {isAdditionalInfoOptions?.reason &&
                                                    <Tooltip title={concatenatedString} placement='top' arrow>

                                                        <div className="mb-10 col-lg-4">


                                                            <Select
                                                                ref={reasonRef}
                                                                menuPlacement={'top'} isMulti
                                                                closeMenuOnSelect={false} // Keep the menu open after selecting an option
                                                                isDisabled={true}
                                                                options={reasonData}
                                                                className="select-search"
                                                                components={{ DropdownIndicator: (props) => <DropdownIndicator {...props} state="reason" /> }}
                                                                classNamePrefix="select"
                                                                placeholder="Reasons"
                                                                isClearable
                                                                name="reason"
                                                                onFocus={() => setIsFocusedReason(true)}
                                                                onBlur={() => { setIsFocusedReason(false) }}
                                                                onKeyDown={(e) => { handleKeyDown(e, 'reason') }}
                                                                onChange={(e) => {
                                                                    formik.setValues({ ...formik.values, reason: e.length > 0 ? e : [] });

                                                                }}
                                                                value={formik?.values?.reason}
                                                                styles={{
                                                                    container: (styles, state) => ({
                                                                        ...styles,
                                                                        borderRadius: "9px",
                                                                    }),
                                                                    control: (styles, state) => ({
                                                                        ...styles,
                                                                        backgroundColor: renderClass(),
                                                                        borderStyle: "none", boxShadow: "none",
                                                                        borderRadius: "10px",
                                                                        maxHeight: state.isFocused ? "auto" : "30px",
                                                                    }),
                                                                    indicatorSeparator: styles => ({
                                                                        ...styles, width: "0px",
                                                                        minHeight: "20px"
                                                                    }),
                                                                    placeholder: (styles) => ({
                                                                        ...styles,
                                                                        color: 'black', // Change the color to your desired color
                                                                        fontSize: '15px'

                                                                    }),
                                                                    valueContainer: (styles, state) => ({
                                                                        ...styles,
                                                                        maxHeight: isFocusedReason ? "auto" : "30px",
                                                                    }),
                                                                }} />
                                                            {formik.errors && formik.errors?.reason &&
                                                                <span className='text-danger'>  {formik?.errors?.reason}</span>
                                                            }


                                                        </div>
                                                    </Tooltip>

                                                }
                                                {isAdditionalInfoOptions?.document_classification &&

                                                    <div className="mb-10 col-lg-4">

                                                        <Form.Select
                                                            onKeyDown={(e) => { handleKeyDown(e, 'document') }}
                                                            style={{ fontSize: '15px' }}

                                                            ref={documnetRef}
                                                            name="document_classification"
                                                            className={
                                                                (pathname == QA_REVIEW || pathname == EDIT_WORK + job_id || pathname == EDIT_WORK + job_id + "?log_id=" + log_id) &&
                                                                    jobData?.grid_data?.qa_corrected_data?.qdx_classification ?
                                                                    'form-control form-control-solid b-r-10 qa-field' :
                                                                    (pathname == QA_REVIEW || pathname == EDIT_WORK + job_id || pathname == EDIT_WORK + job_id + "?log_id=" + log_id) &&
                                                                        jobData?.grid_data?.qdx_classification != formik.values?.document_classification ?
                                                                        'form-control form-control-solid b-r-10 qa-field' :
                                                                        'form-control form-control-solid b-r-10'}
                                                            value={formik.values?.document_classification}
                                                            disabled={true}
                                                            // isValid={formik.touched?.document_classification && !formik?.errors?.document_classification}
                                                            // isInvalid={!!formik.errors?.document_classification}
                                                            placeholder="select an option"
                                                            onChange={formik.handleChange}
                                                        >
                                                            <option value=''>Document Classification</option>
                                                            {documentData && documentData?.length > 0 && documentData?.map((option, index) => (
                                                                <option value={option?.value}>{option?.label}</option>
                                                            ))}
                                                        </Form.Select>

                                                    </div>
                                                }

                                                {isAdditionalInfoOptions?.extra_info &&
                                                    <div className="d-grid-additional-info mt-3 mb-2">
                                                        <div
                                                            className={
                                                                (pathname == QA_REVIEW || pathname == EDIT_WORK + job_id || pathname == EDIT_WORK + job_id + "?log_id=" + log_id) &&
                                                                    jobData?.grid_data?.qa_corrected_data?.is_orphaned_check ?
                                                                    'additional-check qa-field' :
                                                                    (pathname == QA_REVIEW || pathname == EDIT_WORK + job_id || pathname == EDIT_WORK + job_id + "?log_id=" + log_id) &&
                                                                        jobData?.grid_data?.is_orphaned_check != formik.values?.is_orphaned_check ?
                                                                        'additional-check qa-field' :
                                                                        'additional-check'}
                                                        >
                                                            <Form.Check
                                                                as="input"
                                                                inline

                                                                type="switch"
                                                                reverse={true}
                                                                onChange={handleSwitchChange}
                                                                checked={formik?.values?.is_orphaned_check}
                                                                isInvalid={
                                                                    formik.errors?.is_orphaned_check &&
                                                                    !!formik.errors?.is_orphaned_check
                                                                }
                                                                feedbackType="invalid"
                                                                feedback={
                                                                    formik.errors?.is_orphaned_check &&
                                                                    formik.errors?.is_orphaned_check
                                                                }
                                                                disabled={additionalInfoCheckActive}
                                                                id="is_orphaned_check"
                                                                name="is_orphaned_check"
                                                                label="Orphaned Check"
                                                            />
                                                        </div>
                                                        <div
                                                            className={
                                                                (pathname == QA_REVIEW || pathname == EDIT_WORK + job_id || pathname == EDIT_WORK + job_id + "?log_id=" + log_id) &&
                                                                    jobData?.grid_data?.qa_corrected_data?.is_missing_check_image ?
                                                                    'additional-check qa-field' :
                                                                    (pathname == QA_REVIEW || pathname == EDIT_WORK + job_id || pathname == EDIT_WORK + job_id + "?log_id=" + log_id) &&
                                                                        jobData?.grid_data?.is_missing_check_image != formik.values?.is_missing_check_image ?
                                                                        'additional-check qa-field' :
                                                                        'additional-check'}
                                                        >

                                                            <Form.Check
                                                                disabled={additionalInfoCheckActive}
                                                                as="input"
                                                                inline
                                                                className=""
                                                                type="switch"
                                                                reverse={true}
                                                                onChange={handleSwitchChange}
                                                                checked={formik?.values?.is_missing_check_image}
                                                                isInvalid={
                                                                    formik.errors?.is_missing_check_image &&
                                                                    !!formik.errors?.is_missing_check_image
                                                                }
                                                                feedbackType="invalid"
                                                                feedback={
                                                                    formik.errors?.is_missing_check_image &&
                                                                    formik.errors?.is_missing_check_image
                                                                }
                                                                id="is_missing_check_image"
                                                                name="is_missing_check_image"
                                                                label="Missing Check Image"
                                                            />
                                                        </div>
                                                        <div
                                                            className={
                                                                (pathname == QA_REVIEW || pathname == EDIT_WORK + job_id || pathname == EDIT_WORK + job_id + "?log_id=" + log_id) &&
                                                                    jobData?.grid_data?.qa_corrected_data?.is_forced_balancing ?
                                                                    'additional-check qa-field' :
                                                                    (pathname == QA_REVIEW || pathname == EDIT_WORK + job_id || pathname == EDIT_WORK + job_id + "?log_id=" + log_id) &&
                                                                        jobData?.grid_data?.is_forced_balancing != formik.values?.is_forced_balancing ?
                                                                        'additional-check qa-field' :
                                                                        'additional-check'}
                                                        >

                                                            <Form.Check
                                                                disabled={additionalInfoCheckActive}
                                                                as="input"
                                                                inline
                                                                className=""
                                                                type="switch"
                                                                reverse={true}
                                                                onChange={handleSwitchChange}
                                                                checked={formik?.values?.is_forced_balancing}
                                                                isInvalid={
                                                                    formik.errors?.is_forced_balancing &&
                                                                    !!formik.errors?.is_forced_balancing
                                                                }
                                                                feedbackType="invalid"
                                                                feedback={
                                                                    formik.errors?.is_forced_balancing &&
                                                                    formik.errors?.is_forced_balancing
                                                                }
                                                                id="is_forced_balancing"
                                                                name="is_forced_balancing"
                                                                label="Forced Balancing"
                                                            />
                                                        </div>
                                                        <div
                                                            className={
                                                                (pathname == QA_REVIEW || pathname == EDIT_WORK + job_id || pathname == EDIT_WORK + job_id + "?log_id=" + log_id) &&
                                                                    jobData?.grid_data?.qa_corrected_data?.is_payer_black_listing ?
                                                                    'additional-check qa-field' :
                                                                    (pathname == QA_REVIEW || pathname == EDIT_WORK + job_id || pathname == EDIT_WORK + job_id + "?log_id=" + log_id) &&
                                                                        jobData?.grid_data?.is_payer_black_listing != formik.values?.is_payer_black_listing ?
                                                                        'additional-check qa-field' :
                                                                        'additional-check'}
                                                        >

                                                            <Form.Check
                                                                disabled={additionalInfoCheckActive}
                                                                as="input"
                                                                inline
                                                                className=""
                                                                type="switch"
                                                                reverse={true}
                                                                onChange={handleSwitchChange}
                                                                checked={formik?.values?.is_payer_black_listing}
                                                                isInvalid={
                                                                    formik.errors?.is_payer_black_listing &&
                                                                    !!formik.errors?.is_payer_black_listing
                                                                }
                                                                feedbackType="invalid"
                                                                feedback={
                                                                    formik.errors?.is_payer_black_listing &&
                                                                    formik.errors?.is_payer_black_listing
                                                                }
                                                                id="is_payer_black_listing"
                                                                name="is_payer_black_listing"
                                                                label="Payer Black Listing"
                                                            />
                                                        </div>
                                                        <div
                                                            className={
                                                                (pathname == QA_REVIEW || pathname == EDIT_WORK + job_id || pathname == EDIT_WORK + job_id + "?log_id=" + log_id) &&
                                                                    jobData?.grid_data?.qa_corrected_data?.is_payer_excluding_indexing ?
                                                                    'additional-check qa-field' :
                                                                    (pathname == QA_REVIEW || pathname == EDIT_WORK + job_id || pathname == EDIT_WORK + job_id + "?log_id=" + log_id) &&
                                                                        jobData?.grid_data?.is_payer_excluding_indexing != formik.values?.is_payer_excluding_indexing ?
                                                                        'additional-check qa-field' :
                                                                        'additional-check'}
                                                        >

                                                            <Form.Check
                                                                disabled={additionalInfoCheckActive}
                                                                as="input"
                                                                inline
                                                                className=""
                                                                type="switch"
                                                                reverse={true}
                                                                onChange={handleSwitchChange}
                                                                checked={formik?.values?.is_payer_excluding_indexing}
                                                                isInvalid={
                                                                    formik.errors?.is_payer_excluding_indexing &&
                                                                    !!formik.errors?.is_payer_excluding_indexing
                                                                }
                                                                feedbackType="invalid"
                                                                feedback={
                                                                    formik.errors?.is_payer_excluding_indexing &&
                                                                    formik.errors?.is_payer_excluding_indexing
                                                                }
                                                                id="is_payer_excluding_indexing"
                                                                name="is_payer_excluding_indexing"
                                                                label="Payer Excluded Indexing"
                                                            />
                                                        </div>

                                                    </div>
                                                }
                                                <div className='d-flex flex-row'>

                                                    <div className='col-lg-12 pt-2 text-end'>
                                                        <button
                                                            disabled={true}
                                                            // onClick={() => { setIsFocusedReason(false); formik.handleSubmit() }}
                                                            type='submit'
                                                            className='btn btn-light btn-active-primary new-window'
                                                        >
                                                            Ok
                                                        </button>
                                                    </div>
                                                </div>
                                            </React.Fragment>


                                            <React.Fragment>

                                                {jobData?.additional_info_difference?.has_differences && jobData?.additional_info_difference?.additional_info?.length > 1 &&

                                                    <React.Fragment>
                                                        <ul className="list-group list-group-horizontal width-full-additional pt-2">
                                                            <li className="list-group-item item-width-full py-1 fs-7 width-additional-status" >Status</li>
                                                            <li className="list-group-item item-width-full py-1 fs-7 width-additional-reason" >Reason</li>
                                                            <li className="list-group-item item-width-full py-1 fs-7 width-additional-classification"  >Document Classification</li>
                                                            <li className="list-group-item item-width-full py-1 fs-7 width-additional-page-range" >User & Page Range</li>

                                                        </ul>
                                                        <div className="  scroll-overflow-y p-0" style={{ width: '100%' }}>
                                                            {jobData?.additional_info_difference?.additional_info && jobData?.additional_info_difference?.additional_info.map((additional_info, index) => (
                                                                <React.Fragment>

                                                                    <ul className={"list-group list-group-horizontal  "} key={index}>
                                                                        <li className="list-group-item option-item-info p-1 m-0 fs-7" >
                                                                            {additional_info?.qdx_status}
                                                                        </li>
                                                                        <li className="list-group-item option-item-info p-1 m-0 fs-7" >
                                                                            {additional_info?.qdx_reason}

                                                                        </li>

                                                                        <li className="list-group-item option-item-info p-1 m-0 fs-7" >
                                                                            {additional_info?.qdx_classification}

                                                                        </li>
                                                                        <li className="list-group-item option-item-info p-1 m-0 fs-7" >
                                                                            {additional_info?.user_name} | {additional_info?.from_page}-{additional_info?.to_page}


                                                                        </li>

                                                                    </ul>
                                                                </React.Fragment>
                                                            ))}
                                                        </div>
                                                    </React.Fragment>

                                                }
                                            </React.Fragment>


                                        </div>




                                    </Form>
                                </FormikProvider>
                            </Popover.Body>
                        </Popover>
                    )
                }
            </Overlay >
        </React.Fragment >

    )
}
export default AdditionalInfo;




