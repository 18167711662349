import request from "../api/agent";

const API_ROUTE = "users";

export class UserApiService {
  users = [];

  async getAllUsers(data) {
    const params = `?page=${data?.page}&limit=${data?.limit}&search=${data.search ?? ""}`

    const response = await request.get(`${API_ROUTE}/get-users` + params);
    return response.data;
  }
  async getGroupsForUser() {
    const response = await request.get(`${API_ROUTE}/get-groups-for-user-creation`);
    return response.data;
  }

  async addOrUpdateUser(group) {
    console.log(group, "###############");
    let action = `${API_ROUTE}/create-or-update-user`;
    const response = await request.post(action, group);
    return response;
  }
  async changePasswordAdmin(arg) {
    let action = `${API_ROUTE}/change-password-by-admin`;
    const response = await request.post(action, arg);
    return response.data;
  }

  async getSingleUserDetails(user) {
    const params = `?page=${1}&user=${user}`

    const response = await request.get(`${API_ROUTE}/get-user-details` + params);
    return response.data;
  }

  async toggleUser(user) {
    const response = await request.put(`${API_ROUTE}/activate-or-deactivate-users`, {
      user,
    });
    return response.data;
  }
}