import request from "../api/agent";

const API_ROUTE = "reports";

export class FileReportService {

  async getFileReportSummery(page, limit, station, job_status, date_range, hour_count, clients, facilities) {
    const params = `?page=${page}&limit=${limit}&date_range=${date_range}&job_status=${job_status}&station=${station}&hour_count=${hour_count}&facilities=${facilities}&clients=${clients}`

    const response = await request.get(`${API_ROUTE}/get-file-report-summery` + params);
    return response.data ?? response?.response?.data;
  }

  async getFileReportDetails(page, limit, station, job_status, date_range, hour_count, user, day, year, week, monthList, clients, facilities, facility_id) {
    const params = `?page=${page}&limit=${limit}&date_range=${date_range}&job_status=${job_status}&station=${station}&hour_count=${hour_count}&user=${user}&day=${day}&year=${year}&week=${week}&month=${monthList}&facilities=${facilities}&clients=${clients}&facility_id=${facility_id}`

    // const response = await request.get(`${API_ROUTE}/get-file-report-details` + params);
    const response = await request.get(`${API_ROUTE}/get-file-report-proccessed-summery` + params);

    return response.data;
  }
  async getLoackBoxReportDetails(page, limit, station, job_status, date_range, hour_count, user, day, year, week, month, filtering_date, clients, facilities, facility_id) {

    let params = `?page=${page}&limit=${limit}&date_range=${date_range}&job_status=${job_status}&station=${station}&user=${user}&day=${day}&year=${year}&week=${week}&month=${month}&facilities=${facilities}&clients=${clients}&facility_id=${facility_id}`
    if (day) {
      params = `?page=${page}&limit=${limit}&date_range=${date_range}&job_status=${job_status}&station=${station}&user=${user}&day=${day}&year=${year}&week=${week}&month=${month}&processed_date=${filtering_date}&facilities=${facilities}&clients=${clients}&facility_id=${facility_id}`
    }
    else if (week) {
      params = `?page=${page}&limit=${limit}&date_range=${date_range}&job_status=${job_status}&station=${station}&user=${user}&day=${day}&year=${year}&week=${week}&month=${month}&processed_date=${filtering_date}&facilities=${facilities}&clients=${clients}&facility_id=${facility_id}`
    }
    else if (month) {
      params = `?page=${page}&limit=${limit}&date_range=${date_range}&job_status=${job_status}&station=${station}&user=${user}&day=${day}&year=${year}&week=${week}&month=${month}&processed_date=${filtering_date}&facilities=${facilities}&clients=${clients}&facility_id=${facility_id}`
    }
    else if (year) {
      params = `?page=${page}&limit=${limit}&date_range=${date_range}&job_status=${job_status}&station=${station}&user=${user}&day=${day}&year=${year}&week=${week}&month=${month}&processed_date=${filtering_date}&facilities=${facilities}&clients=${clients}&facility_id=${facility_id}`
    }
    // const response = await request.get(`${API_ROUTE}/get-lock-box-report-details` + params);
    const response = await request.get(`${API_ROUTE}/get-file-report-proccessed-details` + params);

    return response.data;

  }
  async downloadFileReportSummery(page, limit, station, job_status, date_range, hour_count, clients, facilities) {
    const params = `?page=${page}&limit=${limit}&date_range=${date_range}&job_status=${job_status}&station=${station}&hour_count=${hour_count}&facilities=${facilities}&clients=${clients}`

    const response = await request.get(`${API_ROUTE}/file-report-download` + params);
    return response.data;
  }
  async downloadFileReportSingle(page, limit, station, job_status, date_range, hour_count, clients, facilities) {
    const params = `?page=${page}&limit=${limit}&date_range=${date_range}&job_status=${job_status}&station=${station}&hour_count=${hour_count}&facilities=${facilities}&clients=${clients}`

    const response = await request.get(`${API_ROUTE}/file-report-single-download` + params);
    return response.data;
  }

  async downloadFileReportDetails(page, limit, station, job_status, date_range, hour_count, user, day, year, week, monthList, clients, facilities, facility_id) {
    const params = `?page=${page}&limit=${limit}&date_range=${date_range}&job_status=${job_status}&station=${station}&hour_count=${hour_count}&user=${user}&day=${day}&year=${year}&week=${week}&month=${monthList}&facilities=${facilities}&clients=${clients}&facility_id=${facility_id}`

    const response = await request.get(`${API_ROUTE}/file-report-proccessed-summery-download` + params);
    return response.data;
  }
  async downloadLoackBoxReportDetails(page, limit, station, job_status, date_range, hour_count, user, day, year, week, month, filtering_date, clients, facilities, facility_id) {

    let params = `?page=${page}&limit=${limit}&date_range=${date_range}&job_status=${job_status}&station=${station}&user=${user}&day=${day}&year=${year}&week=${week}&month=${month}&facilities=${facilities}&clients=${clients}&facility_id=${facility_id}`
    if (day) {
      params = `?page=${page}&limit=${limit}&date_range=${date_range}&job_status=${job_status}&station=${station}&user=${user}&day=${day}&year=${year}&week=${week}&month=${month}&processed_date=${filtering_date}&facilities=${facilities}&clients=${clients}&facility_id=${facility_id}`
    }
    else if (week) {
      params = `?page=${page}&limit=${limit}&date_range=${date_range}&job_status=${job_status}&station=${station}&user=${user}&day=${day}&year=${year}&week=${week}&month=${month}&processed_date=${filtering_date}&facilities=${facilities}&clients=${clients}&facility_id=${facility_id}`
    }
    else if (month) {
      params = `?page=${page}&limit=${limit}&date_range=${date_range}&job_status=${job_status}&station=${station}&user=${user}&day=${day}&year=${year}&week=${week}&month=${month}&processed_date=${filtering_date}&facilities=${facilities}&clients=${clients}&facility_id=${facility_id}`
    }
    else if (year) {
      params = `?page=${page}&limit=${limit}&date_range=${date_range}&job_status=${job_status}&station=${station}&user=${user}&day=${day}&year=${year}&week=${week}&month=${month}&processed_date=${filtering_date}&facilities=${facilities}&clients=${clients}&facility_id=${facility_id}`
    }
    const response = await request.get(`${API_ROUTE}/file-report-proccessed-details-download` + params);
    return response.data;
  }
}