import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { useMemo } from "react";
import { Button, Form, OverlayTrigger } from "react-bootstrap";
import { useFormik } from "formik";

import { TbGridDots } from "react-icons/tb";
import { HiChevronDown } from "react-icons/hi";
import { components } from 'react-select';
import { FileManagementService } from "../../../services/FileManagementService";
import { BsCloudDownloadFill } from "react-icons/bs";
import { downloadXl } from "../../../functions";
import { useEffect } from "react";
import DetailCutomRowStyle from "../../components/DetailCutomRowStyle";
import { Tooltip } from "@mui/material";


function FileManagementDetails(params) {
  const { report_station, report_job_status, report_date_range, report_hour_count, user, clients, facilities, facility_id } = params

  const [reRenderDataTable, setRerenderDataTable] = useState(false)
  const [pageno, setPageNo] = useState(1);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(100);
  const [selectValue, setSelectValue] = useState('');
  const [colorRow, setColorRow] = React.useState({});
  const [data, setData] = useState([])
  const [day, setDay] = useState(true)
  const [year, setYear] = useState(false)
  const [week, setWeek] = useState(false)
  const [month, setMonth] = useState(false)
  const [monthList, setMonthList] = useState([])
  const [limit, setLimit] = useState(100)
  const [searchedData, setSearchedData] = useState({
    search: "",
    client: "",
    created_date: "",
    date_range: "",
  })

  const fileManagementService = new FileManagementService();
  const { getFileManagementDetailPayerResonCode, downloadFileManagementDetails } = fileManagementService


  const handlePerRowsChange = async (newPerPage, page) => {
    setLimit(newPerPage);
    setPageNo(1)

    fetchData(1, newPerPage, report_station, report_job_status, report_date_range, report_hour_count, user, day, year, week, month);
    handleResetDataTable()

  };

  const handlePageChange = page => {
    setPageNo(page)
    fetchData(page, limit, report_station, report_job_status, report_date_range, report_hour_count, user, day, year, week, month);
  };


  const fetchData = (page, limit, station, job_status, date_range, hour_count, user, day, year, week, month) => {

    setLoading(true)
    getFileManagementDetailPayerResonCode(page, limit, report_station, report_job_status, report_date_range, report_hour_count, user, day, year, week, month, clients, facilities, facility_id).then((response) => {
      setData(response?.data?.results);
      setLimit(limit);
      setPageNo(page)
      setTotalRows(response?.data?.count);
      setLoading(false);
    });
  }

  const [expandedRows, setExpandedRows] = useState([]);


  const handleRowExpand = (row) => {
    setColorRow(row);
    const isRowExpanded = expandedRows.includes(row);
    setExpandedRows(isRowExpanded ? expandedRows.filter((r) => r !== row) : [...expandedRows, row]);
  };

  const columns = useMemo(
    () => [
      {
        id: 'S.NO',
        name: 'S.NO',
        selector: (row, index) => pageno == 1 ? index + 1 : (pageno - 1) * limit + index + 1,
        wrap: true,
        width: "40px",
        grow: 0.1,

      },

      // {
      //   id: 'PAYER ID',
      //   name: 'PAYER ID',
      //   selector: row => row?.unique_code,
      //   cell: (row) =>

      //     <div onClick={() => { handleRowClick(row) }} style={row?.unique_code ? { height: 'auto' } : { height: '100%' }}>


      //       <div className='d-flex flex-row justify-content-between align-items-center gap-2' onClick={() => handleRowClick(row)} style={{ width: '170px' }}>
      //         <div className='overflow-hidden text-truncate' style={{ width: "120px" }}>
      //           <span onClick={() => handleRowClick(row)}> {row?.unique_code}</span>

      //         </div>
      //       </div>
      //     </div>
      //   ,
      //   
      //   left: true,
      //   wrap: true,
      //   reorder: false,
      //   grow: 0.1,
      // },
      // {
      //   id: 'PAYER NAME',
      //   name: 'PAYER NAME',
      //   selector: row => row?.payer_name,
      //   cell: (row) =>

      //     <div>
      //       {row?.payer_name &&

      //         <div className='d-flex flex-row justify-content-between align-items-center gap-2' style={{ width: '170px' }}>
      //           <div className='overflow-hidden text-truncate' style={{ width: "120px" }} onClick={() => handleRowClick(row)}>
      //             <Tooltip disableInteractive title={row?.payer_name} placement="top" arrow>
      //               <span className="text-truncate"> {row?.payer_name}</span>
      //             </Tooltip>
      //           </div>
      //         </div>

      //       }
      //     </div>
      //   ,

      //   
      //   left: true,
      //   wrap: true,
      //   reorder: false,
      //   grow: 0.1,
      //   expandOnRowClicked: true,
      //   minWidth: '150px'
      // },

      // {
      //   id: 'DATE ADDED',
      //   name: 'DATE ADDED',
      //   selector: row => row?.approved_date,
      //   cell: (row) =>

      //     <div>
      //       {row?.approved_date &&

      //         <div className='d-flex flex-row justify-content-between align-items-center gap-2'>
      //           <div className='overflow-hidden text-truncate' onClick={() => handleRowClick(row)}>
      //             <Tooltip disableInteractive title={row?.approved_date} placement="top" arrow>
      //               <span className="text-truncate"> {row?.approved_date}</span>
      //             </Tooltip>
      //           </div>
      //         </div>

      //       }
      //     </div>
      //   ,

      //   
      //   left: true,
      //   wrap: true,
      //   reorder: false,
      //   grow: 0.1,
      //   expandOnRowClicked: true,
      // },


      {
        id: 'PAYER ID',
        name: 'PAYER ID',
        selector: row => row?.unique_code,
        cell: (row) =>
          <Tooltip disableInteractive title={row?.unique_code} placement="top" arrow>
            <span onClick={() => handleRowClick(row)}> {row?.unique_code}</span>
          </Tooltip>,

        left: true,
        wrap: true,
        reorder: false,
        grow: 0.1,
      },
      {
        id: 'PAYER NAME',
        name: 'PAYER NAME',
        selector: row => row?.payer_name,
        cell: (row) =>
          <div>
            {row?.payer_name &&

              <div className='d-flex flex-row justify-content-between align-items-center gap-2' style={{ width: '170px' }}>
                <div className='overflow-hidden text-truncate' style={{ width: "120px" }} onClick={() => handleRowClick(row)}>
                  <Tooltip disableInteractive title={row?.payer_name} placement="top" arrow>
                    <span className="text-truncate"> {row?.payer_name}</span>
                  </Tooltip>
                </div>
              </div>

            }
          </div>,

        left: true,
        wrap: true,
        reorder: false,
        grow: 0.1,
      },
      {
        id: 'DATE ADDED',
        name: 'DATE ADDED',
        selector: row => row?.approved_date,
        cell: (row) =>
          <Tooltip disableInteractive title={row?.approved_date} placement="top" arrow>
            <span onClick={() => handleRowClick(row)}> {row?.approved_date}</span>
          </Tooltip>,

        left: true,
        wrap: true,
        reorder: false,
        grow: 0.1,
      },
      {
        id: 'STATUS',
        name: 'STATUS',
        selector: row => row?.is_approved,
        cell: (row) =>
          <Tooltip disableInteractive title={row?.is_approved ? "APPROVED" : "NOT APPROVED"} placement="top" arrow>
            <span onClick={() => handleRowClick(row)}> {row?.is_approved ? "APPROVED" : "NOT APPROVED"}</span>
          </Tooltip>,

        left: true,
        wrap: true,
        reorder: false,
        grow: 0.1,
      },



    ],

    [pageno],
  );

  const conditionalRowStyles = [

    {
      when: row => colorRow?.id === row?.id ? true : false,
      style: (row, index) => ({
        backgroundColor: colorRow?.id === row.id ? '#e4e6ef' : '#f2f2f2',
        color: 'black',
        // fontWeight: '550'
      }),
    },


  ];
  const handleRowClick = (row) => {
    setColorRow(row);
  };


  const searchFormik = useFormik({
    initialValues: {
      search: "",
      job_status: report_job_status,
      station: report_station,
      date_range: report_date_range,
      hour_count: report_hour_count

    },
    onSubmit: (values) => {
      console.log(values);
      handleResetDataTable()
    }
  })

  const [datetime, setDateTime] = useState([]);



  const searchData = ({ station, job_status, date_range, hour_count }) => {
    fetchData(1, limit, station, selectValue, datetime, hour_count)
  }

  const handleChangeSelect = async (e, name) => {
    let selectedId = []
    let selectedOptons = []

    e.map((option) => {
      selectedId.push(option.value)
      selectedOptons.push({ option })
    })

    searchFormik.setValues({ ...searchFormik.values, [name]: selectedId })
    setSelectValue(selectedId)

  }


  const showTooltip = (text, props) => (
    <Tooltip id="button-tooltip" {...props}>
      {text}
    </Tooltip>
  );

  const DropdownIndicator = ({ state, ...props }) => {
    return (
      <components.DropdownIndicator {...props}>

        {searchFormik.values[state]?.length > 1 ? (
          <TbGridDots style={{ fontSize: "1.5em" }} />
        ) : (
          <HiChevronDown style={{ fontSize: "1.5em" }} />
        )}



      </components.DropdownIndicator>
    );
  };
  const handleResetDataTable = () => {
    setRerenderDataTable(true)
  }
  const [isFocusedMonth, setIsFocusedMonth] = useState(false);

  const [isSwal, setIsSwal] = useState({
    status: false,
    data: "",
  });

  const [loader, setLoader] = useState(false);
  const handleDownload = () => {
    setLoader(true)

    downloadFileManagementDetails(1, limit, report_station, report_job_status, report_date_range, report_hour_count, user, day, year, week, month, clients, facilities, facility_id).then((response) => {
      setLoader(false)
      console.log(response);
      downloadXl(response?.data?.export_file)
    });
  }
  useEffect(() => {
    fetchData(1, limit, report_station, report_job_status, report_date_range, report_hour_count, user, day, year, week, month);
  }, [])
  return (
    <React.Fragment>
      <section className="section">
        <div className="row align-items-top py-2 gap-2 gap-md-5">
          <div className="col-lg-12">
            <div className="card card_background">

              <Form onSubmit={searchFormik.handleSubmit} >
                <div className="d-flex justify-content-end">

                  <div className="px-3 pt-1 d-flex flex-row justify-content-start gap-2 align-items-center flex-wrap">

                    <div>
                      <Tooltip disableInteractive title={"Download"} placement="top" arrow>
                        {!loader ?

                          <span style={{ cursor: "pointer" }} className="svg-icon svg-icon-3" onClick={() => { handleDownload() }}>
                            <BsCloudDownloadFill color="#1ba1a7" />
                          </span>
                          :
                          <span className="svg-icon svg-icon-3">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          </span>
                        }

                      </Tooltip>

                    </div>
                  </div>
                </div>

              </Form>


              <div className="card-body p-common">
                <div className="table-responsive">
                  <div className="dashboardtable custom_background">
                    {/* {!reRenderDataTable && */}
                    <DataTable
                      // className="custom-datatable-details"
                      paginationDefaultPage={pageno}

                      fixedHeader={true}
                      progressPending={loading}
                      data={data}
                      columns={columns}
                      pointerOnHover={true}
                      pagination
                      paginationServer
                      onRowClicked={handleRowClick}
                      // highlightOnHover
                      paginationTotalRows={totalRows}
                      paginationPerPage={limit}
                      paginationRowsPerPageOptions={[25, 50, 100, 150, 200]}
                      onChangeRowsPerPage={handlePerRowsChange}
                      onChangePage={handlePageChange}
                      conditionalRowStyles={conditionalRowStyles}
                      theme="detail_solarized"
                      customStyles={DetailCutomRowStyle}



                    />
                    {/* } */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment >
  )
}
export default FileManagementDetails




