import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import AdditionalInfo from './additionalInfo';
import { useRef } from 'react';
import { handleKeyPress } from '../gridFunctions';

const GridFooter = (props) => {

    const { state, isAdditionalInfoOptions, formEob, formPP, formCorres, eobLiteFormik, isDisabled, aditionalInfoFormik, additionalModal, setAdditionalModal, setIsDrag, isAdditional, jobData } = props;
    const [result, setResult] = useState(0);
    const aditionalInfoRef = useRef(null)

    const handleInputExpression = (event) => {
        setResult(eval(event.target.value));
    };

    const handleKeyPressEnter = () => {
        alert('asdf')
    }

    return (
        <div className='quick-calc'>

            <div className='leftside-block'>
                <div>
                    <input tabIndex="-1" className='quickcalc-btn' placeholder='Quick Calculator' onChange={(event) => handleInputExpression(event)} type="text" />
                </div>
                <div>
                    <span style={{ color: "#fff", fontWeight: 900 }}>=</span>
                </div>
                <div className='quickcalcResult'>
                    {parseFloat(result ? result : 0).toFixed(2)}
                </div>
            </div>

            {state?.Payment835 &&
                <React.Fragment>
                    {formEob.isSubmitting ? (

                        <div className='rightside-block gap-4'>
                            <div>
                                <button disabled className='btn btn-reject' type="button"  >
                                    <span className='  '> {formEob.isSubmitting ? 'Saving...' : 'SAVE '} </span>
                                </button>
                            </div>
                            <div className='power-off'>
                                <button disabled className='btn btn-reject ' type="button"  ><span className='  '> {formEob.isSubmitting ? 'Saving...' : 'SAVE & EXIT'} </span></button>
                            </div>
                        </div>
                    ) : (
                        <React.Fragment>
                            <div className='rightside-block gap-4'>
                                {/* {isAdditional && */}
                                <React.Fragment>
                                    <div>
                                        <AdditionalInfo
                                            {...props}
                                            formEob={formEob}
                                            aditionalInfoRef={aditionalInfoRef}
                                            formik={aditionalInfoFormik}
                                            setAdditionalModal={setAdditionalModal}
                                            additionalModal={additionalModal}
                                            setIsDrag={setIsDrag}

                                        />

                                        <button onClick={() => { setAdditionalModal(true); setIsDrag(false) }} ref={aditionalInfoRef} className={!aditionalInfoFormik?.isValid ? 'btn btn-reject border-danger exclude-disabled-new' : "btn btn-reject exclude-disabled-new"} type="button" >
                                            <span className='  '> Additional Info </span>
                                        </button>
                                    </div>
                                </React.Fragment>
                                {/* } */}
                                <div>
                                    <button className='btn btn-reject' type="button" disabled={!formEob.isValid} onClick={formEob.handleSubmit} >
                                        <span className='  '> SAVE </span>
                                    </button>
                                </div>
                                <div className='power-off'>
                                    <button className='btn btn-reject ' onClick={() => { localStorage.setItem('SAVE_EXIT', true); formEob.handleSubmit() }} type="button" disabled={!formEob.isValid} ><span className='  '>SAVE & EXIT</span></button>
                                </div>
                            </div>
                        </React.Fragment>
                    )
                    }
                </React.Fragment>
            }

            {state?.Payment835Lite &&
                <React.Fragment>
                    {eobLiteFormik.isSubmitting ? (
                        <div className='rightside-block gap-4'>
                            <div>
                                <button className='btn btn-reject' type="button"
                                    disabled onClick={eobLiteFormik.handleSubmit}
                                >
                                    <span className='  '> {eobLiteFormik.isSubmitting ? 'Saving...' : 'SAVE '} </span>
                                </button>
                            </div>
                            <div className='power-off'>
                                <button className='btn btn-reject ' type="button" disabled ><span className='  '>{eobLiteFormik.isSubmitting ? 'Saving...' : 'SAVE & EXIT'}</span></button>
                            </div>
                        </div>
                    ) : (
                        <div className='rightside-block gap-4'>
                            {isAdditional &&
                                <React.Fragment>
                                    <div>
                                        <AdditionalInfo
                                            {...props}
                                            formEob={eobLiteFormik}
                                            aditionalInfoRef={aditionalInfoRef}
                                            formik={aditionalInfoFormik}
                                            setAdditionalModal={setAdditionalModal}
                                            additionalModal={additionalModal}
                                            setIsDrag={setIsDrag}

                                        />

                                        <button onClick={() => { setAdditionalModal(true); setIsDrag(false) }} ref={aditionalInfoRef} className={!aditionalInfoFormik?.isValid ? 'btn btn-reject border-danger exclude-disabled-new' : "btn btn-reject exclude-disabled-new"} type="button" >
                                            <span className='  '> Additional Info </span>
                                        </button>

                                    </div>
                                </React.Fragment>
                            }
                            <div>
                                <button className='btn btn-reject' type="button" onKeyDown={handleKeyPress} disabled={!eobLiteFormik.isValid} onClick={eobLiteFormik.handleSubmit} >
                                    <span className='  '>SAVE </span>
                                </button>
                            </div>
                            <div className='power-off'>
                                <button className='btn btn-reject ' onKeyDown={handleKeyPress} onClick={() => { localStorage.setItem('SAVE_EXIT', true); eobLiteFormik.handleSubmit() }} type="button" disabled={!eobLiteFormik.isValid} ><span className='  '>SAVE & EXIT</span></button>
                            </div>
                        </div>

                    )
                    }
                </React.Fragment>
            }

            {state?.PatientPay &&
                <React.Fragment>
                    {formPP.isSubmitting ? (
                        <div className='rightside-block gap-4'>
                            <div>
                                <button className='btn btn-reject' type="button" disabled onClick={formPP.handleSubmit} >
                                    <span className='  '> {formPP.isSubmitting ? 'Saving...' : 'SAVE '} </span>
                                </button>
                            </div>
                            <div className='power-off'>
                                <button className='btn btn-reject ' type="button" disabled ><span className='  '>{formPP.isSubmitting ? 'Saving...' : 'SAVE & EXIT'}</span></button>
                            </div>
                        </div>
                    ) : (
                        <div className='rightside-block gap-4'>
                            {isAdditional &&
                                <React.Fragment>
                                    <div>
                                        <AdditionalInfo
                                            {...props}
                                            formEob={formPP}
                                            aditionalInfoRef={aditionalInfoRef}
                                            formik={aditionalInfoFormik}
                                            setAdditionalModal={setAdditionalModal}
                                            additionalModal={additionalModal}
                                            setIsDrag={setIsDrag}

                                        />

                                        <button onClick={() => { setAdditionalModal(true); setIsDrag(false) }} ref={aditionalInfoRef} className={!aditionalInfoFormik?.isValid ? 'btn btn-reject border-danger exclude-disabled-new' : "btn btn-reject exclude-disabled-new"} type="button" >
                                            <span className='  '> Additional Info </span>
                                        </button>
                                    </div>
                                </React.Fragment>
                            }
                            <div>
                                <button className='btn btn-reject' onKeyDown={handleKeyPress} type="button" disabled={!formPP.isValid} onClick={formPP.handleSubmit} >
                                    <span className='  '>SAVE </span>
                                </button>
                            </div>
                            <div className='power-off'>
                                <button className='btn btn-reject ' onKeyDown={handleKeyPress} onClick={() => { localStorage.setItem('SAVE_EXIT', true); formPP.handleSubmit() }} type="button" disabled={!formPP.isValid} ><span className='  '>SAVE & EXIT</span></button>
                            </div>
                        </div>

                    )
                    }
                </React.Fragment>
            }

            {state?.Correspondence &&

                <React.Fragment>
                    {formCorres.isSubmitting ? (
                        <div className='rightside-block gap-4'>
                            <div>
                                <button className='btn btn-reject' type="button" disabled onClick={formCorres.handleSubmit} >
                                    <span className='  '> {formCorres.isSubmitting ? 'Saving...' : 'SAVE '} </span>
                                </button>
                            </div>
                            <div className='power-off'>
                                <button className='btn btn-reject ' type="button" disabled ><span className='  '>{formCorres.isSubmitting ? 'Saving...' : 'SAVE & EXIT'}</span></button>
                            </div>
                        </div>
                    ) : (
                        <div className='rightside-block gap-4'>
                            {isAdditional &&
                                <React.Fragment>
                                    <div>
                                        <AdditionalInfo
                                            {...props}
                                            formEob={formCorres}
                                            aditionalInfoRef={aditionalInfoRef}
                                            formik={aditionalInfoFormik}
                                            setAdditionalModal={setAdditionalModal}
                                            additionalModal={additionalModal}
                                            setIsDrag={setIsDrag}

                                        />

                                        <button onClick={() => { setAdditionalModal(true); setIsDrag(false) }} ref={aditionalInfoRef} className={!aditionalInfoFormik?.isValid ? 'btn btn-reject border-danger exclude-disabled-new' : "btn btn-reject exclude-disabled-new"} type="button" >
                                            <span className='  '> Additional Info </span>
                                        </button>
                                    </div>
                                </React.Fragment>
                            }
                            <div>
                                <button className='btn btn-reject' type="button" disabled={!formCorres.isValid} onClick={formCorres.handleSubmit} >
                                    <span className=''>SAVE </span>
                                </button>
                            </div>
                            <div className='power-off'>
                                <button className='btn btn-reject ' onClick={() => { localStorage.setItem('SAVE_EXIT', true); formCorres.handleSubmit() }} type="button" disabled={!formCorres.isValid} ><span className='  '>SAVE & EXIT</span></button>
                            </div>
                        </div>

                    )
                    }
                </React.Fragment>
            }
        </div>
    )
}

export default GridFooter;