import React, { useState } from 'react';
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { AuthService } from '../../../services/AuthService';
import { setSignOutState } from '../../../slice/user/userSlice';
import { useNavigate } from 'react-router-dom';


function ResetPasswordForm({ __passwordToast, closeModal }) {
    const navigate = useNavigate()
    const authService = new AuthService()
    const { userLogout, changePasswordUser } = authService
    const dispatch = useDispatch()

    const user = useSelector((state) => state?.user)

    const { user_id } = user;

    const [isSubmit, setIsSubmit] = useState(false)
    const [loading, setLoading] = useState(false)

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            current_password: "",
            new_password: "",
            // confirm_password: "",
        },
        validationSchema: Yup.object().shape({

            current_password: Yup.string().required('Required')
                .min(3, 'Password is too short - should be 8 chars minimum.'),
            new_password: Yup
                .string()
                .required('Please Enter your password')
                .matches(
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                    "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Character"
                ),
            confirm_password: Yup.string()
                .oneOf([Yup.ref('new_password'), null], 'Passwords must match')
                .required('Please Enter your password')
                .matches(
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                    "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Character"
                ),
            passwordConfirmation: Yup.string()
                .oneOf([Yup.ref('password'), null], 'Passwords must match')

        }),
        onSubmit: (values) => {
            setLoading(true)
            setIsSubmit(true)

            // console.log(values, "###");
            let formData = new FormData();    //formdata object

            formData.append('current_password', values.current_password);
            formData.append('new_password', values.new_password);
            formData.append('user', user_id);
            // console.log(formData, "###");

            changePasswordUser(formData).then((response) => {
                if (!response?.data?.status && response?.status && response?.status_code === 201) {

                    __passwordToast("success", response?.message)
                    setIsSubmit(true)
                    setLoading(false)
                    setTimeout(() => {
                        logout()

                    }, 1600);

                } else {
                    __passwordToast("error", response?.response?.data?.errors?.current_password && response?.response?.data?.errors?.current_password[0] ? response?.response?.data?.errors?.current_password[0] : response?.response?.data?.errors?.password && response?.response?.data?.errors?.password[0] ? response?.response?.data?.errors?.password[0] : '')
                    const errors = response?.response?.data?.errors?.current_password && response?.response?.data?.errors?.current_password[0];
                    formik.setErrors({
                        ...formik,
                        current_password: errors,

                    })
                    setLoading(false)
                    setIsSubmit(false)

                }
            });
        },
    });

    const logout = async () => {
        let formData = new FormData();    //formdata object
        const refresh = localStorage.getItem('refresh')
        formData.append('refresh', refresh);   //append the values with key, value pair


        userLogout(formData).then((response) => {
            if (response?.status) {
                dispatch(setSignOutState())
                localStorage.removeItem('token')
                localStorage.removeItem('refresh')
                navigate('/login');
            }
            // else if (response?.response?.status === 401) {
            //     dispatch(setSignOutState())
            //     localStorage.clear()
            //     navigate('/login');; // Replace '/login' with your actual login page URL

            // } 
            else {
                dispatch(setSignOutState())
                localStorage.clear()
                navigate('/login');; // Replace '/login' with your actual login page URL

            }
        })
    }

    const passwordValidationMsg = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Character
        </Tooltip>
    );

    const [toggleCurrentPassword, setToggleCurrentPassword] = useState(false);
    const [toggleNewPassword, setToggleNewPassword] = useState(false);
    const [toggleConfirmPassword, setToggleConfirmPassword] = useState(false);


    return (
        <React.Fragment>


            <Form
                onSubmit={formik.handleSubmit}
            >
                <TopHeadPassword>
                    {/* {!user.is_password_reset_required &&
                        <span onClick={() => { closeModal() }} className='close-icon'><i className="bi bi-x-square"></i></span>
                    } */}
                    <Form.Label className='fw-bold clr-heading' >Reset Password</Form.Label>
                </TopHeadPassword>
                <div className="mb-3 row border-bottom-dashed" >


                    <div className='col-lg-12'>
                        <Form.Group className="mb-3" >
                            <Form.Label className='fw-bold repassword-label'>Current Password</Form.Label>
                            {/* <div className='input-group '> */}
                            <div style={{ position: 'relative' }} className='p-0 m-0'>

                                <Form.Control
                                    name='current_password'
                                    type={toggleCurrentPassword ? "text" : "password"}
                                    onChange={formik.handleChange}
                                    // onBlur={formik.handleBlur}
                                    autoComplete="false"
                                    isValid={formik.touched?.current_password && !formik.errors?.current_password}
                                    isInvalid={!!formik.errors?.current_password}
                                    value={formik.values?.current_password}
                                    className='b-r-10 form-control-solid repassword-input'
                                    // 
                                    placeholder="Current Password"
                                />

                                <div className='' style={formik.errors?.current_password ? { position: 'absolute', top: '10px', right: '35px', cursor: 'pointer', fontSize: '15px', zIndex: "9" } : { position: 'absolute', top: '10px', right: '35px', cursor: 'pointer', fontSize: '15px', zIndex: "9" }} onClick={() => setToggleCurrentPassword(!toggleCurrentPassword)}  >
                                    <i className={toggleCurrentPassword ? 'bi bi-eye' : 'bi bi-eye-slash'}></i>
                                </div>
                            </div>
                            <Form.Control.Feedback type="invalid"> {formik.errors?.current_password}</Form.Control.Feedback>

                        </Form.Group>

                        <Form.Group className="mb-3" >

                            <Form.Label className='pt-4 fw-bold  repassword-label'>New Password </Form.Label>
                            {formik.errors?.new_password &&
                                <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 50, hide: 100 }}
                                    overlay={passwordValidationMsg}
                                >
                                    <span className='ms-2'><i className="bi bi-info-circle text-danger"></i></span>
                                </OverlayTrigger>}
                            <div style={{ position: 'relative' }} className='p-0 m-0'>

                                <Form.Control
                                    name='new_password'
                                    type={toggleNewPassword ? "text" : "password"}
                                    onChange={formik.handleChange}
                                    // onBlur={formik.handleBlur}
                                    autoComplete="false"
                                    isValid={formik.touched?.new_password && !formik.errors?.new_password}
                                    isInvalid={!!formik.errors?.new_password}
                                    value={formik.values?.new_password}
                                    className='b-r-10 form-control-solid  repassword-input'
                                    // 
                                    placeholder="New Password"
                                />

                                <div className='' style={formik.errors?.new_password ? { position: 'absolute', top: '10px', right: '35px', cursor: 'pointer', fontSize: '15px', zIndex: "9" } : { position: 'absolute', top: '10px', right: '35px', cursor: 'pointer', fontSize: '15px', zIndex: "9" }} onClick={() => setToggleNewPassword(!toggleNewPassword)}  >
                                    <i className={toggleNewPassword ? 'bi bi-eye' : 'bi bi-eye-slash'}></i>
                                </div>
                            </div>
                            <Form.Control.Feedback type="invalid"> {formik.errors?.new_password && 'Invalid Password'}</Form.Control.Feedback>

                        </Form.Group>

                        <Form.Group className="mb-3" >

                            <Form.Label className='pt-4 fw-bold  repassword-label'>Confirm Password</Form.Label>

                            <div style={{ position: 'relative' }} className='p-0 m-0'>

                                <Form.Control
                                    name='confirm_password'
                                    type={toggleConfirmPassword ? "text" : "password"}
                                    onChange={formik.handleChange}
                                    // onBlur={formik.handleBlur}
                                    autoComplete="false"
                                    isValid={formik.touched?.confirm_password && !formik.errors?.confirm_password}
                                    isInvalid={!!formik.errors?.confirm_password}
                                    value={formik.values?.confirm_password}
                                    className='b-r-10 form-control-solid  repassword-input'
                                    // 
                                    placeholder="Confirm Password"
                                />

                                <div className='' style={formik.errors?.confirm_password ? { position: 'absolute', top: '10px', right: '35px', cursor: 'pointer', fontSize: '15px', zIndex: "9" } : { position: 'absolute', top: '10px', right: '35px', cursor: 'pointer', fontSize: '15px', zIndex: "9" }} onClick={() => setToggleConfirmPassword(!toggleConfirmPassword)}  >
                                    <i className={toggleConfirmPassword ? 'bi bi-eye' : 'bi bi-eye-slash'}></i>
                                </div>
                            </div>
                            <Form.Control.Feedback type="invalid"> {formik.errors?.confirm_password == "Passwords must match" ? "Passwords doesn't match" : formik.errors?.confirm_password ? 'Invalid Password' : null}</Form.Control.Feedback>

                        </Form.Group>
                    </div>
                </div>

                <div className='d-flex flex-row justify-content-end'>

                    <div className=''>
                        <button
                            disabled={!formik.isValid}
                            type='submit'
                            className='btn btn-light btn-active-primary'
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </Form>
        </React.Fragment>
    )
}

export default ResetPasswordForm

const TopHeadPassword = styled.div`
    ${'' /* display: flex;
    justify-content: space-between;
    flex-direction: row-reverse; */}
    margin-bottom:10px;
    span.close-icon{
        cursor: pointer;
    }
`;