import request from "../api/agent";

const API_ROUTE = "reports";

export class JobReportService {

  async getJobReportSummery(page, limit, station, job_status, date_range, hour_count, clients, facilities) {
    const params = `?page=${page}&limit=${limit}&date_range=${date_range}&job_status=${job_status}&facilities=${facilities}&clients=${clients}`

    const response = await request.get(`${API_ROUTE}/get-job-report-summery` + params);
    return response.data ?? response?.response?.data;

  }

  async getJobReportDetails(page, limit, station, job_status, date_range, hour_count, user, day, year, week, monthList, clients, facilities, client_id, facility_id) {
    const params = `?page=${page}&limit=${limit}&date_range=${date_range}&job_status=${user}&day=${day}&year=${year}&week=${week}&month=${monthList}&facilities=${facilities}&clients=${clients}&client_id=${client_id}&facility_id=${facility_id}`

    const response = await request.get(`${API_ROUTE}/get-job-report-details` + params);
    return response.data;
  }
  async downloadJobReportSummery(page, limit, station, job_status, date_range, hour_count, clients, facilities) {
    const params = `?page=${page}&limit=${limit}&date_range=${date_range}&job_status=${job_status}&facilities=${facilities}&clients=${clients}`

    const response = await request.get(`${API_ROUTE}/job-report-download` + params);
    return response.data;
  }
  async downloadJobReportSingle(page, limit, station, job_status, date_range, hour_count, clients, facilities) {
    const params = `?page=${page}&limit=${limit}&date_range=${date_range}&job_status=${job_status}&facilities=${facilities}&clients=${clients}`

    const response = await request.get(`${API_ROUTE}/job-report-single-download` + params);
    return response.data;
  }

  async downloadJobReportDetails(page, limit, station, job_status, date_range, hour_count, user, day, year, week, monthList, clients, facilities, client_id, facility_id) {
    const params = `?page=${page}&limit=${limit}&date_range=${date_range}&job_status=${user}&day=${day}&year=${year}&week=${week}&month=${monthList}&facilities=${facilities}&clients=${clients}&client_id=${client_id}&facility_id=${facility_id}`

    const response = await request.get(`${API_ROUTE}/job-report-details-download` + params);
    return response.data;
  }
}