import React from 'react'
import { useFormik } from 'formik';
import { Form, Toast, ToastContainer, InputGroup } from 'react-bootstrap';
import * as Yup from 'yup';
import { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { TbGridDots } from "react-icons/tb";
import { HiChevronDown } from "react-icons/hi";
import Select, { components, DropdownIndicatorProps } from 'react-select';

import { UserApiService } from '../../../services/UserApiService';
import Sidebar from '../../includes/Sidebar';


function CreateUpdateUser() {
  const userService = new UserApiService();
  const { addOrUpdateUser, getSingleUserDetails, getGroupsForUser } = userService

  const navigate = useNavigate()
  const { user_id } = useParams();
  const [data, setData] = useState('');

  const [state, setState] = useState({
    showToast: false,
    error: false,
    error_message: "",
  })
  const [groups, setGroups] = useState();

  const [options, setOptions] = useState({});

  const __handleEditUser = (values) => {

    let formData = new FormData();    //formdata object

    // formData.append('username', values.username);
    // formData.append('email', values.email);
    // formData.append('password', values.password);
    // formData.append('name', values.name)
    // formData.append('groups', groups)
    const dict = {
      username: values.username,
      email: values.email,
      name: values.name,
      groups: groups,
    }
    if (user_id) {
      dict.user = user_id
      // formData.append('user', user_id);
    } else {
      dict.password = values.password
    }

    addOrUpdateUser(dict).then((response) => {
      console.log(response, 'data');
      if (!response?.data?.data?.status && response?.data?.status && response?.data?.status_code === 201) {
        setState({ showToast: !state.showToast })
        setTimeout(() => {
          navigate('/list-users')
        }, 1000);
      } else {
        console.log(response?.response?.data?.errors?.username);
        console.log(response);

        formik.setErrors({
          name: response?.response?.data?.errors?.name,
          username: response?.response?.data?.errors?.username,
          groups: response?.response?.data?.errors?.groups,
          email: response?.response?.data?.errors?.email,
        })
        // setState({ error: true, error_message: response?.message });
      }
    });
  }
  const __toggleToast = () => {
    setState({ showToast: !state.showToast })

  }

  const [showPassword, setShowPassword] = useState(false)

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      username: "",
      email: "",
      password: "",
      groups: [],
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().matches(/^[a-zA-Z0-9 ]*$/, "Enter a valid name. Only alphabets, numbers, and space are allowed."),
      username: Yup.string()
        .min(1, ' at least 1 character')
        .matches(/^[a-zA-Z0-9@_.]*$/, "Enter a valid Username. Only alphabets, numbers, '@', '_', and '.' are allowed.")
        .required('Required'),
      email: Yup.string()
        .matches(/^[a-zA-Z0-9@._]+$/, 'Invalid Email')
        .email('Invalid email'),
      password: !user_id ? Yup.string().required('Please Enter your password')
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
          "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Character"
        ) : Yup.string(),
      groups: Yup.array().min(1, 'At least one option is required').required('Required'),

    }),

    onSubmit: (values) => {
      __handleEditUser(values)
    },
  });


  useEffect(() => {
    getGroupsForUser().then((response) => {

      setOptions(response?.data?.groups);
      // setGroups(response?.data?.groups);
    });
    if (user_id) {

      getSingleUserDetails(user_id).then((response) => {

        if (response?.status && response?.data) {
          console.log(response);
          console.log(response?.data?.user?.user_groups.map((el) => el.value))
          setData(response?.data?.user);
          setGroups(response?.data?.user?.user_groups.map((val) => val.value))
          formik.setValues({
            ...formik.values,
            user: user_id,
            username: response?.data?.user?.username,
            email: response?.data?.user?.email,
            admin: response?.data?.user?.is_admin,
            staff: response?.data?.user?.is_staff,
            groups: response?.data?.user?.user_groups,
            name: response?.data?.user?.name,

          })
        }
      });

    }

  }, [user_id]);

  const handleOnSelectChange = ({ name, value }) => {
    formik.setValues({ ...formik.values, [name]: value })
    setGroups(value.map((val) => val.value))
  }

  const DropdownIndicator = ({ state, ...props }) => {
    return (
      <components.DropdownIndicator {...props}>

        {formik.values?.groups?.length > 1 ? (
          <TbGridDots style={{ fontSize: "1.5em" }} />
        ) : (
          <HiChevronDown style={{ fontSize: "1.5em" }} />
        )}



      </components.DropdownIndicator>
    );
  };

  const [isFocusedClient, setIsFocusedClient] = useState(false);
  console.log(formik.errors);
  return (
    <React.Fragment>

      <Sidebar />
      <main id="main" className="main">
        <div className="pagetitle tab-fixed-header ">
          <h1> {!user_id ? "Create" : "Update"} User</h1>
        </div>
        <ToastContainer className="p-3" position={"top-end"}>
          <Toast bg="success" onClose={__toggleToast} show={state.showToast} animation={true} delay={2000} autohide>

            <Toast.Body ><span className='text-white'>Success</span></Toast.Body>
          </Toast>
        </ToastContainer>

        <section className="section mt-5">

          <div className="row align-items-top py-3 gap-2 gap-md-5">
            <div className="col-lg-12">
              <div className="card mb-5 mb-xl-8">

                <div className="card-body py-3 ">

                  <Form
                    onSubmit={formik.handleSubmit}
                  >
                    <div className="mb-3 row" >
                      <div className='col-lg-4'>
                        <Form.Group className="mb-3" >

                          <Form.Label className='fw-bold'>Name</Form.Label>
                          <Form.Control
                            name='name'
                            type="name"
                            onChange={formik.handleChange}
                            autoComplete="false"
                            isValid={formik.touched.name && !formik.errors?.name}
                            isInvalid={!!formik.errors.name}
                            value={formik.values.name}
                            className='b-r-10 form-control-solid'
                            maxLength="255"
                            placeholder="Enter Name"
                          />
                          <Form.Control.Feedback type="invalid">{formik.errors?.name}</Form.Control.Feedback>

                        </Form.Group>
                      </div>

                      <div className='col-lg-4'>
                        <Form.Group className="mb-3" >

                          <Form.Label className='fw-bold'>Username</Form.Label>
                          <Form.Control
                            name='username'
                            type="text"
                            onChange={formik.handleChange}
                            autoComplete="false"
                            isValid={formik.touched.username && !formik.errors.username}
                            isInvalid={!!formik.errors.username}
                            value={formik.values.username}
                            className='b-r-10 form-control-solid'
                            maxLength="255"
                            placeholder="Enter Username"
                          />
                          <Form.Control.Feedback type="invalid">{formik.errors?.username}</Form.Control.Feedback>

                        </Form.Group>
                      </div>
                      <div className='col-lg-4'>
                        <Form.Group className="mb-3" >

                          <Form.Label className='fw-bold'>Email</Form.Label>
                          <Form.Control
                            name='email'
                            type="email"
                            onChange={formik.handleChange}
                            autoComplete="false"
                            isValid={formik.touched.email && !formik.errors.email}
                            isInvalid={!!formik.errors.email}
                            value={formik.values.email}
                            className='b-r-10 form-control-solid custom-padding'
                            maxLength="255"
                            placeholder="Enter Email"
                          />
                          <Form.Control.Feedback type="invalid">{formik.errors.email}</Form.Control.Feedback>

                        </Form.Group>
                      </div>
                      {!user_id &&
                        <div className='col-lg-4'>
                          <Form.Group className=""  >
                            <Form.Label className='fw-bold'>Password</Form.Label>
                            <div style={{ position: 'relative' }} className='p-0 m-0'>

                              <Form.Control
                                name='password'
                                type={showPassword ? "text" : "password"}
                                onChange={formik.handleChange}
                                autoComplete="false"
                                // isValid={formik.touched.password && !formik.errors.password}
                                isInvalid={formik.errors.password}
                                value={formik.values.password}
                                className='b-r-10 form-control-solid password-padding'
                                // maxLength="255"
                                required
                                placeholder="Enter Password"
                              />
                              <Form.Control.Feedback type="invalid">{formik.errors.password}</Form.Control.Feedback>
                              <div className='' style={{ position: 'absolute', top: '10px', right: '30px', cursor: 'pointer', zIndex: "777" }} onClick={() => setShowPassword(!showPassword)}  >
                                <i className={showPassword ? 'bi bi-eye' : 'bi bi-eye-slash'}></i>
                              </div>
                            </div>

                          </Form.Group>
                        </div>
                      }

                      <div className='col-lg-4'>
                        <Form.Label className='fw-bold'>Groups</Form.Label>
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          isMulti
                          value={formik.values?.groups}
                          name="groups"
                          options={options}
                          closeMenuOnSelect={false}
                          components={{ DropdownIndicator: (props) => <DropdownIndicator {...props} /> }}
                          onChange={(e) => { handleOnSelectChange({ name: 'groups', value: e }) }}
                          placeholder="Select User Group..."
                          onFocus={() => setIsFocusedClient(true)}
                          onBlur={() => setIsFocusedClient(false)}
                          hideSelectedOptions={false}
                          styles={{
                            container: (styles, state) => ({
                              ...styles,
                              borderRadius: "9px",
                            }),
                            control: (styles, state) => ({
                              ...styles,
                              backgroundColor: "hsl(204deg 33.33% 97.06%)",
                              borderStyle: "none", boxShadow: "none",
                              borderRadius: "10px",
                              maxHeight: "30px",
                            }),
                            indicatorSeparator: styles => ({
                              ...styles, width: "0px", minHeight: "20px",
                              maxHeight: "30px",
                            }),

                            valueContainer: (styles, state) => ({
                              ...styles,
                              maxHeight: "30px",
                              overflow: 'scroll',
                            }),
                            option: (styles, state) => ({
                              ...styles,
                              backgroundColor: state.isSelected ? '#d9f4f5' : 'inherit',
                              color: state.isSelected ? '#000000' : 'inherit',
                              '&:hover': { backgroundColor: state.isSelected ? '#d9f4f5' : 'rgb(222, 235, 255)' }

                            })
                          }} />

                        {formik.errors?.groups &&
                          <span className='text-danger'>
                            {formik.errors?.groups}

                          </span>
                        }
                        {/* {formik.errors?.groups && formik.touched.groups &&
                                        <ErrorMessage name="groups" component="div" />
                                        } */}
                      </div>

                    </div>
                    <div>
                      {/* {state.error &&
                                        <span className='text-danger'>{state.error_message}</span>} */}
                    </div>
                    <div className='d-flex flex-row justify-content-end'>

                      <div className=''>
                        <button
                          disabled={!formik.isValid}
                          type='submit'
                          className='btn btn-primary btnHover'
                        >
                          {!user_id ? "Create" : "Update"}
                        </button>
                      </div>

                    </div>
                  </Form>

                </div>
              </div>
            </div>
          </div>
        </section>


      </main>
    </React.Fragment >
  )
}

export default CreateUpdateUser