import { darken, desaturate, lighten } from 'polished';
import React from 'react'
import Chart from 'react-apexcharts';
import BarSkelitonLoader from '../../loader/BarSkelitonLoader';

const SingleReportGraph = ({ isVisible, singleData, handleClickInnerGraph, handleClickInnerData, singleColor }) => {


    const options = {
        fill: {
            type: 'gradient',
        },
        chart: {
            id: 'basic-bar',
            toolbar: {
                show: false
            },
            fontFamily: 'Open Sans',
            events: {
                click: handleClickInnerGraph,
                dataPointSelection: handleClickInnerData
            }
        },
        grid: {
            borderColor: '#E4E4E4',
            xaxis: {
                lines: {
                    show: false
                }
            },
            yaxis: {
                lines: {
                    show: true
                }
            },
        },
        plotOptions: {
            bar: {
                distributed: true,
                columnWidth: '40px'
            },
        },
        legend: {
            show: false
        },

        colors: [darken(0.1, singleColor), singleColor, lighten(0.1, singleColor), desaturate(0.2, singleColor)],
        xaxis: {
            categories: ['Payment 835', 'Payment 835 Lite', 'Patient Pay', 'Correspondence'],
            axisBorder: {
                show: true,
                color: '#000000',
                height: 1,
                width: '100%',
                offsetX: 0,
                offsetY: 0
            },
            labels: {
                show: true,
            }

        },
        yaxis: {
            axisBorder: {
                show: true,
                color: '#000000',
                height: '100%',
                width: 1,
                offsetX: 0,
                offsetY: 0
            },

        },
    };
    const series = [{
        name: '',
        data: singleData ? singleData : []
    }]
    // console.log(singleData)
    return (
        <div className='card p-2' style={{ height: '45vh' }}>
            {isVisible ?
                <BarSkelitonLoader /> :
                <Chart options={options} series={series} type="bar" height={'90%'} />
            }
        </div>
    )
}

export default SingleReportGraph