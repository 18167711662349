import React from 'react'
import { Tooltip } from "@mui/material";
import { BsCloudDownloadFill } from 'react-icons/bs';
import { Button } from "react-bootstrap";

function DownloadDropDown({ loader, handleDownload, is_field_error = false }) {

    return (
        <div className="dropdown">
            {!loader ?
                <div>
                    <button className="btn btn-light btn-active-primary pb-2 dropdown-toggle  jobMonitor-Buttons" type="button" data-bs-toggle="dropdown" aria-expanded="false">

                        <Tooltip disableInteractive title={"Download"} placement="top" arrow>
                            <div>
                                <BsCloudDownloadFill className='jobMonitor-svg' />
                            </div>
                        </Tooltip>


                    </button>

                    <ul className="dropdown-menu hide-scroll-bar" style={{ height: is_field_error ? '110px' : '80px', overflowX: 'scroll', cursor: 'pointer' }}>
                        <li>
                            <div className='dropdown-item' onClick={() => handleDownload('single')}>

                                <div className="">
                                    Full view
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className='dropdown-item' onClick={() => handleDownload('summury')}>

                                <div className="">
                                    Summarized view
                                </div>
                            </div>
                        </li>
                        {is_field_error &&
                            <li>
                                <div className='dropdown-item' onClick={() => handleDownload('field_error')}>

                                    <div className="">
                                        Error Field Report
                                    </div>
                                </div>
                            </li>
                        }
                    </ul>
                </div>
                : <Button className="btn btn-light btn-active-primary jobMonitor-Buttons" >
                    <span className="svg-icon svg-icon-3">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    </span>

                </Button>
            }
        </div>
    )
}

export default DownloadDropDown