import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import styled from 'styled-components/macro';

import { useMemo, useCallback } from "react";
import { Button, Form, OverlayTrigger, } from "react-bootstrap";
import moment from "moment";
import { useFormik } from "formik";
import { DateRangePicker } from "rsuite";
import { BiReset, BiSearch } from "react-icons/bi";
import { Stack } from 'rsuite';
import subDays from 'date-fns/subDays';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import addDays from 'date-fns/addDays';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import addMonths from 'date-fns/addMonths';
import { TbGridDots } from "react-icons/tb";
import { HiChevronDown } from "react-icons/hi";
import Select, { components } from 'react-select';
import Sidebar from "../../includes/Sidebar";
import { UserReportService } from "../../../services/UserReportService";
import { DataListService } from '../../../services/DataListService';

import UserReportDetails from "./UserReportDetails";
import { BsCloudDownloadFill } from "react-icons/bs";
import { downloadXl } from "../../../functions";
import { useDispatch, useSelector } from "react-redux";
import { selectIsSuperUser, selectPermissions } from "../../../slice/user/userSlice";
import { Tooltip } from "@mui/material";
import SingleSelect from "../../components/SingleSelect";
import { seErrorMessage } from "../../../slice/error/errorSlice";
import AsyncSelect from 'react-select/async';
import { ClientReportService } from "../../../services/ClientReportService";
import { InvoiceReportService } from "../../../services/InvoiceReportService";
import { useRef } from "react";
import DownloadDropDown from "../../components/DownloadDropDown";


const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <>
    <TextField

      id="search"
      className="form-control form-control-solid w-250px filter-btn-custom"
      type="text"
      placeholder="Filter By Name"
      aria-label="Search Input"
      value={filterText}
      onChange={onFilter}
    />
    <ClearButton type="button" onClick={onClear}>
      X
    </ClearButton>
  </>
);

const TextField = styled.input`
    height: 32px;
    width: 200px;
    &:hover {
        cursor: pointer;
    }
    `;
const ClearButton = styled(Button)`
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	border-top-right-radius: 5px;
    border-left: 0;
    color: #1ba1a7;
	border-bottom-right-radius: 5px;
	height: 35px;
	width: 32px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
    background-color: #f5f8fa;
    border-color: #d9d9d9;
    &:hover {
        background-color: #f5f8fa;
        border-color: #d9d9d9;
        color: #1ba1a7;
    }
    &:focus {
        background-color: #f5f8fa !important;
        border-color: #d9d9d9 !important;
        color: #1ba1a7;
    }`;

const datatableCustomStyles = {
  rows: {
    style: {
      minHeight: '20px', // override the row height
      fontSize: '0.7em',
      fontColor: '#212529 !important',
      width: '100%',

    },

  },
  headCells: {
    style: {
      paddingLeft: '5px', // override the cell padding for head cells
      paddingRight: '5px',
      textTransform: "uppercase",
      fontSize: '0.7em',
      fontWeight: '800',
      fontColor: '#6c757d !important',
      opacity: 1,
      minHeight: "10px",
    },
  },
  cells: {
    style: {
      paddingLeft: '8px', // override the cell padding for data cells
      paddingRight: '8px',
      width: "100%",
    },
  },
  pagination: {
    style: {
      color: '#000',
      fontSize: '13px',
      minHeight: '56px',
      backgroundColor: '#fff',
      borderTopStyle: 'solid',
      borderTopWidth: '1px',
      borderTopColor: '#f2f2f2',
    },
    pageButtonsStyle: {
      borderRadius: '50%',
      height: '40px',
      width: '40px',
      padding: '8px',
      margin: '0px',
      cursor: 'pointer',
      transition: '0.4s',
      color: '#000',
      fill: '#000',
      backgroundColor: 'transparent',
      '&:disabled': {
        cursor: 'unset',
        color: '#e2e2e2',
        fill: '#e2e2e2',
      },
      '&:hover:not(:disabled)': {
        backgroundColor: '#f2f2f2',
      },
      '&:focus': {
        outline: 'none',
        backgroundColor: '#f5f5f5',
      },
    },
  },
};

function UserReport() {
  const dispatch = useDispatch()
  const facilityRef = useRef(null)
  const clientRef = useRef(null)

  const [exportLoader, setExportLoader] = useState(false);
  const [reRenderDataTable, setRerenderDataTable] = useState(false)
  const [pageno, setPageNo] = useState(1);
  const [loading, setLoading] = useState(false);
  const [isTimeCount, setisTimeCount] = useState(false);
  const [isTimeCountQaReview, setisTimeCountQaReview] = useState(false);

  const [totalRows, setTotalRows] = useState(100);
  const [filterText, setFilterText] = useState('');
  const [colorRow, setColorRow] = React.useState({});
  const [data, setData] = useState({})
  const [limit, setLimit] = useState(100)
  const [searchedData, setSearchedData] = useState({
    search: "",
    job_status: "",
    date_range: `${moment(startOfMonth(new Date())).format('MM/DD/YYYY')} - ${moment(new Date()).format('MM/DD/YYYY')}`,
    station: 0,
    hour_count: "",
    staff: [],
    clients: [],
    facilities: [],
  })
  const [isFocusedClient, setIsFocusedClient] = useState(false);

  const [clients, setClients] = useState([]);

  const [isFocusedFacility, setIsFocusedFacility] = useState(false);

  const [selectValue, setSelectValue] = useState([])

  const [facilities, setFacilities] = useState([])

  const [selectIds, setSelectIds] = useState("")

  const [selectedFacility, setSelectedFacility] = useState([])

  const [selectedFacilityIds, setSelectedFacilityIds] = useState("")

  const invoiceReportService = new InvoiceReportService();

  const { getFacilityDropdown, getAllClientDropdown } = invoiceReportService
  const userReportService = new UserReportService();
  const dataListService = new DataListService();

  const { getUserReportSummery, downloadUserReportSummery, downloadUserReportSingle } = userReportService
  const { getUserlist } = dataListService
  //const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  const permissions = useSelector(selectPermissions);
  const isSuperUser = useSelector(selectIsSuperUser);
  const isHavePermission = (name) => {
    return permissions?.includes(name) || isSuperUser
  }
  const

    predefinedBottomRanges = [
      {
        label: 'Today',
        value: [new Date(), new Date()],
        placement: 'left'
      },
      {
        label: 'Yesterday',
        value: [addDays(new Date(), -1), addDays(new Date(), -1)],
        placement: 'left'
      },
      {
        label: 'This week',
        value: [startOfWeek(new Date()), endOfWeek(new Date())],
        placement: 'left'
      },
      {
        label: 'Last 7 days',
        value: [subDays(new Date(), 6), new Date()],
        placement: 'left'
      },
      {
        label: 'Last 30 days',
        value: [subDays(new Date(), 29), new Date()],
        placement: 'left'
      },
      {
        label: 'This month',
        value: [startOfMonth(new Date()), new Date()],
        placement: 'left'
      },
      {
        label: 'Last month',
        value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
        placement: 'left'
      },
      {
        label: 'This year',
        value: [new Date(new Date().getFullYear(), 0, 1), new Date()],
        placement: 'left'
      },
      {
        label: 'Last year',
        value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date(new Date().getFullYear(), 0, 0)],
        placement: 'left'
      },

      {
        label: 'Last week',
        closeOverlay: false,
        value: value => {
          const [start = new Date()] = value || [];
          return [
            addDays(startOfWeek(start, { weekStartsOn: 0 }), -7),
            addDays(endOfWeek(start, { weekStartsOn: 0 }), -7)
          ];
        },
        appearance: 'default'
      },
      {
        label: 'Next week',
        closeOverlay: false,
        value: value => {
          const [start = new Date()] = value || [];
          return [
            addDays(startOfWeek(start, { weekStartsOn: 0 }), 7),
            addDays(endOfWeek(start, { weekStartsOn: 0 }), 7)
          ];
        },
        appearance: 'default'
      }
    ];
  const handleClear = () => {
    if (filterText) {
      //setResetPaginationToggle(!resetPaginationToggle);
      setFilterText('');
      // fetchData(1, limit)
    }
  };
  const handleSearchSplits = async (e) => {
    setFilterText(e.target.value)
    let data = e.target.value ? e.target.value : '';
    fetchData(1, limit, searchedData?.station, jobStatus, datetime, searchedData?.hour_count, searchedData?.staff, searchedData?.clients ?? "", searchedData?.facilities ?? "");

  }

  const handlePerRowsChange = async (newPerPage, page) => {
    setLimit(newPerPage);
    setPageNo(1)
    fetchData(1, newPerPage, searchedData?.station ?? "", searchedData?.job_status ?? "", searchedData?.date_range ?? "", searchedData?.hour_count ?? "", searchedData?.staff, searchedData?.clients ?? "", searchedData?.facilities ?? "");
    handleResetDataTable()

  };

  const handlePageChange = page => {
    setPageNo(page)
    fetchData(page, limit, searchedData?.station ?? "", searchedData?.job_status ?? "", searchedData?.date_range ?? "", searchedData?.hour_count ?? "", searchedData?.staff, searchedData?.clients ?? "", searchedData?.facilities ?? "");
  };
  const [status, setStatus] = useState([
    // {
    //   label: "All",
    //   value: "all",
    // },
    {
      label: "DC Completed",
      value: "datacaptured",
    },
    {
      label: "Partial Save",
      value: "partial_save",
    },
    {
      label: "Re Assigned",
      value: "re_assigned",
    },
    {
      label: "Rejected",
      value: "rejected",
    },
    {
      label: "QA Completed",
      value: "qa_complete",
    },
    {
      label: "Snipping Completed",
      value: "snipping_complete",
    },

  ])

  const [jobStatus, setJobStatus] = useState([])
  const [selectStaffValue, setSelectStaffValue] = useState([])



  const fetchData = (page, limit, station, job_status, date_range, hour_count, staff, clients, facilities) => {
    console.log(station, hour_count, "^^^^^^^^^^^^^^^^&*^&^^^^^^^^^^^^");
    setLoading(true)
    setisTimeCount(hour_count == 'true' || hour_count == '' ? false : true)
    setisTimeCountQaReview(station === 2 ? true : hour_count == 'true' || hour_count == '' ? false : true)

    getUserReportSummery(page, limit, station, job_status, date_range, hour_count, staff, clients, facilities).then((response) => {
      setData(response?.data);
      setLimit(limit);
      setPageNo(page)
      setTotalRows(response?.data?.count);
      setLoading(false);
      if (response?.errors?.date_range) {
        dispatch(seErrorMessage({ message: response?.errors?.date_range[0], is_show: true }))
      }
    });

  }




  const handleRowClickCheck = (row) => {
    data?.results.map((item, index) => {
      if (row?.id == item.id) {
        setColorRow(row)
      }
    })
  }
  const [expandedRows, setExpandedRows] = useState([]);
  const ExpandedComponent = useCallback(
    ({ data }) => (
      <pre>
        {expandedRows?.length > 0 && (
          <UserReportDetails
            report_page={1}
            report_limit={limit}
            report_station={searchedData?.station ?? ""}
            report_job_status={searchedData?.job_status ?? ""}
            report_date_range={searchedData?.date_range ?? ""}
            report_hour_count={searchedData?.hour_count ?? ""}
            user={data.user_id}
            client_id={data.client_id}
            facility_id={data.facility_id}
            isTimeCount={isTimeCount}
            isTimeCountQaReview={isTimeCountQaReview}
            clients={searchedData?.clients}
            facilities={searchedData?.facilities}
          />
        )}
      </pre>
    ),
    [expandedRows, limit, searchedData, isTimeCount, isTimeCountQaReview]
  );
  const handleRowExpand = useCallback((row) => {
    setColorRow(row);
    // row.expandableRowExpanded = !row.expandableRowExpanded;
    const isRowExpanded = expandedRows.includes(row);

    setExpandedRows(isRowExpanded ? expandedRows.filter((r) => r !== row) : [row]);

  });
  const station_option = [
    // { value: 0, label: "All" },
    { value: 1, label: "My Work" },
    { value: 2, label: "QA Review" },
    { value: 3, label: "File Split" },
    { value: 4, label: "Snipping" },
  ]
  const hour_count_option = [
    { value: "true", label: "Count" },
    { value: "false", label: "Time" },
  ]

  const searchFormik = useFormik({
    initialValues: {
      search: "",
      staff: [],
      job_status: "",
      station: 0,
      date_range: [startOfMonth(new Date()), new Date()],
      created_date: `${moment(startOfMonth(new Date())).format('MM/DD/YYYY')} - ${moment(new Date()).format('MM/DD/YYYY')}`,
      hour_count: "",
      clients: [],
      facilities: "",
    },
    onSubmit: (values) => {
      console.log(values, 'valuesvaluesvaluesvalues')
      // values.staff = values?.staff.length > 0 ? values?.staff.map((i) => i?.value) : []

      searchData(values)
      handleResetDataTable()
    }
  })

  const columns = useMemo(
    () => [
      {
        id: 'S.NO',
        name: 'S.NO',
        selector: (row, index) => pageno == 1 ? index + 1 : (pageno - 1) * limit + index + 1,
        wrap: true,
        minWidth: "40px",
        grow: 0,

      },
      {
        id: 'USER NAME',
        name: 'USER NAME',
        selector: row => row?.username,
        cell: (row) =>

          <div>
            {row?.username &&

              <div className='d-flex flex-row justify-content-between align-items-center gap-2' style={{ maxWidth: '120px' }}>
                <div className='overflow-hidden text-truncate' style={{ maxWidth: "120px" }} onClick={() => handleRowExpand(row)}>
                  <Tooltip disableInteractive title={row?.username} placement="top" arrow>
                    <span className="text-truncate"> {row?.username}</span>
                  </Tooltip>
                </div>
              </div>

            }
          </div>
        ,


        left: true,
        wrap: true,
        reorder: false,
        grow: 0,
        expandOnRowClicked: true,
        minWidth: '130px'
      },
      {
        id: 'CLIENT NAME',
        name: 'CLIENT NAME',
        selector: row => row?.client_name,
        cell: (row) =>

          <div>
            {row?.client_name &&

              <div className='d-flex flex-row justify-content-between align-items-center gap-2' style={{ maxWidth: '120px' }}>
                <div className='overflow-hidden text-truncate' style={{ maxWidth: "120px" }} onClick={() => handleRowExpand(row)}>
                  <Tooltip disableInteractive title={row?.client_name} placement="top" arrow>
                    <span className="text-truncate"> {row?.client_name}</span>
                  </Tooltip>
                </div>
              </div>

            }
          </div>
        ,


        left: true,
        wrap: true,
        reorder: false,
        grow: 0,
        expandOnRowClicked: true,
        minWidth: '130px'
      },
      {
        id: 'FACILITY NAME',
        name: 'FACILITY NAME',
        selector: row => row?.facility_name,
        cell: (row) =>

          <div>
            {row?.facility_name &&

              <div className='d-flex flex-row justify-content-between align-items-center gap-2' style={{ width: '120px' }}>
                <div className='overflow-hidden text-truncate' style={{ width: "120px" }} onClick={() => handleRowExpand(row)}>
                  <Tooltip disableInteractive title={row?.facility_name} placement="top" arrow>
                    <span className="text-truncate"> {row?.facility_name}</span>
                  </Tooltip>
                </div>
              </div>

            }
          </div>
        ,


        left: true,
        wrap: true,
        reorder: false,
        grow: 0,
        expandOnRowClicked: true,
        minWidth: '130px'
      },
      {
        id: 'PAYMENT835',
        name: 'PAYMENT835',
        selector: row => row?.no_of_835,
        cell: (row) => <span onClick={() => handleRowExpand(row)}> {row?.no_of_835}</span>,

        right: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        minWidth: '80px'
      },
      {
        id: 'TIME',
        name: 'TIME',
        selector: row => row?.no_of_835_time,
        cell: (row) => <span onClick={() => handleRowExpand(row)}> {row?.no_of_835_time}</span>,

        left: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        omit: searchedData.hour_count == "" ? false : true,
        minWidth: '55px'
      },
      {
        id: 'CPH',
        name: 'CPH',
        selector: row => row?.no_of_835_cph,
        cell: (row) => <span onClick={() => handleRowExpand(row)}> {row?.no_of_835_cph}</span>,
        right: true,
        wrap: true,
        reorder: false,
        minWidth: '65px'

      },
      {
        id: 'EOB LITE/835 LITE',
        name: 'EOB LITE/835 LITE',
        selector: row => row?.no_835_lite,
        cell: (row) => <span onClick={() => handleRowExpand(row)}> {row?.no_835_lite}</span>,

        right: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        minWidth: '100px'
      },
      {
        id: 'TIME',
        name: 'TIME',
        selector: row => row?.no_835_lite_time,
        cell: (row) => <span onClick={() => handleRowExpand(row)}> {row?.no_835_lite_time}</span>,

        left: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        omit: searchedData.hour_count == "" ? false : true,
        minWidth: '55px'
      },
      {
        id: 'CPH',
        name: 'CPH',
        selector: row => row?.no_835_lite_cph,
        cell: (row) => <span onClick={() => handleRowExpand(row)}> {row?.no_835_lite_cph}</span>,
        right: true,
        wrap: true,
        reorder: false,
        minWidth: '65px'

      },
      {
        id: 'CORRES',
        name: 'CORRES',
        selector: row => row?.correspondence,
        cell: (row) => <span onClick={() => handleRowExpand(row)}> {row?.correspondence}</span>,

        right: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        minWidth: '59px',
        // maxWidth: '49px'
      },
      {
        id: 'TIME',
        name: 'TIME',
        selector: row => row?.correspondence_time,
        cell: (row) => <span onClick={() => handleRowExpand(row)}> {row?.correspondence_time}</span>,

        left: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        omit: searchedData.hour_count == "" ? false : true,
        minWidth: '55px'
      },
      {
        id: 'CPH',
        name: 'CPH',
        selector: row => row?.correspondence_cph,
        cell: (row) => <span onClick={() => handleRowExpand(row)}> {row?.correspondence_cph}</span>,
        right: true,
        wrap: true,
        reorder: false,
        minWidth: '65px'

      },
      {
        id: 'PAT PAY',
        name: 'PAT PAY',
        selector: row => row?.patient_pay,
        cell: (row) => <span onClick={() => handleRowExpand(row)}> {row?.patient_pay}</span>,

        right: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        minWidth: '59px',
      },
      {
        id: 'TIME',
        name: 'TIME',
        selector: row => row?.patient_pay_time,
        cell: (row) => <span onClick={() => handleRowExpand(row)}> {row?.patient_pay_time}</span>,

        left: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        omit: searchedData.hour_count == "" ? false : true,
        minWidth: '55px',
      },
      {
        id: 'CPH',
        name: 'CPH',
        selector: row => row?.patient_pay_cph,
        cell: (row) => <span onClick={() => handleRowExpand(row)}> {row?.patient_pay_cph}</span>,
        right: true,
        wrap: true,
        reorder: false,
        minWidth: '65px'

      },
      {
        id: 'PAT PAY 835',
        name: 'PAT PAY 835',
        selector: row => row?.patient_pay_835,
        cell: (row) => <span onClick={() => handleRowExpand(row)}> {row?.patient_pay_835}</span>,

        right: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        minWidth: '70px'
      },
      {
        id: 'TIME',
        name: 'TIME',
        selector: row => row?.patient_pay_835_time,
        cell: (row) => <span onClick={() => handleRowExpand(row)}> {row?.patient_pay_835_time}</span>,

        left: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        omit: searchedData.hour_count == "" ? false : true,
        minWidth: '55px'
      },
      {
        id: 'CPH',
        name: 'CPH',
        selector: row => row?.patient_pay_835_cph,
        cell: (row) => <span onClick={() => handleRowExpand(row)}> {row?.patient_pay_835_cph}</span>,
        right: true,
        wrap: true,
        reorder: false,
        minWidth: '65px'

      },

      {
        id: 'CORRES 835',
        name: 'CORRES 835',
        selector: row => row?.correspondence_835,
        cell: (row) => <span onClick={() => handleRowExpand(row)} > {row?.correspondence_835}</span>,

        right: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        minWidth: '70px'
      },
      {
        id: 'TIME',
        name: 'TIME',
        selector: row => row?.correspondence_835_time,
        cell: (row) => <span onClick={() => handleRowExpand(row)}> {row?.correspondence_835_time}</span>,

        left: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        omit: searchedData.hour_count == "" ? false : true,
        minWidth: '55px',
      },
      {
        id: 'CPH',
        name: 'CPH',
        selector: row => row?.correspondence_835_cph,
        cell: (row) => <span onClick={() => handleRowExpand(row)}> {row?.correspondence_835_cph}</span>,
        right: true,
        wrap: true,
        reorder: false,
        minWidth: '65px'

      },
      {
        id: 'REJECTED',
        name: 'REJECTED',
        selector: row => row?.rejected_count,
        cell: (row) => <span onClick={() => handleRowExpand(row)} > {row?.rejected_count}</span>,

        right: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        minWidth: '70px'
      },
      {
        id: 'TIME',
        name: 'TIME',
        selector: row => row?.rejected_time,
        cell: (row) => <span onClick={() => handleRowExpand(row)}> {row?.rejected_time}</span>,

        left: true,
        wrap: true,
        reorder: false,
        minWidth: '55px',
      },
      {
        id: 'JOB TOTAL',
        name: 'JOB TOTAL',
        selector: row => row?.total,
        cell: (row) => <span onClick={() => handleRowExpand(row)}> {row?.total}</span>,

        right: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        omit: isTimeCount,
        minWidth: '63px',

      },

      {
        id: 'MANUAL SPLIT',
        name: 'MANUAL SPLIT',
        selector: row => row?.file_split,
        cell: (row) => <span onClick={() => handleRowExpand(row)}> {row?.file_split}</span>,

        right: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        minWidth: '81px'
      },
      {
        id: 'TIME',
        name: 'TIME',
        selector: row => row?.file_split_time,
        cell: (row) => <span onClick={() => handleRowExpand(row)}> {row?.file_split_time}</span>,

        left: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        omit: searchedData.hour_count == "" ? false : true,
        minWidth: '55px'
      },
      {
        id: 'TOTAL TIME',
        name: 'TOTAL TIME',
        selector: row => row?.work_split_total_time,
        cell: (row) => <span onClick={() => handleRowExpand(row)}> {row?.work_split_total_time}</span>,

        left: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        omit: searchedData.hour_count == "" ? false : true,
        minWidth: '67px'
      },
      {
        id: 'IDLE',
        name: 'IDLE',
        selector: row => row?.idle,
        cell: (row) => <span onClick={() => handleRowExpand(row)}> {row?.idle}</span>,

        right: true,
        wrap: true,
        reorder: false,
        grow: 0,
        minWidth: '35px',
      },
      {
        id: 'IDLE TIME',
        name: 'IDLE TIME',
        selector: row => row?.idle_time,
        cell: (row) => <span onClick={() => handleRowExpand(row)}> {row?.idle_time}</span>,

        right: true,
        wrap: true,
        reorder: false,
        grow: 0,
        minWidth: '65px',
      },

      {
        id: '# CLAIMS',
        name: '# CLAIMS',
        selector: row => row?.claims,
        cell: (row) => <span onClick={() => handleRowExpand(row)}> {row?.claims}</span>,

        right: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        omit: isTimeCount,
        minWidth: '70px'

      },

      {
        id: '# SVC LINES',
        name: '# SVC LINES',
        selector: row => row?.servicelines,
        cell: (row) => <span onClick={() => handleRowExpand(row)}> {row?.servicelines}</span>,

        right: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        omit: isTimeCount,
        minWidth: '70px'

      },

      {
        id: 'CPH',
        name: 'CPH',
        selector: row => row?.cph,
        cell: (row) => <span onClick={() => handleRowExpand(row)}> {row?.cph}</span>,

        right: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        omit: isTimeCount,
        minWidth: '65px'

      },
      // {
      //   id: 'JOB TOTAL',
      //   name: 'JOB TOTAL',
      //   selector: row => row?.total,
      //   cell: (row) => <span onClick={() => handleRowExpand(row)}> {row?.total}</span>,
      //   
      //   right: true,
      //   wrap: true,
      //   reorder: false,
      //   grow: 0,
      //   omit: isTimeCount,
      //   minWidth: '150px'
      // },

    ],

    [handleRowExpand, limit, pageno, isTimeCount],
  );
  const timeColumns = useMemo(
    () => [
      {
        id: 'S.NO',
        name: 'S.NO',
        selector: (row, index) => pageno == 1 ? index + 1 : (pageno - 1) * limit + index + 1,
        wrap: true,
        width: "40px",
        // grow: 0,

      },
      {
        id: 'USER NAME',
        name: 'USER NAME',
        selector: row => row?.username,
        cell: (row) =>

          <div>
            {row?.username &&

              <div className='d-flex flex-row justify-content-between align-items-center gap-2' style={{ maxWidth: '110px' }}>
                <div className='overflow-hidden text-truncate' style={{ maxWidth: "110px" }} onClick={() => handleRowExpand(row)}>
                  <Tooltip disableInteractive title={row?.username} placement="top" arrow>
                    <span className="text-truncate"> {row?.username}</span>
                  </Tooltip>
                </div>
              </div>

            }
          </div>
        ,


        left: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        expandOnRowClicked: true,
        minWidth: '120px'
      },
      {
        id: 'CLIENT NAME',
        name: 'CLIENT NAME',
        selector: row => row?.client_name,
        cell: (row) =>

          <div>
            {row?.client_name &&

              <div className='d-flex flex-row justify-content-between align-items-center gap-2' style={{ maxWidth: '120px' }}>
                <div className='overflow-hidden text-truncate' style={{ maxWidth: "120px" }} onClick={() => handleRowExpand(row)}>
                  <Tooltip disableInteractive title={row?.client_name} placement="top" arrow>
                    <span className="text-truncate"> {row?.client_name}</span>
                  </Tooltip>
                </div>
              </div>

            }
          </div>
        ,


        left: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        expandOnRowClicked: true,
        minWidth: '120px'
      },
      {
        id: 'FACILITY NAME',
        name: 'FACILITY NAME',
        selector: row => row?.facility_name,
        cell: (row) =>

          <div>
            {row?.facility_name &&

              <div className='d-flex flex-row justify-content-between align-items-center gap-2' style={{ width: '110px' }}>
                <div className='overflow-hidden text-truncate' style={{ width: "110px" }} onClick={() => handleRowExpand(row)}>
                  <Tooltip disableInteractive title={row?.facility_name} placement="top" arrow>
                    <span className="text-truncate"> {row?.facility_name}</span>
                  </Tooltip>
                </div>
              </div>

            }
          </div>
        ,


        left: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        expandOnRowClicked: true,
        minWidth: '120px'
      },
      {
        id: 'PAYMENT835',
        name: 'PAYMENT835',
        selector: row => row?.no_of_835,
        cell: (row) => <span onClick={() => handleRowExpand(row)}> {row?.no_of_835}</span>,

        right: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        minWdth: '50px'
      },

      {
        id: 'EOB LITE/835 LITE',
        name: 'EOB LITE/835 LITE',
        selector: row => row?.no_835_lite,
        cell: (row) => <span onClick={() => handleRowExpand(row)}> {row?.no_835_lite}</span>,

        right: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        minWdth: '50px'
      },

      {
        id: 'CORRES',
        name: 'CORRES',
        selector: row => row?.correspondence,
        cell: (row) => <span onClick={() => handleRowExpand(row)}> {row?.correspondence}</span>,

        right: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        minWdth: '50px'
      },

      {
        id: 'PAT PAY',
        name: 'PAT PAY',
        selector: row => row?.patient_pay,
        cell: (row) => <span onClick={() => handleRowExpand(row)}> {row?.patient_pay}</span>,

        right: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        minWdth: '50px'
      },

      {
        id: 'PAT PAY 835',
        name: 'PAT PAY 835',
        selector: row => row?.patient_pay_835,
        cell: (row) => <span onClick={() => handleRowExpand(row)}> {row?.patient_pay_835}</span>,

        right: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        minWdth: '50px'
      },


      {
        id: 'CORRES 835',
        name: 'CORRES 835',
        selector: row => row?.correspondence_835,
        cell: (row) => <span onClick={() => handleRowExpand(row)} > {row?.correspondence_835}</span>,

        right: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        minWdth: '50px'
      },


      {
        id: 'MANUAL SPLIT',
        name: 'MANUAL SPLIT',
        selector: row => row?.file_split,
        cell: (row) => <span onClick={() => handleRowExpand(row)}> {row?.file_split}</span>,

        right: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        minWdth: '50px'
      },

      {
        id: 'REJECTED',
        name: 'REJECTED',
        selector: row => row?.rejected_time,
        cell: (row) => <span onClick={() => handleRowExpand(row)}> {row?.rejected_time}</span>,

        right: true,
        wrap: true,
        reorder: false,
        minWdth: '50px'

      },
      {
        id: 'JOB TOTAL',
        name: 'JOB TOTAL',
        selector: row => row?.total,
        cell: (row) => <span onClick={() => handleRowExpand(row)}> {row?.total}</span>,

        right: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        minWdth: '50px'
        // minWidth: '125px'

      },
      {
        id: 'IDLE',
        name: 'IDLE',
        selector: row => row?.idle,
        cell: (row) => <span onClick={() => handleRowExpand(row)}> {row?.idle}</span>,

        right: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        minWdth: '50px'
      },



    ],

    [handleRowExpand, limit, pageno, isTimeCount],
  );
  const countColumns = useMemo(
    () => [
      {
        id: 'S.NO',
        name: 'S.NO',
        selector: (row, index) => pageno == 1 ? index + 1 : (pageno - 1) * limit + index + 1,
        wrap: true,
        width: "40px",
        grow: 0,

      },
      {
        id: 'USER NAME',
        name: 'USER NAME',
        selector: row => row?.username,
        cell: (row) =>

          <div>
            {row?.username &&

              <div className='d-flex flex-row justify-content-between align-items-center gap-2' style={{ maxWidth: '120px' }}>
                <div className='overflow-hidden text-truncate' style={{ maxWidth: "120px" }} onClick={() => handleRowExpand(row)}>
                  <Tooltip disableInteractive title={row?.username} placement="top" arrow>
                    <span className="text-truncate"> {row?.username}</span>
                  </Tooltip>
                </div>
              </div>

            }
          </div>
        ,


        left: true,
        wrap: true,
        reorder: false,
        grow: 0,
        expandOnRowClicked: true,
        minWidth: '150px'
      },
      {
        id: 'CLIENT NAME',
        name: 'CLIENT NAME',
        selector: row => row?.client_name,
        cell: (row) =>

          <div>
            {row?.client_name &&

              <div className='d-flex flex-row justify-content-between align-items-center gap-2' style={{ maxWidth: '120px' }}>
                <div className='overflow-hidden text-truncate' style={{ maxWidth: "120px" }} onClick={() => handleRowExpand(row)}>
                  <Tooltip disableInteractive title={row?.client_name} placement="top" arrow>
                    <span className="text-truncate"> {row?.client_name}</span>
                  </Tooltip>
                </div>
              </div>

            }
          </div>
        ,


        left: true,
        wrap: true,
        reorder: false,
        grow: 0,
        expandOnRowClicked: true,
        minWidth: '130px'
      },
      {
        id: 'FACILITY NAME',
        name: 'FACILITY NAME',
        selector: row => row?.facility_name,
        cell: (row) =>

          <div>
            {row?.facility_name &&

              <div className='d-flex flex-row justify-content-between align-items-center gap-2' style={{ maxWidth: '110px' }}>
                <div className='overflow-hidden text-truncate' style={{ maxWidth: "110px" }} onClick={() => handleRowExpand(row)}>
                  <Tooltip disableInteractive title={row?.facility_name} placement="top" arrow>
                    <span className="text-truncate"> {row?.facility_name}</span>
                  </Tooltip>
                </div>
              </div>

            }
          </div>
        ,


        left: true,
        wrap: true,
        reorder: false,
        grow: 0,
        expandOnRowClicked: true,
        minWidth: '120px'
      },
      {
        id: 'PAYMENT835',
        name: 'PAYMENT835',
        selector: row => row?.no_of_835,
        cell: (row) => <span onClick={() => handleRowExpand(row)}> {row?.no_of_835}</span>,

        right: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        minWidth: '80px'
      },
      {
        id: 'CPH',
        name: 'CPH',
        selector: row => row?.no_of_835_cph,
        cell: (row) => <span onClick={() => handleRowExpand(row)}> {row?.no_of_835_cph}</span>,
        right: true,
        wrap: true,
        reorder: false,
        minWidth: '65px'

      },
      {
        id: 'EOB LITE/835 LITE',
        name: 'EOB LITE/835 LITE',
        selector: row => row?.no_835_lite,
        cell: (row) => <span onClick={() => handleRowExpand(row)}> {row?.no_835_lite}</span>,

        right: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        minWidth: '98px'
      },
      {
        id: 'CPH',
        name: 'CPH',
        selector: row => row?.no_835_lite_cph,
        cell: (row) => <span onClick={() => handleRowExpand(row)}> {row?.no_835_lite_cph}</span>,
        right: true,
        wrap: true,
        reorder: false,
        minWidth: '65px'

      },
      {
        id: 'CORRES',
        name: 'CORRES',
        selector: row => row?.correspondence,
        cell: (row) => <span onClick={() => handleRowExpand(row)}> {row?.correspondence}</span>,

        right: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        minWidth: '50px'
      },
      {
        id: 'CPH',
        name: 'CPH',
        selector: row => row?.correspondence_cph,
        cell: (row) => <span onClick={() => handleRowExpand(row)}> {row?.correspondence_cph}</span>,
        right: true,
        wrap: true,
        reorder: false,
        minWidth: '65px'

      },
      {
        id: 'PAT PAY',
        name: 'PAT PAY',
        selector: row => row?.patient_pay,
        cell: (row) => <span onClick={() => handleRowExpand(row)}> {row?.patient_pay}</span>,

        right: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        minWidth: '50px'
      },
      {
        id: 'CPH',
        name: 'CPH',
        selector: row => row?.patient_pay_cph,
        cell: (row) => <span onClick={() => handleRowExpand(row)}> {row?.patient_pay_cph}</span>,
        right: true,
        wrap: true,
        reorder: false,
        minWidth: '65px'

      },
      {
        id: 'PAT PAY 835',
        name: 'PAT PAY 835',
        selector: row => row?.patient_pay_835,
        cell: (row) => <span onClick={() => handleRowExpand(row)}> {row?.patient_pay_835}</span>,

        right: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        minWidth: '50px'
      },
      {
        id: 'CPH',
        name: 'CPH',
        selector: row => row?.patient_pay_835_cph,
        cell: (row) => <span onClick={() => handleRowExpand(row)}> {row?.patient_pay_835_cph}</span>,
        right: true,
        wrap: true,
        reorder: false,
        minWidth: '65px'

      },

      {
        id: 'CORRES 835',
        name: 'CORRES 835',
        selector: row => row?.correspondence_835,
        cell: (row) => <span onClick={() => handleRowExpand(row)} > {row?.correspondence_835}</span>,

        right: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        minWidth: '50px'
        // minWidth: '85px',

      },
      {
        id: 'CPH',
        name: 'CPH',
        selector: row => row?.correspondence_835_cph,
        cell: (row) => <span onClick={() => handleRowExpand(row)}> {row?.correspondence_835_cph}</span>,
        right: true,
        wrap: true,
        reorder: false,
        minWidth: '65px'

      },
      {
        id: 'REJECTED',
        name: 'REJECTED',
        selector: row => row?.rejected_count,
        cell: (row) => <span onClick={() => handleRowExpand(row)}> {row?.rejected_count}</span>,

        right: true,
        wrap: true,
        reorder: false,
        minWdth: '50px',
        maxWidth: '50px',
      },
      {
        id: 'JOB TOTAL',
        name: 'JOB TOTAL',
        selector: row => row?.total,
        cell: (row) => <span onClick={() => handleRowExpand(row)}> {row?.total}</span>,

        right: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        minWidth: '50px',



      },

      {
        id: 'MANUAL SPLIT',
        name: 'MANUAL SPLIT',
        selector: row => row?.file_split,
        cell: (row) => <span onClick={() => handleRowExpand(row)}> {row?.file_split}</span>,

        right: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        minWidth: '80px'
        // minWidth: '85px',

      },


      {
        id: 'IDLE',
        name: 'IDLE',
        selector: row => row?.idle,
        cell: (row) => <span onClick={() => handleRowExpand(row)}> {row?.idle}</span>,

        right: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        minWidth: '50px'
        // minWidth: '45px',

      },


      {
        id: '# CLAIMS',
        name: '# CLAIMS',
        selector: row => row?.claims,
        cell: (row) => <span onClick={() => handleRowExpand(row)}> {row?.claims}</span>,

        right: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        minWidth: '50px'
        // minWidth: '65px',


      },

      {
        id: '# SVC LINES',
        name: '# SVC LINES',
        selector: row => row?.servicelines,
        cell: (row) => <span onClick={() => handleRowExpand(row)}> {row?.servicelines}</span>,

        right: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        minWidth: '50px'
        // minWidth: '75px',


      },

      {
        id: 'CPH',
        name: 'CPH',
        selector: row => row?.cph,
        cell: (row) => <span onClick={() => handleRowExpand(row)}> {row?.cph}</span>,

        right: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        minWidth: '50px'
        // minWidth: '75px',


      },


    ],

    [handleRowExpand, limit, pageno, isTimeCount],
  );

  var i = 0;
  const conditionalRowStyles = [

    {
      when: row => colorRow.id == row.id ? true : false,
      style: (row, index) => ({
        backgroundColor: colorRow?.id == row.id ? '#dbf4f5' : '#f2f2f2',
        color: 'black',
        // fontWeight: '550'
      }),
    },


  ];
  const handleRowClick = (row) => {
    setColorRow(row);
    setExpandedRows([])
  };

  console.log(searchFormik.values.station !== 2, 'searchFormik.station');

  const [datetime, setDateTime] = useState([]);

  const handleOnDateTimeChange = ({ name, value }) => {

    let created_date = ""
    if (value !== null) {
      const startDate = value[0];
      const endDate = value[1];
      // Check if the start date is after the end date, and swap the dates if needed
      let isGreater = false
      if (startDate > endDate) {
        isGreater = true
      }
      const datefrom = moment(isGreater ? endDate : startDate).format('MM/DD/YYYY')
      const dateto = moment(isGreater ? startDate : endDate).format('MM/DD/YYYY')

      created_date = datefrom + " - " + dateto
    } else {
      setSearchedData({
        ...searchedData,
        created_date: "",
        date_range: "",
      })
      fetchData(1, limit, searchedData?.station, jobStatus, "", searchedData?.hour_count, searchedData?.staff, searchedData?.clients ?? "", searchedData?.facilities ?? "");

    }

    setDateTime(created_date)

    searchFormik.setValues({ ...searchFormik.values, created_date, date_range: value ?? "" })

  }

  const searchData = ({ station, job_status, created_date, hour_count, staff, clients, facilities }) => {
    const client_ids = selectValue?.map(i => i.value)
    const facility_ids = selectedFacility?.map(i => i.value)

    setSearchedData({
      job_status: job_status,
      station: station,
      date_range: created_date ?? "",
      hour_count: hour_count ?? "",
      staff: staff,
      clients: client_ids,
      facilities: facility_ids,
    })
    fetchData(1, limit, station, jobStatus, created_date ?? "", hour_count, staff, client_ids, facility_ids)
  }

  const handleChangeSelect = async (e, name, setArgState) => {
    setExpandedRows([])
    let selectedId = []
    let selectedOptons = []

    e.map((option) => {
      selectedId.push(option.value)
      selectedOptons.push({ option })
    })

    // searchFormik.setValues({ ...searchFormik.values, [name]: selectedId })
    // e.map((el) => el.value
    // searchFormik.setFieldValue([name], selectedId.includes('all') ? ['all'] : selectedId)
    searchFormik.setValues({
      ...searchFormik.values, job_status: selectedId.includes('all') ? ['all'] : selectedId
    })
    setArgState(selectedId.includes('all') ? ['all'] : selectedId)

  }



  const DropdownIndicator = ({ state, ...props }) => {
    console.log(state)
    return (
      <components.DropdownIndicator {...props}>

        {state?.length > 1 ? (
          <TbGridDots style={{ fontSize: "1.5em" }} />
        ) : (
          <HiChevronDown style={{ fontSize: "1.5em" }} />
        )}



      </components.DropdownIndicator>
    );
  };
  const handleResetDataTable = () => {
    setRerenderDataTable(true)
    // setTimeout(() => {
    //   setRerenderDataTable(false)
    // }, 200);
  }
  const [isFocusedStatus, setIsFocusedStatus] = useState(false);
  const [loader, setLoader] = useState(false);
  const [staffList, setStaffList] = useState([]);
  const [isFocusedStaff, setIsFocusedStaff] = useState(false);

  const handleDownload = (type = 'summury') => {
    setLoader(true)
    if (type === 'summury') {
      downloadUserReportSummery(1, limit, searchedData?.station, searchedData?.job_status, searchedData?.date_range, searchedData?.hour_count, searchedData?.staff, searchedData?.clients ?? "", searchedData?.facilities ?? "").then((response) => {
        setLoader(false)
        downloadXl(response?.data?.export_file)
      });
    } else {
      downloadUserReportSingle(1, limit, searchedData?.station, searchedData?.job_status, searchedData?.date_range, searchedData?.hour_count, searchedData?.staff, searchedData?.clients ?? "", searchedData?.facilities ?? "").then((response) => {
        setLoader(false)
        downloadXl(response?.data?.export_file)
      });
    }
  }
  useEffect(() => {
    fetchData(1, limit, searchedData?.station, searchedData?.job_status, searchedData?.date_range, searchedData?.hour_count, searchedData?.staff, searchedData?.clients ?? "", searchedData?.facilities ?? "");
    getAllClientDropdown().then((response) => {
      if (response?.status) {
        const data = response?.data
        getFacilityDropdown(data.map((i) => i.value)).then((response) => {
          if (response && response.status) {
            const feciltyIdArr = []
            const FullFacilities = []
            setFacilities(response.data.facilities?.map(result => {
              result?.facilities?.map(item => feciltyIdArr.push(item.value));
              FullFacilities.push({ options: result?.facilities, label: result?.client, id: result?.pk })
              return { options: result?.facilities, label: result?.client, id: result?.pk }

            }))

            setFullFacilities(FullFacilities)
            const newArray = selectedFacility.filter(item => feciltyIdArr.includes(item.value));
            setSelectedFacility(newArray)
          }
        });

        setClients(data)
      }
    })
    getUserlist(1).then((response) => {
      if (response && response.status && response.data && response.data.results) {
        setStaffList(response.data.results)
      }
    });
  }, [])

  // useEffect(() => {
  //   if (selectValue && selectValue.length > 0) {

  //     getFacilityDropdown(selectValue?.map((value) => value?.value)).then((response) => {
  //       if (response && response.status) {
  //         const feciltyIdArr = []
  //         setFacilities(response.data.facilities?.map(result => {
  //           console.log(result?.facilities, 'EEEEE')
  //           result?.facilities?.map(item => feciltyIdArr.push(item.value));
  //           return { options: result?.facilities, label: result?.client }

  //         }))
  //         const newArray = selectedFacility.filter(item => feciltyIdArr.includes(item.value));
  //         setSelectedFacility(newArray)
  //         searchFormik.setValues({
  //           ...searchFormik.values, facilities: newArray.length > 0 ? newArray.map(i => i.value) : []
  //         })
  //       }
  //     });

  //   }

  // }, [selectValue])
  const setFacilityOptions = (clientId) => {
    getFacilityDropdown(clientId).then((response) => {
      if (response && response.status) {
        setFacilities(response.data.facilities?.map(result => {
          return { options: result?.facilities, label: result?.client }

        }))

      }
    });
  }

  const [fullFacilities, setFullFacilities] = useState([])

  const checkSelectedFecilityInitialClient = (selectedOptions) => {
    // Initialize an empty result array and a set to track unique labels
    var result = [...selectValue];
    var uniqueLabels = new Set();
    const clientId = selectValue?.map((value) => String(value?.value))

    // Loop through selected options
    for (var i = 0; i < selectedOptions.length; i++) {
      var selectedOption = selectedOptions[i];

      // Loop through the list of dictionaries to find matching options
      for (var j = 0; j < fullFacilities.length; j++) {
        var options = fullFacilities[j].options;
        for (var k = 0; k < options.length; k++) {
          if (options[k].value === selectedOption.value && options[k].label === selectedOption.label) {
            // Check if the label is unique before adding it to the result
            console.log(clientId, fullFacilities[j].id)
            if (!uniqueLabels.has(fullFacilities[j].label) && !clientId.includes(String(fullFacilities[j].id))) {
              result.push({
                "value": Number(fullFacilities[j].id),
                "label": fullFacilities[j].label,
              });
              uniqueLabels.add(fullFacilities[j].label);
            }
            break;
          }
        }
      }
    }
    console.log("Result:", result);
    return result
  }
  // const handleSelectChange = (val, name = null) => {
  //   console.log(val)
  //   let selectedOptions = [];
  //   let selectedIds = [];

  //   if (val && val?.length > 0) {

  //     val?.map((option) => {
  //       selectedIds.push(option.value)
  //       selectedOptions.push(option)
  //     })

  //   } else {

  //     setSelectedFacility([])
  //     setSelectedFacilityIds([])
  //   }

  //   if (name == "facility") {
  //     const initiaClientsOptions = checkSelectedFecilityInitialClient(selectedOptions)
  //     // const clientIds = initiaClientsOptions?.map((value) => value?.value)
  //     setSelectedFacility(selectedOptions)
  //     setSelectedFacilityIds(selectedIds)
  //     setSelectValue(initiaClientsOptions)
  //     // setFacilityOptions(clientIds)
  //   } else {
  //     // setClients(val.includes('all') ? ['all'] : selectedId)
  //     if (!(val && val?.length > 0)) {
  //       if (clients && clients.length > 0) {
  //         const clientId = clients?.map((value) => value?.value)
  //         setFacilityOptions(clientId)
  //       }

  //     } else {
  //       getFacilityDropdown(selectedIds).then((response) => {
  //         if (response && response.status) {
  //           const feciltyIdArr = []
  //           setFacilities(response.data.facilities?.map(result => {
  //             console.log(result?.facilities, 'EEEEE')
  //             result?.facilities?.map(item => feciltyIdArr.push(item.value));
  //             return { options: result?.facilities, label: result?.client }

  //           }))

  //           const newArray = selectedFacility.filter(item => feciltyIdArr.includes(item.value));
  //           setSelectedFacility(newArray)
  //         }
  //       });

  //     }

  //     setSelectValue(selectedOptions)
  //     setSelectIds(selectedIds)
  //     // setSelectedFacility([])
  //   }
  // };


  const handleSelectChange = (val, name = null) => {
    let selectedOptions = [];
    let selectedIds = [];
    if (val && val?.length > 0) {

      val?.map((option) => {
        selectedIds.push(option.value)
        selectedOptions.push(option)
      })
    }
    if (name == 'facility') {
      setSelectedFacility(selectedOptions)
      setSelectedFacilityIds(selectedIds)
    }
    else if (name == 'client') {
      setSelectedFacility([])
      setFacilities([])
      setSelectValue(selectedOptions)
      setSelectIds(selectedIds)
      const feciltyIdArr = []
      console.log(clients)
      let clientoptions = []
      clients?.map(client => {
        clientoptions.push(client?.value)
      })
      console.log('selectedIds', selectedIds)
      getFacilityDropdown(selectedIds?.length ? selectedIds : clientoptions).then((response) => {
        if (response && response.status) {
          setFacilities(response.data.facilities?.map(result => {
            result?.facilities?.map(item => feciltyIdArr.push(item.value));
            return { options: result?.facilities, label: result?.client }

          }))
          const newArray = selectedFacility.filter(item => feciltyIdArr.includes(item.value));
          setSelectedFacility(selectedIds?.length ? newArray : [])
        }
      });
    }
  }

  const formatOptionLabel = ({ value, label, is_logged_in }) => (
    <div className='d-flex flex-row justify-content-between align-items-center'>
      <div>{label}</div>
      {is_logged_in ?
        <div className='ms-2 dot' />

        :
        <div></div>
      }
    </div>
  );
  const handleOnSelectChange = async (e) => {
    await searchFormik.setValues({
      ...searchFormik.values,
      staff: e.length > 0 ? e.map((i) => i?.value) : []

    })
  }
  const [users, setUsers] = useState([])
  let typingTimer;
  const loadOptions = inputValue => {
    return new Promise((resolve, reject) => {

      clearTimeout(typingTimer);
      typingTimer = setTimeout(() => {
        if (inputValue.length >= 3) {
          getUserlist(1, inputValue.toUpperCase()).then((response) => {
            if (response && response.status && response.data && response.data.results) {
              setUsers(response.data.results)

              resolve(response.data.results);

            }
          });
        }
        else {
          resolve([]);
        }

      }, 300);
    });
  };
  console.log(searchFormik?.values)
  return (
    <React.Fragment>
      {/* <Header /> */}
      <Sidebar />
      <main id="main" className="main">

        <div className="pagetitle tab-fixed-header">
          <h1 id='page-title'>User Report</h1>
        </div>

        <section className="section mt-5 ">
          <div className="row align-items-top py-2 gap-2 gap-md-5">
            <div className="col-lg-12">
              <div className="card mb-xl-8" style={{ height: "92vh" }}>

                <Form onSubmit={searchFormik.handleSubmit} >
                  <div className=" ">
                    <div className="px-1 py-1 d-flex flex-row justify-content-start gap-2 align-items-center flex-wrap">

                      <div>
                        <Select
                          isMulti
                          tabSelectsValue={false}
                          closeMenuOnSelect={false} // Keep the menu open after selecting an option
                          hideSelectedOptions={false}
                          options={staffList}
                          className="select-search-835-list"
                          formatOptionLabel={formatOptionLabel}
                          components={{ DropdownIndicator: (props) => <DropdownIndicator {...props} state={searchFormik?.values?.staff} /> }}
                          classNamePrefix="select"
                          placeholder="Users"
                          // values={selectValue}
                          menuPlacement="auto"
                          maxMenuHeight={300}
                          isClearable
                          name="staff"
                          onFocus={() => setIsFocusedStaff(true)}
                          onBlur={() => setIsFocusedStaff(false)}
                          onChange={(e) => {
                            searchFormik.setFieldValue("staff", e.map(i => i.value))
                          }}
                          // value={status.filter((item) => jobStatus?.includes(item.value))}
                          styles={{
                            container: (styles, state) => ({
                              ...styles,
                              borderRadius: "9px",
                            }),
                            control: (styles, state) => ({
                              ...styles,
                              backgroundColor: "hsl(204deg 33.33% 97.06%)",
                              borderStyle: "none", boxShadow: "none",
                              borderRadius: "10px",
                              maxHeight: "30px",
                            }),
                            indicatorSeparator: styles => ({
                              ...styles, width: "0px", minHeight: "20px",
                              maxHeight: "30px",
                            }),

                            valueContainer: (styles, state) => ({
                              ...styles,
                              maxHeight: "30px",
                              overflow: 'scroll',
                            }),
                            option: (styles, state) => ({
                              ...styles,
                              backgroundColor: state.isSelected ? '#d9f4f5' : 'inherit',
                              color: state.isSelected ? '#000000' : 'inherit',
                              '&:hover': { backgroundColor: state.isSelected ? '#d9f4f5' : 'rgb(222, 235, 255)' }

                            })
                          }} />
                      </div>
                      <div className="">
                        <Select
                          isMulti
                          tabSelectsValue={false}
                          closeMenuOnSelect={false} // Keep the menu open after selecting an option
                          hideSelectedOptions={false}
                          options={searchFormik?.values?.job_status.includes('all') ? status.filter((i) => i === 'all') : status}
                          className="select-search-835-list"
                          formatOptionLabel={formatOptionLabel}
                          components={{ DropdownIndicator: (props) => <DropdownIndicator {...props} state={jobStatus} /> }}
                          classNamePrefix="select"
                          placeholder="Status"
                          // values={selectValue}
                          menuPlacement="auto"
                          maxMenuHeight={300}
                          isClearable
                          name="job_status"
                          onFocus={() => setIsFocusedStatus(true)}
                          onBlur={() => setIsFocusedStatus(false)}
                          onChange={(e) => { handleChangeSelect(e, 'job_status', setJobStatus); }}
                          value={status.filter((item) => jobStatus?.includes(item.value))}
                          styles={{
                            container: (styles, state) => ({
                              ...styles,
                              borderRadius: "9px",
                            }),
                            control: (styles, state) => ({
                              ...styles,
                              backgroundColor: "hsl(204deg 33.33% 97.06%)",
                              borderStyle: "none", boxShadow: "none",
                              borderRadius: "10px",
                              maxHeight: "30px",
                            }),
                            indicatorSeparator: styles => ({
                              ...styles, width: "0px", minHeight: "20px",
                              maxHeight: "30px",
                            }),

                            valueContainer: (styles, state) => ({
                              ...styles,
                              maxHeight: "30px",
                              overflow: 'scroll',
                            }),
                            option: (styles, state) => ({
                              ...styles,
                              backgroundColor: state.isSelected ? '#d9f4f5' : 'inherit',
                              color: state.isSelected ? '#000000' : 'inherit',
                              '&:hover': { backgroundColor: state.isSelected ? '#d9f4f5' : 'rgb(222, 235, 255)' }

                            })
                          }} />
                      </div>

                      <div className='small-select-station'>

                        <SingleSelect formik={searchFormik} placeholder="Station" name="station" list={station_option} />

                      </div>
                      <div className='small-select-container'>

                        <SingleSelect formik={searchFormik} placeholder="Time/Count" name="hour_count" list={hour_count_option} />

                      </div>
                      <div>
                        <Select
                          ref={clientRef}

                          isMulti
                          options={clients}
                          value={selectValue}
                          className=""
                          classNamePrefix="select"
                          placeholder="Client"
                          menuPlacement="auto"
                          maxMenuHeight={300}
                          components={{ DropdownIndicator: (props) => <DropdownIndicator {...props} state={selectValue} /> }}
                          hideSelectedOptions={false}
                          isClearable
                          name="client"
                          onChange={(val) => handleSelectChange(val, 'client')}
                          tabSelectsValue={false}

                          onFocus={() => setIsFocusedClient(true)}
                          onBlur={() => setIsFocusedClient(false)}
                          styles={{
                            container: (styles, state) => ({
                              ...styles,
                              borderRadius: "9px",
                            }),
                            control: (styles, state) => ({
                              ...styles,
                              backgroundColor: "hsl(204deg 33.33% 97.06%)",
                              borderStyle: "none", boxShadow: "none",
                              borderRadius: "10px",
                              maxHeight: "30px",
                            }),
                            indicatorSeparator: styles => ({
                              ...styles, width: "0px",
                              minHeight: "20px",
                              maxHeight: "30px",
                            }),

                            valueContainer: (styles, state) => ({
                              ...styles,
                              maxHeight: "30px",
                              overflow: 'scroll',
                            }),
                            option: (styles, state) => ({
                              ...styles,
                              backgroundColor: state.isSelected ? '#d9f4f5' : 'inherit',
                              color: state.isSelected ? '#000000' : 'inherit',
                              '&:hover': { backgroundColor: state.isSelected ? '#d9f4f5' : 'rgb(222, 235, 255)' }

                            })
                          }}
                          menuPortalTarget={document.body}
                          closeMenuOnSelect={false} // Keep the menu open after selecting an option

                        />
                      </div>
                      <div>
                        <Select
                          ref={facilityRef}
                          options={facilities}
                          className=""
                          classNamePrefix="select"
                          placeholder="Facility"
                          menuPlacement="auto"
                          maxMenuHeight={300}
                          isClearable
                          isMulti
                          name="facility"
                          value={selectedFacility}
                          components={{ DropdownIndicator: (props) => <DropdownIndicator {...props} state={selectedFacility} /> }}
                          hideSelectedOptions={false}
                          tabSelectsValue={false}


                          onChange={(val) => handleSelectChange(val, 'facility')}
                          onFocus={() => setIsFocusedFacility(true)}
                          onBlur={() => setIsFocusedFacility(false)}
                          // styles={{
                          //     container: (styles, state) => ({
                          //         ...styles,
                          //         borderRadius: "15px",
                          //     }),
                          //     control: (styles, state) => ({
                          //         ...styles,
                          //         backgroundColor: "#fff",
                          //         borderStyle: "none", boxShadow: "none",
                          //         height: "26px",
                          //         minHeight: "0px",
                          //         borderRadius: "7px",
                          //         maxHeight: state.isFocused ? "auto" : "30px",
                          //     }),
                          //     indicatorsContainer: styles => ({
                          //         ...styles,
                          //         height: "28px",
                          //     }),
                          //     indicatorSeparator: styles => ({
                          //         ...styles, width: "0px", minHeight: "20px"
                          //     }),
                          //     valueContainer: (styles, state) => ({
                          //         ...styles,
                          //         marginBottom: "89px",
                          //         maxHeight: "30px",
                          //     }),
                          //     option: (styles, { isFocused }) => ({
                          //         ...styles,
                          //         padding: "6px 12px", // Adjust the padding as per your preference
                          //         fontSize: '13px'
                          //     }),
                          // }}
                          styles={{
                            container: (styles, state) => ({
                              ...styles,
                              borderRadius: "9px",
                            }),
                            control: (styles, state) => ({
                              ...styles,
                              backgroundColor: "hsl(204deg 33.33% 97.06%)",
                              borderStyle: "none", boxShadow: "none",
                              borderRadius: "10px",
                              maxHeight: "30px",
                            }),
                            indicatorSeparator: styles => ({
                              ...styles, width: "0px",
                              minHeight: "20px",
                              maxHeight: "30px",
                            }),

                            valueContainer: (styles, state) => ({
                              ...styles,
                              maxHeight: "30px",
                              overflow: 'scroll',
                            }),
                            option: (styles, state) => ({
                              ...styles,
                              backgroundColor: state.isSelected ? '#d9f4f5' : 'inherit',
                              color: state.isSelected ? '#000000' : 'inherit',
                              '&:hover': { backgroundColor: state.isSelected ? '#d9f4f5' : 'rgb(222, 235, 255)' }

                            })
                          }}
                          menuPortalTarget={document.body}
                          closeMenuOnSelect={false} // Keep the menu open after selecting an option

                        />
                      </div>
                      <Tooltip disableInteractive title={"Processed Date"} placement="top" arrow>
                        <div className="correspondenceDatePicker">
                          <Stack direction="column" spacing={8} alignItems="flex-start">
                            <DateRangePicker
                              // style={{ backgroundColor: "#000" }}
                              ranges={predefinedBottomRanges}
                              cleanable={false}
                              placement="auto"
                              appearance="default" format={"MM/dd/yyyy"}
                              className="form-date-control form-control-solid b-r-1 text-dark"
                              placeholder={"MM/DD/YYYY - MM/DD/YYYY"}
                              name="date_range"
                              onChange={(e) => { setExpandedRows([]); handleOnDateTimeChange({ name: 'date_range', value: e }) }}
                              value={searchFormik?.values?.date_range}
                            />
                          </Stack>
                        </div>
                      </Tooltip>


                      <div>
                        <Tooltip disableInteractive title={"Search"} placement="top" arrow>
                          <Button className="btn btn-light btn-active-primary" type="submit">
                            <span className="svg-icon svg-icon-3">
                              <BiSearch />
                            </span>
                          </Button>
                        </Tooltip>
                      </div>
                      {isHavePermission("export_user_report_management") &&
                        // <div>
                        //   <Tooltip disableInteractive title={"Download"} placement="top" arrow>
                        //     {!loader ?
                        //       <Button onClick={() => { handleDownload() }} className="btn btn-light btn-active-primary" >
                        //         <span className="svg-icon svg-icon-3">
                        //           <BsCloudDownloadFill />
                        //         </span>

                        //       </Button> :
                        //       <Button className="btn btn-light btn-active-primary" >
                        //         <span className="svg-icon svg-icon-3">
                        //           <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        //         </span>

                        //       </Button>}
                        //   </Tooltip>

                        // </div>
                        <DownloadDropDown loader={loader} handleDownload={handleDownload} />
                      }
                    </div>
                  </div>

                </Form>


                <div className="card-body p-common">
                  <div className="table-responsive">
                    <div className="dashboardtable">
                      {/* {!reRenderDataTable && */}
                      <DataTable
                        paginationDefaultPage={pageno}

                        className="custom-datatable"
                        fixedHeader={true}
                        progressPending={loading}
                        data={data?.results}
                        columns={searchedData.hour_count === "false" ? timeColumns : searchedData.hour_count === "true" ? countColumns : columns}
                        pointerOnHover={true}
                        pagination
                        paginationServer
                        onRowClicked={handleRowExpand}
                        // highlightOnHover
                        paginationTotalRows={totalRows}
                        paginationPerPage={limit}
                        paginationRowsPerPageOptions={[25, 50, 100, 150, 200]}
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                        conditionalRowStyles={conditionalRowStyles}
                        theme="solarized"
                        customStyles={datatableCustomStyles}
                        striped={true}
                        expandableRows
                        expandOnRowClicked={false}
                        onRowExpandToggled={handleRowExpand}
                        expandableRowsComponent={ExpandedComponent}
                        expandableRowsHideExpander
                        expandableRowExpanded={(row) => expandedRows.includes(row)}

                      />
                      {/* } */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>



      </main>
    </React.Fragment>
  )
}
export default UserReport




