import React, { useEffect, useState } from 'react'
import Sidebar from '../includes/Sidebar'
import MatchedReport from '../components/charts/837matchedReport'
import FileReportChart from '../components/charts/fileReportChart'
import { Button, Form } from 'react-bootstrap'
import { useFormik } from 'formik'
import { addDays, addMonths, endOfDay, endOfMonth, endOfWeek, startOfDay, startOfMonth, startOfWeek, subDays } from 'date-fns'
import Select from 'react-select';
import { Tooltip } from '@mui/material'
import { DateRangePicker, Stack } from 'rsuite'
import { BiSearch } from 'react-icons/bi'
import { InvoiceReportService } from '../../services/InvoiceReportService'
import { DashboardService } from '../../services/DashboardService'
import VolumeReport from '../components/charts/volumeReport/volumeReport'
import moment from 'moment'
import ProductivityReport from '../components/charts/productivityReport/productivityReport'
import QualityReport from '../components/charts/qualityReport/qualityReportChart'
import TimeReport from '../components/charts/timeReport/timeReport'
import FileSplitReportChart from '../components/charts/fileSplitReportChart'
import SnippingReportChart from '../components/charts/snippingReportChart'


const Dashboard = () => {
  const [showSingle, setShowSingle] = useState(false)

  const [volumeGraphType, setVolumeGraphType] = useState('DONUT')
  const [productivityGraphType, setProductivityGraphType] = useState('DONUT')
  const [qalityGraphType, setQalityGraphType] = useState('DONUT')

  const [partners, setPartners] = useState([])
  const [clients, setClients] = useState([]);
  const [facilities, setFacilities] = useState([]);
  const [selectedFacility, setSelectedFacility] = useState([])
  const [selectValue, setSelectValue] = useState([])
  const invoiceReportService = new InvoiceReportService();
  const { getClientPartnerDropdown, getPartnerDropdown, getFacilityDropdown } = invoiceReportService

  const [matchReportData, setMatchReportData] = useState([]);
  const [fileReportData, setFileReportData] = useState([]);
  const [volumeReportData, setVolumeReportData] = useState([]);
  const [productivityReportData, setProductivityReportData] = useState([]);
  const [qualityReportData, setQualityReportData] = useState([]);
  const [timeReportData, setTimeReportData] = useState([]);
  const [fileSplitReportData, setFileSplitReportData] = useState([]);
  const [snippingReportData, setSnippingReportData] = useState([]);
  const [loaderState, setLoaderState] = useState({
    volumeReportLoader: false,
    timeReportLoader: false,
    productivityReportLoader: false,
    qualityLoader: false,
    match837ReportLoader: false,
    fileReportLoader: false,
    fileSplitReportLoader: false,
    snippingReportLoader: false,

  });




  const [update, setUpdate] = useState(false)


  const dashboardService = new DashboardService();

  const { getFileSplitReport, getSnippingReport, getTimeReport, get837UsedMatchReport, getFileReport, geVolumeReport, getProductivityReport, getQualityReport } = dashboardService


  useEffect(() => {
    getPartnerDropdown().then((response) => {
      if (response?.status) {
        setPartners(response?.data)
        getClientPartnerDropdown(response?.data?.map(i => i.value)).then((response) => {
          if (response?.status) {
            const data = response?.data?.partners
            const clientIdArr = []
            const FullClients = []
            setClients(data?.map(result => {
              result?.clients?.map(item => clientIdArr.push(item.value));
              FullClients.push({ options: result?.clients, label: result?.partner, id: result?.pk })
              return { options: result?.clients, label: result?.partner, id: result?.pk }

            }))

            getFacilityDropdown(clientIdArr).then((response) => {
              if (response && response.status) {
                const feciltyIdArr = []
                const FullFacilities = []
                setFacilities(response.data.facilities?.map(result => {
                  result?.facilities?.map(item => feciltyIdArr.push(item.value));
                  FullFacilities.push({ options: result?.facilities, label: result?.client, id: result?.pk })
                  return { options: result?.facilities, label: result?.client, id: result?.pk }

                }))

                const newArray = selectedFacility.filter(item => feciltyIdArr.includes(item.value));
                setSelectedFacility(newArray)
              }
            });
          }
        })

      }
    })

  }, [])

  useEffect(() => {
    let date = moment(searchFormik.values.date_range[0]).format('MM/DD/YYYY') + ' - ' + moment(searchFormik.values.date_range[1]).format('MM/DD/YYYY')
    setShowSingle(false)
    setMatchReportData([])
    setFileReportData([])
    setVolumeReportData([])
    setProductivityReportData([])
    setQualityReportData([])
    setTimeReportData([])
    setVolumeGraphType('DONUT')
    setProductivityGraphType('DONUT')
    setQalityGraphType('DONUT')
    setLoaderState({
      volumeReportLoader: true,
      timeReportLoader: true,
      productivityReportLoader: true,
      qualityLoader: true,
      match837ReportLoader: true,
      fileReportLoader: true,
      fileSplitReportLoader: true,
      snippingReportLoader: true,

    })
    get837UsedMatchReport({ date_range: date, partners: searchFormik?.values?.partners, clients: searchFormik?.values?.clients, facilities: searchFormik?.values.facilities }).then((response) => {
      if (response && response.status) {
        setMatchReportData(response?.data)
        setLoaderState((prevState) => {
          return {
            ...prevState,
            match837ReportLoader: false,
          };
        });
      }
    });
    getFileReport({ date_range: date, partners: searchFormik?.values?.partners, clients: searchFormik?.values?.clients, facilities: searchFormik?.values.facilities }).then((response) => {
      if (response && response.status) {
        setFileReportData(response?.data)
        setLoaderState((prevState) => {
          return {
            ...prevState,
            fileReportLoader: false,
          };
        });

      }
    });
    geVolumeReport({ date_range: date, partners: searchFormik?.values?.partners, clients: searchFormik?.values?.clients, facilities: searchFormik?.values.facilities }).then((response) => {
      if (response && response.status) {
        setVolumeReportData(response?.data)
        setLoaderState((prevState) => {
          return {
            ...prevState,
            volumeReportLoader: false,
          };
        });

      }
    });
    getProductivityReport({ date_range: date, partners: searchFormik?.values?.partners, clients: searchFormik?.values?.clients, facilities: searchFormik?.values.facilities }).then((response) => {
      if (response && response.status) {
        setProductivityReportData(response?.data)
        setLoaderState((prevState) => {
          return {
            ...prevState,
            productivityReportLoader: false,
          };
        });

      }
    });
    getQualityReport({ date_range: date, partners: searchFormik?.values?.partners, clients: searchFormik?.values?.clients, facilities: searchFormik?.values.facilities }).then((response) => {
      if (response && response.status) {
        setQualityReportData(response?.data)
        setLoaderState((prevState) => {
          return {
            ...prevState,
            qualityLoader: false,
          };
        });

      }
    });
    getTimeReport({ date_range: date, partners: searchFormik?.values?.partners, clients: searchFormik?.values?.clients, facilities: searchFormik?.values.facilities }).then((response) => {
      if (response && response.status) {
        setTimeReportData(response?.data)
        setLoaderState((prevState) => {
          return {
            ...prevState,
            timeReportLoader: false,
          };
        });

      }
    });
    getFileSplitReport({ date_range: date, partners: searchFormik?.values?.partners, clients: searchFormik?.values?.clients, facilities: searchFormik?.values.facilities }).then((response) => {
      if (response && response.status) {
        setFileSplitReportData(response?.data)
        setLoaderState((prevState) => {
          return {
            ...prevState,
            fileSplitReportLoader: false,
          };
        });

      }
    });
    getSnippingReport({ date_range: date, partners: searchFormik?.values?.partners, clients: searchFormik?.values?.clients, facilities: searchFormik?.values.facilities }).then((response) => {
      if (response && response.status) {
        setSnippingReportData(response?.data)
        setLoaderState((prevState) => {
          return {
            ...prevState,
            snippingReportLoader: false,
          };
        });

      }
    });
  }, [update])


  const searchFormik = useFormik({
    initialValues: {
      partners: [],
      clients: [],
      facilities: [],
      date_range: [startOfDay(new Date()), new Date()]

    },
    validate: value => {
      const errors = {}
      if (!value?.date_range?.length > 0) {
        errors.date_range = "Required"
      }
      return errors

    },
    onSubmit: (values) => {
      setUpdate(!update)
    }
  })
  const predefinedBottomRanges = [
    {
      label: 'Today',
      value: [startOfDay(new Date()), new Date()],
      placement: 'left'
    },
    {
      label: 'Yesterday',
      value: [startOfDay(addDays(new Date(), -1)), endOfDay(addDays(new Date(), -1))],
      placement: 'left'
    },
    {
      label: 'This week',
      value: [startOfWeek(new Date()), endOfWeek(new Date())],
      placement: 'left'
    },
    {
      label: 'Last 7 days',
      value: [startOfDay(subDays(new Date(), 6)), new Date()],
      placement: 'left'
    },
    {
      label: 'Last 30 days',
      value: [startOfDay(subDays(new Date(), 29)), new Date()],
      placement: 'left'
    },
    {
      label: 'This month',
      value: [startOfMonth(new Date()), new Date()],
      placement: 'left'
    },
    {
      label: 'Last month',
      value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
      placement: 'left'
    },
    {
      label: 'This year',
      value: [new Date(new Date().getFullYear(), 0, 1), new Date()],
      placement: 'left'
    },
    {
      label: 'Last year',
      value: [new Date(new Date().getFullYear() - 1, 0, 1), endOfDay(new Date(new Date().getFullYear(), 0, 0))],
      placement: 'left'
    },

    {
      label: 'Last week',
      closeOverlay: false,
      value: value => {
        const [start = new Date()] = value || [];
        return [
          addDays(startOfWeek(start, { weekStartsOn: 0 }), -7),
          addDays(endOfWeek(start, { weekStartsOn: 0 }), -7)
        ];
      },
      appearance: 'default'
    },
    {
      label: 'Next week',
      closeOverlay: false,
      value: value => {
        const [start = new Date()] = value || [];
        return [
          addDays(startOfWeek(start, { weekStartsOn: 0 }), 7),
          addDays(endOfWeek(start, { weekStartsOn: 0 }), 7)
        ];
      },
      appearance: 'default'
    }
  ];
  const handleSelectChange = (val, name = null) => {
    let selectedOptions = [];
    let selectedIds = [];
    if (val && val?.length > 0) {

      val?.map((option) => {
        selectedIds.push(option.value)
        selectedOptions.push(option)
      })
    }
    if (name == 'facility') {
      setSelectedFacility(selectedOptions)
      searchFormik?.setFieldValue('facilities', selectedIds)
    }
    else if (name == 'client') {
      setSelectedFacility([])
      setFacilities([])
      setSelectValue(selectedOptions)
      searchFormik?.setFieldValue('clients', selectedIds)
      const feciltyIdArr = []
      let clientoptions = []
      clients?.map(client => {
        client?.options?.map(item => {
          clientoptions.push(item?.value)
        })
      })
      getFacilityDropdown(selectedIds?.length ? selectedIds : clientoptions).then((response) => {
        if (response && response.status) {
          setFacilities(response.data.facilities?.map(result => {
            result?.facilities?.map(item => feciltyIdArr.push(item.value));
            return { options: result?.facilities, label: result?.client }

          }))
          const newArray = selectedFacility.filter(item => feciltyIdArr.includes(item.value));
          setSelectedFacility(selectedIds?.length ? newArray : [])
        }
      });
    }
    else if (name == 'partner') {
      setSelectedFacility([])
      setFacilities([])
      setClients([])
      setSelectValue([])
      searchFormik.setFieldValue("partners", selectedIds)
      getClientPartnerDropdown(selectedIds?.length ? selectedIds : null).then((response) => {
        if (response?.status) {

          const data = response?.data?.partners
          const clientIdArr = []
          setClients(data?.map(result => {
            result?.clients?.map(item => clientIdArr.push(item.value));
            return { options: result?.clients, label: result?.partner, id: result?.pk }
          }))
          const newArray = selectValue.filter(item => clientIdArr.includes(item.value));
          setSelectValue(selectedIds?.length ? newArray : [])
          let clientIds = []
          newArray?.map((item) => {
            clientIds.push(item.value)
          })
          console.log(selectedIds?.length, clientIds)
          getFacilityDropdown(selectedIds?.length && clientIds?.length ? clientIds : clientIdArr).then((response) => {
            if (response && response.status) {
              const feciltyIdArr = []
              setFacilities(response.data.facilities?.map(result => {
                result?.facilities?.map(item => feciltyIdArr.push(item.value));
                return { options: result?.facilities, label: result?.client, id: result?.pk }

              }))
              const newArray = selectedFacility.filter(item => feciltyIdArr.includes(item.value));
              setSelectedFacility(selectedIds?.length ? newArray : [])

            }
          });
        }
      })
    }
  }

  return (
    <React.Fragment>
      <Sidebar />
      <main id="main" className="main">
        <div>
          <Form onSubmit={searchFormik.handleSubmit} >
            <div className=" ">
              <div className="px-1 py-1 d-flex flex-row justify-content-start gap-2 align-items-center flex-wrap">
                <div>
                  <Select
                    options={partners}
                    className=""
                    classNamePrefix="select"
                    placeholder="Partner"
                    menuPlacement="auto"
                    maxMenuHeight={300}
                    isClearable
                    isMulti
                    onChange={(val) => {
                      handleSelectChange(val, 'partner')
                    }}
                    value={partners.filter((item) => searchFormik.values.partners?.includes(item.value))}
                    name="partners"
                    tabSelectsValue={false}
                    hideSelectedOptions={false}
                    styles={{
                      container: (styles, state) => ({
                        ...styles,
                        borderRadius: "9px",
                      }),
                      control: (styles, state) => ({
                        ...styles,
                        backgroundColor: "hsl(204deg 33.33% 97.06%)",
                        borderStyle: "none", boxShadow: "none",
                        borderRadius: "10px",
                        maxHeight: "30px",
                      }),
                      indicatorSeparator: styles => ({
                        ...styles, width: "0px",
                        minHeight: "20px",
                        maxHeight: "30px",
                      }),

                      valueContainer: (styles, state) => ({
                        ...styles,
                        maxHeight: "30px",
                        overflow: 'scroll',
                      }),
                      option: (styles, state) => ({
                        ...styles,
                        backgroundColor: state.isSelected ? '#d9f4f5' : 'inherit',
                        color: state.isSelected ? '#000000' : 'inherit',
                        '&:hover': { backgroundColor: state.isSelected ? '#d9f4f5' : 'rgb(222, 235, 255)' }

                      })
                    }}
                    menuPortalTarget={document.body}
                    closeMenuOnSelect={false} // Keep the menu open after selecting an option

                  />
                </div>
                <div>
                  <Select
                    tabSelectsValue={false}
                    isMulti
                    options={clients}
                    className=""
                    classNamePrefix="select"
                    placeholder="Client"
                    menuPlacement="auto"
                    maxMenuHeight={300}
                    isClearable
                    value={selectValue}
                    onChange={(val) => handleSelectChange(val, 'client')}
                    name="client"
                    hideSelectedOptions={false}
                    styles={{
                      container: (styles, state) => ({
                        ...styles,
                        borderRadius: "9px",
                      }),
                      control: (styles, state) => ({
                        ...styles,
                        backgroundColor: "hsl(204deg 33.33% 97.06%)",
                        borderStyle: "none", boxShadow: "none",
                        borderRadius: "10px",
                        maxHeight: "30px",
                      }),
                      indicatorSeparator: styles => ({
                        ...styles, width: "0px",
                        minHeight: "20px",
                        maxHeight: "30px",
                      }),

                      valueContainer: (styles, state) => ({
                        ...styles,
                        maxHeight: "30px",
                        overflow: 'scroll',
                      }),
                      option: (styles, state) => ({
                        ...styles,
                        backgroundColor: state.isSelected ? '#d9f4f5' : 'inherit',
                        color: state.isSelected ? '#000000' : 'inherit',
                        '&:hover': { backgroundColor: state.isSelected ? '#d9f4f5' : 'rgb(222, 235, 255)' }

                      })
                    }}
                    menuPortalTarget={document.body}
                    closeMenuOnSelect={false} // Keep the menu open after selecting an option

                  />
                </div>
                <div>
                  <Select
                    options={facilities}
                    className=""
                    classNamePrefix="select"
                    placeholder="Facility"
                    menuPlacement="auto"
                    maxMenuHeight={300}
                    isClearable
                    isMulti
                    name="facility"
                    tabSelectsValue={false}
                    hideSelectedOptions={false}
                    onChange={(val) => handleSelectChange(val, 'facility')}
                    value={selectedFacility}
                    styles={{
                      container: (styles, state) => ({
                        ...styles,
                        borderRadius: "9px",
                      }),
                      control: (styles, state) => ({
                        ...styles,
                        backgroundColor: "hsl(204deg 33.33% 97.06%)",
                        borderStyle: "none", boxShadow: "none",
                        borderRadius: "10px",
                        maxHeight: "30px",
                      }),
                      indicatorSeparator: styles => ({
                        ...styles, width: "0px",
                        minHeight: "20px",
                        maxHeight: "30px",
                      }),

                      valueContainer: (styles, state) => ({
                        ...styles,
                        maxHeight: "30px",
                        overflow: 'scroll',
                      }),
                      option: (styles, state) => ({
                        ...styles,
                        backgroundColor: state.isSelected ? '#d9f4f5' : 'inherit',
                        color: state.isSelected ? '#000000' : 'inherit',
                        '&:hover': { backgroundColor: state.isSelected ? '#d9f4f5' : 'rgb(222, 235, 255)' }

                      })
                    }}
                    menuPortalTarget={document.body}
                    closeMenuOnSelect={false} // Keep the menu open after selecting an option

                  />
                </div>

                <Tooltip disableInteractive title={"Deposit Date"} placement="top" arrow>

                  <div className={searchFormik.errors.depo_date_range ? "error invoice-report-date correspondenceDatePicker" : "invoice-report-date correspondenceDatePicker"}>
                    <Stack direction="column" spacing={2} alignItems="flex-start">

                      <DateRangePicker
                        id={'date-time-picker-id111'}
                        isClearable={false}
                        cleanable={false}
                        autocomplete={false}
                        ranges={predefinedBottomRanges}
                        placement="auto"
                        appearance="default"
                        format="MM/dd/yyyy"
                        // format={"MM/dd/yyyy"}
                        className="form-date-control form-control-solid b-r-1 text-dark custom-daterange"
                        placeholder={"MM/DD/YYYY - MM/DD/YYYY"}
                        name="date_range"
                        onChange={(value) => {
                          if (value !== null) {
                            searchFormik.setFieldValue("date_range", value)
                          } else {
                            searchFormik.setFieldValue("date_range", [])
                          }
                        }}
                        value={searchFormik?.values?.date_range} />
                    </Stack>
                  </div>
                </Tooltip>

                <div>
                  <Tooltip disableInteractive title={"Search"} placement="top" arrow>
                    <Button className="btn btn-light btn-active-primary" type="submit">
                      <span className="svg-icon svg-icon-3">
                        <BiSearch />
                      </span>

                    </Button>
                  </Tooltip>
                </div>


              </div>
            </div>

          </Form>
        </div>
        <div className='row mt-5'>
          <div className='col-lg-6 p-3'>
            <VolumeReport setGraphType={setVolumeGraphType} graphType={volumeGraphType} setLoaderState={setLoaderState} showSingle={showSingle} setShowSingle={setShowSingle} loaderState={loaderState} data={volumeReportData} searchFormik={searchFormik} update={update} />
          </div>
          <div className='col-lg-6 p-3'>
            <TimeReport setLoaderState={setLoaderState} loaderState={loaderState} data={timeReportData} searchFormik={searchFormik} update={update} />
          </div>
          <div className='col-lg-6 p-3'>
            <ProductivityReport setGraphType={setProductivityGraphType} graphType={productivityGraphType} setLoaderState={setLoaderState} loaderState={loaderState} data={productivityReportData} searchFormik={searchFormik} update={update} />
          </div>
          <div className='col-lg-6 p-3'>
            <QualityReport setGraphType={setQalityGraphType} graphType={qalityGraphType} setLoaderState={setLoaderState} loaderState={loaderState} data={qualityReportData} searchFormik={searchFormik} update={update} />
          </div>
          <div className='col-lg-6 p-3'>
            <MatchedReport setLoaderState={setLoaderState} loaderState={loaderState} data={matchReportData} />
          </div>
          <div className='col-lg-6 p-3'>

            <FileReportChart setLoaderState={setLoaderState} loaderState={loaderState} data={fileReportData} />
          </div>
          <div className='col-lg-6 p-3'>
            <FileSplitReportChart setLoaderState={setLoaderState} loaderState={loaderState} data={fileSplitReportData} />
          </div>
          <div className='col-lg-6 p-3'>
            <SnippingReportChart setLoaderState={setLoaderState} loaderState={loaderState} data={snippingReportData} />
          </div>

        </div>
      </main>
    </React.Fragment>
  )
}

export default Dashboard