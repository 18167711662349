import React from 'react'
import Sidebar from '../../includes/Sidebar'
import DataListTable from '../../components/DataListTable'
import PdfViewer from './PdfViewer'
import CustomizeQuickZoomExample from './CustomizeQuickZoomExample'

function ViewWork() {
  return (
    <React.Fragment>
      {/* <Sidebar /> */}
      <PdfViewer />
      {/* <CustomizeQuickZoomExample /> */}
    </React.Fragment>
  )
}

export default ViewWork    