import React from 'react'
import Chart from 'react-apexcharts';

const DetailsReport = ({ data, singleColor }) => {
    const series = [{
        name: 'CPH',
        data: data?.length ? data?.map(item => item?.cph) : []
        // data: [42, 17, 88, 5, 62, 33, 76, 21, 49, 10, 91, 27, 55, 83, 39, 70, 14, 96, 23, 67, 38, 9, 58, 19, 81, 46, 3, 74, 29, 94]
    }];
    const options = {
        chart: {
            type: 'bar',
            fontFamily: 'Open Sans',
            toolbar: {
                show: false
            },
        },
        plotOptions: {
            bar: {
                // borderRadius: 4,
                horizontal: true,
                barHeight: '20px'
            }
        },
        grid: {
            show: false
        },
        dataLabels: {
            enabled: true
        },
        colors: [singleColor],
        xaxis: {
            labels: {
                show: false
            },
            categories: data?.length ? data?.map(item => item?.username) : [],
            // categories: ["Aaran","Aaren","Aarez","Aarman","Aaron","Aaron-James","Aarron","Aaryan","Aaryn","Aayan","Aazaan","Abaan","Abbas","Abdallah","Abdalroof","Abdihakim","Abdirahman","Abdisalam","Abdul","Abdul-Aziz","Abdulbasir","Abdulkadir","Abdulkarem","Abdulkhader","Abdullah","Abdul-Majeed","Abdulmalik","Abdul-Rehman","Abdur"],
        },
        yaxis: {
            labels: {
                maxWidth: 100
            }
        }
    }
    return (
        <div className='card p-2' style={{ maxHeight: '75vh', width: '500px', overflow: 'auto' }}>
            <Chart options={options} series={series} type="bar" height={data?.length * 25 + 70} />
        </div>
    )
}

export default DetailsReport