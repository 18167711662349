import { AiOutlineSync } from "react-icons/ai";
import styled, { css, keyframes } from "styled-components";

const rotation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
`;

export const ReloadCircle = styled(AiOutlineSync)`
  color: white;
  ${props =>
        props.loader &&
        css`
      animation: ${rotation} 1s linear infinite;
    `}
`;