import request from "../api/agent";

const API_ROUTE = "job_monitor";

export class DataListService {

  async filterQueueDetails(props) {
    const staffs = props?.staff ? props?.staff : "";
    const clients = props?.client ? props?.client : "";
    const status = props?.status ? props?.status : "";
    const client_groups = props?.client_group ? props?.client_group : "";
    const filename = props?.filename ? props?.filename : "";
    const job_unique = props?.job_unique ? props?.job_unique : "";

    const check_number = props?.check_number ? props?.check_number : "";
    const batch_id = props?.batch_id ? props?.batch_id : "";
    const sort_order = props?.sort_order ? props?.sort_order : "";
    const sort_key = props?.sort_key ? props?.sort_key : "";
    const date_from = props?.start_date ? props?.start_date : "";
    const date_to = props?.end_date ? props?.end_date : "";
    const deposit_date_range = date_from && date_to ? date_from + " - " + date_to : "";
    // const deposit_date_range = "09/01/2022-04/28/2023";
    const tat_status = props?.tat_status ? props?.tat_status : "";
    const station = props?.station ? props?.station : "";
    const params = "?page=" + props?.page + "&limit=" + props?.limit + "&staffs=" + staffs + '&clients=' + clients + '&status=' + status + '&client_groups=' + client_groups + '&filename=' + filename + '&job_unique=' + job_unique + '&check_number=' + check_number + '&batch_id=' + batch_id + '&date_range=' + deposit_date_range + "&sort_order=" + sort_order + "&sort_key=" + sort_key + "&tat_status=" + tat_status + "&station=" + station

    const response = await request.get(`${API_ROUTE}/get-datalisting` + params);
    return response.data ?? response?.response?.data;
  }

  async getUserlist(page, search,) {
    const params = "?page=" + page + "&search=" + search

    const response = await request.get(`${API_ROUTE}/get-users` + params);
    return response.data ?? response?.response?.data;
  }
  async getClientGrouplist() {

    const response = await request.get(`${API_ROUTE}/get-client-group`);
    return response.data ?? response?.response?.data;
  }
  async getClientlist() {

    const response = await request.get(`${API_ROUTE}/client-list`);
    return response.data ?? response?.response?.data;
  }
  async exportDataList(props) {
    const staffs = props?.staff ? props?.staff : "";
    const clients = props?.client ? props?.client : "";
    const status = props?.status ? props?.status : "";
    const client_groups = props?.client_group ? props?.client_group : "";
    const filename = props?.filename ? props?.filename : "";
    const job_unique = props?.job_unique ? props?.job_unique : "";
    const check_number = props?.check_number ? props?.check_number : "";
    const batch_id = props?.batch_id ? props?.batch_id : "";
    const sort_order = props?.sort_order ? props?.sort_order : "";
    const sort_key = props?.sort_key ? props?.sort_key : "";
    const date_from = props?.start_date ? props?.start_date : "";
    const date_to = props?.end_date ? props?.end_date : "";
    const deposit_date_range = date_from && date_to ? date_from + " - " + date_to : "";
    // const deposit_date_range = "09/01/2022-04/28/2023";
    const tat_status = props?.tat_status ? props?.tat_status : "";
    const station = props?.station ? props?.station : "";
    const header_names = props?.header_names ? props?.header_names : "";

    const params = "?page=" + props?.page + "&limit=" + props?.limit + "&staffs=" + staffs + '&clients=' + clients + '&status=' + status + '&client_groups=' + client_groups + '&file_name=' + filename + '&job_unique=' + job_unique + '&check_number=' + check_number + '&batch_id=' + batch_id + '&date_range=' + deposit_date_range + "&sort_order=" + sort_order + "&sort_key=" + sort_key + "&tat_status=" + tat_status + "&header_names=" + header_names + "&station=" + station

    const response = await request.get(`${API_ROUTE}/get-datalisting-download` + params);
    return response.data ?? response?.response?.data;
  }

  async filterTotalQueueDetails(props) {
    const staffs = props?.staff ? props?.staff : "";
    const clients = props?.client ? props?.client : "";
    const status = props?.status ? props?.status : "";
    const client_groups = props?.client_group ? props?.client_group : "";
    const filename = props?.filename ? props?.filename : "";
    const job_unique = props?.job_unique ? props?.job_unique : "";

    const check_number = props?.check_number ? props?.check_number : "";
    const batch_id = props?.batch_id ? props?.batch_id : "";
    const sort_order = props?.sort_order ? props?.sort_order : "";
    const sort_key = props?.sort_key ? props?.sort_key : "";
    const date_from = props?.start_date ? props?.start_date : "";
    const date_to = props?.end_date ? props?.end_date : "";
    const deposit_date_range = date_from && date_to ? date_from + " - " + date_to : "";
    // const deposit_date_range = "09/01/2022-04/28/2023";
    const tat_status = props?.tat_status ? props?.tat_status : "";
    const station = props?.station ? props?.station : "";
    const params = "?page=" + props?.page + "&limit=" + props?.limit + "&staffs=" + staffs + '&clients=' + clients + '&status=' + status + '&client_groups=' + client_groups + '&filename=' + filename + '&job_unique=' + job_unique + '&check_number=' + check_number + '&batch_id=' + batch_id + '&date_range=' + deposit_date_range + "&sort_order=" + sort_order + "&sort_key=" + sort_key + "&tat_status=" + tat_status + "&station=" + station

    const response = await request.get(`${API_ROUTE}/get-datalisting-total` + params);
    return response.data ?? response?.response?.data;
  }

  async getJobDetailReport(id) {
    const response = await request.get(`${API_ROUTE}/get-grid-data?job_id=` + id);
    return response.data ?? response?.response?.data;
  }
  async getReasonsDropdown(id) {
    const response = await request.get(`${API_ROUTE}/get-incomplete-status-grid-dropdown?client=` + id);
    return response.data ?? response?.response?.data;
  }
  async getDocumentsDropdown(id) {
    const response = await request.get(`${API_ROUTE}/get-document-classification-grid-dropdown?client=` + id);
    return response.data ?? response?.response?.data;
  }
  async getSnippetImage(url) {
    const response = await request.get(`${API_ROUTE}/get-snipping-image?file_path=` + url);
    return response.data ?? response?.response?.data;
  }

  async downloadMultipleReport(props) {
    const filter_type = props?.filter_type ? props?.filter_type : "";
    const staffs = props?.staff ? props?.staff : "";
    const clients = props?.client ? props?.client : "";
    const status = props?.status ? props?.status : "";
    const client_groups = props?.client_group ? props?.client_group : "";
    const filename = props?.filename ? props?.filename : "";
    const job_unique = props?.job_unique ? props?.job_unique : "";

    const check_number = props?.check_number ? props?.check_number : "";
    const batch_id = props?.batch_id ? props?.batch_id : "";
    const sort_order = props?.sort_order ? props?.sort_order : "";
    const sort_key = props?.sort_key ? props?.sort_key : "";
    const date_from = props?.start_date ? props?.start_date : "";
    const date_to = props?.end_date ? props?.end_date : "";
    const deposit_date_range = date_from && date_to ? date_from + " - " + date_to : "";
    // const deposit_date_range = "09/01/2022-04/28/2023";
    const tat_status = props?.tat_status ? props?.tat_status : "";
    const station = props?.station ? props?.station : "";
    const params = "?page=" + props?.page + "&limit=" + props?.limit + "&staffs=" + staffs + '&clients=' + clients + '&status=' + status + '&client_groups=' + client_groups + '&filename=' + filename + '&job_unique=' + job_unique + '&check_number=' + check_number + '&batch_id=' + batch_id + '&date_range=' + deposit_date_range + "&sort_order=" + sort_order + "&sort_key=" + sort_key + "&tat_status=" + tat_status + "&station=" + station + `&${filter_type}=` + 'true'

    const response = await request.get(`${API_ROUTE}/download-reports` + params);
    return response.data ?? response?.response?.data;
  }
}