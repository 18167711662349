import React from 'react'
import Chart from 'react-apexcharts';
import CircleSkelitonLoader from '../../loader/CircleSkelitonLoader';

const AllReport = ({ isVisible, data, handleClickData }) => {
    const series = data?.map(item => item?.calc_total ?? 0)

    const options = {
        chart: {
            id: 'basic-donut',
            type: 'donut',
            toolbar: {
                show: false
            },

            fontFamily: 'Open Sans',
            events: {
                dataPointSelection: handleClickData
            }
        },
        fill: {
            type: 'gradient',
        },
        dataLabels: {
            enabled: true,
            style: {
                colors: ['black']
            },
            dropShadow: {
                enabled: false,
                enabledOnSeries: undefined,
                top: 0,
                left: 0,
                blur: 3,
                opacity: 0.35
            }
        },
        noData: {
            text: 'No data available',
        },
        legend: {
            show: true,
            customLegendItems: data?.map(item => item?.client_name),
            offsetX: 0,
            offsetY: 100,
        },
        labels: data?.map(item => item?.client_name),
        // colors: ['#ad6baf', '#75ba7a', '#61d6f3', '#c7d07b', '#ffb981', '#00a1a8', '#166783', '#c7d07b', '#df8483', '#ffb237'],
        tooltip: {
            show: true,
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                const color = w.config.colors[seriesIndex];
                const mainLabel = w.globals.labels[seriesIndex]

                return `<div style="background-color:${color};padding:5px" class="">
                        <span style="color:${"#000"};"> ${`${mainLabel}: ${series[seriesIndex]}`}  </span>
                </div>`


            }
        },
        colors: ['#b2d3a3', '#9cadda', '#ffd993', '#c7d07b', '#f6b89d', '#abc7e7', '#969696', '#24B2DD', '#FFB11F', '#D98383'],

    };

    return (
        isVisible ?
            <CircleSkelitonLoader /> :
            <Chart options={options} series={series?.filter(i => i > 0)} type="donut" height={'90%'} />
    )
}

export default AllReport