import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useMemo } from "react";
import { Button, Form, OverlayTrigger } from "react-bootstrap";
import moment from "moment";
import { useFormik } from "formik";
import { DateRangePicker } from "rsuite";
import { BiSearch } from "react-icons/bi";
import { Stack } from 'rsuite';
import subDays from 'date-fns/subDays';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import addDays from 'date-fns/addDays';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import addMonths from 'date-fns/addMonths';
import { TbGridDots } from "react-icons/tb";
import { HiChevronDown } from "react-icons/hi";
import Sidebar from "../../includes/Sidebar";
import { FileManagementService } from "../../../services/FileManagementService";
import FileManagementDetails from "./FileManagementDetails";
import { BsCloudDownloadFill } from "react-icons/bs";
import { downloadXl } from "../../../functions";
import LockBoxFileManagementDetails from "./ReasonCodeFileManagementDetails";
import { Tooltip } from "@mui/material";
import { useCallback } from "react";
import { seErrorMessage } from "../../../slice/error/errorSlice";
import { useDispatch } from "react-redux";
import Select, { components } from 'react-select';
import { useRef } from "react";
import { InvoiceReportService } from "../../../services/InvoiceReportService";


const datatableCustomStyles = {
  rows: {
    style: {
      minHeight: '20px', // override the row height
      fontSize: '0.7em',
      fontColor: '#212529 !important',
      width: '100%'
    },
  },
  headCells: {
    style: {
      paddingLeft: '5px', // override the cell padding for head cells
      paddingRight: '5px',
      textTransform: "uppercase",
      fontSize: '0.7em',
      fontWeight: '800',
      fontColor: '#6c757d !important',
      opacity: 1,
      minHeight: "10px",
    },
  },
  cells: {
    style: {
      paddingLeft: '8px', // override the cell padding for data cells
      paddingRight: '8px',
      width: "100%",
    },
  },
  pagination: {
    style: {
      color: '#000',
      fontSize: '13px',
      minHeight: '56px',
      backgroundColor: '#fff',
      borderTopStyle: 'solid',
      borderTopWidth: '1px',
      borderTopColor: '#f2f2f2',
    },
    pageButtonsStyle: {
      borderRadius: '50%',
      height: '40px',
      width: '40px',
      padding: '8px',
      margin: '0px',
      cursor: 'pointer',
      transition: '0.4s',
      color: '#000',
      fill: '#000',
      backgroundColor: 'transparent',
      '&:disabled': {
        cursor: 'unset',
        color: '#e2e2e2',
        fill: '#e2e2e2',
      },
      '&:hover:not(:disabled)': {
        backgroundColor: '#f2f2f2',
      },
      '&:focus': {
        outline: 'none',
        backgroundColor: '#f5f5f5',
      },
    },
  },
};

function FileManagement() {
  const dispatch = useDispatch()
  const facilityRef = useRef(null)
  const clientRef = useRef(null)

  const [reRenderDataTable, setRerenderDataTable] = useState(false)
  const [pageno, setPageNo] = useState(1);
  const [loading, setLoading] = useState(false);
  const [isTimeCount, setisTimeCount] = useState(false);
  const [totalRows, setTotalRows] = useState(100);
  const [filterText, setFilterText] = useState('');
  const [colorRow, setColorRow] = React.useState({});
  const [data, setData] = useState({})
  const [limit, setLimit] = useState(100)
  const [searchedData, setSearchedData] = useState({
    search: "",
    date_range: `${moment(startOfMonth(new Date())).format('MM/DD/YYYY')} - ${moment(new Date()).format('MM/DD/YYYY')}`,
    hour_count: "",
    clients: [],
    facilities: [],
  })
  const [isFocusedClient, setIsFocusedClient] = useState(false);

  const [clients, setClients] = useState([]);

  const [isFocusedFacility, setIsFocusedFacility] = useState(false);

  const [selectValue, setSelectValue] = useState([])

  const [facilities, setFacilities] = useState([])

  const [selectIds, setSelectIds] = useState("")

  const [selectedFacility, setSelectedFacility] = useState([])

  const [selectedFacilityIds, setSelectedFacilityIds] = useState("")
  const fileManagementService = new FileManagementService();
  const invoiceReportService = new InvoiceReportService()

  const { getFileManagementSummery, downloadFileManagementSummery } = fileManagementService
  const { getFacilityDropdown, getAllClientDropdown } = invoiceReportService

  const predefinedBottomRanges = [
    {
      label: 'Today',
      value: [new Date(), new Date()],
      placement: 'left'
    },
    {
      label: 'Yesterday',
      value: [addDays(new Date(), -1), addDays(new Date(), -1)],
      placement: 'left'
    },
    {
      label: 'This week',
      value: [startOfWeek(new Date()), endOfWeek(new Date())],
      placement: 'left'
    },
    {
      label: 'Last 7 days',
      value: [subDays(new Date(), 6), new Date()],
      placement: 'left'
    },
    {
      label: 'Last 30 days',
      value: [subDays(new Date(), 29), new Date()],
      placement: 'left'
    },
    {
      label: 'This month',
      value: [startOfMonth(new Date()), new Date()],
      placement: 'left'
    },
    {
      label: 'Last month',
      value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
      placement: 'left'
    },
    {
      label: 'This year',
      value: [new Date(new Date().getFullYear(), 0, 1), new Date()],
      placement: 'left'
    },
    {
      label: 'Last year',
      value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date(new Date().getFullYear(), 0, 0)],
      placement: 'left'
    },

    {
      label: 'Last week',
      closeOverlay: false,
      value: value => {
        const [start = new Date()] = value || [];
        return [
          addDays(startOfWeek(start, { weekStartsOn: 0 }), -7),
          addDays(endOfWeek(start, { weekStartsOn: 0 }), -7)
        ];
      },
      appearance: 'default'
    },
    {
      label: 'Next week',
      closeOverlay: false,
      value: value => {
        const [start = new Date()] = value || [];
        return [
          addDays(startOfWeek(start, { weekStartsOn: 0 }), 7),
          addDays(endOfWeek(start, { weekStartsOn: 0 }), 7)
        ];
      },
      appearance: 'default'
    }
  ];


  const handlePerRowsChange = async (newPerPage, page) => {

    setLimit(newPerPage);
    setPageNo(1)
    fetchData(1, newPerPage, searchedData?.search, searchedData?.station, searchedData?.date_range, searchedData?.hour_count, searchedData?.clients, searchedData?.facilities);
    handleResetDataTable()

  };

  const handlePageChange = page => {
    setPageNo(page)
    fetchData(page, limit, searchedData?.search, searchedData?.station, searchedData?.date_range, searchedData?.hour_count, searchedData?.clients, searchedData?.facilities);
  };
  const [status, setStatus] = useState([
    {
      label: "DC Completed",
      value: "datacaptured",
    },
    {
      label: "QA Review",
      value: "qa_review",
    },
    {
      label: "Partial Save",
      value: "partial_save",
    },
    {
      label: "Rejected",
      value: "rejected",
    },
    {
      label: "QA Completed",
      value: "qa_complete",
    },

  ]);



  const fetchData = (page, limit, station, job_status, date_range, hour_count, clients, facilities) => {
    setLoading(true)
    setisTimeCount(hour_count == 'true' || hour_count == '' ? false : true)
    getFileManagementSummery(page, limit, station, job_status, date_range, hour_count, clients, facilities).then((response) => {
      setData(response?.data);
      setLimit(limit);
      setPageNo(page)
      setTotalRows(response?.data?.count);
      setLoading(false);
      if (response?.errors?.date_range) {
        dispatch(seErrorMessage({ message: response?.errors?.date_range[0], is_show: true }))
      }
    });

  }




  const handleRowClickCheck = (row) => {
    data?.results.map((item, index) => {
      if (row?.id == item.id) {
        setColorRow(row)
      }
    })
  }
  const [expandedRows, setExpandedRows] = useState([]);
  const [isReasonCode, setIsReasonCode] = useState(false);
  const [isPayer, setIsPayer] = useState(false);

  // const ExpandedComponent = (row) =>
  //   <pre>{
  //     isPayer ?
  //       <FileManagementDetails report_page={1} report_limit={limit} report_station={""} report_job_status={""} report_date_range={searchedData?.date_range} report_hour_count={searchedData?.hour_count} user={row.data.client_id} />
  //       : isReasonCode ?
  //         <LockBoxFileManagementDetails report_page={1} report_limit={limit} report_station={""} report_job_status={""} report_date_range={searchedData?.date_range} report_hour_count={searchedData?.hour_count} user={row.data.client_id} />
  //         : null
  //   }</pre>;
  const ExpandedComponent = useCallback(
    ({ data }) => {
      if (isPayer) {
        return (
          <pre>
            <FileManagementDetails
              report_page={1}
              report_limit={limit}
              report_station={""}
              report_job_status={""}
              report_date_range={searchedData?.date_range}
              report_hour_count={searchedData?.hour_count}
              user={data.client_id}
              clients={searchedData?.clients}
              facilities={searchedData?.facilities}
              facility_id={data?.facility_id ?? ""}
            />
          </pre>
        );
      } else if (isReasonCode) {
        return (
          <pre>
            <LockBoxFileManagementDetails
              report_page={1}
              report_limit={limit}
              report_station={""}
              report_job_status={""}
              report_date_range={searchedData?.date_range}
              report_hour_count={searchedData?.hour_count}
              user={data.client_id}
              clients={searchedData?.clients}
              facilities={searchedData?.facilities}
              facility_id={data?.facility_id ?? ""}
            />
          </pre>
        );
      } else {
        return null;
      }
    },
    [isPayer, isReasonCode, limit, searchedData]
  );

  const handleRowExpand = ((row, is_payer, is_reasoncode) => {
    setColorRow(row);
    setIsPayer(is_payer)
    setIsReasonCode(is_reasoncode)
    const isRowExpanded = expandedRows.includes(row);
    setExpandedRows((isReasonCode === is_reasoncode || isPayer === is_payer) && (is_reasoncode && !is_payer || !is_reasoncode && is_payer) && isRowExpanded ? expandedRows.filter((r) => r !== row) : [row]);
  });

  const searchFormik = useFormik({
    initialValues: {
      search: "",
      job_status: "",
      station: "",
      date_range: [startOfMonth(new Date()), new Date()],
      created_date: `${moment(startOfMonth(new Date())).format('MM/DD/YYYY')} - ${moment(new Date()).format('MM/DD/YYYY')}`,
      hour_count: "true",
      clients: [],
      facilities: [],

    },
    onSubmit: (values) => {
      const client_ids = selectValue?.map(i => i.value)
      const facility_ids = selectedFacility?.map(i => i.value)
      values.clients = client_ids
      values.facilities = facility_ids
      searchData(values)

      setSearchedData({
        search: values?.search ? values?.search : "",
        job_status: values?.job_status ? values?.job_status : "",
        station: values?.station ? values?.station : "",
        date_range: values?.created_date ? values?.created_date : "",
        hour_count: values?.hour_count ? values?.hour_count : "",
        clients: values?.clients,
        facilities: values?.facilities,
      })
      handleResetDataTable()
    }
  })

  const columns = useMemo(
    () => [
      {
        id: 'S.NO',
        name: 'S.NO',
        selector: (row, index) => pageno == 1 ? index + 1 : (pageno - 1) * limit + index + 1,
        wrap: true,
        width: "40px",
        grow: 0.1,

      },
      {
        id: 'CLIENT NAME',
        name: 'CLIENT NAME',
        selector: row => row?.name,
        cell: (row) =>

          // <span onClick={() => handleRowClick(row)} className="text-truncate"> {row?.name}</span>
          <div>
            {row?.name &&

              <div onClick={() => handleRowClick(row)} className='d-flex flex-row justify-content-between align-items-center gap-2' style={{ width: '170px' }}>
                <div className='overflow-hidden text-truncate' style={{ width: "120px" }} >
                  <Tooltip disableInteractive title={row?.name} placement="top" arrow>
                    <span className="text-truncate" > {row?.name}</span>
                  </Tooltip>
                </div>
              </div>

            }
          </div>
        ,


        left: true,
        wrap: true,
        reorder: false,
        grow: 0.1,
        expandOnRowClicked: true,

      },
      {
        id: 'FACILITY NAME',
        name: 'FACILITY NAME',
        selector: row => row?.facility_name,
        cell: (row) =>

          // <span onClick={() => handleRowClick(row)} className="text-truncate"> {row?.facility_name}</span>
          <div>
            {row?.facility_name &&

              <div onClick={() => handleRowClick(row)} className='d-flex flex-row justify-content-between align-items-center gap-2' style={{ width: '170px' }}>
                <div className='overflow-hidden text-truncate' style={{ width: "120px" }} >
                  <Tooltip disableInteractive title={row?.facility_name} placement="top" arrow>
                    <span className="text-truncate" > {row?.facility_name}</span>
                  </Tooltip>
                </div>
              </div>

            }
          </div>
        ,


        left: true,
        wrap: true,
        reorder: false,
        grow: 0.1,
        expandOnRowClicked: true,

      },
      {
        id: 'PAYER',
        name: 'PAYER',
        selector: row => row?.payer,
        cell: (row) =>
          <span style={{ color: '#00a1a6', fontWeight: 'bold' }} className="text-truncate" onClick={() => { handleRowExpand(row, true, false) }}> {row?.payer}</span>

        ,

        right: true,
        wrap: true,
        reorder: false,
        grow: 0.1,
      },
      {
        id: 'REASON CODE',
        name: 'REASON CODE',
        selector: row => row?.reason_code,
        cell: (row) => <span style={{ color: '#00a1a6', fontWeight: 'bold' }} className="text-truncate" onClick={() => { handleRowExpand(row, false, true) }}> {row?.reason_code}</span>,

        right: true,
        wrap: true,
        reorder: false,
        grow: 0.1,
      },

    ],

    [handleRowExpand, limit, pageno, isTimeCount],
  );

  var i = 0;
  const conditionalRowStyles = [

    {
      when: row => colorRow.id == row.id ? true : false,
      style: (row, index) => ({
        backgroundColor: colorRow?.id == row.id ? '#dbf4f5' : '#f2f2f2',
        color: 'black',
        // fontWeight: '550'
      }),
    },


  ];
  const handleRowClick = (row) => {
    setExpandedRows([])
    setColorRow(row);
  };



  const [datetime, setDateTime] = useState([]);

  const handleOnDateTimeChange = ({ name, value }) => {
    setExpandedRows([])
    let created_date = ""
    if (value !== null) {
      const startDate = value[0];
      const endDate = value[1];
      // Check if the start date is after the end date, and swap the dates if needed
      let isGreater = false
      if (startDate > endDate) {
        isGreater = true
      }
      const datefrom = moment(isGreater ? endDate : startDate).format('MM/DD/YYYY')
      const dateto = moment(isGreater ? startDate : endDate).format('MM/DD/YYYY')

      created_date = datefrom + " - " + dateto
    } else {
      setSearchedData({
        ...searchedData,
        created_date: '',
        date_range: '',
      })
      fetchData(1, limit, searchedData?.search, '', "", searchedData?.hour_count);

    }

    setDateTime(created_date)

    searchFormik.setValues({ ...searchFormik.values, created_date, date_range: value ?? "" })


  }

  const searchData = ({ station, job_status, created_date, hour_count, clients, facilities }) => {
    fetchData(1, limit, station, selectValue, created_date, hour_count, clients, facilities)
  }

  const handleChangeSelect = async (e, name) => {
    let selectedId = []
    let selectedOptons = []

    e.map((option) => {
      selectedId.push(option.value)
      selectedOptons.push({ option })
    })

    searchFormik.setValues({ ...searchFormik.values, [name]: selectedId })
    setSelectValue(selectedId)

  }


  const showTooltip = (text, props) => (
    <Tooltip id="button-tooltip" {...props}>
      {text}
    </Tooltip>
  );

  const DropdownIndicator = ({ state, ...props }) => {
    return (
      <components.DropdownIndicator {...props}>

        {searchFormik.values[state]?.length > 1 ? (
          <TbGridDots style={{ fontSize: "1.5em" }} />
        ) : (
          <HiChevronDown style={{ fontSize: "1.5em" }} />
        )}



      </components.DropdownIndicator>
    );
  };
  const handleResetDataTable = () => {
    setRerenderDataTable(true)
    // setTimeout(() => {
    //   setRerenderDataTable(false)
    // }, 200);
  }
  const [isFocusedStatus, setIsFocusedStatus] = useState(false);
  const [loader, setLoader] = useState(false);

  const handleDownload = () => {
    setLoader(true)

    downloadFileManagementSummery(1, limit, searchedData?.search, selectValue, searchedData?.date_range, searchedData?.hour_count, searchedData?.clients, searchedData?.facilities).then((response) => {
      setLoader(false)
      downloadXl(response?.data?.export_file)
    });
  }


  const setFacilityOptions = (clientId) => {
    getFacilityDropdown(clientId).then((response) => {
      if (response && response.status) {
        setFacilities(response.data.facilities?.map(result => {
          return { options: result?.facilities, label: result?.client }

        }))

      }
    });
  }
  const [fullFacilities, setFullFacilities] = useState([])

  const checkSelectedFecilityInitialClient = (selectedOptions) => {

    // Initialize an empty result array and a set to track unique labels
    var result = [...selectValue];
    var uniqueLabels = new Set();
    const clientId = selectValue?.map((value) => String(value?.value))

    // Loop through selected options
    for (var i = 0; i < selectedOptions.length; i++) {
      var selectedOption = selectedOptions[i];

      // Loop through the list of dictionaries to find matching options
      for (var j = 0; j < fullFacilities.length; j++) {
        var options = fullFacilities[j].options;
        for (var k = 0; k < options.length; k++) {
          if (options[k].value === selectedOption.value && options[k].label === selectedOption.label) {
            // Check if the label is unique before adding it to the result
            console.log(clientId, fullFacilities[j].id)
            if (!uniqueLabels.has(fullFacilities[j].label) && !clientId.includes(String(fullFacilities[j].id))) {
              result.push({
                "value": Number(fullFacilities[j].id),
                "label": fullFacilities[j].label,
              });
              uniqueLabels.add(fullFacilities[j].label);
            }
            break;
          }
        }
      }
    }
    console.log("Result:", result);
    return result
  }
  // const handleSelectChange = (val, name = null) => {
  //   console.log(val)
  //   let selectedOptions = [];
  //   let selectedIds = [];

  //   if (val && val?.length > 0) {

  //     val?.map((option) => {
  //       selectedIds.push(option.value)
  //       selectedOptions.push(option)
  //     })

  //   } else {

  //     setSelectedFacility([])
  //     setSelectedFacilityIds([])
  //   }

  //   if (name == "facility") {
  //     const initiaClientsOptions = checkSelectedFecilityInitialClient(selectedOptions)
  //     // const clientIds = initiaClientsOptions?.map((value) => value?.value)
  //     setSelectedFacility(selectedOptions)
  //     setSelectedFacilityIds(selectedIds)
  //     setSelectValue(initiaClientsOptions)
  //     // setFacilityOptions(clientIds)
  //   } else {
  //     // setClients(val.includes('all') ? ['all'] : selectedId)
  //     if (!(val && val?.length > 0)) {
  //       if (clients && clients.length > 0) {
  //         const clientId = clients?.map((value) => value?.value)
  //         setFacilityOptions(clientId)
  //       }

  //     } else {
  //       getFacilityDropdown(selectedIds).then((response) => {
  //         if (response && response.status) {
  //           const feciltyIdArr = []
  //           setFacilities(response.data.facilities?.map(result => {
  //             console.log(result?.facilities, 'EEEEE')
  //             result?.facilities?.map(item => feciltyIdArr.push(item.value));
  //             return { options: result?.facilities, label: result?.client }

  //           }))

  //           const newArray = selectedFacility.filter(item => feciltyIdArr.includes(item.value));
  //           setSelectedFacility(newArray)
  //         }
  //       });

  //     }

  //     setSelectValue(selectedOptions)
  //     setSelectIds(selectedIds)
  //     // setSelectedFacility([])
  //   }
  // };
  const handleSelectChange = (val, name = null) => {
    let selectedOptions = [];
    let selectedIds = [];
    if (val && val?.length > 0) {

      val?.map((option) => {
        selectedIds.push(option.value)
        selectedOptions.push(option)
      })
    }
    if (name == 'facility') {
      setSelectedFacility(selectedOptions)
      setSelectedFacilityIds(selectedIds)
    }
    else if (name == 'client') {
      setSelectedFacility([])
      setFacilities([])
      setSelectValue(selectedOptions)
      setSelectIds(selectedIds)
      const feciltyIdArr = []
      console.log(clients)
      let clientoptions = []
      clients?.map(client => {
        clientoptions.push(client?.value)
      })
      console.log('selectedIds', selectedIds)
      getFacilityDropdown(selectedIds?.length ? selectedIds : clientoptions).then((response) => {
        if (response && response.status) {
          setFacilities(response.data.facilities?.map(result => {
            result?.facilities?.map(item => feciltyIdArr.push(item.value));
            return { options: result?.facilities, label: result?.client }

          }))
          const newArray = selectedFacility.filter(item => feciltyIdArr.includes(item.value));
          setSelectedFacility(selectedIds?.length ? newArray : [])
        }
      });
    }
  }
  useEffect(() => {
    fetchData(1, limit, searchFormik?.values?.search, selectValue, searchedData.date_range, searchFormik?.values?.hour_count, '', '');
    getAllClientDropdown().then((response) => {
      if (response?.status) {
        const data = response?.data
        getFacilityDropdown(data.map((i) => i.value)).then((response) => {
          if (response && response.status) {
            const feciltyIdArr = []
            const FullFacilities = []
            setFacilities(response.data.facilities?.map(result => {
              result?.facilities?.map(item => feciltyIdArr.push(item.value));
              FullFacilities.push({ options: result?.facilities, label: result?.client, id: result?.pk })
              return { options: result?.facilities, label: result?.client, id: result?.pk }

            }))

            setFullFacilities(FullFacilities)
            const newArray = selectedFacility.filter(item => feciltyIdArr.includes(item.value));
            setSelectedFacility(newArray)
          }
        });

        setClients(data)
      }
    })
  }, [])

  console.log(expandedRows);
  console.log(isPayer, isReasonCode);
  return (
    <React.Fragment>
      {/* <Header /> */}
      <Sidebar />
      <main id="main" className="main">

        <div className="pagetitle tab-fixed-header">
          <h1 id='page-title'>Payer Crosswalk</h1>
        </div>

        <section className="section mt-5 ">
          <div className="row align-items-top py-2 gap-2 gap-md-5">
            <div className="col-lg-12">
              <div className="card mb-5 mb-xl-8 mt-3">

                <Form onSubmit={searchFormik.handleSubmit} >
                  <div className=" ">
                    <div className="px-1 py-1 d-flex flex-row justify-content-start gap-2 align-items-center flex-wrap">
                      <div>
                        <Select
                          ref={clientRef}

                          isMulti
                          options={clients}
                          value={selectValue}
                          components={{ DropdownIndicator: (props) => <DropdownIndicator {...props} state={selectValue} /> }}

                          className=""
                          classNamePrefix="select"
                          placeholder="Client"
                          menuPlacement="auto"
                          maxMenuHeight={300}
                          isClearable
                          name="client"
                          onChange={(val) => handleSelectChange(val, 'client')}
                          tabSelectsValue={false}
                          // onKeyDown={(e) => handleKeyDown(e, 'client')}
                          hideSelectedOptions={false}
                          onFocus={() => setIsFocusedClient(true)}
                          onBlur={() => setIsFocusedClient(false)}
                          styles={{
                            container: (styles, state) => ({
                              ...styles,
                              borderRadius: "9px",
                            }),
                            control: (styles, state) => ({
                              ...styles,
                              backgroundColor: "hsl(204deg 33.33% 97.06%)",
                              borderStyle: "none", boxShadow: "none",
                              borderRadius: "10px",
                              maxHeight: "30px",
                            }),
                            indicatorSeparator: styles => ({
                              ...styles, width: "0px",
                              minHeight: "20px",
                              maxHeight: "30px",
                            }),

                            valueContainer: (styles, state) => ({
                              ...styles,
                              maxHeight: "30px",
                              overflow: 'scroll',
                            }),
                            option: (styles, state) => ({
                              ...styles,
                              backgroundColor: state.isSelected ? '#d9f4f5' : 'inherit',
                              color: state.isSelected ? '#000000' : 'inherit',
                              '&:hover': { backgroundColor: state.isSelected ? '#d9f4f5' : 'rgb(222, 235, 255)' }

                            })
                          }}
                          menuPortalTarget={document.body}
                          closeMenuOnSelect={false} // Keep the menu open after selecting an option

                        />
                      </div>
                      <div>
                        <Select
                          tabSelectsValue={false}

                          ref={facilityRef}
                          options={facilities}
                          className=""
                          classNamePrefix="select"
                          placeholder="Facility"
                          menuPlacement="auto"
                          maxMenuHeight={300}
                          isClearable
                          isMulti
                          name="facility"
                          value={selectedFacility}
                          components={{ DropdownIndicator: (props) => <DropdownIndicator {...props} state={selectedFacility} /> }}
                          hideSelectedOptions={false}
                          // onKeyDown={(e) => handleKeyDown(e, 'facility')}

                          onChange={(val) => handleSelectChange(val, 'facility')}
                          onFocus={() => setIsFocusedFacility(true)}
                          onBlur={() => setIsFocusedFacility(false)}

                          styles={{
                            container: (styles, state) => ({
                              ...styles,
                              borderRadius: "9px",
                            }),
                            control: (styles, state) => ({
                              ...styles,
                              backgroundColor: "hsl(204deg 33.33% 97.06%)",
                              borderStyle: "none", boxShadow: "none",
                              borderRadius: "10px",
                              maxHeight: "30px",
                            }),
                            indicatorSeparator: styles => ({
                              ...styles, width: "0px",
                              minHeight: "20px",
                              maxHeight: "30px",
                            }),

                            valueContainer: (styles, state) => ({
                              ...styles,
                              maxHeight: "30px",
                              overflow: 'scroll',
                            }),
                            option: (styles, state) => ({
                              ...styles,
                              backgroundColor: state.isSelected ? '#d9f4f5' : 'inherit',
                              color: state.isSelected ? '#000000' : 'inherit',
                              '&:hover': { backgroundColor: state.isSelected ? '#d9f4f5' : 'rgb(222, 235, 255)' }

                            })
                          }}
                          menuPortalTarget={document.body}
                          closeMenuOnSelect={false} // Keep the menu open after selecting an option

                        />
                      </div>
                      <Tooltip disableInteractive title={"Processed Date"} placement="top" arrow>

                        <div className="correspondenceDatePicker">
                          <Stack direction="column" spacing={8} alignItems="flex-start">

                            <DateRangePicker
                              // style={{ backgroundColor: "#000" }}
                              ranges={predefinedBottomRanges}
                              cleanable={false}
                              placement="auto"
                              appearance="default" format={"MM/dd/yyyy"}
                              className="form-date-control form-control-solid b-r-1 text-dark"
                              placeholder={"MM/DD/YYYY - MM/DD/YYYY"}
                              name="date_range"
                              onChange={(e) => { handleOnDateTimeChange({ name: 'date_range', value: e }) }} value={searchFormik?.values?.date_range} />
                          </Stack>
                        </div>
                      </Tooltip>

                      <div>
                        <Tooltip disableInteractive title={"Search"} placement="top" arrow>
                          <Button className="btn btn-light btn-active-primary" type="submit">
                            <span className="svg-icon svg-icon-3">
                              <BiSearch />
                            </span>

                          </Button>
                        </Tooltip>
                      </div>
                      <div>
                        <Tooltip disableInteractive title={"Download"} placement="top" arrow>
                          {!loader ?
                            <Button onClick={() => { handleDownload() }} className="btn btn-light btn-active-primary" >
                              <span className="svg-icon svg-icon-3">
                                <BsCloudDownloadFill />
                              </span>

                            </Button> :
                            <Button className="btn btn-light btn-active-primary" >
                              <span className="svg-icon svg-icon-3">
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                              </span>

                            </Button>}
                        </Tooltip>

                      </div>


                    </div>
                  </div>

                </Form>


                <div className="card-body p-common">
                  <div className="table-responsive">
                    <div className="dashboardtable">
                      {/* {!reRenderDataTable && */}
                      <DataTable
                        className="custom-datatable"
                        paginationDefaultPage={pageno}

                        fixedHeader={true}
                        progressPending={loading}
                        data={data?.results}
                        columns={columns}
                        pointerOnHover={true}
                        pagination
                        paginationServer
                        onRowClicked={handleRowClick}
                        // highlightOnHover
                        paginationTotalRows={totalRows}
                        paginationPerPage={limit}
                        paginationRowsPerPageOptions={[25, 50, 100, 150, 200]}
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                        conditionalRowStyles={conditionalRowStyles}
                        theme="solarized"
                        customStyles={datatableCustomStyles}
                        striped={true}

                        expandableRows
                        expandOnRowClicked={false}
                        onRowExpandToggled={handleRowExpand}
                        expandableRowsComponent={ExpandedComponent}
                        expandableRowsHideExpander
                        expandableRowExpanded={(row) => expandedRows.includes(row)}

                      />
                      {/* } */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>



      </main>
    </React.Fragment >
  )
}
export default FileManagement




