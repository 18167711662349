import moment from "moment";
import React, { useEffect } from "react";
import { Form, InputGroup } from "react-bootstrap";
import InputMask from "react-input-mask";
import { QA_REVIEW, EDIT_WORK } from "../../../../../functions"
const PatientPay = (props) => {
  const {
    formik,
    jobData,

    handleFocusPatientPay,
    isDbkPermission,
    handlePatientPayData,

    isPatientPageFromTo,

    currentPage,
    job_id,
    log_id
  } = props;

  useEffect(() => {
    handlePatientPayData();
  }, []);



  const pathname = window.location.pathname;
  const gridData = jobData?.grid_data;
  console.log(jobData?.grid_data);
  const qaCorrectedData = gridData?.qa_corrected_data;
  const renderClass = (name, type, defaultClass) => {
    if (formik.errors?.[name]) {
      return defaultClass + ' error-field'
    }
    if (pathname == QA_REVIEW) {

      if (qaCorrectedData &&
        qaCorrectedData?.[name]) {
        return defaultClass + ' qa-field'
      }
      else if (type == 'DATE' && moment(formik.values?.[name]).format("MM/DD/YYYY") != moment(gridData?.[name]).format("MM/DD/YYYY")) {
        return defaultClass + ' qa-field'
      }
      else if (type == 'TEXT' && formik.values?.[name]?.toUpperCase() !=
        gridData?.[name]?.toUpperCase()) {
        return defaultClass + ' qa-field'
      }
      else if (type == 'NUMBER' && parseFloat(formik.values?.[name] ? formik.values?.[name] : 0).toFixed(2) !=
        parseFloat(gridData?.[name] ?
          gridData?.[name] : 0).toFixed(2)) {
        return defaultClass + ' qa-field'
      }

      else if (gridData?.transaction_type != "PATIENT_PAY") {
        return defaultClass + ' qa-field'
      }
      else {
        return defaultClass;
      }


    }
    else if ((pathname == EDIT_WORK + job_id || pathname == EDIT_WORK + job_id + "?log_id=" + log_id) &&
      qaCorrectedData &&
      qaCorrectedData?.[name]) {
      return defaultClass + ' qa-field'
    }
    else {
      return defaultClass;
    }
  }
  return (
    <React.Fragment>
      <div>
        <div
          className="topblock-wrapper patient_pay_block accordion-collapse collapse show"
          id="collapseOne"
          aria-labelledby="headingOne"
          data-bs-parent="#payment835"
        >
          <Form
            autoComplete={"off"}
            noValidate
            id="payment-835-form"
            onSubmit={formik.handleSubmit}
            onReset={formik.handleReset}
          >
            <div className="sub-section">
              <div className="">
                <Form.Label
                  tabIndex="-1"
                  className="btn btn-payment835"
                >
                  Patient Pay
                </Form.Label>
              </div>
              <div className="checknum-wi">
                <InputGroup>
                  <InputGroup.Text>Check Number</InputGroup.Text>

                  <div className="dbk-main-container">
                    <Form.Control
                      type="text"
                      className={renderClass('check_number', 'TEXT', 'w-78')}

                      name="check_number"

                      value={formik.values.check_number}

                    />

                  </div>
                </InputGroup>
              </div>
              <div className="checkdate-wi">
                <InputGroup>
                  <InputGroup.Text>Check Date</InputGroup.Text>
                  <div className="dbk-main-container">
                    <InputMask
                      mask="99/99/9999"
                      className={renderClass('check_date', 'DATE', 'w-48')}

                      style={{ fontSize: "0.7em" }}

                      value={formik.values?.check_date}
                      name="check_date"
                      placeholder={"mm/dd/yyyy"}

                    />

                  </div>
                </InputGroup>
              </div>
              <div className="checkamt-wi">
                <InputGroup>
                  <InputGroup.Text>Check Amount</InputGroup.Text>
                  <div className="dbk-main-container1">
                    <Form.Control
                      type="number"


                      value={formik.values?.check_amount}
                      className={renderClass('check_amount', 'NUMBER', 'w-78 text-end')}

                      name="check_amount"
                    />

                  </div>
                </InputGroup>
              </div>
            </div>
            <div className="sub-section">
              <div className="">
                <Form.Label
                  tabIndex="-1"
                  className="btn btn-payment835"
                >
                  Patient{" "}
                </Form.Label>
              </div>
              <div className="wrapper-patient">
                <InputGroup>
                  <InputGroup.Text>Patient Account Number</InputGroup.Text>
                  <div className="dbk-main-container">
                    <Form.Control
                      type="text"
                      name={`patient_account_number`}

                      value={formik.values?.patient_account_number}
                      className={renderClass('patient_account_number', 'TEXT', 'w-patient')}


                    />

                  </div>
                </InputGroup>
              </div>
              <div className="wrapper-patient">
                <InputGroup>
                  <InputGroup.Text>First Name</InputGroup.Text>
                  <div className="dbk-main-container">
                    <Form.Control
                      type="text"
                      name={`patient_first_name`}

                      value={formik.values?.patient_first_name}

                      className={renderClass('patient_first_name', 'TEXT', 'w-fsname')}

                    />


                  </div>
                </InputGroup>
              </div>
              <div className="wrapper-miname">
                <InputGroup>
                  <InputGroup.Text>Middle Name</InputGroup.Text>
                  <div className="dbk-main-container">
                    <Form.Control
                      type="text"
                      name={`patient_middle_name`}

                      value={formik.values?.patient_middle_name}
                      className={renderClass('patient_middle_name', 'TEXT', 'w-fsname')}


                    />

                  </div>
                </InputGroup>
              </div>
              <div className="wrapper-patient">
                <InputGroup>
                  <InputGroup.Text>Last Name</InputGroup.Text>
                  <div className="dbk-main-container">
                    <Form.Control
                      type="text"
                      name={`patient_last_name`}


                      value={formik.values?.patient_last_name}

                      className={renderClass('patient_last_name', 'TEXT', 'w-fsname')}

                    />

                  </div>
                </InputGroup>
              </div>
              {isPatientPageFromTo &&
                <div className='pagefrom-corress'>
                  <div className="input-group">
                    <label className="input-group-text">
                      Page From
                    </label>
                    <Form.Control
                      className={renderClass('patient_page_from', 'NUMBER', 'pagefrom')}

                      onChange={formik.handleChange}
                      min={1}
                      type="number"

                      value={formik.values?.patient_page_from}
                      name={`patient_page_from`}
                    />
                  </div>
                </div>
              }
              {isPatientPageFromTo &&
                <div className='pageto-corress'>
                  <div className="input-group">
                    <label className="input-group-text">
                      Page To
                    </label>
                    <Form.Control
                      className={renderClass('patient_page_to', 'NUMBER', 'pagefrom')}

                      min={1}
                      type="number"

                      value={formik.values?.patient_page_to}
                      name={`patient_page_to`}
                    />
                  </div>
                </div>
              }

            </div>
            <div className="sub-section">
              <div className="">
                <Form.Label
                  tabIndex="-1"
                  className="btn btn-payment835"
                >
                  Payment Details{" "}
                </Form.Label>
              </div>
              <div className="wrapper-patient">
                <InputGroup>
                  <InputGroup.Text>Amount Due</InputGroup.Text>
                  <div className="dbk-main-container">
                    <Form.Control
                      type="number"
                      name={`amount_due`}

                      value={formik.values?.amount_due}
                      className={renderClass('amount_due', 'NUMBER', 'w-fsname ps-1 text-end')}


                    />

                  </div>
                </InputGroup>
              </div>
              <div className="wrapper-miname">
                <InputGroup>
                  <InputGroup.Text>Statement Number</InputGroup.Text>
                  <div className="dbk-main-container">
                    <Form.Control
                      type="text"
                      onFocus={() => {
                        handleFocusPatientPay("statement_number", isDbkPermission?.patient_pay?.patientpay_payment_statement_number)
                      }}
                      name={`statement_number`}

                      value={formik.values?.statement_number}
                      className={renderClass('statement_number', 'TEXT', 'w-fsname')}

                    />

                  </div>
                </InputGroup>
              </div>
              <div className="wrapper-miname">
                <InputGroup>
                  <InputGroup.Text>Statement Amount</InputGroup.Text>
                  <div className="dbk-main-container">
                    <Form.Control
                      type="number"

                      name={`statement_amount`}

                      value={formik.values?.statement_amount}

                      className={
                        "w-fsname ps-1 text-end"
                      }

                    />

                  </div>
                </InputGroup>
              </div>
              <div className="wrapper">
                <InputGroup>
                  <InputGroup.Text>Statement Date</InputGroup.Text>
                  <div className="dbk-main-container">
                    <InputMask
                      mask="99/99/9999"
                      className={renderClass('statement_date', 'DATE', 'w-30')}

                      style={{ fontSize: "0.7em" }}

                      value={formik.values?.statement_date}
                      name={`statement_date`}
                      placeholder={"mm/dd/yyyy"}

                    />


                  </div>
                </InputGroup>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PatientPay;
