import React from 'react'
import Select, { components } from 'react-select';

function SingleSelect(props) {
    const { is_clear, formik, placeholder, name, list } = props
    const value = list.find((i) => i.value == formik?.values?.[name])
    console.log(is_clear)
    return (
        <React.Fragment>
            <Select
                tabSelectsValue={false}
                options={list}
                className="select-search"
                classNamePrefix="select"
                placeholder={placeholder}
                isClearable={is_clear ? false : true}
                name={name}
                onChange={(e) => formik.setValues({ ...formik.values, [name]: e?.value ?? "" })}
                defaultValue={value}
                styles={{
                    container: (styles, state) => ({
                        ...styles,
                        borderRadius: "9px",
                    }),
                    control: (styles, state) => ({
                        ...styles,
                        backgroundColor: "hsl(204deg 33.33% 97.06%)",
                        borderStyle: "none", boxShadow: "none",
                        borderRadius: "10px",
                        maxHeight: state.isFocused ? "auto" : "30px",
                    }),
                    indicatorSeparator: styles => ({
                        ...styles, width: "0px", minHeight: "20px"
                    }),
                }}
            />
            {formik.errors?.[name] &&
                <div>
                    <span className='text-danger'>{formik.errors?.[name]}</span>
                </div>
            }
        </React.Fragment>
    )
}

export default SingleSelect