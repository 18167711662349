import React, { useEffect, useState } from 'react'
import SingleReport from './singleReport';
import AllReport from './allReport';
import { DashboardService } from '../../../../services/DashboardService';
import moment from 'moment';
import Modal from 'react-modal'
import DetailsReport from './detailsReport';
import { VscGraph } from "react-icons/vsc";
import { MdDonutLarge } from "react-icons/md";
import AllReportGraph from './allReportGraph';
import SingleReportGraph from './singleReportGraph';
import styled from "styled-components/macro";
import ModalSkelitonLoader from '../../loader/ModalSkelitonLoader';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

const ProductivityReport = ({ graphType, setGraphType, setLoaderState, loaderState, data, searchFormik, update }) => {
    const [modalLoader, setModalLoader] = useState(false)
    const [showSingle, setShowSingle] = useState(false)
    const [singleData, setSingleData] = useState([])
    const [userData, setUserData] = useState([])
    const [modalIsOpen, setModalIsOpen] = useState(false)
    // const [graphType, setGraphType] = useState('DONUT')
    const dashboardService = new DashboardService();
    const [clientId, setClientId] = useState('')
    const [singleColor, setSingleColor] = useState('')

    const { getProductivitySingleReport } = dashboardService
    let inner = false
    const handleClickData = (event, chartContext, { dataPointIndex, seriesIndex, w }) => {
        if (data && data[dataPointIndex]) {
            let items = [data[dataPointIndex].calc_payment835, data[dataPointIndex].calc_payment835_lite, data[dataPointIndex].calc_patientpay, data[dataPointIndex].calc_correspondance]
            setSingleData(items)
            setSingleColor(w.config.colors[dataPointIndex])
            setClientId(data[dataPointIndex].client_id)
            setTimeout(() => {
                setShowSingle(true)
            }, 500);
        }
    }
    const handleClickInnerGraph = () => {
        if (inner) {
            inner = false
        }
        else {
            setTimeout(() => {
                setClientId('')
                setShowSingle(false)
            }, 500);
        }
    }
    const handleClickInnerData = (event, chartContext, { dataPointIndex, seriesIndex, w }) => {
        setModalLoader(true)
        setModalIsOpen(true)
        inner = true
        const full_types = ['calc_payment835', 'calc_payment835_lite', 'calc_patientpay', 'calc_correspondance']
        const types = []

        singleData?.forEach((i, index) => {
            if (i != 0) {
                types.push(full_types[index])
            }
        })


        let date = moment(searchFormik.values.date_range[0]).format('MM/DD/YYYY') + ' - ' + moment(searchFormik.values.date_range[1]).format('MM/DD/YYYY')
        getProductivitySingleReport({ date_range: date, partners: searchFormik?.values?.partners, clients: searchFormik?.values?.clients, facilities: searchFormik?.values.facilities, client_id: clientId, transaction_type: types[dataPointIndex] }).then((response) => {
            if (response && response.status) {
                setUserData(response?.data)
                setModalLoader(false)
            }
        });
    }
    const handleGraphChange = (type) => {
        setGraphType(type)
    }
    useEffect(() => {
        setShowSingle(false)
    }, [update])
    return (
        <div className='card  p-2' >

            <div className='d-flex align-items-center justify-content-between'>
                <div className='mt-2' style={{ fontWeight: '500', fontSize: '20px', marginLeft: '30px', color: '#012970' }}>Productivity Report</div>
                {graphType == 'DONUT' ?
                    <div onClick={() => handleGraphChange('GRAPH')}><VscGraph size={25} /></div>
                    :
                    <div onClick={() => handleGraphChange('DONUT')}><MdDonutLarge size={25} /></div>
                }
            </div>
            <div style={{ height: '45vh', overflow: graphType === 'DONUT' ? 'visible' : 'auto' }}>
                {showSingle ?
                    graphType == 'DONUT' ?
                        <SingleReport isVisible={loaderState.productivityReportLoader} handleClickInnerGraph={handleClickInnerGraph} handleClickInnerData={handleClickInnerData} singleData={singleData} singleColor={singleColor} />
                        :
                        <SingleReportGraph isVisible={loaderState.productivityReportLoader} handleClickInnerGraph={handleClickInnerGraph} handleClickInnerData={handleClickInnerData} singleData={singleData} singleColor={singleColor} update={update} />
                    :
                    graphType == 'DONUT' ?
                        <AllReport isVisible={loaderState.productivityReportLoader} data={data} handleClickData={handleClickData} />
                        :
                        <AllReportGraph isVisible={loaderState.productivityReportLoader} setLoaderState={setLoaderState} searchFormik={searchFormik} handleClickData={handleClickData} update={update} />
                }

                <Modal
                    isOpen={modalIsOpen}
                    ariaHideApp={false}
                    onRequestClose={() => { setModalIsOpen(false) }}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <div>
                        {modalLoader ?
                            <ModalSkelitonLoader /> :
                            <DetailsReport data={userData} singleColor={singleColor} />
                        }
                    </div>
                </Modal>
            </div>
        </div>
    )
}

export default ProductivityReport

