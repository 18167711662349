import React from 'react'
import Modal from "react-modal";
import { QA_REVIEW } from '../../../../../functions';

const FileInfoModal = ({ setInfoModal, infoModal, jobData, file_info, totalPages, setIsDrag }) => {

    const customInfoStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: "60%",
        },
    };
    return (
        <React.Fragment>
            <Modal
                isOpen={infoModal}
                onRequestClose={() => { setInfoModal(false); setIsDrag(true); }}
                style={customInfoStyles}
                contentLabel="Example Modal"
            >

                <div className="modal-custome-header">
                    <h3>File Info</h3>
                    <span style={{ cursor: 'pointer' }} onClick={() => { setInfoModal(false); setIsDrag(true); }} className='close-icon'><i className="bi bi-x-square"></i></span>
                </div>

                <table className="table info-log-table" >
                    <thead>
                        <tr >
                            <th >Client Name</th>
                            <td>{file_info?.client_name}</td>
                        </tr>
                        <tr>
                            <th >Facility Name</th>
                            <td>{file_info?.facility_name}</td>

                        </tr>
                        <tr>
                            <th >File Name</th>

                            <td>{file_info?.file_name}</td>
                        </tr>
                        <tr>
                            <th >Job Id</th>
                            <td>{file_info?.job_unique_id}</td>

                        </tr>
                        <tr>
                            <th >Number of pages</th>
                            <td>{totalPages}</td>

                        </tr>
                        {window.location.pathname == QA_REVIEW && !jobData?.is_large_batch_job &&
                            <tr >
                                <th >Grid User</th>
                                <td>{file_info?.user_name}</td>
                            </tr>
                        }



                        {jobData?.is_large_batch_job &&
                            <tr >
                                <th>Batch Type</th>
                                <td>Large Batch File</td>
                            </tr>
                        }

                        {file_info?.comment &&
                            <tr>
                                <th>Comment</th>
                                {jobData?.file_info?.comment ?
                                    <td>
                                        {/* {jobData?.is_large_batch_job ? */}
                                        {jobData?.file_info?.comment.split(',\n\r').map((line, index) => (
                                            <React.Fragment>
                                                <span className='text-uppercase'> {line}</span>
                                                <br />

                                            </React.Fragment>
                                        ))}
                                    </td>
                                    :
                                    <td>{file_info?.comment}</td>
                                }
                            </tr>
                        }
                    </thead>

                </table>

                {/* <div className="checkviewbtn">
                            <button className="btn btn-primary" onClick={()=>{setInfoModal(false)}}>close</button>
                        </div> */}
                {/* </Modal.Body> */}
            </Modal>
        </React.Fragment>
    )
}

export default FileInfoModal