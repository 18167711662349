import React from 'react';
import { FloatingLabel, Form, Toast, ToastContainer } from 'react-bootstrap';
import logo from '../../assets/img/Xtract-Analytics.png';
import aventus from '../../assets/img/aventus.png';
import { useState, useEffect } from 'react';
// import { getAllPermissions, getDeviceDetails, loginUser, logooutSession } from '../../Redux/auth/action';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { AuthService } from '../../services/AuthService';
import { useDispatch } from 'react-redux';
import { setSignOutState, setUserDetails } from '../../slice/user/userSlice';
import { Link, useNavigate } from 'react-router-dom';
import { AiOutlineCopyright } from 'react-icons/ai';
import { requestForToken } from '../../app/firebase';
import { initializeApp } from 'firebase/app';
import { getMessaging, isSupported, Messaging, getToken } from 'firebase/messaging';
import moment from 'moment';

const firebaseConfig = {
    // apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    // authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    // databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    // projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    // storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    // messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    // appId: process.env.REACT_APP_FIREBASE_APP_ID,
    apiKey: "AIzaSyCNXEXg76ad2aFikGOLe-_KaEgOfaOfIQg",
    authDomain: "xtract-uat-analytics.firebaseapp.com",
    projectId: "xtract-uat-analytics",
    storageBucket: "xtract-uat-analytics.appspot.com",
    messagingSenderId: "99339273135",
    appId: "1:99339273135:web:4e12e3ca9641344725e55a",
    measurementId: "G-PZ88Z0EF9F"
};


const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);


const Login = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const authService = new AuthService();
    const { userLogin, userLogout } = authService
    const [showLogout, setShowLogout] = useState(false);
    const [showPassword, setShowPassword] = useState(false)
    const [showRefresh, setShowRefresh] = useState(false);

    const [state, setState] = useState({
        error: false,
        error_message: ""
    })

    const SignupSchema = Yup.object().shape({
        username: Yup.string()
            .required('Required')
            .min(3, 'Too Short! Enter Valid Username'),
        password: Yup.string()
            .min(3, 'Password must be at least 6 characters')
            .required('Required'),
    });

    const logOut = async (setErrors) => {
        let formData = new FormData();    //formdata object
        formData.append('refresh', showRefresh);   //append the values with key, value pair

        userLogout(formData).then((response) => {
            if (response?.status) {
                dispatch(setSignOutState())
                localStorage.removeItem('token')
                localStorage.removeItem('refresh')
                setShowLogout(false)
                setErrors({
                    username: "",
                    password: ""
                })

            }

        })
    }

    const setUser = (user) => {
        dispatch(
            setUserDetails(user)
        );
    };
    const [usenameFocus, setUsenameFocus] = useState(false);
    const [passwordFocus, setPasswordFocus] = useState(false);
    useEffect(() => {
        dispatch(setSignOutState())
        localStorage.removeItem('token')
        localStorage.removeItem('refresh')
        const callFCBFun = async () => {
            const permission = await Notification.requestPermission();
            const supported = await isSupported()
            if (!supported) {
                throw new Error("NOTIFICATIONS_NOT_SUPPORTED")
            }
            try {
                const currentToken = await getToken(messaging, { vapidKey: 'BFDhmm8B91GqO-heSas_FaI64dgVTMAbkXECcjwhpCxYkMt9QhiYGyTZ7HWQZV3dMqJGv0EeAfvhVadyD3Ot5IA' });

                await getToken(messaging, { vapidKey: 'BFDhmm8B91GqO-heSas_FaI64dgVTMAbkXECcjwhpCxYkMt9QhiYGyTZ7HWQZV3dMqJGv0EeAfvhVadyD3Ot5IA' });
            } catch (err) {
                console.log('FCB An error occurred while retrieving token.', err);
            }

            // await getToken(messaging, { vapidKey: 'BFDhmm8B91GqO-heSas_FaI64dgVTMAbkXECcjwhpCxYkMt9QhiYGyTZ7HWQZV3dMqJGv0EeAfvhVadyD3Ot5IA' }).then((currentToken) => {
            //     console.log("&*((^*&")
            //     if (currentToken) {
            //         alert("**currentToken***")
            //         // Send the token to your server and update the UI if necessary
            //         // ...
            //     } else {
            //         // Show permission request UI
            //         alert("**currenELSEEtToken***")
            //         console.log('No registration token available. Request permission to generate one.');
            //         // ...
            //     }
            // }).catch((err) => {
            //     alert("**catch***")
            //     console.log('An error occurred while retrieving token. ', err);
            //     // ...
            // });

        }
        callFCBFun()
    }, [])
    const setLoginTitle = () => {
        const domain = window.location.origin;
        let value = ""
        console.log(domain)
        switch (domain) {
            case "https://uat-analytics.db-xtract.com":
                value = "Sign in - UAT"
                break;
            case "https://training-analytics.db-xtract.com":
                value = "Sign in - TRAINING"
                break
            case "https://analytics-demo.db-xtract.com":
                value = "Sign in - DEMO"
                break
            default:
                value = "Sign in"
        }
        return value
    }
    return (
        <React.Fragment>
            <ToastContainer className="p-3" position={"top-end"}>
                <Toast bg="danger" show={state.error ? true : false} onClose={() => setState({ error: false, error_message: '' })} animation={true} delay={2000} autohide>
                    <Toast.Body ><span className='text-white'>{state.error_message}</span></Toast.Body>
                </Toast>

            </ToastContainer>
            <section className='loginForm'>
                <div className='container'>
                    <div className='row align-items-center'>
                        <div className='col-lg-6 d-flex align-items-center text-center'>
                            <div class="login_form_leftwrapper">
                                <img src={logo} className='img-fluid mb-5' alt="xtract" />
                                {/* <div className='copy_right'>Copyright <AiOutlineCopyright /> 2023 <Link className='link_datablocks' to='https://data-blocks.com' target="_blank">DataBlocks Inc.</Link> All rights reserved.</div> */}
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='logFont'>
                                <h1 class="loginLabel">{setLoginTitle()} </h1>
                            </div>
                            <div className='d-flex flex-column align-items-center mt-3' >

                                <div className='w-60'>
                                    <Formik
                                        initialValues={{ username: "", password: "" }}
                                        // onSubmit={(e) => __showDataEntry(e)}
                                        onSubmit={(values, actions) => {

                                            let formData = new FormData();    //formdata object

                                            formData.append('username', values.username);   //append the values with key, value pair
                                            formData.append('password', values.password);


                                            userLogin(formData).then((response) => {

                                                console.log(response);
                                                console.log(response?.data?.user);
                                                if (response?.status) {
                                                    const data = {
                                                        user_id: response?.data?.user?.id,
                                                        username: response?.data?.user?.username,
                                                        email: response?.data?.user?.email,
                                                        permissions: response?.data?.user?.permissions,
                                                        is_password_reset: response?.data?.user?.is_password_reset,
                                                        is_superuser: response?.data?.user?.is_superuser,
                                                        sync_time: response?.data?.user?.sync_time,
                                                        token: response?.data?.token,
                                                        refresh: response?.data?.refresh,
                                                        is_login: true,
                                                    }
                                                    if (data.user_id) {
                                                        requestForToken()
                                                    }
                                                    localStorage.setItem('token', response?.data?.token)
                                                    localStorage.setItem('refresh', response?.data?.refresh)
                                                    setUser(data)
                                                    navigate('/');

                                                }
                                                else if (response?.status_code === 207) {
                                                    setShowLogout(true)
                                                    localStorage.setItem('token', response?.data?.token)
                                                    setShowRefresh(response?.data?.refresh)
                                                    actions.setErrors({
                                                        username: response?.message,
                                                        password: response?.message
                                                    })
                                                }
                                                else if (response?.response?.status === 401) {
                                                    setState({ error: true, error_message: response?.response?.data?.message });
                                                    actions.setErrors({
                                                        username: response?.response?.data?.message,
                                                        password: response?.response?.data?.message
                                                    })
                                                }

                                                else {
                                                    console.log(response)
                                                    setState({ error: true, error_message: response?.message });
                                                    actions.setErrors({
                                                        username: response?.message,
                                                        password: response?.message,
                                                    })

                                                }
                                            })

                                        }}
                                        validationSchema={SignupSchema}

                                    >
                                        {({
                                            handleSubmit,
                                            handleChange,
                                            values,
                                            touched,
                                            isValid,
                                            errors,
                                            setErrors
                                        }) => (

                                            <Form
                                                onSubmit={handleSubmit}
                                            >

                                                <FloatingLabel
                                                    controlId="floatingInput"
                                                    label="Username"
                                                    className="input-padding"
                                                    style={usenameFocus ? { zIndex: '1' } : { zIndex: '0' }}


                                                >
                                                    <Form.Control
                                                        autoComplete='one-time-code'
                                                        name='username'
                                                        type="text"
                                                        onChange={handleChange}
                                                        // isValid={touched.username && !errors.username}
                                                        isInvalid={!!errors.username}
                                                        value={values.username}
                                                        onFocus={() => { setUsenameFocus(true) }}
                                                        onBlur={() => { setUsenameFocus(false) }}
                                                        className='b-r-t'
                                                        required
                                                    // placeholder="name@example.com"
                                                    />

                                                    {/* <Form.Control.Feedback type="invalid">{errors.username}</Form.Control.Feedback> */}
                                                </FloatingLabel>
                                                <FloatingLabel
                                                    // hasValidation
                                                    controlId="floatingPassword"
                                                    label="Password"
                                                    style={passwordFocus ? { zIndex: '1' } : { zIndex: '0' }}
                                                >

                                                    <Form.Control
                                                        autoComplete='one-time-code'
                                                        name='password'
                                                        type={showPassword ? "text" : "login-password"}
                                                        onChange={handleChange}
                                                        required
                                                        // isValid={touched.password && !errors.password}
                                                        isInvalid={!!errors.password}
                                                        value={values.password}
                                                        onFocus={() => { setPasswordFocus(true) }}
                                                        onBlur={() => { setPasswordFocus(false) }}
                                                        minLength="3"
                                                        className='b-r-b'
                                                    // placeholder="Password"
                                                    />
                                                    <div className='' style={{ position: 'absolute', top: '20px', right: '35px', cursor: 'pointer' }} onClick={() => setShowPassword(!showPassword)}  >
                                                        <i className={showPassword ? 'bi bi-eye' : 'bi bi-eye-slash'}></i>
                                                    </div>
                                                    {console.log(errors.password)}
                                                    {errors.password && showLogout &&
                                                        <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                                                    }
                                                    {errors.password && showLogout &&
                                                        <div style={{ display: 'flex' }} >
                                                            <div className='custome-invalid-feedback'>Do you want to logout from the system? &nbsp; </div>

                                                            <button style={{ background: '#176c89', color: 'white' }} onClick={() => { logOut(setErrors) }} class="btn" type="button">
                                                                Logout
                                                            </button>

                                                        </div>
                                                    }

                                                </FloatingLabel>
                                                {state.error &&
                                                    <div className='text-center mt-3'>

                                                        <span className='text-danger fw-bold '>{state.error_message}</span>
                                                    </div>
                                                }
                                                {!showLogout &&
                                                    <div className='buttonLogin mt-4'>

                                                        <button
                                                            type='submit'
                                                            className='btn btn-primary new-window'
                                                        >
                                                            Login
                                                        </button>
                                                    </div>
                                                }
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="login-footer-position"><span class="" style={{ color: "rgb(23, 108, 137)", fontSize: "0.8em" }}>Copyright <i class="bi bi-c-circle  fw-bolder"></i> {moment().year()} <a href="http://www.zaparetech.com/" target="_blank" class="" style={{ textDecoration: "none", color: "rgb(23, 108, 137)" }} rel="noreferrer">DataBlocks Inc dba Zapare Technologies.</a> All rights reserved.</span></div>
            </section>

        </React.Fragment>
    );
}

export default Login;
