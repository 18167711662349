import moment from 'moment';
import React from 'react'
import { Table } from 'react-bootstrap';

const ClaimSummaryLite = (props) => {
    const { formik, jumpToPage, setClaimLiteIndex, setClaim835, setControlledPosition, setState } = props;

    const handleClaimBilledTotal = (array) => {
        const sum = array.reduce((acc, item) => acc + parseFloat(item?.service_lines?.billed ? item?.service_lines?.billed : 0), 0)



        return parseFloat(sum).toFixed(2);

    }

    const handleClaimPaidTotal = (array) => {
        const sum = array.reduce((acc, item) => acc + parseFloat(item?.service_lines?.paid ? item?.service_lines?.paid : 0), 0)
        return parseFloat(sum ? sum : 0).toFixed(2);

    }

    const handleRowClicked = (row) => {
        jumpToPage(row?.page_to - 1)
        // setClaim835Index(index)

    }

    const handleRowDoubleClicked = (row, index) => {
        setState((prevState) => {
            return {
                ...prevState,
                updateDisable: !prevState.updateDisable,
            }
        })
        setClaim835(false)
        jumpToPage(row?.page_to - 1)
        setClaimLiteIndex(index)
        setControlledPosition({ x: 0, y: 581 });

    }


    return (
        <div className="topblock-wrapper eob_block accordion-collapse collapse show" id="claimssummary" aria-labelledby="claimssummary" data-bs-parent="#claimSummary">
            <div className="">
                <div className='table-responsive'>
                    <Table striped bordered hover >
                        <thead>
                            <tr>
                                <th>#</th>
                                <th style={{ width: '17rem' }}>Patient Account Number</th>
                                {/* className='text-end' <th style={{ width: '17rem' }}>Payer Claim Number</th> */}
                                <th style={{ width: '10rem' }}></th>
                                <th className='text-end' style={{ width: '5rem' }}>Billed </th>
                                <th className='text-end' style={{ width: '8rem' }}>Paid Amount</th>
                                <th >Patient Lastname</th>
                                <th >Patient Firstname</th>
                                <th >Period Start</th>
                                <th >Period End</th>

                            </tr>
                        </thead>
                        <tbody>
                            {formik.values?.claims && formik.values?.claims.length > 0 && formik.values?.claims.map((claim, index) => (


                                <tr onClick={() => handleRowClicked(claim)} onDoubleClick={() => handleRowDoubleClicked(claim, index)} key={index}>
                                    <td>{index + 1}</td>
                                    <td className='text-uppercase'>{claim?.patient_account_number ? claim?.patient_account_number : "---------"}</td>
                                    <td></td>
                                    <td className='text-end'>{claim?.service_lines?.billed ? parseFloat(claim?.service_lines?.billed).toFixed(2) : parseFloat(0).toFixed(2)}</td>
                                    <td className='text-end'>{claim?.service_lines?.paid ? parseFloat(claim?.service_lines?.paid).toFixed(2) : parseFloat(0).toFixed(2)}</td>
                                    <td className='text-uppercase'>{claim?.patient_last_name ? claim?.patient_last_name : "---------"}</td>
                                    <td className='text-uppercase'>{claim?.patient_first_name ? claim?.patient_first_name : "---------"}</td>
                                    <td>{claim?.service_lines?.period_start ? moment(claim?.service_lines?.period_start).format('MM/DD/YYYY') : "---------"}</td>
                                    <td>{claim?.service_lines?.period_end ? moment(claim?.service_lines?.period_end).format('MM/DD/YYYY') : "---------"}</td>


                                </tr>

                            ))}
                            <tr className='border-top-bottom-dark-2'>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>

                            </tr>
                            <tr className=''>

                                <td></td>
                                <td></td>
                                <td className='fw-bolder text-end table-padding'>Claim Total:</td>
                                <td className='fw-bolder table-padding text-end'>{handleClaimBilledTotal(formik.values?.claims)}</td>
                                <td className='fw-bolder table-padding text-end'>{handleClaimPaidTotal(formik.values?.claims)}</td>
                                <td></td>
                                <td></td>
                                <td></td>

                            </tr>
                            <tr className=''>

                                <td></td>
                                <td></td>
                                <td className='fw-bolder text-end table-padding'>Check Total:</td>
                                <td ></td>
                                <td className='fw-bolder table-padding text-end'>{isNaN(formik.values?.check_amount) ? parseFloat(0).toFixed(2) : formik.values?.check_amount ? parseFloat(formik.values?.check_amount ? formik.values?.check_amount : 0).toFixed(2) : parseFloat(0).toFixed(2)}</td>
                                <td></td>
                                <td></td>
                                <td></td>

                            </tr>
                            <tr className=''>

                                <td></td>
                                <td></td>
                                <td className='fw-bolder text-end table-padding'>Diff:</td>
                                <td ></td>
                                <td className='fw-bolder table-padding text-end'>{parseFloat(formik.values?.remaining_balance ? formik.values?.remaining_balance : 0).toFixed(2)}</td>
                                <td></td>
                                <td></td>
                                <td></td>

                            </tr>
                            {formik.values?.plb_adjustments?.map((plb, index) => (
                                <tr className='' key={index}>

                                    <td></td>
                                    <td></td>
                                    <td className='fw-bolder text-end table-padding'>PLB Adj diff:</td>
                                    <td></td>
                                    <td className='fw-bolder table-padding text-end'>{plb?.plb_amt ? parseFloat(plb.plb_amt ? plb.plb_amt : 0).toFixed(2) : parseFloat(0).toFixed(2)}</td>
                                    <td ></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            ))}

                        </tbody>
                    </Table>
                    <Table striped bordered hover >
                        <thead>
                            <th></th>
                            <th> </th>
                            <th> </th>
                            <th> </th>
                            <th> </th>
                            <th> </th>
                            <th> </th>
                            <th> </th>

                        </thead>
                        <tbody>

                        </tbody>
                    </Table>
                </div>

            </div>
        </div>
    )
}

export default ClaimSummaryLite