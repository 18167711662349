import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import Swal from 'sweetalert2';
const notify = () => toast('Here is your toast.');

const domain = window.location.origin;
// baseURL: "http://192.168.18.110:8001/api/",


let API_URL = "http://localhost:3000"

if (domain == "https://xt-analytics.aventusinformatics.com") {

  API_URL = process.env.REACT_APP_DEV_SERVER_API_URL + "/api";

}
else if (domain == "https://uat-analytics.db-xtract.com") {

  API_URL = process.env.REACT_APP_UAT_SERVER_API_URL + "/api";

}
else if (domain == "https://analytics.db-xtract.com") {

  API_URL = process.env.REACT_APP_PROD_SERVER_API_URL + "/api";

}
else if (domain == "https://training-analytics.db-xtract.com") {

  API_URL = process.env.REACT_APP_TRAINING_SERVER_API_URL + "/api";

}
else if (domain == "https://analytics-demo.db-xtract.com") {

  API_URL = process.env.REACT_APP_DEMO_SERVER_API_URL + "/api";

}
else if (domain == "http://localhost:3000") {

  API_URL = process.env.REACT_APP_DEV_SERVER_API_URL + "/api";

}
else {

  API_URL = process.env.REACT_APP_DEV_SERVER_API_URL + "/api";

}

export const BACKEND_URL = API_URL;

const request = axios.create({
  // baseURL: "http://192.168.0.127:8001/api/",
  baseURL: BACKEND_URL,
  // baseURL: "https://uat-analytics.db-xtract.com/api/",



  headers: {
    // 'Access-Control-Allow-Origin': '*',
    // 'Content-Type': 'application/json',
    // withCredentials: true
  },
});
function sleep(delay) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

request.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

request.interceptors.response.use(
  async (response) => {
    await sleep(1000);
    const pagination = response.headers['pagination'];
    if (pagination) {
      //   response.data = new PaginatedResult(response.data, JSON.parse(pagination));
      return response;
    }
    if (response.data) return response;
  },
  (error) => {
    const { data, status } = error.response || {};

    switch (status) {
      case 400:
        if (typeof data === 'string') {
          toast.error(data);
        }
        break;
      case 401:
        // toast.error('unauthorized');
        // // Show the toast for a specific duration (e.g., 3 seconds)
        // const toastDuration = 3000; // 3 seconds
        // const loginRedirectDelay = 1000; // 1 second before redirecting to the login page

        // const toastId = toast.loading('Redirecting to login page...', {
        //   duration: toastDuration - loginRedirectDelay,
        // });
        // if (window.location.pathname !== '/login') {

        //   setTimeout(() => {
        //     toast.dismiss(toastId); // Dismiss the loading toast
        //     window.location.assign('/login'); // Replace '/login' with your actual login page URL
        //   }, loginRedirectDelay);
        // }
        if (window.location.pathname !== '/login') {
          Swal.fire({
            icon: 'error',
            confirmButtonColor: '#1ba1a6',
            title: 'Session Timeout',
            customClass: {
              title: 'swal-titleText'
            }
            // text: 'Session',
          }).then((result) => {
            localStorage.clear();
            window.location = "/login";

          })
        }

        break;
      case 404:
        toast.error('not found');
        break;
      case 500:
        toast.error('server error');
        break;
      default:
        toast.error('error');
        break;
    }
    return error;

    // return Promise.reject(data);
  }
);

export default request;