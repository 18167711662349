import React, { useRef, useState } from 'react'
import clear from "../../../../../assets/img/clear-icon.svg";
import deleteserviceline from "../../../../../assets/img/delete-icon-serviceline.svg";
import copyicon from "../../../../../assets/img/copy-icon.svg";
import newicon from "../../../../../assets/img/new-icon.svg";
import { Form, InputGroup, Overlay, Popover, Tooltip } from 'react-bootstrap';
import InputMask from 'react-input-mask';
import moment from 'moment';
import { QA_REVIEW, EDIT_WORK } from '../../../../../functions'


const Servicelines = (props) => {

    const {
        formik,

        isViewOnllyGrid,
        index,
        setServiceIndex,
        handleNewServiceLine,
        newPoupUp,
        setNewPoupUp,
        setSlCount,
        disableCorresField,

        setIsDrag,
        job_id,
        jobData,
        log_id
    } = props;

    const ref = useRef(null);
    const refPC = useRef([]);
    const claimRefPC = useRef([]);

    const __copyServiceLine = async () => {
    }

    const __moveServiceLine = async (type) => {
    }

    const __clearServiceLine = async () => {
    }

    const handleNewPoup = async () => {
    }
    const handleClaimAdjustment = () => {
    }

    const handleClaimAServiceLineAdjustment = (serviceIndex) => {
    }

    const inputRefs = useRef([]);


    const pathname = window.location.pathname;
    const gridData = jobData?.grid_data;
    const qaCorrectedData = gridData?.qa_corrected_data;

    const renderClass = (name, type, defaultClass, sfindex) => {
        // console.log(formik.values?.claims[index]?.service_lines[sfindex]?.[name])
        if (formik.errors?.claims &&
            formik.errors?.claims[index] &&
            formik.errors?.claims[index]?.service_lines &&
            formik.errors?.claims[index]?.service_lines[sfindex] &&
            formik.errors?.claims[index]?.service_lines[sfindex]?.[name]) {
            return defaultClass + ' error-field'
        }
        if (pathname == QA_REVIEW) {

            if (qaCorrectedData?.claims &&
                qaCorrectedData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index] &&
                qaCorrectedData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.service_lines &&
                qaCorrectedData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.service_lines[formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index == 0 ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : sfindex] &&
                qaCorrectedData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.service_lines[formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index == 0 ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : sfindex]?.[name]) {
                return defaultClass + ' qa-field'
            }
            else if (type == 'DATE' &&
                gridData?.claims &&
                gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index] &&
                gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.service_lines &&
                gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.service_lines[formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index == 0 ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : sfindex] &&
                moment(formik.values?.claims[index]?.service_lines[sfindex]?.[name]).format("MM/DD/YYYY") != moment(gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.service_lines[formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index == 0 ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : sfindex]?.[name]).format("MM/DD/YYYY")) {
                return defaultClass + ' qa-field'
            }
            else if (type == 'TEXT' &&
                gridData?.claims &&
                gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index] &&
                gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.service_lines &&
                gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.service_lines[formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index == 0 ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : sfindex] &&
                formik.values?.claims[index]?.service_lines[sfindex]?.[name]?.toUpperCase() !=
                gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.service_lines[formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index == 0 ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : sfindex]?.[name]?.toUpperCase()) {
                return defaultClass + ' qa-field'
            }
            else if (type == 'NUMBER' &&
                gridData?.claims &&
                gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index] &&
                gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.service_lines &&
                gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.service_lines[formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index == 0 ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : sfindex] &&
                parseFloat(formik.values?.claims[index]?.service_lines[sfindex]?.[name] ? formik.values?.claims[index]?.service_lines[sfindex]?.[name] : 0).toFixed(2) !=
                parseFloat(gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.service_lines[formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index == 0 ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : sfindex]?.[name] ?
                    gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.service_lines[formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index == 0 ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : sfindex]?.[name] : 0).toFixed(2)) {
                return defaultClass + ' qa-field'
            }
            else if (gridData?.claims &&
                gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index] &&
                gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.service_lines &&
                !gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.service_lines[formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index == 0 ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : sfindex]) {
                return defaultClass + ' qa-field'
            }

            else if (formik.values?.claims[index]?.corrected_claim || formik.values?.claims[index]?.service_lines[sfindex]?.corrected_serviceline || !gridData?.claims || gridData?.transaction_type != "PAYMENT835") {
                return defaultClass + ' qa-field'
            }
            else if (type == 'DATE' && formik.values?.claims[index]?.service_lines[sfindex]?.color_data?.[name] && moment(formik.values?.claims[index]?.service_lines[sfindex]?.color_data?.[name]).format("MM/DD/YYYY") ==
                moment(formik.values?.claims[index]?.service_lines[sfindex]?.[name]).format("MM/DD/YYYY")) {
                return defaultClass + ' data-837'
            }
            else if (type == 'TEXT' && formik.values?.claims[index]?.service_lines[sfindex]?.color_data?.[name] && formik.values?.claims[index]?.service_lines[sfindex]?.color_data?.[name]?.toUpperCase() == formik.values?.claims[index]?.service_lines[sfindex]?.[name]?.toUpperCase()) {
                return defaultClass + ' data-837'
            }
            else if (type == 'NUMBER' && formik.values?.claims[index]?.service_lines[sfindex]?.color_data?.[name] && formik.values?.claims[index]?.service_lines[sfindex]?.color_data?.[name] == formik.values?.claims[index]?.service_lines[sfindex]?.[name]) {
                return defaultClass + ' data-837'
            }
            else {
                return defaultClass;
            }


        }
        else if ((pathname == EDIT_WORK + job_id || pathname == EDIT_WORK + job_id + "?log_id=" + log_id) && qaCorrectedData?.claims &&
            qaCorrectedData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index] &&
            qaCorrectedData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.service_lines &&
            qaCorrectedData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.service_lines[formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index == 0 ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : sfindex] &&
            qaCorrectedData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.service_lines[formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index == 0 ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : sfindex]?.[name]) {
            return defaultClass + ' qa-field'
        }
        else if (type == 'DATE' && formik.values?.claims[index]?.service_lines[sfindex]?.color_data?.[name] && moment(formik.values?.claims[index]?.service_lines[sfindex]?.color_data?.[name]).format("MM/DD/YYYY") ==
            moment(formik.values?.claims[index]?.service_lines[sfindex]?.[name]).format("MM/DD/YYYY")) {
            return defaultClass + ' data-837'
        }
        else if (type == 'TEXT' && formik.values?.claims[index]?.service_lines[sfindex]?.color_data?.[name] && formik.values?.claims[index]?.service_lines[sfindex]?.color_data?.[name]?.toUpperCase() == formik.values?.claims[index]?.service_lines[sfindex]?.[name]?.toUpperCase()) {
            return defaultClass + ' data-837'
        }
        else if (type == 'NUMBER' && formik.values?.claims[index]?.service_lines[sfindex]?.color_data?.[name] && formik.values?.claims[index]?.service_lines[sfindex]?.color_data?.[name] == formik.values?.claims[index]?.service_lines[sfindex]?.[name]) {
            return defaultClass + ' data-837'
        }
        else {
            return defaultClass;
        }
    }
    const renderClaimClass = (name, type, defaultClass) => {
        if (formik.errors?.claims &&
            formik.errors?.claims[index] &&
            formik.errors?.claims[index]?.[name]) {
            return defaultClass + ' error-field'
        }
        if (pathname == QA_REVIEW) {

            if (qaCorrectedData &&
                qaCorrectedData?.claims &&
                qaCorrectedData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index] &&
                qaCorrectedData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.[name]) {
                return defaultClass + ' qa-field'
            }
            else if (type == 'DATE' &&
                gridData?.claims &&
                gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index] &&
                moment(formik.values?.claims[index]?.[name]).format("MM/DD/YYYY") != moment(gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.[name]).format("MM/DD/YYYY")) {
                return defaultClass + ' qa-field'
            }
            else if (type == 'TEXT' &&
                gridData?.claims &&
                gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index] &&
                formik.values?.claims[index]?.[name]?.toUpperCase() !=
                gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.[name]?.toUpperCase()) {
                return defaultClass + ' qa-field'
            }
            else if (type == 'NUMBER' &&
                gridData?.claims &&
                gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index] &&
                parseFloat(formik.values?.claims[index]?.[name] ? formik.values?.claims[index]?.[name] : 0).toFixed(2) !=
                parseFloat(gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.[name] ?
                    gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.[name] : 0).toFixed(2)) {
                return defaultClass + ' qa-field'
            }

            else if (formik.values?.claims[index]?.corrected_claim || !gridData?.claims || gridData?.transaction_type != "PAYMENT835") {
                return defaultClass + ' qa-field'
            }
            else {
                return defaultClass;
            }


        }
        else if ((pathname == EDIT_WORK + job_id || pathname == EDIT_WORK + job_id + "?log_id=" + log_id) && qaCorrectedData &&
            qaCorrectedData?.claims &&
            qaCorrectedData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index] &&
            qaCorrectedData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.[name]) {
            return defaultClass + ' qa-field'
        }
        else {
            return defaultClass;
        }
    }
    const renderAdjustmentClass = (name, type, defaultClass, sfindex, adjIndex) => {
        if (formik.errors &&
            formik.errors?.claims &&
            formik.errors?.claims[index] &&
            formik.errors?.claims[index]?.service_lines &&
            formik.errors?.claims[index]?.service_lines[sfindex] &&
            formik.errors?.claims[index]?.service_lines[sfindex]?.adjustments &&
            formik.errors?.claims[index]?.service_lines[sfindex]?.adjustments[adjIndex] &&
            formik.errors?.claims[index]?.service_lines[sfindex]?.adjustments[adjIndex]?.[name]) {
            return defaultClass + ' error-field'
        }
        if (pathname == QA_REVIEW) {

            if (qaCorrectedData &&
                qaCorrectedData?.claims &&
                qaCorrectedData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index] &&
                qaCorrectedData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.service_lines &&
                qaCorrectedData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.service_lines[formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index == 0 ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : sfindex] &&
                qaCorrectedData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.service_lines[formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index == 0 ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : sfindex]?.adjustments &&
                qaCorrectedData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.service_lines[formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index == 0 ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : sfindex]?.adjustments[adjIndex] &&
                qaCorrectedData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.service_lines[formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index == 0 ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : sfindex]?.adjustments[adjIndex]?.[name]) {
                return defaultClass + ' qa-field'
            }

            else if (type == 'TEXT' &&
                gridData?.claims &&
                gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index] &&
                gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.service_lines &&
                gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.service_lines[formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index == 0 ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : sfindex] &&
                gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.service_lines[formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index == 0 ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : sfindex]?.adjustments &&
                gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.service_lines[formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index == 0 ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : sfindex]?.adjustments[adjIndex] &&
                formik.values?.claims[index]?.service_lines[sfindex]?.adjustments[adjIndex]?.[name]?.toUpperCase() !=
                gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.service_lines[formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index == 0 ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : sfindex]?.adjustments[adjIndex]?.[name]?.toUpperCase()) {
                return defaultClass + ' qa-field'
            }
            else if (type == 'NUMBER' &&
                gridData?.claims &&
                gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index] &&
                gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.service_lines &&
                gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.service_lines[formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index == 0 ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : sfindex] &&
                gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.service_lines[formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index == 0 ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : sfindex]?.adjustments &&
                gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.service_lines[formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index == 0 ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : sfindex]?.adjustments[adjIndex] &&
                parseFloat(formik.values?.claims[index]?.service_lines[sfindex]?.adjustments[adjIndex]?.[name] ? formik.values?.claims[index]?.service_lines[sfindex]?.adjustments[adjIndex]?.[name] : 0).toFixed(2) !=
                parseFloat(gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.service_lines[formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index == 0 ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : sfindex]?.adjustments[adjIndex]?.[name] ?
                    gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.service_lines[formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index == 0 ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : sfindex]?.adjustments[adjIndex]?.[name] : 0).toFixed(2)) {
                return defaultClass + ' qa-field'
            }
            else if (gridData?.claims &&
                gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index] &&
                gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.service_lines &&
                !gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.service_lines[formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index == 0 ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : sfindex]) {
                return defaultClass + ' qa-field'
            }

            else if (formik.values?.claims[index]?.corrected_claim ||
                formik.values?.claims[index]?.service_lines[sfindex]?.corrected_serviceline ||
                !gridData?.claims ||
                !gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index] ||
                !gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.service_lines ||
                !gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.service_lines[formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index == 0 ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : sfindex] ||
                !gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.service_lines[formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index == 0 ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : sfindex]?.adjustments ||
                !gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.service_lines[formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index == 0 ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : sfindex]?.adjustments[adjIndex] ||
                gridData?.transaction_type != "PAYMENT835") {
                return defaultClass + ' qa-field'
            }
            else {
                return defaultClass;
            }


        }
        else if ((pathname == EDIT_WORK + job_id || pathname == EDIT_WORK + job_id + "?log_id=" + log_id) && qaCorrectedData &&
            qaCorrectedData?.claims &&
            qaCorrectedData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index] &&
            qaCorrectedData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.service_lines &&
            qaCorrectedData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.service_lines[formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index == 0 ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : sfindex] &&
            qaCorrectedData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.service_lines[formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index == 0 ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : sfindex]?.adjustments &&
            qaCorrectedData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.service_lines[formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index == 0 ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : sfindex]?.adjustments[adjIndex] &&
            qaCorrectedData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.service_lines[formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index == 0 ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : sfindex]?.adjustments[adjIndex]?.[name]) {
            return defaultClass + ' qa-field'
        }
        else {
            return defaultClass;
        }
    }
    const renderClaimAdjustmentClass = (name, type, defaultClass, clIndex) => {
        if (formik.errors &&
            formik.errors?.claims &&
            formik.errors?.claims[index] &&
            formik.errors?.claims[index]?.claim_adjustments &&
            formik.errors?.claims[index]?.claim_adjustments[clIndex] &&
            formik.errors?.claims[index]?.claim_adjustments[clIndex]?.[name]) {
            return defaultClass + ' error-field'
        }
        if (pathname == QA_REVIEW) {

            if (qaCorrectedData &&
                qaCorrectedData?.claims &&
                qaCorrectedData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index] &&
                qaCorrectedData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.claim_adjustments &&
                qaCorrectedData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.claim_adjustments[clIndex] &&
                qaCorrectedData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.claim_adjustments[clIndex]?.[name]) {
                return defaultClass + ' qa-field'
            }

            else if (type == 'TEXT' &&
                gridData?.claims &&
                gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index] &&
                gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.claim_adjustments &&
                gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.claim_adjustments[clIndex] &&
                // gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.claim_adjustments[clIndex]?.[name] &&
                formik.values?.claims[index]?.claim_adjustments[clIndex]?.[name]?.toUpperCase() !=
                gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.claim_adjustments[clIndex]?.[name]?.toUpperCase()) {
                return defaultClass + ' qa-field'
            }
            else if (type == 'NUMBER' &&
                gridData?.claims &&
                gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index] &&
                gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.claim_adjustments &&
                gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.claim_adjustments[clIndex] &&
                // gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.claim_adjustments[clIndex]?.[name] &&
                parseFloat(formik.values?.claims[index]?.claim_adjustments[clIndex]?.[name] ? formik.values?.claims[index]?.claim_adjustments[clIndex]?.[name] : 0).toFixed(2) !=
                parseFloat(gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.claim_adjustments[clIndex]?.[name] ?
                    gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.claim_adjustments[clIndex]?.[name] : 0).toFixed(2)) {
                return defaultClass + ' qa-field'
            }

            else if (formik.values?.claims[index]?.corrected_claim ||
                !gridData?.claims ||
                !gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index] ||
                !gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.claim_adjustments ||
                !gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.claim_adjustments[clIndex] ||
                gridData?.transaction_type != "PAYMENT835"
            ) {
                return defaultClass + ' qa-field'
            }
            else {
                return defaultClass;
            }


        }
        else if ((pathname == EDIT_WORK + job_id || pathname == EDIT_WORK + job_id + "?log_id=" + log_id) && qaCorrectedData &&
            qaCorrectedData?.claims &&
            qaCorrectedData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index] &&
            qaCorrectedData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.claim_adjustments &&
            qaCorrectedData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.claim_adjustments[clIndex] &&
            qaCorrectedData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.claim_adjustments[clIndex]?.[name]) {
            return defaultClass + ' qa-field'
        }
        else {
            return defaultClass;
        }
    }
    return (
        <React.Fragment>
            <div className="serviceline-mt">
                <div className="serviceline">
                    <div className="serviceline-top">
                        <div className="leftside-block">
                            <div className="accordion" id="serviceline835">
                                <button type="button" className="btn btn-healthcare accordion-icon" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">serviceline835</button>
                            </div>
                            <div className="">
                                <button type="button" tabIndex={"-1"} className="btn btn-healthcare" id='tooltip-button'
                                    ref={ref}
                                    onClick={(e) => handleNewServiceLine(e)}
                                > <img src={newicon} alt="" /> New</button>
                            </div>
                            <Overlay rootClose={true}
                                target={ref.current} show={newPoupUp}
                                onHide={() => { setNewPoupUp(false); setIsDrag(true) }}
                                placement="top">
                                {(props) => (
                                    <Tooltip id="overlay-example" {...props}>
                                        <Form.Control type="text" style={{ inlineSize: '30px' }} onChange={(e) => { setSlCount(e.target.value) }} onBlur={() => { handleNewPoup(); setIsDrag(true) }} />
                                    </Tooltip>
                                )}
                            </Overlay>
                            <div className="">
                                <button type="button" tabIndex={"-1"} disabled={formik.values?.claims[index]?.service_lines?.length > 0 ? false : true} className="btn btn-healthcare" onClick={() => { __copyServiceLine(); }}> <img src={copyicon} alt="" /> Copy</button>
                            </div>
                            <div className="">
                                <button type="button" tabIndex={"-1"} disabled={formik.values?.claims[index]?.service_lines?.length > 0 ? false : true} className="btn btn-healthcare" onClick={() => { __moveServiceLine('UP') }}> <i className="bi bi-chevron-up"></i> Move up</button>
                            </div>
                            <div className="">
                                <button type="button" tabIndex={"-1"} disabled={formik.values?.claims[index]?.service_lines?.length > 0 ? false : true} className="btn btn-healthcare" onClick={() => { __moveServiceLine('DOWN') }}> <i className="bi bi-chevron-down"></i> Move down</button>
                            </div>
                            <div className="">
                                <button type="button" tabIndex={"-1"} className="btn btn-healthcare" onClick={() => { __clearServiceLine() }}> <img src={clear} alt="" className="clear" /> Clear</button>
                            </div>
                        </div>
                        <div className="rightside-block">
                            <InputGroup className=' mt-1' style={{ flex: '0' }}>
                                <InputGroup.Text className="btn btn-healthcare">Billed</InputGroup.Text>
                                <Form.Control
                                    disabled
                                    name={`claims[${index}].claim_billed`}
                                    type="number"
                                    value={formik.values?.claims[index]?.claim_billed}
                                    className={renderClaimClass('claim_billed', 'NUMBER', 'serviceline-btn text-end')}

                                />
                            </InputGroup>
                            <InputGroup className=' mt-1 ' style={{ flex: '0' }}>
                                <InputGroup.Text className="btn btn-healthcare">Patres</InputGroup.Text>
                                <Form.Control
                                    name={`claims[${index}].claim_patres`}
                                    type="number"

                                    value={formik.values?.claims[index]?.claim_patres}
                                    className={renderClaimClass('claim_patres', 'NUMBER', 'serviceline-btn text-end w-blnc')}

                                />
                            </InputGroup>
                            <InputGroup className=' mt-1' style={{ flex: '0' }}>
                                <InputGroup.Text className="btn btn-healthcare">Paid</InputGroup.Text>
                                <Form.Control
                                    type="number"
                                    name={`claims[${index}].claim_paid`}


                                    value={formik.values?.claims[index]?.claim_paid}
                                    className={renderClaimClass('claim_paid', 'NUMBER', 'serviceline-btn text-end')}

                                />
                            </InputGroup>
                        </div>
                    </div>
                </div>
                <div className="bottom-wrapper serviceline_details accordion-collapse collapse show" id="collapseThree" aria-labelledby="headingThree" data-bs-parent="#serviceline835">
                    <div className="sub-section ">
                        <div className="table-responsive servicelinetab">
                            <table className="table table-bordered align-middle" >
                                <thead>
                                    <tr >
                                        <th className="serialno-th">#</th>
                                        <th className="date-th">Date</th>
                                        <th className="procedure-th">Procedure</th>
                                        <th className="revcode-th">Rev code</th>
                                        <th className="units-th">Units</th>
                                        <th className="billed-th">Billed</th>
                                        <th className="discount-th">Discount</th>
                                        <th className="allowed-th">Allowed</th>
                                        <th className="deduct-th">Deduct</th>
                                        <th className="coins-th">Coins</th>
                                        <th className="copay-th">Copay</th>
                                        <th className="patres-th">PatRes</th>
                                        <th className="adjustments-th">Adjustments</th>
                                        <th className="cob-th">COB</th>
                                        <th className="paid-th">Paid</th>
                                        <th className="balance-th">Balance</th>
                                        <th className="em-th">  </th>
                                    </tr>
                                </thead>

                                <tbody className="totalserviceline835">

                                    {formik.values?.claims && formik.values?.claims[index] && formik.values?.claims[index]?.service_lines &&
                                        <tr className='servicelinetd' style={{ outline: 'none', border: 'none', borderWidth: '0px' }}>
                                            <td className="servicelinetd" colSpan="17">
                                                <div className="table-responsive">
                                                    <table className="table table-bordered sub-table" >
                                                        <tbody className=''>
                                                            {formik.values?.claims[index]?.service_lines?.map((servicefield, sfindex) => (
                                                                <tr key={sfindex} id={"serviceline" + sfindex} onClick={() => setServiceIndex(sfindex)}>
                                                                    <td className="serialno">{sfindex + 1}</td>
                                                                    <td className="date">

                                                                        <div className="dflex">
                                                                            <InputMask
                                                                                mask="99/99/9999"
                                                                                className={renderClass('from_date', 'DATE', 'serviceline-date fw-normal', sfindex)}
                                                                                value={formik.values?.claims[index]?.service_lines[sfindex]?.from_date}
                                                                                name={`claims[${index}].service_lines[${sfindex}].from_date`}
                                                                                placeholder={"mm/dd/yyyy"}
                                                                            />

                                                                            <InputMask

                                                                                mask="99/99/9999"
                                                                                className={renderClass('to_date', 'DATE', 'serviceline-date fw-normal', sfindex)}
                                                                                value={formik.values?.claims[index]?.service_lines[sfindex]?.to_date}
                                                                                name={`claims[${index}].service_lines[${sfindex}].to_date`}
                                                                                placeholder={"mm/dd/yyyy"}
                                                                            />

                                                                        </div>
                                                                    </td>

                                                                    <td className="procedure">
                                                                        <div className="dflex">

                                                                            <Form.Control
                                                                                type="text"
                                                                                placeholder="code"
                                                                                name={`claims[${index}].service_lines[${sfindex}].code`}
                                                                                value={formik.values?.claims[index]?.service_lines[sfindex]?.code}
                                                                                className={renderClass('code', 'TEXT', 'w-40 fw-normal', sfindex)}
                                                                                data-corress-disable={disableCorresField('code')}


                                                                            />

                                                                            <Form.Control as="select"

                                                                                placeholder="procedure_type"
                                                                                name={`claims[${index}].service_lines[${sfindex}].procedure_type`}
                                                                                className={renderClass('procedure_type', 'TEXT', 'selc-bg-1 fw-normal', sfindex)}

                                                                                value={formik.values?.claims[index]?.service_lines[sfindex]?.procedure_type}
                                                                                data-corress-disable={disableCorresField('procedure_type')}
                                                                            >
                                                                                <option value="" >---select---</option>
                                                                                <option value="HC" >HC</option>

                                                                            </Form.Control>

                                                                            <Form.Control

                                                                                name={`claims[${index}].service_lines[${sfindex}].modifier1`}
                                                                                type="text"
                                                                                maxLength={2}
                                                                                value={formik.values?.claims[index]?.service_lines[sfindex]?.modifier1}

                                                                                className={renderClass('modifier1', 'TEXT', 'w-30', sfindex)}
                                                                                data-corress-disable={disableCorresField('modifier1')}
                                                                                placeholder="mo1"
                                                                            />
                                                                            <Form.Control

                                                                                name={`claims[${index}].service_lines[${sfindex}].modifier2`}
                                                                                type="text"

                                                                                maxLength={2}
                                                                                value={formik.values?.claims[index]?.service_lines[sfindex]?.modifier2}
                                                                                data-corress-disable={disableCorresField('modifier2')}
                                                                                className={renderClass('modifier2', 'TEXT', 'w-30', sfindex)}

                                                                                placeholder="mo2"
                                                                            />
                                                                            <Form.Control

                                                                                name={`claims[${index}].service_lines[${sfindex}].modifier3`}
                                                                                type="text"

                                                                                maxLength={2}

                                                                                value={formik.values?.claims[index]?.service_lines[sfindex]?.modifier3}
                                                                                className={renderClass('modifier3', 'TEXT', 'w-30', sfindex)}
                                                                                data-corress-disable={disableCorresField('modifier3')}
                                                                                placeholder="mo3"
                                                                            />
                                                                            <Form.Control

                                                                                name={`claims[${index}].service_lines[${sfindex}].modifier4`}
                                                                                type="text"

                                                                                maxLength={2}

                                                                                value={formik.values?.claims[index]?.service_lines[sfindex]?.modifier4}
                                                                                className={renderClass('modifier4', 'TEXT', 'w-30', sfindex)}
                                                                                data-corress-disable={disableCorresField('modifier4')}
                                                                                placeholder="mo4"

                                                                            />
                                                                        </div>
                                                                    </td>
                                                                    <td className="revcode">
                                                                        <div className="">
                                                                            <Form.Control

                                                                                name={`claims[${index}].service_lines[${sfindex}].rev_code`}
                                                                                type="text"

                                                                                maxLength={4}

                                                                                value={formik.values?.claims[index]?.service_lines[sfindex]?.rev_code}
                                                                                className={renderClass('rev_code', 'TEXT', 'w-82', sfindex)}
                                                                                data-corress-disable={disableCorresField('rev_code')}
                                                                                placeholder="rev code"
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                    <td className="units">
                                                                        <div className="">
                                                                            <Form.Control


                                                                                name={`claims[${index}].service_lines[${sfindex}].units`}
                                                                                type="number"

                                                                                value={formik.values?.claims[index]?.service_lines[sfindex]?.units}

                                                                                className={renderClass('units', 'TEXT', 'w-units', sfindex)}
                                                                                data-corress-disable={disableCorresField('units')}
                                                                                placeholder="units"
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                    <td className="billed">
                                                                        <div className="">
                                                                            <Form.Control


                                                                                name={`claims[${index}].service_lines[${sfindex}].billed`}

                                                                                type="number"

                                                                                value={formik.values?.claims[index]?.service_lines[sfindex]?.billed}
                                                                                className={renderClass('billed', 'NUMBER', 'w-billed billed trigger-billed text-end', sfindex)}

                                                                                placeholder="billed"
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                    <td className="discount">
                                                                        <div className="">
                                                                            <Form.Control

                                                                                name={`claims[${index}].service_lines[${sfindex}].discount`}
                                                                                type="number"

                                                                                ref={(el) => (inputRefs.current[index] = el)}

                                                                                value={formik.values?.claims[index]?.service_lines[sfindex]?.discount}
                                                                                className={renderClass('discount', 'NUMBER', 'w-82 discount trigger-discount text-end', sfindex)}
                                                                                data-corress-disable={disableCorresField('discount')}
                                                                                placeholder="discount"
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                    <td className="allowed">
                                                                        <div className="">
                                                                            <Form.Control

                                                                                type="number"
                                                                                name={`claims[${index}].service_lines[${sfindex}].allowed`}
                                                                                data-corress-disable={disableCorresField('allowed')}
                                                                                value={formik.values?.claims[index]?.service_lines[sfindex]?.allowed}
                                                                                className={renderClass('allowed', 'NUMBER', 'w-82 allowed trigger-allowed text-end', sfindex)}
                                                                                placeholder="allowed"
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                    <td className="deduct">
                                                                        <div className="">
                                                                            <Form.Control

                                                                                name={`claims[${index}].service_lines[${sfindex}].deduct`}

                                                                                type="number"
                                                                                data-corress-disable={disableCorresField('deduct')}
                                                                                value={formik.values?.claims[index]?.service_lines[sfindex]?.deduct}
                                                                                className={renderClass('deduct', 'NUMBER', 'w-82 deduct trigger-deduct text-end', sfindex)}
                                                                                placeholder="deduct"
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                    <td className="coins">
                                                                        <div className="">
                                                                            <Form.Control
                                                                                disabled={isViewOnllyGrid}

                                                                                data-corress-disable={disableCorresField('coins')}
                                                                                name={`claims[${index}].service_lines[${sfindex}].coins`}
                                                                                type="number"

                                                                                value={formik.values?.claims[index]?.service_lines[sfindex]?.coins}

                                                                                className={renderClass('coins', 'NUMBER', 'w-82 coins trigger-coins text-end', sfindex)}


                                                                                placeholder="coins"
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                    <td className="copay">
                                                                        <div className="">
                                                                            <Form.Control
                                                                                disabled={isViewOnllyGrid}
                                                                                data-corress-disable={disableCorresField('copay')}

                                                                                name={`claims[${index}].service_lines[${sfindex}].copay`}
                                                                                type="number"


                                                                                value={formik.values?.claims[index]?.service_lines[sfindex]?.copay}
                                                                                className={renderClass('copay', 'NUMBER', 'w-82 copay trigger-copay text-end', sfindex)}

                                                                                placeholder="copay"
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                    <td className="patres">

                                                                    </td>
                                                                    <td className="adjustments">

                                                                        <React.Fragment>
                                                                            <div className='d-flex flex-row justify-content-center'>
                                                                                <div className=' text-end'>
                                                                                    {formik.values?.claims[index]?.service_lines[sfindex]?.adjustments && formik.values?.claims[index]?.service_lines[sfindex]?.adjustments?.map((adjust, adjIndex) => (
                                                                                        <div className="d-flex" key={adjIndex}>

                                                                                            <Form.Control

                                                                                                type="number"

                                                                                                name={`claims[${index}].service_lines[${sfindex}].adjustments[${adjIndex}].amount`}
                                                                                                placeholder="amt"
                                                                                                value={adjust.amount}
                                                                                                className={renderAdjustmentClass('amount', 'NUMBER', 'w-40 amount trigger-amount text-end', sfindex, adjIndex)}

                                                                                            />


                                                                                            <Form.Control
                                                                                                type="text"
                                                                                                className={renderAdjustmentClass('pc', 'TEXT', 'w-30', sfindex, adjIndex)}


                                                                                                value={adjust.pc}
                                                                                                ref={(el) => refPC.current[adjIndex] = el}


                                                                                                data-corress-disable={disableCorresField('pc')}
                                                                                                name={`claims[${index}].service_lines[${sfindex}].adjustments[${adjIndex}].pc`}
                                                                                                placeholder="pc"

                                                                                            />
                                                                                            <Form.Control as="select"
                                                                                                className={renderAdjustmentClass('group_code', 'TEXT', 'w-select-60', sfindex, adjIndex)}
                                                                                                data-corress-disable={disableCorresField('group_code')}


                                                                                                value={adjust.group_code}

                                                                                                name={`claims[${index}].service_lines[${sfindex}].adjustments[${adjIndex}].group_code`}
                                                                                                aria-label="Default select example"
                                                                                            >
                                                                                                <option value="" >-Select-</option>
                                                                                                <option value="CO">CO</option>
                                                                                                <option value="OA">OA</option>
                                                                                                <option value="PI">PI</option>
                                                                                                <option value="PR">PR</option>
                                                                                            </Form.Control>
                                                                                            <Form.Control
                                                                                                disabled={isViewOnllyGrid}
                                                                                                type="text"
                                                                                                className={renderAdjustmentClass('reason_code', 'TEXT', 'w-30', sfindex, adjIndex)}
                                                                                                data-corress-disable={disableCorresField('reason_code')}
                                                                                                value={adjust.reason_code}

                                                                                                name={`claims[${index}].service_lines[${sfindex}].adjustments[${adjIndex}].reason_code`}
                                                                                                placeholder="rc"
                                                                                            />
                                                                                            <Form.Control
                                                                                                type="text"
                                                                                                className={renderAdjustmentClass('rmk', 'TEXT', 'w-30-rmk', sfindex, adjIndex)}
                                                                                                data-corress-disable={disableCorresField('rmk')}



                                                                                                value={adjust.rmk}
                                                                                                name={`claims[${index}].service_lines[${sfindex}].adjustments[${adjIndex}].rmk`}
                                                                                                placeholder="rmk"
                                                                                            />

                                                                                        </div>
                                                                                    ))}


                                                                                </div>
                                                                                <div className='' role="button" >
                                                                                    <button type="button" className='btn btn-hidden-add h-100' onDoubleClick={() => handleClaimAServiceLineAdjustment(sfindex)}></button>
                                                                                </div>
                                                                            </div>
                                                                        </React.Fragment>
                                                                        {/* )}
                                                                        /> */}

                                                                    </td>
                                                                    <td className="cob">
                                                                        <div className="">
                                                                            <Form.Control



                                                                                name={`claims[${index}].service_lines[${sfindex}].cob`}
                                                                                type="number"
                                                                                value={formik.values?.claims[index]?.service_lines[sfindex]?.cob}
                                                                                className={renderClass('cob', 'NUMBER', 'w-82 cob trigger-cob text-end', sfindex)}
                                                                                data-corress-disable={disableCorresField('cob')}
                                                                                placeholder="cob"
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                    <td className="paid">
                                                                        <div className="">
                                                                            <Form.Control


                                                                                name={`claims[${index}].service_lines[${sfindex}].paid`}

                                                                                type="number"

                                                                                value={formik.values?.claims[index]?.service_lines[sfindex]?.paid}

                                                                                className={renderClass('paid', 'NUMBER', 'w-82 paid trigger-paid text-end', sfindex)}

                                                                            />
                                                                        </div>
                                                                    </td>
                                                                    <td className="balance">
                                                                        <div className="">
                                                                            <Form.Control
                                                                                name={`claims[${index}].service_lines[${sfindex}].balance`}
                                                                                type="number"

                                                                                value={formik.values?.claims[index]?.service_lines[sfindex]?.balance}
                                                                                className={'w-blnc balance text-end'}
                                                                                placeholder="balance"
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                    <td className="em-td">
                                                                        <button
                                                                            type='button' style={{ cursor: 'pointer', background: 'transparent', outline: 'none', border: 'none' }} >
                                                                            <img src={deleteserviceline} alt="" />
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </td>
                                        </tr>
                                    }
                                    <tr>
                                        <td></td>
                                        <td className="">
                                            <div className="ms-2 text-end">
                                                SL Totals
                                            </div>
                                        </td>
                                        <td >
                                            <div className="text-end">
                                                {/* procedure */}
                                            </div>
                                        </td>
                                        <td>
                                            <div className="text-end">
                                                {/* rev_code */}
                                            </div>
                                        </td>
                                        <td>
                                            <div className="">
                                                {/* units */}
                                            </div>
                                        </td>
                                        <td className='px-2'>
                                            <div className="px-2 text-end">
                                                {/* billed */}
                                                {parseFloat(formik?.values?.claims[index]?.sl_billed ? formik?.values?.claims[index]?.sl_billed : 0).toFixed(2)}
                                            </div>
                                        </td>
                                        <td className='px-2'>
                                            <div className='px-2 text-end'>
                                                {parseFloat(formik?.values?.claims[index]?.sl_discount ? formik?.values?.claims[index]?.sl_discount : 0).toFixed(2)}
                                            </div>
                                        </td>
                                        <td className='px-2'>
                                            <div className='px-2 text-end'>
                                                {parseFloat(formik?.values?.claims[index]?.sl_allowed ? formik?.values?.claims[index]?.sl_allowed : 0).toFixed(2)}
                                            </div>
                                        </td>
                                        <td className='px-2'>
                                            <div className='px-2 text-end'>
                                                {parseFloat(formik?.values?.claims[index]?.sl_deduct ? formik?.values?.claims[index]?.sl_deduct : 0).toFixed(2)}
                                            </div>
                                        </td>
                                        <td className='px-2'>
                                            <div className='px-2 text-end'>
                                                {parseFloat(formik?.values?.claims[index]?.sl_coins ? formik?.values?.claims[index]?.sl_coins : 0).toFixed(2)}
                                            </div>
                                        </td>
                                        <td className='px-2'>
                                            <div className='px-2 text-end'>
                                                {parseFloat(formik?.values?.claims[index]?.sl_copay ? formik?.values?.claims[index]?.sl_copay : 0).toFixed(2)}
                                            </div>
                                        </td>

                                        <td className='px-2  text-end'>
                                            {/* <td className='px-2 d-flex justify-content-between text-end'> */}
                                            {/* <div>
                                                {parseFloat(formik?.values?.claims[index]?.fake_sl_patres ? formik?.values?.claims[index]?.fake_sl_patres : 0).toFixed(2)}
                                            </div> */}
                                            <div className='px-2'>
                                                {parseFloat(formik?.values?.claims[index]?.sl_patres ? formik?.values?.claims[index]?.sl_patres : 0).toFixed(2)}
                                            </div>

                                        </td>
                                        <td className='px-2'>
                                            <div className='px-2'>
                                                {parseFloat(formik?.values?.claims[index]?.sl_amount ? formik?.values?.claims[index]?.sl_amount : 0).toFixed(2)}
                                            </div>
                                        </td>
                                        <td className='px-2'>
                                            <div className='px-2 text-end'>
                                                {parseFloat(formik?.values?.claims[index]?.sl_cob ? formik?.values?.claims[index]?.sl_cob : 0).toFixed(2)}
                                            </div>
                                        </td>
                                        <td className='px-2'>
                                            <div className='px-2 text-end'>
                                                {parseFloat(formik?.values?.claims[index]?.sl_paid ? formik?.values?.claims[index]?.sl_paid : 0).toFixed(2)}
                                            </div>
                                        </td>
                                        <td className=''>
                                            <div className='me-2 text-end'>

                                                <div className="text-end">
                                                    {parseFloat(formik?.values?.claims[index]?.sl_balance ? formik?.values?.claims[index]?.sl_balance : 0).toFixed(2)}
                                                </div>
                                            </div>
                                        </td>
                                        <td>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td className="">
                                            <div className=" ms-2">
                                                Claim
                                            </div>
                                        </td>
                                        <td >
                                            <div className="">
                                            </div>
                                        </td>
                                        <td>
                                            <div className="">
                                            </div>
                                        </td>
                                        <td>
                                            <div className="">
                                            </div>
                                        </td>
                                        <td className='text-center'>
                                            <div className="">
                                                {/* billed */}
                                                <Form.Control


                                                    name={`claims[${index}].claim_billed`}
                                                    type="number"

                                                    value={formik.values?.claims[index]?.claim_billed}
                                                    className={renderClaimClass('claim_billed', 'NUMBER', 'w-82 paid text-end')}


                                                    placeholder="billed"
                                                />
                                            </div>
                                        </td>
                                        <td className='text-center'>
                                            <div className="">
                                                <Form.Control

                                                    name={`claims[${index}].claim_discount`}
                                                    type="number"
                                                    data-corress-disable={disableCorresField('claim_discount')}


                                                    value={formik.values?.claims[index]?.claim_discount}
                                                    className={renderClaimClass('claim_discount', 'NUMBER', 'w-82 paid text-end')}

                                                    placeholder="discount"
                                                />
                                            </div>
                                        </td>
                                        <td className='text-center'>
                                            <div className="">
                                                <Form.Control

                                                    name={`claims[${index}].claim_allowed`}
                                                    type="number"

                                                    value={formik.values?.claims[index]?.claim_allowed}
                                                    className={renderClaimClass('claim_allowed', 'NUMBER', 'w-82 paid text-end')}
                                                    data-corress-disable={disableCorresField('claim_allowed')}

                                                    placeholder="allowed"
                                                />
                                            </div>
                                        </td>
                                        <td className='text-center'>
                                            <div className="">
                                                <Form.Control

                                                    name={`claims[${index}].claim_deduct`}
                                                    type="number"

                                                    data-corress-disable={disableCorresField('claim_deduct')}
                                                    value={formik.values?.claims[index]?.claim_deduct}
                                                    className={renderClaimClass('claim_deduct', 'NUMBER', 'w-82 paid text-end')}

                                                    placeholder="deduct"
                                                />
                                            </div>
                                        </td>
                                        <td className='text-center'>
                                            <div className="">
                                                <Form.Control

                                                    name={`claims[${index}].claim_coins`}
                                                    type="number"
                                                    data-corress-disable={disableCorresField('claim_coins')}
                                                    value={formik.values?.claims[index]?.claim_coins}
                                                    className={renderClaimClass('claim_coins', 'NUMBER', 'w-82 paid text-end')}

                                                    placeholder="coins"
                                                />
                                            </div>
                                        </td>
                                        <td className='text-center'>
                                            <div className="">
                                                <Form.Control

                                                    name={`claims[${index}].claim_copay`}
                                                    type="number"
                                                    data-corress-disable={disableCorresField('claim_copay')}
                                                    value={formik.values?.claims[index]?.claim_copay}
                                                    className={renderClaimClass('claim_copay', 'NUMBER', 'w-82 paid text-end')}


                                                    placeholder="copay"
                                                />
                                            </div>
                                        </td>
                                        <td className='text-center'>
                                            <div className="">
                                                <Form.Control


                                                    name={`claims[${index}].claim_patres`}
                                                    type="number"

                                                    value={formik.values?.claims[index]?.claim_patres}
                                                    className={renderClaimClass('claim_patres', 'NUMBER', 'w-82 paid text-end w-blnc')}

                                                    placeholder="patres"
                                                />
                                            </div>
                                        </td>

                                        <React.Fragment>
                                            <td className='text-center'>
                                                <React.Fragment>
                                                    <div className='d-flex flex-row justify-content-center'>
                                                        <div className=' text-end'>

                                                            {formik.values?.claims[index]?.claim_adjustments?.map((claim_adjustment, clIndex) => (
                                                                <div className="d-flex" key={clIndex}>
                                                                    <Form.Control

                                                                        type="number"
                                                                        name={`claims[${index}].claim_adjustments[${clIndex}].amount`}
                                                                        placeholder="amt"
                                                                        value={formik.values?.claims[index]?.claim_adjustments[clIndex]?.amount}
                                                                        className={renderClaimAdjustmentClass('amount', 'NUMBER', 'w-40 amount trigger-amount text-end', clIndex)}
                                                                    />


                                                                    <Form.Control
                                                                        type="text"
                                                                        className={renderClaimAdjustmentClass('pc', 'TEXT', 'w-30', clIndex)}
                                                                        value={formik.values?.claims[index]?.claim_adjustments[clIndex]?.pc}
                                                                        ref={(el) => (claimRefPC.current[clIndex] = el)}
                                                                        name={`claims[${index}].claim_adjustments[${clIndex}].pc`
                                                                        }
                                                                        data-corress-disable={disableCorresField('claim_pc')}
                                                                        placeholder="pc"
                                                                    />

                                                                    <Form.Control as="select"
                                                                        className={renderClaimAdjustmentClass('group_code', 'TEXT', 'w-select-60', clIndex)}
                                                                        data-corress-disable={disableCorresField('claim_group_code')}
                                                                        value={formik.values?.claims[index]?.claim_adjustments[clIndex]?.group_code}
                                                                        name={`claims[${index}].claim_adjustments[${clIndex}].group_code`}
                                                                        aria-label="Default select example"
                                                                    >
                                                                        <option value="" >-Select-</option>
                                                                        <option value="CO">CO</option>
                                                                        <option value="OA">OA</option>
                                                                        <option value="PI">PI</option>
                                                                        <option value="PR">PR</option>
                                                                    </Form.Control>
                                                                    <Form.Control
                                                                        type="text"
                                                                        className={renderClaimAdjustmentClass('reason_code', 'TEXT', 'w-30', clIndex)}
                                                                        value={formik.values?.claims[index]?.claim_adjustments[clIndex]?.reason_code}
                                                                        name={`claims[${index}].claim_adjustments[${clIndex}].reason_code`}
                                                                        data-corress-disable={disableCorresField('claim_reason_code')}
                                                                        placeholder="rc"
                                                                    />

                                                                    <Form.Control
                                                                        type="text"
                                                                        className={renderClaimAdjustmentClass('rmk', 'TEXT', 'w-30-rmk', clIndex)}
                                                                        value={formik.values?.claims[index]?.claim_adjustments[clIndex]?.rmk}
                                                                        name={`claims[${index}].claim_adjustments[${clIndex}].rmk`}
                                                                        placeholder="rmk"
                                                                        data-corress-disable={disableCorresField('claim_rmk')}
                                                                    />

                                                                </div>
                                                            ))}


                                                        </div>
                                                        <div className='' role="button" >
                                                            <button type="button" className='btn btn-hidden-add h-100'
                                                                onDoubleClick={() => handleClaimAdjustment()}></button>
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                                {/* )}
                                                /> */}

                                            </td>
                                        </React.Fragment>
                                        <td>
                                            <div className="text-center">
                                                <Form.Control
                                                    name={`claims[${index}].claim_cob`}
                                                    data-corress-disable={disableCorresField('claim_cob')}
                                                    type="number"
                                                    value={formik.values?.claims[index]?.claim_cob}
                                                    className={renderClaimClass('claim_cob', 'NUMBER', 'w-82 paid text-end')}
                                                    placeholder="cob"
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="text-end">
                                                <Form.Control
                                                    name={`claims[${index}].claim_paid`}
                                                    type="number"
                                                    value={formik.values?.claims[index]?.claim_paid}
                                                    className={renderClaimClass('claim_paid', 'NUMBER', 'w-82 paid text-end')}
                                                    placeholder="paid"
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="text-end me-2">
                                                {formik?.values?.claims[index]?.claim_balance ? parseFloat(formik?.values?.claims[index]?.claim_balance ? formik?.values?.claims[index]?.claim_balance : 0).toFixed(2) : parseFloat(0).toFixed(2)}
                                            </div>
                                        </td>
                                        <td>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Servicelines
