import React, { useEffect, useState } from 'react'
import Chart from 'react-apexcharts';
import { DashboardService } from '../../../../services/DashboardService';
import moment from 'moment';
import BarSkelitonLoader from '../../loader/BarSkelitonLoader';

const AllReportGraph = ({ isVisible, setLoaderState, searchFormik, filter, handleClickData, update, setFilterData, handleClickFilterData }) => {
    const [fullData, setFullData] = useState([])
    const [data, setData] = useState([])
    const [labels, setLabels] = useState([])

    const dashboardService = new DashboardService();
    const isChangedSelect = filter?.year || filter?.month || filter?.week || filter?.days
    const { geVolumeReport } = dashboardService
    useEffect(() => {
        let date = moment(searchFormik.values.date_range[0]).format('MM/DD/YYYY HH:mm:ss') + ' - ' + moment(searchFormik.values.date_range[1]).format('MM/DD/YYYY HH:mm:ss')
        setLoaderState((prevState) => {
            return {
                ...prevState,
                volumeReportLoader: true
            }
        })
        geVolumeReport({ date_range: date, partners: searchFormik?.values?.partners, clients: searchFormik?.values?.clients, facilities: searchFormik?.values.facilities, filter }).then((response) => {
            if (response && response.status) {
                setFullData(response?.data)
                if (isChangedSelect) {
                    setLabels(Object.keys(response?.data))
                    setData(response?.data[Object.keys(response?.data)[0]]?.map((item, index) => {
                        return {
                            name: item?.client_name,
                            data: Object.keys(response?.data).map(val => response?.data?.[val]?.[index]?.total_claims ?? 0)
                        }
                    }))
                    setFilterData(response?.data[Object.keys(response?.data)[0]]?.map((item, index) => {
                        return {
                            name: item?.client_name,
                            data: Object.keys(response?.data).map(val => { return response?.data?.[val]?.[index] })
                        }
                    }))
                }
                else {
                    setLabels(response?.data?.map(item => item?.client_name))
                    setData([{
                        name: 'Claims',
                        data: response?.data?.map(item => item?.total_claims)
                    }])
                }
                setLoaderState((prevState) => {
                    return {
                        ...prevState,
                        volumeReportLoader: false
                    }
                })
            }
        });
    }, [filter, update])

    const options = {
        chart: {
            id: 'basic-bar',
            type: 'bar',
            toolbar: {
                show: false
            },
            fontFamily: 'Open Sans',
            events: {
                dataPointSelection: filter?.year || filter?.month || filter?.week || filter?.days ? handleClickFilterData : handleClickData
            },
        },
        fill: {
            type: 'gradient',
        },
        plotOptions: {
            bar: {
                columnWidth: '10px',
                distributed: filter?.year || filter?.month || filter?.week || filter?.days ? false : true,
            },
        },
        grid: {
            borderColor: '#E4E4E4',
            xaxis: {
                lines: {
                    show: false
                }
            },
            yaxis: {
                lines: {
                    show: true
                }
            },
        },
        noData: {
            text: 'No data available',
        },
        dataLabels: {
            enabled: false
        },
        legend: {
            show: true
        },
        colors: ['#b2d3a3', '#9cadda', '#ffd993', '#c7d07b', '#f6b89d', '#abc7e7', '#969696', '#24B2DD', '#FFB11F', '#D98383'],

        // colors: ['#ad6baf', '#75ba7a', '#61d6f3', '#c7d07b', '#ffb981', '#00a1a8', '#166783', '#c7d07b', '#df8483', '#ffb237'],
        xaxis: {
            categories: labels ?? [],
            axisBorder: {
                show: true,
                color: '#000000',
                height: 1,
                width: '100%',
                offsetX: 0,
                offsetY: 0
            },
            labels: {
                show: true,
                trim: true,
                hideOverlappingLabels: false,
                rotate: 0
            }
        },
        yaxis: {
            axisBorder: {
                show: true,
                color: '#000000',
                height: '100%',
                width: 1,
                offsetX: 0,
                offsetY: 0
            },
        },
        tooltip: {
            show: true,
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                let tooltip = ''
                let color = ''
                if (isChangedSelect) {
                    color = w.config.colors[seriesIndex];
                    console.log(fullData[w.globals.labels[dataPointIndex]][seriesIndex].total_job_count)
                    console.log(fullData[w.globals.labels[dataPointIndex]], dataPointIndex, seriesIndex)
                    tooltip = `${fullData[w.globals.labels[dataPointIndex]][seriesIndex].client_name}: ${series[seriesIndex][dataPointIndex]}/${fullData[w.globals.labels[dataPointIndex]][seriesIndex].total_job_count}`
                } else {
                    color = w.config.colors[dataPointIndex];
                    tooltip = `${w.globals.labels[dataPointIndex]}: ${series[seriesIndex][dataPointIndex]}/${fullData[dataPointIndex].total_job_count}`
                }

                return `<div style="background-color:${color};padding:5px" class="">
                            <span style="color:black;"> ${tooltip}  </span>
                        </div>`;
            }
        },
    };


    return (

        isVisible ?
            <BarSkelitonLoader /> :
            <Chart options={options} series={data ? data : []} type="bar" height={'90%'} width={data?.[0]?.data?.length > 0 ? data?.[0]?.data?.length * (filter?.year || filter?.month || filter?.week || filter?.days ? 200 : 100) + 500 > 1000 ? ((data?.[0]?.data?.length ?? 3) * (filter?.year || filter?.month || filter?.week || filter?.days ? 200 : 100) + 500) : '100%' : '100%'} className='graph-width' />

    )
}

export default AllReportGraph