import React, { useEffect, useState } from 'react'
import Chart from 'react-apexcharts';
import SingleReport from './singleReport';
import AllReport from './allReport';
import Modal from 'react-modal'
import DetailsReport from './detailsReport';
import moment from 'moment';
import { DashboardService } from '../../../../services/DashboardService';
import AllReportGraph from './allReportGraph';
import { VscGraph } from "react-icons/vsc";
import { MdDonutLarge } from "react-icons/md";
import SingleReportGraph from './singleReportGraph';
import styled from "styled-components/macro";
import ModalSkelitonLoader from '../../loader/ModalSkelitonLoader';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

const QualityReport = ({ graphType, setGraphType, setLoaderState, loaderState, data, searchFormik, update }) => {
    const [modalLoader, setModalLoader] = useState(false)
    const [showSingle, setShowSingle] = useState(false)
    const [singleData, setSingleData] = useState([])
    const dashboardService = new DashboardService();
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [clientId, setClientId] = useState('')
    // const [graphType, setGraphType] = useState('DONUT')
    const [userData, setUserData] = useState([])
    const [singleColor, setSingleColor] = useState('')
    const { getQualitySingleReport } = dashboardService
    let inner = false
    const handleClickData = (event, chartContext, { dataPointIndex, seriesIndex, w }) => {
        if (data && data[dataPointIndex]) {
            setSingleData(data[dataPointIndex])
            setSingleColor(w.config.colors[dataPointIndex])
            setClientId(data[dataPointIndex].client_id)
            setTimeout(() => {
                setShowSingle(true)
            }, 500);
        }
    }
    const handleClickInnerGraph = () => {
        if (inner) {
            inner = false
        }
        else {
            setTimeout(() => {
                setClientId('')
                setShowSingle(false)
            }, 500);
        }
    }
    const handleClickInnerData = (event, chartContext, { dataPointIndex, seriesIndex, w }) => {
        setModalLoader(true)
        setModalIsOpen(true)
        inner = true
        const full_types = ['quality_payment835', 'quality_payment835_lite', 'quality_patientpay', 'quality_correspondence']
        const types = []
        const series_items = [singleData.quality_payment835 ?? 0, singleData.quality_payment835_lite ?? 0, singleData.quality_patientpay ?? 0, singleData.quality_correspondence ?? 0]
        series_items?.forEach((i, index) => {
            if (i != 0) {
                types.push(full_types[index])
            }
        })
        let date = moment(searchFormik.values.date_range[0]).format('MM/DD/YYYY') + ' - ' + moment(searchFormik.values.date_range[1]).format('MM/DD/YYYY')
        getQualitySingleReport({ date_range: date, partners: searchFormik?.values?.partners, clients: searchFormik?.values?.clients, facilities: searchFormik?.values.facilities, client_id: clientId, transaction_type: types[dataPointIndex] }).then((response) => {
            if (response && response.status) {
                setUserData(response?.data)
                setModalLoader(false)

            }
        });
    }
    const handleGraphChange = (type) => {
        setGraphType(type)
    }
    useEffect(() => {
        setShowSingle(false)
    }, [update])
    return (
        <div className='card  p-2' >

            <div className='d-flex align-items-center justify-content-between'>
                <div className='mt-2' style={{ fontWeight: '500', fontSize: '20px', marginLeft: '30px', color: '#012970' }}>Quality Report</div>
                {graphType == 'DONUT' ?
                    <div onClick={() => handleGraphChange('GRAPH')}><VscGraph size={25} /></div>
                    :
                    <div onClick={() => handleGraphChange('DONUT')}><MdDonutLarge size={25} /></div>
                }
            </div>
            <div style={{ height: '45vh', overflow: graphType === 'DONUT' ? 'visible' : 'auto' }}>
                {showSingle ?
                    graphType == 'DONUT' ?
                        <SingleReport isVisible={loaderState.qualityLoader} handleClickInnerGraph={handleClickInnerGraph} handleClickInnerData={handleClickInnerData} singleData={singleData} singleColor={singleColor} />
                        :
                        <SingleReportGraph isVisible={loaderState.qualityLoader} handleClickInnerGraph={handleClickInnerGraph} handleClickInnerData={handleClickInnerData} singleData={singleData} singleColor={singleColor} update={update} />
                    :
                    graphType == 'DONUT' ?
                        <AllReport isVisible={loaderState.qualityLoader} data={data} handleClickData={handleClickData} />
                        :
                        <AllReportGraph isVisible={loaderState.qualityLoader} setLoaderState={setLoaderState} searchFormik={searchFormik} handleClickData={handleClickData} update={update} />
                }
            </div>
            <Modal
                isOpen={modalIsOpen}
                ariaHideApp={false}
                onRequestClose={() => { setModalIsOpen(false) }}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div>
                    {modalLoader ?
                        <ModalSkelitonLoader /> :
                        <DetailsReport data={userData} singleColor={singleColor} />
                    }
                </div>
            </Modal>
        </div>
    )
}

export default QualityReport

