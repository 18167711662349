


import React, { useState } from 'react';
import Chart from 'react-apexcharts';
import styled from "styled-components/macro";
import BarSkelitonLoader from '../loader/BarSkelitonLoader';
import Select from 'react-select';

const SnippingReportChart = ({ loaderState, data }) => {
    const [selectedReport, setSelectedReport] = useState({ value: 'count', label: 'Count' });

    const options = {
        dataLabels: {
            enabled: true,
            style: {
                colors: ['black']
            },
            dropShadow: {
                enabled: false,
                enabledOnSeries: undefined,
                top: 0,
                left: 0,
                blur: 3,
                opacity: 0.35
            }
        },


        fill: {
            type: 'gradient',
        },
        chart: {
            id: 'basic-bar',
            type: 'bar',
            stacked: true,
            fontFamily: 'Open Sans',
            toolbar: {
                show: false
            },
        },
        grid: {
            borderColor: '#E4E4E4',
            xaxis: {
                lines: {
                    show: false
                }
            },
            yaxis: {
                lines: {
                    show: true
                }
            },
        },
        plotOptions: {
            bar: {
                columnWidth: '40px'
            },
        },
        legend: {
            show: true
        },
        noData: {
            text: 'No data available',
        },
        // colors: selectedReport.value === 'count' ? ['#E5A4C3'] : ['#70D6F5'],
        colors: selectedReport.value === 'count' ? ['#b2d3a3'] : ['#9cadda'],
        xaxis: {
            categories: data?.client_name?.length > 0 ? data?.client_name : [],
            axisBorder: {
                show: true,
                color: '#000000',
                height: 1,
                width: '100%',
                offsetX: 0,
                offsetY: 0
            },
            labels: {
                show: true,
                trim: true,
                hideOverlappingLabels: false,
                rotate: 0
            }
        },
        yaxis: {
            axisBorder: {
                show: true,
                color: '#000000',
                height: '100%',
                width: 1,
                offsetX: 0,
                offsetY: 0
            },
        },
    };

    const series = [
        {
            key: "count",
            name: 'Count',
            data: data?.snipping ?? []
        },
        {
            key: "time",
            name: 'Time',
            data: data?.snipping_time ?? []
        },

    ];
    const filterOptions = [
        { value: 'count', label: 'Count' },
        { value: 'time', label: 'Time' },
    ]
    console.log(series.filter(report => report.key === selectedReport.value))

    return (
        <div className='card  p-2'>
            <div className='d-flex align-items-center justify-content-between'>
                <div className='mt-2' style={{ fontWeight: '500', fontSize: '20px', marginLeft: '30px', color: '#012970' }}>Snipping Report</div>
                <div className='d-flex align-items-center me-2'>
                    <div className='me-3'>
                        <div>
                            <Select
                                options={filterOptions}
                                className=""
                                classNamePrefix="select"
                                placeholder="Filter"
                                menuPlacement="auto"
                                maxMenuHeight={300}
                                onChange={(val) => {
                                    setSelectedReport(val)
                                }}
                                value={selectedReport}
                                name="partners"
                                tabSelectsValue={false}
                                styles={{
                                    container: (styles, state) => ({
                                        ...styles,
                                        borderRadius: "9px",
                                    }),
                                    control: (styles, state) => ({
                                        ...styles,
                                        backgroundColor: "hsl(204deg 33.33% 97.06%)",
                                        borderStyle: "none", boxShadow: "none",
                                        borderRadius: "10px",
                                        maxHeight: "30px",
                                    }),
                                    indicatorSeparator: styles => ({
                                        ...styles, width: "0px",
                                        minHeight: "20px",
                                        maxHeight: "30px",
                                    }),

                                    valueContainer: (styles, state) => ({
                                        ...styles,
                                        maxHeight: "30px",
                                        overflow: 'scroll',
                                    }),
                                    option: (styles, state) => ({
                                        ...styles,
                                        backgroundColor: state.isSelected ? '#d9f4f5' : 'inherit',
                                        color: state.isSelected ? '#000000' : 'inherit',
                                        '&:hover': { backgroundColor: state.isSelected ? '#d9f4f5' : 'rgb(222, 235, 255)' }

                                    })
                                }}
                                menuPortalTarget={document.body}

                            />
                        </div>
                    </div>



                </div>
            </div>
            <div style={{ height: '45vh', overflow: 'auto' }}>
                {loaderState.fileSplitReportLoader ?
                    <BarSkelitonLoader /> :
                    <Chart options={options} series={series.filter(report => report.key === selectedReport.value)} type="bar" height={'90%'} width={(series?.[0]?.data?.length ?? 1) * 50 + 500 > 1000 ? ((series?.[0]?.data?.length ?? 1) * 50 + 500) : '100%'} />}
            </div>
        </div>
    )
}

export default SnippingReportChart;
