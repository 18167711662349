import React, { useState } from "react";
import DataTable from "react-data-table-component";

import { useMemo } from "react";
import { Button, Form, OverlayTrigger } from "react-bootstrap";
import { useFormik } from "formik";

import { TbGridDots } from "react-icons/tb";
import { HiChevronDown } from "react-icons/hi";
import { components } from 'react-select';

import { FileManagementService } from "../../../services/FileManagementService";
import { BsCloudDownloadFill } from "react-icons/bs";
import { downloadXl } from "../../../functions";
import { useEffect } from "react";
import DetailCutomRowStyle from "../../components/DetailCutomRowStyle";
import { Tooltip } from "@mui/material";

const datatableCustomStyles = {
  rows: {
    style: {
      minHeight: '20px', // override the row height
      fontSize: '0.7em',
      fontColor: '#212529 !important',
      width: '100%'
    },
  },
  headCells: {
    style: {
      paddingLeft: '5px', // override the cell padding for head cells
      paddingRight: '5px',
      textTransform: "uppercase",
      fontSize: '0.7em',
      fontWeight: '800',
      fontColor: '#6c757d !important',
      opacity: 1,
      minHeight: "10px",
    },
  },
  cells: {
    style: {
      paddingLeft: '8px', // override the cell padding for data cells
      paddingRight: '8px',
      width: "100%",
    },
  },
  pagination: {
    style: {
      color: '#000',
      fontSize: '13px',
      minHeight: '56px',
      backgroundColor: '#fff',
      borderTopStyle: 'solid',
      borderTopWidth: '1px',
      borderTopColor: '#f2f2f2',
    },
    pageButtonsStyle: {
      borderRadius: '50%',
      height: '40px',
      width: '40px',
      padding: '8px',
      margin: '0px',
      cursor: 'pointer',
      transition: '0.4s',
      color: '#000',
      fill: '#000',
      backgroundColor: 'transparent',
      '&:disabled': {
        cursor: 'unset',
        color: '#e2e2e2',
        fill: '#e2e2e2',
      },
      '&:hover:not(:disabled)': {
        backgroundColor: '#f2f2f2',
      },
      '&:focus': {
        outline: 'none',
        backgroundColor: '#f5f5f5',
      },
    },
  },
};

function ReasonCodeFileManagementDetails(params) {
  const { report_station, report_job_status, report_date_range, report_hour_count, user, clients, facilities, facility_id } = params
  const [reRenderDataTable, setRerenderDataTable] = useState(false)
  const [pageno, setPageNo] = useState(1);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(100);
  const [selectValue, setSelectValue] = useState('');
  const [colorRow, setColorRow] = React.useState({});
  const [data, setData] = useState([])
  const [day, setDay] = useState(true)
  const [year, setYear] = useState(false)
  const [week, setWeek] = useState(false)
  const [month, setMonth] = useState(false)
  const [monthList, setMonthList] = useState([])
  const [limit, setLimit] = useState(100)
  const [searchedData, setSearchedData] = useState({
    search: "",
    client: "",
    created_date: "",
    date_range: "",
  })

  const fileManagementService = new FileManagementService();
  const { getFileManagementDetailPayer, downloadFileManagementReasonCodeDetails } = fileManagementService



  const handlePerRowsChange = async (newPerPage, page) => {
    setLimit(newPerPage);
    setPageNo(1)

    fetchData(1, newPerPage, report_station, report_job_status, report_date_range, report_hour_count, user, day, year, week, month);
    handleResetDataTable()

  };

  const handlePageChange = page => {
    console.log(limit, report_station, report_job_status, report_date_range, report_hour_count, user, day, year, week, month);
    setPageNo(page)
    fetchData(page, limit, report_station, report_job_status, report_date_range, report_hour_count, user, day, year, week, month);
  };




  const fetchData = (page, limit, station, job_status, date_range, hour_count, user, day, year, week, month) => {

    setLoading(true)
    console.log(page, limit, station, job_status, date_range, hour_count, user, day, year, week, month);
    getFileManagementDetailPayer(page, limit, report_station, report_job_status, report_date_range, report_hour_count, user, day, year, week, month, clients, facilities, facility_id).then((response) => {
      console.log(response?.data?.results);
      setData(response?.data?.results);
      setLimit(limit);
      setPageNo(page)
      setTotalRows(response?.data?.count);
      setLoading(false);
    });

  }

  const [expandedRows, setExpandedRows] = useState([]);


  const handleRowExpand = (row) => {
    const isRowExpanded = expandedRows.includes(row);

    setExpandedRows(isRowExpanded ? expandedRows.filter((r) => r !== row) : [...expandedRows, row]);
  };

  const columns = useMemo(
    () => [
      {
        id: 'S.NO',
        name: 'S.NO',
        selector: (row, index) => pageno == 1 ? index + 1 : (pageno - 1) * limit + index + 1,
        wrap: true,
        width: "40px",
        grow: 0.1,

      },

      {
        id: 'PAYER NAME',
        name: 'PAYER NAME',
        selector: row => row?.payer_name,
        cell: (row) =>

          // <span onClick={() => handleRowClick(row)}> {row?.payer_name}</span>
          <div onClick={() => { handleRowClick(row) }} style={row?.payer_name ? { height: 'auto' } : { height: '100%' }}>


            <div className='d-flex flex-row justify-content-between align-items-center gap-2' onClick={() => handleRowClick(row)} style={{ width: '170px' }}>
              <div className='overflow-hidden text-truncate' style={{ width: "120px" }}>
                <Tooltip disableInteractive title={row?.payer_name} placement="top" arrow>
                  <span className="text-truncate" onClick={() => handleRowClick(row)}> {row?.payer_name ? row?.payer_name : row?.payer_name}</span>
                </Tooltip>
              </div>
            </div>
          </div>
        ,

        left: true,
        wrap: true,
        reorder: false,
        grow: 0.1,
      },
      {
        id: 'GROUP CODE',
        name: 'GROUP CODE',
        selector: row => row?.group_code,
        cell: (row) =>
          <span onClick={() => handleRowClick(row)}> {row?.group_code}</span>,

        left: true,
        wrap: true,
        reorder: false,
        grow: 0.1,
      },
      {
        id: 'CARC',
        name: 'CARC',
        selector: row => row?.carc,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.carc}</span>,

        left: true,
        wrap: true,
        reorder: false,
        grow: 0.1,
      },
      {
        id: 'RARC',
        name: 'RARC',
        selector: row => row?.rarc,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.rarc}</span>,

        left: true,
        wrap: true,
        reorder: false,
        grow: 0.1,
      },

      {
        id: 'STATUS',
        name: 'STATUS',
        selector: row => row?.is_approved,
        cell: (row) =>
          <Tooltip disableInteractive title={row?.is_approved ? "APPROVED" : "NOT APPROVED"} placement="top" arrow>
            <span onClick={() => handleRowClick(row)}> {row?.is_approved ? "APPROVED" : "NOT APPROVED"}</span>
          </Tooltip>,

        left: true,
        wrap: true,
        reorder: false,
        grow: 0.1,
      },

    ],

    [pageno],
  );

  const conditionalRowStyles = [

    {
      when: row => colorRow?.id === row?.id ? true : false,
      style: (row, index) => ({
        backgroundColor: colorRow?.id === row.id ? '#e4e6ef' : '#f2f2f2',
        color: 'black',
        // fontWeight: '550'
      }),
    },


  ];
  const handleRowClick = (row) => {
    setColorRow(row);
  };


  const searchFormik = useFormik({
    initialValues: {
      search: "",
      job_status: report_job_status,
      station: report_station,
      date_range: report_date_range,
      hour_count: report_hour_count

    },
    onSubmit: (values) => {
      console.log(values);
      handleResetDataTable()
    }
  })

  const [datetime, setDateTime] = useState([]);



  const searchData = ({ station, job_status, date_range, hour_count }) => {
    fetchData(1, limit, station, selectValue, datetime, hour_count)
  }

  const handleChangeSelect = async (e, name) => {
    let selectedId = []
    let selectedOptons = []

    e.map((option) => {
      selectedId.push(option.value)
      selectedOptons.push({ option })
    })

    searchFormik.setValues({ ...searchFormik.values, [name]: selectedId })
    setSelectValue(selectedId)

  }


  const showTooltip = (text, props) => (
    <Tooltip id="button-tooltip" {...props}>
      {text}
    </Tooltip>
  );


  const handleResetDataTable = () => {
    setRerenderDataTable(true)
  }
  const [loader, setLoader] = useState(false);

  const handleDownload = () => {
    setLoader(true)
    downloadFileManagementReasonCodeDetails(1, limit, report_station, report_job_status, report_date_range, report_hour_count, user, day, year, week, month, clients, facilities, facility_id).then((response) => {
      setLoader(false)
      console.log(response);
      downloadXl(response?.data?.export_file)
    });
  }
  useEffect(() => {
    fetchData(1, limit, report_station, report_job_status, report_date_range, report_hour_count, user, day, year, week, month);
  }, [])
  return (
    <React.Fragment>
      <section className="section">
        <div className="row align-items-top py-2 gap-2 gap-md-5">
          <div className="col-lg-12">
            <div className="card card_background">

              <Form onSubmit={searchFormik.handleSubmit} >
                <div className="d-flex justify-content-end">

                  <div className="px-1 py-1 d-flex flex-row justify-content-start gap-2 align-items-center flex-wrap">

                    <div>
                      <Tooltip disableInteractive title={"Download"} placement="top" arrow>
                        {!loader ?

                          <span style={{ cursor: "pointer" }} className="svg-icon svg-icon-3" onClick={() => { handleDownload() }}>
                            <BsCloudDownloadFill color="#1ba1a7" />
                          </span>
                          :
                          <span className="svg-icon svg-icon-3">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          </span>
                        }

                      </Tooltip>

                    </div>
                  </div>
                </div>

              </Form>


              <div className="card-body p-common">
                <div className="table-responsive">
                  <div className="dashboardtable custom_background">
                    {/* {!reRenderDataTable && */}
                    <DataTable
                      // className="custom-datatable-details"
                      paginationDefaultPage={pageno}

                      fixedHeader={true}
                      progressPending={loading}
                      data={data}
                      columns={columns}
                      pointerOnHover={true}
                      pagination
                      paginationServer
                      onRowClicked={handleRowClick}
                      // highlightOnHover
                      paginationTotalRows={totalRows}
                      paginationPerPage={limit}
                      paginationRowsPerPageOptions={[25, 50, 100, 150, 200]}
                      onChangeRowsPerPage={handlePerRowsChange}
                      onChangePage={handlePageChange}
                      conditionalRowStyles={conditionalRowStyles}
                      theme="detail_solarized"

                      customStyles={DetailCutomRowStyle}


                    />
                    {/* } */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment >
  )
}
export default ReasonCodeFileManagementDetails




