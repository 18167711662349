import { darken, desaturate, lighten } from 'polished';
import React from 'react'
import Chart from 'react-apexcharts';
import CircleSkelitonLoader from '../../loader/CircleSkelitonLoader';

const SingleReport = ({ isVisible, handleClickInnerGraph, handleClickInnerData, singleData, singleColor }) => {
    const labels = ['Payment 835', 'Payment 835 Lite', 'Patient Pay', 'Correspondence']
    const curent_labels = []
    singleData?.forEach((i, index) => {
        if (i != 0) {
            curent_labels.push(labels[index])
        }
    })
    const options = {
        dataLabels: {
            enabled: true,
            style: {
                colors: ['black']
            },
            dropShadow: {
                enabled: false,
                enabledOnSeries: undefined,
                top: 0,
                left: 0,
                blur: 3,
                opacity: 0.35
            }
        },


        fill: {
            type: 'gradient',
        },
        chart: {
            id: 'basic-donut-inner',
            type: 'donut',
            toolbar: {
                show: false
            },
            fontFamily: 'Open Sans',
            events: {
                click: handleClickInnerGraph,
                dataPointSelection: handleClickInnerData
            }
        },

        legend: {
            show: true,
            customLegendItems: curent_labels,
            offsetX: 0,
            offsetY: 100,
        },
        labels: curent_labels,
        tooltip: {
            show: true,
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                const color = w.config.colors[seriesIndex];
                const mainLabel = w.globals.labels[seriesIndex]
                let tooltip = `${mainLabel} : ${singleData?.filter(i => i > 0)[seriesIndex]}`
                return `<div style="background-color:${color};padding:5px" class="">
                        <span style="color:${"#000"};" > ${tooltip}  </span>
                </div>`

            }
        },
        colors: [darken(0.1, singleColor), singleColor, lighten(0.1, singleColor), desaturate(0.2, singleColor)],

    };
    return (
        isVisible ?
            <CircleSkelitonLoader /> :
            <Chart options={options} series={singleData?.filter(i => i > 0)} type="donut" height={'90%'} />
    )
}

export default SingleReport