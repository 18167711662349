import React, { useState } from "react";
import newicon from "../../../../../../assets/img/new-icon.svg";
import deleteicon from "../../../../../../assets/img/delete-icon.svg";
import copyicon from "../../../../../../assets/img/copy-icon.svg";
import { Form, InputGroup } from "react-bootstrap";
import InputMask from "react-input-mask";
import {
  BiChevronLeft,
  BiChevronRight,
  BiFirstPage,
  BiLastPage,
} from "react-icons/bi";
import { DATA_ENTRY, EDIT_WORK, QA_REVIEW } from "../../../../../../functions";
import { DbkField } from "../../Elements/DbkField";
import moment from "moment";
import { handleKeyPress } from "../../gridFunctions";

const CorresClaim = (props) => {
  const {
    job_id,
    log_id,
    setDefaultValueAssignedWorkClaim,
    addingNewCorrespondanceClaim,
    corresPondanceFocus,
    setCorresPondanceFocus,
    handleFocusCorresPondance,
    addingNewCorrespondanceClaimDbk,
    removeCorrespondanceClaimDbk,
    formik,
    arrayHelpers,
    currentPage,
    setClaim835Focus,
    claimCorresIndex,
    setClaimCorresIndex,
    handleClaimPagination,
    handleBackspace,
    totalPages,
    handleWheel,
    jobData,
    isDbkPermission,
    setState,
    state,
    handlePageNavigate,
    pdfLoaded


  } = props;

  const pathname = window.location.pathname;
  const gridData = jobData?.grid_data;
  const qaCorrectedData = gridData?.qa_corrected_data;
  const renderClass = (name, type, defaultClass, index) => {
    if (formik.errors &&
      formik.errors?.claims &&
      formik.errors?.claims[index] &&
      formik.errors?.claims[index]?.[name]) {
      return defaultClass + ' error-field'
    }
    if (pathname == QA_REVIEW) {

      if (qaCorrectedData &&
        qaCorrectedData?.claims &&
        qaCorrectedData?.claims[index] &&
        qaCorrectedData?.claims[index]?.[name]) {
        return defaultClass + ' qa-field'
      }
      else if (type == 'DATE' &&
        gridData?.claims &&
        gridData?.claims[formik.values?.claims[index]?.claim_corrected_index ? formik.values?.claims[index]?.claim_corrected_index : index] &&
        moment(formik.values?.claims[index]?.[name]).format("MM/DD/YYYY") != moment(gridData?.claims[formik.values?.claims[index]?.claim_corrected_index ? formik.values?.claims[index]?.claim_corrected_index : index]?.[name]).format("MM/DD/YYYY")) {
        return defaultClass + ' qa-field'
      }
      else if (type == 'TEXT' &&
        gridData?.claims &&
        gridData?.claims[formik.values?.claims[index]?.claim_corrected_index ? formik.values?.claims[index]?.claim_corrected_index : index] &&
        formik.values?.claims[index]?.[name]?.toUpperCase() !=
        gridData?.claims[formik.values?.claims[index]?.claim_corrected_index ? formik.values?.claims[index]?.claim_corrected_index : index]?.[name]?.toUpperCase()) {
        return defaultClass + ' qa-field'
      }
      else if (type == 'STRING' &&
        gridData?.claims &&
        gridData?.claims[formik.values?.claims[index]?.claim_corrected_index ? formik.values?.claims[index]?.claim_corrected_index : index] &&
        formik.values?.claims[index]?.[name] !=
        gridData?.claims[formik.values?.claims[index]?.claim_corrected_index ? formik.values?.claims[index]?.claim_corrected_index : index]?.[name]) {
        return defaultClass + ' qa-field'
      }
      else if (type == 'NUMBER' &&
        gridData?.claims &&
        gridData?.claims[formik.values?.claims[index]?.claim_corrected_index ? formik.values?.claims[index]?.claim_corrected_index : index] &&
        parseFloat(formik.values?.claims[index]?.[name] ? formik.values?.claims[index]?.[name] : 0).toFixed(2) !=
        parseFloat(gridData?.claims[formik.values?.claims[index]?.claim_corrected_index ? formik.values?.claims[index]?.claim_corrected_index : index]?.[name] ?
          gridData?.claims[formik.values?.claims[index]?.claim_corrected_index ? formik.values?.claims[index]?.claim_corrected_index : index]?.[name] : 0).toFixed(2)) {
        return defaultClass + ' qa-field'
      }

      else if (gridData?.transaction_type != "CORRESPONDENCE" || formik.values?.claims[index]?.corrected_claim) {
        return defaultClass + ' qa-field'
      }
      else {
        return defaultClass;
      }


    }
    else if ((pathname == EDIT_WORK + job_id || pathname == EDIT_WORK + job_id + "?log_id=" + log_id) &&
      qaCorrectedData &&
      qaCorrectedData?.claims &&
      qaCorrectedData?.claims[index] &&
      qaCorrectedData?.claims[index]?.[name]) {
      return defaultClass + ' qa-field'
    }
    else {
      return defaultClass;
    }
  }
  return (
    <React.Fragment>
      <div className="claim835-mt">
        <div className="healthcare-sub">
          <div className="healthcare-top">
            <div className="leftside-block">
              <div className="accordion" id="claim">
                <button
                  tabIndex="-1"
                  type="button"
                  className="btn btn-healthcare accordion-icon"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="true"
                  aria-controls="collapseTwo"
                >
                  Corres Claim
                </button>
              </div>
              <div className="">
                <button
                  tabIndex="-1"
                  type="button"
                  className="btn btn-healthcare"
                  id="btn-clam-corress-add"
                  onClick={() => {
                    addingNewCorrespondanceClaim();
                    setClaimCorresIndex(claimCorresIndex + 1);
                  }}
                >
                  {" "}
                  <img src={newicon} alt="" />
                  New
                </button>
              </div>
              {formik.values?.claims?.length == 1 ? (
                <button
                  type="button"
                  tabIndex="-1"

                  className="btn btn-healthcare"

                >
                  {" "}
                  <img src={deleteicon} alt="" /> Delete
                </button>
              ) : (
                <button
                  type="button"
                  tabIndex="-1"
                  className="btn btn-healthcare"

                >
                  {" "}
                  <img src={deleteicon} alt="" /> Delete
                </button>
              )}

              <div className="" >
                <button
                  tabIndex="-1"
                  type="button"
                  className="btn btn-healthcare"
                >
                  {" "}
                  <img src={copyicon} alt="" /> Copy
                </button>
              </div>
            </div>
            <div className="rightside-block exclude-disabled-new">
              <button
                tabIndex="-1"
                type="button"
                className="py-0 m-0 btn pagination-btn btn-sm "
                onClick={(e) => {
                  setClaimCorresIndex(0);
                  setState({
                    ...state,
                    updateDisable: !state.updateDisable,
                  })

                }}
              >
                <span>
                  <BiFirstPage
                    size="1.3em"
                    style={
                      claimCorresIndex + 1 === 1
                        ? { color: "#ffefef" }
                        : { color: "black" }
                    }
                  />
                </span>
              </button>
              <button
                tabIndex="-1"
                type="button"
                className="py-0 m-0 btn pagination-btn btn-sm"
                disabled={claimCorresIndex + 1 === 1 ? true : false}
                onClick={(e) => {

                  setClaimCorresIndex(claimCorresIndex - 1);
                  setState({
                    ...state,
                    updateDisable: !state.updateDisable,
                  })
                }}
              >
                <BiChevronLeft
                  size="1.3em"
                  style={
                    claimCorresIndex + 1 === 1
                      ? { color: "#ffefef" }
                      : { color: "black" }
                  }
                />
              </button>
              <div className="pagenum-sub">
                <input
                  tabIndex="-1"
                  className="viewer-toolbar-current-page-input w-82"
                  type="text"
                  value={claimCorresIndex + 1}
                  onKeyDown={(e) => handleBackspace(e)}
                  onChange={(e) => {
                    handleClaimPagination(e.target.value, e);
                  }}
                />{" "}
                / {formik?.values?.claims?.length}
              </div>
              <button
                type="button"
                tabIndex="-1"
                className="py-0 m-0 pagination-btn btn btn-sm"
                disabled={
                  formik.values?.claims?.length === claimCorresIndex + 1
                    ? true
                    : false
                }
                onClick={(e) => {
                  setClaimCorresIndex(claimCorresIndex + 1);
                  setState({
                    ...state,
                    updateDisable: !state.updateDisable,
                  })
                }}
              >
                <BiChevronRight
                  size="1.3em"
                  style={
                    formik.values?.claims?.length - 1 === claimCorresIndex
                      ? { color: "#ffefef" }
                      : { color: "black" }
                  }
                />
              </button>
              <button
                type="button"
                tabIndex="-1"
                className="py-0  btn pagination-btn btn-sm "
                disabled={
                  !formik.values?.claims?.length < claimCorresIndex &&
                    formik.values?.claims?.length - 1 === claimCorresIndex
                    ? true
                    : false
                }
                onClick={(e) => {
                  setClaimCorresIndex(formik.values?.claims.length - 1);
                  setState({
                    ...state,
                    updateDisable: !state.updateDisable,
                  })
                }}
              >
                <span>
                  <BiLastPage
                    size="1.3em"
                    style={
                      formik.values?.claims?.length - 1 === claimCorresIndex
                        ? { color: "#ffefef" }
                        : { color: "black" }
                    }
                  />
                </span>
              </button>
            </div>
          </div>
        </div>
        {formik?.values?.claims?.map((claim, index) => (
          <React.Fragment key={index}>
            {claimCorresIndex == index && (
              <div
                className="bottom-wrapper patient_correspondence_details accordion-collapse collapse show"
                id="collapseTwo"
                aria-labelledby="headingTwo"
                data-bs-parent="#claim"
              >
                <div className="sub-section">
                  <div className="">
                    <Form.Label
                      tabIndex="-1"
                      type="button"
                      className="btn btn-claim"
                    >
                      Claim Details{" "}
                    </Form.Label>
                  </div>
                  <div className="wrapper-patient">
                    <InputGroup>

                      <InputGroup.Text>Patient Account Number</InputGroup.Text>
                      <div className="dbk-main-container">
                        <Form.Control
                          type="text"

                          className={renderClass('patient_account_number', 'TEXT', 'w-patient', index)}

                          value={
                            formik.values?.claims[index].patient_account_number
                          }
                          name={`claims[${index}].patient_account_number`}

                        />

                      </div>
                    </InputGroup>
                  </div>
                  <div className="wrapper">
                    <InputGroup>
                      <InputGroup.Text>Period Start</InputGroup.Text>
                      <div className="dbk-main-container">
                        <InputMask
                          mask="99/99/9999"
                          className={renderClass('period_start', 'DATE', 'patient-date', index)}


                          value={formik.values?.claims[index].period_start}
                          name={`claims[${index}].period_start`}
                          placeholder={"mm/dd/yyyy"}

                        />

                      </div>
                    </InputGroup>
                  </div>
                  <div className="wrapper">
                    <InputGroup>
                      <InputGroup.Text>Period End</InputGroup.Text>
                      <div className="dbk-main-container">
                        <InputMask
                          mask="99/99/9999"
                          className={renderClass('period_end', 'DATE', 'patient-date', index)}

                          value={formik.values?.claims[index].period_end}
                          name={`claims[${index}].period_end`}
                          placeholder={"mm/dd/yyyy"}

                        />


                      </div>
                    </InputGroup>
                  </div>
                  <div className="wrapper-miname">
                    <InputGroup>
                      <InputGroup.Text>Billed Amount</InputGroup.Text>
                      <div className="dbk-main-container">
                        <Form.Control
                          className={renderClass('billed', 'NUMBER', 'w-miname text-end', index)}


                          type="number"


                          value={formik.values?.claims[index].billed}
                          name={`claims[${index}].billed`}

                        />
                      </div>
                    </InputGroup>
                  </div>
                </div>
                <div className="sub-section">
                  <div className="">
                    <Form.Label
                      tabIndex="-1"
                      type="button"
                      className="btn btn-claim"
                    >
                      Patient
                    </Form.Label>
                  </div>
                  <div className="wrapper-patient">
                    <InputGroup>
                      <InputGroup.Text>First Name</InputGroup.Text>
                      <div className="dbk-main-container">
                        <Form.Control
                          className={renderClass('patient_first_name', 'TEXT', 'w-fsname', index)}

                          type="text"


                          value={
                            formik.values?.claims[index].patient_first_name
                          }
                          name={`claims[${index}].patient_first_name`}

                        />


                      </div>
                    </InputGroup>
                  </div>
                  <div className="wrapper-miname">
                    <InputGroup>
                      <InputGroup.Text>Middle Name</InputGroup.Text>
                      <div className="dbk-main-container">
                        <Form.Control
                          className={renderClass('patient_middle_name', 'TEXT', 'w-miname', index)}

                          type="text"

                          value={
                            formik.values?.claims[index].patient_middle_name
                          }
                          name={`claims[${index}].patient_middle_name`}

                        />

                      </div>
                    </InputGroup>
                  </div>
                  <div className="wrapper-patient">
                    <InputGroup>
                      <InputGroup.Text>Last Name</InputGroup.Text>
                      <div className="dbk-main-container">
                        <Form.Control
                          className={renderClass('patient_last_name', 'TEXT', 'w-fsname', index)}

                          type="text"
                          value={formik.values?.claims[index].patient_last_name}
                          name={`claims[${index}].patient_last_name`}

                        />
                      </div>
                    </InputGroup>
                  </div>
                </div>
                <div className="sub-section">
                  <div className="">
                    <Form.Label
                      tabIndex="-1"
                      type="button"
                      className="btn btn-claim"
                    >
                      Additionalinfo{" "}
                    </Form.Label>
                  </div>
                  <div className="pagefrom-corress">
                    <InputGroup>
                      <InputGroup.Text>Page From</InputGroup.Text>

                      <Form.Control
                        className={renderClass('page_from', 'NUMBER', 'w-fsname-corress', index)}


                        type="text"

                        value={formik.values?.claims[index].page_from}
                        name={`claims[${index}].page_from`}
                      />
                    </InputGroup>
                  </div>
                  <div className="pageto-corress">
                    <InputGroup>
                      <InputGroup.Text>Page To</InputGroup.Text>
                      <Form.Control
                        className={renderClass('page_to', 'NUMBER', 'w-fsname-corress', index)}

                        type="text"
                        value={formik.values?.claims[index].page_to}
                        name={`claims[${index}].page_to`}
                      />
                    </InputGroup>
                  </div>
                </div>
              </div>
            )}
          </React.Fragment>
        ))}
      </div>
    </React.Fragment>
  );
};

export default CorresClaim;
