import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import Button from 'react-bootstrap/Button';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { OverlayTrigger, Toast, ToastContainer } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import Modal from 'react-modal'

import { UserApiService } from '../../../services/UserApiService';
import Sidebar from '../../includes/Sidebar';
import { FilterComponent } from '../../components/FilterComponent';
import ChangePasswordForm from './ChangePasswordForm';
import { useSelector } from 'react-redux';
import { selectIsSuperUser, selectPermissions } from '../../../slice/user/userSlice';
import { Tooltip } from '@mui/material';

const datatableCustomStyles = {
    rows: {
        style: {
            minHeight: '20px', // override the row height
            fontSize: '0.7em',
            fontColor: '#212529 !important',
            width: '100%'
        },
    },
    headCells: {
        style: {
            paddingLeft: '5px', // override the cell padding for head cells
            paddingRight: '5px',
            textTransform: "uppercase",
            fontSize: '0.7em',
            fontWeight: '800',
            fontColor: '#6c757d !important',
            opacity: 1,
            minHeight: "10px",
        },
    },
    cells: {
        style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
            width: "100%",
        },
    },
    pagination: {
        style: {
            color: '#000',
            fontSize: '13px',
            minHeight: '56px',
            backgroundColor: '#fff',
            borderTopStyle: 'solid',
            borderTopWidth: '1px',
            borderTopColor: '#f2f2f2',
        },
        pageButtonsStyle: {
            borderRadius: '50%',
            height: '40px',
            width: '40px',
            padding: '8px',
            margin: '0px',
            cursor: 'pointer',
            transition: '0.4s',
            color: '#000',
            fill: '#000',
            backgroundColor: 'transparent',
            '&:disabled': {
                cursor: 'unset',
                color: '#e2e2e2',
                fill: '#e2e2e2',
            },
            '&:hover:not(:disabled)': {
                backgroundColor: '#f2f2f2',
            },
            '&:focus': {
                outline: 'none',
                backgroundColor: '#f5f5f5',
            },
        },
    },
};

function ListUsers() {
    const userService = new UserApiService()
    const { getAllUsers, toggleUser } = userService
    const permissions = useSelector(selectPermissions);
    const isSuperUser = useSelector(selectIsSuperUser);
    const isHavePermission = (name) => {
        return permissions?.includes(name) || isSuperUser
    }

    const [reRenderDataTable, setRerenderDataTable] = useState(false)
    const navigate = useNavigate()

    const [state, setState] = useState({
        error: false,
        error_message: "",
        showToast: false,
        showToastPassword: false
    });
    const [loading, setLoading] = useState(false);
    const [colorRow, setColorRow] = useState({});
    const [totalRows, setTotalRows] = useState(100);
    const [limit, setLimit] = useState(10);
    const [data, setData] = useState({})
    const [userId, setUserId] = useState(null)
    const [toaster, setToaster] = useState(false)

    const [toggleReportPopup, setToggleReportPopup] = useState(false);
    const [reportData, setReportData] = useState([]);
    const [loader, setLoader] = useState(false);

    const [filterText, setFilterText] = React.useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [pageno, setPageNo] = useState(1);


    function closeModal() {
        setIsOpen(false);
        setUserId(null)
    }
    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };

    const [modalUpload, setModalUpload] = useState(false);

    // const renderEditTooltip = (props) => (
    //     <Tooltip id="button-tooltip" {...props}>
    //         Edit
    //     </Tooltip>
    // );
    // const renderchangePasswordTooltip = (props) => (
    //     <Tooltip id="button-tooltip" {...props}>
    //         Change Password
    //     </Tooltip>
    // );
    // const renderToggleTooltip = (props) => (
    //     <Tooltip id="button-tooltip" {...props}>
    //         Status
    //     </Tooltip>
    // );
    // const renderReportTooltip = (props) => (
    //     <Tooltip id="button-tooltip" {...props}>
    //         Report
    //     </Tooltip>
    // );
    // const showTooltip = (text, props) => (
    //     <Tooltip id="button-tooltip" {...props}>
    //         {text}
    //     </Tooltip>
    // );
    const columns = useMemo(
        () => [
            {
                id: 'id',
                name: 'S NO',
                selector: (row, index) => pageno == 1 ? index + 1 : (pageno - 1) * limit + index + 1,
                // 
                wrap: true,
                width: "70px",
                grow: 0,

            },

            {
                id: 'username',
                name: 'Username',
                selector: row => row.username,
                cell: (row) => <div>
                    {row?.username &&
                        <div className='d-flex flex-row justify-content-between align-items-center gap-2' style={{ width: '170px' }}>
                            <div className='overflow-hidden text-truncate' style={{ width: "120px" }} onClick={() => handleRowClick(row)}>
                                <Tooltip disableInteractive title={row?.username} placement="top" arrow>
                                    <span className='text-truncate'> {row?.username}</span>
                                </Tooltip>
                            </div>
                        </div>
                    }
                </div>,

                wrap: true,
                // width: "160px",
                //grow: 0.3,

            },
            {
                id: 'name',
                name: 'Name',
                selector: row => row.name,
                cell: (row) => <div>
                    {row?.name &&

                        <div className='d-flex flex-row justify-content-between align-items-center gap-2' style={{ width: '170px' }}>
                            <div className='overflow-hidden text-truncate' style={{ width: "120px" }} onClick={() => handleRowClick(row)}>
                                <Tooltip disableInteractive title={row?.name} placement="top" arrow>
                                    <span className='text-truncate'> {row?.name}</span>
                                </Tooltip>
                            </div>
                        </div>
                    }
                </div>,

                wrap: true,
                // width: "160px",
                //grow: 0.3,

            },

            {
                id: 'email',
                name: 'Email',
                selector: row => row.email,
                cell: (row) => <div>
                    {row?.email &&
                        <div className='d-flex flex-row justify-content-between align-items-center gap-2' style={{ width: '240px' }}>
                            <div className='overflow-hidden text-truncate' style={{ width: "240px" }} onClick={() => handleRowClick(row)}>
                                <Tooltip disableInteractive title={row?.email} placement="top" arrow>
                                    <span className='text-truncate'> {row?.email}</span>
                                </Tooltip>
                            </div>
                        </div>

                    }
                </div>,

                left: true,
                wrap: true,
                //width: "400px",
                //grow: 0.6,


            },

            {
                id: 'edit',
                name: 'Actions',

                cell: (row) =>
                    <div className="d-flex justify-content-end flex-shrink-0 align-items-center gap-2" onClick={() => { handleRowClick(row) }}>
                        {isHavePermission("edit_users") &&

                            <Tooltip disableInteractive title={"Edit"} placement="top" arrow>
                                <Link to={'/edit-user/' + row.pk} className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1" >
                                    <span className="svg-icon svg-icon-3">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path opacity="0.3" d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z" fill="currentColor"></path>
                                            <path d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z" fill="currentColor"></path>
                                        </svg>
                                    </span>
                                </Link>
                            </Tooltip>
                        }
                        {isHavePermission("change_user_password") &&

                            <Tooltip disableInteractive title={"Change Password"} placement="top" arrow>

                                <Link onClick={() => { setIsOpen(true); setUserId(row.pk) }} className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1" >
                                    <span className="svg-icon svg-icon-3">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-key" viewBox="0 0 16 16">
                                            <path d="M0 8a4 4 0 0 1 7.465-2H14a.5.5 0 0 1 .354.146l1.5 1.5a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0L13 9.207l-.646.647a.5.5 0 0 1-.708 0L11 9.207l-.646.647a.5.5 0 0 1-.708 0L9 9.207l-.646.647A.5.5 0 0 1 8 10h-.535A4 4 0 0 1 0 8zm4-3a3 3 0 1 0 2.712 4.285A.5.5 0 0 1 7.163 9h.63l.853-.854a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.793-.793-1-1h-6.63a.5.5 0 0 1-.451-.285A3 3 0 0 0 4 5z" />
                                            <path d="M4 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                                        </svg>
                                    </span>
                                </Link>
                            </Tooltip>
                        }
                        {isHavePermission("enable_disable_user") &&

                            <Tooltip disableInteractive title={"Status"} placement="top" arrow>
                                <div className="ms-2 form-check form-switch  ">
                                    <input className="form-check-input" type="checkbox" defaultChecked={row.is_active} role="switch" id="flexSwitchCheckDefault" onClick={() => __toggleUser(row.pk)} />
                                </div>
                            </Tooltip>
                        }



                    </div>,
                // ignoreRowClick: false,

                // width: '280px',
                // allowOverflow: true,
                button: false,
                right: true
                //button: true,
            },

        ],
        [pageno, loading],
    );
    const [message, setMessage] = useState("")


    const [ToastMessage, setToastMessage] = useState({
        status: false,
        message: "",
        type: "",
    })
    const [isSwal, setIsSwal] = useState({
        show: false,
        message: '',
    })


    const fetchUsers = async (page, limit, search) => {

        setLoading(true);

        //setLimit(limit);
        await getAllUsers({ page, limit, search }).then((response) => {
            setData(response?.data);
            setPageNo(page)
            setLimit(limit);
            setTotalRows(response?.data?.count);
            setLoading(false);
            //console.log(response);
        })
        // const response = await axiosInstance.get(GET_ALL_USER_URL + "?page=" + page + "&limit=" + limit);

    };
    const handlePageChange = page => {
        setPageNo(page)
        fetchUsers(page, limit, filterText);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        // setLoading(true);

        // const response = await axiosInstance.get(GET_ALL_USER_URL + "?page=" + page + "&limit=" + newPerPage);
        // setData(response?.data);
        // setLimit(newPerPage);
        // setTotalRows(response?.data?.data?.count);
        fetchUsers(1, newPerPage, filterText)
        setLimit(newPerPage);
        setPageNo(1)
        // setLoading(false);
        handleResetDataTable()


    };
    useEffect(() => {
        // getAllUsers()((response) => {
        //     setData(response);
        // });
        fetchUsers(1, limit);
    }, []);

    const __toggleUser = (user) => {
        let formData = new FormData();    //formdata object

        formData.append('user', user);

        toggleUser(user).then((response) => {
            console.log(response?.status_code);
            if (response?.status && response?.status_code === 201) {
                setState({ showToast: !state.showToast })
                setToaster('success')
                // getAllUsers()((response) => {
                //     setData(response);
                // });
            } else {
                setState({ error: true, error_message: response?.message });
            }
        });
    }
    useEffect(() => {
        if (toaster) {
            const timer = setTimeout(() => {
                setToaster('')
            }, 2000);
            return () => clearTimeout(timer);
        }
    }, [toaster])

    const searchUsers = async (e) => {
        setFilterText(e.target.value)
        let data = e.target.value ? e.target.value : '';
        await fetchUsers(1, limit, data)
        // const response = await axiosInstance.get(GET_ALL_USER_URL + "?limit=" + data?.data?.count + "&search=" + data);
        // setData(response?.data);
        handleResetDataTable()
    }
    // const filteredItems = data?.data?.results?.filter(
    //     item => item?.username?.toLowerCase().includes(filterText.toLowerCase()),
    // );
    const subHeaderComponentMemo = React.useMemo(() => {


        // return (
        //     <FilterComponent onFilter={(e) => searchUsers(e)} onClear={handleClear} filterText={filterText} />
        // );
    }, [filterText, resetPaginationToggle]);
    const handleClear = () => {
        if (filterText) {
            //setResetPaginationToggle(!resetPaginationToggle);
            setFilterText('');
            fetchUsers(1, limit);

        }
    };
    const __passwordToast = (status, message, status_code) => {
        // console.log(message);
        setState({ ...state, showToastPassword: true })
        setToaster('success')
        closeModal()
    }
    const checkUserPermission = (check) => {
        var permissions = JSON.parse(localStorage.getItem('permissions'))
        var data = permissions?.find((element) => {
            return element == check;
        })
        // // console.log(data)
        if (data && data != undefined) {
            return true;
        }
        else {

            return false;
        }
    }
    const __closeToast = () => {
        setState({
            showToast: false,
            showToastPassword: false
        })
    }
    const handleRowClick = (row) => {
        setColorRow(row);
        // // console.log(row);
    };
    var i = 0;
    // const conditionalRowStyles = [

    //     {
    //         when: row => { i = i + 1; return i % 2 == 0 || colorRow.pk == row.pk ? true : false },
    //         style: (row, index) => ({
    //             backgroundColor: colorRow?.pk == row.pk ? '#dbf4f5' : '#f2f2f2',
    //         }),
    //     },

    // ];
    const conditionalRowStyles = [

        {
            when: row => colorRow.pk == row.pk ? true : false,
            style: (row, index) => ({
                backgroundColor: colorRow?.pk == row.pk ? '#dbf4f5' : '#f2f2f2',
                color: 'black',
                // fontWeight: '550'
            }),
        },


    ];


    const handleRowCheckClick = (row) => {
        data?.results.map((item, index) => {
            if (row?.id == item.id) {
                setColorRow(row)
            }
        })
        // // console.log(row);
    };
    const handleUserReport = (id) => {

    }

    const handleResetDataTable = () => {
        setRerenderDataTable(true)
        setTimeout(() => {
            setRerenderDataTable(false)
        }, 200);
    }
    return (
        <React.Fragment>
            <Sidebar />
            <main id="main" className="main">
                <ToastContainer className="p-3" position={"top-end"}>
                    <Toast bg="success" show={toaster === 'success' ? true : false} animation={true} delay={2000} autohide>

                        <Toast.Body ><span className='text-white'>Success</span></Toast.Body>
                    </Toast>
                    <Toast bg="danger" show={toaster === 'error' ? true : false} animation={true} delay={2000} autohide>

                        <Toast.Body ><span className='text-white'>Failed</span></Toast.Body>
                    </Toast>
                </ToastContainer>
                <div className="pagetitle tab-fixed-header">
                    <h1 id='page-title'>Users</h1>
                </div>

                <section className="section mt-5" >
                    <div className="card mb-5 mb-xl-8">
                        <div className="card-header border-0 px-4">
                            <div className="d-flex align-items-center position-relative my-1">
                                <FilterComponent placeholder="Search" onFilter={(e) => searchUsers(e)} onClear={handleClear} filterText={filterText} />

                            </div>
                            {isHavePermission("create_users") &&

                                <div className="card-toolbar" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-trigger="hover" data-bs-original-title="Click to add a user" data-kt-initialized="1">
                                    <Link to='/create-user' className="btn btn-light btn-active-primary">
                                        <span className="svg-icon svg-icon-3">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1" transform="rotate(-90 11.364 20.364)" fill="currentColor"></rect>
                                                <rect x="4.36396" y="11.364" width="16" height="2" rx="1" fill="currentColor"></rect>
                                            </svg>
                                        </span>
                                        Create</Link>
                                </div>
                            }
                        </div>
                        <div className="card-body py-3">
                            <div className="table-responsive">
                                <div className='dashboardtable'>
                                    {!reRenderDataTable &&
                                        <DataTable
                                            className="custom-datatable"
                                            data={data?.results}
                                            columns={columns}
                                            progressPending={loading}
                                            pagination
                                            paginationServer
                                            paginationTotalRows={totalRows}
                                            paginationPerPage={limit}
                                            fixedHeader={true}
                                            // paginationRowsPerPageOptions={[25, 50, 100, 150, 200]}
                                            onChangeRowsPerPage={handlePerRowsChange}
                                            onChangePage={handlePageChange}
                                            conditionalRowStyles={conditionalRowStyles}
                                            onRowClicked={handleRowClick}
                                            striped={true}
                                            theme="solarized"
                                            customStyles={datatableCustomStyles}

                                        />
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Modal
                    isOpen={modalIsOpen}
                    ariaHideApp={false}
                    onRequestClose={() => { closeModal() }}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <ChangePasswordForm closeModal={closeModal} __passwordToast={__passwordToast} UserId={userId} />

                </Modal>
            </main>
        </React.Fragment>
    )
}

export default ListUsers