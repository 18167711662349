

import React from 'react'
import Chart from 'react-apexcharts';
import CircleSkelitonLoader from '../../loader/CircleSkelitonLoader';

const AllReport = ({ isVisible, isNodataShow, data, handleClickData }) => {
    const options = {
        dataLabels: {
            enabled: true,
            style: {
                colors: ['black']
            },
            dropShadow: {
                enabled: false,
                enabledOnSeries: undefined,
                top: 0,
                left: 0,
                blur: 3,
                opacity: 0.35
            }
        },
        fill: {
            type: 'gradient',
        },
        chart: {
            id: 'basic-donut',
            type: 'donut',
            toolbar: {
                show: false
            },
            events: {
                dataPointSelection: handleClickData
            },
            fontFamily: 'Open Sans',

        },

        legend: {
            show: true,
            customLegendItems: data?.map(item => item?.client_name),
            offsetX: 0,
            offsetY: 100,
        },
        tooltip: {
            show: true,
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                const color = w.config.colors[seriesIndex];
                return `<div style="background-color:${color};padding:5px" class="">
                        <span style="color:${"#000"};"> ${w.globals.labels[seriesIndex]}:${series[seriesIndex]}/${data[seriesIndex].total_job_count}  </span>
                </div>`

            }
        },
        labels: data?.map(item => item?.client_name),
        noData: {
            text: 'No data available',
        },
        yaxix: {
            title: 'Claims'
        },
        // colors: ['#ad6baf', '#75ba7a', '#61d6f3', '#c7d07b', '#ffb981', '#00a1a8', '#166783', '#c7d07b', '#df8483', '#ffb237'],
        colors: ['#b2d3a3', '#9cadda', '#ffd993', '#c7d07b', '#f6b89d', '#abc7e7', '#969696', '#24B2DD', '#FFB11F', '#D98383'],


    };

    const series = data?.map(item => item?.total_claims)
    console.log(data?.map(item => item?.client_name))
    return (
        isVisible ?
            <CircleSkelitonLoader /> :
            <Chart options={options} series={series?.filter(i => i != 0)} type="donut" height={'90%'} />

    )
}

export default AllReport
