import React, { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import InputMask from "react-input-mask";
import { handleKeyPress, handleWheel } from "../gridFunctions";

export const DbkField = ({
  formik,
  type,
  index,
  fieldName,
  fieldNameDbk,
  fieldType,
  setCorresPondanceFocus,
  corresPondanceFocus,
}) => {
  const [isDbkValid, setIsDbkValid] = useState(false);
  const [inputFocus, setInputFocus] = useState(false);

  const [isNormalTextSelected, setIsNormalTextSelected] = useState(false);
  const [isDbkTextSelected, setIsDbkTextSelected] = useState(false);

  const inputRef = useRef(null);
  const handlePaste = (e) => {
    e.preventDefault();
  };

  const handleCopy = (e) => {
    e.preventDefault();
  };
  useEffect(() => {

    if (fieldType == "claims") {
      setPasswordToText(`claims[${index}].${fieldName}`, `claims[${index}].${fieldNameDbk}`)

      // if (
      //   formik?.values?.claims[index][fieldName] &&
      //   formik?.values?.claims[index][fieldNameDbk] &&
      //   formik?.values?.claims[index][fieldName] ==
      //   formik?.values?.claims[index][fieldNameDbk]
      // ) {
      //   type == "number" && inputRef.current.blur();
      //   setIsDbkValid(true);
      // } else {
      //   setIsDbkValid(false);
      // }
    }
  }, [
    formik?.values?.claims?.[index]?.[fieldNameDbk],
    formik?.values?.claims?.[index]?.[fieldName],
  ]);

  function isAllTextSelectedByName(name) {
    let isValue = false
    if (type !== "date") {
      if (fieldType == "claims") {
        name = `claims[${index}].${name}`
      }
      const inputElement = document.querySelector(`input[name="${name}"]`);
      isValue = inputElement && inputElement.selectionStart === 0 && inputElement.selectionEnd === inputElement.value.length;
    }
    return isValue
    // setIsDbkTextSelected(inputElement && inputElement.selectionStart === 0 && inputElement.selectionEnd === inputElement.value.length)
  }
  function setPasswordToText(normal, dbk) {
    const inputElement = document.querySelector(`input[name="${dbk}"]`);
    const inputElement1 = document.querySelector(`input[name="${normal}"]`);
    if (type !== "date") {

      if (inputElement && inputElement.value == '' || isDbkTextSelected) {
        inputElement.type = type;
      }
      if (inputElement1 && inputElement1.value == '') {
        inputElement1.type = type;
      }
      if (inputElement && inputElement1 && inputElement.value.toUpperCase() == inputElement1.value.toUpperCase()) {
        inputElement1.type = type;


      }
    } else {

      if (inputElement && inputElement.value.replace("__/__/____", "") == '' || isDbkTextSelected) {
        inputElement.type = 'text';
      } else if (document.activeElement == inputElement) {
        inputElement.type = 'text';

      }
      if (inputElement1 && inputElement1.value == '') {
        inputElement1.type = 'text';
      }
      if (inputElement && inputElement1 && inputElement.value.replace("__/__/____", "") == inputElement1.value.replace("__/__/____", "")) {
        inputElement1.type = 'text';

      } else if (document.activeElement == inputElement1) {
        inputElement1.type = 'text';

      }

    }
  }
  useEffect(() => {


    if (fieldType == "main") {
      setPasswordToText(fieldName, fieldNameDbk)
      if (
        formik?.values?.[fieldName] &&
        formik?.values?.[fieldNameDbk] &&
        formik?.values?.[fieldName] == formik?.values?.[fieldNameDbk]
      ) {
        type == "number" && inputRef.current.blur();
        setIsDbkValid(true);
      } else {
        setIsDbkValid(false);
      }
    }
  }, [formik?.values?.[fieldName], formik?.values?.[fieldNameDbk]]);

  const handleInputFocus = (event) => {
    const inputElement1 = document.querySelector(`input[name="${fieldName}"]`);

    const parentElement = event.target.closest('.dbk-main-container');
    // const passwordInputs = parentElement.querySelectorAll('input[type="text"]');
    const passwordInputs = parentElement.querySelectorAll('input');


    setIsDbkTextSelected(isAllTextSelectedByName(fieldNameDbk))
    // setIsNormalTextSelected(isAllTextSelectedByName(fieldName))
    if (type == 'date') {
      passwordInputs.forEach(input => {
        if (input.value.replace("__/__/____", "") == '') {
          input.type = 'text';
        } else {
          input.type = 'password';
        }
      });

    } else {

      passwordInputs.forEach(input => {
        // if (input.name === event.target.name) {
        //   input.type = 'text';
        // } else {
        input.type = 'password';
        // }
      });
    }
  }

  const handleInputFocusOut = (event) => {
    setIsDbkTextSelected(false)
    // setIsNormalTextSelected(isAllTextSelectedByName(fieldName))

    const parentElement = event.target.closest('.dbk-main-container');
    // const passwordInputs = parentElement.querySelectorAll('input[type="text"]');
    const passwordInputs = parentElement.querySelectorAll('input');


    // if(event.target.value)
    if (type == 'date') {
      passwordInputs.forEach(input => {
        if (input.name === event.target.name) {
          input.type = 'password';
        } else {
          // input.type = 'text';
        }
      });
    } else {
      passwordInputs.forEach(input => {
        if (input.name === event.target.name) {
          input.type = 'password';
        } else {
          // input.type = 'text';
        }
      });
    }
  }

  const handleKeyDown = (event) => {
    setIsDbkTextSelected(isAllTextSelectedByName(fieldNameDbk))
    // setIsNormalTextSelected(isAllTextSelectedByName(fieldName))

    if (event.key == "Tab" && isDbkValid == false) {
      const form = event.target.form;
      var index = Array.prototype.indexOf.call(form, event.target);

      form.elements[index - 1].focus();
      event.preventDefault();
    } else if (isDbkValid == true) {
      // setCorresPondanceFocus(prevState => ({
      //     ...Object.fromEntries(
      //         Object.keys(prevState).map(key => [key, false])
      //     ),
      //     // [fieldName]: true
      // }));
    }
  };

  const handleAmountFloat = (event) => {
    let value = parseFloat(event.target.value).toFixed(2);
    // event.target.value = value;
    // console.log("########", value);
    // formik.handleChange(event);
    // console.log(event.target.name, "handleAmountFloathandleAmountFloat");
    if (fieldType == "claims") {
      formik.setValues((prevState) => ({
        ...prevState,
        claims: prevState.claims.map((claim, ind) => {
          if (ind === index) {
            return {
              ...claim,
              [fieldNameDbk]: event.target.value ? parseFloat(event.target.value).toFixed(2) : "",
            };
          }
          return claim;
        }),
      }));
    } else {

      formik.setValues((prevState) => ({
        ...prevState,
        [event.target.name]: event.target.value ? String(parseFloat(event.target.value).toFixed(2)) : "",
      }));
    }
  };
  // console.log(formik.values?.[fieldNameDbk]);
  return type === "text" ? (
    <div className="confirm-wrapper">
      {fieldType == "claims" ? (
        <Form.Control
          onPaste={handlePaste}
          onCopy={handleCopy}
          className={
            formik.errors?.claims &&
              formik.errors?.claims[index] &&
              formik.errors?.claims[index][fieldName]
              ? "w-fsname error-field"
              : "w-fsname"
          }
          type="password"
          onChange={formik.handleChange}
          value={formik.values?.claims[index][fieldNameDbk]}
          name={`claims[${index}].${fieldNameDbk}`}
          // onFocus={() => setCorresPondanceFocus({ ...corresPondanceFocus, [fieldNameDbk]: true, [fieldName]: true })}
          // onBlur={() => setCorresPondanceFocus({ ...corresPondanceFocus, [fieldNameDbk]: false, [fieldName]: true })}
          ref={inputRef}
          // autoFocus={corresPondanceFocus[fieldNameDbk]}
          onKeyDown={(e) => {
            handleKeyDown(e);
          }}
          data-dbk={true}
          onFocus={(e) => { handleInputFocus(e) }}
          onBlur={(e) => { handleInputFocusOut(e) }}
        />
      ) : (
        <Form.Control
          onPaste={handlePaste}
          onCopy={handleCopy}
          type="password"
          onChange={formik.handleChange}
          className={
            formik.errors?.[fieldNameDbk] ? "w-78 error-field" : "w-78"
          }
          value={formik.values?.[fieldNameDbk]}
          name={fieldNameDbk}
          ref={inputRef}
          isDbkField={true}
          onKeyDown={(e) => {
            handleKeyDown(e);
          }}
          onFocus={(e) => { handleInputFocus(e) }}
          onBlur={(e) => { handleInputFocusOut(e) }}
        />
      )}
      <span className="confirm-arrow-down"></span>
    </div>
  ) : type === "number" ? (
    <div className="confirm-wrapper">
      {fieldType == "claims" ? (
        <Form.Control
          onPaste={handlePaste}
          onCopy={handleCopy}
          className={
            formik.errors?.claims &&
              formik.errors?.claims[index] &&
              formik.errors?.claims[index][fieldName]
              ? "w-fsname error-field text-end"
              : "w-fsname text-end"
          }
          type="password"
          // onChange={(e) => {
          //   formik.handleChange(e);
          // }}
          onChange={(event) => {
            if (event.target.value.includes(".") && event.target.value.split(".")[1].length > 2) {
              return;
            }
            formik.setValues((prevState) => ({
              ...prevState,
              claims: prevState.claims.map((claim, ind) => {
                if (ind === index) {
                  return {
                    ...claim,
                    [fieldNameDbk]: event.target.value,
                  };
                }
                return claim;
              }),
            }));
          }}
          onBlur={(e) => {
            handleAmountFloat(e);
            handleInputFocusOut(e);
          }}
          value={formik.values?.claims[index][fieldNameDbk]}

          name={`claims[${index}].${fieldNameDbk}`}
          onFocus={(e) => {
            setCorresPondanceFocus({
              ...corresPondanceFocus,
              [fieldNameDbk]: true,
              [fieldName]: true,
            });
            handleInputFocus(e)
          }
          }
          onWheel={handleWheel}
          onKeyUp={handleKeyPress}
          // onBlur={() => setCorresPondanceFocus({ ...corresPondanceFocus, [fieldNameDbk]: false, [fieldName]: true })}
          ref={inputRef}
          // autoFocus={corresPondanceFocus[fieldNameDbk]}
          onKeyDown={(e) => {
            handleKeyDown(e);
          }}
          data-dbk={true}
        />
      ) : (
        <Form.Control
          onPaste={handlePaste}
          onCopy={handleCopy}
          type="password"
          onChange={(event) => {
            if (event.target.value.includes(".") && event.target.value.split(".")[1].length > 2) {
              return;
            }
            formik.setValues((prevState) => ({
              ...prevState,
              [event.target.name]: event.target.value,
            }));
          }}

          onBlur={(e) => {
            handleAmountFloat(e);
            handleInputFocusOut(e)

          }}
          className={
            formik.errors?.[fieldNameDbk] ? "w-78 error-field form-control" : "w-78 text-end"
          }
          value={formik.values?.[fieldNameDbk]}
          name={fieldNameDbk}
          ref={inputRef}
          isDbkField={true}
          onWheel={handleWheel}
          onKeyUp={handleKeyPress}
          onKeyDown={(e) => {
            handleKeyDown(e);
          }}
          onFocus={(e) => { handleInputFocus(e) }}
        />
      )}
      <span className="confirm-arrow-down"></span>
    </div>
  ) : type === "date" ? (
    <div className="confirm-wrapper">
      {fieldType == "claims" ? (
        <InputMask
          onPaste={handlePaste}
          onCopy={handleCopy}
          ref={inputRef}
          mask="99/99/9999"
          className={
            formik.errors?.claims &&
              formik.errors?.claims[index] &&
              formik.errors?.claims[index][fieldNameDbk]
              ? "patient-date dbk-date error-field"
              : "patient-date dbk-date"
          }
          onKeyDown={(e) => {
            handleKeyDown(e);
          }}
          onChange={formik.handleChange}
          value={formik.values?.claims[index][fieldNameDbk]}
          name={`claims[${index}].${fieldNameDbk}`}
          placeholder={"mm/dd/yyyy"}
          onFocus={(e) => { handleInputFocus(e) }}
          onBlur={(e) => { handleInputFocusOut(e) }}
        />
      ) : (
        <InputMask
          onPaste={handlePaste}
          onCopy={handleCopy}
          mask="99/99/9999"
          className={
            formik.errors[fieldNameDbk]
              ? "dbk-date w-48 error-field"
              : "dbk-date w-48"
          }
          onChange={formik.handleChange}
          onKeyDown={(e) => {
            handleKeyDown(e);
          }}
          value={formik.values[fieldNameDbk]}
          name={fieldNameDbk}
          placeholder={"mm/dd/yyyy"}
          ref={inputRef}
          // type={formik.values[fieldNameDbk]=='__/__/____'?'text'}
          type='password'
          onFocus={(e) => { handleInputFocus(e) }}
          onBlur={(e) => { handleInputFocusOut(e) }}
        />
      )}
      <span className="confirm-arrow-down"></span>
    </div>
  ) : null;
};
