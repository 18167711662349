import request from "../../api/agent";
import bowser from 'bowser';

export class FireBaseService {

  async registerDevice(currentToken) {
    const browser = bowser.getParser(navigator.userAgent);
    const data = {
        "firebase_token": currentToken,
        "unique_device": "IO7psJZPyVTnq9CoObKIgePeWXY2",
        "browser_details": Object.entries(browser.parsedResult)
    }

    const response = await request.post(`notification/device-registration`, data);
    return response.data??response?.response?.data;
  }

}