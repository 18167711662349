import React from 'react'
import Sidebar from '../includes/Sidebar'
import DataListTable from '../components/DataListTable'

function DataList() {
  return (
    <React.Fragment>
      <Sidebar />
      <DataListTable />
    </React.Fragment>
  )
}

export default DataList