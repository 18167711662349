import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";

import { useMemo, useCallback } from "react";
import { Button, Form } from "react-bootstrap";
import moment from "moment";
import { useFormik } from "formik";
import { DateRangePicker } from "rsuite";
import { BiSearch } from "react-icons/bi";
import { Stack } from 'rsuite';
import subDays from 'date-fns/subDays';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import addDays from 'date-fns/addDays';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import addMonths from 'date-fns/addMonths';

import Sidebar from "../../includes/Sidebar";
import { PayerReportService } from "../../../services/PayerReportService";
import PayerReportDetails from "./PayerReportDetails";
import { BsCloudDownloadFill } from "react-icons/bs";
import { downloadXl } from "../../../functions";
import { useDispatch, useSelector } from "react-redux";
import { selectIsSuperUser, selectPermissions } from "../../../slice/user/userSlice";
import { Tooltip } from "@mui/material";
import { seErrorMessage } from "../../../slice/error/errorSlice";
import AsyncSelect from 'react-select/async';
import { InvoiceReportService } from "../../../services/InvoiceReportService";
import { useRef } from "react";
import Select, { components } from 'react-select';
import { TbGridDots } from "react-icons/tb";
import { HiChevronDown } from "react-icons/hi";


const datatableCustomStyles = {
  rows: {
    style: {
      minHeight: '20px', // override the row height
      fontSize: '0.7em',
      fontColor: '#212529 !important',
      width: '100%'
    },
  },
  headCells: {
    style: {
      paddingLeft: '5px', // override the cell padding for head cells
      paddingRight: '5px',
      textTransform: "uppercase",
      fontSize: '0.7em',
      fontWeight: '800',
      fontColor: '#6c757d !important',
      opacity: 1,
      minHeight: "10px",
    },
  },
  cells: {
    style: {
      paddingLeft: '8px', // override the cell padding for data cells
      paddingRight: '8px',
      width: "100%",
    },
  },
  pagination: {
    style: {
      color: '#000',
      fontSize: '13px',
      minHeight: '56px',
      backgroundColor: '#fff',
      borderTopStyle: 'solid',
      borderTopWidth: '1px',
      borderTopColor: '#f2f2f2',
    },
    pageButtonsStyle: {
      borderRadius: '50%',
      height: '40px',
      width: '40px',
      padding: '8px',
      margin: '0px',
      cursor: 'pointer',
      transition: '0.4s',
      color: '#000',
      fill: '#000',
      backgroundColor: 'transparent',
      '&:disabled': {
        cursor: 'unset',
        color: '#e2e2e2',
        fill: '#e2e2e2',
      },
      '&:hover:not(:disabled)': {
        backgroundColor: '#f2f2f2',
      },
      '&:focus': {
        outline: 'none',
        backgroundColor: '#f5f5f5',
      },
    },
  },
};

function PayerReport() {
  const dispatch = useDispatch()
  const facilityRef = useRef(null)
  const clientRef = useRef(null)

  const [reRenderDataTable, setRerenderDataTable] = useState(false)
  const [pageno, setPageNo] = useState(1);
  const [loading, setLoading] = useState(false);
  const [isTimeCount, setisTimeCount] = useState(false);
  const [totalRows, setTotalRows] = useState(100);
  const [colorRow, setColorRow] = React.useState({});
  const [data, setData] = useState({})
  const [limit, setLimit] = useState(100)
  const [searchedData, setSearchedData] = useState({
    search: "",
    date_range: `${moment(startOfMonth(new Date())).format('MM/DD/YYYY')} - ${moment(new Date()).format('MM/DD/YYYY')}`,
    hour_count: "",
    payer_list: [],
    clients: [],
    facilities: [],

  })
  const [isFocusedClient, setIsFocusedClient] = useState(false);

  const [clients, setClients] = useState([]);

  const [isFocusedFacility, setIsFocusedFacility] = useState(false);

  const [selectValue, setSelectValue] = useState([])

  const [facilities, setFacilities] = useState([])

  const [selectIds, setSelectIds] = useState("")

  const [selectedFacility, setSelectedFacility] = useState([])

  const [selectedFacilityIds, setSelectedFacilityIds] = useState("")

  const invoiceReportService = new InvoiceReportService();

  const { getFacilityDropdown, getAllClientDropdown } = invoiceReportService
  const payerReportService = new PayerReportService();
  const { getPayerReportSummery, downloadPayerReportSummery, getPayerDropdown } = payerReportService
  const permissions = useSelector(selectPermissions);
  const isSuperUser = useSelector(selectIsSuperUser);
  const isHavePermission = (name) => {
    return permissions?.includes(name) || isSuperUser
  }

  const predefinedBottomRanges = [
    {
      label: 'Today',
      value: [new Date(), new Date()],
      placement: 'left'
    },
    {
      label: 'Yesterday',
      value: [addDays(new Date(), -1), addDays(new Date(), -1)],
      placement: 'left'
    },
    {
      label: 'This week',
      value: [startOfWeek(new Date()), endOfWeek(new Date())],
      placement: 'left'
    },
    {
      label: 'Last 7 days',
      value: [subDays(new Date(), 6), new Date()],
      placement: 'left'
    },
    {
      label: 'Last 30 days',
      value: [subDays(new Date(), 29), new Date()],
      placement: 'left'
    },
    {
      label: 'This month',
      value: [startOfMonth(new Date()), new Date()],
      placement: 'left'
    },
    {
      label: 'Last month',
      value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
      placement: 'left'
    },
    {
      label: 'This year',
      value: [new Date(new Date().getFullYear(), 0, 1), new Date()],
      placement: 'left'
    },
    {
      label: 'Last year',
      value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date(new Date().getFullYear(), 0, 0)],
      placement: 'left'
    },

    {
      label: 'Last week',
      closeOverlay: false,
      value: value => {
        const [start = new Date()] = value || [];
        return [
          addDays(startOfWeek(start, { weekStartsOn: 0 }), -7),
          addDays(endOfWeek(start, { weekStartsOn: 0 }), -7)
        ];
      },
      appearance: 'default'
    },
    {
      label: 'Next week',
      closeOverlay: false,
      value: value => {
        const [start = new Date()] = value || [];
        return [
          addDays(startOfWeek(start, { weekStartsOn: 0 }), 7),
          addDays(endOfWeek(start, { weekStartsOn: 0 }), 7)
        ];
      },
      appearance: 'default'
    }
  ];
  useEffect(() => {
    fetchData(1, limit, searchedData?.search, searchedData?.station, searchedData?.date_range, searchedData?.hour_count, '', searchedData?.clients ?? "", searchedData?.facilities ?? "");
    getAllClientDropdown().then((response) => {
      if (response?.status) {
        const data = response?.data
        getFacilityDropdown(data.map((i) => i.value)).then((response) => {
          if (response && response.status) {
            const feciltyIdArr = []
            const FullFacilities = []
            setFacilities(response.data.facilities?.map(result => {
              result?.facilities?.map(item => feciltyIdArr.push(item.value));
              FullFacilities.push({ options: result?.facilities, label: result?.client, id: result?.pk })
              return { options: result?.facilities, label: result?.client, id: result?.pk }

            }))

            setFullFacilities(FullFacilities)
            const newArray = selectedFacility.filter(item => feciltyIdArr.includes(item.value));
            setSelectedFacility(newArray)
          }
        });

        setClients(data)
      }
    })
  }, [])

  // useEffect(() => {
  //   if (selectValue && selectValue.length > 0) {

  //     getFacilityDropdown(selectValue?.map((value) => value?.value)).then((response) => {
  //       if (response && response.status) {
  //         const feciltyIdArr = []
  //         setFacilities(response.data.facilities?.map(result => {
  //           console.log(result?.facilities, 'EEEEE')
  //           result?.facilities?.map(item => feciltyIdArr.push(item.value));
  //           return { options: result?.facilities, label: result?.client }

  //         }))
  //         const newArray = selectedFacility.filter(item => feciltyIdArr.includes(item.value));
  //         setSelectedFacility(newArray)
  //         searchFormik.setValues({
  //           ...searchFormik.values, facilities: newArray.length > 0 ? newArray.map(i => i.value) : []
  //         })
  //       }
  //     });

  //   }

  // }, [selectValue])
  const setFacilityOptions = (clientId) => {
    getFacilityDropdown(clientId).then((response) => {
      if (response && response.status) {
        setFacilities(response.data.facilities?.map(result => {
          return { options: result?.facilities, label: result?.client }

        }))

      }
    });
  }
  const [fullFacilities, setFullFacilities] = useState([])

  const checkSelectedFecilityInitialClient = (selectedOptions) => {
    // Initialize an empty result array and a set to track unique labels
    var result = [...selectValue];
    var uniqueLabels = new Set();
    const clientId = selectValue?.map((value) => String(value?.value))

    // Loop through selected options
    for (var i = 0; i < selectedOptions.length; i++) {
      var selectedOption = selectedOptions[i];

      // Loop through the list of dictionaries to find matching options
      for (var j = 0; j < fullFacilities.length; j++) {
        var options = fullFacilities[j].options;
        for (var k = 0; k < options.length; k++) {
          if (options[k].value === selectedOption.value && options[k].label === selectedOption.label) {
            // Check if the label is unique before adding it to the result
            console.log(clientId, fullFacilities[j].id)
            if (!uniqueLabels.has(fullFacilities[j].label) && !clientId.includes(String(fullFacilities[j].id))) {
              result.push({
                "value": Number(fullFacilities[j].id),
                "label": fullFacilities[j].label,
              });
              uniqueLabels.add(fullFacilities[j].label);
            }
            break;
          }
        }
      }
    }
    console.log("Result:", result);
    return result
  }
  // const handleSelectChange = (val, name = null) => {
  //   console.log(val)
  //   let selectedOptions = [];
  //   let selectedIds = [];

  //   if (val && val?.length > 0) {

  //     val?.map((option) => {
  //       selectedIds.push(option.value)
  //       selectedOptions.push(option)
  //     })

  //   } else {

  //     setSelectedFacility([])
  //     setSelectedFacilityIds([])
  //   }

  //   if (name == "facility") {
  //     const initiaClientsOptions = checkSelectedFecilityInitialClient(selectedOptions)
  //     // const clientIds = initiaClientsOptions?.map((value) => value?.value)
  //     setSelectedFacility(selectedOptions)
  //     setSelectedFacilityIds(selectedIds)
  //     setSelectValue(initiaClientsOptions)
  //     // setFacilityOptions(clientIds)
  //   } else {
  //     // setClients(val.includes('all') ? ['all'] : selectedId)
  //     if (!(val && val?.length > 0)) {
  //       if (clients && clients.length > 0) {
  //         const clientId = clients?.map((value) => value?.value)
  //         setFacilityOptions(clientId)
  //       }

  //     } else {
  //       getFacilityDropdown(selectedIds).then((response) => {
  //         if (response && response.status) {
  //           const feciltyIdArr = []
  //           setFacilities(response.data.facilities?.map(result => {
  //             console.log(result?.facilities, 'EEEEE')
  //             result?.facilities?.map(item => feciltyIdArr.push(item.value));
  //             return { options: result?.facilities, label: result?.client }

  //           }))

  //           const newArray = selectedFacility.filter(item => feciltyIdArr.includes(item.value));
  //           setSelectedFacility(newArray)
  //         }
  //       });

  //     }

  //     setSelectValue(selectedOptions)
  //     setSelectIds(selectedIds)
  //     // setSelectedFacility([])
  //   }
  // };
  const handleSelectChange = (val, name = null) => {
    let selectedOptions = [];
    let selectedIds = [];
    if (val && val?.length > 0) {

      val?.map((option) => {
        selectedIds.push(option.value)
        selectedOptions.push(option)
      })
    }
    if (name == 'facility') {
      setSelectedFacility(selectedOptions)
      setSelectedFacilityIds(selectedIds)
    }
    else if (name == 'client') {
      setSelectedFacility([])
      setFacilities([])
      setSelectValue(selectedOptions)
      setSelectIds(selectedIds)
      const feciltyIdArr = []
      console.log(clients)
      let clientoptions = []
      clients?.map(client => {
          clientoptions.push(client?.value)
      })
      console.log('selectedIds',selectedIds)
      getFacilityDropdown(selectedIds?.length ? selectedIds : clientoptions).then((response) => {
        if (response && response.status) {
          setFacilities(response.data.facilities?.map(result => {
            result?.facilities?.map(item => feciltyIdArr.push(item.value));
            return { options: result?.facilities, label: result?.client }

          }))
          const newArray = selectedFacility.filter(item => feciltyIdArr.includes(item.value));
          setSelectedFacility(selectedIds?.length ? newArray : [])
        }
      });
    }
  }
  const handlePerRowsChange = async (newPerPage, page) => {

    setLimit(newPerPage);

    if (pageno == 1) {
      fetchData(1, newPerPage, searchedData?.search, searchedData?.station, searchedData?.date_range, searchFormik?.values?.hour_count, searchedData?.payer_list, searchedData?.clients ?? "", searchedData?.facilities ?? "");
    }
    else {
      setPageNo(1)
    }
    // fetchData(1, newPerPage, searchedData?.search, searchedData?.station, searchedData?.date_range, searchFormik?.values?.hour_count);
    // handleResetDataTable()

  };
  const handlePageChange = page => {
    setPageNo(page)

    fetchData(page, limit, searchedData?.search, searchedData?.station, searchedData?.date_range, searchedData?.hour_count, searchedData?.payer_list, searchedData?.clients ?? "", searchedData?.facilities ?? "");
  };


  const fetchData = (page, limit, station = '', job_status = '', date_range, hour_count, payer_list, clients, facilities) => {
    setLoading(true)
    setisTimeCount(hour_count == 'true' || hour_count == '' ? false : true)
    getPayerReportSummery(page, limit, station, job_status, date_range, hour_count, payer_list, clients, facilities).then((response) => {
      setData(response?.data);
      // setLimit(limit);
      // setPageNo(page)
      setTotalRows(response?.data?.count);
      setLoading(false);
      if (response?.errors?.date_range) {
        dispatch(seErrorMessage({ message: response?.errors?.date_range[0], is_show: true }))
      }
    });

  }

  const handleRowClickCheck = (row) => {
    data?.results.map((item, index) => {
      if (row?.id == item.id) {
        setColorRow(row)
      }
    })
  }
  const [expandedRows, setExpandedRows] = useState([]);
  const ExpandedComponent = useCallback(
    ({ data }) => (
      <pre>
        {expandedRows?.length > 0 && (
          <PayerReportDetails report_page={1}
            report_limit={limit}
            report_station={""}
            report_job_status={""}
            report_date_range={searchedData?.date_range ?? ""}
            report_hour_count={searchedData?.hour_count}
            clients={searchedData?.clients}
            facilities={searchedData?.facilities}
            client_id={data?.client_id}
            facility_id={data?.facility_id}
            user={data.payer_id} />
        )}
      </pre>
    ),
    [expandedRows, limit, searchedData]
  );
  const handleRowExpand = useCallback((row) => {
    setColorRow(row);
    const isRowExpanded = expandedRows.includes(row);
    setExpandedRows(isRowExpanded ? expandedRows.filter((r) => r !== row) : [row]);
  });

  const searchFormik = useFormik({
    initialValues: {
      search: "",
      job_status: "",
      station: "",
      date_range: [startOfMonth(new Date()), new Date()],
      created_date: `${moment(startOfMonth(new Date())).format('MM/DD/YYYY')} - ${moment(new Date()).format('MM/DD/YYYY')}`,
      hour_count: "true",
      payer_list: [],
      clients: [],
      facilities: ""

    },
    onSubmit: (values) => {
      const client_ids = selectValue?.map(i => i.value)
      const facility_ids = selectedFacility?.map(i => i.value)
      values.clients = client_ids
      values.facilities = facility_ids
      setSearchedData({
        search: values?.search ? values?.search : "",
        job_status: values?.job_status ? values?.job_status : "",
        station: values?.station ? values?.station : "",
        date_range: values?.created_date ? values?.created_date : "",
        hour_count: values?.hour_count ? values?.hour_count : "",
        payer_list: values.payer_list,
        clients: values?.clients ?? "",
        facilities: values?.facilities ?? "",
      })
      searchData(values)
      // handleResetDataTable()
    }
  })

  const columns = useMemo(
    () => [
      {
        id: 'S.NO',
        name: 'S.NO',
        selector: (row, index) => pageno == 1 ? index + 1 : (pageno - 1) * limit + index + 1,
        wrap: true,
        width: "40px",
        grow: 0.1,

      },
      {
        id: 'PAYER NAME',
        name: 'PAYER NAME',
        selector: row => row?.payer_name,
        cell: (row) =>
          <div onClick={() => { handleRowExpand(row) }} style={row?.payer_name ? { height: 'auto' } : { height: '100%' }}>


            <div className='d-flex flex-row justify-content-between align-items-center gap-2' onClick={() => handleRowExpand(row)} style={{ maxWidth: '120px' }}>
              <div className='overflow-hidden text-truncate' style={{ maxWidth: "120px" }}>
                <Tooltip disableInteractive title={row?.payer_name} placement="top" arrow>
                  <span className="text-truncate" onClick={() => handleRowExpand(row)}> {row?.payer_name ? row?.payer_name : row?.payer_name}</span>
                </Tooltip>
              </div>
            </div>
          </div>
        ,


        left: true,
        wrap: true,
        reorder: false,
        grow: 0.1,
        expandOnRowClicked: true,
        minWidth: '120px'


      },
      {
        id: 'LAST USED',
        name: 'LAST USED',
        selector: row => row?.last_pulled_date,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.last_pulled_date}</span>,

        left: true,
        wrap: true,
        reorder: false,
        minWidth: '50px'

        // grow: 0.1,
      },
      {
        id: 'CLIENT NAME',
        name: 'CLIENT NAME',
        selector: row => row?.client_name,
        cell: (row) =>
          <div onClick={() => { handleRowExpand(row) }} style={row?.client_name ? { height: 'auto' } : { height: '100%' }}>


            <div className='d-flex flex-row justify-content-between align-items-center gap-2' onClick={() => handleRowExpand(row)} style={{ width: '170px' }}>
              <div className='overflow-hidden text-truncate' style={{ width: "120px" }}>
                <Tooltip disableInteractive title={row?.client_name} placement="top" arrow>
                  <span className="text-truncate" onClick={() => handleRowExpand(row)}> {row?.client_name ? row?.client_name : row?.client_name}</span>
                </Tooltip>
              </div>
            </div>
          </div>
        ,


        left: true,
        wrap: true,
        reorder: false,
        // grow: 0.1,
        expandOnRowClicked: true,
        minWidth: '120px'


      },
      {
        id: 'FACILITY NAME',
        name: 'FACILITY NAME',
        selector: row => row?.client_name,
        cell: (row) =>
          <div onClick={() => { handleRowExpand(row) }} style={row?.facility_name ? { height: 'auto' } : { height: '100%' }}>


            <div className='d-flex flex-row justify-content-between align-items-center gap-2' onClick={() => handleRowExpand(row)} style={{ width: '170px' }}>
              <div className='overflow-hidden text-truncate' style={{ width: "120px" }}>
                <Tooltip disableInteractive title={row?.facility_name} placement="top" arrow>
                  <span className="text-truncate" onClick={() => handleRowExpand(row)}> {row?.facility_name ? row?.facility_name : row?.client_name}</span>
                </Tooltip>
              </div>
            </div>
          </div>
        ,


        left: true,
        wrap: true,
        reorder: false,
        // grow: 0.1,
        expandOnRowClicked: true,
        minWidth: '120px'


      },
      {
        id: 'PAYMENT835',
        name: 'PAYMENT835',
        selector: row => row?.no_of_835,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.no_of_835}</span>,

        right: true,
        wrap: true,
        reorder: false,
        // grow: 0.1,
        minWidth: '50px'

      },
      {
        id: 'PAYMENT LITE',
        name: 'PAYMENT LITE',
        selector: row => row?.no_835_lite,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.no_835_lite}</span>,

        right: true,
        wrap: true,
        reorder: false,
        // grow: 0.1,
        minWidth: '100px'

      },
      {
        id: 'CORRES',
        name: 'CORRES',
        selector: row => row?.correspondence,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.correspondence}</span>,

        right: true,
        wrap: true,
        reorder: false,
        // grow: 0.1,
        minWidth: '50px'

      },
      {
        id: 'PAT PAY',
        name: 'PAT PAY',
        selector: row => row?.patient_pay,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.patient_pay}</span>,

        right: true,
        wrap: true,
        reorder: false,
        // grow: 0.1,
        minWidth: '50px'

      },
      {
        id: 'PAT PAY 835',
        name: 'PAT PAY 835',
        selector: row => row?.patient_pay_835,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.patient_pay_835}</span>,

        right: true,
        wrap: true,
        reorder: false,
        // grow: 0.1,
        minWidth: '50px'

      },


      {
        id: 'CORRES 835',
        name: 'CORRES 835',
        selector: row => row?.correspondence_835,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.correspondence_835}</span>,

        right: true,
        wrap: true,
        reorder: false,
        // grow: 0.1,
        minWidth: '50px'

      },
      {
        id: 'JOB TOTAL',
        name: 'JOB TOTAL',
        selector: row => row?.total,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.total}</span>,

        right: true,
        wrap: true,
        reorder: false,
        // grow: 0.1,
        minWidth: '50px'


      },
      {
        id: 'FILES SPLIT',
        name: 'FILES SPLIT - JOB',
        selector: row => row?.file_split,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.file_split}</span>,

        right: true,
        wrap: true,
        reorder: false,
        // grow: 0.1,
        minWidth: '100px'

      },

      {
        id: '# CLAIMS',
        name: '# CLAIMS',
        selector: row => row?.claims,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.claims}</span>,

        right: true,
        wrap: true,
        reorder: false,
        // grow: 0.1,
        omit: isTimeCount,
        minWidth: '50px'

      },
      {
        id: '# SVC LINES',
        name: '# SVC LINES',
        selector: row => row?.servicelines,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.servicelines}</span>,

        right: true,
        wrap: true,
        reorder: false,
        // grow: 0.1,
        omit: isTimeCount,
        minWidth: '50px'


      },

    ],

    [handleRowExpand, limit, pageno, isTimeCount],
  );

  var i = 0;
  const conditionalRowStyles = [

    {
      when: row => colorRow.id == row.id ? true : false,
      style: (row, index) => ({
        backgroundColor: colorRow?.id == row.id ? '#dbf4f5' : '#f2f2f2',
        color: 'black',
        // fontWeight: '550'
      }),
    },


  ];
  const handleRowClick = (row) => {
    // setColorRow(row);
    // setExpandedRows([])
    setColorRow(row);
    const isRowExpanded = expandedRows.includes(row);
    setExpandedRows(isRowExpanded ? expandedRows.filter((r) => r !== row) : [row]);
  };



  const [datetime, setDateTime] = useState([]);

  const handleOnDateTimeChange = ({ name, value }) => {
    setExpandedRows([])
    let created_date = ""
    if (value !== null) {
      const startDate = value[0];
      const endDate = value[1];
      // Check if the start date is after the end date, and swap the dates if needed
      let isGreater = false
      if (startDate > endDate) {
        isGreater = true
      }
      const datefrom = moment(isGreater ? endDate : startDate).format('MM/DD/YYYY')
      const dateto = moment(isGreater ? startDate : endDate).format('MM/DD/YYYY')

      created_date = datefrom + " - " + dateto
    } else {
      setSearchedData({
        ...searchedData,
        created_date: "",
        date_range: "",
      })
      setPageNo(1)
      fetchData(1, limit, searchFormik?.values?.search, selectValue, "", searchFormik?.values?.hour_count, searchedData?.payer_list, searchedData?.clients, searchedData?.facilities);

    }

    setDateTime(created_date)

    searchFormik.setValues({ ...searchFormik.values, created_date, date_range: value ?? "" })


  }

  const searchData = ({ station, job_status, created_date, hour_count, payer_list, clients, facilities }) => {
    setPageNo(1)
    fetchData(1, limit, station, '', created_date, hour_count, payer_list, clients, facilities)
  }


  const showTooltip = (text, props) => (
    <Tooltip id="button-tooltip" {...props}>
      {text}
    </Tooltip>
  );

  // const handleResetDataTable = () => {
  //   // setRerenderDataTable(true)
  //   // setTimeout(() => {
  //   //   setRerenderDataTable(false)
  //   // }, 200);
  // }
  const [loader, setLoader] = useState(false);

  const handleDownload = () => {
    setLoader(true)

    downloadPayerReportSummery(1, limit, searchedData?.search, '', searchedData.date_range, searchedData?.hour_count, searchedData?.payer_list, searchedData?.clients, searchedData?.facilities).then((response) => {
      setLoader(false)
      downloadXl(response?.data?.export_file)
    });
  }

  const [payers, setPayers] = useState([])
  let typingTimer;
  const loadOptions = inputValue => {
    return new Promise((resolve, reject) => {

      clearTimeout(typingTimer);
      typingTimer = setTimeout(() => {
        if (inputValue.length >= 3) {
          getPayerDropdown(1, inputValue.toUpperCase()).then((response) => {
            if (response && response.status && response.data && response.data.results) {
              setPayers(response.data.results)

              resolve(response.data.results);

            }
          });
        }
        else {
          resolve([]);
        }

      }, 300);
    });
  };
  const handleOnSelectChange = async (e) => {
    console.log(e);
    await searchFormik.setValues({
      ...searchFormik.values,
      payer_list: e.length > 0 ? e.map((i) => i?.value) : []
    })
  }
  // useEffect(() => {
  //   fetchData(1, limit, searchFormik?.values?.search, selectValue, datetime, searchFormik?.values?.hour_count);
  // }, [])
  const DropdownIndicator = ({ state, ...props }) => {
    console.log(state?.length, state)
    return (
      <components.DropdownIndicator {...props}>

        {state?.length > 1 ? (
          <TbGridDots style={{ fontSize: "1.5em" }} />
        ) : (
          <HiChevronDown style={{ fontSize: "1.5em" }} />
        )}



      </components.DropdownIndicator>
    );
  };
  return (
    <React.Fragment>
      <Sidebar />
      <main id="main" className="main">

        <div className="pagetitle tab-fixed-header">
          <h1 id='page-title'>Payer Report</h1>
        </div>

        <section className="section mt-5 ">
          <div className="row align-items-top py-2 gap-2 gap-md-5">
            <div className="col-lg-12">
              <div className="card mb-5 mb-xl-8" style={{ height: "92vh" }}>

                <Form onSubmit={searchFormik.handleSubmit}>
                  <div className=" ">
                    <div className="px-1 py-1 d-flex flex-row justify-content-start gap-2 align-items-center flex-wrap">

                      {/* <div className='col-lg-2'>
                        <Form.Group className=' '>
                          <Form.Control as="select"
                            name="hour_count"
                            className='form-control form-control-solid b-r-10'
                            value={searchFormik.values?.hour_count}
                            isValid={searchFormik.touched?.hour_count && !searchFormik?.errors?.hour_count}
                            isInvalid={!!searchFormik.errors?.hour_count}
                            placeholder="select an option"
                            onChange={(e) => { setExpandedRows([]); searchFormik.handleChange(e) }}
                          >

                            <option key={2} value={"true"}>Count</option>
                          </Form.Control>
                        </Form.Group>
                        {searchFormik.errors?.hour_count &&
                          <div>
                            <span className='text-danger'>{searchFormik.errors?.hour_count}</span>
                          </div>
                        }
                      </div> */}
                      <div className="col-md-2">
                        <AsyncSelect
                          // cacheOptions
                          tabSelectsValue={false}
                          closeMenuOnSelect={false}
                          isMulti
                          loadOptions={loadOptions}
                          placeholder="Select Payers"
                          defaultOptions={[]}
                          className="basic-single"
                          classNamePrefix="select"
                          isClearable={true}
                          hideSelectedOptions={false}
                          onChange={(e) => handleOnSelectChange(e, 'payer_id')}
                          styles={{

                            control: (styles, state) => ({
                              ...styles,
                              maxHeight: "30px",
                            }),
                            indicatorSeparator: styles => ({
                              ...styles,
                              maxHeight: "30px",
                            }),

                            valueContainer: (styles, state) => ({
                              ...styles,
                              maxHeight: "30px",
                              overflow: 'scroll',
                            }),
                            option: (styles, state) => ({
                              ...styles,
                              backgroundColor: state.isSelected ? '#d9f4f5' : 'inherit',
                              color: state.isSelected ? '#000000' : 'inherit',
                              '&:hover': { backgroundColor: state.isSelected ? '#d9f4f5' : 'rgb(222, 235, 255)' }

                            })
                          }}
                        // debounceInterval={1000}
                        />
                      </div>
                      <div>
                        <Select
                          tabSelectsValue={false}

                          ref={clientRef}
                          hideSelectedOptions={false}
                          isMulti
                          options={clients}
                          value={selectValue}
                          className=""
                          classNamePrefix="select"
                          placeholder="Client"
                          menuPlacement="auto"
                          maxMenuHeight={300}
                          components={{ DropdownIndicator: (props) => <DropdownIndicator {...props} state={selectValue} /> }}
                          isClearable
                          name="client"
                          onChange={(val) => handleSelectChange(val,'client')}

                          onFocus={() => setIsFocusedClient(true)}
                          onBlur={() => setIsFocusedClient(false)}
                          styles={{
                            container: (styles, state) => ({
                              ...styles,
                              borderRadius: "9px",
                            }),
                            control: (styles, state) => ({
                              ...styles,
                              backgroundColor: "hsl(204deg 33.33% 97.06%)",
                              borderStyle: "none", boxShadow: "none",
                              borderRadius: "10px",
                              maxHeight: "30px",
                            }),
                            indicatorSeparator: styles => ({
                              ...styles, width: "0px",
                              minHeight: "20px",
                              maxHeight: "30px",
                            }),

                            valueContainer: (styles, state) => ({
                              ...styles,
                              maxHeight: "30px",
                              overflow: 'scroll',
                            }),
                            option: (styles, state) => ({
                              ...styles,
                              backgroundColor: state.isSelected ? '#d9f4f5' : 'inherit',
                              color: state.isSelected ? '#000000' : 'inherit',
                              '&:hover': { backgroundColor: state.isSelected ? '#d9f4f5' : 'rgb(222, 235, 255)' }

                            })
                          }}
                          menuPortalTarget={document.body}
                          closeMenuOnSelect={false} // Keep the menu open after selecting an option

                        />
                      </div>
                      <div>
                        <Select
                          tabSelectsValue={false}

                          ref={facilityRef}
                          options={facilities}
                          className=""
                          classNamePrefix="select"
                          placeholder="Facility"
                          menuPlacement="auto"
                          maxMenuHeight={300}
                          isClearable
                          isMulti
                          name="facility"
                          value={selectedFacility}
                          components={{ DropdownIndicator: (props) => <DropdownIndicator {...props} state={selectedFacility} /> }}
                          hideSelectedOptions={false}

                          onChange={(val) => handleSelectChange(val, 'facility')}
                          onFocus={() => setIsFocusedFacility(true)}
                          onBlur={() => setIsFocusedFacility(false)}

                          styles={{
                            container: (styles, state) => ({
                              ...styles,
                              borderRadius: "9px",

                            }),
                            control: (styles, state) => ({
                              ...styles,
                              backgroundColor: "hsl(204deg 33.33% 97.06%)",
                              borderStyle: "none", boxShadow: "none",
                              borderRadius: "10px",
                              maxHeight: "30px",
                            }),
                            indicatorSeparator: styles => ({
                              ...styles, width: "0px",
                              minHeight: "20px",
                              maxHeight: "30px",
                            }),

                            valueContainer: (styles, state) => ({
                              ...styles,
                              maxHeight: "30px",
                              overflow: 'scroll',
                            }),
                            option: (styles, state) => ({
                              ...styles,
                              backgroundColor: state.isSelected ? '#d9f4f5' : 'inherit',
                              color: state.isSelected ? '#000000' : 'inherit',
                              '&:hover': { backgroundColor: state.isSelected ? '#d9f4f5' : 'rgb(222, 235, 255)' }

                            })
                          }}
                          menuPortalTarget={document.body}
                          closeMenuOnSelect={false} // Keep the menu open after selecting an option

                        />
                      </div>
                      <Tooltip disableInteractive title={"Last Used Date"} placement="top" arrow>

                        <div className="correspondenceDatePicker">
                          <Stack direction="column" spacing={8} alignItems="flex-start">

                            <DateRangePicker
                              // style={{ backgroundColor: "#000" }}
                              ranges={predefinedBottomRanges}
                              cleanable={false}
                              placement="auto"
                              appearance="default" format={"MM/dd/yyyy"}
                              className="form-date-control form-control-solid b-r-1 text-dark"
                              placeholder={"MM/DD/YYYY - MM/DD/YYYY"}
                              name="date_range"
                              onChange={(e) => { handleOnDateTimeChange({ name: 'date_range', value: e }) }} value={searchFormik?.values?.date_range} />
                          </Stack>
                        </div>
                      </Tooltip>

                      <div>
                        <Tooltip disableInteractive title={"Search"} placement="top" arrow>
                          <Button className="btn btn-light btn-active-primary" type="submit">
                            <span className="svg-icon svg-icon-3">
                              <BiSearch />
                            </span>

                          </Button>
                        </Tooltip>
                      </div>
                      {isHavePermission("export_payer_report_management") &&

                        <div>
                          <Tooltip disableInteractive title={"Download"} placement="top" arrow>
                            {!loader ?
                              <Button onClick={() => { handleDownload() }} className="btn btn-light btn-active-primary" >
                                <span className="svg-icon svg-icon-3">
                                  <BsCloudDownloadFill />
                                </span>

                              </Button> :
                              <Button className="btn btn-light btn-active-primary" >
                                <span className="svg-icon svg-icon-3">
                                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                </span>

                              </Button>}
                          </Tooltip>

                        </div>
                      }
                    </div>
                  </div>

                </Form>


                <div className="card-body p-common">
                  <div className="table-responsive">
                    <div className="dashboardtable">
                      {/* {!reRenderDataTable && */}
                      <DataTable
                        paginationDefaultPage={pageno}
                        className="custom-datatable"
                        fixedHeader={true}
                        progressPending={loading}
                        data={data?.results}
                        columns={columns}
                        pointerOnHover={true}
                        pagination
                        paginationServer
                        // highlightOnHover
                        paginationTotalRows={totalRows}
                        paginationPerPage={limit}
                        paginationRowsPerPageOptions={[25, 50, 100, 150, 200]}
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                        conditionalRowStyles={conditionalRowStyles}
                        theme="solarized"
                        customStyles={datatableCustomStyles}
                        striped={true}
                        expandableRows
                        expandOnRowClicked={false}
                        onRowExpandToggled={handleRowExpand}
                        expandableRowsComponent={ExpandedComponent}
                        expandableRowsHideExpander
                        expandableRowExpanded={(row) => expandedRows.includes(row)}
                        onRowClicked={handleRowClick}

                      />
                      {/* } */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>



      </main>
    </React.Fragment >
  )
}
export default PayerReport




