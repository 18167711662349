import { darken, desaturate, lighten } from 'polished';
import React from 'react'
import Chart from 'react-apexcharts';
import BarSkelitonLoader from '../../loader/BarSkelitonLoader';

const SingleReportGraph = ({ isVisible, singleData, handleClickInnerGraph, handleClickInnerData, singleColor }) => {

    const series_items = [singleData.quality_payment835 ?? 0, singleData.quality_payment835_lite ?? 0, singleData.quality_patientpay ?? 0, singleData.quality_correspondence ?? 0]

    const options = {
        dataLabels: {
            enabled: true,
            style: {
                colors: ['black']
            },
            dropShadow: {
                enabled: false,
                enabledOnSeries: undefined,
                top: 0,
                left: 0,
                blur: 3,
                opacity: 0.35
            }
        },

        fill: {
            type: 'gradient',
        },
        chart: {
            id: 'basic-bar',
            toolbar: {
                show: false
            },
            fontFamily: 'Open Sans',
            events: {
                click: handleClickInnerGraph,
                dataPointSelection: handleClickInnerData
            }
        },
        grid: {
            borderColor: '#E4E4E4',
            xaxis: {
                lines: {
                    show: false
                }
            },
            yaxis: {
                lines: {
                    show: true
                }
            },
        },
        plotOptions: {
            bar: {
                distributed: true,
                columnWidth: '40px'
            },
        },
        legend: {
            show: false
        },

        colors: [darken(0.1, singleColor), singleColor, lighten(0.1, singleColor), desaturate(0.2, singleColor)],
        xaxis: {
            categories: ['Payment 835', 'Payment 835 Lite', 'Patient Pay', 'Correspondence'],
            axisBorder: {
                show: true,
                color: '#000000',
                height: 1,
                width: '100%',
                offsetX: 0,
                offsetY: 0
            },
            labels: {
                show: true,
            }

        },
        yaxis: {
            axisBorder: {
                show: true,
                color: '#000000',
                height: '100%',
                width: 1,
                offsetX: 0,
                offsetY: 0
            },

        },
        tooltip: {
            show: true,
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                const color = w.config.colors[dataPointIndex];
                let tooltip = ''
                const mainLabel = w.globals.labels[dataPointIndex]
                if (mainLabel === "Payment 835") {
                    tooltip = `${mainLabel}:${singleData?.quality_payment835}(${singleData?.payment835_error_count}/${singleData?.payment835_qa_complte})`
                }
                else if (mainLabel === "Payment 835 Lite") {
                    tooltip = `${mainLabel}:${singleData?.quality_payment835_lite}(${singleData?.payment835_lite_error_count}/${singleData?.payment835_lite_qa_complte})`
                }
                else if (mainLabel === "Patient Pay") {
                    tooltip = `${mainLabel}:${singleData?.quality_patientpay}(${singleData?.patientpay_error_count}/${singleData?.patientpay_qa_complte})`
                }
                else if (mainLabel === "Correspondence") {
                    tooltip = `${mainLabel}:${singleData?.quality_correspondence}(${singleData?.correspondance_error_count}/${singleData?.correspondance_qa_complte})`
                } return `<div style="background-color:${color};padding:5px" class="">
                        <span style="color:${"#000"};" style="color:white;"> ${tooltip}  </span>
                </div>`

            }
        },
    };
    const series = [{
        name: '',
        data: series_items ? series_items : []
    }]
    // console.log(singleData)
    return (
        <div className='card p-2' style={{ height: '45vh' }}>
            {isVisible ?
                <BarSkelitonLoader /> :
                <Chart options={options} series={series} type="bar" height={'90%'} />
            }
        </div>
    )
}

export default SingleReportGraph