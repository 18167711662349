


import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import styled from "styled-components/macro";

const CircleSkelitonLoader = ({ isVisible }) => {
  return (
    <BlurBackground isVisible={isVisible}>

      <SkelitonContainer>
        {/* <div className="top-headder">
        <Skeleton style={{ position: 'absolute' }} height={30} width={150} />
      </div> */}
        <div className="main-circle-dotted-conatiner">
          <CircleLoader >
            <div className="inner-circle-loader"></div>
            <Skeleton style={{ position: 'absolute' }} circle height={350} width={350}>
            </Skeleton>
          </CircleLoader>
          <LeftDottedList>
            <div className="dotted-list-container">
              <div className="dotted-circle">
                <Skeleton circle height={10} width={10} />
              </div>
              <div className="stright-line">
                <Skeleton height={5} width={100} />
              </div>
            </div>
            <div className="dotted-list-container">
              <div className="dotted-circle">
                <Skeleton circle height={10} width={10} />
              </div>
              <div className="stright-line">
                <Skeleton height={5} width={100} />
              </div>
            </div>
            <div className="dotted-list-container">
              <div className="dotted-circle">
                <Skeleton circle height={10} width={10} />
              </div>
              <div className="stright-line">
                <Skeleton height={5} width={100} />
              </div>
            </div>
            <div className="dotted-list-container">
              <div className="dotted-circle">
                <Skeleton circle height={10} width={10} />
              </div>
              <div className="stright-line">
                <Skeleton height={5} width={100} />
              </div>
            </div>
            <div className="dotted-list-container">
              <div className="dotted-circle">
                <Skeleton circle height={10} width={10} />
              </div>
              <div className="stright-line">
                <Skeleton height={5} width={100} />
              </div>
            </div>
            <div className="dotted-list-container">
              <div className="dotted-circle">
                <Skeleton circle height={10} width={10} />
              </div>
              <div className="stright-line">
                <Skeleton height={5} width={100} />
              </div>
            </div>
            <div className="dotted-list-container">
              <div className="dotted-circle">
                <Skeleton circle height={10} width={10} />
              </div>
              <div className="stright-line">
                <Skeleton height={5} width={100} />
              </div>
            </div>

          </LeftDottedList>
        </div>

      </SkelitonContainer>
    </BlurBackground>
  );
};

export default CircleSkelitonLoader;

const BlurBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const SkelitonContainer = styled.div`
  width :100%;
 .top-headder{
  margin-bottom: 10px;
  span{
    display: block;
    width: 150px;
    height: 30px;
  }
 }
 .main-circle-dotted-conatiner{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 183px;
  /* column-gap: 100px; */
 }
`;
const CircleLoader = styled.div`
   position: relative;
   width: 350px;
   height: 350px;
   display: flex;
   align-items: center;
   justify-content: center;
   .inner-circle-loader{
    position: absolute;
    width: 226px;
    height: 226px;
    background-color: white;
    border-radius:50%;
    z-index: 1;
   }
   span{
    display: block;
    width: 350px;
    height: 350px;
   }
`
const LeftDottedList = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 5px;

.dotted-list-container{
  display: flex;
  column-gap: 5px;
  align-items: center;
.dotted-circle span{
  display: block;
  width: 10px;
  height: 10px;
}
.stright-line span{
  display: block;
  width: 100px;
  height: 5px;
}
} 
`
