import React, { useRef } from 'react';
import testDocument from "../../../../assets/pdf/page-fit.pdf";
import { Worker, Viewer, SpecialZoomLevel, ScrollMode, RotateDirection, MinimalButton, DocumentLoadEvent } from '@react-pdf-viewer/core';
import { thumbnailPlugin } from '@react-pdf-viewer/thumbnail';
import type { RenderThumbnailItemProps } from '@react-pdf-viewer/thumbnail';
import { ThumbnailIcon } from '@react-pdf-viewer/default-layout';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { ToolbarSlot } from "@react-pdf-viewer/toolbar";
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import { scrollModePlugin } from '@react-pdf-viewer/scroll-mode';
import { rotatePlugin } from '@react-pdf-viewer/rotate';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/full-screen/lib/styles/index.css';
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';
import Grid from './../Grid/index';
import { useState } from 'react';
import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { DataListService } from '../../../../services/DataListService';
import { DATA_ENTRY, EDIT_WORK, QA_REVIEW, disablingBasedOnDbk } from '../../../../functions';


const PdfViewer = () => {
    const dataListService = new DataListService();
    const { getUserlist, getJobDetailReport } = dataListService



    const pageNavigationPluginInstance = pageNavigationPlugin();
    const { jumpToPage } = pageNavigationPluginInstance;
    const renderToolbar = (Toolbar: (props: ToolbarProps) => ReactElement) => (
        <Toolbar>
            {function (slots: ToolbarSlot) {
                const {
                    CurrentPageInput, EnterFullScreen, GoToFirstPage, GoToNextPage, GoToPreviousPage, GoToLastPage, NumberOfPages, Zoom, ZoomIn, ZoomOut,
                } = slots;

                return (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%',
                        }}
                    >


                        <div className='d-flex flex-row justify-content-between align-items-center' style={{ padding: '0px 2px', marginLeft: '100px' }}>

                            <GoToFirstPage />
                            <GoToPreviousPage />
                            <CurrentPageInput />

                            / <NumberOfPages />
                            <GoToNextPage />
                            <GoToLastPage />

                        </div>
                        <div className='d-flex flex-row justify-content-between align-items-center' style={{ padding: '0px 2px', marginLeft: '42rem' }}>
                            <ZoomOut />
                            <Zoom onZoom={(e) => handleZoom(e, true)}
                            />
                            <ZoomIn />
                        </div>
                        <div style={{ padding: '0px 2px', marginLeft: 'auto' }}>
                            <EnterFullScreen />
                        </div>
                    </div>
                );
            }}
        </Toolbar>
    );

    const user = JSON.parse(localStorage.getItem("user"));
    const navigate = useNavigate()
    const location = useLocation();


    let { job_id } = useParams();

    //grid states
    const [jobData, setJobData] = useState("");
    const [fileUrl, setFileUrl] = useState("");
    const [noJob, setNoJOb] = useState(false);

    //page states
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [initialPage, setInitialPage] = useState();
    //notification states
    const [notification, setNotification] = useState({ title: '', body: '', job: '' });
    const [show, setShow] = useState(false);
    const [toast, setToast] = useState({
        status: false,
        type: "",
        message: ""
    });
    const [jobUserData, setJobUserData] = useState({})
    const [errorAlert, setErrorAlert] = useState({
        status: false,
        message: "",
        type: '',
    })
    const [error837, setError837] = useState({
        status: false,
        message: "",
        type: '',
    })
    const [subJobOptions, setSubJobOptions] = useState([])
    const user_idle_time_out = user?.settings?.filter((i) => i?.key_name === 'ideal_user_timeOut')[0]?.value



    useEffect(() => {
        if (job_id) {
            getGridData()
        }
    }, [job_id]);

    const getGridLogData = () => {

    }

    const getGridData = () => {

        let task_type = "1";

        if (window.location.pathname == DATA_ENTRY) {

            task_type = "1"
        }
        else if (window.location.pathname == QA_REVIEW) {
            task_type = "2"
        }
        else if (window.location.pathname == EDIT_WORK + job_id) {
            task_type = "2"
        }

        getJobDetailReport(job_id).then((response) => {

            setNoJOb(false)
            setJobData(response?.data)
            setFileUrl(response?.data?.file_url)

            if (response?.data?.grid_data?.claims && response?.data?.grid_data?.claims?.length > 0 && response?.data?.grid_data?.status == "partial_save") {
                setInitialPage(response?.data?.grid_data?.claims[response?.data?.grid_data?.claims?.length - 1].page_to);
                jumpToPage(response?.data?.grid_data?.claims[response?.data?.grid_data?.claims?.length - 1].page_to)

            }

            if (window.location.pathname == QA_REVIEW) {
                setIsSubJob(false)
                setIsFirstJob(false);
            } else {
                setIsSubJob(response?.data?.is_sub_job)
                setIsFirstJob(response?.data?.is_first_sub_job);
            }



        })
    }

    const [commentAlert, setCommentAlert] = useState(false)

    const [isSubJob, setIsSubJob] = useState(false);
    const [isFirstJob, setIsFirstJob] = useState(false);
    const jumpRef = useRef(null)


    const scrollModePluginInstance = scrollModePlugin();
    const rotatePluginInstance = rotatePlugin();
    const { RotatePage } = rotatePluginInstance;

    //custom layout instance for pdf viewer
    const defaultLayoutPluginInstance = defaultLayoutPlugin({

        renderToolbar, toolbarPlugin: {
            pageNavigationPlugin: {
                enableShortcuts: true,
            },

        },
        fullScreenPlugin: {
            onEnterFullScreen: (zoom) => {
                zoom(SpecialZoomLevel.PageFit);
                defaultLayoutPluginInstance.toolbarPluginInstance.scrollModePluginInstance.switchScrollMode(
                    ScrollMode.Wrapped
                );
            },

            onExitFullScreen: (zoom) => {
                zoom(SpecialZoomLevel.PageWidth);
                defaultLayoutPluginInstance.toolbarPluginInstance.scrollModePluginInstance.switchScrollMode(
                    ScrollMode.Vertical
                );
            },
        },

        sidebarTabs: (defaultTabs) => [
            {
                content: <Thumbnails />,
                icon: <ThumbnailIcon />,
                title: 'Thumbnails',
            },
        ],

        thumbnailPlugin: {
            thumbnailWidth: 50,
        },

    });


    //trigger fucntion for page scroll or change events
    const handlePageChange = (e) => {
        setCurrentPage(e.currentPage)

        // setInitialPage(e.currentPage)
    };
    const [stop, setStop] = useState(false)
    const [pdfLoaded, setPdfLoaded] = useState(false)



    const thumbnailPluginInstance = thumbnailPlugin();
    const { Thumbnails } = thumbnailPluginInstance;



    // fucntion for check rotation and other fucntions
    const [rotatePosition, setRotatePosition] = useState('stright')

    const handlePageRotate = (props, position, currentPage) => {
        if (position == 'left') {
            setRotatePosition(rotatePosition == 'stright' ? 'left' : rotatePosition == 'left' ? 'bottom' : rotatePosition == 'bottom' ? 'right' : rotatePosition == 'right' ? 'stright' : null)
            props.onRotatePage(currentPage, RotateDirection.Backward)
        }
        else if (position == 'right') {
            setRotatePosition(rotatePosition == 'stright' ? 'right' : rotatePosition == 'right' ? 'bottom' : rotatePosition == 'bottom' ? 'left' : rotatePosition == 'left' ? 'stright' : null)
            props.onRotatePage(currentPage, RotateDirection.Forward)
        }

    };




    useEffect(() => {

        if (jobData && jobData?.file_info && jobData?.file_info?.comment) {
            setCommentAlert(true)
        }

    }, [jobData])

    useEffect(() => {

        // if (jobData?.is_large_batch_job) {
        //     getSubJobUserData(jobData?.job)((response) => {
        //         setJobUserData(response?.data);
        //         const subjobs = [];
        //         response?.data?.map((user, index) => {
        //             subjobs.push({
        //                 value: user?.pk,
        //                 label: user?.unique + "|" + user?.from_page + "-" + user?.to_page + "|" + user?.staff_name,
        //                 staff_name: user?.staff_name,
        //                 staff_id: user?.staff_id,
        //                 page_range: user?.from_page + "-" + user?.to_page,
        //                 is_select: false,
        //                 is_active: user?.is_active,
        //                 assign_reason: "",
        //                 assignTo: user?.is_active ? "user" : "common"

        //             })
        //         })
        //         setCommentAlert(true)
        //         setSubJobOptions(subjobs)
        //     });
        // }

    }, [jobData?.is_large_batch_job])

    const [containerWidth, setContainerWidth] = useState(window.innerWidth - 60);
    const [calculatedZoom, setCalculatedZoom] = useState(0);
    const [reRenderViewer, setReRenderViewer] = useState(true);


    const setZoomScale = (e, setStateCalcZoom, setReRender) => {
        const { doc } = e;
        const numPages = doc.numPages;

        const getPagePromises = [];

        // Iterate through all pages to find the one with the maximum width
        for (let pageIndex = 1; pageIndex <= numPages; pageIndex++) {
            const getPagePromise = doc.getPage(pageIndex).then((page) => {
                const viewport = page.getViewport({ scale: 1 });
                const pageWidth = viewport.width;

                return pageWidth;
            });

            getPagePromises.push(getPagePromise);
        }

        // Wait for all promises to resolve
        Promise.all(getPagePromises).then((pageWidths) => {
            const maxPageWidth = Math.max(...pageWidths);

            const containerToPageWidthRatio = containerWidth / maxPageWidth;
            const initialZoom = containerToPageWidthRatio;

            // Use the `zoom` function from the library to set the initial zoom level
            // Replace this with the actual function provided by the library to set the zoom level
            // For example, if the library provides a `zoom` prop for the Viewer component
            // You might do something like:
            // setZoom(initialZoom);
            setStateCalcZoom(initialZoom);
            setReRender(false)
            setTimeout(() => {
                setReRender(true)
            }, 200);
        });
    };

    const handleZoom = (e, isZoom) => {
        const { scale } = e
        const zoomListArr = [0.5, 0.75, 1, 1.25, 1.50, 2, 3, 4]
        // Define the minimum zoom level
        const minZoomLevel = 0.1;

        // Calculate the new zoom level based on the pinch gesture
        const newZoomLevel = scale < calculatedZoom ? calculatedZoom - 0.3 : calculatedZoom + 0.3;

        // Ensure the new zoom level doesn't go below the minimum
        const updatedZoomLevel = zoomListArr.includes(scale) ? scale : Math.max(minZoomLevel, newZoomLevel);

        // Set the new zoom level
        setCalculatedZoom(updatedZoomLevel);

        // Trigger re-rendering
        setReRenderViewer(false);
        setTimeout(() => {
            setReRenderViewer(true);
        }, 200);

        // Handle other zoom-related logic if needed

        // Call the defaultLayoutPluginInstance zoom handler
    };

    const handleDocumentLoad = function (e: DocumentLoadEvent) {

        setTotalPages(e.doc.numPages);
        setStop(true)
        setPdfLoaded(true)
        if (calculatedZoom === 0) {
            setZoomScale(e, setCalculatedZoom, setReRenderViewer)
        }
    };


    useEffect(() => {
        // setTimeout(() => {
        //     if (jumpRef && stop && jobData?.grid_data?.claims?.length > 0 && jobData?.grid_data?.status == "partial_save") {
        //         jumpToPage(jobData?.grid_data?.claims[jobData?.grid_data?.claims.length - 1]?.page_to - 1)
        //         setStop(false)
        //     }
        // }, 200);

    }, [jumpRef, stop])

    return (

        <div className='ex2'>
            {/* Grid */}
            {/* {pdfLoaded && */}
            <Grid
                pdfLoaded={pdfLoaded}
                jumpToPage={jumpToPage}
                initialPage={initialPage}
                setInitialPage={setInitialPage}
                job_id={job_id}
                log_id={null}
                fileUrl={fileUrl}
                jobData={jobData}
                setNoJOb={setNoJOb}
                setJobData={setJobData}
                jobUserData={jobUserData}
                setFileUrl={setFileUrl}
                toast={toast}
                setToast={setToast}
                currentPage={currentPage}
                totalPages={totalPages}
                rotatePosition={rotatePosition}
                getGridData={getGridData}
                setErrorAlert={setErrorAlert}
                error837={error837}
                setError837={setError837}
                errorAlert={errorAlert}
                isSubJob={isSubJob}
                setIsSubJob={setIsSubJob}
                isFirstJob={isFirstJob}
                setIsFirstJob={setIsFirstJob}
                subJobOptions={subJobOptions}
                jumpRef={jumpRef}
            />
            {/* } */}

            {/* Grid */}

            {/* pdf viewer starts here */}
            <Worker workerUrl="/assets/js/pdf.worker.min.js">
                <div
                    id="magnifier-box"
                    style={{
                        border: '1px solid rgba(0, 0, 0, 0.3)',
                        // height: '990px',
                        // maxHeight: '990px',
                        height: '100vh',
                        maxHeight: '100vh',
                    }}>


                    {fileUrl && reRenderViewer &&

                        <Viewer
                            // defaultScale={SpecialZoomLevel.PageWidth}
                            plugins={[defaultLayoutPluginInstance, pageNavigationPluginInstance, scrollModePluginInstance, rotatePluginInstance, thumbnailPluginInstance]}
                            fileUrl={fileUrl}
                            // fileUrl={testDocument}

                            // initialPage={initialPage - 1}
                            // fileUrl={testDocument}
                            onDocumentLoad={handleDocumentLoad}
                            onPageChange={handlePageChange}
                            onZoom={handleZoom}
                            defaultScale={calculatedZoom}


                        />

                    }

                </div>
            </Worker>
            {/* pdf viewer ends here */}

        </div>
    );

}
export default PdfViewer;
