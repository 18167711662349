import request from "../api/agent";

const API_ROUTE = "reports";

export class QaErrorReportService {

  async getQaErrorReportSummery(page, limit, station, job_status, date_range, hour_count, users_ids, clients, facilities) {
    const params = `?page=${page}&limit=${limit}&station=${station}&date_range=${date_range}&job_status=${job_status}&users_ids=${users_ids}&clients=${clients}&facilities=${facilities}`

    const response = await request.get(`${API_ROUTE}/get-qa-error-summery` + params);
    return response.data ?? response?.response?.data;

  }

  async getQaErrorReportDetails(page, limit, station, job_status, date_range, hour_count, user, day, year, week, monthList, clients, facilities, client_id, facility_id) {
    const params = `?page=${page}&limit=${limit}&station=${station}&date_range=${date_range}&user=${user}&day=${day}&year=${year}&week=${week}&month=${monthList}&clients=${clients}&facilities=${facilities}&client_id=${client_id}&facility_id=${facility_id}`

    const response = await request.get(`${API_ROUTE}/get-qa-error-details` + params);
    return response.data;
  }
  async downloadQaErrorReportSummery(page, limit, station, job_status, date_range, hour_count, users_ids, clients, facilities) {
    const params = `?page=${page}&limit=${limit}&station=${station}&date_range=${date_range}&job_status=${job_status}&users_ids=${users_ids}&clients=${clients}&facilities=${facilities}`

    const response = await request.get(`${API_ROUTE}/qa-error-summery-download` + params);
    return response.data;
  }
  async downloadQaErrorFieldReport(page, limit, station, job_status, date_range, hour_count, users_ids, clients, facilities) {
    const params = `?page=${page}&limit=${limit}&station=${station}&date_range=${date_range}&job_status=${job_status}&users_ids=${users_ids}&clients=${clients}&facilities=${facilities}`

    const response = await request.get(`${API_ROUTE}/qa-error-field-download` + params);
    return response.data;
  }
  async downloadQaErrorReportSingle(page, limit, station, job_status, date_range, hour_count, users_ids, clients, facilities) {
    const params = `?page=${page}&limit=${limit}&station=${station}&date_range=${date_range}&job_status=${job_status}&users_ids=${users_ids}&clients=${clients}&facilities=${facilities}`

    const response = await request.get(`${API_ROUTE}/qa-error-summery-single-download` + params);
    return response.data;
  }

  async downloadQaErrorReportDetails(page, limit, station, job_status, date_range, hour_count, user, day, year, week, monthList, clients, facilities, client_id, facility_id) {
    const params = `?page=${page}&limit=${limit}&station=${station}&date_range=${date_range}&user=${user}&day=${day}&year=${year}&week=${week}&month=${monthList}&clients=${clients}&facilities=${facilities}&client_id=${client_id}&facility_id=${facility_id}`

    const response = await request.get(`${API_ROUTE}/qa-error-details-download` + params);
    return response.data;
  }
}