import { useFormik } from "formik";
import React, { useMemo, useState } from "react";
import { Button, Form, OverlayTrigger } from "react-bootstrap";
import DataTable from "react-data-table-component";

import { useEffect } from "react";
import { BsCloudDownloadFill } from "react-icons/bs";
import { useSelector } from "react-redux";
import { downloadXl } from "../../../functions";
import { UserReportService } from "../../../services/UserReportService";
import { selectIsSuperUser, selectPermissions } from "../../../slice/user/userSlice";
import { Tooltip } from "@mui/material";
import { createTheme } from 'react-data-table-component';
import DetailCutomRowStyle from "../../components/DetailCutomRowStyle";



// const datatableCustomStyles = {
//   rows: {
//     style: {
//       minHeight: '20px', // override the row height
//       fontSize: '0.7em',
//       fontColor: '#212529 !important',
//       width: '100%'
//     },
//   },
//   headCells: {
//     style: {
//       paddingLeft: '5px', // override the cell padding for head cells
//       paddingRight: '5px',
//       textTransform: "uppercase",
//       fontSize: '0.7em',
//       fontWeight: '800',
//       fontColor: '#6c757d !important',
//       opacity: 1,
//       minHeight: "10px",
//     },
//   },
//   cells: {
//     style: {
//       paddingLeft: '8px', // override the cell padding for data cells
//       paddingRight: '8px',
//       width: "100%",
//     },
//   },
//   pagination: {
//     style: {
//       color: '#000',
//       fontSize: '13px',
//       minHeight: '56px',
//       backgroundColor: '#fff',
//       borderTopStyle: 'solid',
//       borderTopWidth: '1px',
//       borderTopColor: '#f2f2f2',
//     },
//     pageButtonsStyle: {
//       borderRadius: '50%',
//       height: '40px',
//       width: '40px',
//       padding: '8px',
//       margin: '0px',
//       cursor: 'pointer',
//       transition: '0.4s',
//       color: '#000',
//       fill: '#000',
//       backgroundColor: 'transparent',
//       '&:disabled': {
//         cursor: 'unset',
//         color: '#e2e2e2',
//         fill: '#e2e2e2',
//       },
//       '&:hover:not(:disabled)': {
//         backgroundColor: '#f2f2f2',
//       },
//       '&:focus': {
//         outline: 'none',
//         backgroundColor: '#f5f5f5',
//       },
//     },
//   },

// };
// createTheme(
//   'solarized',
//   {
//     text: {
//       primary: '#000000',
//       secondary: 'rgba(0, 0, 0, 0.54)',
//       disabled: 'rgba(0, 0, 0, 0.38)',
//     },
//     background: {
//       default: '#dbf4f5 ',
//     },
//     context: {
//       background: '#e3f2fd',
//       text: 'rgba(0, 0, 0, 0.87)',
//     },
//     divider: {
//       default: '#f4f4f4',
//     },
//     button: {
//       default: 'rgba(0,0,0,.54)',
//       focus: 'rgba(0,0,0,.12)',
//       hover: 'rgba(0,0,0,.12)',
//       disabled: 'rgba(0, 0, 0, .18)',
//     },
//     selected: {
//       default: '#e3f2fd',
//       text: 'rgba(0, 0, 0, 0.87)',
//     },
//     highlightOnHover: {
//       default: '#EEEEEE',
//       text: 'rgba(0, 0, 0, 0.87)',
//     },
//     striped: {
//       default: '#dbf4f5 ',
//       text: 'rgba(0, 0, 0, 0.87)',
//     },
//   }
// );
function UserReportDetails(params) {
  const { report_station, report_job_status, report_date_range, report_hour_count, user, isTimeCount, isTimeCountQaReview, clients, facilities, client_id, facility_id } = params

  const [reRenderDataTable, setRerenderDataTable] = useState(false)
  const [pageno, setPageNo] = useState(1);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(100);
  const [selectValue, setSelectValue] = useState('');
  const [colorRow, setColorRow] = React.useState({});
  const [data, setData] = useState([])
  const [day, setDay] = useState(true)
  const [year, setYear] = useState(false)
  const [week, setWeek] = useState(false)
  const [month, setMonth] = useState(false)
  const [monthList, setMonthList] = useState([])
  const [limit, setLimit] = useState(100)
  const [searchedData, setSearchedData] = useState({
    search: "",
    client: "",
    created_date: "",
    date_range: "",
  })
  const permissions = useSelector(selectPermissions);
  const isSuperUser = useSelector(selectIsSuperUser);
  const isHavePermission = (name) => {
    return permissions?.includes(name) || isSuperUser
  }
  const userReportService = new UserReportService();
  const { getUserReportDetails, downloadUserReportDetails } = userReportService

  const handleDetailFilterChange = (name) => {
    if (name === 'year') {
      setYear(true)
      setWeek(false)
      setMonth(false)
      setDay(false)
      fetchData(1, limit, report_station, report_job_status, report_date_range, report_hour_count, user, 'false', 'true', 'false', 'false');

    }
    else if (name === 'day') {
      setYear(false)
      setWeek(false)
      setMonth(false)
      setDay(true)
      fetchData(1, limit, report_station, report_job_status, report_date_range, report_hour_count, user, 'true', 'false', 'false', 'false');

    }
    else if (name === 'month') {
      setYear(false)
      setWeek(false)
      setMonth(true)
      setDay(false)
      fetchData(1, limit, report_station, report_job_status, report_date_range, report_hour_count, user, 'false', 'false', 'false', 'true');

    }
    else if (name === 'week') {
      setYear(false)
      setWeek(true)
      setMonth(false)
      setDay(false)
      fetchData(1, limit, report_station, report_job_status, report_date_range, report_hour_count, user, 'false', 'false', 'true', 'false');

    }

  }
  const handlePerRowsChange = async (newPerPage, page) => {
    setLimit(newPerPage);
    setPageNo(1)

    fetchData(1, newPerPage, report_station, report_job_status, report_date_range, report_hour_count, user, day, year, week, month);
    handleResetDataTable()

  };

  const handlePageChange = page => {
    setPageNo(page)
    fetchData(page, limit, report_station, report_job_status, report_date_range, report_hour_count, user, day, year, week, month);
  };

  const fetchData = (page, limit, station, job_status, date_range, hour_count, user, day, year, week, month) => {

    setLoading(true)
    getUserReportDetails(page, limit, report_station, report_job_status, report_date_range, report_hour_count, user, day, year, week, month, clients, facilities, client_id, facility_id).then((response) => {
      setData(response?.data?.results);
      setLimit(limit);
      setPageNo(page)
      setTotalRows(response?.data?.count);
      setLoading(false);
    });

  }

  const columns = useMemo(
    () => [
      {
        id: 'S.NO',
        name: 'S.NO',
        selector: (row, index) => pageno == 1 ? index + 1 : (pageno - 1) * limit + index + 1,
        wrap: true,
        width: "40px",
        grow: 0.1,

      },

      {
        id: 'FILTER DATA',
        name: year ? 'YEAR' : week ? "WEEK" : month ? "MONTH" : "DATE",
        selector: row => row?.filter_data,
        cell: (row) =>

          <div>
            {row?.filter_data &&

              <div className='d-flex flex-row justify-content-between align-items-center gap-2' style={{ maxWidth: '170px' }}>
                <div className='overflow-hidden text-truncate' style={{ maxWidth: "120px" }} onClick={() => handleRowClick(row)}>
                  <Tooltip disableInteractive title={row?.filter_data} placement="top" arrow>
                    <span className="text-truncate"> {row?.filter_data}</span>
                  </Tooltip>
                </div>
              </div>

            }
          </div>
        ,


        left: true,
        wrap: true,
        reorder: false,
        grow: 0,
        expandOnRowClicked: true,
        minWidth: '100px'
      },
      {
        id: 'PAYMENT835',
        name: 'PAYMENT835',
        selector: row => row?.no_of_835,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.no_of_835}</span>,

        right: true,
        wrap: true,
        reorder: false,
        grow: 0,
        minWidth: '80px'

      },
      {
        id: 'TIME',
        minWidth: '55px',
        name: 'TIME',
        selector: row => row?.no_of_835_time,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.no_of_835_time}</span>,

        left: true,
        wrap: true,
        reorder: false,
        grow: 0,
        omit: report_hour_count == "" ? false : true,

      },
      {
        id: 'CPH',
        name: 'CPH',
        selector: row => row?.no_of_835_cph,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.no_of_835_cph}</span>,
        right: true,
        wrap: true,
        reorder: false,
        grow: 0,
        minWidth: '60px',
        maxWidth: '60px',

      },
      {
        id: 'EOB LITE/835 LITE',
        name: 'EOB LITE/835 LITE',
        selector: row => row?.no_835_lite,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.no_835_lite}</span>,

        right: true,
        wrap: true,
        reorder: false,
        grow: 0,
      },
      {
        id: 'TIME',
        minWidth: '55px',
        name: 'TIME',
        selector: row => row?.no_835_lite_time,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.no_835_lite_time}</span>,

        left: true,
        wrap: true,
        reorder: false,
        grow: 0,
        omit: report_hour_count == "" ? false : true,

      },
      {
        id: 'CPH',
        name: 'CPH',
        selector: row => row?.no_835_lite_cph,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.no_835_lite_cph}</span>,
        right: true,
        wrap: true,
        reorder: false,
        grow: 0,
        minWidth: '60px',
        maxWidth: '60px',

      },
      {
        id: 'CORRES',
        name: 'CORRES',
        selector: row => row?.correspondence,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.correspondence}</span>,

        right: true,
        wrap: true,
        reorder: false,
        grow: 0,
        minWidth: '59px',

      },
      {
        id: 'TIME',
        minWidth: '55px',
        name: 'TIME',
        selector: row => row?.correspondence_time,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.correspondence_time}</span>,

        left: true,
        wrap: true,
        reorder: false,
        grow: 0,
        omit: report_hour_count == "" ? false : true,

      },
      {
        id: 'CPH',
        name: 'CPH',
        selector: row => row?.correspondence_cph,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.correspondence_cph}</span>,
        right: true,
        wrap: true,
        reorder: false,
        grow: 0,
        minWidth: '60px',
        maxWidth: '60px',

      },
      {
        id: 'PAT PAY',
        name: 'PAT PAY',
        selector: row => row?.patient_pay,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.patient_pay}</span>,

        right: true,
        wrap: true,
        reorder: false,
        grow: 0,
        minWidth: '59px',

      },
      {
        id: 'TIME',
        minWidth: '55px',
        name: 'TIME',
        selector: row => row?.patient_pay_time,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.patient_pay_time}</span>,

        left: true,
        wrap: true,
        reorder: false,
        grow: 0,
        omit: report_hour_count == "" ? false : true,

      },
      {
        id: 'CPH',
        name: 'CPH',
        selector: row => row?.patient_pay_cph,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.patient_pay_cph}</span>,
        right: true,
        wrap: true,
        reorder: false,
        grow: 0,
        minWidth: '60px',
        maxWidth: '60px',

      },
      {
        id: 'PAT PAY 835',
        name: 'PAT PAY 835',
        selector: row => row?.patient_pay_835,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.patient_pay_835}</span>,

        right: true,
        wrap: true,
        reorder: false,
        grow: 0,
      },

      {
        id: 'TIME',
        minWidth: '55px',
        name: 'TIME',
        selector: row => row?.patient_pay_835_time,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.patient_pay_835_time}</span>,

        left: true,
        wrap: true,
        reorder: false,
        grow: 0,
        omit: report_hour_count == "" ? false : true,

      },
      {
        id: 'CPH',
        name: 'CPH',
        selector: row => row?.patient_pay_835_cph,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.patient_pay_835_cph}</span>,
        right: true,
        wrap: true,
        reorder: false,
        grow: 0,
        minWidth: '60px',
        maxWidth: '60px',

      },
      {
        id: 'CORRES 835',
        name: 'CORRES 835',
        selector: row => row?.correspondence_835,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.correspondence_835}</span>,

        right: true,
        wrap: true,
        reorder: false,
        grow: 0,
      },
      {
        id: 'TIME',
        minWidth: '55px',
        name: 'TIME',
        selector: row => row?.correspondence_835_time,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.correspondence_835_time}</span>,

        left: true,
        wrap: true,
        reorder: false,
        grow: 0,
        omit: report_hour_count == "" ? false : true,

      },
      {
        id: 'CPH',
        name: 'CPH',
        selector: row => row?.correspondence_835_cph,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.correspondence_835_cph}</span>,
        right: true,
        wrap: true,
        reorder: false,
        grow: 0,
        minWidth: '60px',
        maxWidth: '60px',

      },
      {
        id: 'REJECTED',
        name: 'REJECTED',
        selector: row => row?.rejected_count,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.rejected_count}</span>,

        right: true,
        wrap: true,
        reorder: false,
        grow: 0,

      },
      {
        id: 'TIME',
        minWidth: '55px',
        name: 'TIME',
        selector: row => row?.rejected_time,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.rejected_time}</span>,

        left: true,
        wrap: true,
        reorder: false,
        grow: 0,
        omit: report_hour_count == "" ? false : true,

      },
      {
        id: 'JOB TOTAL',
        name: 'JOB TOTAL',
        selector: row => row?.total,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.total}</span>,

        right: true,
        wrap: true,
        reorder: false,
        grow: 0,
        omit: isTimeCount,

      },
      {
        id: 'MANUAL SPLIT',
        name: 'MANUAL SPLIT',
        selector: row => row?.file_split,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.file_split}</span>,

        right: true,
        wrap: true,
        reorder: false,
        grow: 0,
      },
      {
        id: 'TIME',
        minWidth: '55px',
        name: 'TIME',
        selector: row => row?.file_split_time,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.file_split_time}</span>,

        left: true,
        wrap: true,
        reorder: false,
        grow: 0,
        omit: report_hour_count == "" ? false : true,

      },
      {
        id: 'TOTAL TIME',
        name: 'TOTAL TIME',
        selector: row => row?.work_split_total_time,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.work_split_total_time}</span>,

        left: true,
        wrap: true,
        reorder: false,
        grow: 0,
        omit: report_hour_count == "" ? false : true,

      },
      {
        id: 'IDLE',
        name: 'IDLE',
        selector: row => row?.idle,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.idle}</span>,

        right: true,
        wrap: true,
        reorder: false,
        grow: 0,
        minWidth: '35px'
      },
      {
        id: 'IDLE TIME',
        name: 'IDLE TIME',
        selector: row => row?.idle_time,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.idle_time}</span>,

        right: true,
        wrap: true,
        reorder: false,
        grow: 0,
      },
      {
        id: '# CLAIMS',
        name: '# CLAIMS',
        selector: row => row?.claims,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.claims}</span>,

        right: true,
        wrap: true,
        reorder: false,
        grow: 0,
        omit: isTimeCount
      },

      {
        id: '# SVC LINES',
        name: '# SVC LINES',
        selector: row => row?.servicelines,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.servicelines}</span>,

        right: true,
        wrap: true,
        reorder: false,
        grow: 0,
        omit: isTimeCount

      },

      {
        id: 'JOB TOTAL',
        name: 'JOB TOTAL',
        selector: row => row?.total,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.total}</span>,

        right: true,
        wrap: true,
        reorder: false,
        grow: 0,
        omit: !isTimeCount,

      },
      {
        id: 'CPH',
        name: 'CPH',
        selector: row => row?.cph,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.cph}</span>,

        right: true,
        wrap: true,
        reorder: false,
        grow: 0,
        omit: isTimeCount,

      },


    ],

    [limit, pageno, report_hour_count, week, year, month],
  );
  const timeColumns = useMemo(
    () => [
      {
        id: 'S.NO',
        name: 'S.NO',
        selector: (row, index) => pageno == 1 ? index + 1 : (pageno - 1) * limit + index + 1,
        wrap: true,
        width: "40px",
        grow: 0.1,

      },

      {
        id: 'FILTER DATA',
        name: year ? 'YEAR' : week ? "WEEK" : month ? "MONTH" : "DATE",
        selector: row => row?.filter_data,
        cell: (row) =>

          <div>
            {row?.filter_data &&

              <div className='d-flex flex-row justify-content-between align-items-center gap-2' style={{ width: '80px' }}>
                <div className='overflow-hidden text-truncate' style={{ width: "80px" }} onClick={() => handleRowClick(row)}>
                  <Tooltip disableInteractive title={row?.filter_data} placement="top" arrow>
                    <span className="text-truncate"> {row?.filter_data}</span>
                  </Tooltip>
                </div>
              </div>

            }
          </div>
        ,


        left: true,
        wrap: true,
        reorder: false,
        grow: 0,
        expandOnRowClicked: true,
        minWidth: '80px'

      },
      {
        id: 'PAYMENT835',
        name: 'PAYMENT835',
        selector: row => row?.no_of_835,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.no_of_835}</span>,

        right: true,
        wrap: true,
        reorder: false,
        grow: 0,
        minWidth: '100px'

      },

      {
        id: 'EOB LITE/835 LITE',
        name: 'EOB LITE/835 LITE',
        selector: row => row?.no_835_lite,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.no_835_lite}</span>,

        right: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        // minWidth: '170px'

      },

      {
        id: 'CORRES',
        name: 'CORRES',
        selector: row => row?.correspondence,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.correspondence}</span>,

        right: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        // minWidth: '170px'


      },

      {
        id: 'PAT PAY',
        name: 'PAT PAY',
        selector: row => row?.patient_pay,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.patient_pay}</span>,

        right: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        // minWidth: '170px'


      },

      {
        id: 'PAT PAY 835',
        name: 'PAT PAY 835',
        selector: row => row?.patient_pay_835,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.patient_pay_835}</span>,

        right: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        // minWidth: '170px'

      },


      {
        id: 'CORRES 835',
        name: 'CORRES 835',
        selector: row => row?.correspondence_835,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.correspondence_835}</span>,

        right: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        // minWidth: '170px'

      },


      {
        id: 'MANUAL SPLIT',
        name: 'MANUAL SPLIT',
        selector: row => row?.file_split,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.file_split}</span>,

        right: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        // minWidth: '170px'

      },



      {
        id: 'JOB TOTAL',
        name: 'JOB TOTAL',
        selector: row => row?.total,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.total}</span>,

        right: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        // minWidth: '170px'


      },
      {
        id: 'REJECTED',
        name: 'REJECTED',
        selector: row => row?.rejected_time,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.rejected_time}</span>,
        right: true,
        wrap: true,
        reorder: false,


      },
      {
        id: 'IDLE',
        name: 'IDLE',
        selector: row => row?.idle,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.idle}</span>,

        right: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        // minWidth: '170px'

      },





    ],

    [limit, pageno, report_hour_count, week, year, month],
  );
  const countColumns = useMemo(
    () => [
      {
        id: 'S.NO',
        name: 'S.NO',
        selector: (row, index) => pageno == 1 ? index + 1 : (pageno - 1) * limit + index + 1,
        wrap: true,
        width: "40px",
        grow: 0,

      },

      {
        id: 'FILTER DATA',
        name: year ? 'YEAR' : week ? "WEEK" : month ? "MONTH" : "DATE",
        selector: row => row?.filter_data,
        cell: (row) =>

          <div>
            {row?.filter_data &&

              <div className='d-flex flex-row justify-content-between align-items-center gap-2' style={{ width: '80px' }}>
                <div className='overflow-hidden text-truncate' style={{ width: "80px" }} onClick={() => handleRowClick(row)}>
                  <Tooltip disableInteractive title={row?.filter_data} placement="top" arrow>
                    <span className="text-truncate"> {row?.filter_data}</span>
                  </Tooltip>
                </div>
              </div>

            }
          </div>
        ,


        left: true,
        wrap: true,
        reorder: false,
        grow: 0,
        expandOnRowClicked: true,
        minWidth: '80px'
      },
      {
        id: 'PAYMENT835',
        name: 'PAYMENT835',
        selector: row => row?.no_of_835,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.no_of_835}</span>,

        right: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        minWidth: '55px',


      },
      {
        id: 'CPH',
        name: 'CPH',
        selector: row => row?.no_of_835_cph,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.no_of_835_cph}</span>,
        right: true,
        wrap: true,
        reorder: false,
        minWidth: '55px',


      },

      {
        id: 'EOB LITE/835 LITE',
        name: 'EOB LITE/835 LITE',
        selector: row => row?.no_835_lite,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.no_835_lite}</span>,

        right: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        minWidth: '55px',
        // minWidth: '100px'

      },
      {
        id: 'CPH',
        name: 'CPH',
        selector: row => row?.no_835_lite_cph,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.no_835_lite_cph}</span>,
        right: true,
        wrap: true,
        reorder: false,
        minWidth: '55px',


      },

      {
        id: 'CORRES',
        name: 'CORRES',
        selector: row => row?.correspondence,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.correspondence}</span>,

        right: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        minWidth: '55px',
        // minWidth: '100px'


      },
      {
        id: 'CPH',
        name: 'CPH',
        selector: row => row?.correspondence_cph,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.correspondence_cph}</span>,
        right: true,
        wrap: true,
        reorder: false,
        minWidth: '55px'

      },
      {
        id: 'PAT PAY',
        name: 'PAT PAY',
        selector: row => row?.patient_pay,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.patient_pay}</span>,

        right: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        minWidth: '55px',
        // minWidth: '100px'


      },
      {
        id: 'CPH',
        name: 'CPH',
        selector: row => row?.patient_pay_cph,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.patient_pay_cph}</span>,
        right: true,
        wrap: true,
        reorder: false,
        minWidth: '55px'

      },

      {
        id: 'PAT PAY 835',
        name: 'PAT PAY 835',
        selector: row => row?.patient_pay_835,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.patient_pay_835}</span>,

        right: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        minWidth: '55px',
        // minWidth: '100px'

      },
      {
        id: 'CPH',
        name: 'CPH',
        selector: row => row?.patient_pay_835_cph,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.patient_pay_835_cph}</span>,
        right: true,
        wrap: true,
        reorder: false,
        minWidth: '55px'

      },

      {
        id: 'CORRES 835',
        name: 'CORRES 835',
        selector: row => row?.correspondence_835,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.correspondence_835}</span>,

        right: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        minWidth: '55px',
        // minWidth: '100px'

      },
      {
        id: 'CPH',
        name: 'CPH',
        selector: row => row?.correspondence_835_cph,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.correspondence_835_cph}</span>,
        right: true,
        wrap: true,
        reorder: false,
        minWidth: '55px'

      },
      {
        id: 'REJECTED',
        name: 'REJECTED',
        selector: row => row?.rejected_count,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.rejected_count}</span>,

        right: true,
        wrap: true,
        reorder: false,
        minWdth: '50px',
        maxWidth: '50px',
      },
      {
        id: 'JOB TOTAL',
        name: 'JOB TOTAL',
        selector: row => row?.total,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.total}</span>,

        right: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        minWidth: '55px',
        // minWidth: '100px'

      },
      {
        id: 'MANUAL SPLIT',
        name: 'MANUAL SPLIT',
        selector: row => row?.file_split,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.file_split}</span>,

        right: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        minWidth: '55px',
        // minWidth: '100px'

      },


      {
        id: 'IDLE',
        name: 'IDLE',
        selector: row => row?.idle,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.idle}</span>,

        right: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        minWidth: '55px',
        // minWidth: '100px'

      },


      {
        id: '# CLAIMS',
        name: '# CLAIMS',
        selector: row => row?.claims,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.claims}</span>,

        right: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        minWidth: '55px',
        // minWidth: '100px'

      },

      {
        id: '# SVC LINES',
        name: '# SVC LINES',
        selector: row => row?.servicelines,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.servicelines}</span>,

        right: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        minWidth: '55px',
        // minWidth: '100px'


      },
      {
        id: 'CPH',
        name: 'CPH',
        selector: row => row?.cph,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.cph}</span>,

        right: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        minWidth: '55px',
        // minWidth: '100px'


      },


    ],

    [limit, pageno, report_hour_count, week, year, month],
  );
  const conditionalRowStyles = [

    {
      when: row => colorRow.filter_data == row.filter_data ? true : false,
      style: (row, index) => ({
        backgroundColor: colorRow?.filter_data == row.filter_data ? '#e4e6ef' : '#f2f2f2',
        color: 'black',
        // fontWeight: '550'
      }),
    },


  ];
  const handleRowClick = (row) => {
    setColorRow(row);
  };

  console.log(colorRow, "################$$$%");
  const searchFormik = useFormik({
    initialValues: {
      search: "",
      job_status: report_job_status,
      station: report_station,
      date_range: report_date_range,
      hour_count: report_hour_count

    },
    onSubmit: (values) => {
      console.log(values);
      searchData(values)

      setSearchedData({
        search: values?.search ? values?.search : "",
        job_status: values?.job_status ? values?.job_status : "",
        station: values?.station ? values?.station : "",
        date_range: values?.date_range ? values?.date_range : "",
        hour_count: values?.hour_count ? values?.hour_count : "",
      })
      handleResetDataTable()
    }
  })

  const [datetime, setDateTime] = useState([]);

  const searchData = ({ station, job_status, date_range, hour_count }) => {
    fetchData(1, limit, station, selectValue, datetime, hour_count)
  }

  const showTooltip = (text, props) => (
    <Tooltip id="button-tooltip" {...props}>
      {text}
    </Tooltip>
  );

  const handleResetDataTable = () => {
    setRerenderDataTable(true)
    // setTimeout(() => {
    //   setRerenderDataTable(false)
    // }, 200);
  }
  const [loader, setLoader] = useState(false);

  const handleDownload = () => {
    setLoader(true)
    downloadUserReportDetails(1, limit, report_station, report_job_status, report_date_range, report_hour_count, user, day, year, week, month, clients, facilities, client_id, facility_id).then((response) => {
      setLoader(false)
      console.log(response);
      downloadXl(response?.data?.export_file)
    });
  }
  useEffect(() => {
    fetchData(1, limit, report_station, report_job_status, report_date_range, report_hour_count, user, day, year, week, month);
  }, [])

  console.log(year,
    week,
    month);
  return (
    <React.Fragment>
      <section className="section">
        <div className="row align-items-top py-2 gap-2 gap-md-5">
          <div className="col-lg-12">
            <div className="card card_background">

              <Form onSubmit={searchFormik.handleSubmit} >
                <div className="d-flex justify-content-end" style={{ height: "20px" }}>
                  <div className="px-3 pt-1 d-flex flex-row justify-content-start gap-2 align-items-center flex-wrap">

                    <div>
                      <Tooltip disableInteractive title={"Year"} placement="top" arrow>

                        <div class="form-check">
                          <input class="form-check-input me-1" type="radio" onChange={() => handleDetailFilterChange('year')} checked={year} name="year" id="year1" />
                          <label class="form-check-label" for="year1" style={{ fontSize: "14px", fontWeight: "600" }}>
                            Year
                          </label>
                        </div>
                      </Tooltip>

                    </div>
                    <div>
                      <Tooltip disableInteractive title={"Month"} placement="top" arrow>

                        <div class="form-check">
                          <input class="form-check-input me-1" type="radio" onChange={() => handleDetailFilterChange('month')} checked={month} name="month" id="month2" />
                          <label class="form-check-label" for="month2" style={{ fontSize: "14px", fontWeight: "600" }}>
                            Month
                          </label>
                        </div>
                      </Tooltip>

                    </div>


                    <div>
                      <Tooltip disableInteractive title={"Week"} placement="top" arrow>

                        <div class="form-check">
                          <input class="form-check-input me-1" onChange={() => handleDetailFilterChange('week')} checked={week} type="radio" name="week" id="week3" />
                          <label class="form-check-label" for="week3" style={{ fontSize: "14px", fontWeight: "600" }}>
                            Week
                          </label>
                        </div>
                      </Tooltip>

                    </div>
                    <div>
                      <Tooltip disableInteractive title={"Day"} placement="top" arrow>

                        <div class="form-check">
                          <input class="form-check-input me-1" onChange={() => handleDetailFilterChange('day')} checked={day} type="radio" name="flexRadioDefault" id="flexRadioDefault4" />
                          <label class="form-check-label" for="flexRadioDefault4" style={{ fontSize: "14px", fontWeight: "600" }}>
                            Day
                          </label>
                        </div>
                      </Tooltip>

                    </div>
                  </div>
                  {isHavePermission("detail_export_user_report_management") &&
                    <div className="px-3 pt-1 d-flex flex-row justify-content-start gap-2 align-items-center flex-wrap">

                      <div>
                        <Tooltip disableInteractive title={"Download"} placement="top" arrow>
                          {!loader ?

                            <span style={{ cursor: "pointer" }} className="svg-icon svg-icon-3" onClick={() => { handleDownload() }}>
                              <BsCloudDownloadFill color="#1ba1a7" />
                            </span>
                            :
                            <span className="svg-icon svg-icon-3">
                              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            </span>
                          }

                        </Tooltip>

                      </div>
                    </div>
                  }
                </div>

              </Form>


              <div className="card-body p-common">
                <div className="table-responsive">
                  <div className="dashboardtable custom_background">
                    {/* {!reRenderDataTable && */}
                    <DataTable
                      // className="custom-datatable-details"
                      paginationDefaultPage={pageno}

                      fixedHeader={true}
                      progressPending={loading}
                      data={data}
                      columns={report_hour_count === "false" ? timeColumns : report_hour_count === "true" ? countColumns : columns}
                      pointerOnHover={true}
                      pagination
                      paginationServer
                      // highlightOnHover
                      paginationTotalRows={totalRows}
                      paginationPerPage={limit}
                      paginationRowsPerPageOptions={[25, 50, 100, 150, 200]}
                      onChangeRowsPerPage={handlePerRowsChange}
                      onChangePage={handlePageChange}
                      conditionalRowStyles={conditionalRowStyles}
                      theme="detail_solarized"
                      customStyles={DetailCutomRowStyle}
                      // striped={true}
                      // expandableRows
                      // expandOnRowClicked={false}
                      // expandableRowsHideExpander
                      onRowClicked={handleRowClick}

                    />
                    {/* } */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment >
  )
}
export default UserReportDetails




