import React, { useEffect, useState } from 'react'
import newicon from "../../../../../assets/img/new-icon.svg";
import addicon from "../../../../../assets/img/add-icon.svg";
import claimicon from "../../../../../assets/img/claims-icon.svg";
import checkview from "../../../../../assets/img/Check-View.png";
import clear from "../../../../../assets/img/clear-icon.svg";
import styled from 'styled-components';
import { Form, Modal, Overlay, Popover, InputGroup } from 'react-bootstrap';
import CheckViewModal from './checkViewModal';
import FileInfoModal from './fileInfoModal';
import { TiArrowBack } from 'react-icons/ti';
import { Formik, FormikProvider } from 'formik';
import { useRef } from 'react';
import moment from 'moment';
import Select from 'react-select';
import { BsDot } from 'react-icons/bs';
import { handleWheel } from "../gridFunctions";
import { DATA_ENTRY, EDIT_WORK, QA_REVIEW } from '../../../../../functions';

const TextAreaField = styled(Overlay)`
    height: 32px;
    width: 200px;
    &:hover {
        cursor: pointer;
    }
    `;

const GridHeader = (props) => {


    const {
        state,
        setState,
        setControlledPosition,
        setIsDrag,
        jobData,
        payeeLookup,
        claim835,
        setClaim835,
        payerLookup,
        payeeLiteLookup,
        payerLiteLookup,
        patientLookup,
        handleAddPlbAdjustment,
        handleLiteAddPlbAdjustment,

        __payment835Handler,
        __payment835LiteHandler,
        __patientPayHandler,
        __correspondenceHandler,

        rejectRef,
        fileUrl,
        totalPages,
        rotatePosition,
        checkNum,

        formPartial,
        partialSave,
        setPartialSave,
        setPartialSavePopUp,
        partialSavePopUp,
        setRejectPopup,
        rejectPopup,
        rejectLabel,
        setRejectLabel,
        handleRejectFormClose,
        handlePartialFormClose,
        // handlePartialTransactionType,
        formEob,
        formPP,
        eobLiteFormik,
        rejectForm,
        formCorres,
        cleareCorresPondanceFocus,
        clearEob835Form,
        cleareEobFocus,
        cleareEobLiteFocus,
        clearePatientPayFocus,

        assignTooltip,
        setAssignTooltip,
        userStatus,
        setUserStatus,
        assignTo,
        setAssignTo,
        assignReason,
        setAssignReason,
        reAssignUser,
        setClaimCorresIndex,
        handleEobLite835Data,
        setClaimLiteIndex,
        claim835Index,
        setClaim835Index,
        setQdxTransaction,
        qdxTransaction,
        isQdx,
        formReassign,
        formReassignMulty,
        clearServiceLines,
        handleRemainingBalanceCalculation,
        isCheckNumber,
        setIsCheckNumber,

        isCorres835,
        isPP835,

        isSubJob,
        isFirstJob,
        jobUserData,
        subJobOptions,
        selectedSubJob,
        setSelectedSubJob,
        job_id,
        isGeneratedCheckNumber,
        isOverallPage,
        pdfLoaded,
        overallPage,
        setOverallPage,
        currentPage,
        aditionalInfoFormik,
        setIsIncomplete,
        isIncomplete,
        log_id,
    } = props;

    const isViewOnllyGrid = true
    const clearCurrentForm = async () => {

    }

    const [showCheckView, setShowCheckView] = useState(false)
    const [infoModal, setInfoModal] = useState(false)
    // const [qdxTransaction, setQdxTransaction] = useState('Payment835')
    const closeClaim = () => {
        setClaim835(false)
        setState({
            ...state,
            updateDisable: !state.updateDisable,
        })
        if (state.Payment835) {
            if (qdxTransaction != "PATIENT_PAY") {

                setControlledPosition({ x: 0, y: 560 });
            }
            else {
                setControlledPosition({ x: 0, y: 650 });
            }
        }
        else {
            setControlledPosition({ x: 0, y: 700 });
        }

    }

    const showAssignTooltip = () => {


    }

    const assignRef = useRef(null);


    const handleQdxTransaction = async () => {


    }


    const handleQdxPatientPay = async () => {



    }

    const handleQdxPayment835 = async () => {


    }
    // const [selectedSubJob, setSelectedSubJob] = useState({})


    const handleChangeOverAllFrom = (e) => {

    };
    const handleChangeOverAllTo = (e) => {

    };

    const handleChangeFrom = (e) => {

    };

    const handleChangeTo = (e) => {

    };

    const changeUserAssignStatus = (val, index) => {

    }

    const pathname = window.location.pathname;
    const isPlbOnllyActive = state.Payment835Lite && eobLiteFormik?.values?.grid_customization != 'PLB_ONLY' ? true : state.Payment835 && formEob?.values?.grid_customization != 'PLB_ONLY' ? true : false

    return (
        <React.Fragment>
            <div className={payeeLookup && payerLookup && payeeLiteLookup && payerLiteLookup && patientLookup ? "" : "healthcare"}>
                <div className={payeeLookup && payerLookup && payeeLiteLookup && payerLiteLookup && patientLookup ? "" : "healthcare-top"}>
                    <div className={payeeLookup && payerLookup && payeeLiteLookup && payerLiteLookup && patientLookup ? "" : "leftside-block"}>
                        {!claim835 && !payeeLookup && !payerLookup && !payeeLiteLookup && !payerLiteLookup && !patientLookup ? (
                            <React.Fragment>
                                {!claim835 &&
                                    <React.Fragment>
                                        <div className="accordion" id="payment835">
                                            <button type="button" tabIndex="-1" className="btn btn-healthcare accordion-icon-top" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"></button>
                                        </div>
                                        <div className="dropdown" >
                                            <button type="button" tabIndex="-1" className="btn btn-healthcare" data-bs-toggle="dropdown"> <img src={newicon} alt="" /> Transaction Type</button>
                                            <ul className={"dropdown-menu"} >
                                                {jobData?.document_type && jobData?.document_type?.eob &&
                                                    <li><button disabled={true} tabIndex="-1" className={state.Payment835 ? "dropdown-item active" : jobData?.is_large_batch_job && !isFirstJob ? "dropdown-item disabled disabled-typography" : "dropdown-item"} onClick={__payment835Handler}>Payment835</button></li>
                                                }
                                                {!jobData?.is_large_batch_job &&
                                                    <React.Fragment>

                                                        {jobData?.document_type && jobData?.document_type?.eob_lite &&
                                                            <li><button disabled={true} tabIndex="-1" className={state.Payment835Lite ? "dropdown-item active" : jobData?.is_large_batch_job && !isFirstJob ? "dropdown-item disabled disabled-typography" : "dropdown-item "} onClick={__payment835LiteHandler}>Payment835 Lite</button></li>
                                                        }
                                                        {jobData?.document_type && jobData?.document_type?.patient_pay &&
                                                            <li><button disabled={true} tabIndex="-1" className={state.PatientPay ? "dropdown-item active" : jobData?.is_large_batch_job && !isFirstJob ? "dropdown-item disabled disabled-typography" : "dropdown-item "} onClick={__patientPayHandler}>Patient Pay</button></li>
                                                        }
                                                    </React.Fragment>
                                                }
                                                {jobData?.document_type && jobData?.document_type?.correspondence &&
                                                    <li><button disabled={true} tabIndex="-1" className={state.Correspondence ? "dropdown-item active" : jobData?.is_large_batch_job && !isFirstJob ? "dropdown-item disabled disabled-typography" : "dropdown-item "} onClick={__correspondenceHandler}>Correspondence</button></li>
                                                }
                                            </ul>
                                        </div>
                                    </React.Fragment>
                                }
                                <div className="">
                                    <button type="button"
                                        tabIndex="-1"
                                        // onClick={__handleResetForm}
                                        onClick={() => { clearCurrentForm(); localStorage.setItem('clear', true) }}
                                        className="btn btn-healthcare"> <img src={clear} alt="" className="clear" />Clear</button>
                                </div>
                            </React.Fragment>
                        ) : payeeLookup && payerLookup && patientLookup ? (
                            <React.Fragment></React.Fragment>
                        ) : !payeeLookup && !payerLookup && !payeeLiteLookup && !payerLiteLookup && !patientLookup && (
                            <React.Fragment>

                                <div className="accordion" id="claimSummary" >
                                    <button disabled type="button" tabIndex="-1" className="btn btn-healthcare accordion-claim" data-bs-toggle="collapse" data-bs-target="#claimssummary" aria-expanded="true" aria-controls="claimssummary">Claim Summary</button>
                                </div>

                                <div className="">
                                    <div type="button" tabIndex="-1" className="btn btn-healthcare" onClick={() => closeClaim()}> <TiArrowBack /> Back</div>
                                </div>
                            </React.Fragment>
                        )
                        }

                        {state.Payment835 && !claim835 && !payerLookup && !payeeLiteLookup && !payerLiteLookup && !payeeLookup && !patientLookup &&
                            <div className="">
                                <button type="button" tabIndex="-1" className="btn btn-healthcare" onClick={handleAddPlbAdjustment}>  <img src={addicon} alt="add" /> PLB Adjustment</button>
                            </div>
                        }
                        {state.Payment835Lite && !claim835 && !payerLookup && !payeeLiteLookup && !payerLiteLookup && !payeeLookup && !patientLookup &&
                            <div className="">
                                <button type="button" tabIndex="-1" className="btn btn-healthcare" onClick={handleLiteAddPlbAdjustment}>  <img src={addicon} alt="add" /> PLB Adjustment</button>
                            </div>
                        }
                        {state.Payment835 | state.Payment835Lite && !claim835 && !payerLookup && !payeeLiteLookup && !payerLiteLookup && !patientLookup && !payeeLookup && isPlbOnllyActive
                            ? (
                                <React.Fragment>

                                    <div className="">
                                        <button type="button" tabIndex="-1" className="exclude-disabled-new btn btn-healthcare" onClick={() => { setClaim835(true); if (qdxTransaction != "PATIENT_PAY") { setControlledPosition({ x: 0, y: 560 }); } else { setControlledPosition({ x: 0, y: 710 }); } }}> <img src={claimicon} alt="claim" /> Claims</button>
                                    </div>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    {/* just a null fragment */}
                                </React.Fragment>
                            )
                        }
                        <React.Fragment>
                            {(state.Payment835 || state.Payment835Lite) && jobData?.client_level_customization?.is_grid_customzation_enable == true && !claim835 && !payerLookup && !payeeLiteLookup && !payerLiteLookup && !payeeLookup && !patientLookup ? (

                                <div className="dropdown">
                                    <button disabled={isViewOnllyGrid} type="button" tabIndex="-1" className="btn btn-checkview" data-bs-toggle="dropdown">{
                                        state.Payment835 && formEob?.values?.grid_customization == 'PLB_ONLY' ? "PLB ONLY" :
                                            state.Payment835Lite && eobLiteFormik?.values?.grid_customization == 'PLB_ONLY' ? "PLB ONLY"
                                                :
                                                "NORMAL"

                                    }</button>

                                </div>
                            ) : (
                                <React.Fragment></React.Fragment>
                            )}
                        </React.Fragment>
                        {state.Payment835 && !claim835 && !payerLookup && !payeeLiteLookup && !payerLiteLookup && !payeeLookup && !patientLookup &&

                            <React.Fragment>
                                {isCorres835 || isPP835 ? (

                                    <div className="dropdown">
                                        <button disabled={isViewOnllyGrid} type="button" tabIndex="-1" className="btn btn-checkview" data-bs-toggle="dropdown">{qdxTransaction == 'PATIENT_PAY' ? 'PATIENT PAY' : qdxTransaction}</button>
                                        <ul className="dropdown-menu">
                                            <li><button tabIndex="-1" disabled={true} className={qdxTransaction == 'PAYMENT835' && jobData?.is_large_batch_job && isFirstJob ? "dropdown-item active" : qdxTransaction != 'PAYMENT835' && jobData?.is_large_batch_job && !isFirstJob ? "dropdown-item disabled disabled-typography" : "dropdown-item "} onClick={() => { handleQdxPayment835() }}>Payment835</button></li>

                                            {isPP835 &&
                                                <li><button tabIndex="-1" disabled={true} className={qdxTransaction == 'PATIENT_PAY' && jobData?.is_large_batch_job && isFirstJob ? "dropdown-item active" : qdxTransaction != 'PATIENT_PAY' && jobData?.is_large_batch_job && !isFirstJob ? "dropdown-item disabled disabled-typography" : "dropdown-item "} onClick={() => { handleQdxPatientPay() }}>Patient Pay</button></li>
                                            }

                                            {isCorres835 &&

                                                <li><button tabIndex="-1" disabled={true} className={qdxTransaction == 'CORRESPONDENCE' && jobData?.is_large_batch_job && isFirstJob ? "dropdown-item active" : qdxTransaction != 'CORRESPONDENCE' && jobData?.is_large_batch_job && !isFirstJob ? "dropdown-item disabled disabled-typography" : "dropdown-item "} onClick={() => { handleQdxTransaction() }}>Correspondence</button></li>
                                            }
                                        </ul>
                                    </div>
                                ) : (
                                    <React.Fragment></React.Fragment>
                                )}
                            </React.Fragment>
                        }

                        {isOverallPage && !claim835 && !payerLookup && !payeeLiteLookup && !payerLiteLookup && !payeeLookup && !patientLookup &&

                            <div className="gap-0 d-flex flex-row">
                                <InputGroup className="overall-from ">
                                    <InputGroup.Text className="fs-7 text-capitalize" >Overall Page From</InputGroup.Text>
                                    <Form.Control
                                        type="number"
                                        min={1}
                                        // onBlur={handleCurrentPageChange}
                                        // onClick={handleChangeFrom}
                                        data-disabled={!pdfLoaded}
                                        onChange={(e) => handleChangeOverAllFrom(e)}
                                        onWheel={handleWheel}
                                        // onFocus={() => setClaim835Focus(true)}
                                        onClick={(e) => {
                                            handleChangeFrom(e);
                                        }}
                                        onFocus={(e) => {
                                            handleChangeFrom(e);
                                        }}
                                        className={
                                            "pagefrom "}
                                        value={overallPage?.from}

                                    />
                                </InputGroup>
                                <InputGroup className="overall-from ">
                                    <InputGroup.Text className="fs-7 text-capitalize" >To</InputGroup.Text>
                                    <Form.Control
                                        type="number"
                                        min={1}
                                        // onBlur={handleCurrentPageChange}
                                        // onClick={handleChangeFrom}
                                        data-disabled={!pdfLoaded}
                                        onChange={(e) => handleChangeOverAllTo(e)}
                                        onWheel={handleWheel}
                                        // onFocus={() => setClaim835Focus(true)}
                                        onClick={(e) => {
                                            handleChangeTo(e);
                                        }}
                                        onFocus={(e) => {
                                            handleChangeTo(e);
                                        }}
                                        value={overallPage?.to}
                                        className={
                                            "pageto  "}

                                    />
                                </InputGroup>
                            </div>

                        }
                    </div>
                    {!payerLookup && !payeeLookup && !patientLookup && !payeeLiteLookup && !payerLiteLookup &&
                        <div className="rightside-block">


                            {!state.Correspondence &&
                                <div className="" style={{ marginBottom: "2px" }}>
                                    <button data-check-view={!checkNum || checkNum == 0 ? true : checkNum > totalPages ? true : false} disabled={!checkNum || checkNum == 0 ? true : checkNum > totalPages ? true : false} type="button" tabIndex="-1" className="exclude-disabled-new btn btn-checkview"
                                        onClick={() => { setShowCheckView(true); setIsDrag(false) }}
                                    >Check View</button>
                                </div>
                            }

                            {/* Check View Modal Start */}
                            <CheckViewModal fileUrl={fileUrl} initialPage={checkNum} setShowCheckView={setShowCheckView} showCheckView={showCheckView} rotatePosition={rotatePosition} setIsDrag={setIsDrag} />
                            {/* Check View Modal Start */}

                            <div className="" style={{ marginBottom: "2px" }}>
                                <div type="button" tabIndex="-1" className="" onClick={() => { setInfoModal(true); setIsDrag(false) }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#ffffff" className="bi bi-info-circle" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                        <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                                    </svg>
                                </div>
                            </div>

                            {/* File Info Modal Start */}
                            <FileInfoModal jobData={jobData} infoModal={infoModal} setInfoModal={setInfoModal} file_info={jobData?.file_info} totalPages={totalPages} setIsDrag={setIsDrag} />
                            {/* File Info Modal End */}

                        </div>
                    }
                </div>
            </div>
        </React.Fragment>
    )
}

export default GridHeader