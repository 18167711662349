import React, { Suspense, useEffect } from 'react'
import { Route, Routes as Switch, useNavigate } from "react-router-dom";
import Dashboard from '../pages/Dashboard';
import UserReport from '../pages/UserReport/UserReport';
import { useSelector } from 'react-redux';
import { selectIsLogin } from '../../slice/user/userSlice';
import ListGroups from '../pages/Groups/ListGroups';
import CreateUpdateGroup from '../pages/Groups/CreateUpdateGroup';
import ListRoles from '../pages/Roles/ListRoles';
import CreateUpdateRole from '../pages/Roles/CreateUpdateRole';
import ListUsers from '../pages/Users/ListUsers';
import CreateUpdateUser from '../pages/Users/CreateUpdateUser';
import ClientReport from '../pages/ClientReport/ClientReport';
import JobReport from '../pages/JobReport/JobReport';
import PayerReport from '../pages/PayerReport/PayerReport';
import FileReport from '../pages/FileReport/FileReport';
import FileManagement from '../pages/FileManagement/FileManagement';
import QaErrorReport from '../pages/QaErrorReport/QaErrorReport';
import InvoiceReport from '../pages/InvoiceReport/InvoiceReport';
import { AuthService } from '../../services/AuthService';
import DataList from '../pages/DataList';
import ViewWork from '../pages/DataEntry/ViewWork';
import Swal from 'sweetalert2';

// const Dashboard = lazy(() => import("../pages/Dashboard"));
// const ListGroups = lazy(() => import("../pages/Groups/ListGroups"));
// const CreateUpdateGroup = lazy(() => import("../pages/Groups/CreateUpdateGroup"));
// const ListRoles = lazy(() => import("../pages/Roles/ListRoles"));
// const CreateUpdateRole = lazy(() => import("../pages/Roles/CreateUpdateRole"));
// const ListUsers = lazy(() => import("../pages/Users/ListUsers"));
// const CreateUpdateUser = lazy(() => import("../pages/Users/CreateUpdateUser"));
// const FileReport = lazy(() => import("../pages/FileReport/FileReport"));
// const PayerReport = lazy(() => import("../pages/PayerReport/PayerReport"));
// const JobReport = lazy(() => import("../pages/JobReport/JobReport"));
// const ClientReport = lazy(() => import("../pages/ClientReport/ClientReport"));
// const UserReport = lazy(() => import("../pages/UserReport/UserReport"));

const AdminPane = () => {
    const logged = useSelector(selectIsLogin);
    const authService = new AuthService();
    const { keepAliveApi } = authService
    const navigate = useNavigate();
    useEffect(() => {
        if (!logged && window.location.pathname !== '/login') {
            // navigate('/login')
            Swal.fire({
                icon: 'error',
                confirmButtonColor: '#1ba1a6',
                title: 'Session Timeout',
                customClass: {
                    title: 'swal-titleText'
                }
                // text: 'Session',
            }).then((result) => {
                localStorage.clear();
                window.location = "/login";

            })
        }
    }, [logged, navigate])

    useEffect(() => {
        let alive = false
        let minute = 1;

        let timeout = setInterval(() => {
            console.log(alive);
            if (alive) {
                keepAliveApi().then((response) => {
                    if (response?.data) {
                        alive = false
                        minute = 1
                    }
                })
            }
            else {
                minute = minute + 1
            }
            console.log(minute);
            // if(minute > process.env.REACT_APP_TIMEOUT_MINUTE){

            //     // Swal.fire({
            //     //     icon: 'error',
            //     //     confirmButtonColor : '#1ba1a6',
            //     //     title: 'Session Timeout',
            //     //     customClass: {
            //     //       title: 'swal-titleText'
            //     //     }
            //     //     // text: 'Session',
            //     //   }).then((result) => {


            //     //     localStorage.clear();
            //     //     window.location = "/";

            //     //   })
            // }
        }, 60000);


        const resetTimer = () => {
            if (!alive) {
                alive = true
            }

        };

        // Add event listeners to reset the timer on user interaction
        window.addEventListener('mousemove', resetTimer);
        window.addEventListener('keydown', resetTimer);
        window.addEventListener('click', resetTimer);
        window.addEventListener('scroll', resetTimer);

        // Clear the timer and remove event listeners when the component unmounts
        return () => {
            clearInterval(timeout);
            window.removeEventListener('mousemove', resetTimer);
            window.removeEventListener('keydown', resetTimer);
            window.removeEventListener('click', resetTimer);
            window.removeEventListener('scroll', resetTimer);
        };
    }, [window.location.pathname]);

    return (
        <Suspense
            fallback={
                <div style={{ height: '100vh' }}>
                    Loading
                </div>
            }
        >

            <Switch>
                <Route index exact path='/' element={<Dashboard />} />
                {/* ======== Report Start========= */}
                <Route exact path='/data-list' element={<DataList />} />
                <Route exact path='/view-work/:job_id' element={<ViewWork />} />
                <Route exact path='/user-report' element={<UserReport />} />
                <Route exact path='/client-report' element={<ClientReport />} />
                <Route exact path='/job-report' element={<JobReport />} />
                <Route exact path='/payer-report' element={<PayerReport />} />
                <Route exact path='/file-report' element={<FileReport />} />
                <Route exact path='/file-management' element={<FileManagement />} />
                <Route exact path='/qa-error-report' element={<QaErrorReport />} />
                <Route exact path='/invoice-report' element={<InvoiceReport />} />

                {/* ======== Report End========= */}
                <Route exact path='/list-groups' element={<ListGroups />} />
                <Route exact path='/create-group' element={<CreateUpdateGroup />} />
                <Route exact path='/edit-group/:group_id' element={<CreateUpdateGroup />} />
                <Route exact path='/list-roles' element={<ListRoles />} />
                <Route exact path='/create-role' element={<CreateUpdateRole />} />
                <Route exact path='/edit-role/:role_id' element={<CreateUpdateRole />} />
                <Route exact path='/list-users' element={<ListUsers />} />
                <Route exact path='/create-user' element={<CreateUpdateUser />} />
                <Route exact path='/edit-user/:user_id' element={<CreateUpdateUser />} />

            </Switch>
        </Suspense>
    )
}

export default AdminPane