import React from 'react';
import { useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Toast, ToastContainer } from 'react-bootstrap';
import { useEffect } from 'react';


const DeleteSweetAlert = ({ handleDelete, isSwal, setIsSwal }) => {
    const [isSwalComplete, setIsSwalComplete] = useState(false)
    useEffect(() => {
        if (isSwal.show == 2) {
            const timer = setTimeout(() => {
                setIsSwal({ ...isSwal, show: false })
            }, [1500]);
            return () => clearTimeout(timer)
        }
    }, [isSwal.show])
    return (
        <React.Fragment>
            {/* <ToastContainer className="p-3" position={"top-end"}>
                    <Toast bg="success" show={isSwal.show==2?true:false} animation={true} delay={2000} autohide>

                        <Toast.Body ><span className='text-white'>Deleted SuccessFully</span></Toast.Body>
                    </Toast>
                </ToastContainer> */}
            {isSwal.show === true &&
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Ok"
                    cancelBtnText="Cancel"
                    // cancelBtnBsStyle="danger"
                    confirmBtnCssClass="custom-confirm-button-class"
                    cancelBtnCssClass="custom-cancel-button-class"
                    title="Are you sure?"
                    onConfirm={() => { handleDelete(isSwal.id) }}
                    onCancel={() => { setIsSwal({ ...isSwal, show: false }) }}
                    focusCancelBtn
                >
                </SweetAlert>
                // : isSwal.show ===2 ?

                //     <SweetAlert success title="Good job!"
                //         onConfirm={() => { setIsSwal({...isSwal,show:false}) }}
                //         onCancel={() => {setIsSwal({...isSwal,show:false}) }}
                //     >
                //         You clicked the button!
                //     </SweetAlert>


                //     : null
            }
        </React.Fragment>
    )
}

export default DeleteSweetAlert