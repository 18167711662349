import React, { useState } from "react";
import DataTable from "react-data-table-component";

import { useMemo } from "react";
import { Form } from "react-bootstrap";
import { useFormik } from "formik";
import { QaErrorReportService } from "../../../services/QaErrorReportService";
import { BsCloudDownloadFill } from "react-icons/bs";
import { downloadXl } from "../../../functions";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectIsSuperUser, selectPermissions } from "../../../slice/user/userSlice";
import { Tooltip } from "@mui/material";
import DetailCutomRowStyle from "../../components/DetailCutomRowStyle";



function QaErrorReportDetails(params) {
  const { report_station, report_job_status, report_date_range, report_hour_count, user, clients, facilities, client_id, facility_id } = params

  const [reRenderDataTable, setRerenderDataTable] = useState(false)
  const [pageno, setPageNo] = useState(1);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(100);
  const [selectValue, setSelectValue] = useState('');
  const [colorRow, setColorRow] = React.useState({});
  const [data, setData] = useState([])
  const [day, setDay] = useState(true)
  const [year, setYear] = useState(false)
  const [week, setWeek] = useState(false)
  const [month, setMonth] = useState(false)
  const [limit, setLimit] = useState(100)

  const permissions = useSelector(selectPermissions);
  const isSuperUser = useSelector(selectIsSuperUser);
  const isHavePermission = (name) => {
    return permissions?.includes(name) || isSuperUser
  }
  const qaErrorReportService = new QaErrorReportService();
  const { getQaErrorReportDetails, downloadQaErrorReportDetails } = qaErrorReportService


  const handleDetailFilterChange = (name) => {
    if (name === 'year') {
      setYear(true)
      setWeek(false)
      setMonth(false)
      setDay(false)
      fetchData(1, limit, report_station, report_job_status, report_date_range, report_hour_count, user, 'false', 'true', 'false', 'false');

    }
    else if (name === 'day') {
      setYear(false)
      setWeek(false)
      setMonth(false)
      setDay(true)
      fetchData(1, limit, report_station, report_job_status, report_date_range, report_hour_count, user, 'true', 'false', 'false', 'false');

    }
    else if (name === 'month') {
      setYear(false)
      setWeek(false)
      setMonth(true)
      setDay(false)
      fetchData(1, limit, report_station, report_job_status, report_date_range, report_hour_count, user, 'false', 'false', 'false', 'true');

    }
    else if (name === 'week') {
      setYear(false)
      setWeek(true)
      setMonth(false)
      setDay(false)
      fetchData(1, limit, report_station, report_job_status, report_date_range, report_hour_count, user, 'false', 'false', 'true', 'false');

    }

  }
  const handlePerRowsChange = async (newPerPage, page) => {

    setLimit(newPerPage);
    setPageNo(1)

    fetchData(1, newPerPage, report_station, report_job_status, report_date_range, report_hour_count, user, day, year, week, month);

    handleResetDataTable()

  };

  const handlePageChange = page => {
    setPageNo(page)
    fetchData(page, limit, report_station, report_job_status, report_date_range, report_hour_count, user, day, year, week, month);
  };

  const fetchData = (page, limit, station, job_status, date_range, hour_count, user, day, year, week, month) => {

    setLoading(true)
    getQaErrorReportDetails(page, limit, report_station, report_job_status, report_date_range, report_hour_count, user, day, year, week, month, clients, facilities, client_id, facility_id).then((response) => {
      setData(response?.data?.results);
      setLimit(limit);
      setPageNo(page)
      setTotalRows(response?.data?.count);
      setLoading(false);
    });

  }


  const columns = useMemo(
    () => [
      {
        id: 'S.NO',
        name: 'S.NO',
        selector: (row, index) => pageno == 1 ? index + 1 : (pageno - 1) * limit + index + 1,
        wrap: true,
        width: "40px",
        grow: 0,

      },
      {
        id: 'QA USER',
        name: 'QA USER',
        selector: row => row?.qa_user,
        cell: (row) =>
          <div style={row?.qa_user ? { height: 'auto' } : { height: '100%' }}>


            <div onClick={() => handleRowClick(row)} className='d-flex flex-row justify-content-between align-items-center gap-2' style={{ maxWidth: '120px' }}>
              <div className='overflow-hidden text-truncate' style={{ maxWidth: "120px" }} >
                <Tooltip disableInteractive title={row?.qa_user} placement="top" arrow>
                  <span className="text-truncate"> {row?.qa_user}</span>
                </Tooltip>
              </div>
            </div>

          </div>
        ,


        left: true,
        wrap: true,
        reorder: false,
        omit: report_station != 2 ? true : false,
        minWidth: '120px'


      },
      {
        id: 'USER NAME',
        name: 'USER NAME',
        selector: row => row?.staff,
        cell: (row) =>
          <div style={row?.staff ? { height: 'auto' } : { height: '100%' }}>


            <div onClick={() => handleRowClick(row)} className='d-flex flex-row justify-content-between align-items-center gap-2' style={{ maxWidth: '110px' }}>
              <div className='overflow-hidden text-truncate' style={{ maxWidth: "110px" }} >
                <Tooltip disableInteractive title={row?.staff} placement="top" arrow>
                  <span className="text-truncate"> {row?.staff}</span>
                </Tooltip>
              </div>
            </div>

          </div>
        ,

        left: true,
        wrap: true,
        reorder: false,
        grow: 0,
        minWidth: '120px',
        omit: report_station == 2 ? true : false,


      },
      {
        id: 'CLIENT NAME',
        name: 'CLIENT NAME',
        selector: row => row?.client_name,
        cell: (row) =>
          <div style={row?.client_name ? { height: 'auto' } : { height: '100%' }}>


            <div onClick={() => handleRowClick(row)} className='d-flex flex-row justify-content-between align-items-center gap-2' style={{ maxWidth: '110px' }}>
              <div className='overflow-hidden text-truncate' style={{ maxWidth: "110px" }} >
                <Tooltip disableInteractive title={row?.client_name} placement="top" arrow>
                  <span className="text-truncate"> {row?.client_name}</span>
                </Tooltip>
              </div>
            </div>

          </div>
        ,

        left: true,
        wrap: true,
        reorder: false,
        grow: 0,
        minWidth: '120px'

      },
      {
        id: 'FACILITY',
        name: 'FACILITY NAME',
        selector: row => row?.facility,
        cell: (row) =>
          <div style={row?.facility ? { height: 'auto' } : { height: '100%' }}>


            <div onClick={() => handleRowClick(row)} className='d-flex flex-row justify-content-between align-items-center gap-2' style={{ maxWidth: '100px' }}>
              <div className='overflow-hidden text-truncate' style={{ maxWidth: "100px" }} >
                <Tooltip disableInteractive title={row?.facility} placement="top" arrow>
                  <span className="text-truncate"> {row?.facility}</span>
                </Tooltip>
              </div>
            </div>

          </div>
        ,


        left: true,
        wrap: true,
        reorder: false,
        grow: 0,
        minWidth: '130px'


      },

      {
        id: 'CLMS',
        name: 'CLMS',
        selector: row => row?.claims,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.claims}</span>,

        right: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        omit: report_station != 2 ? true : false,

        minWidth: '50px',
        maxWidth: '50px'

      },
      {
        id: 'JOB ID',
        name: 'JOB ID',
        selector: row => row?.job_id,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.job_id}</span>,

        right: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        minWidth: '100px'

      },
      {
        id: 'CLMS',
        name: 'CLMS',
        selector: row => row?.claims,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.claims}</span>,

        right: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        omit: report_station != 2 ? false : true,

        minWidth: '50px',
        maxWidth: '50px'

      },
      {
        id: 'DOCUMENT TYPE',
        name: 'DOCUMENT TYPE',
        selector: row => row?.document_type,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.document_type}</span>,

        left: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        minWidth: '120px'

      },
      {
        id: 'PAYER',
        name: 'PAYER',
        selector: row => row?.payer,
        cell: (row) =>
          <div style={row?.payer ? { height: 'auto' } : { height: '100%' }}>


            <div onClick={() => handleRowClick(row)} className='d-flex flex-row justify-content-between align-items-center gap-2' style={{ maxWidth: '120px' }}>
              <div className='overflow-hidden text-truncate' style={{ maxWidth: "120px" }} >
                <Tooltip disableInteractive title={row?.payer} placement="top" arrow>
                  <span className="text-truncate"> {row?.payer}</span>
                </Tooltip>
              </div>
            </div>

          </div>
        ,


        left: true,
        wrap: true,
        reorder: false,
        omit: report_station != 2 ? false : true,
        minWidth: '120px'


      },
      {
        id: 'NO.OF ERROR FIELDS',
        name: 'NO.OF ERROR FIELDS',
        selector: row => row?.no_of_errors,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.no_of_errors}</span>,

        right: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        minWidth: '120px'


      },
      {
        id: 'TOTAL FIELDS',
        name: 'TOTAL FIELDS',
        selector: row => row?.total_fields_count,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.total_fields_count}</span>,

        right: true,
        wrap: true,
        reorder: false,
        minWidth: '100px'


      },

      {
        id: 'PROCESSED ON',
        name: 'PROCESSED ON',
        selector: row => row?.proccessed_on,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.proccessed_on}</span>,

        left: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        minWidth: '88px',
        maxWidth: '88px'

      },


      {
        id: 'QA ON',
        name: 'QA ON',
        selector: row => row?.qa_on,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.qa_on}</span>,

        left: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        minWidth: '60px',
        maxWidth: '60px'

      },
      {
        id: 'QA TIME',
        name: 'QA TIME',
        selector: row => row?.qa_time,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.qa_time}</span>,

        left: true,
        wrap: true,
        reorder: false,
        omit: report_station != 2 ? true : false,
        minWidth: '50px'

      },
      {
        id: 'CPH',
        name: 'CPH',
        selector: row => row?.qa_cph,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.qa_cph}</span>,

        right: true,
        wrap: true,
        reorder: false,
        omit: report_station != 2 ? true : false,
        minWidth: '50px'

      },

      {
        id: 'REASSIGN',
        name: 'REASSIGN',
        selector: row => row?.re_assigned,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.re_assigned}</span>,

        left: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        minWidth: '60px',
        maxWidth: '60px'

      },
      {
        id: 'CLM DEL',
        name: 'CLM DEL',
        selector: row => row?.claims_delete,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.claims_delete}</span>,

        right: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        minWidth: '50px'

      },

      {
        id: 'SVC LINE DEL',
        name: 'SVC LINE DEL',
        selector: row => row?.servicelines_delete,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.servicelines_delete}</span>,

        right: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        minWidth: '80px'

      },
      {
        id: 'PLB DELETE',
        name: 'PLB DELETE',
        selector: row => row?.plb_delete,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.plb_delete}</span>,

        right: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        minWidth: '80px',
        maxWidth: '80px'

      },
      {
        id: 'TRNS TYPE CHANGED',
        name: 'TRNS TYPE CHANGED',
        selector: row => row?.transaction_types,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.transaction_types}</span>,

        left: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        minWidth: '115px'
      },
      {
        id: '837 ERROR',
        name: '837 ERROR',
        selector: row => row?.is_837_error,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.is_837_error}</span>,

        left: true,
        wrap: true,
        reorder: false,
        minWidth: '100px'
      },
      {
        id: 'USER NAME',
        name: 'USER NAME',
        selector: row => row?.staff,
        cell: (row) =>
          <div style={row?.staff ? { height: 'auto' } : { height: '100%' }}>


            <div onClick={() => handleRowClick(row)} className='d-flex flex-row justify-content-between align-items-center gap-2' style={{ maxWidth: '110px' }}>
              <div className='overflow-hidden text-truncate' style={{ maxWidth: "110px" }} >
                <Tooltip disableInteractive title={row?.staff} placement="top" arrow>
                  <span className="text-truncate"> {row?.staff}</span>
                </Tooltip>
              </div>
            </div>

          </div>
        ,

        left: true,
        wrap: true,
        reorder: false,
        grow: 0,
        minWidth: '120px',
        omit: report_station != 2 ? true : false,


      },

      {
        id: 'QA USER',
        name: 'QA USER',
        selector: row => row?.qa_user,
        cell: (row) =>
          <div style={row?.qa_user ? { height: 'auto' } : { height: '100%' }}>


            <div onClick={() => handleRowClick(row)} className='d-flex flex-row justify-content-between align-items-center gap-2' style={{ maxWidth: '120px' }}>
              <div className='overflow-hidden text-truncate' style={{ maxWidth: "120px" }} >
                <Tooltip disableInteractive title={row?.qa_user} placement="top" arrow>
                  <span className="text-truncate"> {row?.qa_user}</span>
                </Tooltip>
              </div>
            </div>

          </div>
        ,


        left: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        minWidth: '120px',
        omit: report_station == 2 ? true : false,


      },
      {
        id: 'PAYER',
        name: 'PAYER',
        selector: row => row?.payer,
        cell: (row) =>
          <div style={row?.payer ? { height: 'auto' } : { height: '100%' }}>


            <div onClick={() => handleRowClick(row)} className='d-flex flex-row justify-content-between align-items-center gap-2' style={{ maxWidth: '120px' }}>
              <div className='overflow-hidden text-truncate' style={{ maxWidth: "120px" }} >
                <Tooltip disableInteractive title={row?.payer} placement="top" arrow>
                  <span className="text-truncate"> {row?.payer}</span>
                </Tooltip>
              </div>
            </div>

          </div>
        ,


        left: true,
        wrap: true,
        reorder: false,
        omit: report_station != 2 ? true : false,
        minWidth: '120px'


      },
      {
        id: 'ERROR FIELDS',
        name: 'ERROR FIELDS',
        selector: row => row?.error_fields,
        cell: (row) =>
          <div style={row?.error_fields ? { height: 'auto' } : { height: '100%' }}>


            <div onClick={() => handleRowClick(row)} className='d-flex flex-row justify-content-between align-items-center gap-2' style={{ maxWidth: '120px' }}>
              <div className='overflow-hidden text-truncate' style={{ maxWidth: "110px" }} >
                <Tooltip disableInteractive title={row?.error_fields} placement="top" arrow>
                  <span style={{ display: 'block', width: "120px" }} className="text-truncate"> {row?.error_fields}</span>
                </Tooltip>
              </div>
            </div>

          </div>
        ,


        left: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        minWidth: '120px'



      },
      {
        id: 'ERROR TYPE',
        name: 'ERROR TYPE',
        selector: row => row?.qa_error_type,
        cell: (row) =>
          <div style={row?.qa_error_type ? { height: 'auto' } : { height: '100%' }}>


            <div onClick={() => handleRowClick(row)} className='d-flex flex-row justify-content-between align-items-center gap-2' style={{ maxWidth: '120px' }}>
              <div className='overflow-hidden text-truncate' style={{ maxWidth: "120px" }} >
                <Tooltip disableInteractive title={row?.qa_error_type} placement="top" arrow>
                  <span className="text-truncate"> {row?.qa_error_type}</span>
                </Tooltip>
              </div>
            </div>

          </div>
        ,


        left: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        minWidth: '130px'



      },
      {
        id: 'COMMENTS',
        name: 'COMMENTS',
        selector: row => row?.qa_error_comment,
        cell: (row) =>
          <div style={row?.qa_error_comment ? { height: 'auto' } : { height: '100%' }}>


            <div onClick={() => handleRowClick(row)} className='d-flex flex-row justify-content-between align-items-center gap-2' style={{ maxWidth: '120px' }}>
              <div className='overflow-hidden text-truncate' style={{ maxWidth: "120px" }} >
                <Tooltip disableInteractive title={row?.qa_error_comment} placement="top" arrow>
                  <span className="text-truncate"> {row?.qa_error_comment}</span>
                </Tooltip>
              </div>
            </div>

          </div>
        ,


        left: true,
        wrap: true,
        reorder: false,
        // grow: 0,
        minWidth: '100px'



      },

    ],

    [limit, pageno, report_hour_count, week, year, month],
  );

  const conditionalRowStyles = [

    {
      when: row => colorRow.id == row.id ? true : false,
      style: (row, index) => ({
        backgroundColor: colorRow?.id == row.id ? '#e4e6ef' : '#f2f2f2',
        color: 'black',
        // fontWeight: '550'
      }),
    },


  ];
  const handleRowClick = (row) => {
    setColorRow(row);
    console.log(row.expandableRowExpanded);
    row.expandableRowExpanded = !row.expandableRowExpanded;
    console.log(row.expandableRowExpanded);
    // // console.log(row);
  };


  const searchFormik = useFormik({
    initialValues: {
      search: "",
      job_status: report_job_status,
      station: report_station,
      date_range: report_date_range,
      hour_count: report_hour_count

    },
    onSubmit: (values) => {
      console.log(values);
      handleResetDataTable()
    }
  })

  const [datetime, setDateTime] = useState([]);

  const searchData = ({ station, job_status, date_range, hour_count }) => {
    fetchData(1, limit, station, selectValue, datetime, hour_count)
  }

  const showTooltip = (text, props) => (
    <Tooltip id="button-tooltip" {...props}>
      {text}
    </Tooltip>
  );

  const handleResetDataTable = () => {
    setRerenderDataTable(true)
  }

  const [loader, setLoader] = useState(false);
  const handleDownload = () => {
    setLoader(true)
    downloadQaErrorReportDetails(1, limit, report_station, report_job_status, report_date_range, report_hour_count, user, day, year, week, month, clients, facilities, client_id, facility_id).then((response) => {
      setLoader(false)
      downloadXl(response?.data?.export_file)
    });
  }
  useEffect(() => {
    fetchData(1, limit, report_station, report_job_status, report_date_range, report_hour_count, user, day, year, week, month);
  }, [])

  return (
    <React.Fragment>
      <section className="section">
        <div className="row align-items-top py-2 gap-2 gap-md-5">
          <div className="col-lg-12">
            <div className="card card_background">

              <Form onSubmit={searchFormik.handleSubmit} >
                <div className="d-flex justify-content-end" style={{ height: "20px" }}>


                  {isHavePermission("detail_export_qa_error_report_management") &&
                    <div className="px-3 pt-1 d-flex flex-row justify-content-start gap-2 align-items-center flex-wrap">

                      <div>
                        <Tooltip disableInteractive title={"Download"} placement="top" arrow>
                          {!loader ?

                            <span style={{ cursor: "pointer" }} className="svg-icon svg-icon-3" onClick={() => { handleDownload() }}>
                              <BsCloudDownloadFill color="#1ba1a7" />
                            </span>
                            :
                            <span className="svg-icon svg-icon-3">
                              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            </span>
                          }

                        </Tooltip>

                      </div>
                    </div>
                  }
                </div>

              </Form>


              <div className="card-body p-common">
                <div className="table-responsive">
                  <div className="dashboardtable custom_background">
                    {/* {!reRenderDataTable && */}
                    <DataTable
                      // className="custom-datatable-details"
                      paginationDefaultPage={pageno}

                      fixedHeader={true}
                      progressPending={loading}
                      data={data}
                      columns={columns}
                      pointerOnHover={true}
                      pagination
                      paginationServer
                      // highlightOnHover
                      paginationTotalRows={totalRows}
                      paginationPerPage={limit}
                      paginationRowsPerPageOptions={[25, 50, 100, 150, 200]}
                      onChangeRowsPerPage={handlePerRowsChange}
                      onChangePage={handlePageChange}
                      conditionalRowStyles={conditionalRowStyles}
                      theme="detail_solarized"
                      customStyles={DetailCutomRowStyle}
                      onRowClicked={handleRowClick}

                    />
                    {/* } */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment >
  )
}
export default QaErrorReportDetails




