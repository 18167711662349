import React, { useState } from "react";
import DataTable from "react-data-table-component";

import { useMemo } from "react";
import { Form } from "react-bootstrap";
import { useFormik } from "formik";

import { PayerReportService } from "../../../services/PayerReportService";
import { BsCloudDownloadFill } from "react-icons/bs";
import { downloadXl } from "../../../functions";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectIsSuperUser, selectPermissions } from "../../../slice/user/userSlice";
import { Tooltip } from "@mui/material";
import DetailCutomRowStyle from "../../components/DetailCutomRowStyle";



function PayerReportDetails(params) {
  const { report_station, report_job_status, report_date_range, report_hour_count, user, clients, facilities, client_id, facility_id } = params

  const [reRenderDataTable, setRerenderDataTable] = useState(false)
  const [pageno, setPageNo] = useState(1);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(100);
  const [selectValue, setSelectValue] = useState('');
  const [colorRow, setColorRow] = React.useState({});
  const [data, setData] = useState([])
  const [day, setDay] = useState(true)
  const [year, setYear] = useState(false)
  const [week, setWeek] = useState(false)
  const [month, setMonth] = useState(false)
  const [limit, setLimit] = useState(100)

  const payerReportDetailsService = new PayerReportService();
  const { getPayerReportDetails, downloadPayerReportDetails } = payerReportDetailsService


  const handleDetailFilterChange = (name) => {
    if (name === 'year') {
      setYear(true)
      setWeek(false)
      setMonth(false)
      setDay(false)
      fetchData(1, limit, report_station, report_job_status, report_date_range, report_hour_count, user, 'false', 'true', 'false', 'false');

    }
    else if (name === 'day') {
      setYear(false)
      setWeek(false)
      setMonth(false)
      setDay(true)
      fetchData(1, limit, report_station, report_job_status, report_date_range, report_hour_count, user, 'true', 'false', 'false', 'false');

    }
    else if (name === 'month') {
      setYear(false)
      setWeek(false)
      setMonth(true)
      setDay(false)
      fetchData(1, limit, report_station, report_job_status, report_date_range, report_hour_count, user, 'false', 'false', 'false', 'true');

    }
    else if (name === 'week') {
      setYear(false)
      setWeek(true)
      setMonth(false)
      setDay(false)
      fetchData(1, limit, report_station, report_job_status, report_date_range, report_hour_count, user, 'false', 'false', 'true', 'false');

    }

  }
  const handlePerRowsChange = async (newPerPage, page) => {

    setLimit(newPerPage);
    setPageNo(1)

    fetchData(1, newPerPage, report_station, report_job_status, report_date_range, report_hour_count, user, day, year, week, month);

    handleResetDataTable()

  };

  const handlePageChange = page => {
    setPageNo(page)
    fetchData(page, limit, report_station, report_job_status, report_date_range, report_hour_count, user, day, year, week, month);
  };




  const fetchData = (page, limit, station, job_status, date_range, hour_count, user, day, year, week, month) => {

    setLoading(true)
    getPayerReportDetails(page, limit, report_station, report_job_status, report_date_range, report_hour_count, user, day, year, week, month, clients, facilities, client_id, facility_id).then((response) => {
      setData(response?.data?.results);
      setLimit(limit);
      setPageNo(page)
      setTotalRows(response?.data?.count);
      setLoading(false);
    });

  }

  const columns = useMemo(
    () => [
      {
        id: 'S.NO',
        name: 'S.NO',
        selector: (row, index) => pageno == 1 ? index + 1 : (pageno - 1) * limit + index + 1,
        wrap: true,
        width: "40px",
        grow: 0.1,

      },
      {
        id: 'FILTER DATA',
        name: year ? 'YEAR' : week ? "WEEK" : month ? "MONTH" : "DATE",
        selector: row => row?.filter_data,
        cell: (row) =>

          <div>
            {row?.filter_data &&

              <div className='d-flex flex-row justify-content-between align-items-center gap-2' style={{ width: '170px' }}>
                <div className='overflow-hidden text-truncate' style={{ width: "120px" }} onClick={() => handleRowClick(row)}>
                  <Tooltip disableInteractive title={row?.filter_data} placement="top" arrow>
                    <span style={{ display: 'block' }} className="text-truncate"> {row?.filter_data}</span>
                  </Tooltip>
                </div>
              </div>

            }
          </div>
        ,


        left: true,
        wrap: true,
        reorder: false,
        minWidth: '120px',
        // grow: 0.1,
        expandOnRowClicked: true,
      },
      {
        id: 'PAYMENT835',
        name: 'PAYMENT835',
        selector: row => row?.no_of_835,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.no_of_835}</span>,

        right: true,
        wrap: true,
        reorder: false,
        minWidth: '50px',
        // grow: 0.1,
      },
      {
        id: 'PAYMENT LITE',
        name: 'PAYMENT LITE',
        selector: row => row?.no_835_lite,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.no_835_lite}</span>,

        right: true,
        wrap: true,
        reorder: false,
        minWidth: '50px',
        // grow: 0.1,
      },
      {
        id: 'CORRES',
        name: 'CORRES',
        selector: row => row?.correspondence,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.correspondence}</span>,

        right: true,
        wrap: true,
        reorder: false,
        minWidth: '50px',
        // grow: 0.1,
        minWidth: '150px'

      },
      {
        id: 'PAT PAY',
        name: 'PAT PAY',
        selector: row => row?.patient_pay,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.patient_pay}</span>,

        right: true,
        wrap: true,
        reorder: false,
        minWidth: '50px',
        // grow: 0.1,
      },
      {
        id: 'PAT PAY 835',
        name: 'PAT PAY 835',
        selector: row => row?.patient_pay_835,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.patient_pay_835}</span>,

        right: true,
        wrap: true,
        reorder: false,
        minWidth: '50px',
        // grow: 0.1,
      },


      {
        id: 'CORRES 835',
        name: 'CORRES 835',
        selector: row => row?.correspondence_835,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.correspondence_835}</span>,

        right: true,
        wrap: true,
        reorder: false,
        minWidth: '50px',
        // grow: 0.1,

      },
      {
        id: 'JOB TOTAL',
        name: 'JOB TOTAL',
        selector: row => row?.total,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.total}</span>,

        right: true,
        wrap: true,
        reorder: false,
        minWidth: '50px',
        // grow: 0.1,

      },
      {
        id: 'FILES SPLIT',
        name: 'FILES SPLIT - JOB',
        selector: row => row?.file_split,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.file_split}</span>,

        right: true,
        wrap: true,
        reorder: false,
        minWidth: '50px',
        // grow: 0.1,
      },

      {
        id: '# CLAIMS',
        name: '# CLAIMS',
        selector: row => row?.claims,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.claims}</span>,

        right: true,
        wrap: true,
        reorder: false,
        minWidth: '50px',
        // grow: 0.1,
        // omit: isTimeCount
      },
      {
        id: '# SVC LINES',
        name: '# SVC LINES',
        selector: row => row?.servicelines,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.servicelines}</span>,

        right: true,
        wrap: true,
        reorder: false,
        minWidth: '50px',
        // grow: 0.1,
        // omit: isTimeCount

      },



    ],

    [limit, pageno, report_hour_count, week, year, month],
  );

  const conditionalRowStyles = [

    {
      when: row => colorRow.filter_data == row.filter_data ? true : false,
      style: (row, index) => ({
        backgroundColor: colorRow?.filter_data == row.filter_data ? '#e4e6ef' : '#f2f2f2',
        color: 'black',
        // fontWeight: '550'
      }),
    },


  ];
  const handleRowClick = (row) => {
    setColorRow(row);
    console.log(row.expandableRowExpanded);
    row.expandableRowExpanded = !row.expandableRowExpanded;
    console.log(row.expandableRowExpanded);
    // // console.log(row);
  };


  const searchFormik = useFormik({
    initialValues: {
      search: "",
      job_status: report_job_status,
      station: report_station,
      date_range: report_date_range,
      hour_count: report_hour_count

    },
    onSubmit: (values) => {
      console.log(values);
      handleResetDataTable()
    }
  })

  const [datetime, setDateTime] = useState([]);



  const searchData = ({ station, job_status, date_range, hour_count }) => {
    fetchData(1, limit, station, selectValue, datetime, hour_count)
  }

  const handleChangeSelect = async (e, name) => {
    let selectedId = []
    let selectedOptons = []

    e.map((option) => {
      selectedId.push(option.value)
      selectedOptons.push({ option })
    })

    searchFormik.setValues({ ...searchFormik.values, [name]: selectedId })
    setSelectValue(selectedId)

  }
  const permissions = useSelector(selectPermissions);
  const isSuperUser = useSelector(selectIsSuperUser);
  const isHavePermission = (name) => {
    return permissions?.includes(name) || isSuperUser
  }

  const showTooltip = (text, props) => (
    <Tooltip id="button-tooltip" {...props}>
      {text}
    </Tooltip>
  );


  const handleResetDataTable = () => {
    setRerenderDataTable(true)
  }

  const [loader, setLoader] = useState(false);
  const handleDownload = () => {
    setLoader(true)
    downloadPayerReportDetails(1, limit, report_station, report_job_status, report_date_range, report_hour_count, user, day, year, week, month, clients, facilities, client_id, facility_id).then((response) => {
      setLoader(false)
      downloadXl(response?.data?.export_file)
    });
  }

  useEffect(() => {
    fetchData(1, limit, report_station, report_job_status, report_date_range, report_hour_count, user, day, year, week, month);
  }, [])

  return (
    <React.Fragment>
      <section className="section">
        <div className="row align-items-top py-2 gap-2 gap-md-5">
          <div className="col-lg-12">
            <div className="card card_background">

              <Form onSubmit={searchFormik.handleSubmit} >
                <div className="d-flex justify-content-end" style={{ height: "20px" }}>
                  <div className="px-3 pt-1 d-flex flex-row justify-content-start gap-2 align-items-center flex-wrap">

                    <div>
                      <Tooltip disableInteractive title={"Year"} placement="top" arrow>

                        <div class="form-check">
                          <input class="form-check-input me-1" type="radio" onChange={() => handleDetailFilterChange('year')} checked={year} name="year" id="year1" />
                          <label class="form-check-label" for="year1" style={{ fontSize: "14px", fontWeight: "600" }}>
                            Year
                          </label>
                        </div>
                      </Tooltip>

                    </div>
                    <div>
                      <Tooltip disableInteractive title={"Month"} placement="top" arrow>

                        <div class="form-check">
                          <input class="form-check-input me-1" type="radio" onChange={() => handleDetailFilterChange('month')} checked={month} name="month" id="month2" />
                          <label class="form-check-label" for="month2" style={{ fontSize: "14px", fontWeight: "600" }}>
                            Month
                          </label>
                        </div>
                      </Tooltip>

                    </div>


                    <div>
                      <Tooltip disableInteractive title={"Week"} placement="top" arrow>


                        <div class="form-check">
                          <input class="form-check-input me-1" onChange={() => handleDetailFilterChange('week')} checked={week} type="radio" name="week" id="week3" />
                          <label class="form-check-label" for="week3" style={{ fontSize: "14px", fontWeight: "600" }}>
                            Week
                          </label>
                        </div>
                      </Tooltip>

                    </div>
                    <div>
                      <Tooltip disableInteractive title={"Day"} placement="top" arrow>


                        <div class="form-check">
                          <input class="form-check-input me-1" onChange={() => handleDetailFilterChange('day')} checked={day} type="radio" name="flexRadioDefault" id="flexRadioDefault4" />
                          <label class="form-check-label" for="flexRadioDefault4" style={{ fontSize: "14px", fontWeight: "600" }}>
                            Day
                          </label>
                        </div>
                      </Tooltip>

                    </div>
                  </div>
                  {isHavePermission("detail_export_payer_report_management") &&

                    <div className="px-3 pt-1 d-flex flex-row justify-content-start gap-2 align-items-center flex-wrap">

                      <div>
                        <Tooltip disableInteractive title={"Download"} placement="top" arrow>
                          {!loader ?

                            <span style={{ cursor: "pointer" }} className="svg-icon svg-icon-3" onClick={() => { handleDownload() }}>
                              <BsCloudDownloadFill color="#1ba1a7" />
                            </span>
                            :
                            <span className="svg-icon svg-icon-3">
                              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            </span>
                          }

                        </Tooltip>

                      </div>
                    </div>
                  }
                </div>

              </Form>


              <div className="card-body p-common">
                <div className="table-responsive">
                  <div className="dashboardtable custom_background">
                    {/* {!reRenderDataTable && */}
                    <DataTable
                      // className="custom-datatable-details"
                      paginationDefaultPage={pageno}
                      fixedHeader={true}
                      progressPending={loading}
                      data={data}
                      columns={columns}
                      pointerOnHover={true}
                      pagination
                      paginationServer
                      // highlightOnHover
                      paginationTotalRows={totalRows}
                      paginationPerPage={limit}
                      paginationRowsPerPageOptions={[25, 50, 100, 150, 200]}
                      onChangeRowsPerPage={handlePerRowsChange}
                      onChangePage={handlePageChange}
                      conditionalRowStyles={conditionalRowStyles}
                      theme="detail_solarized"
                      customStyles={DetailCutomRowStyle}
                      onRowClicked={handleRowClick}

                    // expandableRows
                    // expandOnRowClicked={false}
                    // expandableRowsHideExpander

                    />
                    {/* } */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment >
  )
}
export default PayerReportDetails




