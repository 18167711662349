import request from "../api/agent";

const API_ROUTE = "reports";

export class UserReportService {

  async getUserReportSummery(page, limit, station, job_status, date_range, hour_count, staff, clients, facilities) {
    if (station == "") {
      station = 0
    }
    const params = `?page=${page}&limit=${limit}&date_range=${date_range}&job_status=${job_status}&station=${station}&hour_count=${hour_count}&users_ids=${staff}&clients=${clients}&facilities=${facilities}`

    const response = await request.get(`${API_ROUTE}/get-user-report-summery` + params);
    return response.data ?? response?.response?.data;
  }

  async getUserReportDetails(page, limit, station, job_status, date_range, hour_count, user, day, year, week, monthList, clients, facilities, client_id, facility_id) {
    if (station == "") {
      station = 0
    }
    const params = `?page=${page}&limit=${limit}&date_range=${date_range}&job_status=${job_status}&station=${station}&hour_count=${hour_count}&user=${user}&day=${day}&year=${year}&week=${week}&month=${monthList}&clients=${clients}&facilities=${facilities}&client_id=${client_id}&facility_id=${facility_id}`

    const response = await request.get(`${API_ROUTE}/get-user-report-details` + params);
    return response.data;
  }
  async downloadUserReportSummery(page, limit, station, job_status, date_range, hour_count, staff, clients, facilities) {
    if (station == "") {
      station = 0
    }
    const params = `?page=${page}&limit=${limit}&date_range=${date_range}&job_status=${job_status}&station=${station}&hour_count=${hour_count}&users_ids=${staff}&clients=${clients}&facilities=${facilities}`

    const response = await request.get(`${API_ROUTE}/user-report-download` + params);
    return response.data;
  }
  async downloadUserReportSingle(page, limit, station, job_status, date_range, hour_count, staff, clients, facilities) {
    if (station == "") {
      station = 0
    }
    const params = `?page=${page}&limit=${limit}&date_range=${date_range}&job_status=${job_status}&station=${station}&hour_count=${hour_count}&users_ids=${staff}&clients=${clients}&facilities=${facilities}`

    const response = await request.get(`${API_ROUTE}/user-report-single-download` + params);
    return response.data;
  }

  async downloadUserReportDetails(page, limit, station, job_status, date_range, hour_count, user, day, year, week, monthList, clients, facilities, client_id, facility_id) {
    if (station == "") {
      station = 0
    }
    const params = `?page=${page}&limit=${limit}&date_range=${date_range}&job_status=${job_status}&station=${station}&hour_count=${hour_count}&user=${user}&day=${day}&year=${year}&week=${week}&month=${monthList}&clients=${clients}&facilities=${facilities}&client_id=${client_id}&facility_id=${facility_id}`

    const response = await request.get(`${API_ROUTE}/user-report-details-download` + params);
    return response.data;
  }
}