import request from "../api/agent";

const API_ROUTE = "reports";

export class ClientReportService {

  async getClientReportSummery(page, limit, station, job_status, date_range, hour_count, clients, facilities) {
    const params = `?page=${page}&limit=${limit}&date_range=${date_range}&job_status=${job_status}&station=${station}&hour_count=${hour_count}&facilities=${facilities}&clients=${clients}`

    const response = await request.get(`${API_ROUTE}/get-client-report-summery` + params);
    return response.data ?? response?.response?.data;
  }

  async getClientReportDetails(page, limit, station, job_status, date_range, hour_count, user, day, year, week, monthList, clients, facilities, facility_id) {

    const params = `?page=${page}&limit=${limit}&date_range=${date_range}&job_status=${job_status}&station=${station}&hour_count=${hour_count}&user=${user}&day=${day}&year=${year}&week=${week}&month=${monthList}&facilities=${facilities}&clients=${clients}&facility_id=${facility_id}`

    const response = await request.get(`${API_ROUTE}/get-client-report-details` + params);
    return response.data;
  }
  async downloadClientReportSummery(page, limit, station, job_status, date_range, hour_count, clients, facilities) {

    const params = `?page=${page}&limit=${limit}&date_range=${date_range}&job_status=${job_status}&station=${station}&hour_count=${hour_count}&facilities=${facilities}&clients=${clients}`

    const response = await request.get(`${API_ROUTE}/client-report-download` + params);
    return response.data;
  }

  async downloadClientReportDetails(page, limit, station, job_status, date_range, hour_count, user, day, year, week, monthList, clients, facilities, facility_id) {

    const params = `?page=${page}&limit=${limit}&date_range=${date_range}&job_status=${job_status}&station=${station}&hour_count=${hour_count}&user=${user}&day=${day}&year=${year}&week=${week}&month=${monthList}&facilities=${facilities}&clients=${clients}&facility_id=${facility_id}`

    const response = await request.get(`${API_ROUTE}/client-report-details-download` + params);
    return response.data;
  }

  async downloadClientReportSingle(page, limit, station, job_status, date_range, hour_count, clients, facilities) {

    const params = `?page=${page}&limit=${limit}&date_range=${date_range}&job_status=${job_status}&station=${station}&hour_count=${hour_count}&facilities=${facilities}&clients=${clients}`

    const response = await request.get(`${API_ROUTE}/client-report-single-download` + params);
    return response.data;
  }
}