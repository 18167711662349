import React, { useMemo } from 'react'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { FilterComponent } from '../../components/FilterComponent'
import DataTable from 'react-data-table-component'
import { OverlayTrigger } from 'react-bootstrap'
import Sidebar from '../../includes/Sidebar'
import DeleteSweetAlert from '../../components/DeleteSweetAlert'
import { GroupApiService } from '../../../services/GroupApiService'
import { useEffect } from 'react'
import { showTooltip } from '../../components/ShowTooltip'
import { Toast, ToastContainer } from 'react-bootstrap';
import { useSelector } from 'react-redux'
import { selectIsSuperUser, selectPermissions } from '../../../slice/user/userSlice'
import { Tooltip } from '@mui/material'


const datatableCustomStyles = {
    rows: {
        style: {
            minHeight: '20px', // override the row height
            fontSize: '0.7em',
            fontColor: '#212529 !important',
            width: '100%'
        },
    },
    headCells: {
        style: {
            paddingLeft: '5px', // override the cell padding for head cells
            paddingRight: '5px',
            textTransform: "uppercase",
            fontSize: '0.7em',
            fontWeight: '800',
            fontColor: '#6c757d !important',
            opacity: 1,
            minHeight: "10px",
        },
    },
    cells: {
        style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
            width: "100%",
        },
    },
    pagination: {
        style: {
            color: '#000',
            fontSize: '13px',
            minHeight: '56px',
            backgroundColor: '#fff',
            borderTopStyle: 'solid',
            borderTopWidth: '1px',
            borderTopColor: '#f2f2f2',
        },
        pageButtonsStyle: {
            borderRadius: '50%',
            height: '40px',
            width: '40px',
            padding: '8px',
            margin: '0px',
            cursor: 'pointer',
            transition: '0.4s',
            color: '#000',
            fill: '#000',
            backgroundColor: 'transparent',
            '&:disabled': {
                cursor: 'unset',
                color: '#e2e2e2',
                fill: '#e2e2e2',
            },
            '&:hover:not(:disabled)': {
                backgroundColor: '#f2f2f2',
            },
            '&:focus': {
                outline: 'none',
                backgroundColor: '#f5f5f5',
            },
        },
    },
};

function ListGroups() {
    const groupService = new GroupApiService();
    const { getAllGroups, deleteGroup } = groupService
    const permissions = useSelector(selectPermissions);
    const isSuperUser = useSelector(selectIsSuperUser);
    const isHavePermission = (name) => {
        return permissions?.includes(name) || isSuperUser
    }


    const [reRenderDataTable, setRerenderDataTable] = useState(false)
    const [isSwal, setIsSwal] = useState({
        show: false,
        id: ''
    })
    const [toaster, setToaster] = useState(false)


    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(100);
    const [limit, setLimit] = useState(10);
    const [data, setData] = useState({})
    const [colorRow, setColorRow] = useState({});
    const [filterText, setFilterText] = useState('');
    const [pageno, setPageNo] = useState(1);
    const columns = useMemo(
        () => [

            {
                id: 'id',
                name: 'S NO',
                selector: (row, index) => pageno == 1 ? index + 1 : (pageno - 1) * limit + index + 1,
                // 
                wrap: true,
                width: "70px",
                grow: 0,

            },

            {
                id: 'name',
                name: 'Name',
                selector: row => row.name,
                cell: (row) => <div className="" onClick={() => handleRowClick(row)}>
                    {row?.name &&

                        <div className='overflow-hidden text-truncate' style={{ width: "260px" }}>
                            <Tooltip disableInteractive title={row?.name} placement="top" arrow>
                                <span className="text-truncate"> {row?.name}</span>
                            </Tooltip>
                        </div>

                    }

                </div>,

                left: true,
                wrap: true,
                //width: "300px",
                //grow: 0.3,


            },

            {
                id: 'Roles',
                name: 'Roles',
                selector: row => row.roles,

                left: true,
                fixed: true,
                wrap: true,
                //width: "300px",
                //grow: 2,


                cell: (row) => (

                    <div className='d-flex flex-row justify-content-start flex-wrap'>
                        {row && row?.roles && row.roles.map((role, index) => (

                            <span key={index} className="badge badge-light-primary fs-7 fw-bold ">
                                {role}
                            </span>
                        ))}
                    </div>
                )
            },

            {
                id: 'edit',
                name: 'Actions',

                cell: (row) =>
                    <div className="d-flex justify-content-end flex-shrink-0" onClick={() => handleRowClick(row)}>
                        {isHavePermission("edit_group") &&

                            <Tooltip disableInteractive title={"Edit"} placement="top" arrow>
                                <Link to={'/edit-group/' + row.pk} className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1" >
                                    <span className="svg-icon svg-icon-3">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path opacity="0.3" d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z" fill="currentColor"></path>
                                            <path d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z" fill="currentColor"></path>
                                        </svg>
                                    </span>
                                </Link>
                            </Tooltip>
                        }
                        {isHavePermission("delete_group") &&

                            <Tooltip disableInteractive title={"Delete"} placement="top" arrow>
                                <Link className={"btn btn-icon btn-bg-light btn-active-color-primary btn-sm "} onClick={() => setIsSwal({ ...isSwal, show: true, id: row.pk })}>
                                    <span className="svg-icon svg-icon-3">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
                                            <path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="currentColor"></path>
                                            <path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="currentColor"></path>
                                            <path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="currentColor"></path>
                                        </svg>
                                    </span>
                                </Link>
                            </Tooltip>
                        }
                    </div>,
                // ignoreRowClick: false,
                // allowOverflow: true,
                right: true,
                // width: "100px", 
                button: false,
            },

        ],
        [pageno],
    );

    const handleClear = () => {
        if (filterText) {
            setFilterText('');
            fetchGroups(1, limit);

        }
    };
    const handleRowClick = (row) => {
        setColorRow(row);
        // // console.log(row);
    };
    let i = 0
    // const conditionalRowStyles = [

    //     {
    //         when: row => { i = i + 1; return i % 2 == 0 || colorRow.pk == row.pk ? true : false },
    //         style: (row, index) => ({
    //             backgroundColor: colorRow?.pk == row.pk ? '#dbf4f5' : '#f2f2f2',
    //         }),
    //     },

    // ];
    const conditionalRowStyles = [

        {
            when: row => colorRow.pk == row.pk ? true : false,
            style: (row, index) => ({
                backgroundColor: colorRow?.pk == row.pk ? '#dbf4f5' : '#f2f2f2',
                color: 'black',
                // fontWeight: '550'
            }),
        },


    ];

    const fetchGroups = async (page, limit, search) => {
        setLoading(true);
        getAllGroups(page, limit, search).then((response) => {

            setData(response?.data);
            setLimit(limit);
            setPageNo(page)
            setTotalRows(response?.data?.count);
            setLoading(false);
        })

    };

    const handlePageChange = page => {
        setPageNo(page);
        fetchGroups(page, limit, filterText);
    };

    const handlePerRowsChange = async (newPerPage, page) => {


        setLimit(newPerPage);
        setPageNo(1)

        fetchGroups(1, newPerPage, filterText)
    };
    useEffect(() => {
        fetchGroups(1, limit);
    }, []);

    const onClickDeleteGroup = (group_id) => {
        deleteGroup(group_id).then((response) => {

            if (response?.status) {
                setToaster('success')
                setTimeout(() => {
                    setToaster('')
                    fetchGroups(pageno, limit, filterText)

                }, 2000);

            } else {
                setToaster('error')
                setTimeout(() => {
                    setToaster('')

                }, 2000);
            }
        });
    }
    const handleDelete = (pk) => {
        // console.log(pk);
        setIsSwal({ ...isSwal, show: 2 })
        onClickDeleteGroup(pk)
    }
    const searchGroups = async (e) => {
        let data = e.target.value ? e.target.value : '';
        setFilterText(e.target.value)
        await fetchGroups(1, limit, data)

    }
    return (
        <React.Fragment>
            <Sidebar />
            <main id="main" className="main">
                <ToastContainer className="p-3" position={"top-end"}>
                    <Toast bg="success" show={toaster === 'success' ? true : false} animation={true} delay={2000} autohide>

                        <Toast.Body ><span className='text-white'>Success</span></Toast.Body>
                    </Toast>
                    <Toast bg="danger" show={toaster === 'error' ? true : false} animation={true} delay={2000} autohide>

                        <Toast.Body ><span className='text-white'>Failed</span></Toast.Body>
                    </Toast>
                </ToastContainer>
                <div className="pagetitle tab-fixed-header">
                    <h1 id='page-title'>Groups</h1>
                </div>

                <section className="section mt-5" >
                    <div className="card mb-5 mb-xl-8">
                        <div className="card-header border-0 px-4">
                            <div className="d-flex align-items-center position-relative my-1">
                                <FilterComponent onFilter={(e) => searchGroups(e)} onClear={handleClear} filterText={filterText} />

                            </div>
                            {isHavePermission("create_group") &&

                                <div className="card-toolbar" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-trigger="hover" data-bs-original-title="Click to add a user" data-kt-initialized="1">
                                    <Link to='/create-group' className="btn btn-light btn-active-primary">
                                        <span className="svg-icon svg-icon-3">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1" transform="rotate(-90 11.364 20.364)" fill="currentColor"></rect>
                                                <rect x="4.36396" y="11.364" width="16" height="2" rx="1" fill="currentColor"></rect>
                                            </svg>
                                        </span>
                                        Create</Link>
                                </div>
                            }
                        </div>
                        <div className="card-body py-3">
                            <div className="table-responsive">
                                <div className='dashboardtable'>
                                    {!reRenderDataTable &&
                                        <DataTable
                                            className="custom-datatable"
                                            data={data?.results}
                                            columns={columns}
                                            progressPending={loading}
                                            pagination
                                            paginationServer
                                            paginationTotalRows={totalRows}
                                            paginationPerPage={limit}
                                            fixedHeader={true}
                                            // paginationRowsPerPageOptions={[25, 50, 100, 150, 200]}
                                            onChangeRowsPerPage={handlePerRowsChange}
                                            onChangePage={handlePageChange}
                                            conditionalRowStyles={conditionalRowStyles}
                                            striped={true}
                                            // onRowClicked={handleRowCheckClick}
                                            onRowClicked={handleRowClick}
                                            theme="solarized"
                                            customStyles={datatableCustomStyles}

                                        />
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <DeleteSweetAlert handleDelete={handleDelete} isSwal={isSwal} setIsSwal={setIsSwal} />

            </main>
        </React.Fragment>
    )
}

export default ListGroups