import request from "../api/agent";


export class DashboardService {
    async get837UsedMatchReport(props) {
        let BASE_URL = '/dashboard/837-used-match-report?'
        if (props?.date_range) {
            BASE_URL += 'date_range=' + props.date_range + '&'
        }
        if (props?.partners) {
            BASE_URL += 'partners=' + props.partners + '&'
        }
        if (props?.clients) {
            BASE_URL += 'clients=' + props.clients + '&'
        }
        if (props?.facilities) {
            BASE_URL += 'facilities=' + props.facilities + '&'
        }
        const response = await request.get(BASE_URL);
        return response.data ?? response?.response?.data;

    }
    async getFileReport(props) {
        let BASE_URL = '/dashboard/file-report?'
        if (props?.date_range) {
            BASE_URL += 'date_range=' + props.date_range + '&'
        }
        if (props?.partners) {
            BASE_URL += 'partners=' + props.partners + '&'
        }
        if (props?.clients) {
            BASE_URL += 'clients=' + props.clients + '&'
        }
        if (props?.facilities) {
            BASE_URL += 'facilities=' + props.facilities + '&'
        }
        const response = await request.get(BASE_URL);
        return response.data ?? response?.response?.data;

    }
    async geVolumeReport(props) {
        let BASE_URL = '/dashboard/volume-report?'
        if (props?.date_range) {
            BASE_URL += 'date_range=' + props.date_range + '&'
        }
        if (props?.partners) {
            BASE_URL += 'partners=' + props.partners + '&'
        }
        if (props?.clients) {
            BASE_URL += 'clients=' + props.clients + '&'
        }
        if (props?.facilities) {
            BASE_URL += 'facilities=' + props.facilities + '&'
        }
        if (props?.filter?.year) {
            BASE_URL += 'year=true&'
        }
        if (props?.filter?.month) {
            BASE_URL += 'month=true&'
        }
        if (props?.filter?.week) {
            BASE_URL += 'week=true&'
        }
        if (props?.filter?.days) {
            BASE_URL += 'days=true&'
        }
        const response = await request.get(BASE_URL);
        return response.data ?? response?.response?.data;

    }
    async getTimeReport(props) {
        let BASE_URL = '/dashboard/time-report?client=true&'
        if (props?.date_range) {
            BASE_URL += 'date_range=' + props.date_range + '&'
        }
        if (props?.partners) {
            BASE_URL += 'partners=' + props.partners + '&'
        }
        if (props?.clients) {
            BASE_URL += 'clients=' + props.clients + '&'
        }
        if (props?.facilities) {
            BASE_URL += 'facilities=' + props.facilities + '&'
        }
        const response = await request.get(BASE_URL);
        return response.data ?? response?.response?.data;

    }
    async getProductivityReport(props) {
        let BASE_URL = '/dashboard/productivity-report?'
        if (props?.date_range) {
            BASE_URL += 'date_range=' + props.date_range + '&'
        }
        if (props?.partners) {
            BASE_URL += 'partners=' + props.partners + '&'
        }
        if (props?.clients) {
            BASE_URL += 'clients=' + props.clients + '&'
        }
        if (props?.facilities) {
            BASE_URL += 'facilities=' + props.facilities + '&'
        }

        const response = await request.get(BASE_URL);
        return response.data ?? response?.response?.data;

    }
    async getQualityReport(props) {
        let BASE_URL = '/dashboard/quality-report?'
        if (props?.date_range) {
            BASE_URL += 'date_range=' + props.date_range + '&'
        }
        if (props?.partners) {
            BASE_URL += 'partners=' + props.partners + '&'
        }
        if (props?.clients) {
            BASE_URL += 'clients=' + props.clients + '&'
        }
        if (props?.facilities) {
            BASE_URL += 'facilities=' + props.facilities + '&'
        }
        const response = await request.get(BASE_URL);
        return response.data ?? response?.response?.data;

    }
    async getProductivitySingleReport(props) {
        let BASE_URL = '/dashboard/user-cph-report?'
        if (props?.date_range) {
            BASE_URL += 'date_range=' + props.date_range + '&'
        }
        if (props?.partners) {
            BASE_URL += 'partners=' + props.partners + '&'
        }
        if (props?.clients) {
            BASE_URL += 'clients=' + props.clients + '&'
        }
        if (props?.facilities) {
            BASE_URL += 'facilities=' + props.facilities + '&'
        }
        if (props?.client_id) {
            BASE_URL += 'client_id=' + props.client_id + '&'
        }
        if (props?.transaction_type) {
            BASE_URL += 'transaction_type=' + props.transaction_type + '&'
        }
        const response = await request.get(BASE_URL);
        return response.data ?? response?.response?.data;

    }
    async getQualitySingleReport(props) {
        let BASE_URL = '/dashboard/user-quality-report?'
        if (props?.date_range) {
            BASE_URL += 'date_range=' + props.date_range + '&'
        }
        if (props?.partners) {
            BASE_URL += 'partners=' + props.partners + '&'
        }
        if (props?.clients) {
            BASE_URL += 'clients=' + props.clients + '&'
        }
        if (props?.facilities) {
            BASE_URL += 'facilities=' + props.facilities + '&'
        }
        if (props?.client_id) {
            BASE_URL += 'client_id=' + props.client_id + '&'
        }
        if (props?.transaction_type) {
            BASE_URL += 'transaction_type=' + props.transaction_type + '&'
        }
        const response = await request.get(BASE_URL);
        return response.data ?? response?.response?.data;

    }

    async getSnippingReport(props) {
        let BASE_URL = '/dashboard/snipping-report?'
        if (props?.date_range) {
            BASE_URL += 'date_range=' + props.date_range + '&'
        }
        if (props?.partners) {
            BASE_URL += 'partners=' + props.partners + '&'
        }
        if (props?.clients) {
            BASE_URL += 'clients=' + props.clients + '&'
        }
        if (props?.facilities) {
            BASE_URL += 'facilities=' + props.facilities + '&'
        }
        const response = await request.get(BASE_URL);
        return response.data ?? response?.response?.data;

    }
    async getFileSplitReport(props) {
        let BASE_URL = '/dashboard/file-split-report?'
        if (props?.date_range) {
            BASE_URL += 'date_range=' + props.date_range + '&'
        }
        if (props?.partners) {
            BASE_URL += 'partners=' + props.partners + '&'
        }
        if (props?.clients) {
            BASE_URL += 'clients=' + props.clients + '&'
        }
        if (props?.facilities) {
            BASE_URL += 'facilities=' + props.facilities + '&'
        }
        const response = await request.get(BASE_URL);
        return response.data ?? response?.response?.data;

    }
}