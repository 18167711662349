import React, { useEffect, useState } from 'react'
import Chart from 'react-apexcharts';
import { DashboardService } from '../../../../services/DashboardService';
import moment from 'moment';
import BarSkelitonLoader from '../../loader/BarSkelitonLoader';

const AllReportGraph = ({ update, isVisible, setLoaderState, searchFormik, handleClickData }) => {
    const [data, setData] = useState([])
    const [fullData, setFullData] = useState([])
    const [labels, setLabels] = useState([])

    const dashboardService = new DashboardService();

    const { getQualityReport } = dashboardService
    useEffect(() => {
        setLoaderState((prevState) => { return { ...prevState, qualityLoader: true } })
        let date = moment(searchFormik.values.date_range[0]).format('MM/DD/YYYY HH:mm:ss') + ' - ' + moment(searchFormik.values.date_range[1]).format('MM/DD/YYYY HH:mm:ss')
        getQualityReport({ date_range: date, partners: searchFormik?.values?.partners, clients: searchFormik?.values?.clients, facilities: searchFormik?.values.facilities }).then((response) => {
            if (response && response.status) {
                setFullData(response?.data)
                setLabels(response?.data?.map(item => item?.client_name))
                setData(response?.data?.map(item => item?.quality_total))
                setLoaderState((prevState) => { return { ...prevState, qualityLoader: false } })

            }
        });
    }, [update])

    const options = {
        dataLabels: {
            enabled: true,
            style: {
                colors: ['black']
            },
            dropShadow: {
                enabled: false,
                enabledOnSeries: undefined,
                top: 0,
                left: 0,
                blur: 3,
                opacity: 0.35
            }
        },

        fill: {
            type: 'gradient',
        },
        chart: {
            id: 'basic-bar',
            type: 'bar',
            toolbar: {
                show: false
            },
            fontFamily: 'Open Sans',
            events: {
                dataPointSelection: handleClickData
            }
        },
        plotOptions: {
            bar: {
                distributed: true,
                columnWidth: '40px'
            },
        },
        grid: {
            borderColor: '#E4E4E4',
            xaxis: {
                lines: {
                    show: false
                }
            },
            yaxis: {
                lines: {
                    show: true
                }
            },
        },

        legend: {
            show: false
        },
        colors: ['#b2d3a3', '#9cadda', '#ffd993', '#c7d07b', '#f6b89d', '#abc7e7', '#969696', '#24B2DD', '#FFB11F', '#D98383'],

        // colors: ['#ad6baf', '#75ba7a', '#61d6f3', '#c7d07b', '#ffb981', '#00a1a8', '#166783', '#c7d07b', '#df8483', '#ffb237'],
        xaxis: {
            categories: labels ?? [],
            axisBorder: {
                show: true,
                color: '#000000',
                height: 1,
                width: '100%',
                offsetX: 0,
                offsetY: 0
            },
            labels: {
                show: true,
                trim: true,
                hideOverlappingLabels: false,
                rotate: 0
            }
        },
        yaxis: {
            axisBorder: {
                show: true,
                color: '#000000',
                height: '100%',
                width: 1,
                offsetX: 0,
                offsetY: 0
            },
        },
        noData: {
            text: 'No data available',
        },
        tooltip: {
            show: true,
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                const color = w.config.colors[dataPointIndex];
                const tooltip = `${fullData[dataPointIndex]?.client_name} ${fullData[dataPointIndex]?.quality_total}(${fullData[dataPointIndex]?.total_error_count}/${fullData[dataPointIndex]?.total_qa_complte})`
                console.log(fullData[dataPointIndex])
                return `<div style="background-color:${color};padding:5px" class="">
                        <span style="color:black;"> ${tooltip}  </span>
                </div>`

            }
        },
    };

    // console.log(data)
    const series = [{
        name: 'Quality (%)',
        data: data ? data : []
    }]
    return (
        isVisible ?
            <BarSkelitonLoader /> :
            <Chart options={options} series={series} type="bar" height={'90%'} width={(series?.[0]?.data?.length ?? 3) * 50 + 500 > 1000 ? ((series?.[0]?.data?.length ?? 3) * 50 + 500) : '100%'} />
    )
}

export default AllReportGraph