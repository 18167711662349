import request from "../api/agent";

const API_ROUTE = "reports";

export class FileManagementService {

  async getFileManagementSummery(page, limit, station, job_status, date_range, hour_count, clients, facilities) {
    const params = `?page=${page}&limit=${limit}&date_range=${date_range}&job_status=${job_status}&station=${station}&hour_count=${hour_count}&clients=${clients}&facilities=${facilities}`

    const response = await request.get(`${API_ROUTE}/get-payer-reasoncode-summery` + params);
    return response.data ?? response?.response?.data;
  }

  async getFileManagementDetailPayer(page, limit, station, job_status, date_range, hour_count, user, day, year, week, monthList, clients, facilities, facility_id) {
    const params = `?page=${page}&limit=${limit}&date_range=${date_range}&job_status=${job_status}&station=${station}&hour_count=${hour_count}&user=${user}&day=${day}&year=${year}&week=${week}&month=${monthList}&clients=${clients}&facilities=${facilities}&facility_id=${facility_id}`

    const response = await request.get(`${API_ROUTE}/get-reasoncode-details-payer` + params);
    return response.data;
  }
  async getFileManagementDetailPayerResonCode(page, limit, station, job_status, date_range, hour_count, user, day, year, week, monthList, clients, facilities, facility_id) {
    const params = `?page=${page}&limit=${limit}&date_range=${date_range}&job_status=${job_status}&station=${station}&hour_count=${hour_count}&user=${user}&day=${day}&year=${year}&week=${week}&month=${monthList}&clients=${clients}&facilities=${facilities}&facility_id=${facility_id}`

    const response = await request.get(`${API_ROUTE}/get-payer-details-reasoncode` + params);
    return response.data;
  }
  async downloadFileManagementSummery(page, limit, station, job_status, date_range, hour_count, clients, facilities) {
    const params = `?page=${page}&limit=${limit}&date_range=${date_range}&job_status=${job_status}&station=${station}&hour_count=${hour_count}&clients=${clients}&facilities=${facilities}`

    const response = await request.get(`${API_ROUTE}/payer-reasoncocde-report-download` + params);
    return response.data;
  }

  async downloadFileManagementDetails(page, limit, station, job_status, date_range, hour_count, user, day, year, week, monthList, clients, facilities, facility_id) {
    const params = `?page=${page}&limit=${limit}&date_range=${date_range}&job_status=${job_status}&station=${station}&hour_count=${hour_count}&user=${user}&day=${day}&year=${year}&week=${week}&month=${monthList}&clients=${clients}&facilities=${facilities}&facility_id=${facility_id}`

    const response = await request.get(`${API_ROUTE}/payer-report-download-reasoncocde` + params);
    return response.data;
  }

  async downloadFileManagementReasonCodeDetails(page, limit, station, job_status, date_range, hour_count, user, day, year, week, monthList, clients, facilities, facility_id) {
    const params = `?page=${page}&limit=${limit}&date_range=${date_range}&job_status=${job_status}&station=${station}&hour_count=${hour_count}&user=${user}&day=${day}&year=${year}&week=${week}&month=${monthList}&clients=${clients}&facilities=${facilities}&facility_id=${facility_id}`

    const response = await request.get(`${API_ROUTE}/reasoncocde-payer-report-download` + params);
    return response.data;
  }
}