export const downloadXl = (file) => {
  // using Java Script method to get PDF file
  // const fileURL = file.replace('http://', 'https://');
  const fileURL = file.startsWith('http://') ? file.replace('http://', 'https://') : file;
  // Setting various property values
  let alink = document.createElement('a');
  alink.href = fileURL;
  alink.download = 'UserReport.xlsx';
  alink.target = '_blank';
  alink.click();
}


export function changeDateFormat(originalDate) {
  const parts = originalDate.split('-');
  return `${parts[1]}/${parts[2]}/${parts[0]}`;
}


export function downloadFile(url) {
  fetch(url)
    .then(async response => {
      const contentDisposition = response.headers.get('Content-Disposition');
      const filename = contentDisposition ? contentDisposition.split('filename=')[1] : 'job-monitor';

      return response.blob().then(blob => {
        const downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(blob);
        downloadLink.setAttribute('download', filename);

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      });
    })
    .catch(error => {
      console.error('Error downloading file:', error);
    });
}

export const changeAllTitleFunction = (linkText, linkUrl) => {

}

export const checkDbkValidation = (value1, dbk_value1, is_permission, type = "text") => {
  let value = value1 == null ? "" : value1
  let dbk_value = dbk_value1 == null ? "" : dbk_value1
  let is_valid = false

  if (is_permission) {
    if (type === "text" && String(value).toUpperCase() != String(dbk_value).toUpperCase()) {
      is_valid = true
    } else if (type === "number" && String(value).replace(NaN, "") != String(dbk_value).replace(NaN, "")) {
      is_valid = true
    } else if (type === "date" && value?.replace("__/__/____", "") !=
      dbk_value?.replace("__/__/____", "")) {
      is_valid = true
    }
  }
  return is_valid
}

export const disableBasedOnSubJob = (subJobEobContainer) => {
  if (subJobEobContainer) {

    const inputs = subJobEobContainer.getElementsByTagName("input");
    const selects = subJobEobContainer.getElementsByTagName("select");


    for (var i = 0; i < inputs.length; i++) {
      inputs[i].disabled = true;
    }

    for (var i = 0; i < selects.length; i++) {
      selects[i].disabled = true;
    }



  }
}

export function disablingBasedOnDbk(container) {
  if (container) {
    const inputs = container.querySelectorAll("input");
    const buttons = container.getElementsByTagName("button");
    const selects = container.getElementsByTagName("select");
    // Disable input elements
    for (let i = 0; i < inputs.length; i++) {
      inputs[i].disabled = true;
    }

    // Disable buttons
    for (let i = 0; i < buttons.length; i++) {
      if (!buttons[i].closest('.exclude-disabled-new')) {
        buttons[i].disabled = true;
      }
    }

    // Disable select elements
    for (let i = 0; i < selects.length; i++) {
      selects[i].disabled = true;
    }

  }
}

export function isActiveDbkField(obj, formik, isFieldPermission) {

  let isActive = Object.values(obj).some(val => val === true) ||
    obj?.claims?.some(claim => Object.values(claim).some(val => val === true));


  let isError = Object.values(formik?.errors).some(val => val === 'Dont Match') ||

    formik?.errors && formik?.errors?.claims && formik?.errors?.claims?.some(claim => claim ? Object.values(claim)?.some(val => val === 'Dont Match') : false);

  return isActive && isError
}
//data-entry
export const DATA_ENTRY = "/my-work"
export const QA_REVIEW = "/qa-review"
export const EDIT_WORK = "/my-work/edit/"
export const DASHBOARD = ""


