import React, { useState } from "react";
import DataTable from "react-data-table-component";

import { useMemo } from "react";
import { Button, Form, OverlayTrigger } from "react-bootstrap";
import { useFormik } from "formik";

import { TbGridDots } from "react-icons/tb";
import { HiChevronDown } from "react-icons/hi";
import { components } from 'react-select';
import { BsCloudDownloadFill } from "react-icons/bs";

import { FileReportService } from "../../../services/FileReportService";
import { changeDateFormat, downloadXl } from "../../../functions";
import { useEffect } from "react";
import LockBoxFileReportDetails from "./LockBoxFileReportDetails";
import { Tooltip } from "@mui/material";
import DetailCutomRowStyle from "../../components/DetailCutomRowStyle";
import { useCallback } from "react";


function FileReportDetails(params) {
  const { report_station, report_job_status, report_date_range, report_hour_count, user, clients, facilities, facility_id } = params

  const [reRenderDataTable, setRerenderDataTable] = useState(false)
  const [pageno, setPageNo] = useState(1);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(100);
  const [selectValue, setSelectValue] = useState('');
  const [colorRow, setColorRow] = React.useState({});
  const [data, setData] = useState([])
  const [day, setDay] = useState(true)
  const [year, setYear] = useState(false)
  const [week, setWeek] = useState(false)
  const [month, setMonth] = useState(false)
  const [monthList, setMonthList] = useState([])
  const [limit, setLimit] = useState(100)
  const [searchedData, setSearchedData] = useState({
    search: "",
    client: "",
    created_date: "",
    date_range: "",
  })

  const fileReportService = new FileReportService();
  const { getFileReportDetails, downloadFileReportDetails } = fileReportService


  const handleDetailFilterChange = (name) => {
    if (name === 'year') {
      setYear(true)
      setWeek(false)
      setMonth(false)
      setDay(false)
      fetchData(1, limit, report_station, report_job_status, report_date_range, report_hour_count, user, 'false', 'true', 'false', 'false');

    }
    else if (name === 'day') {
      setYear(false)
      setWeek(false)
      setMonth(false)
      setDay(true)
      fetchData(1, limit, report_station, report_job_status, report_date_range, report_hour_count, user, 'true', 'false', 'false', 'false');

    }
    else if (name === 'month') {
      setYear(false)
      setWeek(false)
      setMonth(true)
      setDay(false)
      fetchData(1, limit, report_station, report_job_status, report_date_range, report_hour_count, user, 'false', 'false', 'false', 'true');

    }
    else if (name === 'week') {
      setYear(false)
      setWeek(true)
      setMonth(false)
      setDay(false)
      fetchData(1, limit, report_station, report_job_status, report_date_range, report_hour_count, user, 'false', 'false', 'true', 'false');

    }

  }
  const handlePerRowsChange = async (newPerPage, page) => {
    setLimit(newPerPage);
    setPageNo(1)

    fetchData(1, newPerPage, report_station, report_job_status, report_date_range, report_hour_count, user, day, year, week, month);
    handleResetDataTable()

  };

  const handlePageChange = page => {
    setPageNo(page)
    fetchData(page, limit, report_station, report_job_status, report_date_range, report_hour_count, user, day, year, week, month);
  };




  const fetchData = (page, limit, station, job_status, date_range, hour_count, user, day, year, week, month) => {

    setLoading(true)
    getFileReportDetails(page, limit, report_station, report_job_status, report_date_range, report_hour_count, user, day, year, week, month, clients, facilities, facility_id).then((response) => {
      setData(response?.data?.results);
      setLimit(limit);
      setPageNo(page)
      setTotalRows(response?.data?.count);
      setLoading(false);
    });

  }

  const [expandedRows, setExpandedRows] = useState([]);

  // const ExpandedComponent = (row) => <pre>{expandedRows?.length > 0 && <LockBoxFileReportDetails report_page={1} report_limit={limit} report_station={""} report_job_status={""} report_date_range={searchedData?.date_range} report_hour_count={searchedData?.hour_count} user={user} day={day} year={year} week={week} month={month} filtering_date={row?.data?.proccessed_date} />}</pre>;
  const ExpandedComponent = useCallback(
    ({ data }) => (
      <pre>
        {expandedRows?.length > 0 && (
          <LockBoxFileReportDetails
            report_page={1}
            report_limit={limit}
            report_station={""}
            report_job_status={""}
            report_date_range={report_date_range}
            report_hour_count={report_hour_count}
            clients={clients}
            facilities={facilities}
            user={user} day={day} year={year} week={week}
            month={month}
            facility_id={facility_id}
            filtering_date={data?.proccessed_date} />
        )}
      </pre>
    ),
    [expandedRows, limit, searchedData]
  );
  const handleRowExpand = (row) => {
    handleRowClick(row)
    const isRowExpanded = expandedRows.includes(row);

    setExpandedRows(isRowExpanded ? expandedRows.filter((r) => r !== row) : [row]);
  };

  const columns = useMemo(
    () => [
      {
        id: 'S.NO',
        name: 'S.NO',
        selector: (row, index) => pageno == 1 ? index + 1 : (pageno - 1) * limit + index + 1,
        wrap: true,
        width: "40px",
        grow: 0.1,

      },
      {
        id: 'PROCEES DATA',
        name: year ? 'YEAR' : week ? "WEEK" : month ? "MONTH" : "DATE",
        selector: row => row?.proccessed_date,
        cell: (row) =>

          <div>
            {row?.proccessed_date &&

              <div className='d-flex flex-row justify-content-between align-items-center gap-2' style={{ width: '70px' }}>
                <div className='overflow-hidden text-truncate' style={{ width: "70px" }} onClick={() => handleRowClick(row)}>
                  <Tooltip disableInteractive title={day ? changeDateFormat(row?.proccessed_date) : row?.proccessed_date} placement="top" arrow>
                    <span className="text-truncate"> {day ? changeDateFormat(row?.proccessed_date) : row?.proccessed_date}</span>
                  </Tooltip>
                </div>
              </div>

            }
          </div>
        ,


        left: true,
        wrap: true,
        reorder: false,
        // grow: 0.1,
        expandOnRowClicked: true,
        minWidth: '70px'
      },
      {
        id: 'LBX',
        name: 'LBX',
        selector: row => row?.lock_box,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.lock_box}</span>,

        right: true,
        wrap: true,
        reorder: false,
        // grow: 0.1,
        minWidth: '50px'
      },
      {
        id: 'LBX FILES',
        name: 'LBX FILES',
        selector: row => row?.lock_box_files,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.lock_box_files}</span>,

        right: true,
        wrap: true,
        reorder: false,
        minWidth: '50px'

        // grow: 0.1,
      },
      {
        id: 'INDEX FILES',
        name: 'INDEX FILES',
        selector: row => row?.index_files,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.index_files}</span>,

        right: true,
        wrap: true,
        reorder: false,
        minWidth: '50px'

        // grow: 0.1,
      },
      {
        id: 'AUTO SPLIT',
        name: 'AUTO SPLIT',
        selector: row => row?.auto_split,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.auto_split}</span>,

        right: true,
        wrap: true,
        reorder: false,
        minWidth: '50px'

        // grow: 0.1,
      },
      {
        id: 'MANUAL SPLIT',
        name: 'MANUAL SPLIT',
        selector: row => row?.manual_split,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.manual_split}</span>,

        right: true,
        wrap: true,
        reorder: false,
        minWidth: '100px'

        // grow: 0.1,
      },


      {
        id: 'FILES COMPLETED',
        name: 'FILES COMPLETED',
        selector: row => row?.files_completed,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.files_completed}</span>,

        right: true,
        wrap: true,
        reorder: false,
        // grow: 0.1,
        minWidth: '100px'

      },
      {
        id: 'FILES REJECTED',
        name: 'FILES REJECTED',
        selector: row => row?.files_rejected,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.files_rejected}</span>,

        right: true,
        wrap: true,
        reorder: false,
        minWidth: '100px'

        // grow: 0.1,
      },

      {
        id: 'JOB COUNT',
        name: 'JOB COUNT',
        selector: row => row?.job_count,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.job_count}</span>,

        right: true,
        wrap: true,
        reorder: false,
        // grow: 0.1,
        // omit: isTimeCount
        minWidth: '50px'


      },
      {
        id: '# CLAIMS',
        name: '# CLAIMS',
        selector: row => row?.claims_count,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.claims_count}</span>,

        right: true,
        wrap: true,
        reorder: false,
        // grow: 0.1,
        minWidth: '50px'


      },
      {
        id: '# 837 USED-JOB',
        name: '# 837 USED-JOB',
        selector: row => row?.used_837,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.used_837}</span>,

        right: true,
        wrap: true,
        reorder: false,
        // grow: 0.1,
        minWidth: '100px'

      },
      {
        id: '# 837 USED-CLAIMS',
        name: '# 837 USED-CLAIMS',
        selector: row => row?.used_837_claims,
        cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.used_837_claims}</span>,

        right: true,
        wrap: true,
        reorder: false,
        // grow: 0.1,
        minWidth: '110px'

      },


    ],

    [handleRowExpand, limit, pageno, report_hour_count, week, year],
  );
  const handleRowClickCheck = (row) => {
    data?.map((item, index) => {
      if (row?.proccessed_date == item.proccessed_date) {
        setColorRow(row)
      }
    })
  }
  var i = 0;
  const conditionalRowStyles = [

    {
      when: row => colorRow.proccessed_date == row.proccessed_date ? true : false,
      style: (row, index) => ({
        backgroundColor: colorRow?.proccessed_date == row.proccessed_date ? '#e4e6ef' : '#fffff',
        color: 'black',
        // fontWeight: '550'
      }),
    },


  ];
  const handleRowClick = (row) => {
    setColorRow(row);
    const isRowExpanded = expandedRows.includes(row);

    setExpandedRows(isRowExpanded ? expandedRows.filter((r) => r !== row) : [row]);
    // row.expandableRowExpanded = !row.expandableRowExpanded;
  };



  const searchFormik = useFormik({
    initialValues: {
      search: "",
      job_status: report_job_status,
      station: report_station,
      date_range: report_date_range,
      hour_count: report_hour_count

    },
    onSubmit: (values) => {
      console.log(values);
      handleResetDataTable()
    }
  })

  const [datetime, setDateTime] = useState([]);



  const searchData = ({ station, job_status, date_range, hour_count }) => {
    fetchData(1, limit, station, selectValue, datetime, hour_count)
  }

  const handleChangeSelect = async (e, name) => {
    let selectedId = []
    let selectedOptons = []

    e.map((option) => {
      selectedId.push(option.value)
      selectedOptons.push({ option })
    })

    searchFormik.setValues({ ...searchFormik.values, [name]: selectedId })
    setSelectValue(selectedId)

  }


  const showTooltip = (text, props) => (
    <Tooltip id="button-tooltip" {...props}>
      {text}
    </Tooltip>
  );

  const handleResetDataTable = () => {
    setRerenderDataTable(true)
  }

  const [loader, setLoader] = useState(false);
  const handleDownload = () => {
    setLoader(true)

    downloadFileReportDetails(1, limit, report_station, report_job_status, report_date_range, report_hour_count, user, day, year, week, month, clients, facilities, facility_id).then((response) => {
      setLoader(false)
      downloadXl(response?.data?.export_file)
    });
  }
  useEffect(() => {
    fetchData(1, limit, report_station, report_job_status, report_date_range, report_hour_count, user, day, year, week, month);
  }, [])

  return (
    <React.Fragment>
      <section className="section">
        <div className="row align-items-top py-2 gap-2 gap-md-5">
          <div className="col-lg-12">
            <div className="card card_background">

              <Form onSubmit={searchFormik.handleSubmit} >
                <div className="d-flex justify-content-end" style={{ height: "20px" }}>
                  <div className="px-3 pt-1 d-flex flex-row justify-content-start gap-2 align-items-center flex-wrap">

                    <div>
                      <Tooltip disableInteractive title={"Year"} placement="top" arrow>
                        <div class="form-check">
                          <input class="form-check-input me-1" type="radio" onChange={() => handleDetailFilterChange('year')} checked={year} name="year" id="year1" />
                          <label class="form-check-label" for="year1" style={{ fontSize: "14px", fontWeight: "600" }}>
                            Year
                          </label>
                        </div>
                      </Tooltip>

                    </div>
                    <div>
                      <Tooltip disableInteractive title={"Month"} placement="top" arrow>

                        <div class="form-check">
                          <input class="form-check-input me-1" type="radio" onChange={() => handleDetailFilterChange('month')} checked={month} name="month" id="month2" />
                          <label class="form-check-label" for="month2" style={{ fontSize: "14px", fontWeight: "600" }}>
                            Month
                          </label>
                        </div>
                      </Tooltip>

                    </div>


                    <div>
                      <Tooltip disableInteractive title={"Week"} placement="top" arrow>

                        <div class="form-check">
                          <input class="form-check-input me-1" onChange={() => handleDetailFilterChange('week')} checked={week} type="radio" name="week" id="week3" />
                          <label class="form-check-label" for="week3" style={{ fontSize: "14px", fontWeight: "600" }}>
                            Week
                          </label>
                        </div>
                      </Tooltip>

                    </div>
                    <div>
                      <Tooltip disableInteractive title={"Day"} placement="top" arrow>


                        <div class="form-check">
                          <input class="form-check-input me-1" onChange={() => handleDetailFilterChange('day')} checked={day} type="radio" name="flexRadioDefault" id="flexRadioDefault4" />
                          <label class="form-check-label" for="flexRadioDefault4" style={{ fontSize: "14px", fontWeight: "600" }}>
                            Day
                          </label>
                        </div>
                      </Tooltip>

                    </div>
                  </div>
                  <div className="px-3 pt-1 d-flex flex-row justify-content-start gap-2 align-items-center flex-wrap">

                    <div>
                      <Tooltip disableInteractive title={"Download"} placement="top" arrow>
                        {!loader ?

                          <span style={{ cursor: "pointer" }} className="svg-icon svg-icon-3" onClick={() => { handleDownload() }}>
                            <BsCloudDownloadFill color="#1ba1a7" />
                          </span>
                          :
                          <span className="svg-icon svg-icon-3">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          </span>
                        }

                      </Tooltip>

                    </div>
                  </div>
                </div>

              </Form>


              <div className="card-body p-common">
                <div className="table-responsive">
                  <div className="dashboardtable custom_background">
                    {/* {!reRenderDataTable && */}
                    <DataTable
                      // className="custom-datatable-details"
                      paginationDefaultPage={pageno}

                      fixedHeader={true}
                      progressPending={loading}
                      data={data}
                      columns={columns}
                      pointerOnHover={true}
                      pagination
                      paginationServer
                      // highlightOnHover
                      paginationTotalRows={totalRows}
                      paginationPerPage={limit}
                      paginationRowsPerPageOptions={[25, 50, 100, 150, 200]}
                      onChangeRowsPerPage={handlePerRowsChange}
                      onChangePage={handlePageChange}
                      onRowClicked={handleRowClick}
                      conditionalRowStyles={conditionalRowStyles}
                      theme="detail_solarized"
                      customStyles={DetailCutomRowStyle}
                      striped={true}
                      expandableRows
                      expandOnRowClicked={false}
                      onRowExpandToggled={handleRowExpand}
                      expandableRowsComponent={ExpandedComponent}
                      expandableRowsHideExpander
                      expandableRowExpanded={(row) => expandedRows.includes(row)}

                    />
                    {/* } */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment >
  )
}
export default FileReportDetails




