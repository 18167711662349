import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import userReducer from "../slice/user/userSlice";
import errorReducer from "../slice/error/errorSlice";

export default configureStore({
  reducer: {
    user: userReducer,
    error: errorReducer,
  },
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
});
